import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import AppTheme from 'src/assets/styles/theme';

const DatePickerTheme = createMuiTheme({
  palette: {
    primary: { main: AppTheme.mainTealColor },
    secondary: { main: AppTheme.mainButtonColor },
  },
});

const ProfileFormLicenseDatePickerContainer = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
  input {
    color: black !important;
  }
  .MuiFormLabel-root {
    text-transform: capitalize !important;
  }
  & > div {
    width: 48%;
  }
`;

interface Props {
  formikProps: FormikProps<any>;
  isDisabled: boolean;
}

// only endDate is editable, startDate is read-only
const ProfileFormLicenseDatePicker = ({ formikProps, isDisabled }: Props) => {
  const { values, errors, setFieldValue, setFieldError } = formikProps;

  const handleEndDateChange = (date: moment.Moment) => {
    if (formikProps && date) {
      setFieldValue('licenseEndDate', date.endOf('day').millisecond(0o00));
    } else {
      setFieldValue('licenseEndDate', null);
      setFieldError('licenseEndDate', undefined);
    }
  };

  const handleEndDateError = (error: any) => {
    if (isDisabled) return;
    if (!!error && !errors['licenseEndDate']) setFieldError('licenseEndDate', error);
    if (!error && errors['licenseEndDate']) setFieldError('licenseEndDate', undefined);
  };

  const endDateHelperText = errors['licenseEndDate']
    ? errors.licenseEndDate
    : `The date that the user's account gets automatically removed from the organization`;

  return (
    <ProfileFormLicenseDatePickerContainer>
      <ThemeProvider theme={DatePickerTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            id="licenseStartDate"
            name="licenseStartDate"
            disabled
            disableToolbar
            variant="inline"
            label="License Start Date"
            helperText="The date the user's account becomes active in your organization"
            value={values.licenseStartDate}
            InputLabelProps={{
              shrink: true,
            }}
            format="MM/DD/yyyy"
            onChange={() => null}
          />
          <KeyboardDatePicker
            id="licenseEndDate"
            name="licenseEndDate"
            disableToolbar
            autoOk
            placeholder="MM/DD/YYYY"
            variant="inline"
            disabled={isDisabled}
            label="License End Date (optional)"
            helperText={endDateHelperText}
            InputLabelProps={{
              shrink: true,
            }}
            error={isDisabled ? false : !!errors['licenseEndDate']}
            value={values.licenseEndDate}
            format="MM/DD/yyyy"
            onError={handleEndDateError}
            minDate={moment().add(1, 'days')}
            onChange={(date) => handleEndDateChange(date)}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ProfileFormLicenseDatePickerContainer>
  );
};

export default ProfileFormLicenseDatePicker;
