import styled from 'styled-components';
import AppTheme from 'src/assets/styles/theme';
import { SecondaryButton } from 'src/components/shared/HypercareComponents';

interface RoleHolderStyledComponentProps {
  assignedColor: string;
  assignedColorBackground: string;
  shiftOrder?: any;
}

interface RequiredShiftGapHolderStyledComponentPorps {
  isRequired: boolean;
  shiftOrder: any;
}

export const scrollIndicatorHeight = `4px`;
export const cellWidth = `200px`;
export const cellGap = `1em`;
export const dateFieldWidth = `130px`;

const borderBottomColor = AppTheme.borderBottomLight;
const mainTealColor = AppTheme.mainTealColor;
const darkenFontColor = AppTheme.darkenFontColor;
const mainBackgroundColor = AppTheme.mainBackgroundColor;
const lightGrey = AppTheme.lightGrey;
const rowMinHeight = `100px`;
const gapBottomMargin = '8px';

interface GridProps {
  gridBodyWidth: string;
}

interface RoleBodyProps {
  isHoliday?: boolean;
}

// doc: https://www.notion.so/Scheduling-0d37f6f90d694f32aaa2deb2bf5df635
export const allowedSchedulingCellBackgroundColors = [
  '#B6BADB',
  '#DDE862',
  '#47C9F2',
  '#FFE498',
  '#FFBEB7',
  '#4B65E4',
  '#7BD77B',
  '#27E1B5',
  '#FFBF0C',
  '#FF364B',
  '#864D9D',
  '#0B664B',
  '#007A8F',
  '#FF984E',
  '#E15599',
  '#2A2893',
  '#99AA6A',
  '#134C76',
  '#C3291C',
  '#8B3C23',
];

export const GridScrollProgressIndicatorWrapper = styled.div`
  position: sticky;
  top: 0;
  height: ${scrollIndicatorHeight};
  width: 100%;
  background-color: ${mainBackgroundColor};
  z-index: 1;
`;

export const GridScrollProgressIndicator = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 150, 137, max(var(--scroll), 30%)) var(--scroll), transparent 0);
  z-index: 1;
`;

export const GridSettings = styled.div`
  margin-top: 20px;
`;

export const GridContainer = styled.div`
  display: grid;
  cursor: grab;
  user-select: none;
  max-width: 100%;
  margin-bottom: 30px;
  grid-template-columns: ${dateFieldWidth} auto;
  grid-template-areas:
    'role-header role-header'
    'role-body role-body';
  position: relative;
  z-index: 1;
`;

export const GapHoverOverlay = styled.div`
  display: none;
`;

export const GapInfo = styled.div`
  font-weight: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5em;
  span {
    width: 100%;
    display: block;
  }
`;

export const RequiredShiftGapHolder = styled.div<RequiredShiftGapHolderStyledComponentPorps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(60px - 1.5px - 1.5px);
  max-height: 60px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  order: ${(props) => props.shiftOrder};
  border: 1.5px solid ${(props) => (props.isRequired ? 'red' : 'grey')};
  color: ${(props) => (props.isRequired ? 'red' : 'grey')};
  margin-bottom: ${gapBottomMargin};
  &:hover {
    border-color: transparent;
    ${GapInfo} {
      display: none !important;
    }
    ${GapHoverOverlay} {
      display: flex !important;
      background-color: lightgrey;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
      color: black;
      font-weight: bold;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 1.5px solid ${(props) => (props.isRequired ? 'red' : 'grey')};
    }
  }
`;

export const HiddenEditRoleHolder = styled.div`
  visibility: hidden;
  order: 10000; // order is based on HH start time, let hidden role holder be the last
  opacity: 0;
  height: 60px;
  padding: ${`calc(${gapBottomMargin} / 2)`};
  transition: opacity 0.2s, visibility 0.2s;
  div {
    height: 40px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    border-style: dashed;
    border-width: 1.5px;
    border-color: grey;
    color: grey;
    line-height: 40px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      i {
        font-size: 13px;
        padding-right: 3px;
      }
    }
  }
`;

/** Role Cell Holder and Assignee Details */

export const RoleHolder = styled.div`
  color: ${darkenFontColor};
  width: ${cellWidth};
  display: flex;
  flex-direction: column;
  min-height: ${rowMinHeight};
  &:hover {
    ${HiddenEditRoleHolder} {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
`;

export const RoleHolderDetailTime = styled.div`
  font-size: 12px;
  letter-spacing: normal;
  color: ${darkenFontColor};
`;

export const RoleHolderDetailTitle = styled.div`
  padding-bottom: 8px;
  font-size: 13px;
  font-family: Nunito;
  font-weight: bold;
  overflow-wrap: break-word;
  line-height: 1.23;
  letter-spacing: normal;
  width: 100%;
  color: ${darkenFontColor};
`;

export const RoleHolderDetail = styled.div`
  margin-left: 8px;
  width: 70%;
`;

export const RoleHolderWrapper = styled.div<RoleHolderStyledComponentProps>`
  padding: 10px 5px 10px 15px;
  cursor: pointer;
  min-height: calc(60px - 10px - 10px);
  margin-bottom: ${gapBottomMargin};
  background-color: ${(props) => props.assignedColorBackground};
  border-left: 5px solid ${(props) => props.assignedColor};
  display: flex;
  align-items: center;
  order: ${(props) => props.shiftOrder};
`;

/** Body Area */

export const GridBodyArea = styled.div`
  grid-area: role-body;
  display: grid;
  overflow-x: scroll;
  overflow-y: hidden;
  min-width: 100%;
  position: relative;
  padding-bottom: 20px;

  .jsHover {
    background-color: lightgrey !important;
    border-left: 5px solid black !important;
    ${RoleHolderDetailTitle},
    ${RoleHolderDetailTime} {
      background-color: lightgrey !important;
    }
  }
`;

export const GridBodyRowRole = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${cellGap};
  padding-left: ${cellGap};
`;

export const GridBodyRowDate = styled.div<RoleBodyProps>`
  height: 100%;
  min-height: ${rowMinHeight};
  width: ${dateFieldWidth};
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  text-align: center;
  background-color: ${(props) => (props.isHoliday ? lightGrey : mainBackgroundColor)};
  z-index: 1;
`;

export const RowDateHolder = styled.div`
  margin-top: 5px;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  overflow-wrap: break-word;
  font-size: 14px;
  color: ${darkenFontColor};
`;

export const BoldDate = styled.div`
  font-family: Nunito;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 600;
`;

export const GridBodyRow = styled.div<RoleBodyProps>`
  display: grid;
  font-family: Nunito;
  position: relative;
  grid-template-columns: ${dateFieldWidth} 1px auto;
  border-bottom: 0.5px solid ${borderBottomColor};
  min-width: 100%;
  padding-bottom: ${cellGap};
  padding-top: ${cellGap};
  background-color: ${(props) => (props.isHoliday ? lightGrey : '')};

  &:hover ${GridBodyRowDate} ${RowDateHolder} {
    color: ${mainTealColor} !important;
    font-weight: bold;
    font-size: 16px;
  }

  .popper__container {
    z-index: 9;
    .popper__content__wrapper {
      cursor: pointer !important;
      ul {
        padding-top: 0;
        padding-bottom: 0;
      }
      li {
        font-size: 15px;
        line-height: 2em;
        font-family: 'Open Sans';
      }
    }
  }
`;

/** Header Area */

export const GridHeaderArea = styled.div`
  grid-area: role-header;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: auto;
  background-color: ${mainBackgroundColor};
  border-bottom: 1px solid ${borderBottomColor};
`;

export const GridHeader = styled.div<GridProps>`
  display: grid;
  min-width: 100vw;
  padding-left: calc(${dateFieldWidth} + ${cellGap});
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: ${cellGap};
  width: ${(props) => props.gridBodyWidth};
  z-index: 2;
`;

export const GridHeaderRoleName = styled.div`
  padding: 0 10px;
  font-size: 16px;
  font-family: Nunito;
  font-weight: bold;
  text-align: center;
  color: ${darkenFontColor};
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const GridHeaderRepeatRule = styled.div`
  color: dimgrey;
  font-size: 14px;
`;

export const GridHeaderNameHolder = styled.div`
  color: black;
  width: ${cellWidth};
  text-align: center;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  &:hover {
    * {
      color: ${mainTealColor};
    }
  }
`;

export const AlertIconWrapper = styled.div`
  align-self: flex-start;
  margin-top: -5px;
  display: flex;
  svg:first-child {
    margin-right: 4px;
  }
`;

export const ConflictWarningCount = styled.div`
  font-family: 'Nunito Sans' 'Open Sans' !important;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  padding: 8px 12px;
`;

export const ConflictAndWarningContainer = styled.div`
  padding: 8px 12px;
  max-height: 40vh;
  overflow: scroll;
`;

export const ConflictAndWarningHeader = styled.div`
  display: flex;
  margin-bottom: 4px;
  span {
    margin-left: 8px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const UnorderedList = styled.ul`
  margin-top: 4px;
  font-size: 14px !important;
  font-family: 'Open sans', sans-serif !important;
  padding-inline-start: 25px;
  margin-bottom: 4px;
  li {
    line-height: 20px !important;
    list-style-type: disc;
    font-size: 14px !important;
  }
`;

export const StyledPlusOneContainer = styled.sup<any>`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: ${(props) => (props.color ? props.color : '#767676')};
`;

export const GridBody = styled.div`
  .popper__container {
    z-index: 9;
    .popper__content__wrapper {
      cursor: pointer !important;
      ul {
        padding-top: 0;
        padding-bottom: 0;
      }
      li {
        font-size: 15px;
        line-height: 2em;
        font-family: 'Open Sans';
      }
    }
  }
`;

export const HiddenEditRoleHolderOvernight = styled.div<any>`
  visibility: hidden;
  order: ${(props) => props.shiftOrder};
  opacity: 1;
  height: 60px;
  padding: ${`calc(${gapBottomMargin} / 2)`};
  transition: opacity 0.2s, visibility 0.2s;
  div {
    height: 54px;
    width: 100%;
    text-align: center;
    border: 1px dashed grey;
    border-radius: 4px;
    color: grey;
    line-height: 40px;
    span {
      margin-bottom: -22px;
      margin-top: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 600;
      i {
        font-size: 13px;
        padding-right: 3px;
      }
    }
  }
`;

export const RequiredShiftGapHolderOvernight = styled.div<RequiredShiftGapHolderStyledComponentPorps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(60px - 1.5px - 1.5px);
  max-height: 60px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  order: ${(props) => props.shiftOrder};
  border: 1.5px solid ${(props) => (props.isRequired ? 'red' : 'grey')};
  color: ${(props) => (props.isRequired ? 'red' : 'grey')};
  margin-bottom: ${gapBottomMargin};
  &:hover {
    border: 1px dashed red;
  }
`;

export const RoleHolderOvernight = styled.div`
  color: ${darkenFontColor};
  width: ${cellWidth};
  display: flex;
  flex-direction: column;
  min-height: ${rowMinHeight};
  &:hover {
    ${HiddenEditRoleHolderOvernight} {
      opacity: 1 !important;
      div {
        border: 1px dashed #00859a !important;
        background: #f6f6f9 !important;
      }
    }
  }
`;

export const HolidayDataHolder = styled.div`
  width: 161px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: ${mainTealColor};
  line-height: 20px;
  padding: 0px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RoleNoteGridHeader1 = styled.div`
  display: grid;
  min-width: 100vw;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 1em;
  width: calc(100px * 4 + 1em * 4 + 130px);
  z-index: 1;
`;

export const GridHeaderRoleNotesTitle = styled.div`
  width: 130px;
  text-align: center;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
`;

export const RoleNoteGridHeader = styled.div<GridProps>`
  display: grid;
  min-width: 100vw;
  border-top: 1px solid #d8d8d8;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 1em;
  width: ${(props) => props.gridBodyWidth};
  z-index: 1;
  background-color: white;
  height: 100px;
`;

export const GridHeaderRoleNotes = styled.div`
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: ${cellWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  height: 5.9em; /* Approximate height for four lines */
  gap: 2px;
  flex-direction: column;
  overflow-wrap: break-word;
`;

export const AddRoleNoteButtonContainer = styled.div`
  width: ${cellWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: 0;
`;

export const RoleNoteModalContainer = styled.div`
  width: 320px;
  padding: 24px 16px 12px 16px;
`;

export const RoleNoteModalTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
`;

export const RoleNoteModalBody = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 20px;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  margin: 12px 8px;
  webkit-line-clamp: 8;
  line-clamp: 8;
  webkit-box-orient: vertical;
  gap: 15px;
  overflow-wrap: break-word;
  padding-right: 10px;
`;

export const AddEditRoleNoteButton = styled(SecondaryButton)`
  margin-top: 12px !important;
  border: none !important;
  padding: 8px 0 8px 0;
`;
