import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import store from 'src/redux/store';
import theme from 'src/assets/styles/theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';

const SaveIndicator = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  text-align: left;
  padding-bottom: 12px;
  color: ${theme.mainFontColor};
  span {
    padding-left: 4px;
  }
`;

const SubHeaderSavingIndicator = () => {
  const [lastUpdatedAtTimeFromNow, setLastUpdatedAtTimeFromNow] = React.useState<string>(null);
  const [isSaving, setSaving] = React.useState<boolean>(false);
  const timerIntervalRef = React.useRef(null);

  // TODO: implement actual saving indication
  const fakeSavingTimeoutRef = React.useRef(null);

  const lastUpdatedAt = typedUseSelector((state) => state.monthlyScheduleReducer.lastUpdatedAt);
  const dispatch = useDispatch();
  const setLastUpdatedAt = (lastUpdatedAt: string) =>
    dispatch(allActions.monthlyScheduleAction.setLastUpdateAt({ lastUpdatedAt }));

  const clearTimerInterval = () => clearInterval(timerIntervalRef.current);
  const clearSavingTimeout = () => clearTimeout(fakeSavingTimeoutRef.current);

  React.useEffect(() => {
    return () => {
      if (store.getState().monthlyScheduleReducer.lastUpdatedAt) setLastUpdatedAt(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (lastUpdatedAt) {
      clearInterval(timerIntervalRef.current);
      setLastUpdatedAtTimeFromNow(moment(lastUpdatedAt).fromNow());
      setSaving(true);

      if (timerIntervalRef.current) clearTimerInterval();
      if (fakeSavingTimeoutRef.current) clearSavingTimeout();

      fakeSavingTimeoutRef.current = setTimeout(() => {
        setSaving(false);
      }, 1000); // fake indicator last for 1sec

      timerIntervalRef.current = setInterval(() => {
        setLastUpdatedAtTimeFromNow(moment(lastUpdatedAt).fromNow());
      }, 60000); // update the state per minute
    } else {
      clearTimerInterval();
    }
  }, [lastUpdatedAt]);

  if (lastUpdatedAtTimeFromNow) {
    return (
      <SaveIndicator>
        {!isSaving ? <span>Changes saved. Last updated {lastUpdatedAtTimeFromNow}</span> : <span>saving...</span>}
        {!isSaving ? <span className="material-icons">done</span> : <CircularProgress size={16} color={'inherit'} />}
      </SaveIndicator>
    );
  }

  return null;
};

export default SubHeaderSavingIndicator;
