import React from 'react';

type WarningConeIconProp = {
  width?: number;
  height?: number;
};

export default (props: WarningConeIconProp) => (
  <svg
    width={props.width || 30}
    height={props.height || 30}
    viewBox="0 0 38 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.15 2.43331L2.03331 26C1.74225 26.504 1.58825 27.0755 1.58662 27.6575C1.58499 28.2395 1.73579 28.8119 2.02402 29.3175C2.31224 29.8232 2.72785 30.2446 3.22949 30.5397C3.73112 30.8349 4.30131 30.9936 4.88331 31H33.1166C33.6986 30.9936 34.2688 30.8349 34.7705 30.5397C35.2721 30.2446 35.6877 29.8232 35.9759 29.3175C36.2642 28.8119 36.415 28.2395 36.4133 27.6575C36.4117 27.0755 36.2577 26.504 35.9666 26L21.85 2.43331C21.5529 1.94349 21.1345 1.53851 20.6353 1.25745C20.1361 0.976389 19.5729 0.828735 19 0.828735C18.4271 0.828735 17.8639 0.976389 17.3646 1.25745C16.8654 1.53851 16.4471 1.94349 16.15 2.43331Z"
      fill="#FFC705"
      stroke="#ECAD0B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19 11V17.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 24.3334H19.0167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
