import gql from 'graphql-tag';
import { roleFragmentStringWithoutShifts } from 'src/gql/fragment/RoleFragment';

export default gql`
  mutation CreateRole(
    $name: String!,
    $startTime: String!,
    $duration: Int!, #seconds
    $required: Boolean!, #default = false
    $departmentId: Int!,
    $repeatRule: RoleRepeatRule!,
  ) {
    admin {
      locating {
        department(id: $departmentId) {
          createRole(
            details: {
              name: $name,
              startTime: $startTime,
              duration: $duration,
              required: $required,
              repeatRule: $repeatRule,
            } #CreateRoleDetails
          ) {
            ${roleFragmentStringWithoutShifts}
          } 
        }
      }
    }
  }
`;
