import {
  SET_ADD_ROLE_NOTE,
  SET_ADD_ROLE_TO_ROLE_NOTES,
  SET_CLEAR_ROLE_NOTES,
  SET_CURRENT_SELECTED_ROLE,
  SET_DELETE_ROLE_NOTE,
  SET_LAST_UPDATED_AT,
  SET_ROLE_NOTE_MODAL_VISIBILITY,
  SET_ROLE_NOTES,
  SET_ROLES_AND_SHIFTS,
  SET_SCHEDULE_DATE,
  SET_SCHEDULE_ID,
  SET_SHOULD_SHOW_GAP,
  SET_TOTAL_CONFLICTS_WARNINGS,
  SET_UPDATE_ROLE_NOTE,
  SET_USER_COLOR,
} from 'src/constants/actionNames';
import { HiddenNotes, SetRolesAndShiftsActionPayload } from 'src/types';

interface SetRoleAndShiftsAction {
  type: typeof SET_ROLES_AND_SHIFTS;
  payload: SetRolesAndShiftsActionPayload;
}

interface SetUserColorAction {
  type: typeof SET_USER_COLOR;
  payload: { userIdSet: Set<string> };
}

interface SetLastUpdateAtAction {
  type: typeof SET_LAST_UPDATED_AT;
  payload: SetLastUpdateAtActionPayload;
}

interface SetScheduleRefetchDateAction {
  type: typeof SET_SCHEDULE_DATE;
  payload: SetScheduleRefetchDatePayload;
}

interface SetScheduleShowGapAction {
  type: typeof SET_SHOULD_SHOW_GAP;
  payload: SetScheduleShowGapPayload;
}

interface SetScheduleIdAction {
  type: typeof SET_SCHEDULE_ID;
  payload: setScheduleIdPayload;
}

interface SetRoleNotesAction {
  type: typeof SET_ROLE_NOTES;
  payload: setRoleNotesPayload;
}

interface SetAddRoleNoteAction {
  type: typeof SET_ADD_ROLE_NOTE;
  payload: setAddRoleNotePayload;
}

interface SetAddRoleToRoleNotesAction {
  type: typeof SET_ADD_ROLE_TO_ROLE_NOTES;
  payload: setAddRoleToRoleNotesPayload;
}

interface SetDeleteRoleNoteAction {
  type: typeof SET_DELETE_ROLE_NOTE;
  payload: setDeleteRoleNotePayload;
}

interface SetUpdateRoleNoteAction {
  type: typeof SET_UPDATE_ROLE_NOTE;
  payload: setUpdateRoleNotePayload;
}

interface SetRoleNoteModalVisibilityAction {
  type: typeof SET_ROLE_NOTE_MODAL_VISIBILITY;
  payload: setRoleNoteModalVisibilityPayload;
}

interface SetCurrentRoleSelectedAction {
  type: typeof SET_CURRENT_SELECTED_ROLE;
  payload: setCurrentRoleSelectedPayload;
}

interface SetClearRoleNotesAction {
  type: typeof SET_CLEAR_ROLE_NOTES;
  payload: {};
}

interface setCurrentRoleSelectedPayload {
  roleId: number;
  roleName: string;
}

interface setRoleNoteModalVisibilityPayload {
  visibility: boolean;
}

interface setDeleteRoleNotePayload {
  roleId: number;
  deletedNoteId: string;
}

interface setUpdateRoleNotePayload {
  roleId: number;
  updatedNoteObject: HiddenNotes;
}

interface setAddRoleNotePayload {
  note: HiddenNotes;
  id: string;
}

interface setAddRoleToRoleNotesPayload {
  id: string;
  roleName: string;
}
interface SetLastUpdateAtActionPayload {
  lastUpdatedAt: string;
}

interface SetTotalConflictAndWarningPayload {
  numberOfConflicts: number;
  numberOfWarnings: number;
}

interface SetScheduleRefetchDatePayload {
  startDate: string;
  endDate: string;
}

interface SetScheduleShowGapPayload {
  shouldShowGap: boolean;
}

interface setScheduleIdPayload {
  scheduleId: number;
}

interface setRoleNotesPayload {
  [key: string]: {
    notes: HiddenNotes[];
    roleName: string;
  };
}

interface SetTotalConflictAndWarningAction {
  type: typeof SET_TOTAL_CONFLICTS_WARNINGS;
  payload: SetTotalConflictAndWarningPayload;
}

export type MonthlyScheduleActionTypes =
  | SetRoleAndShiftsAction
  | SetLastUpdateAtAction
  | SetUserColorAction
  | SetScheduleShowGapAction
  | SetScheduleRefetchDateAction
  | SetTotalConflictAndWarningAction
  | SetScheduleIdAction
  | SetRoleNotesAction
  | SetAddRoleNoteAction
  | SetDeleteRoleNoteAction
  | SetUpdateRoleNoteAction
  | SetRoleNoteModalVisibilityAction
  | SetCurrentRoleSelectedAction
  | SetAddRoleToRoleNotesAction
  | SetClearRoleNotesAction;

export const setRoleAndShifts = (payload: SetRolesAndShiftsActionPayload): SetRoleAndShiftsAction => {
  return {
    type: SET_ROLES_AND_SHIFTS,
    payload,
  };
};

export const setNumberOfConflictAndWarnings = (
  payload: SetTotalConflictAndWarningPayload,
): SetTotalConflictAndWarningAction => {
  return {
    type: SET_TOTAL_CONFLICTS_WARNINGS,
    payload,
  };
};

export const setLastUpdateAt = (payload: SetLastUpdateAtActionPayload): SetLastUpdateAtAction => {
  return {
    type: SET_LAST_UPDATED_AT,
    payload,
  };
};

export const setScheduleRefetchDate = (payload: SetScheduleRefetchDatePayload): SetScheduleRefetchDateAction => {
  return {
    type: SET_SCHEDULE_DATE,
    payload,
  };
};

export const setUserColor = (payload: { userIdSet: Set<string> }): SetUserColorAction => {
  return {
    type: SET_USER_COLOR,
    payload,
  };
};

export const setScheduleShowGap = (payload: { shouldShowGap: boolean }): SetScheduleShowGapAction => {
  return {
    type: SET_SHOULD_SHOW_GAP,
    payload,
  };
};

export const setScheduleId = (payload: setScheduleIdPayload): SetScheduleIdAction => {
  return {
    type: SET_SCHEDULE_ID,
    payload,
  };
};

export const setRoleNotes = (payload: setRoleNotesPayload): SetRoleNotesAction => {
  return {
    type: SET_ROLE_NOTES,
    payload,
  };
};

export const setAddRoleToRoleNotes = (payload: setAddRoleToRoleNotesPayload): SetAddRoleToRoleNotesAction => {
  return {
    type: SET_ADD_ROLE_TO_ROLE_NOTES,
    payload,
  };
};

export const setAddRoleNote = (payload: setAddRoleNotePayload): SetAddRoleNoteAction => {
  return {
    type: SET_ADD_ROLE_NOTE,
    payload,
  };
};

export const setDeleteRoleNote = (payload: setDeleteRoleNotePayload): SetDeleteRoleNoteAction => {
  return {
    type: SET_DELETE_ROLE_NOTE,
    payload,
  };
};

export const setUpdateRoleNote = (payload: setUpdateRoleNotePayload): SetUpdateRoleNoteAction => {
  return {
    type: SET_UPDATE_ROLE_NOTE,
    payload,
  };
};

export const setRoleNoteModalVisibility = (
  payload: setRoleNoteModalVisibilityPayload,
): SetRoleNoteModalVisibilityAction => {
  return {
    type: SET_ROLE_NOTE_MODAL_VISIBILITY,
    payload,
  };
};

export const setCurrentRoleSelected = (payload: setCurrentRoleSelectedPayload): SetCurrentRoleSelectedAction => {
  return {
    type: SET_CURRENT_SELECTED_ROLE,
    payload,
  };
};

export const setClearRoleNotes = (payload: {}): SetClearRoleNotesAction => {
  return {
    type: SET_CLEAR_ROLE_NOTES,
    payload,
  };
};
