import { MemberAnalyticsReportPayload, MemberAnalyticsReportResponse } from '../../types';
import { GET_MEMBERS } from '../../gql/query/AnalyticsQuery';
import client from '../../clients/apolloClient';
import { ApolloQueryResult } from 'apollo-client';
export const AnalyticsRepository = () => {
  const fetchMembersAnalyticsReport = async ({
    departmentId,
    organizationId,
    siteId,
    startDate,
    endDate,
  }): Promise<ApolloQueryResult<MemberAnalyticsReportResponse>> => {
    const result = await client.query<MemberAnalyticsReportResponse, MemberAnalyticsReportPayload>({
      query: GET_MEMBERS,
      variables: {
        departmentId,
        organizationId,
        siteId,
        startDate,
        endDate,
      },
      errorPolicy: 'ignore',
    });
    return result;
  };

  return {
    fetchMembersAnalyticsReport,
  };
};
