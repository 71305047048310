import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 40 40" id="svg8" {...props}>
    <g fill="none" fillRule="evenodd" id="g6" strokeWidth="2.5">
      <circle cx="20" cy="20" r="20" fill={props.circlefill || '#ffc705'} id="circle2" />
      <g id="g822" transform="translate(-.508 -.508)">
        <path
          id="path4"
          d="m 18.980983,28 h -0.02 c -0.354,-0.01 -0.632,-0.262 -0.622,-0.565 l 0.31,-8.903 c 0.011,-0.296 0.295,-0.532 0.642,-0.532 h 2.096 c 0.347,0 0.631,0.236 0.641,0.532 l 0.31,8.903 c 10e-4,0.32 -0.286,0.565 -0.64,0.565 z m 1.358,-16 c 0.631,0 1.11,0.176 1.459,0.522 0.357,0.354 0.541,0.842 0.541,1.472 0,0.647 -0.185,1.14 -0.54,1.49 -0.35,0.341 -0.828,0.516 -1.46,0.516 -0.623,0 -1.101,-0.174 -1.454,-0.517 -0.36,-0.349 -0.546,-0.842 -0.546,-1.49 0,-0.629 0.186,-1.117 0.547,-1.47 0.353,-0.347 0.83,-0.523 1.453,-0.523 z"
          fill={props.strokefill || '#2b2b2b'}
          fillRule="nonzero"
          stroke={props.stroke || '#ffc705'}
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
);
