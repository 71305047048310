/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

interface department {
  id: number;
  name: string;
}
interface MultiSelectProps {
  noOptionsText: string;
  items: department[];
  selectedValues: department[];
  selectAllLabel: string;
  placeHolder: string;
  limitTags: number;
  onToggleOption: any;
  onClearOptions: () => void;
  onSelectAll: (boolean) => void;
  getOptionLabel: (string) => string;
}
const MultiSelect = ({
  items,
  selectedValues,
  selectAllLabel,
  noOptionsText,
  limitTags,
  placeHolder,
  onToggleOption,
  onClearOptions,
  onSelectAll,
  getOptionLabel,
}: MultiSelectProps) => {
  const allSelected = items.length === selectedValues.length;
  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected);
  };

  const handleChange = async (
    event: React.ChangeEvent<{}>,
    selectedOptions: department[],
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (selectedOptions.find((option) => option.id === 999)) {
        handleToggleSelectAll();
      } else {
        onToggleOption && onToggleOption(selectedOptions);
      }
    } else if (reason === 'clear') {
      onClearOptions && onClearOptions();
    }
  };
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.id === 999 // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <React.Fragment>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </React.Fragment>
    );
  };
  const inputRenderer = (params) => <TextField {...params} placeholder={placeHolder} variant="outlined" />;
  const getOptionSelected = (option, anotherOption) => option.id === anotherOption.id;
  const filter = createFilterOptions();
  return (
    <Autocomplete
      noOptionsText={noOptionsText}
      multiple
      size="small"
      limitTags={limitTags}
      options={items}
      value={selectedValues}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ name: selectAllLabel, id: 999 }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            deleteIcon={<CloseIcon fontSize="small" />}
            variant="outlined"
            color="primary"
            label={option.name}
            size="small"
            style={{ border: '1px solid #D8D8D8', borderRadius: '4px' }}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

MultiSelect.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value,
};

export default MultiSelect;
