import gql from 'graphql-tag';

export const DELETE_SELF_PROFILE_NOTE = gql`
  mutation DeleteSelfProfileNote($id: ID!) {
    selfMutation {
      note(id: $id) {
        __typename
        ... on AccessForbiddenError {
          __typename
          message
        }
        ... on NoteNotFoundError {
          __typename
          message
        }
        ... on NoteDeletedError {
          __typename
          message
        }
        ... on ProfileNoteMutation {
          deleteNote {
            ... on ProfileNote {
              __typename
              id
            }
          }
        }
      }
    }
  }
`;
