import React, { Component } from 'react';
import { Dialog, ThemeProvider, IconButton } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import ScheduleInputCalendarForm from 'src/components/forms/ScheduleInputCalendarForm';
import ScheduleInputMultiUserForm from 'src/components/forms/ScheduleInputMultiUserForm';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import client from 'src/clients/apolloClient';
import store, { RootState } from 'src/redux/store';
import CreateShiftMutation from 'src/gql/mutation/CreateShiftMutation';
import UpdateShiftMutation from 'src/gql/mutation/UpdateShiftMutation';
import DeleteShiftMutation from 'src/gql/mutation/DeleteShiftMutation';
import clonedeep from 'lodash.clonedeep';
import RoleFragment from 'src/gql/fragment/RoleFragment';
import ShiftFragment from 'src/gql/fragment/ShiftFragment';
import getCachedRoleById from 'src/utils/schedulingHelper/getCachedRoleById';
import sleep from 'src/utils/sleep';
import { Role, Assignee, ScheduleModalPayload, ShiftResultInfo, RoleResultInfo } from 'src/types';
import allActions from 'src/redux/actions';
import { CANT_MUTATE_ARCHIVED_SHIFT, CANT_MUTATE_PAST_SHIFT } from 'src/constants/networkError';
import { Transition, DialogTitleTypography } from 'src/components/shared/HypercareComponents';
import CloseMark from 'src/assets/svgs/CloseMark';
import Apptheme from 'src/assets/styles/theme';
import { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { TIMER } from 'src/constants/inviteUserTypes';
import CustomToaster from 'src/components/CustomToaster';
import CheckSuccess from 'src/assets/svgs/CheckSuccess';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

interface Props {
  handleCloseModal: () => void;
  showModal: boolean;
  hasCalendar: boolean;
  payload: ScheduleModalPayload | null; // exist on shift click
  flags?: any;
}

interface SingleAssigneeFormValue {
  endTime: moment.Moment;
  startTime: moment.Moment;
  roleIndex: number;
  selectedDates: moment.Moment[];
  userId: string;
  userFullName: string;
}

interface MultiAssigneeFormValue {
  assignee: Assignee[];
  roleIndex: number;
  roleName: string;
  selectedDates: moment.Moment[]; //length will be always1
}

class InputScheduleModal extends Component<Props> {
  public handleModalFormSingleAssigneeSubmission = (formValue: SingleAssigneeFormValue) => {
    const { selectedDates, roleIndex, userId, startTime, endTime } = formValue;
    const { roleContainer, scheduleId } = store.getState().monthlyScheduleReducer;
    const { department_id } = store.getState().organizationReducer;
    const { roleId } = roleContainer[roleIndex];

    return new Promise(async (resolve, reject) => {
      try {
        const addShiftByDate = async (selectedDate: moment.Moment) => {
          let selectedStartTime: moment.Moment = clonedeep(startTime);
          let selectedEndTime: moment.Moment = clonedeep(endTime);

          // selectedStartTime has arbitrary month year and date, use selected one
          selectedStartTime.set('month', selectedDate.month());
          selectedStartTime.set('year', selectedDate.year());
          selectedStartTime.set('date', selectedDate.date());
          selectedEndTime.set('month', selectedDate.month());
          selectedEndTime.set('year', selectedDate.year());
          selectedEndTime.set('date', selectedDate.date());
          if (selectedEndTime <= selectedStartTime) {
            selectedEndTime.add(1, 'd');
          }
          try {
            // let randomCrush = Math.round(Math.random())
            // if (randomCrush) throw 'ignore random crush'
            await client.mutate({
              mutation: CreateShiftMutation,
              variables: {
                roleId: parseInt(roleId),
                userId,
                scheduleId,
                departmentId: department_id,
                startTime: selectedStartTime.toISOString(),
                endTime: selectedEndTime.toISOString(),
              },
            });

            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.addNewSchedule,
              params: {
                role_id: roleId,
                user_id: userId,
                schedule_id: scheduleId,
                department_id: department_id,
                start_date: selectedStartTime.toISOString(),
                end_date: selectedEndTime.toISOString(),
              },
            });

            toast(<CustomToaster logo={<CheckSuccess />} body={`Successfully added multiple shifts.`} />, {
              autoClose: TIMER,
              className: 'successToastr',
              toastId: 'multipleShiftAddedToast',
            });
          } catch (e) {
            console.error(e);
            if (e.graphQLErrors && e.graphQLErrors[0]?.code === METRICAIDERRORCODE) {
              return Promise.reject(METRICAIDERRORCODE);
            } else {
              const failedDate = selectedDate.format('YYYY-MM-DD');
              toast.error(`Failed to add schedule at ${failedDate}.`, {
                className: 'Toast-Container',
                autoClose: false,
              });
            }
          }
          return Promise.resolve('done');
        };

        await Promise.all(selectedDates.map((selectedDate) => addShiftByDate(selectedDate)));
        // TODO: deletion performance bug, ideally save succeeded shifts and adding to cache at once
        await sleep(500);
        await client.reFetchObservableQueries();

        this.dispatchLastUpdatedAt();

        resolve('done!');
        this.props.handleCloseModal();
      } catch (e) {
        console.error(e);
        if (e === METRICAIDERRORCODE) reject(METRICAIDERRORCODE);
        reject('network error');
      }
    });
  };

  // TODO-BUG: https://github.com/apollographql/apollo-feature-requests/issues/4
  public handleDeleteShifts = async (selectedShifts: Assignee[]) => {
    const { scheduleId } = store.getState().monthlyScheduleReducer;
    const { department_id } = store.getState().organizationReducer;
    let realShiftOccurred = false;

    const deleteShift = async (shift: Assignee) => {
      const { shiftId, userFullName } = shift;
      if (!Boolean(shiftId)) return Promise.resolve('no such shift');
      try {
        // let randomCrush = Math.round(Math.random())
        // if (randomCrush) throw 'ignore random crush'
        await client.mutate({
          mutation: DeleteShiftMutation,
          variables: {
            departmentId: department_id,
            scheduleId,
            shiftId: shiftId,
          },
        });
        realShiftOccurred = true;
        return Promise.resolve('done');
      } catch (e) {
        console.error(e);
        // TODO: backend needs to resolve with 500, currently still a 200 status thus gqlError
        if (
          e.graphQLErrors &&
          e.graphQLErrors[0] &&
          (e.graphQLErrors[0].code === CANT_MUTATE_PAST_SHIFT || e.graphQLErrors[0].code === CANT_MUTATE_ARCHIVED_SHIFT)
        ) {
          await client.reFetchObservableQueries();
          toast.error('Unable to delete passed or archived shift.', {
            className: 'Toast-Container',
          });
        } else {
          toast.error(`Failed to delete shift for ${userFullName}`, {
            className: 'Toast-Container',
            autoClose: false,
          });
        }
        return Promise.reject(e);
      }
    };
    await Promise.all(selectedShifts.map((shift) => deleteShift(shift)));
    // TODO: performance bug: only query roles and shifts not all ObservableQueries
    if (realShiftOccurred) {
      await sleep(500);
      await client.reFetchObservableQueries();
    }
  };

  public handleUpdateShifts = async (selectedShifts: Assignee[], role: Role) => {
    const { scheduleId, startDateISOstring, endDateISOstring } = store.getState().monthlyScheduleReducer;
    const { department_id } = store.getState().organizationReducer;
    const { roleId } = role;

    const updateShift = async (shift: Assignee) => {
      const { startTime, endTime, userId, userFullName } = shift;
      const currentShiftId = shift.shiftId;
      try {
        // let randomCrush = Math.round(Math.random())
        // if (randomCrush) throw 'ignore random crush'
        const result = await client.mutate({
          mutation: UpdateShiftMutation,
          variables: {
            departmentId: department_id,
            scheduleId,
            roleId: parseInt(roleId),
            shiftId: currentShiftId,
            shiftDetails: {
              endTime: endTime.toISOString(),
              startTime: startTime.toISOString(),
              userId,
            },
          },
        });
        const updatedShift = result.data.admin.locating.department.schedule.shift.update;
        // @2020-09-17 shifts and roles are now changed immutable obj, where each update will results in a different ID
        // no way to update existing cached-key in apollo, rewrite parent fragment instead
        const cachedRole = getCachedRoleById(roleId);
        const updatedShifts = cachedRole.shifts.map((shift) => (shift.id === currentShiftId ? updatedShift : shift));
        client.writeFragment({
          id: `Role:${roleId}`,
          fragment: RoleFragment,
          variables: {
            endDate: endDateISOstring,
            startDate: startDateISOstring,
          },
          fragmentName: 'RoleFragment',
          data: {
            ...cachedRole,
            shifts: updatedShifts,
          },
        });
        return Promise.resolve('done');
      } catch (e) {
        console.error(e);
        // TODO: backend needs to resolve with 500, currently still a 200 status thus gqlError
        if (
          e.graphQLErrors &&
          e.graphQLErrors[0] &&
          (e.graphQLErrors[0].code === CANT_MUTATE_PAST_SHIFT || e.graphQLErrors[0].code === CANT_MUTATE_ARCHIVED_SHIFT)
        ) {
          await client.reFetchObservableQueries();
          toast.error('Unable to update passed or archived shift.', {
            className: 'Toast-Container',
          });
        } else {
          toast.error(`Failed to update new shift for ${userFullName}`, {
            className: 'Toast-Container',
            autoClose: false,
          });
        }
        return Promise.reject(e);
      }
    };
    await Promise.all(selectedShifts.map((shift) => updateShift(shift)));
  };

  public handleAddNewShits = async (selectedShifts: Assignee[], role: Role) => {
    const { scheduleId } = store.getState().monthlyScheduleReducer;
    const { department_id } = store.getState().organizationReducer;
    const { roleId } = role;
    const addShift = async (shift: Assignee) => {
      const { startTime, endTime, userId, userFullName } = shift;
      let selectedStartTime: moment.Moment = clonedeep(startTime);
      let selectedEndTime: moment.Moment = clonedeep(endTime);

      try {
        // let randomCrush = Math.round(Math.random())
        // if (randomCrush) throw 'ignore random crush'
        const result = await client.mutate({
          mutation: CreateShiftMutation,
          variables: {
            roleId: parseInt(roleId),
            userId,
            scheduleId,
            departmentId: department_id,
            endTime: selectedEndTime.toISOString(),
            startTime: selectedStartTime.toISOString(),
          },
        });
        const newShift = result.data.admin.locating.department.schedule.createShift;
        const cachedRole = getCachedRoleById(roleId);
        const { startDateISOstring, endDateISOstring } = store.getState().monthlyScheduleReducer;

        client.writeFragment({
          id: `Role:${roleId}`,
          variables: {
            startDate: startDateISOstring,
            endDate: endDateISOstring,
          },
          fragment: RoleFragment,
          fragmentName: 'RoleFragment',
          data: {
            ...cachedRole,
            shifts: [...cachedRole.shifts, newShift],
          },
        });
        return Promise.resolve('done');
      } catch (e) {
        console.error(e);
        toast.error(`Failed to add new shift for ${userFullName}`, {
          className: 'Toast-Container',
          autoClose: false,
        });
        return Promise.reject(e);
      }
    };
    await Promise.all(selectedShifts.map((shift) => addShift(shift)));
  };

  public handleModalFormMultiAssigneeSubmission = async (
    formValue: MultiAssigneeFormValue,
    initialValues: MultiAssigneeFormValue,
    deletedAssignees: Assignee[],
  ) => {
    // @ts-ignore
    const { role } = this.props.payload;
    const updateAssignees: Assignee[] = [];
    const newAssignees: Assignee[] = [];

    const originalShifts: Assignee[] = initialValues.assignee;
    const updatedShifts: Assignee[] = formValue.assignee;

    updatedShifts.forEach((updatedShift) => {
      const originalShift = originalShifts.find((originShift) => updatedShift.shiftId === originShift.shiftId);
      // shifts that has been updated
      if (originalShift && originalShift.shiftId) {
        if (
          originalShift.startTime !== updatedShift.startTime ||
          originalShift.endTime !== updatedShift.endTime ||
          originalShift.userId !== updatedShift.userId
        ) {
          updateAssignees.push(updatedShift);
        }
      }
      // shifts that has been newly added
      if (!updatedShift.shiftId) {
        updatedShift.shiftId = '';
        newAssignees.push(updatedShift);
      }
    });

    try {
      await this.handleAddNewShits(newAssignees, role);
      await this.handleUpdateShifts(updateAssignees, role);
      await this.handleDeleteShifts(deletedAssignees);

      this.dispatchLastUpdatedAt();
      this.props.handleCloseModal();
      toast(<CustomToaster logo={<CheckSuccess />} body={`Shift successfully assigned.`} />, {
        autoClose: TIMER,
        className: 'successToastr',
      });
      return Promise.resolve('all done');
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  public getTargetShiftStartDateTime = (payload: ScheduleModalPayload, overnightShifts: boolean) => {
    const { role, date, targetAssignee, initialAssigneeGap } = payload;
    const momentedClickedDate: moment.Moment = moment(date, 'YYYY-MM-DD');

    const targetShift = targetAssignee
      ? client.readFragment({
          id: `Shift:${targetAssignee.shiftId}`,
          fragment: ShiftFragment,
          fragmentName: 'ShiftFragment',
        })
      : null;
    let searchingRoleStartTime;
    if (overnightShifts) {
      let currentShiftStartTime = targetShift
        ? moment(targetShift.startDate)
        : momentedClickedDate.set('hour', initialAssigneeGap?.startTime?.hour() || 0);

      if (currentShiftStartTime.hour() < role.startTime.hour()) {
        searchingRoleStartTime = currentShiftStartTime
          .clone()
          .subtract(1, 'day')
          .set('hour', role.startTime.hour())
          .set('minute', role.startTime.minute());
      } else if (role.endTime.hour() <= role.startTime.hour()) {
        searchingRoleStartTime = currentShiftStartTime
          .clone()
          .set('hour', role.startTime.hour())
          .set('minute', role.startTime.minute());
      } else {
        searchingRoleStartTime = currentShiftStartTime
          .clone()
          .set('hour', role.startTime.hour())
          .set('minute', role.startTime.minute());
      }
    }
    return searchingRoleStartTime;
  };

  public getShiftsFromDataCache = (payload: ScheduleModalPayload, overnightShifts: boolean) => {
    const { role, date, targetAssignee, initialAssigneeGap } = payload;
    const momentedClickedDate: moment.Moment = moment(date, 'YYYY-MM-DD');
    const cachedRole: RoleResultInfo = getCachedRoleById(role.roleId);
    const { shifts } = cachedRole;
    const targetShift = targetAssignee
      ? client.readFragment({
          id: `Shift:${targetAssignee.shiftId}`,
          fragment: ShiftFragment,
          fragmentName: 'ShiftFragment',
        })
      : null;
    let searchingRoleStartTime, searchingRoleEndTime;
    if (overnightShifts) {
      let currentShiftStartTime = targetShift
        ? moment(targetShift.startDate)
        : momentedClickedDate.set('hour', initialAssigneeGap?.startTime?.hour() || 0);

      if (currentShiftStartTime.hour() < role.startTime.hour()) {
        searchingRoleEndTime = currentShiftStartTime
          .clone()
          .set('hour', role.endTime.hour())
          .set('minute', role.endTime.minute());
        searchingRoleStartTime = currentShiftStartTime
          .clone()
          .subtract(1, 'day')
          .set('hour', role.startTime.hour())
          .set('minute', role.startTime.minute());
      } else if (role.endTime.hour() <= role.startTime.hour()) {
        searchingRoleStartTime = currentShiftStartTime
          .clone()
          .set('hour', role.startTime.hour())
          .set('minute', role.startTime.minute());
        searchingRoleEndTime = currentShiftStartTime
          .clone()
          .add(1, 'day')
          .set('hour', role.endTime.hour())
          .set('minute', role.endTime.minute());
      } else {
        searchingRoleStartTime = currentShiftStartTime
          .clone()
          .set('hour', role.startTime.hour())
          .set('minute', role.startTime.minute());
        searchingRoleEndTime = currentShiftStartTime
          .clone()
          .set('hour', role.endTime.hour())
          .set('minute', role.endTime.minute());
      }
    }
    let newShifts = shifts
      .filter((shift: ShiftResultInfo) => {
        const { startDate } = shift;
        // search base on startDate only
        if (overnightShifts) {
          searchingRoleEndTime = searchingRoleStartTime.clone().add(1, 'day');
          return moment(startDate).local().isBetween(searchingRoleStartTime, searchingRoleEndTime, undefined, '[)');
        } else {
          if (targetShift) {
            return moment(startDate).isSame(moment(targetShift.startDate), 'day');
          } else {
            return moment(startDate).isSame(momentedClickedDate, 'day');
          }
        }
      })
      .map((shift) => {
        const { startDate, endDate } = shift;
        return {
          endTime: moment(endDate),
          startTime: moment(startDate),
          shiftId: shift.id,
          userId: shift.user.id,
          userFullName: shift.user.firstname + ' ' + shift.user.lastname,
        } as Assignee;
      });
    if (overnightShifts && initialAssigneeGap) {
      newShifts.push(initialAssigneeGap);
    }

    return newShifts.sort(this.sortByStartTime);
  };

  sortByStartTime = (shiftA: Assignee, shiftB: Assignee) => {
    if (shiftA.startTime.isBefore(shiftB.startTime)) return -1;
    if (shiftA.startTime.isAfter(shiftB.startTime)) return 1;
    return 1;
  };

  public modalRoleStartingDateString = (payload: ScheduleModalPayload) => {
    const { date, targetAssignee } = payload;
    const momentedClickedDate: moment.Moment = moment(date);
    const targetShift = targetAssignee
      ? client.readFragment({
          id: `Shift:${targetAssignee.shiftId}`,
          fragment: ShiftFragment,
          fragmentName: 'ShiftFragment',
        })
      : null;
    if (targetShift) {
      return moment(targetShift.startDate).format('DD/MMM/YYYY');
    }
    return momentedClickedDate.format('DD/MMM/YYYY');
  };

  public render() {
    const { showModal, handleCloseModal, payload, hasCalendar, flags } = this.props;

    const prefillRole = payload ? payload.role : null;
    let prefillDate = payload ? payload.date : null;
    const prefillAssignee = payload ? this.getShiftsFromDataCache(payload, flags.overnightShifts) : null;
    let startingDateString = payload ? this.modalRoleStartingDateString(payload) : null;
    let targetShiftStartDateTime = payload ? this.getTargetShiftStartDateTime(payload, flags.overnightShifts) : null;

    if (flags.overnightShifts && targetShiftStartDateTime) {
      prefillDate = targetShiftStartDateTime.format('YYYY-MM-DD');
      startingDateString = targetShiftStartDateTime.format('DD/MMM/YYYY');
    }

    const theme = createMuiTheme({
      palette: {
        primary: { main: Apptheme.mainTealColor },
      },
      typography: {
        fontFamily: 'Open Sans',
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <Dialog maxWidth={'sm'} fullWidth={true} open={showModal} TransitionComponent={Transition}>
          <MuiDialogTitle disableTypography>
            <DialogTitleTypography variant="h6">
              {payload?.targetAssignee ? (
                <>Edit Shifts Starting on {startingDateString}</>
              ) : (
                <>Input Schedule {Boolean(startingDateString) && `Starting at ${startingDateString}`}</>
              )}
            </DialogTitleTypography>
            <IconButton
              aria-label="close"
              onClick={() => handleCloseModal()}
              style={{ position: 'absolute', top: 16, right: 16 }}
            >
              <CloseMark />
            </IconButton>
          </MuiDialogTitle>
          {hasCalendar && !Boolean(payload) ? (
            <ScheduleInputCalendarForm
              closeModal={handleCloseModal}
              handleModalFormSubmission={this.handleModalFormSingleAssigneeSubmission}
            />
          ) : (
            <ScheduleInputMultiUserForm
              prefillRole={prefillRole}
              prefillDate={moment(prefillDate)}
              closeModal={handleCloseModal}
              prefillAssignee={prefillAssignee}
              targetAssignee={payload.targetAssignee}
              handleModalFormSubmission={this.handleModalFormMultiAssigneeSubmission}
            />
          )}
        </Dialog>
      </ThemeProvider>
    );
  }

  private dispatchLastUpdatedAt = () => {
    store.dispatch(allActions.monthlyScheduleAction.setLastUpdateAt({ lastUpdatedAt: moment().toISOString() }));
  };
}

const mapStateToProps = (state: RootState) => {
  return {
    roleContainer: state.monthlyScheduleReducer.roleContainer,
  };
};

export default connect(mapStateToProps)(withLDConsumer()(InputScheduleModal));
