import store from 'src/redux/store';

export const checkOrganizationalUnit = () => {
  let id: number;
  const { organization_id, site_id, department_id, type } = store.getState().organizationReducer;
  if (organization_id) id = organization_id;
  if (site_id) id = site_id;
  if (department_id) id = department_id;

  if (!id || !type) {
    // Ideally this should just return undefined and force consumers to handle this case but right now we have strict mode off
    console.error('missing org unit details');
    return {
      type: 'error',
    } as const;
  }

  return {
    id,
    type,
  };
};

export const getOrganizationObject = () => {
  let id: number | null;
  const { organization_id } = store.getState().organizationReducer;
  if (organization_id) id = organization_id;
  return {
    id,
    type: 'organization',
  };
};
