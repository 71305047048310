import gql from 'graphql-tag';

export const GET_MEMBERS = gql`
  query ($startDate: String!, $endDate: String!, $organizationId: Int!, $departmentId: Int, $siteId: Int) {
    analytics {
      members(
        startDate: $startDate
        endDate: $endDate
        organizationId: $organizationId
        departmentId: $departmentId
        siteId: $siteId
      ) {
        id
        firstname
        lastname
        role
        username
        messagesentcount
      }
    }
  }
`;

export const GET_ACTIVE_MEMBER_TIMELINE = gql`
  query ($startDate: String!, $endDate: String!, $groupBy: String!, $organizationId: Int!, $departmentId: Int) {
    analytics {
      activeMemberTimelines(
        startDate: $startDate
        endDate: $endDate
        groupBy: $groupBy
        organizationId: $organizationId
        departmentId: $departmentId
      ) {
        series {
          label
          values {
            label
            value
          }
        }
      }
      messageSentTimelines(
        startDate: $startDate
        endDate: $endDate
        groupBy: $groupBy
        organizationId: $organizationId
        departmentId: $departmentId
      ) {
        series {
          label
          values {
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_MESSAGE_BY_TYPE_TIMELINE = gql`
  query (
    $startDate: String!
    $endDate: String!
    $groupBy: String!
    $organizationId: Int!
    $departmentId: Int
    $priorityOnly: Boolean
  ) {
    analytics {
      messageByTypesTimelines(
        startDate: $startDate
        endDate: $endDate
        groupBy: $groupBy
        organizationId: $organizationId
        departmentId: $departmentId
        priorityOnly: $priorityOnly
      ) {
        series {
          label
          values {
            label
            value
          }
        }
      }
      messageSentTimelines(
        startDate: $startDate
        endDate: $endDate
        groupBy: $groupBy
        organizationId: $organizationId
        departmentId: $departmentId
      ) {
        series {
          label
          values {
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_HIGHTLIGHTS_ACTIVE_USERS = gql`
  query ($startDate: String!, $endDate: String!, $organizationId: Int!, $departmentId: Int) {
    analytics {
      highlights {
        activeUsers(
          startDate: $startDate
          endDate: $endDate
          organizationId: $organizationId
          departmentId: $departmentId
        ) {
          id
          value {
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_HIGHTLIGHTS_MESSAGE_SENT = gql`
  query ($startDate: String!, $endDate: String!, $organizationId: Int!, $departmentId: Int) {
    analytics {
      highlights {
        messageSent(
          startDate: $startDate
          endDate: $endDate
          organizationId: $organizationId
          departmentId: $departmentId
        ) {
          id
          value {
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_CHAT_BY_TYPE = gql`
  query ($startDate: String!, $endDate: String!, $organizationId: Int!, $departmentId: Int) {
    analytics {
      chatByType(
        startDate: $startDate
        endDate: $endDate
        organizationId: $organizationId
        departmentId: $departmentId
      ) {
        id
        values {
          label
          value
        }
      }
    }
  }
`;

export const EXPORTABLE_DATA = gql`
  query ($startDate: String!, $endDate: String!, $organizationId: Int!, $departmentId: Int) {
    analytics {
      exportableData(
        startDate: $startDate
        endDate: $endDate
        organizationId: $organizationId
        departmentId: $departmentId
      ) {
        date
        totalMembership
        fullMembers
        guests
        DailyActiveMembers
        dailyMembersPostingMessages
        weeklyActiveMembers
        weeklyMembersPostingMessages
        messagesInPublicChannels
        messagesInPrivateChannels
        messagesInSharedChannels
        messagesInDMs
        percentOfMessagesPublicChannels
        percentOfMessagesPrivateChannels
        percentOfMessagesDMs
        percentOfViewsPublicChannels
        percentOfViewsPrivateChannels
        percentOfViewsDMs
        filesUploaded
        messagesPostedBymembers
        name
        publicChannelsSingleWorkspace
        messagesPosted
        messagesPostedByApps
      }
    }
  }
`;
