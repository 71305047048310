import React, { useEffect, useState, useRef, ReactElement } from 'react';
import { AuthContext } from 'src/auth';
import { AuthPayload, AuthRegion } from 'src/types';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import CanadaFlag from 'src/assets/static/canada.svg';
import AmericaFlag from 'src/assets/static/united-states-of-america.svg';

import 'src/assets/styles/DropdownStyles.scss';
import { Query, QueryResult } from 'react-apollo';
import { GlobalImageAvatar } from 'src/components/headers/global-header/UserOptions';
import { GET_USERS_PROFILE } from 'src/gql/query/GetUserProfileQuery';
import { typedUseSelector } from 'src/redux/store';
import { AuthRegionFlag } from '../shared/AuthRegionFlag';
import { AuthRegionMap } from 'src/constants/strings';
import { getParsedAuthRegion } from '../../utils/localStorageHelper';

const RegionLi = styled.li`
  display: flex;
  align-items: center;
  min-width: 200px;
  justify-content: center;
  color: ${(props) => props.theme.mainFontColor};
  font-size: 16px;
  line-height: 40px;
  min-height: 40px;
  cursor: default !important;
`;

const AvatarPlaceholder = styled.span`
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;

interface Option {
  description: string;
}

interface Props {
  options: Option[];
  onSelect: (option: Option, index?: number) => void;
  renderChildren: () => ReactElement;
  placeholder: string;
  authRegion: AuthRegion;
  authInfo: AuthPayload;
}

const Dropdown = ({ options, onSelect, authRegion, authInfo, placeholder }: Props) => {
  const node = useRef(null);
  const [open, setOpen] = useState(false);

  const currentAuthRegion = getParsedAuthRegion();

  const id = authInfo?.user?.id;

  const handleClick = (e) => {
    if (node.current.contains(e.target)) return;
    setOpen(false);
  };

  const handleChange = (option, index) => {
    setOpen(false);
    onSelect(option, index);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  if (authInfo) {
    return (
      <Query query={GET_USERS_PROFILE} variables={{ userId: id }}>
        {({ loading, error, data }: QueryResult<any>) => {
          if (error) console.error(error);
          return (
            <div ref={node} className="userDropdownToggler" title="more options" onClick={() => setOpen(!open)}>
              <div className="globalHeader__user__nameAvatar">
                {loading ? <AvatarPlaceholder /> : null}
                {data ? GlobalImageAvatar(data.profile) : null}
                <div className="globalHeader__userFullName">{placeholder}</div>
              </div>
              {open && (
                <ul className="dropdownUserMenu">
                  <RegionLi key="region">
                    <span>Current region:</span>
                    <AuthRegionFlag currentRegion={currentAuthRegion} size="small" />
                    <span>{currentAuthRegion}</span>
                  </RegionLi>
                  <Divider />
                  {options.map((opt, index) => (
                    <li
                      className="dropdownUserMenu__item"
                      key={`${index}-${opt.description}`}
                      onClick={() => handleChange(opt, index)}
                    >
                      {opt.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        }}
      </Query>
    );
  }

  return null;
};

export default (props) => (
  <AuthContext.Consumer>
    {({ authRegion, authInfo }) => <Dropdown {...props} authInfo={authInfo} authRegion={authRegion} />}
  </AuthContext.Consumer>
);
