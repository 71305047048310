import React from 'react';
import client from 'src/clients/apolloClient';
import { FetchResult } from 'apollo-link';
import { UPDATE_ADDRESS_PREFERENCE } from 'src/gql/v2/mutation/UpdateAddressPreferenceMutation';
import { UPDATE_PROFILE_FIELDS } from 'src/gql/v2/mutation/AdminUpdateProfileFieldsMutation';
import { checkOrganizationalUnit, getOrganizationObject } from 'src/utils/getOrganizationalUnitObject';
import { UpdateUserProfileV2Result, UserAddressAccess, UserAddress } from 'src/types';
import AddUserAddressMutation from 'src/gql/mutation/AddUserAddressesMutation';
import RemoveUserAddressesMutation from 'src/gql/mutation/RemoveUserAddressesMutation';
import SetUserLicenseExpiryMutation from 'src/gql/mutation/SetUserLicenseExpiryMutation';
import sleep from 'src/utils/sleep';
import { useMutation, useQuery } from 'react-apollo';
import {
  FETCH_ORGANIZATION_ADDRESS_LABEL_OPTIONS,
  FetchOrganizationAddressLabelOptionsResponse,
} from '../../../gql/v2/query/FetchOrganizationAddressLabelOptions';
import { OrganizationalUnitInputType } from '../../../gql/v2/types/input';
import {
  EditUserAddressLabelMutation,
  EditUserAddressLabelMutationResult,
} from 'src/gql/v2/mutation/EditUserAddressLabelMutation';
import {
  EditUserAddressAccessMutation,
  EditUserAddressAccessMutationResult,
} from 'src/gql/v2/mutation/EditUserAddressAccessMutation';
import { useSelector } from 'react-redux';
import { IsFeatureFlagEnabled } from '../../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../../utils/FeatureFlags';

type FetchOrganizationLabelOptionError = null | 'networkError' | 'anotherError(complete me)';
type EditAddressLabelError =
  | null
  | 'networkError'
  | 'InvalidAddressLabel'
  | 'AddressNotFound'
  | 'OrganizationalUnitNotFoundError'
  | 'UserNotAdminForScope';

type EditAddressAccessError =
  | null
  | 'networkError'
  | 'InvalidAddressLabel'
  | 'AddressNotFound'
  | 'OrganizationalUnitNotFoundError'
  | 'UserNotAdminForScope';

const ProfilePageRepository = () => {
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabels);
  const useFetchOrganizationLabelOptions = () => {
    const result = useQuery<FetchOrganizationAddressLabelOptionsResponse, OrganizationalUnitInputType>(
      FETCH_ORGANIZATION_ADDRESS_LABEL_OPTIONS,
      {
        variables: {
          organizationalUnit: getOrganizationObject(),
        },
        skip: !profileAddressLabelFlag,
      },
    );
    let error: FetchOrganizationLabelOptionError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const updateProfilePreference = async (userId: String, addresses: string[]) => {
    try {
      const result = await client.mutate({
        mutation: UPDATE_ADDRESS_PREFERENCE,
        variables: {
          userId,
          address: addresses,
          organizationalUnit: checkOrganizationalUnit(),
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const addUserAddresses = async (userId: String, added: UserAddress[]) => {
    let result: FetchResult<any>;
    try {
      result = await client.mutate({
        mutation: AddUserAddressMutation,
        variables: {
          userId,
          addresses: added,
        },
      });
      return Promise.resolve(result);
    } catch (e) {
      const mutationResult = result?.data.admin.user.addAddresses;
      if (e?.networkError?.result?.errors) {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: e.networkError.result.errors[0].message,
          name: e.networkError.result.errors[0].name,
          mutationResult: mutationResult,
        };
      }
      // eslint-disable-next-line no-throw-literal
      throw {
        error: e,
        mutationResult: mutationResult,
      };
    }
  };

  const editAddressLabel = async (
    organizationalUnit: OrganizationalUnitInputType,
    userId: string,
    address: string,
    label: string,
  ): Promise<{
    data?: EditUserAddressLabelMutationResult;
    error: EditAddressLabelError;
  }> => {
    let result: FetchResult<EditUserAddressLabelMutationResult>;
    try {
      result = await client.mutate<
        EditUserAddressLabelMutationResult,
        OrganizationalUnitInputType & {
          userId: string;
          address: string;
          addressLabel: string;
        }
      >({
        mutation: EditUserAddressLabelMutation,
        variables: {
          ...organizationalUnit,
          userId: userId,
          address: address,
          addressLabel: label,
        },
      });

      if (result.errors) {
        throw new Error();
      }
      if (result.data.adminMutation.organizationalUnit.__typename === 'OrganizationalUnitNotFoundError') {
        return {
          error: 'OrganizationalUnitNotFoundError',
        };
      }
      if (result.data.adminMutation.organizationalUnit.__typename === 'UserNotAdminForScope') {
        return {
          error: 'UserNotAdminForScope',
        };
      }
      if (result.data.adminMutation.organizationalUnit.member.address.__typename === 'AddressNotFound') {
        return {
          error: 'AddressNotFound',
        };
      }
      if (
        result.data.adminMutation.organizationalUnit.member.address.updateLabel.__typename === 'InvalidAddressLabel'
      ) {
        return {
          error: 'InvalidAddressLabel',
        };
      }

      return {
        data: result.data,
        error: null,
      };
    } catch (e) {
      return {
        error: 'networkError',
      };
    }
  };

  const editAddressAccess = async (
    organizationalUnit: OrganizationalUnitInputType,
    userId: string,
    address: string,
    accessType: UserAddressAccess,
  ): Promise<{
    data?: EditUserAddressAccessMutationResult;
    error: EditAddressAccessError;
  }> => {
    let result: FetchResult<EditUserAddressAccessMutationResult>;
    try {
      result = await client.mutate<
        EditUserAddressAccessMutationResult,
        OrganizationalUnitInputType & {
          userId: string;
          address: string;
          accessType: UserAddressAccess;
        }
      >({
        mutation: EditUserAddressAccessMutation,
        variables: {
          ...organizationalUnit,
          userId: userId,
          address: address,
          accessType: accessType,
        },
      });

      if (result.errors) {
        throw new Error('mutation had errors');
      }
      if (result.data.adminMutation.organizationalUnit.__typename === 'OrganizationalUnitNotFoundError') {
        return {
          error: 'OrganizationalUnitNotFoundError',
        };
      } else if (result.data?.adminMutation.organizationalUnit.__typename === 'UserNotAdminForScope') {
        return {
          error: 'UserNotAdminForScope',
        };
      } else if (result.data?.adminMutation.organizationalUnit.member.address.__typename === 'AddressNotFound') {
        return {
          error: 'AddressNotFound',
        };
      }

      return {
        data: result.data,
        error: null,
      };
    } catch (e) {
      return {
        error: 'networkError',
      };
    }
  };

  const removeUserAddresses = async (userId: String, deleted: string[]) => {
    let result: FetchResult<any>;
    try {
      await sleep(500); // backend input delay
      result = await client.mutate({
        mutation: RemoveUserAddressesMutation,
        variables: {
          userId,
          addressIds: deleted,
        },
      });
      return Promise.resolve(result);
    } catch (e) {
      const mutationResult = result?.data.admin.user.removeAddress;
      if (e?.networkError?.result?.errors) {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: e.networkError.result.errors[0].message,
          name: e.networkError.result.errors[0].name,
          mutationResult: mutationResult,
        };
      }
      // eslint-disable-next-line no-throw-literal
      throw {
        error: e,
        mutationResult: mutationResult,
      };
    }
  };

  const updateProfileFormFields = async (userId, values) => {
    const { firstname: firstName, lastname: lastName, role } = values;
    const details = {
      firstName,
      lastName,
      role,
    };
    try {
      const result = await client.mutate<UpdateUserProfileV2Result>({
        mutation: UPDATE_PROFILE_FIELDS,
        variables: {
          userId,
          details,
          organizationalUnit: checkOrganizationalUnit(),
        },
      });
      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const updateLicenseStartEndDate = async (userId, values) => {
    const licenseStart = values.licenseStartDate.toISOString();
    const licenseEndDate = values.licenseEndDate ? values.licenseEndDate.toISOString() : null;
    try {
      const result = await client.mutate({
        mutation: SetUserLicenseExpiryMutation,
        variables: {
          userId,
          licenseStart: licenseStart,
          licenseEnd: licenseEndDate,
        },
      });
      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  return {
    updateProfilePreference,
    addUserAddresses,
    removeUserAddresses,
    updateProfileFormFields,
    updateLicenseStartEndDate,
    useFetchOrganizationLabelOptions,
    editAddressLabel,
    editAddressAccess,
  };
};

export default ProfilePageRepository;
