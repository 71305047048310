import React from 'react';
import ChevronSort from 'src/assets/svgs/ChevronSort';
import { ASCEND, DATE_CREATED, DATE_LAST_PAGED, DESCEND } from 'src/constants/SorterTypes';
import { SortFieldType } from 'src/types/VirtualPagers';
import styled from 'styled-components';
import {
  NUMBER,
  ALERT,
  ASSIGNED_TO,
  CREATED,
  LAST_PAGED,
  STATUS,
  VOICEMAIL,
  ALERT_HEADER_TOOLTIP,
  VOICEMAIL_HEADER_TOOLTIP,
  ASSIGNE_TO_HEADER_TOOLTIP,
} from 'src/constants/virtualPagerStrings';
import theme from 'src/assets/styles/theme';
import { typedUseSelector } from 'src/redux/store';
import { setSortField, setSortOrder } from 'src/redux/actions/virtualPagersActions';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';

const SortButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 13.27% 13.27% 21.01% 8.62% 8.62% 13.27% 13.27% 8.62% !important;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2 !important;
  border-bottom: 1px solid ${theme.borderBottomLight};
`;

const SortButtonText = styled.div`
  color: ${(props) => props.theme.mainFontColor};
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
`;

const UnderlinedSortButtonText = styled(SortButtonText)`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  text-decoration-color: ${theme.textLightTertiary};
`;

const SortButton = styled.div`
  padding: 8px 0px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const SortButtons: React.FC = () => {
  const { sortField, sortOrder } = typedUseSelector((state) => state.virtualPagerReducer);

  const dispatch = useDispatch();

  const sortingActions = (sortFieldType: SortFieldType) => {
    if (sortField === sortFieldType) {
      dispatch(setSortOrder(sortOrder === ASCEND ? DESCEND : ASCEND));
    } else {
      dispatch(setSortField(sortFieldType));
      dispatch(setSortOrder(DESCEND));
    }
  };

  return (
    <SortButtonsContainer>
      <SortButtonText style={{ padding: '8px 113px 8px 12px' }}>{NUMBER}</SortButtonText>

      <SortButtonText>{STATUS}</SortButtonText>

      <Tooltip title={ASSIGNE_TO_HEADER_TOOLTIP} placement="top">
        <UnderlinedSortButtonText style={{ paddingLeft: '12px' }}>{ASSIGNED_TO}</UnderlinedSortButtonText>
      </Tooltip>
      <Tooltip title={ALERT_HEADER_TOOLTIP} placement="top">
        <UnderlinedSortButtonText>{ALERT}</UnderlinedSortButtonText>
      </Tooltip>

      <Tooltip title={VOICEMAIL_HEADER_TOOLTIP} placement="top">
        <UnderlinedSortButtonText>{VOICEMAIL}</UnderlinedSortButtonText>
      </Tooltip>

      <SortButton onClick={() => sortingActions(DATE_CREATED)}>
        <SortButtonText>{CREATED}</SortButtonText>
        <ChevronSort />
      </SortButton>

      <SortButton onClick={() => sortingActions(DATE_LAST_PAGED)}>
        <SortButtonText>{LAST_PAGED}</SortButtonText>
        <ChevronSort />
      </SortButton>

      <div id="empty-column-heading"></div>
    </SortButtonsContainer>
  );
};

export default SortButtons;
