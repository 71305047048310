import React, { Component } from 'react';
import * as ReactModal from 'react-modal';
import SchedulePageCallbackForm from 'src/components/forms/SchedulePageCallbackForm';
import { toast } from 'react-toastify';
import client from 'src/clients/apolloClient';
import PageCallbackNumberMutation from 'src/gql/mutation/PageCallbackNumberMutation';

interface Props {
  showModal: boolean;
  selectedAssignee: {
    userFullName: string;
    userId: string;
    startTime: any;
    endTime: any;
  } | null;
  handleCloseModal: () => void;
}

class PageCallbackModal extends Component<Props> {
  handleModalFormSubmission = (values: { pageNumber: string }) => {
    const { handleCloseModal, selectedAssignee } = this.props;

    return new Promise(async (resolve, reject) => {
      try {
        await client.mutate({
          mutation: PageCallbackNumberMutation,
          variables: {
            userId: selectedAssignee!.userId,
            callbackNum: values.pageNumber,
          },
        });
        resolve('done!');
        this.successPageUser();
        handleCloseModal();
      } catch {
        reject('unknown backend error');
        this.errorPageUser();
      }
    });
  };

  successPageUser = () => {
    this.props.handleCloseModal();
    toast.success('Page has been sent.', {
      className: 'Toast-Container',
    });
  };

  errorPageUser = () => {
    toast.error('Failed to send page.', {
      className: 'Toast-Container',
    });
  };

  render() {
    const { showModal, handleCloseModal, selectedAssignee } = this.props;

    return (
      <ReactModal
        overlayClassName="modal__overlay"
        className="modal__confirmation"
        isOpen={showModal}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <div className="close" onClick={handleCloseModal} />
        <div className="modal__header">Page {selectedAssignee!.userFullName}</div>
        <SchedulePageCallbackForm handleModalFormSubmission={this.handleModalFormSubmission} />
      </ReactModal>
    );
  }
}

export default PageCallbackModal;
