import React from 'react';
import styled from 'styled-components';

interface Props {
  type: 'warning' | 'neutral' | 'admin' | 'shell' | 'active' | 'error';
  valueClassName: any;
  text: string;
}

const StyledChip = styled.div`
  align-items: flex-start;
  border: 1px solid;
  border-radius: 19px;
  display: inline-flex;
  gap: 10px;
  padding: 2px 8px;
  position: relative;
  margin-right: 5px;

  & .value {
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  &.warning {
    color: #4a4a4a;
    background-color: #fff4cd;
    border-color: #ffc705;
  }

  &.neutral {
    color: #4a4a4a;
    background-color: #f2f2f2;
    border: 1px solid #bcbcbc;
  }

  &.admin {
    background-color: #ff3e55;
    border-color: #ff3e55;
    color: #ffffff;
  }

  &.shell {
    color: #ffffff;
    background-color: #a9a9a9;
    border-color: #a9a9a9;
  }

  &.error {
    color: #4a4a4a;
    background-color: #ffd7d7;
    border-color: #f35588;
  }

  &.active {
    color: #4a4a4a;
    background-color: #90e4c1;
    border-color: #b2d8d8;
  }
`;

export const StyledHypercareChip = ({ type, valueClassName, text = 'Tag' }: Props): JSX.Element => {
  return (
    <StyledChip className={`chip ${type}`}>
      <div className={`value ${valueClassName}`}>{text}</div>
    </StyledChip>
  );
};
