import { UserAddress } from 'src/types';

// This is legacy code that compares pre and post edit addresses
// We used to not support editing addresses in the backend so in order to get around that, the existing consumers of this function expect any edited addresses to be included in the deleted AND added arrays
// The backend added support for editing addresses, so in order to avoid breaking existing consumers who expect the old behaviour, we added a flag to denote whether this function should conform to the old strategy for handling edited addresses (delete and add) vs the new strategy (edit)

export const diffTwoAddressesArrays = (
  postEditAddresses: UserAddress[],
  preEditAddresses: UserAddress[],
  isEditSupported: boolean = false,
) => {
  const compareProperties = (current: UserAddress, other: UserAddress) => {
    return (
      current.access === other.access &&
      current.address === other.address &&
      current.ordinal === other.ordinal &&
      current.type === other.type &&
      current.label === other.label
    );
  };

  // post edit addresses that don't match any pre edit addresses exactly
  const addressesToBeAddedIfNotAllowEdit = postEditAddresses.filter(
    (current) => !preEditAddresses.some((other) => compareProperties(current, other)),
  );

  // pre edit addresses that don't match any post edit addresses exactly
  const addressesToBeDeletedIfNotAllowEdit = preEditAddresses.filter(
    (current) => !postEditAddresses.some((other) => compareProperties(current, other)),
  );

  // post edit addresses that don't match address value of any pre edit addresses
  const addressesToBeAddedIfAllowEdit = postEditAddresses.filter(
    (current) => !preEditAddresses.some((other) => current.address === other.address),
  );

  // pre edit addresses that don't match address value of any post edit addresses
  const addressesToBeDeletedIfAllowEdit = preEditAddresses.filter(
    (current) => !postEditAddresses.some((other) => current.address === other.address),
  );

  // post edit addresses that match address value of a pre edit address AND have changes
  const addressesToBeEdited = postEditAddresses.filter((current) =>
    preEditAddresses.some((other) => {
      return current.address === other.address && !compareProperties(current, other);
    }),
  );

  return isEditSupported
    ? {
        added: addressesToBeAddedIfAllowEdit,
        deleted: addressesToBeDeletedIfAllowEdit,
        edited: addressesToBeEdited,
      }
    : {
        added: addressesToBeAddedIfNotAllowEdit,
        deleted: addressesToBeDeletedIfNotAllowEdit,
        edited: [],
      };
};
