export default function hexToRgba(hex: string, alpha: number = 1) {
  if (!hex) return 'whitesmoke';

  // credit: https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
}
