import React from 'react';
import { AddressType, UserAddress } from '../../../types';
import { MissingBannerType } from './AddressBanner';

export const AddressBannerViewModel = () => {
  const getMissingAddressInformation = ({ addresses }: { addresses: UserAddress[] }) => {
    const isPhoneNumberPresent = addresses.some((address) => address.type === AddressType.PHONE_NUMBER);
    const isEmailPresent = addresses.some((address) => address.type === AddressType.EMAIL);

    if (!isEmailPresent && !isPhoneNumberPresent) {
      return { type: MissingBannerType.BOTH };
    }

    if (!isEmailPresent) {
      return { type: MissingBannerType.EMAIL };
    }

    if (!isPhoneNumberPresent) {
      return { type: MissingBannerType.PHONE };
    }

    return { type: null };
  };

  return { getMissingAddressInformation };
};
