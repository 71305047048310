import gql from 'graphql-tag';
import { ProfileFieldFragment, ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type ProfileSectionFragmentType = {
  id: string;
  sectionId: string;
  label: string;
  labelOptions: string[];
  maxFields: number;
  sections: Array<ProfileSectionFragmentType>;
  fields: Array<{ id: string } & ProfileFieldFragmentType>;
};

export const ProfileSectionFragment = gql`
  fragment ProfileSectionFragment on ProfileSection {
    id
    sectionId
    label
    labelOptions
    maxFields
    sections {
      id
      sectionId
      label
      labelOptions
      maxFields
    }
    fields {
      ... on Node {
        id
      }
      ...ProfileFieldFragment
    }
  }

  ${ProfileFieldFragment}
`;
