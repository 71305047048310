import gql from 'graphql-tag';
import RoleFragment from 'src/gql/fragment/RoleFragment';
import ScheduleFragment from 'src/gql/fragment/ScheduleFragment';

export const GET_ROLES = gql`
  query GetRolesQuery($departmentId: Int!, $startDate: String!, $endDate: String!, $filter: Boolean = false) {
    locating {
      department(id: $departmentId) {
        roles(startDate: $startDate, endDate: $endDate, filter: $filter) {
          ...RoleFragment
        }
      }
    }
  }
  ${RoleFragment}
`;

export default gql`
  query FetchRolesAndShiftsInRange(
    $departmentId: Int!
    $startDate: String!
    $endDate: String!
    $filter: Boolean = false
  ) {
    locating {
      department(id: $departmentId) {
        roles(startDate: $startDate, endDate: $endDate, filter: $filter) {
          ...RoleFragment
        }
        schedule(startDate: $startDate, endDate: $endDate) {
          ...ScheduleFragment
        }
      }
    }
  }
  ${RoleFragment}
  ${ScheduleFragment}
`;
