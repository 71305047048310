import { SET_CURRENT_ORGANIZATION } from 'src/constants/actionNames';
import { UserOrganizationSwitcherPayload } from 'src/types';
import { SetCurrentOrganizationAction } from 'src/redux/actions/headerAction';

let initialState: OrganizationReducerState = {
  name: '',
  type: null,
  image: '',
  site_id: null,
  department_id: null,
  organization_id: null,
  organization_name: null,
};

export interface OrganizationReducerState extends UserOrganizationSwitcherPayload {}

export const organizationReducer = (
  state = initialState,
  action: SetCurrentOrganizationAction,
): UserOrganizationSwitcherPayload => {
  switch (action.type) {
    case SET_CURRENT_ORGANIZATION: {
      const newState = Object.assign({}, action.currentOrganization);
      return newState;
    }
    default:
      return state;
  }
};
