import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import initFeatureFlags from 'src/utils/FeatureFlagManager';

const FeatureFlagClient = initFeatureFlags(App);

ReactDOM.render(<FeatureFlagClient />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    // auto refresh page for admin:
    if (registration && registration.waiting) {
      setTimeout(() => {
        // setTimeout as main thread being blocked
        // https://github.com/facebook/create-react-app/issues/5316
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }, 800);
    }
  },
});
