import moment from 'moment';
import PVPAApiHelper from 'src/api/VirtualPagersAPIEndpoints';
import _ from 'lodash';

import {
  VirtualPagers,
  PagerStats,
  UserSearchResponseObject,
  OrganizationMemberFragment,
  OrganizationRoleFragment,
  FetchOrgPurchasedNumbersResponse,
  SearchFieldType,
  VirtualPagerStatusTypes,
  DepartmentFragment,
} from 'src/types/VirtualPagers';

interface EntryPageViewModelProps {
  fetchOrgPurchasedNumbers: (
    orgId: number,
    sortField: string,
    sortOrder: string,
    nextCursor: string,
  ) => Promise<FetchOrgPurchasedNumbersResponse>;
  fetchAllOrgPurchasedNumbers: (orgId: number, sortField: string, sortOrder: string) => Promise<VirtualPagers[]>;
  searchPagers: (
    orgId: number,
    sortField: string,
    sortOrder: string,
    nextCursor: string,
    searchFieldType: SearchFieldType,
    searchInput?: string,
  ) => Promise<FetchOrgPurchasedNumbersResponse>;
  fetchUserbyUserID: (userIds: string[]) => Promise<OrganizationMemberFragment[]>;
  fetchRolebyRoleID: (roleIds: string[]) => Promise<OrganizationRoleFragment[]>;
  getAssigneeStringMapFromPagerList: (
    cacheMap: Map<string, string[]>,
    pagers: VirtualPagers[],
    roleList: OrganizationRoleFragment[],
  ) => Promise<Map<string, string[]>>;
  fetchOrganizationRoles: () => Promise<OrganizationRoleFragment[]>;
}

export default (): EntryPageViewModelProps => {
  const searchPagers = async (
    orgId: number,
    sortField: string,
    sortOrder: string,
    nextCursor: string,
    searchFieldType: SearchFieldType,
    searchInput?: string,
  ): Promise<FetchOrgPurchasedNumbersResponse> => {
    let searchedPagers: FetchOrgPurchasedNumbersResponse;
    switch (searchFieldType) {
      case 'number':
        searchedPagers = await _filterNumbersByNumber(orgId, sortField, sortOrder, searchInput, nextCursor);
        break;
      case 'friendlyName':
        searchedPagers = await _filterNumbersByFriendlyName(orgId, sortField, sortOrder, searchInput, nextCursor);
        break;
      case 'userIds':
        searchedPagers = await _filterNumbersByAssignee(orgId, sortField, sortOrder, searchInput, nextCursor);
        break;
      case 'all':
        const data = await PVPAApiHelper.fetchOrgPurchasedNumbers(orgId, nextCursor, sortField, sortOrder, 'all');
        searchedPagers = {
          nextResponseCursor: data.data.nextCursor,
          fetchedNumbers: data.data.numbers,
          pagerStatus: data.data.pagerStatus,
        };
        break;
    }
    return searchedPagers;
  };

  const fetchOrgPurchasedNumbers = async (
    orgId: number,
    sortField: string,
    sortOrder: string,
    nextCursor: string,
    type: string = 'all',
  ): Promise<FetchOrgPurchasedNumbersResponse> => {
    try {
      const response = await PVPAApiHelper.fetchOrgPurchasedNumbers(orgId, nextCursor, sortField, sortOrder, type);
      const data = response.data;
      return {
        nextResponseCursor: data.nextCursor,
        fetchedNumbers: data.numbers,
        pagerStatus: {
          [VirtualPagerStatusTypes.ALL]: data.pagerStatus[VirtualPagerStatusTypes.ALL] || 0,
          [VirtualPagerStatusTypes.ACTIVE]: data.pagerStatus[VirtualPagerStatusTypes.ACTIVE] || 0,
          [VirtualPagerStatusTypes.PENDING]: data.pagerStatus[VirtualPagerStatusTypes.PENDING] || 0,
          [VirtualPagerStatusTypes.DISABLED]: data.pagerStatus[VirtualPagerStatusTypes.DISABLED] || 0,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }
  };

  const fetchAllOrgPurchasedNumbers = async (
    orgId: number,
    sortField: string,
    sortOrder: string,
  ): Promise<VirtualPagers[]> => {
    let nextCursor = null;
    const pagers: VirtualPagers[] = [];
    do {
      const res = await fetchOrgPurchasedNumbers(orgId, sortField, sortOrder, nextCursor);
      pagers.push(...res.fetchedNumbers);
      nextCursor = res.nextResponseCursor;
    } while (nextCursor !== null);

    return pagers;
  };

  const fetchUserbyUserID = async (userIds: string[]) => {
    const fetchUserbyUserIDResult = await PVPAApiHelper.fetchUserbyUserID(userIds);
    return fetchUserbyUserIDResult.data.users;
  };

  const fetchRolebyRoleID = async (roleIds: string[]) => {
    let roleArray = [];
    for (let i = 0; i < roleIds.length; i++) {
      const fetchRolebyRoleIDResult = await PVPAApiHelper.fetchRolebyRoleID(parseInt(roleIds[i], 10));
      roleArray.push(fetchRolebyRoleIDResult);
    }

    return roleArray;
  };

  const getAssigneeStringMapFromPagerList = async (
    cacheMap: Map<string, string[]>,
    pagers: VirtualPagers[],
    roleList: OrganizationRoleFragment[],
  ): Promise<Map<string, string[]>> => {
    const assignToMap = new Map<string, string[]>();
    const assignToMapPromises = new Map<string, Promise<any>>();
    for (const pager of pagers) {
      if (cacheMap.has(pager._id)) {
        assignToMap.set(pager._id, cacheMap.get(pager._id));
      } else {
        if (pager.userIds.length > 0) {
          const usersPromise = fetchUserbyUserID(pager.userIds);
          assignToMapPromises.set(pager._id, usersPromise);
        } else if (pager.roleIds.length > 0) {
          if (!_.isEmpty(roleList)) {
            const roles = roleList.filter((role) => pager.roleIds.includes(role.roleAuditId.toString()));
            assignToMap.set(
              pager._id,
              roles.map((role) => role.roleName),
            );
          }
        } else {
          assignToMap.set(pager._id, []);
        }
      }
    }
    const newPagerIds = [...assignToMapPromises.keys()];
    const usersForPagers = await Promise.all([...assignToMapPromises.values()]);
    newPagerIds.forEach((pagerId) => {
      const users = usersForPagers.shift();
      assignToMap.set(
        pagerId,
        users.map((user) => `${user.firstname} ${user.lastname}`),
      );
    });
    return assignToMap;
  };

  const _filterNumbersByFriendlyName = async (
    orgId: number,
    sortField: string,
    sortOrder: string,
    searchInput: string,
    nextCursor: string,
  ): Promise<FetchOrgPurchasedNumbersResponse> => {
    const type = 'friendlyName';
    try {
      const response = await PVPAApiHelper.fetchOrgPurchasedNumbers(
        orgId,
        nextCursor,
        sortField,
        sortOrder,
        type,
        searchInput,
        null,
        null,
      );

      const data = response.data;

      return {
        nextResponseCursor: data.nextCursor,
        fetchedNumbers: data.numbers,
        pagerStatus: data.pagerStatus,
      } as FetchOrgPurchasedNumbersResponse;
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }
  };

  const _filterNumbersByNumber = async (
    orgId: number,
    sortField: string,
    sortOrder: string,
    searchInput: string,
    nextCursor: string,
  ): Promise<FetchOrgPurchasedNumbersResponse> => {
    const type = 'number';
    try {
      const response = await PVPAApiHelper.fetchOrgPurchasedNumbers(
        orgId,
        nextCursor,
        sortField,
        sortOrder,
        type,
        null,
        searchInput,
        null,
      );

      const data = response.data;

      return {
        nextResponseCursor: data.nextCursor,
        fetchedNumbers: data.numbers,
        pagerStatus: data.pagerStatus,
      } as FetchOrgPurchasedNumbersResponse;
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }
  };

  const _filterNumbersByAssignee = async (
    orgId: number,
    sortField: string,
    sortOrder: string,
    searchInput: string,
    nextCursor: string,
  ): Promise<FetchOrgPurchasedNumbersResponse> => {
    const extractUserIds = (userArray: UserSearchResponseObject[]): string[] => {
      return userArray.map((userObj) => userObj.user.id);
    };
    let userArray = [];
    let userData = await PVPAApiHelper.searchOrgUsers(searchInput, null);

    userArray.push(...userData.data.searchQuery.searchUsers.users);
    const userCount = userData.data.searchQuery.searchUsers.totalResultsCount;

    if (userCount === 0) {
      return {
        nextResponseCursor: null,
        fetchedNumbers: [],
        pagerStatus: {
          [VirtualPagerStatusTypes.ALL]: 0,
          [VirtualPagerStatusTypes.ACTIVE]: 0,
          [VirtualPagerStatusTypes.DISABLED]: 0,
          [VirtualPagerStatusTypes.PENDING]: 0,
        },
      };
    }

    const userIdArray = extractUserIds(userArray);

    const type = 'userIds';

    const encodedUsers = _encodeAndConcatenateUserIDs(userIdArray);

    try {
      const response = await PVPAApiHelper.fetchOrgPurchasedNumbers(
        orgId,
        nextCursor,
        sortField,
        sortOrder,
        type,
        null,
        null,
        encodedUsers,
      );

      const data = response.data;

      return {
        nextResponseCursor: data.nextCursor,
        fetchedNumbers: data.numbers,
        pagerStatus: data.pagerStatus,
      } as FetchOrgPurchasedNumbersResponse;
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }
  };

  function _encodeAndConcatenateUserIDs(userIds: string[] | string) {
    if (!Array.isArray(userIds) || userIds.length === 0) {
      return '';
    }

    const concantenatedUserIds = userIds.join(',');

    return encodeURIComponent(concantenatedUserIds);
  }

  const fetchOrganizationRoles = async () => {
    const currentDate = moment();

    const currentMonth = currentDate.format('MMMM').toLowerCase();
    const currentYear = parseInt(currentDate.format('YYYY'));

    const fetchedRoles = await PVPAApiHelper.fetchScheduleByMonthAndYear(currentMonth, currentYear);

    const data = fetchedRoles.data?.locating.sites;

    const rolesList: OrganizationRoleFragment[] = !!data ? processRoles(data) : [];

    return rolesList;
  };

  function processRoles(data: Array<{ departments: DepartmentFragment[] }>): OrganizationRoleFragment[] {
    const result: OrganizationRoleFragment[] = [];

    for (const item of data) {
      const departments = item.departments;
      if (departments) {
        for (const department of departments) {
          const departmentName = department.name;
          const roles = department.schedule?.roles;

          if (roles) {
            for (const role of roles) {
              result.push({
                roleId: role.id,
                roleAuditId: role.auditId,
                roleName: role.name,
                department: departmentName,
              });
            }
          }
        }
      }
    }

    return result;
  }

  return {
    fetchAllOrgPurchasedNumbers,
    fetchOrgPurchasedNumbers,
    searchPagers,
    fetchUserbyUserID,
    fetchRolebyRoleID,
    getAssigneeStringMapFromPagerList,
    fetchOrganizationRoles,
  };
};
