import {
  SET_ESCALATION_LADDER,
  SET_LEVEL_POLICY,
  SET_LEVEL_ONCALL,
  DELETE_LEVEL_POLICY,
  DELETE_LEVEL_ONCALL,
  DELETE_ESCALATION_LEVEL,
  CLEAR_ESCALATION_REDUCER,
  CHANGE_ESCALATION_STATE,
} from 'src/constants/actionNames';
import { EscalationActionTypes } from 'src/redux/actions/escalationActions';
import { v4 as uuid } from 'uuid';
import { EscalationLadderStateType, EscalationLevel, EscalationMember } from 'src/types/Escalation';
import Yallist from 'yallist';

// LL using yallist, this is introduced in early prototyping of implementing escalation level
// can simply just use array, since in reality most escalation ladder is not long where tracking head and tail connection is unnecessary

// note that splice is implemented in v4 but has bug, current typescript support is up to v3
// DOC: https://github.com/isaacs/yallist

export interface EscalationReducerState {
  escalationLadder: Yallist<EscalationLevel>;
  escalationLadderState: EscalationLadderStateType;
  escalationLadderName: string;
  escalationLadderID: string;
}

let initialState: EscalationReducerState = {
  escalationLadder: null,
  escalationLadderState: null,
  escalationLadderName: null,
  escalationLadderID: null,
};

const isOncallMemberIdEqualsDroppedOnCallID = (onCallMember: EscalationMember, onCallData: EscalationMember) => {
  return (
    (onCallMember.role && onCallData.role && onCallMember.role.id === onCallData.role.id) ||
    (onCallMember.user && onCallData.user && onCallMember.user.id === onCallData.user.id)
  );
};

// TODO: also pre-populate policies on all proceeding levels after first level (out of scope)
const getEscalationLevelPlaceHolder = (): EscalationLevel => ({
  escalationMembers: [],
  escalationPolicy: null,
  id: uuid(),
});

export const escalationReducer = (state = initialState, action: EscalationActionTypes): typeof initialState => {
  switch (action.type) {
    case SET_ESCALATION_LADDER: {
      const mockLadderLList: Yallist<EscalationLevel> = Yallist.create([
        {
          ...getEscalationLevelPlaceHolder(),
        },
      ]);

      return {
        ...state,
        escalationLadder: mockLadderLList,
        escalationLadderState: 'draft',
        escalationLadderName: '', // only when there is existing escalationLadderName
      };
    }

    case SET_LEVEL_POLICY: {
      const { levelIndex, policyData } = action.payload;
      const targetLevel = state.escalationLadder.get(levelIndex);

      const updatedLevel = {
        ...targetLevel,
        escalationPolicy: {
          id: policyData.id,
          name: policyData.name,
          channels: [
            {
              type: policyData.type,
              numAttempts: policyData.repeatAttempts,
              timeBetweenAttempts: policyData.repeatLength, //ms
            },
          ],
        },
      };

      const updatedLListLadder = state.escalationLadder.map((escalationLevel) => {
        return escalationLevel.id === updatedLevel.id ? updatedLevel : escalationLevel;
      });

      const lastLevel = updatedLListLadder.get(updatedLListLadder.length - 1);
      if (lastLevel.escalationMembers.length > 0 && lastLevel.escalationPolicy) {
        updatedLListLadder.push(getEscalationLevelPlaceHolder());
      }

      return {
        ...state,
        escalationLadder: updatedLListLadder,
      };
    }

    case DELETE_LEVEL_POLICY: {
      const { levelIndex } = action.payload;
      const targetLevel = state.escalationLadder.get(levelIndex);

      const updatedLevel = {
        ...targetLevel,
        escalationPolicy: null,
      };

      const updatedLListLadder = state.escalationLadder.map((escalationLevel) => {
        return escalationLevel.id === updatedLevel.id ? updatedLevel : escalationLevel;
      });

      const lastLevel = updatedLListLadder.get(updatedLListLadder.length - 1);
      if (lastLevel.escalationMembers.length > 0 && lastLevel.escalationPolicy) {
        updatedLListLadder.push(getEscalationLevelPlaceHolder());
      }

      return {
        ...state,
        escalationLadder: updatedLListLadder,
      };
    }

    case SET_LEVEL_ONCALL: {
      const { levelIndex, onCallData, originLevelIndex } = action.payload;
      const targetLevel = state.escalationLadder.get(levelIndex);
      const originLevel =
        originLevelIndex || originLevelIndex === 0 ? state.escalationLadder.get(originLevelIndex) : null;

      // prevent re-entering duplicates
      if (
        targetLevel.escalationMembers.find((onCallMember) =>
          isOncallMemberIdEqualsDroppedOnCallID(onCallMember, onCallData),
        )
      ) {
        return {
          ...state,
        };
      }

      const updatedLevel = {
        ...targetLevel,
        escalationMembers: [...targetLevel.escalationMembers, onCallData],
      };

      const updatedLListLadder = state.escalationLadder.map((escalationLevel) => {
        if (originLevel && escalationLevel.id === originLevel.id) {
          return {
            ...originLevel,
            escalationMembers: [
              ...originLevel.escalationMembers.filter(
                (onCallMember) => !isOncallMemberIdEqualsDroppedOnCallID(onCallMember, onCallData),
              ),
            ],
          };
        }
        return escalationLevel.id === updatedLevel.id ? updatedLevel : escalationLevel;
      });

      const lastLevel = updatedLListLadder.get(updatedLListLadder.length - 1);
      if (lastLevel.escalationMembers.length > 0 && lastLevel.escalationPolicy) {
        updatedLListLadder.push(getEscalationLevelPlaceHolder());
      }

      return {
        ...state,
        escalationLadder: updatedLListLadder,
      };
    }

    case DELETE_LEVEL_ONCALL: {
      const { levelIndex, onCallData } = action.payload;
      const targetLevel = state.escalationLadder.get(levelIndex);

      const updatedLevel = {
        ...targetLevel,
        escalationMembers: [
          ...targetLevel.escalationMembers.filter(
            (onCallMember) => !isOncallMemberIdEqualsDroppedOnCallID(onCallMember, onCallData),
          ),
        ],
      };

      const updatedLListLadder = state.escalationLadder.map((escalationLevel) =>
        escalationLevel.id === updatedLevel.id ? updatedLevel : escalationLevel,
      );

      return {
        ...state,
        escalationLadder: updatedLListLadder,
      };
    }

    case DELETE_ESCALATION_LEVEL: {
      const currentEscalationLadder = state.escalationLadder.toArray();
      currentEscalationLadder.splice(action.payload.index, 1);
      const updatedLListLadder = Yallist.create(currentEscalationLadder);
      return {
        ...state,
        escalationLadder: updatedLListLadder,
      };
    }

    case CLEAR_ESCALATION_REDUCER: {
      return {
        ...initialState,
      };
    }

    case CHANGE_ESCALATION_STATE: {
      // TODO: remove this part, move to save escalation actions, or (SET_ESCALATION_LADDER with all the properties?)

      return {
        ...state,
        escalationLadderState: action.state,
      };
    }

    default:
      return state;
  }
};
