import { initializeApp } from 'firebase/app';
import { getRemoteConfig, isSupported } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
};

const app = initializeApp(firebaseConfig);

export const isFirebaseRemoteConfigSupported = async () => {
  try {
    return isSupported();
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const remoteConfigObject = () => {
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.defaultConfig = { idle_timeout: 900000, forced_timeout: 28800000 };

  return remoteConfig;
};
