import gql from 'graphql-tag';
import ScheduleFragment from 'src/gql/fragment/ScheduleFragment';

export default gql`
  mutation PublishSchedule($departmentId: Int!, $scheduleId: Int!, $notifyOption: NotifyOption, $note: String) {
    admin {
      locating {
        department(id: $departmentId) {
          schedule(id: $scheduleId) {
            publish(notifyOption: $notifyOption, note: $note) {
              ...ScheduleFragment
            }
          }
        }
      }
    }
  }
  ${ScheduleFragment}
`;
