import * as React from 'react';

export default (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="chevron--left" clipPath="url(#clip0_449_68675)">
      <path
        id="Vector"
        d="M8.20711 12.7071C7.81658 12.3166 7.81658 11.6834 8.20711 11.2929L14.475 5.025C14.7649 4.73505 15.2351 4.73505 15.525 5.025C15.8149 5.31495 15.8149 5.78505 15.525 6.075L9.6 12L15.525 17.925C15.8149 18.2149 15.8149 18.6851 15.525 18.975C15.235 19.2649 14.7649 19.2649 14.475 18.975L8.20711 12.7071Z"
        fill="#222222"
      />
    </g>
    <defs>
      <clipPath id="clip0_449_68675">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
