import React, { useEffect, useState } from 'react';

import LineChart from 'src/components/analytics/LineChart';
import { Box, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { GET_ACTIVE_MEMBER_TIMELINE } from 'src/gql/query/AnalyticsQuery';
import { tickNumberFormat, transformActiveMemberPipeline } from 'src/pages/AnalyticsDemoPage/helpers';
import { Period, IActiveMemberPipelineResult } from 'src/types';
import { typedUseSelector } from 'src/redux/store';
import ButtonGroupComponent from 'src/pages/AnalyticsDemoPage/ButtonGroupComponent';
import { TooltipProps } from 'recharts';

const getDateFormat = (period: Period) => {
  switch (period) {
    case 'hourly':
      return 'ddd, MMM DD HH:mm';
    case 'monthly':
      return 'MMMM yyyy';
    default:
      return 'ddd, MMM DD';
  }
};

const getTooltipDateFormat = (period: Period) => {
  switch (period) {
    case 'hourly':
      return 'ddd, MMM DD | HH:mm';
    case 'monthly':
      return 'MMMM yyyy';
    default:
      return 'ddd, MMM DD';
  }
};

const CustomXAxisTick = (props: any) => {
  // console.log(props);
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} fontSize={13} fill="#666">
        <tspan textAnchor="middle" x="0">
          {moment(payload.value).format('ddd, MMM DD')}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="16">
          {moment(payload.value).format('HH:mm')}
        </tspan>
      </text>
    </g>
  );
};

interface IActiveMemberTooltipComponentProps extends TooltipProps {
  period: Period;
}

const ActiveMemberTooltipComponent = (props: IActiveMemberTooltipComponentProps) => {
  if (props.active && props.payload && props.payload[0].payload) {
    const { dataKey } = props.payload[0];
    const { name, active_member, message_sent } = props.payload[0].payload;
    return (
      <Box boxShadow={1} bgcolor="white" padding={1}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            style={{ backgroundColor: '#F5F5F5', padding: 2, marginRight: 4 }}
            color="textSecondary"
            variant="body2"
          >
            {moment(name).format(getTooltipDateFormat(props.period))}
          </Typography>
        </Box>

        {dataKey === 'active_member' && (
          <Box display="flex" flexDirection="row" alignItems="center" marginY={1}>
            <Box>
              <Box height={10} width={10} borderRadius={5} bgcolor="rgb(142,192,202)" marginRight={1} />
            </Box>
            <Box>
              <Typography variant="body2">
                Active members : <span style={{ fontWeight: 'bold' }}>{active_member}</span>
              </Typography>
              {/* <Typography variant="subtitle2" color="textSecondary">9% from previous day</Typography> */}
            </Box>
          </Box>
        )}

        {dataKey === 'message_sent' && (
          <Box display="flex" flexDirection="row" alignItems="center" marginY={1}>
            <Box>
              <Box height={10} width={10} borderRadius={5} bgcolor="rgb(247,200,69)" marginRight={1} />
            </Box>
            <Box>
              <Typography variant="body2">
                Messages sent : <span style={{ fontWeight: 'bold' }}>{message_sent}</span>
              </Typography>
              {/* <Typography variant="subtitle2" color="textSecondary">9% from previous day</Typography> */}
            </Box>
          </Box>
        )}
      </Box>
    );
  }
  return null;
};

const ActiveMemberTimelineChart: React.FC = () => {
  const theme = useTheme();
  const dateRange = typedUseSelector((state) => state.analyticsReducer.dateRange);
  const refreshOn = typedUseSelector((state) => state.analyticsReducer.refreshOn);
  const organizationId = typedUseSelector((state) => state.organizationReducer.organization_id);
  const departmentId = typedUseSelector((state) => state.organizationReducer.department_id);
  const [activePeriod, setActivePeriod] = useState<Period>('daily');

  const activeMemberPipeline = useQuery<IActiveMemberPipelineResult>(GET_ACTIVE_MEMBER_TIMELINE, {
    variables: {
      startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
      groupBy: activePeriod,
      organizationId,
      departmentId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    activeMemberPipeline.refetch();
    // eslint-disable-next-line
  }, [dateRange, activePeriod, organizationId, departmentId, refreshOn]);

  const getTicks = (
    data: {
      name: string;
    }[],
    activePeriod: Period,
  ) => {
    const ticks: any[] = [];

    if (activePeriod === 'daily') {
      if (data.length <= 14) {
        return;
      }
      data.forEach((element) => {
        if (moment(element.name).format('ddd') === 'Mon') {
          ticks.push(element.name);
        }
      });

      if (ticks.length < 1) {
        return;
      }
    }

    if (activePeriod === 'hourly') {
      if (data.length <= 24) {
        return;
      }

      if (data.length <= 336) {
        // 14 days
        return;
      } else {
        data.forEach((element) => {
          if (moment(element.name).format('ddd HH:mm') === 'Mon 00:00') {
            ticks.push(element.name);
          }
        });
      }

      if (ticks.length < 1) {
        return;
      }
    }
    return ticks.length ? ticks : undefined;
  };

  const transformedMemberData = transformActiveMemberPipeline(activeMemberPipeline.data);
  const memberTicks = getTicks(transformedMemberData, activePeriod);
  const memberInterval = !transformedMemberData.length
    ? undefined
    : memberTicks && memberTicks.length
    ? 'preserveStart'
    : Math.max(0, Math.floor(transformedMemberData.length / 6) - 1);

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
        <Box flexGrow="1">
          <Typography variant="body1" style={{ fontSize: 18, fontWeight: theme.typography.fontWeightBold }}>
            Active members
          </Typography>
          <Typography variant="subtitle2">
            How many people are active - sent at least one message within selected time range
          </Typography>
        </Box>
        <ButtonGroupComponent
          active={activePeriod}
          onChange={setActivePeriod}
          values={['hourly', 'daily', 'monthly']}
          enabledValues={['hourly', 'daily', 'monthly']}
        />
      </Box>
      <Box paddingY={4} position="relative">
        <LineChart
          loading={activeMemberPipeline.loading}
          data={transformedMemberData}
          xAxis="name"
          yAxis={['active_member']}
          colors={['rgb(142,192,202)']}
          customTooltip={(props: TooltipProps) => <ActiveMemberTooltipComponent period={activePeriod} {...props} />}
          rightYAxis
          yAxisLabel="Active members"
          height={300}
          width="100%"
          ticks={memberTicks}
          xTickFormatter={(value) => moment(value).format(getDateFormat(activePeriod))}
          tick={activePeriod === 'hourly' ? <CustomXAxisTick /> : undefined}
          tickFormatter={tickNumberFormat}
          rightYAxisTickFormatter={tickNumberFormat}
          interval={memberInterval}
        />

        {/* <Box flexDirection="row" display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(142,192,202)" marginRight={1} marginLeft={3} />
            <Typography>Active members</Typography>
          </Box>
        </Box> */}
      </Box>

      <Box paddingY={4} position="relative">
        <LineChart
          loading={activeMemberPipeline.loading}
          data={transformedMemberData}
          xAxis="name"
          yAxis={['message_sent']}
          colors={['rgb(247,200,69)']}
          customTooltip={(props: TooltipProps) => <ActiveMemberTooltipComponent period={activePeriod} {...props} />}
          rightYAxis
          yAxisLabel="Message sent"
          height={300}
          width="100%"
          ticks={memberTicks}
          xTickFormatter={(value) => moment(value).format(getDateFormat(activePeriod))}
          tick={activePeriod === 'hourly' ? <CustomXAxisTick /> : undefined}
          tickFormatter={tickNumberFormat}
          interval={memberInterval}
        />

        {/* <Box flexDirection="row" display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(247,200,69)" marginRight={1} marginLeft={3} />
            <Typography>Messages sent</Typography>
          </Box>
        </Box> */}
      </Box>
    </React.Fragment>
  );
};

export default ActiveMemberTimelineChart;
