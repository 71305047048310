// @flow
import * as React from 'react';
import { StyledTooltipWhite } from '../../../../components/StyledTooltip';
import {
  ACCESS,
  ALPHANUMERIC_PAGER,
  EMAIL,
  MOBILE_NUMBER,
  PROFILE_PAGE_ADDRESS_RE_ORDER_TOOLTIP_TEXT,
  ProfileAddressMap,
  ProfileVisibiltyMap,
  NUMERIC_PAGER,
} from '../../../../constants/strings';
import { MenuDragIcon } from '../../../../assets/svgs/MenuDragIcon';
import Chevron from '../../../../assets/svgs/Chevron';
import { StyledInputBase } from '../../../../components/forms/profile-form-partials/StepFormPartials';
import { HCLabelOne, LinkButton, TextButton } from '../../../../components/shared/HypercareComponents';
import formatPhoneNumber, { removePlusSignWithNumber } from '../../../../utils/formatPhoneNumber';
import { TrashBinIcon } from '../../../../assets/svgs/TrashBinIcon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { User, UserAddress } from '../../../../types';
import { IsFeatureFlagEnabled } from '../../../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../../../utils/FeatureFlags';
import { useState } from 'react';
import Select from '@material-ui/core/Select';
import theme from '../../../../assets/styles/theme';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { FormikErrors, FormikTouched } from 'formik/dist/types';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import AnalyticsManager, { EVENTS } from '../../../../analytics/AnalyticsManager';
import { FieldArrayRenderProps } from 'formik/dist/FieldArray';
import { Dropdown } from '../../../../components/@hc-ui/Dropdown/Dropdown';
import ProfileViewModel from '../viewModels/ProfileViewModel';
import ChevronDown from '../../../../assets/svgs/ChevronDown';
import ChevronUp from '../../../../assets/svgs/ChevronUp';
import Button from '@material-ui/core/Button';

const selectUseStyles = makeStyles(() => {
  return {
    root: {
      border: 'none',
      display: 'flex',
      gap: '10px',
      alignSelf: 'stretch',
      padding: '0px',
      width: '180px',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: theme.ghostWhite,
      },
    },
    select: {
      '&&': {
        paddingRight: 0,
      },
      '& ul': {
        borderRadius: '4px',
        backgroundColor: '#fff',
        display: 'flex',
        padding: '8px',
        flexDirection: 'column',
        alignItems: 'flexStart',
      },
      '& li': {
        padding: '10px 8px',
      },
    },
    selected: {
      borderRadius: '4px',
      backgroundColor: theme.ghostWhite,
    },
    font: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      textTransform: 'none',
    },
  };
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 8px 0`,
  background: isDragging ? '#F2F2F2' : '',
  ...draggableStyle,
});

const PreferencesCellIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TD = styled.td`
  width: 120px;
  padding: 8px;
  border-bottom: 1px solid #d8d8d8;
  color: #222;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  height: 41px;
`;
const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: '253px',
    padding: '16px !important',
    fontStyle: 'normal !important',
    fontWight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    fontFamily: 'Open Sans !important',
    backgroundColor: '#fff !important',
    background: '#fff !important',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.16) !important',
    borderRdius: '8px !important',
    position: 'relative',
    color: '#FF3E55',
    borderRadius: theme.shape.borderRadius,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '100%', // Position the arrow at the bottom of the tooltip
      left: '10%', // Adjust this value for arrow positioning
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderTop: `8px solid ${theme.palette.common.white}`, // Match the tooltip background color
    },
  },
}))(Tooltip);

const DeleteIconWrapper = styled.div`
  width: 10px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DeleteRowIconHolder = styled.div`
  color: grey;
  position: relative;
  margin-left: 3px;
  user-select: none;
  &:hover {
    background-color: #f2f2f2;
  }
`;

interface Props {
  isInputDisabled: boolean;
  address: UserAddress;
  index: number;
  handleChange: (e: React.ChangeEvent<any>) => void;
  onModeChange: (mode: 'view' | 'edit') => void;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  addresses: UserAddress[];
  arrayHelpers: FieldArrayRenderProps;
  setFieldValue: any;
}

export const HypercareProfileAddressTableRows = ({
  address,
  index,
  isInputDisabled,
  handleChange,
  onModeChange,
  errors,
  touched,
  addresses,
  arrayHelpers,
  setFieldValue,
}: Props) => {
  const [isHoverList, setIsHoverList] = useState(false);
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabels);
  const selectClasses = selectUseStyles();
  const adminAlphanumericPaging = IsFeatureFlagEnabled(FeatureFlagResult.adminAlphanumericPaging);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const viewModel = ProfileViewModel();

  const { data: organizationAddressLabelOptions } = viewModel.useGetOrganizationLabelOptions();

  const handleDeleteContactMethodButtonPressed = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileRemoveButtonPressed,
    });
  };

  const onLabelOptionChange = (o: string) => {
    const newAddressArray = addresses.map((a: UserAddress) => (a.id === address.id ? { ...a, label: o } : a));
    setFieldValue('addresses', newAddressArray);
  };

  return (
    <Draggable
      key={`addresses${index}`}
      draggableId={address.address + index}
      index={index}
      isDragDisabled={isInputDisabled || !isHoverList}
    >
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <TD>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {!isInputDisabled && (
                <StyledTooltipWhite
                  arrow
                  title={PROFILE_PAGE_ADDRESS_RE_ORDER_TOOLTIP_TEXT}
                  placement="left"
                  className="styledPopper"
                >
                  <PreferencesCellIconWrapper
                    onMouseEnter={() => {
                      setIsHoverList(true);
                    }}
                    onMouseLeave={() => {
                      setIsHoverList(false);
                    }}
                  >
                    <MenuDragIcon />
                  </PreferencesCellIconWrapper>
                </StyledTooltipWhite>
              )}
              {index + 1}
            </div>
          </TD>
          {profileAddressLabelFlag && (
            <TD>
              {!isInputDisabled ? (
                <>
                  {' '}
                  <Button
                    className={selectClasses.root}
                    ref={setAnchorElement}
                    onClick={() => setIsOptionsOpen(true)}
                    type={'button'}
                  >
                    <span className={selectClasses.font} style={{ color: !address.label ? theme.errorRed : undefined }}>
                      {address.label || 'Select a label...'}
                    </span>
                    {isOptionsOpen ? (
                      <ChevronUp style={{ color: !address.label ? theme.errorRed : undefined }} />
                    ) : (
                      <ChevronDown style={{ color: !address.label ? theme.errorRed : undefined }} />
                    )}
                  </Button>
                  <Dropdown
                    isOpen={isOptionsOpen}
                    options={organizationAddressLabelOptions}
                    getOptionId={(o) => o}
                    getIsOptionDisabled={(o) => {
                      return false;
                    }}
                    getIsOptionSelected={(_o) => false}
                    onOptionClick={(o) => {
                      onLabelOptionChange(o);
                      setIsOptionsOpen(false);
                    }}
                    onClickAway={() => setIsOptionsOpen(false)}
                    renderOptionContent={(o) => o}
                    anchorElement={anchorElement}
                  />
                </>
              ) : (
                <>
                  {address.label ? (
                    <span>{address.label}</span>
                  ) : (
                    <LinkButton onClick={() => onModeChange('edit')}>{'Add label'}</LinkButton>
                  )}
                </>
              )}
            </TD>
          )}
          {!isInputDisabled ? (
            <TD>
              <Select
                inputProps={{
                  id: `addresses.${index}.type`,
                  name: `addresses.${index}.type`,
                }}
                disabled={address.syncStatus}
                variant="outlined"
                classes={{
                  root: selectClasses.root,
                }}
                value={address.type}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  disablePortal: true,
                  classes: { paper: selectClasses.select },
                }}
                IconComponent={!address.syncStatus ? Chevron : () => null}
                input={<StyledInputBase />}
              >
                <MenuItem value={'phoneNumber'} classes={{ selected: selectClasses.selected }}>
                  <HCLabelOne>{MOBILE_NUMBER}</HCLabelOne>
                </MenuItem>
                <MenuItem value={'email'} classes={{ selected: selectClasses.selected }}>
                  <HCLabelOne>{EMAIL}</HCLabelOne>
                </MenuItem>
                {adminAlphanumericPaging && (
                  <MenuItem value={'alphanumeric_pager'} classes={{ selected: selectClasses.selected }}>
                    <HCLabelOne>{ALPHANUMERIC_PAGER}</HCLabelOne>
                  </MenuItem>
                )}
                <MenuItem value={'pager'} classes={{ selected: selectClasses.selected }}>
                  <HCLabelOne>{NUMERIC_PAGER}</HCLabelOne>
                </MenuItem>
              </Select>
            </TD>
          ) : (
            <TD>{ProfileAddressMap[address.type]}</TD>
          )}
          {!isInputDisabled ? (
            <TD style={{ width: '349px', minWidth: '249px' }}>
              <CustomTooltip
                placement="top-start"
                open={Boolean(
                  errors.addresses &&
                    errors.addresses[index] &&
                    errors.addresses[index].address &&
                    touched.addresses &&
                    touched.addresses[index],
                )}
                title={
                  errors.addresses && errors.addresses[index] && touched.addresses && touched.addresses[index]
                    ? errors.addresses[index].address
                    : ''
                }
              >
                <TextField
                  key="contact"
                  fullWidth
                  disabled={address.syncStatus}
                  style={{ marginTop: 0, marginBottom: 0, margin: 0, maxHeight: 25 }}
                  id={`addresses.${index}.address`}
                  name={`addresses.${index}.address`}
                  value={address.type === 'phoneNumber' ? removePlusSignWithNumber(address.address) : address.address}
                  InputProps={
                    address.type === 'phoneNumber'
                      ? {
                          classes: {
                            input: selectClasses.font,
                          },
                          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                          disableUnderline: true,
                        }
                      : {
                          classes: {
                            input: selectClasses.font,
                          },
                          disableUnderline: true,
                        }
                  }
                  margin="normal"
                  onChange={handleChange}
                  error={Boolean(
                    errors.addresses &&
                      errors.addresses[index] &&
                      errors.addresses[index].address &&
                      touched.addresses &&
                      touched.addresses[index],
                  )}
                />
              </CustomTooltip>
            </TD>
          ) : (
            <TD style={{ width: '349px', height: '38px' }}>
              {address?.type === 'phoneNumber' ? formatPhoneNumber(address?.address) : address?.address}
            </TD>
          )}
          {!isInputDisabled ? (
            <TD>
              <Select
                inputProps={{
                  id: `addresses.${index}.access`,
                  name: `addresses.${index}.access`,
                }}
                variant="outlined"
                classes={{
                  root: selectClasses.root,
                }}
                value={address.access}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  disablePortal: true,
                  classes: { paper: selectClasses.select },
                }}
                IconComponent={!isInputDisabled ? Chevron : () => null}
                input={<StyledInputBase />}
              >
                <MenuItem value={'public'} classes={{ selected: selectClasses.selected }}>
                  <HCLabelOne>{ACCESS.PUBLIC}</HCLabelOne>
                </MenuItem>
                <MenuItem value={'private'} classes={{ selected: selectClasses.selected }}>
                  <HCLabelOne>{ACCESS.PRIVATE}</HCLabelOne>
                </MenuItem>
              </Select>
            </TD>
          ) : (
            <TD>{ProfileVisibiltyMap[address.access]}</TD>
          )}
          {addresses.length >= 1 && !isInputDisabled && (
            <TD>
              {addresses.length >= 1 && !isInputDisabled && (
                <DeleteIconWrapper>
                  <TextButton disabled={address.syncStatus}>
                    <DeleteRowIconHolder onClick={() => handleDeleteContactMethodButtonPressed(arrayHelpers, index)}>
                      {address.syncStatus ? <TrashBinIcon color={'#BCBCBC'} /> : <TrashBinIcon color={'#FF3E55'} />}
                    </DeleteRowIconHolder>
                  </TextButton>
                </DeleteIconWrapper>
              )}
            </TD>
          )}
        </tr>
      )}
    </Draggable>
  );
};
