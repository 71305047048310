import React from 'react';
import styled from 'styled-components';
import ListIcon from '@material-ui/icons/List';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { LISTSCHEDULINGVIEW, GRIDSCHEDULINGVIEW } from 'src/constants/scheduler';
import ScheduleToggleButton from 'src/assets/svgs/ScheduleToggleButton';

const StyledToggleButton = styled(ToggleButton)`
  padding: 0 16px 0 16px !important;
  span {
    color: #000;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
  }
`;

/**
 * Toggle button shown on top right corner of the scheduling page for toggling
 * between list and grid views of schedule
 * @param onChange () => {} Function to execute when the other toggle button is clicked
 * @param value string selected value of the toggle button
 */
const SchedulingToggleButton = ({ onChange, value }) => {
  return (
    <ToggleButtonGroup
      style={{ float: 'right', height: '100%' }}
      size="small"
      value={value}
      onChange={onChange}
      aria-label="toggle schedule view"
    >
      <StyledToggleButton
        selected={value === GRIDSCHEDULINGVIEW}
        value={GRIDSCHEDULINGVIEW}
        aria-label={GRIDSCHEDULINGVIEW}
      >
        <ScheduleToggleButton /> &nbsp; Calendar view
      </StyledToggleButton>
      <StyledToggleButton
        selected={value === LISTSCHEDULINGVIEW}
        value={LISTSCHEDULINGVIEW}
        aria-label={LISTSCHEDULINGVIEW}
      >
        <ListIcon /> &nbsp; List view
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default SchedulingToggleButton;
