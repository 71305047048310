import moment from 'moment';

export const formatStringToMomentObject = (dateString: string) => {
  // match calendar initilized datetime (local zone)
  let momentObj = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
  momentObj = momentObj.set({
    hour: 12,
    minute: parseInt('00'),
    second: parseInt('00'),
  }) as moment.Moment;

  return momentObj;
};
