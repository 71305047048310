import gql from 'graphql-tag';
import { FullOrganizationMemberFragment } from 'src/gql/fragment/FullOrganizationMemberFragment';
import { PaginatedUsersType } from 'src/types/PaginatedTypes';

export type PaginatedLicensedMembers = {
  continuationId: string;
  totalResultsCount: number;
  users: PaginatedUsersType[];
};

export type FetchPaginatedUsersQueryResponse = {
  adminQuery: {
    organizationalUnit: {
      paginatedMembers: PaginatedLicensedMembers;
    };
  };
};
export const FETCH_PAGINATED_USERS_QUERY = gql`
  query FetchPaginatedUsers(
    $organizationalUnit: OrganizationalUnitInput!
    $direction: PaginationDirection
    $continuationId: ID
    $isDirSyncFeatureFlagEnabled: Boolean!
    $limit: Int
  ) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            paginatedMembers(continuationId: $continuationId, direction: $direction, limit: $limit) {
              ...PaginatedUsersAdminQueryFragment
            }
          }
          ... on AdminSiteQuery {
            paginatedMembers(continuationId: $continuationId, direction: $direction, limit: $limit) {
              ...PaginatedUsersAdminQueryFragment
            }
          }
          ... on AdminDepartmentQuery {
            paginatedMembers(continuationId: $continuationId, direction: $direction, limit: $limit) {
              ...PaginatedUsersAdminQueryFragment
            }
          }
        }
      }
    }
  }

  fragment PaginatedUsersAdminQueryFragment on PaginatedUsersAdminQuery {
    continuationId
    totalResultsCount
    pageSize
    users {
      ...FullOrganizationMemberFragment
    }
  }

  ${FullOrganizationMemberFragment}
`;
