import React from 'react';
import moment from 'moment';
import clonedeep from 'lodash.clonedeep';
import DeleteShiftMutation from 'src/gql/mutation/DeleteShiftMutation';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import store from 'src/redux/store';
import ReactModal from 'react-modal';
import client from 'src/clients/apolloClient';
import { Assignee, RemoveShiftPayload } from 'src/types';
import sleep from 'src/utils/sleep';
import { CANT_MUTATE_PAST_SHIFT, CANT_MUTATE_ARCHIVED_SHIFT } from 'src/constants/networkError';
import MetricaidComponent, { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';

interface RemoveShiftMutationProps {
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  removeShiftPayload: RemoveShiftPayload;
  handleCloseModal: () => void;
}

const RemoveShiftMutation = ({
  isLoading,
  setLoading,
  handleCloseModal,
  removeShiftPayload,
}: RemoveShiftMutationProps) => {
  const [metricaidErrorState, setMetricaidErrorState] = React.useState<boolean>(false);

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') handleRemoveShift();
    return;
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveShift = async () => {
    const { department_id } = store.getState().organizationReducer;
    const { scheduleId } = store.getState().monthlyScheduleReducer;
    const { shift } = removeShiftPayload;
    const { shiftId } = shift;

    setLoading(true);

    try {
      await client.mutate({
        mutation: DeleteShiftMutation,
        variables: {
          shiftId,
          scheduleId,
          departmentId: department_id,
        },
      });
      await sleep(500);
      // TODO: deletion performance bug
      await client.reFetchObservableQueries();
      toast.success('This shift has been removed.', {
        className: 'Toast-Container',
      });
      handleCloseModal();
    } catch (e) {
      console.error(e);
      // TODO: backend needs to resolve with 500, currently still a 200 status thus gqlError
      if (
        e.graphQLErrors &&
        e.graphQLErrors[0] &&
        (e.graphQLErrors[0].code === CANT_MUTATE_PAST_SHIFT || e.graphQLErrors[0].code === CANT_MUTATE_ARCHIVED_SHIFT)
      ) {
        await client.reFetchObservableQueries();
        toast.error('Unable to remove passed shift.', {
          className: 'Toast-Container',
        });
        handleCloseModal();
      } else if (e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].code === METRICAIDERRORCODE) {
        setMetricaidErrorState(true);
      } else {
        toast.error('Error when removing this shift.', {
          className: 'Toast-Container',
        });
        handleCloseModal();
      }
    }
    setLoading(false);
  };

  const getRealStartTimeEndTime = () => {
    const { shift, role, dateString } = removeShiftPayload;
    const { monthlyCalendar } = store.getState().monthlyScheduleReducer;
    const { startTime, endTime, shiftId } = shift;
    const { index } = role;
    const dateStringMomentObj = moment(dateString);
    let realStartTime = startTime;
    let realEndTime = endTime;

    const isPreviousDateInSameMonth =
      clonedeep(dateStringMomentObj).subtract(1, 'days').month() === dateStringMomentObj.month();
    const isNextDateInSameMonth = clonedeep(dateStringMomentObj).add(1, 'days').month() === dateStringMomentObj.month();

    const setShiftTimeByDateString = (dateString: string, isPrev: boolean) => {
      const crossDateShift: Assignee = monthlyCalendar[dateString][index].assignee.find(
        (shift: Assignee) => shift.shiftId === shiftId,
      );

      if (!crossDateShift) return;
      if (isPrev) {
        realStartTime = crossDateShift.startTime;
      } else {
        realEndTime = crossDateShift.endTime;
      }
    };

    if (isPreviousDateInSameMonth) {
      const prevDateString = clonedeep(dateStringMomentObj).subtract(1, 'days').format('YYYY-MM-DD');
      setShiftTimeByDateString(prevDateString, true);
    } else if (isNextDateInSameMonth) {
      const nextDateString = clonedeep(dateStringMomentObj).add(1, 'days').format('YYYY-MM-DD');
      setShiftTimeByDateString(nextDateString, false);
    }

    return {
      realStartTime,
      realEndTime,
    };
  };

  return (
    <Mutation mutation={DeleteShiftMutation}>
      {() => {
        const { shift, role } = removeShiftPayload;
        const { realStartTime, realEndTime } = getRealStartTimeEndTime();

        return (
          <React.Fragment>
            <div className="modal__confirmation__statement">
              <p>
                Are you sure you want to remove <span>{shift.userFullName}</span>
                's shift from <span>{role.roleName}</span>?
              </p>
              {metricaidErrorState && <MetricaidComponent />}
              <p className="timeText">
                - Start Time: <span>{realStartTime.format('YYYY-MM-DD HH:mm')}</span>
              </p>
              <p className="timeText">
                - End Time: <span>{realEndTime.format('YYYY-MM-DD HH:mm')}</span>
              </p>
            </div>
            <div className="modal__confirmation__buttons">
              <Button
                type="button"
                disabled={isLoading}
                variant="outlined"
                disableTouchRipple
                onClick={handleCloseModal}
              >
                cancel
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleRemoveShift}
                disableTouchRipple
              >
                {isLoading ? 'sending request...' : 'delete shift'}
              </Button>
            </div>
          </React.Fragment>
        );
      }}
    </Mutation>
  );
};

interface RemoveShiftModalProps {
  showModal: boolean;
  removeShiftPayload: RemoveShiftPayload;
  handleCloseModal: () => void;
}

const ScheduleRemoveShiftModal = ({ showModal, removeShiftPayload, handleCloseModal }: RemoveShiftModalProps) => {
  const [isLoading, setLoading] = React.useState(false);

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__confirmation"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={handleCloseModal}
    >
      <div className="close" onClick={handleCloseModal} />
      <RemoveShiftMutation
        isLoading={isLoading}
        setLoading={setLoading}
        handleCloseModal={handleCloseModal}
        removeShiftPayload={removeShiftPayload}
      />
    </ReactModal>
  );
};

export default ScheduleRemoveShiftModal;
