import React from 'react';
import theme from 'src/assets/styles/theme';
import Checkmark from 'src/assets/svgs/Checkmark';
import FailureIcon from 'src/assets/svgs/FailureIcon';
import { HCLabelOne } from 'src/components/shared/HypercareComponents';
import {
  NUMBER_CELL_PURCHASE_FAILED,
  NUMBER_CELL_PURCHASE_SUCCESS,
  NUMBER_CELL_PURCHASING,
} from 'src/constants/virtualPagerStrings';
import { PagerPurchasedState } from 'src/types';
import styled from 'styled-components';

interface Props {
  formattedList: string[];
  addSecondCell: boolean;
  successList?: { [key: string]: PagerPurchasedState };
}

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${theme.borderBottomLight};
`;

const TC = styled.td`
  height: 40px !important;
  text-align: left;
  padding: 8px 0px 8px 12px;
  justify-content: center;
`;

const Loader = styled.div`
  border: 2.25px solid #2222224d;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  border-top: 2.25px solid #555;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

const Label = styled.td`
  color: ${(props) => (props.color ? props.color : '#767676')};
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
`;

const StyledTBody = styled.tbody`
  overflow: auto;
`;

const NumberCell = ({ formattedList, addSecondCell, successList }: Props) => {
  return (
    <div>
      {!addSecondCell ? (
        <div>
          <CustomTable>
            <StyledTBody>
              {formattedList.map((number, index) => (
                <tr key={index}>
                  <TC style={{ borderBottom: '1px solid #d8d8d8' }}>
                    <HCLabelOne>{number}</HCLabelOne>
                  </TC>
                </tr>
              ))}
            </StyledTBody>
          </CustomTable>
        </div>
      ) : successList ? (
        <div>
          <CustomTable>
            <StyledTBody>
              {Object.entries(successList).map(([number, state]) => (
                <tr key={number} style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TC style={{ width: '318px', height: '40px' }}>
                    <HCLabelOne>{number}</HCLabelOne>
                  </TC>
                  {state === 'success' ? (
                    <TC style={{ display: 'flex', gap: '8px', padding: '8px 16px 8px 102px' }}>
                      <Label color="#00859A">{NUMBER_CELL_PURCHASE_SUCCESS}</Label>
                      <Checkmark />
                    </TC>
                  ) : (
                    <TC style={{ display: 'flex', gap: '8px', padding: '8px 16px 8px 60px' }}>
                      <Label color="#FF3E55">{NUMBER_CELL_PURCHASE_FAILED}</Label>
                      <FailureIcon />
                    </TC>
                  )}
                </tr>
              ))}
            </StyledTBody>
          </CustomTable>
        </div>
      ) : (
        <div>
          <CustomTable>
            <StyledTBody>
              {formattedList.map((number, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TC style={{ width: '318px' }}>
                    <HCLabelOne>{number}</HCLabelOne>
                  </TC>
                  <TC style={{ display: 'flex', gap: '8px', padding: '8px 16px 8px 26px' }}>
                    <Label>{NUMBER_CELL_PURCHASING}</Label>
                    <Loader />
                  </TC>
                </tr>
              ))}
            </StyledTBody>
          </CustomTable>
        </div>
      )}
    </div>
  );
};

export default NumberCell;
