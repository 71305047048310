import React from 'react';
import { StyledModal, CloseBtn, Header, Footer } from 'src/components/modals/ModalStyleComponents';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { HCHeadingThree, HCLabelOne, PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';
import ConfigureNumberViewModel from './ConfigureNumberViewModel';
import { VirtualPagerStatusTypes, VirtualPagers } from 'src/types';

import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import {
  CANCEL,
  REACTIVATE,
  REACTIVATING_NUMBER_HEADER,
  REACTIVATING_NUMBER_WARNING_TEXT,
} from 'src/constants/virtualPagerStrings';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const StylizedModal = styled(StyledModal)`
  .ReactModal__Content {
    width: 480px;
    padding: 0;
  }
`;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 12px;
`;

const ModalFooter = styled(Footer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 16px 24px;
  gap: 8px;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  pager: VirtualPagers;
}

const ModalReactivateNumber = ({ isOpen, closeModal, pager }: Props) => {
  const { configureStatus } = ConfigureNumberViewModel();
  const dispatch = useDispatch();

  const handleClick = async () => {
    const newPager = await configureStatus(pager._id, VirtualPagerStatusTypes.ACTIVE);
    dispatch(allActions.virtualPagerAction.setEditedAndCurrentPager(newPager));
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaReactivateNumberConfirmPressed,
      params: {
        virtual_pager_number: pager.pagerNumber,
      },
    });
    closeModal();
  };

  return (
    <StylizedModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <ModalContainer>
        <Header>
          <HCHeadingThree>{REACTIVATING_NUMBER_HEADER}</HCHeadingThree>
          <CloseBtn onClick={closeModal} />
        </Header>
        <HCLabelOne>{REACTIVATING_NUMBER_WARNING_TEXT}</HCLabelOne>
      </ModalContainer>
      <ModalFooter>
        <SecondaryButton onClick={closeModal} color={theme.mainFontColor}>
          {CANCEL}
        </SecondaryButton>
        <PrimaryButton disabled={false} onClick={handleClick}>
          {REACTIVATE}
        </PrimaryButton>
      </ModalFooter>
    </StylizedModal>
  );
};

export default ModalReactivateNumber;
