import React from 'react';
import client from 'src/clients/apolloClient';
import { DELETE_SELF_PROFILE_NOTE } from 'src/gql/v2/mutation/DeleteSelfProfileNoteMutation';
import { CREATE_SELF_PROFILE_NOTE } from 'src/gql/v2/mutation/CreateSelfProfileNoteMutation';
import FetchSelfProfileNotes from 'src/gql/v2/query/FetchSelfProfileNotesQuery';
import {
  AdminFetchProfileNotesbyMemberResult,
  EditNotesFormValues,
  FetchRoleNotesForDepartmentResult,
  IRoleNoteRequest,
  SelfProfileNotesResult,
} from 'src/types';
import { UPDATE_SELF_PROFILE_NOTE } from 'src/gql/v2/mutation/UpdateSelfProfileNoteMutation';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import FetchProfileNotesByMemberQuery from 'src/gql/v2/query/FetchProfileNotesByMemberQuery';
import { ADMIN_CREATE_PROFILE_NOTE } from 'src/gql/v2/mutation/AdminCreateProfileNoteMutation';
import { ADMIN_DELETE_PROFILE_NOTE } from 'src/gql/v2/mutation/AdminDeleteProfileNoteMutation';
import { ADMIN_UPDATE_PROFILE_NOTE } from 'src/gql/v2/mutation/AdminUpdateProfileNoteMutation';
import { ADMIN_CREATE_ROLE_NOTE } from 'src/gql/v2/mutation/AdminCreateRoleNoteMutation';
import { ADMIN_UPDATE_ROLE_NOTE } from 'src/gql/v2/mutation/AdminUpdateRoleNoteMutation';
import { ADMIN_DELETE_ROLE_NOTE } from 'src/gql/v2/mutation/AdminDeleteRoleNoteMutation';
import { FETCH_DEPARTMENT_ROLES_NOTES } from 'src/gql/v2/query/FetchRolesNotesForDepartment';
import { ApolloQueryResult } from 'apollo-client';

interface HiddenNotesRepositoryProps {
  deleteSelfProfileNote: (noteId: string) => Promise<{ error: boolean | null; result: any }>;
  createSelfProfileNote: (noteId: string, access: string) => Promise<{ error: boolean | null; result: any }>;
  updateSelfProfileNote: (values: EditNotesFormValues) => Promise<{ error: boolean | null; result: any }>;
  adminCreateProfileNote: (
    userId: string,
    description: string,
    access: string,
  ) => Promise<{ error: boolean | null; result: any }>;
  adminDeleteProfileNote: (userId: string, noteId: string) => Promise<{ error: boolean | null; result: any }>;
  adminUpdateProfileNote: (
    userId: string,
    noteId: string,
    description: string,
    access: string,
  ) => Promise<{ error: boolean | null; result: any }>;
  adminCreateRoleNote: (values: {
    scheduleId: number;
    roleId: number;
    details: { note: string; access: string };
  }) => Promise<{ error: boolean | null; result: any }>;
  adminUpdateRoleNote: (values: {
    scheduleId: number;
    roleId: number;
    details: { note: string; access: string };
  }) => Promise<{ error: boolean | null; result: any }>;
  adminDeleteRoleNote: (
    scheduleId?: number,
    roleId?: number,
    noteId?: string,
  ) => Promise<{ error?: boolean | null; result?: any }>;
  fetchRoleNotesForDepartment: ({
    scheduleId,
  }: {
    scheduleId: number;
  }) => Promise<{ error: boolean | null; result: ApolloQueryResult<FetchRoleNotesForDepartmentResult> }>;
}

export default (): HiddenNotesRepositoryProps => {
  const deleteSelfProfileNote = async (noteId: string) => {
    try {
      const result = await client.mutate({
        mutation: DELETE_SELF_PROFILE_NOTE,
        variables: {
          id: noteId,
        },
        update: (client, { data }) => {
          let cachedData = client.readQuery<SelfProfileNotesResult>({ query: FetchSelfProfileNotes });

          const newData = {
            selfQuery: {
              notes: cachedData.selfQuery.notes.filter((note) => note.id !== noteId),
              __typename: 'SelfUser',
            },
          };

          client.writeQuery({
            query: FetchSelfProfileNotes,
            data: newData,
          });
        },
      });

      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const createSelfProfileNote = async (description: string, access: string) => {
    try {
      const result = await client.mutate({
        mutation: CREATE_SELF_PROFILE_NOTE,
        variables: {
          details: {
            note: description,
            access,
          },
        },
        update: (client, { data }) => {
          const newProfileNoteObject = data.selfMutation.createNote;
          let cachedData = client.readQuery<SelfProfileNotesResult>({ query: FetchSelfProfileNotes });

          const newCachedData = {
            selfQuery: {
              notes: [...cachedData.selfQuery.notes, newProfileNoteObject],
              __typename: 'SelfUser',
            },
          };

          client.writeQuery({
            query: FetchSelfProfileNotes,
            data: newCachedData,
          });
        },
      });

      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const updateSelfProfileNote = async ({ description, access, noteId }: EditNotesFormValues) => {
    try {
      const result = await client.mutate({
        mutation: UPDATE_SELF_PROFILE_NOTE,
        variables: {
          id: noteId,
          details: {
            note: description.trim(),
            access,
          },
        },
      });

      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const adminCreateProfileNote = async (userId: string, description: string, access: string) => {
    try {
      const result = await client.mutate({
        mutation: ADMIN_CREATE_PROFILE_NOTE,
        variables: {
          userId,
          organizationalUnit: checkOrganizationalUnit(),
          details: {
            note: description,
            access,
          },
        },
        update: (client, { data }) => {
          let cachedData = client.readQuery<AdminFetchProfileNotesbyMemberResult>({
            query: FetchProfileNotesByMemberQuery,
            variables: {
              id: userId,
              organizationalUnit: checkOrganizationalUnit(),
            },
          });

          const newProfileNoteObject = data.adminMutation?.organizationalUnit?.member?.createNote;

          const newCachedData = {
            adminQuery: {
              organizationalUnit: {
                member: {
                  id: cachedData.adminQuery.organizationalUnit.member.id,
                  notes: [newProfileNoteObject, ...cachedData.adminQuery.organizationalUnit.member.notes],
                  __typename: 'FullOrganizationMember',
                },
                __typename: 'AdminOrganizationQuery',
              },
              __typename: 'AdminQuery',
            },
          };

          client.writeQuery({
            query: FetchProfileNotesByMemberQuery,
            data: newCachedData,
            variables: {
              id: userId,
              organizationalUnit: checkOrganizationalUnit(),
            },
          });
        },
      });

      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const adminDeleteProfileNote = async (userId: string, noteId: string) => {
    try {
      const result = await client.mutate({
        mutation: ADMIN_DELETE_PROFILE_NOTE,
        variables: {
          userId,
          organizationalUnit: checkOrganizationalUnit(),
          noteId,
        },
        update: (client, { data }) => {
          let cachedData = client.readQuery<AdminFetchProfileNotesbyMemberResult>({
            query: FetchProfileNotesByMemberQuery,
            variables: {
              id: userId,
              organizationalUnit: checkOrganizationalUnit(),
            },
          });

          const deletedNoteId = data.adminMutation?.organizationalUnit?.member?.note?.deleteNote?.id;

          const newCachedData = {
            adminQuery: {
              organizationalUnit: {
                member: {
                  id: cachedData.adminQuery.organizationalUnit.member.id,
                  notes: cachedData.adminQuery.organizationalUnit.member.notes.filter(
                    (note) => note.id !== deletedNoteId,
                  ),
                  __typename: 'FullOrganizationMember',
                },
                __typename: 'AdminOrganizationQuery',
              },
              __typename: 'AdminQuery',
            },
          };

          client.writeQuery({
            query: FetchProfileNotesByMemberQuery,
            data: newCachedData,
            variables: {
              id: userId,
              organizationalUnit: checkOrganizationalUnit(),
            },
          });
        },
      });

      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const adminUpdateProfileNote = async (userId: string, noteId: string, description: string, access: string) => {
    try {
      const result = await client.mutate({
        mutation: ADMIN_UPDATE_PROFILE_NOTE,
        variables: {
          userId,
          organizationalUnit: checkOrganizationalUnit(),
          details: {
            note: description,
            access,
          },
          noteId,
        },
      });

      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  const adminCreateRoleNote = async ({ scheduleId, roleId, details }: IRoleNoteRequest) => {
    try {
      const result = await client.mutate({
        mutation: ADMIN_CREATE_ROLE_NOTE,
        variables: {
          scheduleId,
          roleId,
          organizationalUnit: checkOrganizationalUnit(),
          details: {
            note: details.note.trim(),
            access: details.access,
          },
        },
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };
  const adminUpdateRoleNote = async ({ scheduleId, roleId, details, noteId }: IRoleNoteRequest) => {
    try {
      const result = await client.mutate({
        mutation: ADMIN_UPDATE_ROLE_NOTE,
        variables: {
          scheduleId,
          roleId,
          noteId,
          organizationalUnit: checkOrganizationalUnit(),
          details: {
            note: details.note.trim(),
            access: details.access,
          },
        },
      });
      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };
  const adminDeleteRoleNote = async (scheduleId: number, roleId: number, noteId: string) => {
    try {
      const result = await client.mutate({
        mutation: ADMIN_DELETE_ROLE_NOTE,
        variables: {
          scheduleId,
          roleId,
          noteId,
          organizationalUnit: checkOrganizationalUnit(),
        },
      });
      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };
  const fetchRoleNotesForDepartment = async ({ scheduleId }) => {
    try {
      const result: ApolloQueryResult<FetchRoleNotesForDepartmentResult> = await client.query({
        query: FETCH_DEPARTMENT_ROLES_NOTES,
        variables: {
          scheduleId,
          organizationalUnit: checkOrganizationalUnit(),
        },
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({ error: null, result });
    } catch (err) {
      return Promise.resolve({ error: err.message, result: null });
    }
  };

  return {
    deleteSelfProfileNote,
    createSelfProfileNote,
    updateSelfProfileNote,
    adminCreateProfileNote,
    adminDeleteProfileNote,
    adminUpdateProfileNote,
    adminCreateRoleNote,
    adminDeleteRoleNote,
    adminUpdateRoleNote,
    fetchRoleNotesForDepartment,
  };
};
