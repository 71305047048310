import React, { useState } from 'react';
import { PaginatedLicensedMembers } from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import { PaginatedBlackListedMembers } from 'src/gql/v2/query/FetchPaginatedRemovedUsersQuery';
import { PaginatedPendingInvites } from 'src/gql/v2/query/FetchPaginatedInvitesQuery';
export const usePaginatedSearchState = () => {
  const [searchLicensedUserData, setSearchLicensedUserData] = useState<PaginatedLicensedMembers>(null);
  const [searchBlackListedUserData, setSearchBlackListedUserData] = useState<PaginatedBlackListedMembers>(null);
  const [searchPendingInviteData, setSearchPendingInviteData] = useState<PaginatedPendingInvites>(null);
  const [searchPendingAdminApprovalInviteData, setSearchPendingAdminApprovalInviteData] =
    useState<PaginatedPendingInvites>(null);

  const [localSearchText, setLocalSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  return {
    searchLicensedUserData,
    searchBlackListedUserData,
    searchPendingInviteData,
    setSearchLicensedUserData,
    setSearchBlackListedUserData,
    setSearchPendingInviteData,
    searchPendingAdminApprovalInviteData,
    setSearchPendingAdminApprovalInviteData,
    localSearchText,
    searchLoading,
    setLocalSearchText,
    setSearchLoading,
  };
};
