import gql from 'graphql-tag';

export const ADMIN_GET_MEMBER_SYNC_STATUS = gql`
  query FetchDirectorySyncStatus(
    $organizationalUnit: OrganizationalUnitInput!
    $isDirSyncFeatureFlagEnabled: Boolean!
  ) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationQuery {
            __typename
            syncStatus @include(if: $isDirSyncFeatureFlagEnabled)
          }
          ... on AdminSiteQuery {
            __typename
            syncStatus @include(if: $isDirSyncFeatureFlagEnabled)
          }
          ... on AdminDepartmentQuery {
            __typename
            syncStatus @include(if: $isDirSyncFeatureFlagEnabled)
          }
        }
      }
    }
  }
`;
