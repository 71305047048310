import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import styled from 'styled-components';
import { HypercareUI } from '../../../../components/@hc-ui';
import theme from '../../../../assets/styles/theme';
import PlusButton from '../../../../assets/svgs/PlusButton';
import { PROFILE_PAGE_ADD_ANOTHER_FIELD_TEXT } from '../../../../constants/strings';
import { Dropdown } from '../../../../components/@hc-ui/Dropdown/Dropdown';
import { CPFField } from '../viewModels/CustomFieldsViewModel';
import { FullOrganizationMember, User } from '../../../../types';

type ProfileConfigurableFieldsSectionProps = {
  title: string;
  mode: 'view' | 'edit';
  isAddFieldDisabled: boolean;
  addFieldOptions: CPFField[];
  user: User;
  onAddField: (field: CPFField) => void;
  children: React.ReactNode;
};

const CPFSectionCard = styled(HypercareUI.Card)`
  margin-bottom: 16px;
  padding-top: 18px;
`;

export const ProfileConfigurableFieldsSection = (props: ProfileConfigurableFieldsSectionProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  return (
    <CPFSectionCard title={props.title}>
      <Grid container direction="column">
        <Grid item container direction="column" style={{ padding: '8px 0' }}>
          {props.children}
        </Grid>
        <Grid item container style={{ color: theme.mainTealColor }}>
          {props.mode === 'edit' && (
            <Button
              variant="text"
              color="inherit"
              startIcon={<PlusButton />}
              disabled={props.isAddFieldDisabled}
              onClick={() => setIsOptionsOpen(!isOptionsOpen)}
              ref={setAnchorElement}
            >
              {PROFILE_PAGE_ADD_ANOTHER_FIELD_TEXT}
            </Button>
          )}
          <Dropdown
            isOpen={isOptionsOpen}
            options={props.addFieldOptions}
            getIsOptionDisabled={(o) => {
              if (!o.isEditable) return true;

              if (props.user.isDirectorySynced) {
                return o.isSynced;
              }

              return false;
            }}
            getOptionId={(o) => o.fieldId}
            getIsOptionSelected={(_o) => false}
            onOptionClick={(o) => {
              props.onAddField(o);
              setIsOptionsOpen(false);
            }}
            onClickAway={() => setIsOptionsOpen(false)}
            renderOptionContent={(o) => o.label || o.fieldType}
            anchorElement={anchorElement}
          />
        </Grid>
      </Grid>
    </CPFSectionCard>
  );
};
