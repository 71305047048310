import store from 'src/redux/store';
import { saveScopeToken } from 'src/utils/localStorageHelper';
import { toast } from 'react-toastify';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { getSelfInfo } from 'src/utils/getLocalAuth';
import { setCurrentOrganization } from 'src/redux/actions/headerAction';
import { offWaitingSwitchOrganization, setWaitingSwitchOrganization } from 'src/redux/actions/flagAction';
import { UserOrganizationSwitcherPayload } from 'src/types';
import { PREVIOUS_ORGANIZATION } from 'src/constants/storageKeys';
import { AppRoutes } from '../router/AppRoutes';

const trySwitchOrganization = async (option: UserOrganizationSwitcherPayload, isAcceptingTOS?: boolean) => {
  const { department_id, site_id, organization_id, name } = option;

  const currentOrganization = store.getState().organizationReducer;

  if (
    currentOrganization.department_id === department_id &&
    currentOrganization.site_id === site_id &&
    currentOrganization.organization_id === organization_id
  ) {
    return;
  }

  sessionStorage.setItem(PREVIOUS_ORGANIZATION, JSON.stringify(currentOrganization));

  try {
    store.dispatch(setWaitingSwitchOrganization());

    // update scopeToken
    const pickedOption = (({ department_id, site_id, organization_id }) => ({
      departmentId: department_id,
      siteId: site_id,
      organizationId: organization_id,
    }))(option);

    const userScopeString = JSON.stringify(pickedOption);
    const getScopeToken = btoa(userScopeString);

    await saveScopeToken(getScopeToken);
    store.dispatch(setCurrentOrganization(option));

    if (currentOrganization.organization_id !== organization_id) {
      const selfID = getSelfInfo().id;
      if (selfID && name) {
        AnalyticsManager.identifyMixpanelUserOrg({
          userId: selfID,
          company: name,
          company_id: option.organization_id,
        });
      }
    }

    if (
      window.routerHistory.location.pathname !== '/home' &&
      !window.routerHistory.location.pathname.includes('scheduling')
    ) {
      window.routerHistory.push(AppRoutes.Home);
    }

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.switchOrganization,
      params: {
        organization_id: option.organization_id,
        organization_name: option.name,
      },
    });

    window.location.reload();
  } catch (e) {
    toast.error('Error when switching organization, please try again', {
      className: 'Toast-Container',
    });
    console.error(e);
  }
  store.dispatch(offWaitingSwitchOrganization());
};

export default trySwitchOrganization;
