import React from 'react';
import 'src/assets/styles/DropdownStyles.scss';
import RenderOrganizations from 'src/components/popup-dropdowns/dropdown-partials/RenderOrganization';
import { typedUseSelector } from 'src/redux/store';
import { OrganizationScope, UserOrganizationSwitcherPayload } from 'src/types';
import OrganizationDropdownLoader from 'src/components/loaders/GlobalHeaderLoader';

interface Props {
  options: OrganizationScope[];
  onSelect: (option: UserOrganizationSwitcherPayload) => void;
  isOpen: boolean;
  isReadOnly: boolean;
}

const Dropdown = ({ options, onSelect, isOpen, isReadOnly }: Props) => {
  const currentOrganization = typedUseSelector((state) => state.organizationReducer);

  const handleChange = (option: UserOrganizationSwitcherPayload, index?: number) => {
    onSelect(option);
  };

  const sortedOrganizationList = options?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  if (isOpen) {
    return (
      <ul
        className={`dropdownOrganizationMenu ${
          isReadOnly ? 'organizationSwitcherDropdown' : 'promoteAdminOrganizationDropdown'
        }`}
      >
        {sortedOrganizationList ? (
          <RenderOrganizations
            organizations={options}
            currentOrganization={currentOrganization}
            handleChange={handleChange}
            isReadOnly={isReadOnly}
          />
        ) : (
          <OrganizationDropdownLoader />
        )}
      </ul>
    );
  }

  return null;
};

export default Dropdown;
