import React from 'react';
import BreadcrumbsLink from '@material-ui/core/Link';
import { Box, Grid, Container, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TemplateReportForm from './TemplateReportForm';
import client from 'src/clients/apolloClient';
import { GET_TEMPLATE_AUDIT_REPORT } from 'src/gql/query/TemplateReportQuery';
import { DescriptionText, Breadcrumb } from 'src/components/shared/HypercareComponents';
import { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    separator: {
      fontSize: '1.5rem',
      color: '#222222',
      userSelect: 'none',
      marginRight: '4px !important',
      cursor: 'pointer',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    paper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      maxWidth: 'unset !important',
      paddingLeft: '5%',
      paddingRight: '2.5%',
    },
    link: {
      textDecoration: 'none !important',
      color: '#00859A',
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 14,
      fontStyle: 'normal',
      lineHeight: '20px',
      flex: 'none',
      order: 4,
      flexGrow: 0,
      margin: '0px 4px 4px 4px',
    },
    title: {
      fontFamily: 'Nunito Sans',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '29px',
      color: '#222222 !important',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  }),
);

interface FormValue {
  templateIds: string[];
  startDate: string;
  endDate: string;
  senderUserIds: string[];
  recipientUserIds: string[];
}

interface Props {
  handleTemplateReport: (formValue: FormValue) => Promise<any>;
}

const ReportTabPanel = () => {
  const classes = useStyles();

  const handleBreadcrumbsClick = (page) => {
    window.routerHistory.push(page);
  };

  const handleTemplateReport = async ({
    templateIds,
    startDate,
    endDate,
    senderUserIds,
    recipientUserIds,
  }: FormValue) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await client.query({
          query: GET_TEMPLATE_AUDIT_REPORT,
          variables: {
            templateIds: templateIds,
            startDate: startDate,
            endDate: endDate,
            senderUserIds: senderUserIds,
            recipientUserIds: recipientUserIds,
          },
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.exportTemplateMessageReportButtonPressed,
          params: {
            template_ids: templateIds,
            start_date: startDate,
            end_date: endDate,
            sender_ids: senderUserIds,
            recipient_ids: recipientUserIds,
          },
        });

        resolve(response);
      } catch (e) {
        console.error(e); // TODO: proper error handling
        if (e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].code === METRICAIDERRORCODE)
          reject(METRICAIDERRORCODE);
        reject('network error');
      }
    });
  };

  return (
    <Container id="reportDemoPage" className={classes.container} style={{ marginTop: 16 }} maxWidth="xs">
      <div className={classes.paper}>
        <Grid item md={6} sm={12}>
          <Box>
            <Breadcrumb separator="›" className={classes.separator} aria-label="breadcrumb">
              <BreadcrumbsLink className={classes.link} onClick={() => handleBreadcrumbsClick('/home')}>
                Home
              </BreadcrumbsLink>
              <BreadcrumbsLink className={classes.link} onClick={() => handleBreadcrumbsClick('/dashboard')}>
                Analytics & Reports
              </BreadcrumbsLink>
              <Typography className={classes.link} style={{ color: '#4A4A4A', paddingTop: '10px' }}>
                Templated message report
              </Typography>
            </Breadcrumb>
            <Typography variant="body1" className={classes.title}>
              Template message report
            </Typography>
            <DescriptionText>
              Generate a summary report based on template messages that you did not personally send or receive (e.g. for
              a department). Reports will be exported as an XLS (Microsoft Excel) file, and sent to you on Hypercare as
              a message attachment.
            </DescriptionText>
            <TemplateReportForm handleTemplateReport={handleTemplateReport} />
          </Box>
        </Grid>
      </div>
    </Container>
  );
};

export default ReportTabPanel;
