import React from 'react';
import moment from 'moment';
import { makeStyles, DialogContent, Grid, Divider, CircularProgress } from '@material-ui/core';

import 'src/assets/styles/FormStyles.scss';
import UsernameInputSelectContainer from 'src/components/shared/UsernameInputSelectContainer';
import StartEndTimePicker from 'src/pages/SchedulingPage/scheduling-layout/StartEndTimePicker';
import ScheduleModalCalendar from 'src/pages/SchedulingPage/scheduling-layout/ScheduleModalCalendar';
import ScheduleRoleInputSelect from 'src/pages/SchedulingPage/scheduling-layout/ScheduleModalRoleInputSelect';
import MetricaidComponent, { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';
import { PrimaryButton, SecondaryButton, StyledDialogActions } from 'src/components/shared/HypercareComponents';

const useStyles = makeStyles((_) => ({
  dialogContent: {
    '&:first-child': {
      paddingTop: 0,
      height: '70vh',
    },
  },
  formError: {
    color: 'red',
  },
  progressbar: {
    marginRight: 4,
  },
}));

interface BulkShiftInputFormValues {
  endTime: moment.Moment;
  startTime: moment.Moment;
  roleIndex: number;
  selectedDates: moment.Moment[];
  userId: string;
  userFullName: string;
  roleName?: string;
}

interface InputScheduleFormProps {
  handleModalFormSubmission: (args: BulkShiftInputFormValues) => Promise<unknown>;
  closeModal: () => void;
}

/**
 * functional component for bulk shift creation
 * @param handleModalFormSubmission callback function to call when submitting the form with data
 * @param closeModal callback function to call to close the modal
 */
const InputScheduleForm = ({ handleModalFormSubmission, closeModal }: InputScheduleFormProps) => {
  const classes = useStyles({}),
    [fieldError, setFieldError] = React.useState({}),
    [submitting, setSubmitting] = React.useState<boolean>(false),
    [prefillEndTime, setPrefillEndTime] = React.useState<moment.Moment>(null),
    [prefillStartTime, setPrefillStartTime] = React.useState<moment.Moment>(null),
    [metricaidErrorState, setMetricaidErrorState] = React.useState<boolean>(false),
    [inputShiftFormValues, setInputShiftFormValues] = React.useState<BulkShiftInputFormValues>({
      roleName: '',
      roleIndex: null,
      startTime: null,
      endTime: null,
      userFullName: '',
      userId: '',
      selectedDates: [],
    });

  /**
   * fn. to validate selected date array
   * @param selectedDates {[moment.Moment]} arroy of moment date object
   */
  const isSelectedDateValid = (selectedDates: moment.Moment[]) => {
    if (selectedDates.length === 0) {
      setFieldError({ selectedDates: 'At least one day for this role is required!' });
      return false;
    }
    return true;
  };

  /**
   * fn. to validate start and end time in the form, checks for if is null/undefined/empty or if valid moment object
   * @param startTime {moment.Moment} startTime moment object
   * @param endTime {moment.Moment} endTime moment object
   */
  const isTimeValid = (startTime: moment.Moment, endTime: moment.Moment) => {
    if (!startTime) {
      setFieldError({ startTime: 'Start time is required!' });
      return false;
    }
    if (!endTime) {
      setFieldError({ endTime: 'End time is required!' });
      return false;
    }
    if (!startTime.isValid()) {
      setFieldError({ startTime: 'Start time is not valid!' });
      return false;
    }
    if (!endTime.isValid()) {
      setFieldError({ endTime: 'End time is not valid!' });
      return false;
    }

    return true;
  };

  /**
   * On selection of role the start time and end time is populated in the start time and end time fields for the shift
   * based on the role start and end time
   * @param roleStartTime {moment.Moment} startdate of role to be set in useState hook & to be set in start date field
   * @param roleEndTime {moment.Moment} endDate of role to be set in useState hook & to be set in end date field
   */
  const onNewRoleSelection = (roleStartTime: moment.Moment, roleEndTime: moment.Moment) => {
    setPrefillStartTime(roleStartTime);
    setPrefillEndTime(roleEndTime);
  };

  /**
   * function to set the values of fields to useState hook
   * @param field key to set in the useState hook object
   * @param value value of every key to be set in the object
   */
  const fieldValueHandler = (field, value, _) => {
    setInputShiftFormValues((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  /** fn. to validate the selected dates in the calendar and date and time fields and then submit the form to
   * create new shifts in the schedule */
  const submitForm = () => {
    setFieldError('');
    setSubmitting(true);
    const { selectedDates, startTime, endTime } = inputShiftFormValues;
    const isValid = isSelectedDateValid(selectedDates) && isTimeValid(startTime, endTime);
    if (!isValid) {
      setSubmitting(false);
      return;
    }
    handleModalFormSubmission(inputShiftFormValues).catch((e) => {
      switch (e) {
        case 'unknown backend error':
          setSubmitting(false);
          setFieldError({ general: 'unknown backend error occurred, please try again' });
          break;
        case METRICAIDERRORCODE:
          setSubmitting(false);
          setMetricaidErrorState(true);
          document.getElementById('metricaid-wrapper')?.scrollIntoView({ behavior: 'smooth' });
          break;
        default:
          setFieldError({ general: 'Unknown error occurred, please try again' });
      }
    });
  };

  return (
    <div className="modalForm inputScheduleForm">
      <DialogContent classes={{ root: classes.dialogContent }}>
        {metricaidErrorState && <MetricaidComponent />}
        <ScheduleRoleInputSelect
          prefillRole={''}
          isSingleUserForm={true}
          setFieldValue={fieldValueHandler}
          onNewRoleSelection={onNewRoleSelection}
          roleNameError={inputShiftFormValues.roleName === '' && 'Role is required!'}
        />

        <Grid container style={{ marginTop: 20 }} wrap={'nowrap'}>
          <Grid item lg={6} style={{ marginRight: 12, width: '100%' }}>
            <UsernameInputSelectContainer
              index={null}
              userId={null}
              isSingleUserForm={true}
              setFieldValue={fieldValueHandler}
              userFullNameError={inputShiftFormValues.userFullName === '' && 'Name is required!'}
            />
          </Grid>
          <StartEndTimePicker
            index={null}
            isAllDayShift={false}
            prefillEndTime={prefillEndTime}
            setFieldValue={fieldValueHandler}
            prefillStartTime={prefillStartTime}
            endTimeError={fieldError['endTime'] && fieldError['endTime']}
            startTimeError={fieldError['startTime'] && fieldError['startTime']}
          />
        </Grid>
        <div style={{ marginTop: 24, marginBottom: 16 }}>
          <ScheduleModalCalendar
            setFieldValue={fieldValueHandler}
            endTime={inputShiftFormValues.endTime}
            startTime={inputShiftFormValues.startTime}
            roleIndex={inputShiftFormValues.roleIndex}
          />
        </div>
        {fieldError['selectedDates'] && <span className="modalForm__inputError">{fieldError['selectedDates']}</span>}
        {fieldError['general'] && <span className="modalForm__inputError">{fieldError['general']}</span>}
      </DialogContent>
      <Divider />
      <StyledDialogActions>
        <SecondaryButton style={{ marginRight: 8 }} onClick={() => closeModal()}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={inputShiftFormValues.roleName === '' || inputShiftFormValues.userFullName === ''}
          onClick={() => submitForm()}
        >
          {submitting && <CircularProgress className={classes.progressbar} color="inherit" size={16} />}
          {submitting ? 'Creating' : 'Create'} shifts
        </PrimaryButton>
      </StyledDialogActions>
    </div>
  );
};
export default InputScheduleForm;
