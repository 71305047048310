import React from 'react';
import moment from 'moment';
import { ActionType, GetPendingUsersResult, User } from 'src/types';
import styled from 'styled-components';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import UserAvatar from 'src/components/shared/UserAvatar';
import { getParsedAuthInfo } from 'src/utils/localStorageHelper';
import { Box } from '@material-ui/core';
import { APPROVE, PENDING_ADMIN, PENDING_USER, REJECT, TIMER } from 'src/constants/inviteUserTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { QueryResult } from 'react-apollo';
import client from 'src/clients/apolloClient';
import { APPROVE_INVITE } from 'src/gql/mutation/ApproveInvite';
import { DECLINE_INVITE } from 'src/gql/mutation/DeclineInvite';
import { toast } from 'react-toastify';
import CustomToaster from 'src/components/CustomToaster';
import CheckSuccess from 'src/assets/svgs/CheckSuccess';
import ResendInvite from 'src/gql/mutation/ResendInvite';
import PendingInviteModal from 'src/components/modals/PendingInviteModal';
import { StyledButton, StyledMenu, StyledMenuItem, UserListContainer } from 'src/pages/HomePage/InviteStyle';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { getUserFullName } from 'src/utils/getUserFullName';
import { AppRoutes } from '../../../router/AppRoutes';
import { IsFeatureFlagEnabled } from '../../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../../utils/FeatureFlags';

const StyledStatus = styled.span<{ status }>`
  ${(props) => {
    if (props.status === PENDING_ADMIN)
      return `color: ${props.theme.mainTealColor}; 
      background: #E9FCF8; 
      border-radius: 3px; 
      padding: 4px 8px; 
      text-transform: uppercase;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;`;
    if (props.status === PENDING_USER)
      return `color: ${props.theme.darkenFontColor};font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-transform: none;`;
  }};
`;

interface Props {
  users: any[];
  status: string;
  pendingByAdmin: any;
  setPendingByAdmin: any;
  pendingByInvitee: any;
  setPendingByInvitee: any;
  pendingRecords: QueryResult<GetPendingUsersResult>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      marginTop: '-9px',
    },
    actionButton: {
      gridArea: 'none',
      marginTop: '-46px',
    },
    adminActionButtonBorder: {
      border: '1px solid #00859A !important',
      borderRadius: '4px',
    },
    inviteeActionButtonBorder: {
      border: '1px solid #D8D8D8 !important',
      borderRadius: '4px',
    },
    reject: {
      color: '#FF3E55',
    },
    userBlockSection: {
      gridTemplateColumns: '0.4fr 2fr 1fr 1fr 0.4fr',
      paddingLeft: '50px',
      paddingRight: '15px',
    },
    userNameSection: {
      cursor: 'default',
      marginBottom: '4px',
    },
    userFullName: {
      color: '#222222',
      fontSize: '17px',
      lineHeight: '24px',
      alignSelf: 'center',
      fontWeight: 700,
      maxWidth: '50%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:hover': {
        color: '#4A4A4A !important',
      },
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    invitedBy: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    approvedBy: {
      textTransform: 'inherit',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#767676',
      marginTop: '-12px',
    },
    rejectToastr: {
      background: '#4A4A4A',
    },
    acceptToastr: {
      background: '#00859A',
    },
  }),
);

const PendingUsersList: React.FC<Props> = (props) => {
  const { users, status, pendingByAdmin, setPendingByAdmin, pendingByInvitee, setPendingByInvitee, pendingRecords } =
    props;

  const classes = useStyles();
  const [selectUser, setSelectedUser] = React.useState(null);
  const [actionStatus, setActiveStatus] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [actionTypeEl, setActionTypeEl] = React.useState<null | HTMLElement>(null);
  const [action, setAction] = React.useState<null | ActionType>(null);
  const [rejectReason, setRejectReason] = React.useState('');
  const [activeAction, setActiveAction] = React.useState(null);

  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  let undo = false;

  const getInvitedBy = React.useCallback((user) => {
    const userId = getParsedAuthInfo()?.user?.id;
    let invitedBy = user.invitedBy.id === userId ? 'You' : user.invitedBy.firstname + ' ' + user.invitedBy.lastname;

    return invitedBy;
  }, []);

  const getTimestampString = (invitedDate: string) => {
    const date = new Date(invitedDate);
    const now = moment(new Date()).startOf('day');
    const givenDate = moment(date);
    const days = now.diff(givenDate.startOf('day'), 'days');
    if (days < 1) {
      return 'Today';
    }
    if (days === 1) {
      return 'Yesterday';
    }
    return moment(date).format('DD/MMM/YYYY');
  };

  const handleAction = async (status: string) => {
    undo = false;
    let indexforselectedUser;
    setIsModalOpen(false);
    new Promise(async (resolve, reject) => {
      try {
        indexforselectedUser = pendingByAdmin.findIndex((user) => user.id === selectUser.id);
        pendingByAdmin.splice(indexforselectedUser, 1);
        setPendingByAdmin(pendingByAdmin);

        if (status === APPROVE) {
          pendingByInvitee.unshift({ ...selectUser });
          let updatedInvitee = pendingByInvitee.map((user) => {
            if (user.id === selectUser.id) {
              user.inviteStatus = PENDING_USER;
              user.updatedAt = moment();
            }
            return user;
          });
          setPendingByInvitee(updatedInvitee);
        }

        await showToaster(resolve, status);
        setTimeout(async () => {
          resolve(true);
        }, TIMER); //miliseconds
      } catch (e) {
        reject('error');
      }
    })
      .then((result) => {
        if (!undo && result) {
          const variable = {
            inviteId: selectUser.id,
          };
          if (status !== APPROVE) {
            variable['note'] = rejectReason;
          }
          const response = client.mutate({
            mutation: status === APPROVE ? APPROVE_INVITE : DECLINE_INVITE,
            variables: variable,
          });
          if (status === REJECT) {
            pendingRecords.refetch();
          }

          AnalyticsManager.applyAnalytics({
            eventName: status === APPROVE ? EVENTS.approveInvite : EVENTS.declineInvite,
            params: {
              invite_id: selectUser.id,
            },
          });

          return response;
        } else {
          let updatedInviteeList = pendingByInvitee.filter(
            (user) => user.id !== selectUser.id && user.inviteStatus === PENDING_USER,
          );
          setPendingByInvitee(updatedInviteeList);

          pendingByAdmin.splice(indexforselectedUser, 0, { ...selectUser });
          let updatedAdminList = pendingByAdmin.map((user) => {
            if (user.id === selectUser.id) {
              user.inviteStatus = PENDING_ADMIN;
              user.updatedAt = selectUser.updatedAt;
            }
            return user;
          });
          setPendingByAdmin(updatedAdminList);

          return;
        }
      })
      .catch((e) => {
        let message = 'Failed to send request, please check your internet connection and try again!';
        if (e?.networkError?.result?.errors[0]) {
          let errorCodeName = e.networkError.result.errors[0].code;
          if (errorCodeName) {
            message = e.networkError.result.errors[0].message;
          }
        }
        toast.error(message);

        let updatedInviteeList = pendingByInvitee.filter(
          (user) => user.id !== selectUser.id && user.inviteStatus === PENDING_USER,
        );
        setPendingByInvitee(updatedInviteeList);

        pendingByAdmin.splice(indexforselectedUser, 0, { ...selectUser });
        let updatedAdminList = pendingByAdmin.map((user) => {
          if (user.id === selectUser.id) {
            user.inviteStatus = PENDING_ADMIN;
            user.updatedAt = selectUser.updatedAt;
          }
          return user;
        });
        setPendingByAdmin(updatedAdminList);
      });
  };

  const showToaster = async (resolve: (value: unknown) => void, status: string) => {
    const name = selectUser.user
      ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
      : selectUser?.address?.address;
    toast(
      <CustomToaster
        logo={status === APPROVE && <CheckSuccess />}
        body={`Invite for “${name}” has been ${status === APPROVE ? 'approved' : 'rejected'}.`}
        isVisibleUndo={true}
        handleUndo={() => {
          undo = true;
          resolve(true);
        }}
      />,
      {
        autoClose: TIMER,
        className: status === APPROVE ? classes.acceptToastr : classes.rejectToastr,
      },
    );

    return true;
  };

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>, user: User, index: string, status: string) => {
    setActionTypeEl(event.currentTarget);
    setSelectedUser(user);
    setActiveAction(index);
    setActiveStatus(status);
  };

  const handleChangeActionType = (type: null | ActionType) => {
    setRejectReason('');
    setActionTypeEl(null);
    setIsModalOpen(true);
    setAction(type);
    setActiveStatus('');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRejectReason(event.target.value);
  };

  const handleResend = async () => {
    try {
      const name = selectUser.user
        ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
        : selectUser?.address?.address;
      setActionTypeEl(null);
      await client.mutate({
        mutation: ResendInvite,
        variables: {
          inviteId: selectUser.id,
        },
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.resendInvite,
        params: {
          invite_id: selectUser.id,
        },
      });

      toast(<CustomToaster body={`Invite for “${name}” has been resent.`} isVisibleUndo={false} />, {
        className: classes.rejectToastr,
      });
    } catch (e) {
      let message = 'Failed to send request, please check your internet connection and try again!';
      if (e?.networkError?.result?.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        if (errorCodeName) {
          message = e.networkError.result.errors[0].message;
        }
      }
      toast.error(message);
    }
  };

  const handleCloseMenu = () => {
    setActionTypeEl(null);
    setSelectedUser(null);
    setActiveStatus('');
  };

  return (
    <React.Fragment>
      {users && users.length > 0 && (
        <>
          <UserListContainer paginatedContactsFeatureFlag={paginatedContactsFeatureFlag}>
            {users.map((user: User, index: number) => {
              const invitedBy = getInvitedBy(user);
              const uniqueIndex = `${index}-${user.id}`;
              return (
                <div
                  className={`usersBlock ${classes.userBlockSection}`}
                  key={`user-${index}`}
                  data-testid={`user-${user.id}`}
                >
                  <div className="usersBlock__avatarIcon">
                    <UserAvatar profileSize="default" user={user} />
                  </div>

                  <div className={`usersBlock__userNames ${classes.userNameSection}`}>
                    <span className={classes.userFullName}>{getUserFullName(user)}</span>
                    {user.username && <span className="usersBlock__userNames--username">@{user.username}</span>}
                  </div>

                  <div className="usersBlock__userTitle">
                    Invited by &nbsp;
                    <span
                      className={classes.invitedBy}
                      onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.invitedBy.id}`)}
                    >
                      {invitedBy}
                    </span>
                    , {moment(user.createdAt).format('DD/MMM/YYYY')}
                  </div>
                  {user.resolvedBy && user.resolvedBy.id !== user.invitedBy.id && (
                    <div className={`usersBlock__userTags ${classes.approvedBy}`}>
                      Approved by &nbsp;
                      <span
                        className={classes.invitedBy}
                        onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.resolvedBy.id}`)}
                      >
                        {invitedBy}
                      </span>
                      , <span>{getTimestampString(user.createdAt)}</span>
                    </div>
                  )}

                  <div className={`usersBlock__userStatus ${classes.status}`}>
                    <StyledStatus status={status} data-testid="status">
                      {status === PENDING_ADMIN ? 'Pending admin approval' : 'Pending invitee response'}
                    </StyledStatus>
                  </div>

                  <div className={`usersBlock__joinDate ${classes.status}`}>
                    <span>{user.joinDate ? moment(user.joinDate).format('MMM DD, YYYY') : '--'}</span>
                  </div>
                  <div className={classes.actionButton}>
                    <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                      <StyledButton
                        id={`action-button-${user.id}`}
                        aria-haspopup="true"
                        className={
                          status === PENDING_ADMIN ? classes.adminActionButtonBorder : classes.inviteeActionButtonBorder
                        }
                        onClick={(e) => handleActionClick(e, user, uniqueIndex, status)}
                        endIcon={actionTypeEl && uniqueIndex === activeAction ? <ExpandLess /> : <ExpandMore />}
                      >
                        Action
                      </StyledButton>
                    </Box>
                  </div>
                </div>
              );
            })}
          </UserListContainer>
          <StyledMenu
            id={`action-menu-${selectUser?.id}`}
            keepMounted
            anchorEl={actionTypeEl}
            open={Boolean(actionTypeEl)}
            onClose={() => handleCloseMenu()}
          >
            {actionStatus === PENDING_ADMIN ? (
              <div key={activeAction}>
                <StyledMenuItem onClick={() => handleChangeActionType(APPROVE)}>Approve</StyledMenuItem>
                <StyledMenuItem onClick={() => handleChangeActionType(REJECT)} className={classes.reject}>
                  Reject
                </StyledMenuItem>
              </div>
            ) : (
              <StyledMenuItem onClick={() => handleResend()}>Resend invite</StyledMenuItem>
            )}
          </StyledMenu>
        </>
      )}

      {isModalOpen && (
        <PendingInviteModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          action={action}
          selectUser={selectUser}
          handleAction={handleAction}
          rejectReason={rejectReason}
          handleChange={handleChange}
        />
      )}
    </React.Fragment>
  );
};

export default PendingUsersList;
