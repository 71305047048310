import React, { CSSProperties } from 'react';
import Select, { createFilter } from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import { ValueType } from 'react-select/src/types';
import overriddenComponents, { useStyles } from 'src/components/shared/ReactSelectMaterialUI';
import {
  NoCircleOption as Option,
  AutoHeightMenuList as MenuList,
} from 'src/components/shared/ReactSelectSharedComponents';
import { FieldInputLabel } from 'src/components/shared/HypercareComponents';

/**
 * Search by label and role
 */
const stringify = (option) => {
  return option.label + option.data.role;
};
const filterOption = createFilter({ ignoreCase: true, stringify });

const CollleagueUserListSelect = ({
  prefillAssignee,
  setFieldValue,
  userFullNameError,
  suggestions,
  index,
  isSingleUserForm,
}) => {
  const classes = useStyles({});
  const theme = useTheme();
  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<any>>([]);

  React.useEffect(() => {
    if (prefillAssignee) setSelectedColleague(prefillAssignee);
  }, [prefillAssignee]);

  const userFullNameFieldName = typeof index === 'number' ? `assignee.${index}.userFullName` : 'userFullName';

  const userIdFieldName = typeof index === 'number' ? `assignee.${index}.userId` : 'userId';

  function handleChange(value: ValueType<any>) {
    setSelectedColleague(value);
    setFieldValue(userFullNameFieldName, value ? value.label : '', true);
    setFieldValue(userIdFieldName, value ? value.value : '', true);
  }

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div className={classes.root}>
      <FieldInputLabel>Name</FieldInputLabel>
      <Select
        isClearable
        autoFocus={Boolean(userFullNameError)}
        classes={classes}
        styles={selectStyles}
        inputId={userFullNameFieldName}
        TextFieldProps={{
          name: userFullNameFieldName,
          InputLabelProps: {
            htmlFor: userFullNameFieldName,
            shrink: true,
          },
          FormHelperTextProps: {
            classes: { contained: classes.helperText },
          },
          variant: 'outlined',
          helperText: userFullNameError || '',
          error: Boolean(userFullNameError),
        }}
        placeholder={'Search a name...'}
        options={suggestions}
        components={{
          ...overriddenComponents,
          Option,
          MenuList,
        }}
        value={selectedColleague}
        menuPortalTarget={document.body}
        onChange={handleChange}
        filterOption={filterOption}
        // menuIsOpen
      />
    </div>
  );
};

export default CollleagueUserListSelect;
