import React from 'react';
import { NotesFormValue, EditNotesFormValues } from 'src/types';
import useRepository from 'src/components/hidden-notes/HiddenNotesRepository';
import FetchSelfProfileNotes from 'src/gql/v2/query/FetchSelfProfileNotesQuery';
import { useQuery } from 'react-apollo';
import { sortByUpdatedAt } from 'src/utils/userSorting/sortByUpdatedAt';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

export default function HiddenNotesSelfViewModel() {
  const { data, loading, error } = useQuery(FetchSelfProfileNotes);

  const { deleteSelfProfileNote, createSelfProfileNote, updateSelfProfileNote } = useRepository();

  const notes = data && sortByUpdatedAt(data?.selfQuery?.notes);

  const handleCreateProfileNotes = async (values: NotesFormValue) => {
    return handleCreateSelfProfileNotes(values);
  };

  const handleCreateSelfProfileNotes = async ({ description, access }: NotesFormValue) => {
    const { result, error } = await createSelfProfileNote(description, access);

    if (error) {
      return { error };
    }

    const responseObject = result.data.selfMutation.createNote;

    if (responseObject.note) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteCreated,
        params: {
          note_id: responseObject.id,
          visibility: responseObject.access,
        },
      });
      return { error: null, result: 'success' };
    }

    if (responseObject.message) {
      switch (responseObject.__typename) {
        case 'ExceedMaxNoteCountError':
          return {
            error:
              'The maximun limit of notes (25) has been reached. To create a new one, kindly delete an existing one',
          };
        case 'ExceedMaxWordCountError':
          return { error: 'Note description exceeds the max word count' };
        default:
          return { error: 'An Error has occured while creating note, please refresh page and try again' };
      }
    }
  };

  const handleDeleteProfileNote = async (noteId: string) => {
    return handleDeleteSelfProfileNote(noteId);
  };

  const handleDeleteSelfProfileNote = async (noteId: string) => {
    const { result, error } = await deleteSelfProfileNote(noteId);

    if (error) {
      return { error };
    }

    const deletedNoteObject = result.data?.selfMutation?.note?.deleteNote;
    const responseObject = result.data?.selfMutation?.note;

    if (deletedNoteObject) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteDeleted,
        params: {
          note_id: deletedNoteObject.id,
        },
      });
      return { error: null, result: 'success' };
    }

    if (responseObject) {
      switch (responseObject.__typename) {
        case 'NoteDeletedError':
          return {
            error: "The note you're looking for has already been deleted, please refresh page to see the updated notes",
          };
        case 'AccessForbiddenError':
          return { error: 'You do not have access to delete this Note' };
        case 'NoteNotFoundError':
          return { error: 'Note not found' };
        default:
          return { error: 'An Error has occured while deleting note, please refresh page and try again' };
      }
    }
  };

  const handleUpdateProfileNote = async (values: EditNotesFormValues) => {
    return handleUpdateSelfProfileNote(values);
  };

  const handleUpdateSelfProfileNote = async (values: EditNotesFormValues) => {
    const { result, error } = await updateSelfProfileNote(values);

    if (error) {
      return { error };
    }

    const responseObject = result?.data?.selfMutation?.note;

    const updatedNoteObject = result?.data?.selfMutation?.note?.update;

    if (updatedNoteObject) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteUpdated,
        params: {
          note_id: updatedNoteObject.id,
          visibility: updatedNoteObject.access,
        },
      });
      return { error: null, result: 'success' };
    }

    if (responseObject) {
      switch (responseObject.__typename) {
        case 'NoteDeletedError':
          return {
            error: "The note you're looking for has already been deleted, please refresh page to see the updated notes",
          };
        case 'AccessForbiddenError':
          return { error: 'You do not have access to delete this Note' };
        case 'NoteNotFoundError':
          return { error: 'Note not found' };
        default:
          return { error: 'An Error has occured while deleting note, please refresh page and try again' };
      }
    }
  };

  return { notes, loading, error, handleDeleteProfileNote, handleCreateProfileNotes, handleUpdateProfileNote };
}
