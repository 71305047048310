import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { ClickAwayListener, Divider, Fade, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { Assignee, AssigneeWithRole, Role } from 'src/types';
import UserAvatar from 'src/components/shared/UserAvatar';
import AlertTriangle from 'src/assets/svgs/AlertTriangle';
import WarningIcon from 'src/assets/svgs/WarningIcon';
import {
  AlertIconWrapper,
  ConflictAndWarningContainer,
  ConflictAndWarningHeader,
  ConflictWarningCount,
  RoleHolderDetail,
  RoleHolderDetailTime,
  RoleHolderDetailTitle,
  RoleHolderWrapper,
  StyledPlusOneContainer,
  UnorderedList,
} from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';
import store, { typedUseSelector } from 'src/redux/store';
import { StyledTooltip } from 'src/components/shared/HypercareComponents';
import hexToRgba from 'src/utils/schedulingHelper/hexToRgba';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { CalenderShifts } from '@hypercare/hc-web-sdk';
import { LISTSCHEDULINGVIEW } from 'src/constants/scheduler';
import ScheduleRemoveShiftModal from 'src/components/modals/ScheduleRemoveShiftModal';
import ScheduleInputModal from 'src/components/modals/ScheduleInputModal';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { AppRoutes } from '../../../../router/AppRoutes';

interface Props {
  assignees: Assignee[] | AssigneeWithRole[];
  date: string;
  isDepartment: boolean;
  role?: Role;
  type?: string;
}

const StyledTooltipWrapper = styled.span`
  border-bottom: 2px dotted ${(props) => props.theme.mainFontColor};
  cursor: pointer;

  &:hover {
    border-bottom: 2px dotted ${(props) => props.theme.mainTealColor};
  }
`;
const minutesOfDay = function (m) {
  return m.minutes() + m.hours() * 60;
};
const ShiftAssignees = ({ role, assignees, date, isDepartment, type = LISTSCHEDULINGVIEW }: Props) => {
  const [targetShift, setTargetShift] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);
  const [anchorE2, setAnchorE2] = React.useState<HTMLElement>(null);
  const [showInputScheduleModal, setshowInputScheduleModal] = React.useState(false);
  const [inputScheduleModalPayload, setinputScheduleModalPayload] = React.useState(null);
  const [removeShiftPayload, setRemoveShiftPayload] = React.useState(null);
  const overnightShifts = IsFeatureFlagEnabled('overnightShifts');
  const [gridViewRole, setGridViewRole] = React.useState(null);

  // for keyboard shortcuts when hover on an shift
  const currentKeypressListener = React.useRef(null);

  const userToColorCodes = typedUseSelector((state) => state.monthlyScheduleReducer.userToHexColorCode);

  function handleHolderClick(e: React.MouseEvent<HTMLElement>, shift: Assignee, roleData: Role) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setTargetShift(shift);
    setGridViewRole(roleData);
  }

  function handleMouseOut() {
    if (currentKeypressListener.current) {
      window.removeEventListener('keydown', currentKeypressListener.current);
    }
  }

  function handleMouseOver(shift: Assignee, role: Role, dateString: string) {
    const isAnyModalOpen = (): boolean => Boolean(document.querySelector('.ReactModal__Content'));

    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Backspace' && !isAnyModalOpen()) {
        showRemoveShiftModal(shift, role, dateString);
      }
      return;
    };
    currentKeypressListener.current = handler;
    window.addEventListener('keydown', currentKeypressListener.current);
  }

  function showRemoveShiftModal(shift: Assignee, role: Role, dateString: string) {
    setAnchorEl(null);
    setRemoveShiftPayload({
      shift,
      role: gridViewRole,
      dateString,
    });

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.removeSchedule,
      params: {
        shift_id: shift.shiftId,
      },
    });
  }

  function gotoProfile(userId: string) {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.viewScheduledUserProfile,
      params: {
        user_id: userId,
      },
    });
    window.routerHistory.push(`${AppRoutes.UserProfile}/${userId}`);
  }

  function handleClickAway(e: React.MouseEvent<Document, MouseEvent>) {
    // @ts-ignore
    if (anchorEl && anchorEl.contains(e.target)) return;
    setAnchorEl(null);
  }

  function onMenuClick() {
    setAnchorEl(null);
    setinputScheduleModalPayload({
      role: gridViewRole,
      date: overnightShifts ? targetShift.startTime.format('YYYY-MM-DD') : date,
      targetAssignee: targetShift,
    });
    setshowInputScheduleModal(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editScheduleOptionPressed,
      params: {
        shift_id: targetShift,
      },
    });
  }

  function toggleHoverEffect(flag: boolean, shiftId: string) {
    let targetElements = Array.from(document.getElementsByClassName(shiftId));

    targetElements.forEach((element) => {
      if (flag) {
        element.classList.add('jsHover');
      } else {
        element.classList.remove('jsHover');
      }
    });
  }

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>, shift: Assignee) => {
    const isAnotherPopoverOpen = () => Boolean(document.querySelector('.conflict__warning__popover'));
    if (!isAnotherPopoverOpen()) {
      e.stopPropagation();
      setAnchorE2(e.currentTarget);
      setTargetShift(shift);
    }
  };
  const handleClose = (e) => {
    if (anchorE2 && anchorE2.contains(e.target)) return;
    setAnchorE2(null);
  };

  const ConflictAndWarningListView = ({ shiftArray }) => {
    return (
      <UnorderedList>
        {shiftArray?.map((_conflict: any, index) => {
          const {
            conflictShift: {
              startDate,
              endDate,
              role: {
                name: roleName,
                site: { name: siteName },
                department: { name: departmentName },
              },
            },
          } = _conflict;
          return (
            <li key={index}>
              {roleName} in {departmentName} at {siteName} on {moment(startDate).format('DD/MMM/YYYY HH:mm')} -{' '}
              {moment(startDate).format('DD/MMM/YYYY') === moment(endDate).format('DD/MMM/YYYY')
                ? moment(endDate).format('HH:mm')
                : moment(endDate).format('DD/MMM/YYYY HH:mm')}
            </li>
          );
        })}
      </UnorderedList>
    );
  };

  return (
    <React.Fragment>
      {assignees.map((shift, index) => {
        let shiftGrid = shift;
        role = type === LISTSCHEDULINGVIEW ? role : shift.role;
        shift = type === LISTSCHEDULINGVIEW ? shift : shift.assignee;
        const { shiftId, userId, conflictingShifts, warningShifts } = shift,
          assignedColor = userToColorCodes[userId],
          assignedColorBackground = hexToRgba(userToColorCodes[userId], 0.1),
          hasWarningShifts = warningShifts?.length > 0,
          hasConflictingShifts = conflictingShifts?.length > 0;

        let date1 = shift.startTime.date();
        let date2 = shift.endTime.date();

        if (date2 < date1) date2 = moment().endOf('month').millisecond(0o00).date();

        return type === LISTSCHEDULINGVIEW ? (
          <RoleHolderWrapper
            shiftOrder={shift.startTime.format('HH:mm').replace(':', '')}
            key={`${shiftId}-${index}`}
            className={`${shiftId}`}
            assignedColorBackground={assignedColorBackground}
            assignedColor={assignedColor}
            onClick={(e) => handleHolderClick(e, shift, role)}
            onMouseEnter={() => {
              toggleHoverEffect(true, shiftId);
              handleMouseOver(shift, role, date);
            }}
            onMouseLeave={() => {
              toggleHoverEffect(false, shiftId);
              handleMouseOut();
            }}
          >
            <UserAvatar user={shift} profileSize="small" />
            <RoleHolderDetail>
              <RoleHolderDetailTitle>{shift.userFullName}</RoleHolderDetailTitle>
              <RoleHolderDetailTime>
                {`${shift.startTime.format('HH:mm')} - ${shift.endTime.format('HH:mm')}`}
                {minutesOfDay(shift.endTime) <= minutesOfDay(shift.startTime) && (
                  <StyledPlusOneContainer>+1</StyledPlusOneContainer>
                )}
              </RoleHolderDetailTime>
            </RoleHolderDetail>
            {(hasConflictingShifts || hasWarningShifts) && (
              <React.Fragment>
                <AlertIconWrapper aria-describedby={index.toString()} onMouseEnter={(e) => handlePopoverOpen(e, shift)}>
                  {hasConflictingShifts && (
                    <AlertTriangle color={'#FA7A23'} width={16} height={16} viewBox={'0 4 24 20'} />
                  )}
                  {hasWarningShifts && <WarningIcon width={16} height={16} viewBox={'0 2 24 20'} />}
                </AlertIconWrapper>
              </React.Fragment>
            )}
          </RoleHolderWrapper>
        ) : (
          <CalenderShifts
            key={`${shiftId}-${index}`}
            assignee={shift}
            roleName={role.roleName}
            userToColorCodes={store.getState().monthlyScheduleReducer.userToHexColorCode}
            onCellClick={(e) => handleHolderClick(e, shift, shiftGrid.role)}
            menuItem={[]}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            onMouseEnter={() => {
              toggleHoverEffect(true, shiftId);
              handleMouseOver(shift, role, date);
            }}
            onMouseLeave={() => {
              toggleHoverEffect(false, shiftId);
              handleMouseOut();
            }}
            shiftIcon={
              (hasConflictingShifts || hasWarningShifts) && (
                <div aria-describedby={index.toString()} onMouseEnter={(e) => handlePopoverOpen(e, shift)}>
                  {hasConflictingShifts && (
                    <AlertTriangle color={'#FA7A23'} width={16} height={16} viewBox={'0 4 24 20'} />
                  )}
                  {hasWarningShifts && <WarningIcon width={16} height={16} viewBox={'0 2 24 20'} />}
                </div>
              )
            }
            variant="TimeFirstVariant"
          />
        );
      })}

      <Popper
        open={Boolean(anchorE2)}
        anchorEl={anchorE2}
        placement={'right-start'}
        style={{ width: '25vw' }}
        className="popper__container conflict__warning__popover"
        transition
        disablePortal
      >
        {({ TransitionProps }) => {
          const { conflictingShifts, userFullName, warningShifts } = targetShift,
            hasWarningShifts = warningShifts.length > 0,
            hasConflictingShifts = conflictingShifts.length > 0;
          return (
            <Fade {...TransitionProps} timeout={300}>
              <Paper className="popper__content__wrapper">
                <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                  <MenuList>
                    <ConflictWarningCount>Scheduling issue(s)</ConflictWarningCount>
                    <Divider />
                    <ConflictAndWarningContainer>
                      {hasConflictingShifts && (
                        <React.Fragment>
                          <ConflictAndWarningHeader>
                            <AlertTriangle color={'#FA7A23'} width={16} height={16} viewBox={'0 0 24 20'} />
                            <StyledTooltip
                              arrow
                              title={
                                'Conflicts are identified when a provider has two shifts occurring at the same time.'
                              }
                            >
                              <StyledTooltipWrapper>Conflict</StyledTooltipWrapper>
                            </StyledTooltip>
                          </ConflictAndWarningHeader>
                          <div style={{ fontSize: 14 }}>{userFullName} is also scheduled for:</div>
                          <ConflictAndWarningListView shiftArray={conflictingShifts} />
                        </React.Fragment>
                      )}
                      {hasWarningShifts && (
                        <React.Fragment>
                          <ConflictAndWarningHeader>
                            <WarningIcon width={16} height={16} viewBox={'0 -2 24 20'} />
                            <StyledTooltip
                              arrow
                              title={
                                'Warnings are identified when a provider has two shifts occurring on the same calendar day.'
                              }
                            >
                              <StyledTooltipWrapper>Warning</StyledTooltipWrapper>
                            </StyledTooltip>
                          </ConflictAndWarningHeader>
                          <div style={{ fontSize: 14 }}>{userFullName} is also scheduled for:</div>
                          <ConflictAndWarningListView shiftArray={warningShifts} />
                        </React.Fragment>
                      )}
                    </ConflictAndWarningContainer>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          );
        }}
      </Popper>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className="popper__container"
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <Paper className="popper__content__wrapper">
              <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                <MenuList>
                  {isDepartment && (
                    <div>
                      <MenuItem disableTouchRipple onClick={onMenuClick}>
                        Edit Shift
                      </MenuItem>
                      <Divider />
                    </div>
                  )}

                  {/* <MenuItem disableTouchRipple onClick={() => setShowPageCallBackModal(shift)}>
                    Page<b>&nbsp;{shift.userFullName}</b>
                  </MenuItem> */}

                  <MenuItem disableTouchRipple onClick={() => gotoProfile(targetShift.userId)}>
                    View<b>&nbsp;{targetShift.userFullName}</b>'s Profile
                  </MenuItem>

                  {isDepartment && (
                    <div>
                      <Divider />
                      <MenuItem disableTouchRipple onClick={() => showRemoveShiftModal(targetShift, role, date)}>
                        Remove<b>&nbsp;{targetShift.userFullName}&nbsp;</b>from this shift
                      </MenuItem>
                    </div>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      {showInputScheduleModal && (
        <ScheduleInputModal
          payload={inputScheduleModalPayload}
          hasCalendar={false}
          showModal={showInputScheduleModal}
          handleCloseModal={() => {
            setshowInputScheduleModal(false);
            setinputScheduleModalPayload(null);
          }}
        />
      )}
      {Boolean(removeShiftPayload) && (
        <ScheduleRemoveShiftModal
          showModal={Boolean(removeShiftPayload)}
          removeShiftPayload={removeShiftPayload}
          handleCloseModal={() => setRemoveShiftPayload(null)}
        />
      )}
    </React.Fragment>
  );
};

export default ShiftAssignees;
