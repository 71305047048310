import React from 'react';
import moment from 'moment';
import UserAvatar from 'src/components/shared/UserAvatar';
import Button from '@material-ui/core/Button';
import ResetPasswordModal from 'src/components/modals/ResetPasswordModal';
import { User, UserLicensingStatus } from 'src/types';
import styled from 'styled-components';
import ResendInviteModal from 'src/components/modals/ResendInviteModal';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { CANCEL } from 'src/constants/strings';

const LicenseExpirationBoxWrapper = styled.div`
  margin-top: 20px;
  button {
    max-width: fit-content;
  }
`;

const ExpirationBoxContent = styled.div`
  background: rgb(255, 244, 233);
  border: 1px solid wheat;
  min-width: 240px;
  border-radius: 4px;
  padding: 20px 20px 20px 50px;
  line-height: 1.4;
  position: relative;
  color: ${(props) => props.theme.mainFontColor};
  max-width: 300px;
  &:before {
    display: block;
    position: absolute;
    left: 20px;
    top: 24px;
    content: '!';
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.mainFontColor};
    width: 14px;
    height: 14px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: ${(props) => props.theme.mainFontColor};
  }
`;

const ExpirationTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const ExpirationDetail = styled.div`
  font-size: 14px;
  margin-bottom: 1em;
`;

interface State {
  showModal: boolean;
}

interface Props {
  user: User;
  enableEditing: () => void;
  onCancelForm: () => void;
  onSubmitForm: () => void;
  isInputDisabled: boolean;
  isSubmitting: boolean;
  userLicenseStatus: UserLicensingStatus;
}
class FormButtons extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  public handleOpenModal = () => {
    this.setState({ showModal: true });
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.resetPasswordButtonPressed,
    });
  };

  public handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  public render() {
    const { isInputDisabled, enableEditing, onCancelForm, onSubmitForm, isSubmitting } = this.props;
    const { showModal } = this.state;

    return (
      <div className="profileBox__editButtonWrapper">
        {isInputDisabled && !isSubmitting ? (
          <React.Fragment>
            <Button fullWidth disableTouchRipple variant="contained" color="secondary" onClick={enableEditing}>
              Edit Profile
            </Button>
            <Button fullWidth disableTouchRipple variant="outlined" color="secondary" onClick={this.handleOpenModal}>
              Reset Password
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              fullWidth
              disableTouchRipple
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              onClick={onSubmitForm}
            >
              {isSubmitting ? 'Sending Request...' : 'Update Profile'}
            </Button>
            {!isSubmitting && (
              <Button fullWidth disableTouchRipple color="secondary" variant="outlined" onClick={onCancelForm}>
                {CANCEL}
              </Button>
            )}
          </React.Fragment>
        )}
        {showModal && <ResetPasswordModal showModal={showModal} handleCloseModal={this.handleCloseModal} />}
      </div>
    );
  }
}

const ProfileBoxImage = ({ user }: { user: User }) => {
  return (
    <div className="profileBox__imageWrapper">
      <UserAvatar profileSize="large" user={user} />
    </div>
  );
};

const ProfileBoxJoinDate = ({ user }: { user: User }) => {
  if (user && user.joinDate) {
    return <div className="profileBox__joinDate">Joined {moment(user.joinDate).format('MMM DD, YYYY')}</div>;
  }
  return null;
};

const LicenseExpirationBox = ({ isPending, user }: { isPending: boolean; user: User }) => {
  const [showResendInviteModal, setShowResendInviteModal] = React.useState<boolean>(false);

  const warningTitle = isPending ? `An invitation has been sent` : `License expired`;
  const warningDetail = isPending
    ? `Profile information will not be able to be edited until user have accepted the invitation`
    : `Profile information will not be able to be edited until user has been reinvited and accepts the invitation`;

  return (
    <React.Fragment>
      <LicenseExpirationBoxWrapper>
        <ExpirationBoxContent>
          <ExpirationTitle>{warningTitle}</ExpirationTitle>
          <ExpirationDetail>{warningDetail}</ExpirationDetail>
          <Button
            fullWidth
            disableTouchRipple
            color="secondary"
            variant="contained"
            onClick={() => setShowResendInviteModal(true)}
          >
            Reinvite user
          </Button>
        </ExpirationBoxContent>
      </LicenseExpirationBoxWrapper>
      {showResendInviteModal && (
        <ResendInviteModal
          user={user}
          isPending={isPending}
          showModal={showResendInviteModal}
          handleCloseModal={() => setShowResendInviteModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default (props: Props) => {
  const { userLicenseStatus } = props;

  return (
    <div className="profileBox">
      <ProfileBoxImage user={props.user} />
      {userLicenseStatus === 'licensed' ? (
        <React.Fragment>
          <FormButtons {...props} />
          <ProfileBoxJoinDate user={props.user} />
        </React.Fragment>
      ) : (
        <LicenseExpirationBox isPending={userLicenseStatus === 'pending'} user={props.user} />
      )}
    </div>
  );
};
