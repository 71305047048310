import React from 'react';
import * as ReactModal from 'react-modal';
import { REMOTE_LOGOUT, REMOVE_USER, PROMOTE_ADMIN } from 'src/constants/modalTypes';
import { ModalActionType } from 'src/types/Modal';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { RemoteLogout, RemoveUser, PromoteAdmin } from 'src/components/modals/confirmation-actions';
import { User } from 'src/types';

interface Props {
  profile: User;
  showModal: boolean;
  confirmationType: ModalActionType | '';
  handleCloseModal: () => void;
}

const ConfirmationActionModal = ({ profile, showModal, confirmationType, handleCloseModal }: Props) => {
  React.useEffect(() => {
    // TODO: track promot admin
    if (confirmationType === REMOTE_LOGOUT) AnalyticsManager.recordPageVisited(PAGE_VIEWS.logoutAccountView);
    if (confirmationType === REMOVE_USER) AnalyticsManager.recordPageVisited(PAGE_VIEWS.removeMemberView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__confirmation"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseModal}
    >
      {confirmationType === REMOTE_LOGOUT && <RemoteLogout profile={profile} handleCloseModal={handleCloseModal} />}
      {confirmationType === REMOVE_USER && <RemoveUser profile={profile} handleCloseModal={handleCloseModal} />}
      {confirmationType === PROMOTE_ADMIN && <PromoteAdmin handleCloseModal={handleCloseModal} />}
    </ReactModal>
  );
};

export default ConfirmationActionModal;
