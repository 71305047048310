import React from 'react';
import ContentLoader from 'react-content-loader';
// import AppTheme from 'src/assets/styles/theme';

export default () => (
  <ContentLoader
    speed={2}
    width={700}
    height={750}
    viewBox="0 0 700 500"
    style={{
      height: '100%',
      maxHeight: '800px',
      width: '100%',
      padding: '5px',
    }}
  >
    <rect x="18" y="0" rx="10" ry="10" width="650" height="45" />
    <rect x="53" y="70" rx="10" ry="10" width="625" height="45" />
    <rect x="89" y="144" rx="10" ry="10" width="600" height="45" />
    <rect x="92" y="216" rx="10" ry="10" width="600" height="45" />

    <rect x="18" y="288" rx="10" ry="10" width="625" height="45" />
    <rect x="53" y="360" rx="10" ry="10" width="600" height="45" />
    <rect x="89" y="432" rx="10" ry="10" width="600" height="45" />
  </ContentLoader>
);
