import gql from 'graphql-tag';

export const PASSWORD_RESET = gql`
  mutation PasswordReset($id: ID!, $email: String) {
    admin {
      user(id: $id) {
        resetPassword(email: $email)
      }
    }
  }
`;
