import moment from 'moment';
import clonedeep from 'lodash.clonedeep';
import { Assignee, RoleResultInfo, MonthlyCalendar } from 'src/types';
import { REDUCER_DATE_FORMAT } from 'src/constants/scheduler';

// https://gist.github.com/umarazhar/7a014933b050cbd1953ec7ed4de74dd4
// note: shifts dates are still in UTC iso string,
// check if its in the month before split shift in schedule
function splitShifts(role: RoleResultInfo, schedule: MonthlyCalendar, index: number, overnightShifts: boolean) {
  const { shifts } = role;

  shifts.forEach((shift) => {
    let startDate = moment(shift.startDate);
    let endDate = moment(shift.endDate);

    const userPartial = {
      userId: shift.userId || shift.user.id,
      userFullName: shift.userFullName || shift.user.firstname + ' ' + shift.user.lastname,
      firstname: shift.user.firstname,
      lastname: shift.user.lastname,
      profilePic: shift.user.profilePic,
      shiftId: shift.shiftId || shift.id,
      conflictingShifts: shift.conflictingShifts,
      warningShifts: shift.warningShifts,
    };

    let date1 = startDate.date();
    let date2 = endDate.date();
    if (date2 < date1) date2 = moment(shift.startDate).endOf('month').millisecond(0o00).date();

    const numDaysInBetween = date2 - date1;
    const isNumDaysInBetween = overnightShifts ? numDaysInBetween <= 1 : numDaysInBetween === 0;

    if (isNumDaysInBetween) {
      if (schedule[startDate.format(REDUCER_DATE_FORMAT)]) {
        schedule[startDate.format(REDUCER_DATE_FORMAT)][index].assignee.push({
          ...userPartial,
          startTime: overnightShifts ? moment(shift.startDate) : startDate,
          endTime: overnightShifts ? moment(shift.endDate) : endDate,
        } as Assignee);
      }
    } else {
      let previousStart = startDate.clone();
      let previousEnd = endDate.clone();

      for (let i = 0; i < numDaysInBetween; i++) {
        previousEnd = previousStart.clone().endOf('day');

        const newShift = clonedeep(shift);
        newShift.startDate = previousStart.toISOString();
        newShift.endDate = previousEnd.toISOString();

        if (schedule[previousStart.format(REDUCER_DATE_FORMAT)]) {
          schedule[previousStart.format(REDUCER_DATE_FORMAT)][index].assignee.push({
            ...userPartial,
            startTime: moment(newShift.startDate),
            endTime: moment(newShift.endDate),
          } as Assignee);
        }

        previousStart.add(1, 'days').startOf('day');
      }

      // avoid 00:00 - 00:00 on the next splitted day (local time)
      if (endDate.hour() === 0 && endDate.minute() === 0) return;

      const newShift = clonedeep(shift);
      newShift.startDate = previousStart.toISOString();

      if (schedule[moment(newShift.startDate).format(REDUCER_DATE_FORMAT)]) {
        schedule[moment(newShift.startDate).format(REDUCER_DATE_FORMAT)][index].assignee.push({
          ...userPartial,
          startTime: moment(newShift.startDate),
          endTime: moment(newShift.endDate),
        } as Assignee);
      }
    }
  });

  return schedule;
}

export default splitShifts;
