import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import moment from 'moment-timezone';
import { AuthHelper } from 'src/auth';
import { AuthPayload } from 'src/types';
import { getAuthToken } from 'src/utils/getLocalAuth';
import ApiHelper from 'src/api';
import { USER_AUTH_EXPIRATION_NAMES } from 'src/constants/user';
import { AUTH_INFO, IS_EXPIRED } from 'src/constants/storageKeys';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import checkIsJSONString from 'src/utils/checkIsJSONString';

const formatGqlQueryForAnalytics = (data: any) => {
  let gqlQuery: string;
  if (checkIsJSONString(data)) {
    let parsedResponseData = JSON.parse(data);
    if (parsedResponseData['query']) {
      gqlQuery = parsedResponseData.query;
    } else if (parsedResponseData['mutation']) {
      gqlQuery = parsedResponseData.mutation;
    }
  }
  return gqlQuery;
};

const axiosConfig = () => {
  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload = authInfo ? JSON.parse(authInfo) : null;
  const { scopeToken = '' } = parsedAuthInfo || {};

  /*
   * TODO: Investigate jest tests failing due to axios.interceptors being undefined
   *  current fix was just to add a null check to axios.interceptors
   */

  axios.interceptors?.request.use(
    (config) => {
      config.headers.set({
        'Cache-Control': 'no-cache,no-store,must-revalidate,private',
        Pragma: 'no-cache',
        Expires: 0,
        'hc-timezone': moment.tz.guess(),
        'X-Timezone': moment.tz.guess(),
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'hypercare-scope': scopeToken ? `${scopeToken}` : '',
        ...config.headers,
      });
      config['start'] = new Date().getTime();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  // Response
  axios.interceptors?.response.use(
    (response) => {
      if (response.request) {
        let gqlQuery = formatGqlQueryForAnalytics(response.config.data);
        const { responseURL, status } = response.request;

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.networkRequest,
          params: {
            status_code: status,
            query: gqlQuery,
            error_code: '',
            url_path: responseURL,
          },
        });
      }
      // TODO: track network events for analytics
      return response;
    },
    (error: AxiosError<any>) => {
      // #Handling Errors
      let errorCode: string;
      let gqlQuery: string;
      let status: number;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        status = error.response.status;
        if (error.response.data.errors && error.response.data.errors[0]) {
          errorCode = error.response.data.errors[0].name;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
        errorCode = 'client did not receive response';
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        errorCode = error.message;
      }

      gqlQuery = formatGqlQueryForAnalytics(error.config.data);
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.networkRequest,
        params: {
          status_code: status,
          query: gqlQuery,
          error_code: errorCode,
          url_path: error.config.url,
        },
      });

      // TODO: track network events for analytics
      console.error(`error ${errorCode} with status ${status}`);

      // catch access token error and retry
      if (
        error.response.data &&
        ((error.response.data.inner && USER_AUTH_EXPIRATION_NAMES.includes(error.response.data.inner.statusCode)) ||
          USER_AUTH_EXPIRATION_NAMES.includes(error.response.data.name))
      ) {
        const originalRequest: AxiosRequestConfig = error.config;
        return ApiHelper.refreshAccessToken()
          .then(() => {
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${getAuthToken()}`,
            };
            return axios(originalRequest);
          })
          .catch(() => {
            AuthHelper.logout();
            sessionStorage.setItem(IS_EXPIRED, 'expired by network error');
            window.location.reload();
          });
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export default axiosConfig;
