import React, { useState } from 'react';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { ListItem, Paper, ClickAwayListener } from '@material-ui/core';

export type DropdownProps<TOption> = {
  options: TOption[];
  getOptionId: (option: TOption) => string;
  getIsOptionDisabled?: (option: TOption) => boolean;
  getIsOptionSelected: (option: TOption) => boolean;
  renderOptionContent: (option: TOption) => React.ReactNode;
  onOptionClick: (option: TOption) => void;
  onClickAway: () => void;
  anchorElement: Element | null;
  isOpen: boolean;
  placement?: Placement;
};

const DropdownContainer = styled(Paper)`
  min-width: 250px;
  padding: 8px;
  z-index: 1000;
  cursor: pointer;
`;

export function Dropdown<TOption>(props: DropdownProps<TOption>) {
  const [dropdownElement, setDropdownElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(props.anchorElement, dropdownElement, {
    placement: props.placement ?? 'bottom-start',
  });

  if (!props.isOpen) return null;

  return (
    <ClickAwayListener onClickAway={props.onClickAway}>
      <DropdownContainer ref={setDropdownElement} style={styles.popper} elevation={1} {...attributes.popper}>
        {props.options.map((o) => (
          <ListItem
            key={props.getOptionId(o)}
            button
            disabled={props.getIsOptionDisabled?.(o)}
            selected={props.getIsOptionSelected(o)}
            onClick={() => props.onOptionClick(o)}
          >
            {props.renderOptionContent(o)}
          </ListItem>
        ))}
      </DropdownContainer>
    </ClickAwayListener>
  );
}
