import React from 'react';
import Chart from 'src/components/analytics/Chart';
import { AxisInterval, CartesianGrid, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { CircularProgress } from '@material-ui/core';

interface IProps {
  loading: boolean;
  data: any[];
  height: number | string;
  width: number | string;
  xAxis: string;
  tickFormatter?: (value: any) => void;
  xTickFormatter?: (value: any) => any;
  tick?: any;
  customTooltip?: any;
  colors: string[];
  yAxis: Array<string | { dataKey: string; isRight?: boolean }>;
  yAxisLabel?: string;
  rightYAxis?: boolean;
  rightYAxisLabel?: string;
  rightYAxisTickFormatter?: (value: any) => void;
  ticks?: any[] | undefined;
  interval?: AxisInterval;
}

const LineChartComponent: React.FC<IProps> = (props) => {
  const {
    height,
    width,
    data,
    xAxis,
    tickFormatter,
    xTickFormatter,
    customTooltip,
    colors,
    yAxis,
    yAxisLabel,
    rightYAxis,
    rightYAxisTickFormatter,
    rightYAxisLabel,
    tick,
    ticks,
    interval,
  } = props;

  return (
    <React.Fragment>
      <div
        style={{
          display: props.loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: '#ffffffa0',
          zIndex: 1000,
        }}
      >
        <CircularProgress />
      </div>
      <Chart height={height} width={width}>
        <LineChart data={data}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey={xAxis}
            tickFormatter={xTickFormatter}
            // minTickGap={data && data.length ? data.length / 2 : 1}
            tick={tick || { fontSize: 12 }}
            ticks={ticks}
            interval={interval}
          />
          <YAxis
            yAxisId="left"
            tickFormatter={tickFormatter}
            tickLine={false}
            label={yAxisLabel ? { value: yAxisLabel, angle: -90, fontSize: 13, fontWeight: 600, dx: -10 } : null}
            tick={{ fontSize: 12 }}
          />
          {rightYAxis && (
            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={rightYAxisTickFormatter}
              tickLine={false}
              label={
                rightYAxisLabel ? { value: 'Message sent', angle: -90, fontSize: 13, fontWeight: 600, dx: 20 } : null
              }
              tick={{ fontSize: 12 }}
            />
          )}
          {Boolean(customTooltip) && <Tooltip cursor={{ fill: 'transparent' }} content={customTooltip} />}
          {!Boolean(customTooltip) && <Tooltip cursor={{ fill: 'transparent' }} />}
          <ReferenceLine yAxisId="left" y={0} strokeWidth={2} stroke="#b3b3b3" />
          {yAxis.map((k, i) => (
            <Line
              yAxisId={rightYAxis && typeof k === 'object' && k.isRight ? 'right' : 'left'}
              dataKey={typeof k === 'string' ? k : k.dataKey}
              key={i}
              strokeWidth={3}
              stroke={colors[i] || '#747BF3'}
              dot={false}
            />
          ))}
        </LineChart>
      </Chart>
    </React.Fragment>
  );
};

LineChartComponent.defaultProps = {
  height: 100,
  width: 100,
  tickFormatter: (value: any) => value,
};

export default LineChartComponent;
