export const EXISTING = 'existing';
export const NEW = 'new';
export const APPROVE = 'approve';
export const REJECT = 'reject';
export const PENDING_ADMIN = 'pending_admin_approval';
export const PENDING_USER = 'pending_user_acceptance';
export const TIMER = 5000;
export const PENDING_ADMIN_APPROVAL = 'Pending admin approval';
export const PENDING_ADMIN_RESPONSE = 'Pending invitee response';
export const USER_MENU_OPTION = {
  All: 'all',
  Licensed: 'licensed',
  Pending: 'pending',
  Unlicensed: 'unlicensed',
  active: 'active',
};

export type PresentedUserLicensingStatus =
  | 'licensed'
  | 'unlicensed'
  | 'pending invitation'
  | 'Pending admin approval'
  | 'Pending invitee response'
  | 'pending_admin_approval'
  | 'pending_user_acceptance';
