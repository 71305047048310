import React from 'react';
import ConfigureNumberPage from './ConfigureNumberPage/ConfigureNumberPage';
import EntryPage from './EntryPage/EntryPage';
import { useParams } from 'react-router-dom';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { AppRoutes } from '../../router/AppRoutes';
import { StyledLoader } from 'src/pages/VirtualPagersPage/components/StyledLoader';

const VirtualPagersPage = () => {
  const flag = IsFeatureFlagEnabled('virtualPagerNavigationTab');
  const { pagerId } = useParams<{ pagerId: string }>();

  if (flag === undefined) {
    return <StyledLoader />;
  }
  if (!flag) {
    return null;
  }
  return <div className="appContent">{pagerId ? <ConfigureNumberPage /> : <EntryPage />}</div>;
};

export default VirtualPagersPage;
