import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { User, DropSourceItem, EscalationRole, EscalationMember } from 'src/types';
import EscalationSourceOnCallList from 'src/pages/EscalationPage/escalation-layout/side-panel/EscalationSourceOnCallList';
import { typedUseSelector } from 'src/redux/store';
import SearchIcon from 'src/assets/svgs/SearchIcon';

const DragSourceButtonSwitcher = styled.div`
  margin-top: 1em;
  button {
    box-shadow: unset;
    border-radius: 0;
    width: 130px;
    height: 40px;
  }
`;

const SourceSearchInputWrapper = styled.div`
  margin-bottom: 1em;
  .MuiInputBase-root {
    height: 44px;
  }
`;

interface Props {
  users: User[];
  roles: EscalationRole[];
}

const EscalationOnCallSearchWrapper: React.FC<Props> = ({ users, roles }) => {
  const [searchBy, setSearchBy] = React.useState<'user' | 'role'>('role');
  const [searchKeyword, setSearchKeyWord] = React.useState<string>('');

  const escalationLadder = typedUseSelector((state) => state.escalationReducer.escalationLadder);

  const currentOnCallsInAllLevel = React.useMemo(() => {
    const currentLadder = escalationLadder.toArray();
    return currentLadder.reduce((acc, cur) => [...acc, ...cur.escalationMembers], [] as EscalationMember[]);
  }, [escalationLadder]);

  const filteredDropSourceItem: DropSourceItem[] = React.useMemo(() => {
    if (searchBy === 'user') {
      const filteredUsers = users.filter(
        (user) =>
          (user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase()).includes(searchKeyword.toLowerCase()) &&
          !currentOnCallsInAllLevel.find((onCallMember) => onCallMember.user && onCallMember.user.id === user.id),
      );
      return filteredUsers.map((user) => ({
        type: 'user',
        data: {
          user,
          role: null,
        },
      }));
    }

    if (searchBy === 'role') {
      const filteredRoles = roles.filter(
        (role) =>
          role.name.toLowerCase().includes(searchKeyword.toLowerCase()) &&
          !currentOnCallsInAllLevel.find((onCallMember) => onCallMember.role && onCallMember.role.id === role.id),
      );

      return filteredRoles.map((role) => ({
        type: 'role',
        data: {
          role,
          user: null,
        },
      }));
    }
  }, [searchBy, searchKeyword, currentOnCallsInAllLevel, users, roles]);

  return (
    <React.Fragment>
      <DragSourceButtonSwitcher>
        <Button
          variant="contained"
          color={searchBy === 'role' ? 'primary' : undefined}
          onClick={() => setSearchBy('role')}
          disableTouchRipple
        >
          By Roles
        </Button>
        <Button
          variant="contained"
          color={searchBy === 'user' ? 'primary' : undefined}
          onClick={() => setSearchBy('user')}
          disableTouchRipple
        >
          By User
        </Button>
      </DragSourceButtonSwitcher>

      <SourceSearchInputWrapper>
        <TextField
          fullWidth
          autoFocus
          variant="outlined"
          id="sourceSearch"
          name="sourceSearch"
          placeholder={`Search by ${searchBy} name`}
          value={searchKeyword}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) => setSearchKeyWord(event.target.value)}
        />
      </SourceSearchInputWrapper>
      <EscalationSourceOnCallList dropSourceItems={filteredDropSourceItem} searchBy={searchBy} />
    </React.Fragment>
  );
};

export default EscalationOnCallSearchWrapper;
