import { OrganizationReducerState } from 'src/redux/reducers/organizationReducer';
import { AuthPayload, User } from 'src/types';
import store from 'src/redux/store';
import { updateTokenLastUpdatedAt } from 'src/redux/actions/authorizeAction';
import { AUTH_INFO, CURRENT_ORGANIZATION } from 'src/constants/storageKeys';

export const getAuthToken = (): string => {
  if (window.localStorage.getItem(AUTH_INFO)) {
    const parsedAuthInfo = JSON.parse(window.localStorage.getItem(AUTH_INFO));
    return parsedAuthInfo.accessToken;
  }
};

export const getCurrentOrganization = (): OrganizationReducerState => {
  if (window.localStorage.getItem(CURRENT_ORGANIZATION)) {
    const parsedOrganizationInfo = JSON.parse(window.localStorage.getItem(CURRENT_ORGANIZATION));
    return parsedOrganizationInfo.organizationReducer;
  }
};

export const getCurrentOrganizationId = () => {
  const data = getCurrentOrganization();
  return data.organization_id;
};

export const getRefreshToken = (): string => {
  if (window.localStorage.getItem(AUTH_INFO)) {
    const parsedAuthInfo = JSON.parse(window.localStorage.getItem(AUTH_INFO));
    return parsedAuthInfo.refreshToken;
  }
};

export const getScopeToken = (): string => {
  if (window.localStorage.getItem(AUTH_INFO)) {
    const parsedAuthInfo = JSON.parse(window.localStorage.getItem(AUTH_INFO));
    return parsedAuthInfo.scopeToken;
  }
};

export const getAdminScopeToken = (): string => {
  if (window.localStorage.getItem(AUTH_INFO)) {
    const parsedCurrentOrganization = getCurrentOrganization();

    const organizationId = parsedCurrentOrganization?.organization_id || null;
    const departmentId = parsedCurrentOrganization?.department_id || null;
    const siteId = parsedCurrentOrganization?.site_id || null;

    const adminScope = {
      organizationId: organizationId,
      siteId: siteId,
      departmentId: departmentId,
      status: 'admin',
    };

    const adminScopeString = JSON.stringify(adminScope);
    const getScopeToken = btoa(adminScopeString);

    return getScopeToken;
  }
};

export const getSelfInfo = (): User => {
  if (window.localStorage.getItem(AUTH_INFO)) {
    const parsedAuthInfo = JSON.parse(window.localStorage.getItem(AUTH_INFO));
    return parsedAuthInfo.user;
  }
};

export const updateLocalStorageTokenInfo = (payload: AuthPayload) => {
  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload = JSON.parse(authInfo);

  const { accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt } = payload;

  const newParsedAuthInfo = {
    ...parsedAuthInfo,
    accessToken,
    accessTokenExpiresAt,
    refreshToken,
    refreshTokenExpiresAt,
  };
  localStorage.setItem(AUTH_INFO, JSON.stringify(newParsedAuthInfo));

  store.dispatch(updateTokenLastUpdatedAt());
};
