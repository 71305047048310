import React from 'react';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';
import { UserAccordionText } from 'src/pages/HomePage/views/UserAccordionText';
import { usePaginatedSearchState } from 'src/pages/HomePage/hooks/usePaginatedSearchState';
import { useInviteUsersFilter } from 'src/pages/HomePage/hooks/useInviteUsersFilter';
import { ORGANIZATION } from 'src/constants/organizationTypes';
import { usePaginatedDataState } from 'src/pages/HomePage/hooks/usePaginatedDataState';
import { MultiUserInputUser } from 'src/types';
import debounce from 'lodash/debounce';

const MultiUserInputPaginatedDropdownContainer = ({ setFieldValue }) => {
  const userViewModel = UserViewModel();

  const {
    searchLoading,
    setSearchLoading,
    localSearchText,
    setLocalSearchText,
    searchLicensedUserData,
    setSearchLicensedUserData,
  } = usePaginatedSearchState();
  const { selectedUsers, setSelectedUsers } = useInviteUsersFilter();

  const { isDoneRows, seenContinuationIds, setIsDoneRows, setSeenContinuationIds } = usePaginatedDataState();

  const fetchMorePaginatedSearchData = async () => {
    const continuationId = searchLicensedUserData?.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await userViewModel.getSearchedUsers({
        text: localSearchText,
        limit: 30,
        continuationId,
        organizationType: ORGANIZATION,
      });

      if ('error' in result) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchLicensedUserData({
          ...searchLicensedUserData,
          continuationId: result.continuationId,
          users: [...searchLicensedUserData.users, ...result.users],
        });

        if (result.continuationId === null) {
          setIsDoneRows(true);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const getSearchData = async (searchValue: string) => {
    const searchResult = await userViewModel.getSearchedUsers({
      text: searchValue,
      limit: 30,
      continuationId: null,
      organizationType: ORGANIZATION,
    });

    if ('error' in searchResult) {
      setSearchLicensedUserData(null);
    } else {
      setSearchLicensedUserData(searchResult);
    }

    setSearchLoading(false);
  };

  const handleCombinedSearch = React.useCallback(
    debounce(async (searchValue: string) => {
      await getSearchData(searchValue);
    }, 250),
    [],
  );

  const handleSearch = async (searchValue: string) => {
    setLocalSearchText(searchValue);

    if (searchValue === '') {
      setSearchLicensedUserData(null);
      return;
    }

    if (searchValue.length <= 1) {
      return;
    }

    setSearchLoading(true);

    handleCombinedSearch(searchValue);
  };

  const refactoredSearchedLicensedUserData: MultiUserInputUser[] =
    searchLicensedUserData?.users?.map((user) => {
      return {
        id: user.id,
        label: `${user.firstname} ${user.lastname}`,
        username: user.username,
        role: `${user.role || ''}`,
      };
    }) || [];

  return (
    <UserAccordionText
      userOptions={refactoredSearchedLicensedUserData}
      setFieldValue={setFieldValue}
      searchText={localSearchText}
      handleSearch={handleSearch}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
      setLocalSearchText={setLocalSearchText}
      fetchMorePaginatedSearchData={fetchMorePaginatedSearchData}
      isDoneRows={isDoneRows}
      isSearchLoading={searchLoading}
    />
  );
};

export default React.memo(MultiUserInputPaginatedDropdownContainer);
