import moment from 'moment';
import { OrganizationMutationScope, OrganizationScope } from 'src/types/Organization';
import { AuthRegion } from './Auth';

export interface UserScope extends OrganizationMutationScope {
  status: 'admin' | 'regular';
}

export interface GetUsersResult {
  admin: {
    users: User[];
  };
}

export interface GetAllCurrentAndBlacklistedUsersResult {
  admin: {
    users: User[];
    blacklistedUsers: User[];
  };
}

export interface GetAllLicensingUsersResult {
  admin: {
    users: User[];
    pendingUsers: User[];
    blacklistedUsers: User[];
  };
}

export interface FetchUserProfileResult {
  admin: {
    user: User;
  };
}

export interface FetchUserProfileResultFinal {
  adminQuery: {
    organizationalUnit: {
      member: User;
    };
  };
}

export interface FetchMembersResult {
  adminQuery: {
    organizationalUnit: {
      members: User[];
    };
  };
}

export interface FetchUserProfileAdminResult {
  data: {
    adminQuery: {
      organizationalUnit: {
        member: User;
      };
    };
  };
}

export interface UpdateUserProfileResult {
  admin: {
    user: {
      updateProfile: User;
    };
  };
}

export interface UpdateUserProfileV2Result {
  data: {
    adminMutation: {
      organizationalUnit: {
        member: {
          updateProfile: {
            __typename: string;
            id: string;
            firstname: string;
            lastname: string;
            role: string;
          };
        };
      };
    };
  };
}

export interface UpdateDirectorySyncStatusResult {
  adminMutation: {
    organizationalUnit: {
      member: {
        updateSyncStatus: {
          firstname: string;
          id: string;
          isDirectorySynced: boolean;
          lastname: string;
          role: string;
          message: string;
        };
      };
    };
  };
}

export interface UserSelectOptionType {
  label: string;
  value: string;
  username?: string;
  role?: string;
}

export interface ProfilePic {
  url: string | null;
}

export type UserAddressAccess = 'public' | 'private';
export type UserAddressOrdinal = 'primary' | 'secondary';
export type UserAddressType = 'phoneNumber' | 'email' | 'pager' | 'alphanumeric_pager';
export type SorterType = 'lastName' | 'joinDate' | 'lastSeen' | 'licensingStatus' | 'status' | 'updatedAt';
export type UserMenuOptionStatus = 'all' | 'licensed' | 'pending' | 'unlicensed';
export type UserLicensingStatus = 'licensed' | 'pending' | 'unlicensed';
export type ActionType = 'approve' | 'reject';

export interface UserAddress {
  access: UserAddressAccess;
  address: string;
  ordinal?: UserAddressOrdinal;
  type: UserAddressType;
  id?: string;
  syncStatus?: boolean;
  label?: string;
  __typename?: string;
}

export interface UserProfileValues {
  addresses: UserAddress[];
  firstname: string;
  lastname: string;
  organizations?: OrganizationScope[];
  licenseStartDate?: moment.Moment;
  licenseEndDate?: moment.Moment;
  role: string;
}

// joinDate, licenseStartTime and licenseExpiryTime are all public user field and not yet handled at local cache yet
// currently all user are treated as fullUser
export interface User {
  addresses?: UserAddress[];
  isDirectorySynced?: boolean;
  isSSOEnabled?: boolean;
  scopes?: UserScope[];
  email?: string;
  joinDate?: string;
  licenseStartTime?: string;
  licenseExpiryTime?: string;
  status: string;
  accountStatus?: string;
  firstname: string;
  id: string;
  lastname: string;
  organizations?: OrganizationScope[];
  profilePic: any;
  role: string;
  note?: string;
  username: string;
  address?: {
    address: string;
  };
  createdAt?: string;
  updatedAt?: string;
  inviteStatus?: string;
  resolvedBy?: {
    id: string;
    username: string;
    firstname: string;
    lastname: string;
  };
  invitedBy?: {
    id: string;
    username: string;
    firstname: string;
    lastname: string;
  };
  user?: PublicUserFragment;
  __typename?: string;
  notes?: {
    id: string;
    note: string;
  };
}

export interface PublicUserFragment {
  firstname: string;
  id: string;
  lastname: string;
  username: string;
}

export interface DefaultUser {
  id: string;
  username: string | null | void;
  email: string | null | void;
  firstname: string | null | void;
  lastname: string | null | void;
  role: string | null;
  profilePic: ProfilePic | null;
  joinDate?: string;
  eulaStatus?: boolean;
  statusDescription?: string | null;
  workStatusProxy?: any;
  status?: 'active' | 'inactive' | 'shell';
}

export interface GetPendingUsersResult {
  locating: {
    organization: {
      userInvites: User[];
    };
  };
}

export interface UserDevice {
  id: string;
  type: string;
  userAgent: string;
  pushToken: string;
  pushService: string;
  environment: string;
  browser: string;
}

export interface UserDeviceResult {
  adminQuery: {
    user: {
      devices: UserDevice[];
      id: string;
      firstName: string;
      lastName: string;
      username: string;
      role: string;
    };
  };
}

export enum AddressType {
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  NUMERIC_PAGER = 'pager',
  ALPHANUMERIC_PAGER = 'alphanumeric_pager',
}

export enum PagerTypeEvent {
  ALPHANUMERIC_PAGER = 'alphanumeric_pager',
  NUMERIC_PAGER = 'numeric_pager',
}

export interface FullOrganizationMember {
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  isDirectorySynced: boolean;
  workStatus?: {
    value: string;
    alternateContact?: {
      id: string;
      accountStatus: string;
      firstName: string;
      lastName: string;
      username: string;
    };
    message?: string;
  };
  accountStatus: string;
  avatar?: {
    url?: string;
  };
  role: string;
  statusDescription: string;
  statusExpiryDate: string;
  workStatusProxyId?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  addresses?: UserAddress[];
  __typename: string;
}

export interface MultiUserInputUser {
  id: string;
  label: string;
  username: string;
  role: string;
}

export type UserGeoData = {
  country: string;
  country_code: AuthRegion;
  country_code_iso3: AuthRegion;
  ip: string;
};
