import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mutation, MutationFunction, MutationResult } from 'react-apollo';
import * as ReactModal from 'react-modal';
import { PASSWORD_RESET } from 'src/gql/mutation/PasswordResetMutation';
import ResetAccountPasswordForm from 'src/components/forms/ResetPasswordForm';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { RootState } from 'src/redux/store';
import { User } from 'src/types';

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  profile: User;
}

interface State {
  isPasswordResetSuccess: boolean;
}

class PasswordModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordResetSuccess: false,
    };
  }

  public componentDidMount() {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.resetPasswordView);
  }

  public handlePasswordReset = (bool: boolean) => {
    this.setState({
      isPasswordResetSuccess: bool,
    });
  };

  public render() {
    const { isPasswordResetSuccess } = this.state;
    const { showModal, handleCloseModal, profile } = this.props;
    return (
      <ReactModal
        overlayClassName="modal__overlay"
        className="modal__resetPassword"
        isOpen={showModal}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <div
          className="close"
          onClick={() => {
            this.handlePasswordReset(false);
            handleCloseModal();
          }}
        />
        <div className="modal__header">
          {isPasswordResetSuccess ? 'Reset Password Successful!' : 'Reset Account Password'}
        </div>
        <PasswordResetMutation
          isPasswordResetSuccess={isPasswordResetSuccess}
          handlePasswordReset={this.handlePasswordReset}
          profile={profile}
        />
      </ReactModal>
    );
  }
}

const PasswordResetMutation = ({
  isPasswordResetSuccess,
  handlePasswordReset,
  profile,
}: {
  isPasswordResetSuccess: boolean;
  handlePasswordReset: (bool: boolean) => void;
  profile: User;
}) => {
  return (
    <Mutation mutation={PASSWORD_RESET}>
      {(resetPassword: MutationFunction, { data }: MutationResult<any>) => {
        return (
          <ResetAccountPasswordForm
            data={data}
            profile={profile}
            handlePasswordReset={handlePasswordReset}
            passwordSuccess={isPasswordResetSuccess}
            mutation={resetPassword}
          />
        );
      }}
    </Mutation>
  );
};

const getProfileData = (state, ownProps) => {
  const { profile } = ownProps;

  if (profile) {
    return profile;
  } else {
    return state.userDataReducer;
  }
};

const mapStateToProps = (state: RootState, ownProps) => {
  return {
    profile: getProfileData(state, ownProps),
  };
};

export default connect(mapStateToProps)(PasswordModal);
