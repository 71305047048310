import React from 'react';
import styled from 'styled-components';
import { VIRTUAL_PAGERS, PAGER_PAGE_HEADER_DESCRIPTION, LEARN_MORE } from 'src/constants/virtualPagerStrings';
import { HCHeadingThree } from 'src/components/shared/HypercareComponents';
import theme from 'src/assets/styles/theme';

const PagerPageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 15px;
  justify-content: space-between;
`;

const StyledLink = styled.a`
  color: #00859a;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  font-family: 'Open Sans';
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const DescriptionLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  font-family: 'Open Sans';
`;

const DescriptionAndLinkContainer = styled.div`
  width: 527px;
`;

interface PagerHeaderProps {
  buttonComponent: React.ReactNode;
  hasNumbers: boolean;
}

const PagerHeader: React.FC<PagerHeaderProps> = ({ buttonComponent, hasNumbers }) => {
  return (
    <div>
      <PagerPageHeaderContainer>
        <HCHeadingThree color={theme.black}>{VIRTUAL_PAGERS}</HCHeadingThree>
        {buttonComponent}
      </PagerPageHeaderContainer>
      {hasNumbers && (
        <DescriptionAndLinkContainer>
          <DescriptionLabel>{PAGER_PAGE_HEADER_DESCRIPTION}</DescriptionLabel>{' '}
          <span>
            <StyledLink
              target="_blank"
              referrerPolicy="no-referrer"
              href="https://www.hypercare.com/solution/pager-replacement-solutions-healthcare-professionals"
            >
              {LEARN_MORE}
            </StyledLink>
          </span>
        </DescriptionAndLinkContainer>
      )}
    </div>
  );
};

export default PagerHeader;
