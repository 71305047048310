import gql from 'graphql-tag';
import { GQLErrorType } from '../types/result';

export type EditUserAddressLabelMutationResult = {
  adminMutation: {
    organizationalUnit:
      | {
          __typename: 'AdminOrganizationMutation';
          member: {
            address:
              | {
                  __typename: 'AddressMutation';
                  updateLabel:
                    | {
                        __typename: 'Address';
                        id: string;
                      }
                    | GQLErrorType<'InvalidAddressLabel'>;
                }
              | GQLErrorType<'AddressNotFound'>;
          };
        }
      | GQLErrorType<'OrganizationalUnitNotFoundError'>
      | GQLErrorType<'UserNotAdminForScope'>;
  };
};

export const EditUserAddressLabelMutation = gql`
  mutation EditUserAddressLabel(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $address: String!
    $addressLabel: AddressLabel!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              address(address: $address) {
                ... on AddressMutation {
                  updateLabel(label: $addressLabel) {
                    ... on Address {
                      __typename
                      id
                    }
                    ... on InvalidAddressLabel {
                      __typename
                      message
                    }
                  }
                }
                ... on AddressNotFound {
                  __typename
                  message
                }
              }
            }
          }
          ... on AdminDepartmentMutation {
            member(id: $userId) {
              address(address: $address) {
                ... on AddressMutation {
                  updateLabel(label: $addressLabel) {
                    ... on Address {
                      __typename
                      id
                    }
                    ... on InvalidAddressLabel {
                      __typename
                      message
                    }
                  }
                }
                ... on AddressNotFound {
                  __typename
                  message
                }
              }
            }
          }
          ... on AdminSiteMutation {
            member(id: $userId) {
              address(address: $address) {
                ... on AddressMutation {
                  updateLabel(label: $addressLabel) {
                    ... on Address {
                      __typename
                      id
                    }
                    ... on InvalidAddressLabel {
                      __typename
                      message
                    }
                  }
                }
                ... on AddressNotFound {
                  __typename
                  message
                }
              }
            }
          }
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on UserNotAdminForScope {
            __typename
            message
          }
        }
      }
    }
  }
`;
