import React from 'react';
import { Mutation, MutationFunction } from 'react-apollo';
import { LOGOUT_ALL_DEVICES, DEVICE_LOGOUT } from 'src/gql/mutation/RemoteLogoutMutation';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogActions, IconButton } from '@material-ui/core';
import { DialogBoxTitle, PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: '24px 24px 16px 24px',
      padding: '0px',
    },
    dialogDescription: {
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: '#4A4A4A',
      marginBottom: '0px',
    },
    dialogAction: {
      margin: '16px 24px 16px 24px',
      padding: '0px',
    },
    dialogIconButton: {
      height: 'fit-content',
      margin: 'auto',
      marginRight: '0px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      float: 'right',
    },
    dialogContent: {
      padding: '0px 24px 40px 24px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  }),
);

interface Props {
  modalTitle: string;
  modalBody: string;
  isAllDevice: boolean;
  openModal: boolean;
  handleCloseModal: () => void;
  handleUserLogout: (remoteLogout: MutationFunction, isAllDevice: boolean) => void;
}

const DeviceLogoutModal = ({
  modalTitle,
  modalBody,
  isAllDevice,
  openModal,
  handleCloseModal,
  handleUserLogout,
}: Props) => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = React.useState(false);

  const handleSubmit = (remoteLogout, isAllDevice) => {
    setIsSubmit(true);
    handleUserLogout(remoteLogout, isAllDevice);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Mutation mutation={isAllDevice ? LOGOUT_ALL_DEVICES : DEVICE_LOGOUT}>
        {(remoteLogout: MutationFunction) => {
          return (
            <React.Fragment>
              <DialogBoxTitle id="alert-dialog-title" className={classes.dialogTitle}>
                {modalTitle}
                <IconButton onClick={handleCloseModal} className={classes.dialogIconButton}>
                  <CloseIcon color="#4A4A4A" width="15" height="15" />
                </IconButton>
              </DialogBoxTitle>
              <DialogContent className={classes.dialogContent}>
                <DialogContentText className={classes.dialogDescription} id="alert-dialog-description">
                  {modalBody}
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogAction}>
                <SecondaryButton type="submit" onClick={handleCloseModal}>
                  {`Don’t log out`}
                </SecondaryButton>
                <PrimaryButton
                  type="button"
                  disabled={isSubmit}
                  onClick={() => handleSubmit(remoteLogout, isAllDevice)}
                >
                  {`Confirm`}
                </PrimaryButton>
              </DialogActions>
            </React.Fragment>
          );
        }}
      </Mutation>
    </Dialog>
  );
};

export default DeviceLogoutModal;
