import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { Formik, FormikProps } from 'formik';
import { TextField, Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import { PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';
import {
  PUBLIC_ACCESS,
  PRIVATE_ACCESS,
  DESCRIPTION_LIMIT,
  ADD,
  NOTE_ADDED,
  NOTE_UPDATED,
  EDIT_NOTE_FORM_LABEL,
  DISCARD,
  ADD_NOTE,
  UPDATE,
  ROLE_NOTE_ADDED,
} from 'src/constants/hiddenNotes';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { PublicAccess, PrivateAccess, StyledToastWrapper } from 'src/components/hidden-notes/SharedCompoment';
import { NotesFormValue } from 'src/types/HiddenNotes';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { typedUseSelector } from 'src/redux/store';
import { SCHEDULING } from 'src/constants/scheduler';

const AddNoteContainer = styled.div`
  display: flow-root;
`;

const NoteLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.darkenFontColor};
  margin-bottom: 4px;
`;

const NoteDescription = styled(TextField)`
  width: 100%;
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 2px solid ${theme.mainTealColor} !important;
    }
  }
  & .MuiFormHelperText-contained {
    align-self: self-end;
    height: 14px !important;
  }
  textarea {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.darkenFontColor};
  }
`;

const AddActionButton = styled.div`
  display: flex;
  float: right;
  margin-top: 8px;
  margin-bottom: 16px;
  gap: 8px;
`;

const AccessButtonContainer = styled.div`
  position: relative;
  height: 28px;
  margin-top: 8px;
`;

const AccessButton = styled(Button)<{ access: string }>`
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 4px 12px !important;
  gap: 8px !important;
  position: absolute !important;
  height: 28px !important;
  background: ${(props) => (props.access === PUBLIC_ACCESS ? theme.lighterTeal : theme.lighterGrey)} !important;
  border-radius: 19px !important;
`;

const StyledMenu = withStyles({
  paper: {
    background: theme.white,
    boxShadow: '0px 18px 28px rgb(9 30 66 / 15%), 0px 0px 1px rgb(9 30 66 / 31%)',
    borderRadius: '4px',
    marginTop: '44px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '4px',
    position: 'absolute',
  },
})(Menu);

const StyledMenuItem = styled(MenuItem)`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 44px !important;
`;

interface AddNoteProps {
  page: string;
  action: string;
  selectedNoteId?: string;
  noteDescription?: string;
  selectedAccess?: string;
  handleCancelledNote: () => void;
  handleResetInputs?: () => void;
  submitForm: (values: { description: string; access: string; noteId?: string; roleId?: number }) => Promise<any>;
}

const NoteForm = ({
  page,
  action,
  selectedNoteId,
  noteDescription,
  selectedAccess,
  handleCancelledNote,
  handleResetInputs,
  submitForm,
}: AddNoteProps) => {
  const [accessActionType, setAccessActionType] = React.useState<null | HTMLElement>(null);
  const currentRoleSelected = typedUseSelector((state) => state.monthlyScheduleReducer.currentSelectedRole);

  const initialValues: NotesFormValue = {
    description: action === ADD ? '' : noteDescription,
    access: action === ADD ? PUBLIC_ACCESS : selectedAccess,
  };

  if (selectedNoteId) {
    initialValues['noteId'] = selectedNoteId;
  }

  const handleMakeNotePrivate = (setFieldValue) => {
    setAccessActionType(null);
    setFieldValue('access', PRIVATE_ACCESS);

    if (action === ADD) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteNewVisibilityOptionPressed,
        params: {
          visibility: PRIVATE_ACCESS,
        },
      });
    } else {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteEditVisibilityOptionPressed,
        params: {
          visibility: PRIVATE_ACCESS,
        },
      });
    }
  };

  const handleMakeNotePublic = (setFieldValue) => {
    setAccessActionType(null);
    setFieldValue('access', PUBLIC_ACCESS);

    if (action === ADD) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteNewVisibilityOptionPressed,
        params: {
          visibility: PUBLIC_ACCESS,
        },
      });
    } else {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteEditVisibilityOptionPressed,
        params: {
          visibility: PUBLIC_ACCESS,
        },
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm, setFieldError, setSubmitting }) => {
          let payload = {
            ...values,
            roleId: currentRoleSelected?.roleId,
          };

          const result = await submitForm(payload);

          if (result?.result) {
            resetForm();
            handleResetInputs();
            toast.success(
              <StyledToastWrapper>
                <CheckCircleIcon />
                {action === ADD ? <>{page === SCHEDULING ? ROLE_NOTE_ADDED : NOTE_ADDED}</> : <>{NOTE_UPDATED}</>}
              </StyledToastWrapper>,
              {
                className: 'toast-message',
                autoClose: 5000,
              },
            );
          }

          if (result?.error) {
            toast.error(result.error, {
              className: 'Toast-Container',
              autoClose: 10000,
            });
            handleResetInputs();
            setFieldError('general', result.error);
          }
        }}
        render={(props) => {
          const { values, handleChange, isSubmitting, setFieldValue, handleSubmit } = props as FormikProps<any>;
          const { description, access } = values as NotesFormValue;
          return (
            <form onSubmit={handleSubmit}>
              <AddNoteContainer data-testid="note-form">
                <NoteLabel>{EDIT_NOTE_FORM_LABEL}</NoteLabel>
                <NoteDescription
                  id="note-description"
                  name="description"
                  multiline
                  rows={4}
                  inputProps={{ maxLength: DESCRIPTION_LIMIT }}
                  defaultValue={description}
                  variant="outlined"
                  inputRef={(input) => input && input.focus()}
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                  }
                  onChange={handleChange}
                  helperText={`${description.length}/${DESCRIPTION_LIMIT}`}
                />
                <AccessButtonContainer>
                  <AccessButton
                    id={`action-button`}
                    aria-haspopup="true"
                    onClick={(e) => setAccessActionType(e.currentTarget)}
                    endIcon={accessActionType ? <ExpandLess /> : <ExpandMore />}
                    access={access}
                  >
                    {access === PUBLIC_ACCESS ? <PublicAccess page={page} /> : <PrivateAccess page={page} />}
                  </AccessButton>
                  <StyledMenu
                    id={`action-menu`}
                    keepMounted
                    anchorEl={accessActionType}
                    open={Boolean(accessActionType)}
                    onClose={() => setAccessActionType(null)}
                  >
                    <StyledMenuItem onClick={() => handleMakeNotePublic(setFieldValue)}>
                      <PublicAccess page={page} />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => handleMakeNotePrivate(setFieldValue)}>
                      <PrivateAccess page={page} />
                    </StyledMenuItem>
                  </StyledMenu>
                </AccessButtonContainer>
                <AddActionButton>
                  <SecondaryButton type="button" onClick={() => handleCancelledNote()}>
                    {DISCARD}
                  </SecondaryButton>
                  <PrimaryButton type="submit" disabled={isSubmitting || !description.trim()}>
                    {action === ADD ? ADD_NOTE : UPDATE}
                  </PrimaryButton>
                </AddActionButton>
              </AddNoteContainer>
            </form>
          );
        }}
      />
    </>
  );
};

export default NoteForm;
