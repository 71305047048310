import React from 'react';
import { Redirect } from 'react-router-dom';
import HypercareLogo from 'src/assets/static/horizontal-logo-white.svg';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import 'src/assets/styles/LoginStyles.scss';
import HypercareLoginCoordinator from 'src/pages/LoginPage/LoginCoordinator';

interface Props {
  isAuthorized: boolean;
}

const LoginLayout = ({ isAuthorized }: Props) => {
  if (isAuthorized) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          state: { params: '/home' },
        }}
      />
    );
  }
  return (
    <div className="login__wrapper">
      <div className="login__wrapper__central">
        <div className="hypercare_logo" onClick={() => alert(`Hypercare version is: ${process.env.REACT_APP_VERSION}`)}>
          <img src={HypercareLogo} alt="hypercare white logo" />
        </div>
        <HypercareLoginCoordinator />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isAuthorized: state.flagReducer.isAuthorized,
  };
};

const ConnectedLoginLayout = connect(mapStateToProps)(LoginLayout);

export default ConnectedLoginLayout;
