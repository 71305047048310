import React from 'react';
import theme from 'src/assets/styles/theme';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from 'src/components/shared/HypercareComponents';
import { Dialog, DialogContent, makeStyles, Divider, Grid, IconButton } from '@material-ui/core';
import {
  SecondaryButton,
  PrimaryButton,
  StyledDialogActions,
  StyledDialogTitle,
} from 'src/components/shared/HypercareComponents';

enum HypercareModalButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  LeftOutlined = 'leftOutlined',
}

const useStyles = makeStyles((theme) => ({
  paperWidthMd: {
    maxWidth: '50vw',
  },
  dialogContent: {
    padding: '0px 24px 24px 24px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: '20px',
  },
  muiDialogTitle: {
    margin: '24px 24px 8px 24px',
    padding: '0px',
  },
}));

interface HypercareModalButton {
  buttonLabel: string;
  type: string;
  disabled?: boolean;
  onClickHandler: (args?) => void;
}

interface HypercareModalProps {
  title: React.ReactNode;
  body: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  closeModal: (boolean) => void;
  isModalVisible: boolean;
  modalButtons?: Array<HypercareModalButton>;
}

interface MaterialDialogHeaderProps {
  loading?: boolean;
  dialogTitle: React.ReactNode;
  closeIconButtonClickHandler: () => void;
  showCloseIconButton?: boolean;
}

const DialogActionsView = ({ modalButtons }) => {
  const modalButtonsLength = modalButtons.length;
  return (
    <React.Fragment>
      {modalButtons.map((button, index) => {
        const { type, buttonLabel, onClickHandler, disabled } = button;
        if (type === HypercareModalButtonTypes.Secondary)
          return (
            <SecondaryButton
              key={index}
              data-testid="secondary-button"
              style={{ marginRight: modalButtonsLength > 2 ? 8 : 0 }}
              onClick={onClickHandler}
            >
              {buttonLabel}
            </SecondaryButton>
          );
        else if (type === HypercareModalButtonTypes.Primary)
          return (
            <PrimaryButton key={index} data-testid="primary-button" disabled={disabled} onClick={onClickHandler}>
              {buttonLabel}
            </PrimaryButton>
          );
        else return null;
      })}
    </React.Fragment>
  );
};

/**
 * Reusable Fn. component for material ui dialog header
 * @param {boolean} tells if the loading is true then disable close button
 * @param dialogTitle {string} title of the material ui dialog
 * @param closeIconButtonClickHandler function to execute on click of close icon button
 * @param showCloseIconButton {boolean} to show or hide the close button, default is true to show the icon button.
 */
const MaterialDialogHeader = ({
  loading = false,
  dialogTitle,
  closeIconButtonClickHandler,
  showCloseIconButton = true,
}: MaterialDialogHeaderProps) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography classes={{ root: classes.muiDialogTitle }}>
      <StyledDialogTitle variant="h6">{dialogTitle}</StyledDialogTitle>
      {showCloseIconButton && (
        <IconButton
          disabled={loading}
          aria-label="close"
          className={classes.closeButton}
          onClick={closeIconButtonClickHandler}
        >
          <CloseIcon color={theme.mainFontColor} width="15" height="15" />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

const CustomDialogActionsView = ({ modalButtons }) => {
  let modalLeftButton = modalButtons.find((button) => button.type === HypercareModalButtonTypes.LeftOutlined),
    { onClickHandler, buttonLabel, type } = modalLeftButton;
  return (
    <Grid container justify={'space-between'}>
      <Grid item>
        <SecondaryButton key={type} onClick={onClickHandler}>
          {buttonLabel}
        </SecondaryButton>
      </Grid>
      <Grid item>
        <DialogActionsView modalButtons={modalButtons} />
      </Grid>
    </Grid>
  );
};

/**
 * Reusable Hypercare modal, works for confirmation modals
 * @param title Title of the Hypercare Modal
 * @param body Body of the Dialog Modal
 * @param closeModal {(boolean) => void} Reference to a function to call for closing the dialog modal
 * @param isModalVisible Sets the dialog open or close
 * @param modalButtons [HypercareModalButton] Provide an array of objects to inflate two material ui buttons primary and secondary
 * @constructor
 */
const HypercareModal = (props) => {
  const { title, body, size, closeModal, isModalVisible, modalButtons }: HypercareModalProps = props;
  const classes = useStyles({});
  return (
    <Dialog
      fullWidth={true}
      open={isModalVisible}
      TransitionComponent={Transition}
      classes={{ paperWidthMd: classes.paperWidthMd }}
      onClose={() => closeModal(false)}
      maxWidth={size}
      disableEscapeKeyDown={true}
      onBackdropClick={() => 'false'}
    >
      <MaterialDialogHeader dialogTitle={title} closeIconButtonClickHandler={() => closeModal(false)} />
      <DialogContent className="custom-scrollbar" classes={{ root: modalButtons && classes.dialogContent }}>
        {body}
      </DialogContent>
      {modalButtons && (
        <>
          <Divider />
          <StyledDialogActions>
            {modalButtons.length > 2 ? (
              <CustomDialogActionsView modalButtons={modalButtons} />
            ) : (
              <DialogActionsView modalButtons={modalButtons} />
            )}
          </StyledDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default HypercareModal;
