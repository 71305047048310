import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormikProps, FieldArray } from 'formik';
import { makeStyles, Tooltip, withStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { HCCustomSpan, HCLabelOne } from 'src/components/shared/HypercareComponents';
import { UserAddress } from 'src/types/User';
import InfoIcon from 'src/assets/svgs/InfoIcon';
import { UserProfileValues } from 'src/types';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import 'src/assets/styles/FormStyles.scss';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import PlusButton from 'src/assets/svgs/PlusButton';
import { TextButton } from 'src/components/shared/HypercareComponents';
import { v4 as uuid } from 'uuid';

import {
  CONTACT,
  PREFERENCES,
  PROFILE_PAGE_PREFERENCE_ORDER_TOOLTIP_TEXT,
  PROFILE_PAGE_ADD_ANOTHER_ADDRESS_BUTTON_TEXT,
  TYPE,
  VISIBILITY,
  Label,
} from 'src/constants/strings';
import { StyledTooltipWhite } from 'src/components/StyledTooltip';
import { FeatureFlagResult } from '../../../../utils/FeatureFlags';

import { HypercareProfileAddressTableRows } from './ProfileAddressTableRows';
import WarningBanner from '../../../../components/shared/WarningBanner';
import { AddressBannerCoordinator } from '../../../../components/PageBanner/AddressBanner/AddressBannerCoordinator';

const selectUseStyles = makeStyles(() => {
  return {
    root: {
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      padding: '0px',
      width: '180px',
      '&:hover': {
        backgroundColor: theme.ghostWhite,
      },
    },
    select: {
      '&&': {
        paddingRight: 0,
      },
      '& ul': {
        borderRadius: '4px',
        backgroundColor: '#fff',
        display: 'flex',
        padding: '8px',
        flexDirection: 'column',
        alignItems: 'flexStart',
      },
      '& li': {
        padding: '10px 8px',
      },
    },
    selected: {
      borderRadius: '4px',
      backgroundColor: theme.ghostWhite,
    },
    font: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  };
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: '253px',
    padding: '16px !important',
    fontStyle: 'normal !important',
    fontWight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    fontFamily: 'Open Sans !important',
    backgroundColor: '#fff !important',
    background: '#fff !important',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.16) !important',
    borderRdius: '8px !important',
    position: 'relative',
    color: '#FF3E55',
    borderRadius: theme.shape.borderRadius,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '100%', // Position the arrow at the bottom of the tooltip
      left: '10%', // Adjust this value for arrow positioning
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderTop: `8px solid ${theme.palette.common.white}`, // Match the tooltip background color
    },
  },
}))(Tooltip);

const reorder = (list, startIndex, endIndex) => {
  const result: UserAddress[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 8px 0`,
  background: isDragging ? '#F2F2F2' : '',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  width: '100%',
  background: isDraggingOver ? '' : '',
  padding: '8px',
});

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const TH = styled.th`
  text-align: left;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  padding: 8px;
`;

const TR = styled.tr`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #d8d8d8;
`;

const DeleteIconWrapper = styled.div`
  width: 10px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DeleteRowIconHolder = styled.div`
  color: grey;
  position: relative;
  margin-left: 3px;
  user-select: none;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const AddContactMethodButton = styled.div`
  height: 42px;

  &:hover {
    background-color: f6f6f9 !important;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
`;

const PreferenceCellWrapper = styled.div<{ isEditMode: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: ${(props) => (props.isEditMode ? '26px' : '')};
`;

const PreferencesCellIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  formikProps: FormikProps<UserProfileValues>;
  isInputDisabled: boolean;
  onModeChange: (mode: 'view' | 'edit') => void;
  setCurrentAddresses?: React.Dispatch<React.SetStateAction<UserAddress[]>>;
}

export const createAddress = () => {
  const defaultInitialAddress: UserAddress = {
    access: 'public',
    address: '',
    type: 'email',
    id: uuid(),
  };

  return defaultInitialAddress;
};

const ProfileAddressView = ({ formikProps, isInputDisabled, onModeChange, setCurrentAddresses }: Props) => {
  const adminAlphanumericPaging = IsFeatureFlagEnabled(FeatureFlagResult.adminAlphanumericPaging);
  const { values, touched, errors, setFieldValue, handleChange } = formikProps as FormikProps<any>;
  const { addresses } = values;
  const [isHoverList, setIsHoverList] = useState(false);
  const selectClasses = selectUseStyles();
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabels);
  const [isProfileLabelOptionsOpen, setIsProfileLabelOptionsOpen] = useState(false);

  const missingAddressBannerFlag = IsFeatureFlagEnabled(FeatureFlagResult.missingAddressBanner);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newItems: UserAddress[] = reorder(addresses, result.source.index, result.destination.index);
    const addressArray = newItems.map((address) => {
      return address;
    });
    setCurrentAddresses(addressArray);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileOrderChanged,
    });
  };

  const handleAddContactMethodButtonPressed = (arrayHelpers) => {
    arrayHelpers.push(createAddress());
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileAddAnotherMethodButtonPressed,
    });
  };

  const handleDeleteContactMethodButtonPressed = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileRemoveButtonPressed,
    });
  };

  return (
    <FieldArray
      name="addresses"
      render={(arrayHelpers) => (
        <TableContainer>
          {missingAddressBannerFlag && <AddressBannerCoordinator addresses={addresses} />}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <CustomTable ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  <thead style={{ borderBottom: '1px solid black' }}>
                    <TR>
                      <TH style={{ width: '150px' }}>
                        <PreferenceCellWrapper isEditMode={!isInputDisabled}>
                          <span>{PREFERENCES}</span>
                          <StyledTooltipWhite
                            arrow
                            title={PROFILE_PAGE_PREFERENCE_ORDER_TOOLTIP_TEXT}
                            className="styledPopper"
                            placement="top"
                          >
                            <PreferencesCellIconWrapper>
                              <InfoIcon />
                            </PreferencesCellIconWrapper>
                          </StyledTooltipWhite>
                        </PreferenceCellWrapper>
                      </TH>
                      {profileAddressLabelFlag && <TH style={{ width: '120' }}>{Label}</TH>}
                      <TH style={{ width: '200px' }}>{TYPE}</TH>
                      <TH>{CONTACT}</TH>
                      {<TH style={{ width: '200px' }}>{VISIBILITY}</TH>}
                      {!isInputDisabled && <TH style={{ width: '100px' }}></TH>}
                    </TR>
                  </thead>
                  <tbody style={{ width: '100%' }}>
                    {addresses.map((address: UserAddress, index) => {
                      return (
                        <HypercareProfileAddressTableRows
                          isInputDisabled={isInputDisabled}
                          address={address}
                          index={index}
                          handleChange={handleChange}
                          onModeChange={onModeChange}
                          errors={errors}
                          touched={touched}
                          addresses={addresses}
                          arrayHelpers={arrayHelpers}
                          setFieldValue={setFieldValue}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                </CustomTable>
              )}
            </Droppable>
          </DragDropContext>
          {!isInputDisabled && Boolean(errors.addresses) && typeof errors.addresses === 'string' && (
            <FormHelperText error>{errors.addresses}</FormHelperText>
          )}
          {!isInputDisabled && (
            <AddContactMethodButton>
              <TextButton onClick={() => handleAddContactMethodButtonPressed(arrayHelpers)}>
                <PlusButton />
                <HCLabelOne color={theme.mainTealColor}>{PROFILE_PAGE_ADD_ANOTHER_ADDRESS_BUTTON_TEXT}</HCLabelOne>
              </TextButton>
            </AddContactMethodButton>
          )}
        </TableContainer>
      )}
    />
  );
};

export default ProfileAddressView;
