export type AccessType = 'public' | 'private';

export interface HiddenNotes {
  id: string;
  note: string;
  access: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
    avatar?: Avatar;
  };
  editedBy: {
    id: string;
    firstName: string;
    lastName: string;
    avatar?: Avatar;
  };
  createdAt: string;
  updatedAt: string;
}

export interface Avatar {
  id: number | null;
  url: string | null;
}

export interface ModalProps {
  openModal: boolean;
  note: HiddenNotes;
  handleCloseModal: (openModal: boolean) => void;
  handlePrimayButton: (noteId?: string, setIsSubmitting?: (isSubmitting) => void) => void;
}

export interface NotesFormValue {
  noteId?: string;
  description: string;
  access: string;
  roleId?: number;
}

export interface EditNotesFormValues {
  noteId: string;
  description: string;
  access: string;
}

export interface PersonalNotesResult {
  adminQuery: {
    user: {
      notes: any;
      user: {
        id: string;
        notes: HiddenNotes;
      };
    };
  };
}

export interface SelfProfileNotesResult {
  selfQuery: {
    notes: HiddenNotes[];
  };
}

export interface AdminFetchProfileNotesbyMemberResult {
  adminQuery: {
    organizationalUnit: {
      member: {
        id?: string;
        notes: HiddenNotes[];
      };
    };
  };
}

export interface AdminCreateRoleNoteResult {
  adminMutation: {
    organizationalUnit: {
      schedule: {
        role: {
          createNote: HiddenNotes[];
        };
      };
    };
  };
}

export interface IRoleNote {
  noteId?: string;
  roleId: number;
  access: AccessType;
  description: string;
}

export interface IRoleNoteRequest {
  scheduleId: number;
  roleId: number;
  details: {
    note: string;
    access: AccessType;
  };
  noteId?: number;
}
export interface BasicRoleField {
  id: string;
  name: string;
  notes: HiddenNotes[];
}

export interface DepartmentRoleNoteObject {
  [key: string]: {
    notes: HiddenNotes[];
    roleName: string;
  };
}

export interface FetchRoleNotesForDepartmentResult {
  organizationalUnitQuery: {
    organizationalUnit: {
      id: string;
      scheduling: {
        schedule: {
          roles: BasicRoleField[];
        };
      };
    };
  };
}

export enum ProfileFieldVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
