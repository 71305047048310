import gql from 'graphql-tag';
import RoleFragment from './RoleFragment';

export const scheduleFragmentString = `
  id
  name
  startDate
  endDate
  state
  updatedAt
  createdAt
  __typename
  
`;

export default gql`
  fragment ScheduleFragment on Schedule {
    ${scheduleFragmentString}
  }
`;
