import React from 'react';
import styled from 'styled-components';
import SubHeaderMonthSwitcher from 'src/pages/SchedulingPage/scheduling-layout/scheduling-sub-header/SubHeaderMonthSwitcher';
import SubHeaderSettings from 'src/pages/SchedulingPage/scheduling-layout/scheduling-sub-header/SubHeaderSettings';
import moment from 'moment';
import store from 'src/redux/store';
import { FetchRolesAndShiftsResult } from 'src/types';
import { ApolloQueryResult } from 'apollo-boost';
import { GRIDSCHEDULINGVIEW, LISTSCHEDULINGVIEW, SCHEDULINGQUERYPARAM } from 'src/constants/scheduler';
import SchedulingToggleButton from 'src/components/SchedulingToggleButton';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { AppRoutes } from '../../../router/AppRoutes';
import { AdminFetchScheduleResponse } from '../../../gql/query/AdminFetchSchedule';

interface Props {
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<AdminFetchScheduleResponse>>;
  startDate: moment.Moment;
  selectedCalendarView: string;
  flags?: { [key: string]: boolean };
}

const CalendarWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const CalendarWrapperPart = styled.div`
  width: 100%;
`;

const CalenderMonthSwitcher = styled.div`
  margin-top: -1em;
  width: 100%;
`;

const ScheduleSubHeaderWrapper = styled.div`
  text-align: center;
`;

class SchedulingSubHeader extends React.PureComponent<Props> {
  public render() {
    const { refetch, startDate, selectedCalendarView, flags } = this.props;
    const reduxOrganization = store.getState().organizationReducer;
    const organizationType = reduxOrganization.type;

    const viewChangeHandler = () => {
      if (selectedCalendarView === LISTSCHEDULINGVIEW) {
        window.routerHistory.push(`${AppRoutes.Scheduling}?${SCHEDULINGQUERYPARAM}=${GRIDSCHEDULINGVIEW}`);
      } else {
        window.routerHistory.push(AppRoutes.Scheduling);
      }
    };

    return (
      <ScheduleSubHeaderWrapper>
        {organizationType === 'department' && <SubHeaderSettings />}

        <CalendarWrapper>
          <CalendarWrapperPart />
          <CalenderMonthSwitcher>
            <SubHeaderMonthSwitcher refetch={refetch} startDate={startDate} />
          </CalenderMonthSwitcher>
          <CalendarWrapperPart>
            <SchedulingToggleButton value={selectedCalendarView} onChange={viewChangeHandler} />
          </CalendarWrapperPart>
        </CalendarWrapper>
      </ScheduleSubHeaderWrapper>
    );
  }
}

export default withLDConsumer()(SchedulingSubHeader);
