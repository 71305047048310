import React from 'react';
import store from 'src/redux/store';
import styled from 'styled-components';
import ChevronRight from 'src/assets/svgs/ChevronRight';
import { toast } from 'react-toastify';
import theme from 'src/assets/styles/theme';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DeviceLogoutModal from 'src/components/modals/DeviceLogoutModal';
import CustomToaster from 'src/components/CustomToaster';
import { TIMER } from 'src/constants/inviteUserTypes';
import Info from 'src/assets/svgs/Info';
import { useQuery } from 'react-apollo';
import Loader from 'src/components/loaders/UserProfileLayoutLoader';
import { UserDeviceResult } from 'src/types';
import { FETCH_USER } from 'src/gql/v2/query/FetchUserQuery';
import { USER_NOT_LOGIN_ON_DEVICE } from 'src/constants/user';
import UserDeviceItem from 'src/pages/UserProfilePage/profile-layout/UserDeviceItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rejectToastr: {
      background: '#4A4A4A',
    },
  }),
);

const StyledInfo = styled(Info)`
  height: 16px !important;
  width: 16px !important;
`;

const DeviceListSection = styled.section`
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 5%;
  padding-right: 5%;
`;

const DeviceContainer = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  min-height: 13rem;
  display: flex;
`;

const DeviceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  justify-content: flex-start;
  width: calc(100% - 67%);
  padding-top: 32px;
`;

const DeviceHeading = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  margin-bottom: 8px;
  color: ${theme.darkenFontColor};
`;

const DeviceSubHeading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
`;

const DeviceListContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  padding: 16px;
  gap: 8px;
  background: #fafafa;
  border: 1px solid ${theme.borderBottomLight};
  border-radius: 4px;
`;

const DeviceDetailsContainer = styled.div`
  padding-bottom: 8px;
  margin-left: 4%;
`;

const LogoutDeviceLink = styled.div<{ noRecord }>`
  margin-top: 16px;
  user-select: none;
  cursor: ${(props) => (props.noRecord ? 'no-drop' : 'pointer')};
  color: ${(props) => (props.noRecord ? theme.warmGreyColor : theme.errorRed)};
  justify-self: start;
  align-self: flex-start;
  font-size: 18px;
  padding-right: 88px;
  display: flex;
  align-items: flex-end;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
`;

const NoDevicesConatiner = styled.div`
  display: flex;
`;

const NoDevices = styled.div`
  margin-left: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
`;

const StyledIconContainer = styled.div`
  display: flex !important;
  margin-bottom: 2px !important;
`;

const DeviceListGridContainer = styled.div`
  width: 100%;
  padding-top: 34px;
`;

const UserDeviceList = ({ user }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [isAllDevice, setIsAllDevice] = React.useState(false);
  const [deviceId, setDeviceId] = React.useState(null);
  const [title, setTitle] = React.useState('');
  const [deviceVersion, setDeviceVersion] = React.useState('');

  const { data, loading, error, refetch } = useQuery<UserDeviceResult>(FETCH_USER, {
    variables: {
      userId: user.id,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loader />;

  if (error) {
    toast.error('An Error Occurred, Please Check Your Internet Connection And Try Again.', {
      className: 'Toast-Container',
    });

    return;
  }

  const userDetails = data?.adminQuery?.user;

  const modalBody = `This is a list of all mobile devices that this user is actively logged into Hypercare on. You can log the
  user out of any/all devices if you feel there is inappropriate activity or if the device(s) have been
  compromised.`;

  const handleOpenModal = (device, deviceVersion) => {
    const title = `Log ${userDetails.firstName} ${userDetails.lastName} out of "${deviceVersion}”?`;

    setDeviceId(device.id);
    setTitle(title);
    setDeviceVersion(deviceVersion);
    setOpenModal(true);
  };

  const handleOpenAllModal = (isLogoutAll) => {
    const title = `Log ${userDetails.firstName} ${userDetails.lastName} out of all devices?`;

    setIsAllDevice(isLogoutAll);
    setTitle(title);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setDeviceId(null);
  };

  const handleUserLogout = (remoteLogout, isAllDevice) => {
    const { id } = user;
    let variable = {};
    const userOrganization = store.getState().organizationReducer;
    if (isAllDevice) {
      variable['organizationId'] = userOrganization?.organization_id;
      variable['userId'] = id;
    } else {
      variable['organizationId'] = userOrganization?.organization_id;
      variable['userId'] = userDetails.id;
      variable['deviceId'] = deviceId;
    }

    remoteLogout({ variables: variable })
      .then(() => successLogoutUser())
      .catch(() => errorLogoutUser());
  };

  const successLogoutUser = () => {
    handleCloseModal();
    toast(<CustomToaster body={`Logged out of ${isAllDevice ? `all devices` : `“${deviceVersion}”`}.`} />, {
      className: classes.rejectToastr,
      autoClose: TIMER,
    });
    refetch();
  };

  const errorLogoutUser = () => {
    toast(<CustomToaster body={`Error when logging out member.`} />, {
      className: classes.rejectToastr,
      autoClose: TIMER,
    });
  };

  return (
    <>
      <DeviceListSection>
        <DeviceContainer>
          <DeviceSection>
            <DeviceHeading>Devices</DeviceHeading>
            <DeviceSubHeading>
              {`This is a list of all mobile devices that this user is actively logged into Hypercare on. You can log the
              user out of any/all devices if you feel there is inappropriate activity or if the device(s) have been
              compromised.`}
            </DeviceSubHeading>
          </DeviceSection>
          <DeviceListGridContainer>
            {userDetails?.devices?.length > 0 ? (
              <>
                {userDetails?.devices?.map((device, i) => {
                  return <UserDeviceItem key={i} device={device} handleOpenModal={handleOpenModal} />;
                })}
              </>
            ) : (
              <DeviceDetailsContainer>
                <DeviceListContainer>
                  <NoDevicesConatiner>
                    <StyledInfo circlefill={theme.warmGreyColor} strokefill={'white'} />
                    <NoDevices>{USER_NOT_LOGIN_ON_DEVICE}</NoDevices>
                  </NoDevicesConatiner>
                </DeviceListContainer>
              </DeviceDetailsContainer>
            )}
            <DeviceDetailsContainer>
              <LogoutDeviceLink
                noRecord={userDetails?.devices?.length === 0 ? true : false}
                onClick={() => userDetails?.devices?.length > 0 && handleOpenAllModal(true)}
              >
                Log out of all devices
                <StyledIconContainer>
                  <ChevronRight fill={userDetails?.devices?.length === 0 ? theme.warmGreyColor : theme.errorRed} />
                </StyledIconContainer>
              </LogoutDeviceLink>
            </DeviceDetailsContainer>
          </DeviceListGridContainer>
        </DeviceContainer>
      </DeviceListSection>
      {openModal && (
        <DeviceLogoutModal
          modalTitle={title}
          modalBody={modalBody}
          isAllDevice={isAllDevice}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          handleUserLogout={handleUserLogout}
        />
      )}
    </>
  );
};

export default UserDeviceList;
