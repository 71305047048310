import React from 'react';
import { FormikProps, FieldArray } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Select, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import formatPhoneNumber from 'src/utils/formatPhoneNumber';
import { removePlusSignWithNumber } from 'src/utils/formatPhoneNumber';
import { UserAddress, UserAddressType } from 'src/types';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import 'src/assets/styles/FormStyles.scss';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { createAddress } from 'src/pages/UserProfilePage/profile-layout/components/ProfileAddressView';
import { NUMERIC_PAGER } from 'src/constants/strings';

function getLabelByType(type: UserAddressType) {
  switch (type) {
    case 'phoneNumber':
      return 'Phone number';
    case 'email':
      return 'Email';
    case 'alphanumeric_pager':
    case 'pager':
      return 'Pager';
    default:
      break;
  }
}

const StyledSelect = styled(Select)`
  option {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: ${theme.darkenFontColor} !important;
`;

const ProfileFormAddresses = ({ formikProps, isInputDisabled }) => {
  const adminAlphanumericPaging = IsFeatureFlagEnabled('adminAlphanumericPaging');
  const { values, touched, errors, setFieldValue, handleChange } = formikProps as FormikProps<any>;
  const { addresses } = values;

  return (
    <FieldArray
      name="addresses"
      render={(arrayHelpers) => (
        <div className={'clearfix profileForm__userAddresses'} style={{ position: 'relative' }}>
          <label className="profileForm__userAddresses__label">CONTACT INFORMATION</label>
          {addresses && addresses.length === 0 && isInputDisabled && <div>--</div>}
          {addresses.map((address, index) => {
            return (
              <div className={'profileForm__userAddresses__wrapper'} key={`address-${index}`}>
                <TextField
                  fullWidth
                  disabled={isInputDisabled}
                  style={{ marginTop: 0 }}
                  id={`addresses.${index}.address`}
                  name={`addresses.${index}.address`}
                  label={getLabelByType(address.type)}
                  value={
                    address.type === 'phoneNumber'
                      ? isInputDisabled
                        ? formatPhoneNumber(address.address)
                        : address.address
                      : address.address
                  }
                  InputProps={
                    address.type === 'phoneNumber'
                      ? {
                          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                        }
                      : undefined
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  onChange={handleChange}
                  helperText={
                    errors.addresses && errors.addresses[index] && touched.addresses && touched.addresses[index]
                      ? errors.addresses[index].address
                      : ''
                  }
                  error={Boolean(
                    errors.addresses &&
                      errors.addresses[index] &&
                      errors.addresses[index].address &&
                      touched.addresses &&
                      touched.addresses[index],
                  )}
                />
                <div style={{ display: 'flex' }} className={isInputDisabled ? 'disableSelectIcon' : undefined}>
                  <FormControl style={{ width: '200px', marginLeft: '10px' }}>
                    <InputLabel shrink htmlFor={`addresses.${index}.type`}>
                      Type
                    </InputLabel>
                    <StyledSelect
                      value={address.type}
                      disabled={isInputDisabled}
                      onChange={handleChange}
                      inputProps={{
                        id: `addresses.${index}.type`,
                        name: `addresses.${index}.type`,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <StyledMenuItem value={'email'}>Email</StyledMenuItem>
                      <StyledMenuItem value={'phoneNumber'}>Phone</StyledMenuItem>
                      {adminAlphanumericPaging && (
                        <StyledMenuItem value={'alphanumeric_pager'}>Alphanumeric Pager</StyledMenuItem>
                      )}
                      <StyledMenuItem value={'pager'}>{NUMERIC_PAGER}</StyledMenuItem>
                    </StyledSelect>
                  </FormControl>

                  <FormControl style={{ width: '150px', marginLeft: '10px' }}>
                    <InputLabel shrink htmlFor={`addresses.${index}.access`}>
                      Visibility
                    </InputLabel>
                    <StyledSelect
                      value={address.access}
                      disabled={isInputDisabled}
                      onChange={handleChange}
                      inputProps={{
                        id: `addresses.${index}.access`,
                        name: `addresses.${index}.access`,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <StyledMenuItem value={'public'}>Public</StyledMenuItem>
                      <StyledMenuItem value={'private'}>Private</StyledMenuItem>
                    </StyledSelect>
                  </FormControl>

                  <FormControl disabled={isInputDisabled}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={`addresses.${index}.ordinal`}
                          name={`addresses.${index}.ordinal`}
                          checked={addresses[index].ordinal === 'primary'}
                          onChange={(e) => {
                            addresses.forEach((element, i) => {
                              if (element.ordinal === 'primary' && i !== index) {
                                setFieldValue(`addresses.${i}.ordinal`, 'secondary');
                              }
                            });
                            setFieldValue(`addresses.${index}.ordinal`, e.target.value);
                          }}
                          value={address.ordinal === 'primary' ? 'secondary' : 'primary'}
                        />
                      }
                      label="Primary"
                      labelPlacement="top"
                    />
                  </FormControl>
                </div>
                {addresses.length >= 1 && !isInputDisabled && (
                  <div className="deleteRowIconHolder" onClick={() => arrayHelpers.remove(index)}>
                    <i className="material-icons">delete</i>
                  </div>
                )}
              </div>
            );
          })}
          {!isInputDisabled && Boolean(errors.addresses) && typeof errors.addresses === 'string' && (
            <FormHelperText error>{errors.addresses}</FormHelperText>
          )}

          {!isInputDisabled && (
            <div className="plusSignHolder">
              <Button
                aria-label="add"
                disableTouchRipple
                variant="outlined"
                onClick={() => arrayHelpers.push(createAddress())}
              >
                <i className="material-icons">add</i>
                Add Address
              </Button>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default ProfileFormAddresses;
function uuid(): string {
  throw new Error('Function not implemented.');
}
