import React from 'react';

export default ({ width = 24, height = 20, viewBox = '0 0 24 20' }) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1375 9.58675L17.916 0.649C17.8455 0.527664 17.7444 0.426953 17.6228 0.356935C17.5012 0.286917 17.3633 0.250044 17.223 0.25H6.77702C6.63682 0.250175 6.49911 0.287111 6.37765 0.357122C6.25618 0.427134 6.15519 0.527773 6.08477 0.649L0.862517 9.5875C0.78931 9.71292 0.750732 9.85553 0.750732 10.0007C0.750732 10.146 0.78931 10.2886 0.862517 10.414L6.08552 19.3517C6.15594 19.473 6.25693 19.5736 6.3784 19.6436C6.49987 19.7136 6.63757 19.7506 6.77777 19.7507H17.2238C17.364 19.7506 17.5017 19.7136 17.6231 19.6436C17.7446 19.5736 17.8456 19.473 17.916 19.3517L23.1375 10.4125C23.2107 10.2871 23.2493 10.1445 23.2493 9.99925C23.2493 9.85403 23.2107 9.71217 23.1375 9.58675ZM11.157 4H12.8445V12.25H11.157V4ZM12 16.75C11.7775 16.75 11.56 16.684 11.375 16.5604C11.19 16.4368 11.0458 16.2611 10.9607 16.0555C10.8755 15.85 10.8532 15.6238 10.8966 15.4055C10.94 15.1873 11.0472 14.9868 11.2045 14.8295C11.3619 14.6722 11.5623 14.565 11.7805 14.5216C11.9988 14.4782 12.225 14.5005 12.4305 14.5856C12.6361 14.6708 12.8118 14.815 12.9354 15C13.059 15.185 13.125 15.4025 13.125 15.625C13.125 15.9234 13.0065 16.2095 12.7955 16.4205C12.5845 16.6315 12.2984 16.75 12 16.75Z"
      fill="#ECAD0B"
    />
  </svg>
);
