import React from 'react';
// import styled from 'styled-components';
// import {typedUseSelector} from 'src/redux/store';
import {
  EscalationLadderWrapper,
  EscalationLevelWrapper,
  EscalationLevelArrow,
  BaseEscalationOnCallMembersWrapper,
  EscalationLevelTitle,
  OnCallMemberListWrapper,
  BaseEscalationPolicyWrapper,
  EscalationDetail,
  BaseOnCallMemberHolder,
  OnCallNameHolder,
  OnCallRoleTitle,
  OnCallRoleTime,
} from 'src/pages/EscalationPage/escalation-layout/SharedEscalationStyles';
import moment from 'moment';
import { ARBITRARY_DATE } from 'src/constants/scheduler';
import { EscalationLadder } from 'src/types';

interface Props {
  escalationLadder: EscalationLadder;
}

const EscalationReadOnlyContainer: React.FC<Props> = ({ escalationLadder }) => {
  // const escalationLadder = typedUseSelector((state) => state.escalationReducer.escalationLadder);
  // const escalationLadderList = escalationLadder.toArray();

  // only creation endpoint are ready, forgo update / delete
  const escalationLadderLevelList = escalationLadder.escalationLevels.sort((a, b) => (a.level < b.level ? -1 : 1));

  return (
    <EscalationLadderWrapper id="EscalationLadderWrapper">
      {escalationLadderLevelList.map((escalationLevel, i) => {
        return (
          <React.Fragment key={i}>
            <EscalationLevelWrapper>
              <BaseEscalationOnCallMembersWrapper>
                <EscalationLevelTitle>Escalation Level {i + 1}</EscalationLevelTitle>
                <OnCallMemberListWrapper>
                  {escalationLevel.escalationMembers.map((onCallMember, j) => {
                    const { user: userData, role: roleData } = onCallMember;
                    if (userData)
                      return (
                        <BaseOnCallMemberHolder key={j}>
                          <OnCallNameHolder>
                            <div>
                              {userData.firstname} {userData.lastname}
                            </div>
                          </OnCallNameHolder>
                          <OnCallRoleTitle>{userData.role || '--'}</OnCallRoleTitle>
                        </BaseOnCallMemberHolder>
                      );
                    if (roleData) {
                      const startTime = moment
                        .utc(`${ARBITRARY_DATE} ${roleData.startTime}`, 'YYYY/MM/DD HH:mm')
                        .local();
                      const endTime = moment.utc(`${ARBITRARY_DATE} ${roleData.endTime}`, 'YYYY/MM/DD HH:mm').local();
                      return (
                        <BaseOnCallMemberHolder key={j}>
                          <OnCallNameHolder>
                            <div>{roleData.name}</div>
                          </OnCallNameHolder>
                          <OnCallRoleTime>
                            <span>
                              {roleData.repeatRule} ({startTime.format('HH:mm')} - {endTime.format('HH:mm')}
                            </span>
                            {startTime >= endTime && <sup> +1</sup>})
                          </OnCallRoleTime>
                        </BaseOnCallMemberHolder>
                      );
                    }
                    return null;
                  })}
                </OnCallMemberListWrapper>
              </BaseEscalationOnCallMembersWrapper>

              <EscalationLevelArrow />

              {escalationLevel.escalationPolicy && (
                <BaseEscalationPolicyWrapper>
                  <div>{escalationLevel.escalationPolicy.name}</div>
                  <EscalationDetail>
                    Try each channel every{' '}
                    {moment.duration(escalationLevel.escalationPolicy.channels[0].timeBetweenAttempts).asMinutes()}{' '}
                    minutes for {escalationLevel.escalationPolicy.channels[0].numAttempts} attempts
                  </EscalationDetail>
                </BaseEscalationPolicyWrapper>
              )}
            </EscalationLevelWrapper>

            {i < escalationLadderLevelList.length - 1 ? <EscalationLevelArrow /> : null}
          </React.Fragment>
        );
      })}
    </EscalationLadderWrapper>
  );
};

export default EscalationReadOnlyContainer;
