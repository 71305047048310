import React from 'react';
import store from 'src/redux/store';
import styled from 'styled-components';
import { AuthPayload } from 'src/types/Auth';
import { useParams } from 'react-router-dom';
import theme from 'src/assets/styles/theme';
import AddIcon from 'src/assets/svgs/AddIcon';
import EyeIcon from 'src/assets/svgs/EyeIcon';
import LockIcon from 'src/assets/svgs/LockIcon';
import { AUTH_INFO } from 'src/constants/storageKeys';
import { PUBLIC_ACCESS, PRIVATE_ACCESS, PROFILE } from 'src/constants/hiddenNotes';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { SCHEDULING } from '../../constants/scheduler';
import { NOTE_PRIVATE_ACCESS_TEXT, NOTE_PUBLIC_ACCESS_TEXT } from '../../constants/strings';

const AccessContainer = styled.div<{ page: string }>`
  margin-left: 8px;
  display: flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.darkenFontColor};
  text-transform: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flow-root;
  text-align: start;
  max-width: ${(props) => (props.page === SCHEDULING ? '425px' : '')};
`;

const Access = styled.div`
  display: inline;
  margin-right: 4px;
  font-weight: 600;
  text-transform: capitalize;
`;

const AddNoteButtonContainer = styled.div<{ color: string; limit: number }>`
  width: fit-content;
  height: 24px;
  display: inline-flex;
  gap: 16px;
  cursor: ${(props) => (props.limit ? 'no-drop' : 'pointer')};
  padding: 4px;
  div {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: ${(props) => props.color};
  }
  ${(props) => {
    if (props.color === props.theme.errorRed)
      return `
      :hover path {
        fill: ${theme.warmRed};
      }
      :hover div {
        color: ${theme.warmRed};
      }`;
  }};
`;

interface AccessTypeProps {
  page: string;
}

export const PublicAccess = ({ page }: AccessTypeProps) => {
  const { organization_name } = store.getState()?.organizationReducer;
  return (
    <>
      <EyeIcon />
      <div>
        <AccessContainer page={page}>
          <Access data-testid="access">{PUBLIC_ACCESS}:</Access>
          {NOTE_PUBLIC_ACCESS_TEXT(organization_name)}
        </AccessContainer>
      </div>
    </>
  );
};

export const PrivateAccess = ({ page }: AccessTypeProps) => {
  const { userId } = useParams<{ userId: string }>();
  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload = authInfo ? JSON.parse(authInfo) : null;
  const currentUser = store.getState()?.userDataReducer;
  const isSelf = parsedAuthInfo?.user?.id === userId;
  const { organization_name } = store.getState()?.organizationReducer;

  return (
    <>
      <LockIcon />
      <div>
        <AccessContainer page={page}>
          <Access data-testid="access">{PRIVATE_ACCESS}:</Access>
          {NOTE_PRIVATE_ACCESS_TEXT(organization_name)}
        </AccessContainer>
      </div>
    </>
  );
};

export const AddNoteButton = (props) => {
  const { color, setIsAddNotesActive, isNoteExceedLimit } = props;
  const addNoteButtonPressed = () => {
    if (!isNoteExceedLimit) setIsAddNotesActive(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileAddNoteButtonPressed,
    });
  };
  return (
    <AddNoteButtonContainer
      data-testid="add-note"
      onClick={addNoteButtonPressed}
      color={color}
      limit={isNoteExceedLimit}
    >
      <AddIcon fill={color} styles={{ marginTop: '2px' }} />
      <div>Add note</div>
    </AddNoteButtonContainer>
  );
};

export const StyledToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;
