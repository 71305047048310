import React from 'react';
import styled from 'styled-components';
import SchedulingContainer from 'src/pages/SchedulingPage/scheduling-layout/SchedulingGridContainer';
import SchedulingSiteContainer from 'src/pages/SchedulingPage/scheduling-layout/SchedulingSiteContainer';
import BackTopButton from 'src/components/shared/BackTopFAB';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import AppTheme from 'src/assets/styles/theme';
import store from 'src/redux/store';
import { DEPARTMENT } from 'src/constants/organizationTypes';

const SchedulingWrapper = styled.div`
  padding: 0 3%;
  margin-bottom: 70px;
`;

const schedulingLayoutTheme = createMuiTheme({
  palette: {
    primary: { main: AppTheme.mainTealColor },
    secondary: { main: AppTheme.mainButtonColor },
  },
});

const SchedulingLayout = () => {
  const reduxOrganization = store.getState().organizationReducer;
  if (reduxOrganization.type === DEPARTMENT)
    return (
      <SchedulingWrapper>
        <ThemeProvider theme={schedulingLayoutTheme}>
          <SchedulingContainer />
          <BackTopButton offSetY={200} />
        </ThemeProvider>
      </SchedulingWrapper>
    );
  else
    return (
      <SchedulingWrapper>
        <ThemeProvider theme={schedulingLayoutTheme}>
          <SchedulingSiteContainer />
        </ThemeProvider>
      </SchedulingWrapper>
    );
};

export default SchedulingLayout;
