import gql from 'graphql-tag';
import ConflictShiftFragment from './ConflictShiftFragmentUser';

export const shiftFragmentString = `
  id
  user {
    id
    username
    firstname
    lastname
    profilePic {
      url
    }
  }
  startDate
  endDate
  warningShifts {
    conflictShift {
        ...ConflictShiftFragment
    }
  }
  conflictingShifts {
    conflictShift {
      ...ConflictShiftFragment
    }
  }
`;

export default gql`
  fragment ShiftFragment on Shift {
    ${shiftFragmentString}
  }
  ${ConflictShiftFragment}
`;
