import moment from 'moment';

// schedule state
export const DRAFT = 'draft';
export const PUBLISHED = 'published';

// Reducuer
export const REDUCER_DATE_FORMAT = 'YYYY-MM-DD';

export const ARBITRARY_DATE = moment().format('YYYY/MM/DD');

//scheduling view
export const GRIDSCHEDULINGVIEW = 'grid';
export const LISTSCHEDULINGVIEW = 'list';
export const SWAPSCHEDULINGVIEW = 'swap';
export const SCHEDULINGQUERYPARAM = 'view';
export const SCHEDULING = 'scheduling';
