import React from 'react';
import { StyledModal, CloseBtn, Header, Footer } from 'src/components/modals/ModalStyleComponents';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { HCBodySpan, HCHeadingThree, HCLabelOne, SecondaryButton } from 'src/components/shared/HypercareComponents';
import { BannerMessage } from 'src/components/shared/BannerMessage';
import ConfigureNumberViewModel from './ConfigureNumberViewModel';
import { VirtualPagerStatusTypes, VirtualPagers } from 'src/types';
import {
  CANCEL,
  DISABLE_NUMBER_BUTTON_TEXT,
  DISABLING_NUMBER_ACKNOWLEDGEMENT_ITEM_1,
  DISABLING_NUMBER_ACKNOWLEDGEMENT_ITEM_2,
  DISABLING_NUMBER_BANNER_MESSAGE_PART_1,
  DISABLING_NUMBER_BANNER_MESSAGE_PART_2,
  DISABLING_NUMBER_BANNER_MESSAGE_PART_3,
  DISABLING_NUMBER_HEADER,
  DISABLING_NUMBER_WARNING_TEXT_PART_1,
  DISABLING_NUMBER_WARNING_TEXT_PART_2,
  DISABLING_NUMBER_WARNING_TEXT_PART_3,
} from 'src/constants/virtualPagerStrings';

import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const StylizedModal = styled(StyledModal)`
  .ReactModal__Content {
    width: 480px;
    padding: 0;
  }
`;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 12px;
`;

const ModalFooter = styled(Footer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 16px 24px;
  gap: 8px;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  pager: VirtualPagers;
}

const ModalDisableNumber = ({ isOpen, closeModal, pager }: Props) => {
  const { configureStatus } = ConfigureNumberViewModel();
  const dispatch = useDispatch();

  const handleClick = async () => {
    const newPager = await configureStatus(pager._id, VirtualPagerStatusTypes.DISABLED);
    dispatch(allActions.virtualPagerAction.setEditedAndCurrentPager(newPager));
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaDisableNumberConfirmPressed,
      params: {
        virtual_pager_number: pager.pagerNumber,
      },
    });
    closeModal();
  };

  return (
    <StylizedModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <ModalContainer>
        <Header>
          <HCHeadingThree>{DISABLING_NUMBER_HEADER}</HCHeadingThree>
          <CloseBtn onClick={closeModal} />
        </Header>
        <BannerMessage
          description={
            <HCBodySpan>
              {DISABLING_NUMBER_BANNER_MESSAGE_PART_1}
              <a href="mailto:support@hypercare.com">
                <HCBodySpan isLink={true}>{DISABLING_NUMBER_BANNER_MESSAGE_PART_2}</HCBodySpan>
              </a>
              {DISABLING_NUMBER_BANNER_MESSAGE_PART_3}
            </HCBodySpan>
          }
          type="warning"
        ></BannerMessage>
        <HCLabelOne>
          {DISABLING_NUMBER_WARNING_TEXT_PART_1}
          <strong>{DISABLING_NUMBER_WARNING_TEXT_PART_2}</strong>
          {DISABLING_NUMBER_WARNING_TEXT_PART_3}
        </HCLabelOne>
        <ul style={{ marginTop: '0px' }}>
          <li>
            <HCLabelOne>{DISABLING_NUMBER_ACKNOWLEDGEMENT_ITEM_1}</HCLabelOne>
          </li>
          <li>
            <HCLabelOne>{DISABLING_NUMBER_ACKNOWLEDGEMENT_ITEM_2}</HCLabelOne>
          </li>
        </ul>
      </ModalContainer>
      <ModalFooter>
        <SecondaryButton onClick={closeModal} color={theme.mainFontColor}>
          {CANCEL}
        </SecondaryButton>
        <SecondaryButton disabled={false} color={theme.errorRed} onClick={handleClick}>
          {DISABLE_NUMBER_BUTTON_TEXT}
        </SecondaryButton>
      </ModalFooter>
    </StylizedModal>
  );
};

export default ModalDisableNumber;
