import gql from 'graphql-tag';
import { OrganizationFragments } from 'src/gql/fragment/ManagementFragments';

export default gql`
  query GetOrganizations {
    me {
      id
      organizations {
        ...OrganizationFragments
      }
    }
  }
  ${OrganizationFragments}
`;
