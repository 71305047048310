import React from 'react';
import { RootState } from 'src/redux/store';
import { connect } from 'react-redux';
import ScheduleInputModal from 'src/components/modals/ScheduleInputModal';
import GridRoleHeaderArea from 'src/pages/SchedulingPage/scheduling-layout/scheduling-grid/GridRoleHeaderArea';
import RoleBodyDetail from 'src/pages/SchedulingPage/scheduling-layout/scheduling-grid/RoleBodyDetail';
import RoleBodyDetailOvernight from 'src/pages/SchedulingPage/scheduling-layout/scheduling-grid/RoleBodyDetailOvernight';
import { Role, ScheduleModalPayload, MonthlyCalendar } from 'src/types';
import { DEPARTMENT } from 'src/constants/organizationTypes';
import {
  GridContainer,
  GridBodyArea,
  cellWidth,
  cellGap,
  dateFieldWidth,
  GridScrollProgressIndicatorWrapper,
  GridScrollProgressIndicator,
} from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';

interface Props {
  roleContainer: Role[];
  monthlyCalendar: MonthlyCalendar;
  organizationType: string;
  overnightShifts: boolean;
  holidays: any;
  scheduleId: number;
}

interface State {
  isMouseMoving: boolean;
  showInputScheduleModal: boolean;
  inputScheduleModalPayload: ScheduleModalPayload;
}

class GridWrapper extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isMouseMoving: false,
      showInputScheduleModal: false,
      inputScheduleModalPayload: null,
    };
  }

  handleOpenScheduleModal = (payload: ScheduleModalPayload) => {
    this.setState({
      showInputScheduleModal: true,
      inputScheduleModalPayload: payload,
    });
  };
  handleCloseScheduleModal = () => {
    this.setState({
      showInputScheduleModal: false,
      inputScheduleModalPayload: null,
    });
  };

  setGridHorizontalDragScroll = () => {
    const slider = document.getElementById('gridBodyArea') as HTMLElement;
    if (!slider) return;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    let mousemoveFlag = false;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      mousemoveFlag = false;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      const { isMouseMoving } = this.state;
      if (mousemoveFlag) {
        if (!isMouseMoving) this.setState({ isMouseMoving: true });
      } else {
        if (isMouseMoving) this.setState({ isMouseMoving: false });
      }
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      mousemoveFlag = true;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      // velocity
      const walk = (x - startX) * 1;
      slider.scrollLeft = scrollLeft - walk;
    });
  };

  handleScroll = (event: React.FormEvent<HTMLElement>) => {
    const { currentTarget } = event;
    const headerElement = document.getElementById('gridHeaderArea');
    const bodyElement = document.getElementById('gridBodyArea');

    let maxScrollLeft = bodyElement.scrollWidth - bodyElement.clientWidth;
    let scrollPercent = (bodyElement.scrollLeft / maxScrollLeft) * 100 + '%';
    document.getElementById('GridScrollProgressIndicator').style.setProperty('--scroll', scrollPercent);

    headerElement.scrollLeft = currentTarget.scrollLeft;
  };

  componentDidMount = () => {
    this.setGridHorizontalDragScroll();
  };

  render() {
    const { roleContainer, monthlyCalendar, organizationType, overnightShifts, holidays, scheduleId } = this.props;
    const { showInputScheduleModal, inputScheduleModalPayload, isMouseMoving } = this.state;

    // Note: StickyContainer is breaking the width of role-body grid area, set width base on number of roles
    const numberOfRoles = roleContainer.length;
    const gridBodyWidth = `calc(${cellWidth} * ${numberOfRoles} + ${cellGap} * ${numberOfRoles} + ${dateFieldWidth})`;

    const isDepartment = organizationType === DEPARTMENT;

    return (
      <React.Fragment>
        <GridScrollProgressIndicatorWrapper>
          <GridScrollProgressIndicator id="GridScrollProgressIndicator" />
        </GridScrollProgressIndicatorWrapper>

        <GridContainer id="gridContainer">
          <GridRoleHeaderArea isDepartment={isDepartment} roleContainer={roleContainer} scheduleId={scheduleId} />

          <GridBodyArea style={{ maxWidth: gridBodyWidth }} id="gridBodyArea" onScroll={this.handleScroll}>
            {overnightShifts ? (
              <RoleBodyDetailOvernight
                gridBodyWidth={gridBodyWidth}
                isDepartment={isDepartment}
                handleOpenScheduleModal={this.handleOpenScheduleModal}
                monthlyCalendar={monthlyCalendar}
                flag={isMouseMoving}
                holidays={holidays}
              />
            ) : (
              <RoleBodyDetail
                gridBodyWidth={gridBodyWidth}
                isDepartment={isDepartment}
                handleOpenScheduleModal={this.handleOpenScheduleModal}
                monthlyCalendar={monthlyCalendar}
                flag={isMouseMoving}
                holidays={holidays}
              />
            )}
          </GridBodyArea>
        </GridContainer>

        {showInputScheduleModal && (
          <ScheduleInputModal
            payload={inputScheduleModalPayload}
            hasCalendar={false}
            showModal={showInputScheduleModal}
            handleCloseModal={this.handleCloseScheduleModal}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    roleContainer: state.monthlyScheduleReducer.roleContainer,
    monthlyCalendar: state.monthlyScheduleReducer.monthlyCalendar,
    organizationType: state.organizationReducer.type,
  };
};

export default connect(mapStateToProps)(GridWrapper);
