export const PROFILE = 'profile';
export const PUBLIC_ACCESS = 'public';
export const PRIVATE_ACCESS = 'private';
export const ADD_NOTES = 'Add notes';
export const ADD_NOTE = 'Add note';
export const EDIT_NOTE = 'Edit note';
export const DELETE_NOTE = 'Delete note';
export const ADD = 'add';
export const DONE = 'Done';
export const EDIT = 'edit';
export const DESCRIPTION_LIMIT = 1000;
export const ACCESS = ['public', 'private'];
export const NOTE_LIMIT = 25;
export const DISCARD = 'Discard';
export const DISCARDED = 'Discarded';
export const NOTE_ADDED = 'Note added to your profile!';

export const ROLE_NOTE_ADDED = 'Note successfully added!';

export const NOTE_UPDATED = 'Note updated!';
export const NOTE_DELETED = 'Note deleted!';
export const EDIT_NOTE_FORM_LABEL = `Enter up to ${DESCRIPTION_LIMIT} characters`;
export const UPDATE = 'Update';
export const NO_NOTES_FOUND = "User does not have any notes at this time. Click 'Add note' to get started.";
export const NO_ROLE_NOTES_FOUND =
  "This role currently has no notes. Click 'Add note' to get started or 'Done' to skip.";
export const HN_NETWORK_ERROR = 'A Network Error Has Occured, Please Check Your Internet Connection And Try Again.';
export const ROLE_NOTES = 'Role notes';

export const ROLE_NOTE_MODAL_TITLE = (roleName: string) => `Add notes for '${roleName}'`;

export const VISIBILITY = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
} as const;
