import moment from 'moment';
import clonedeep from 'lodash.clonedeep';
import {
  HiddenNotes,
  MonthlyCalendar,
  Role,
  RoleResultInfo,
  ScheduleStateType,
  SetRolesAndShiftsActionPayload,
  User,
} from 'src/types';
import splitShifts from 'src/utils/schedulingHelper/splitShifts';
import { ARBITRARY_DATE, REDUCER_DATE_FORMAT } from 'src/constants/scheduler';
import { MonthlyScheduleActionTypes } from 'src/redux/actions/monthlyScheduleAction';
import {
  SET_ADD_ROLE_NOTE,
  SET_ADD_ROLE_TO_ROLE_NOTES,
  SET_CLEAR_ROLE_NOTES,
  SET_CURRENT_SELECTED_ROLE,
  SET_DELETE_ROLE_NOTE,
  SET_LAST_UPDATED_AT,
  SET_ROLE_NOTE_MODAL_VISIBILITY,
  SET_ROLE_NOTES,
  SET_ROLES_AND_SHIFTS,
  SET_SCHEDULE_DATE,
  SET_SCHEDULE_ID,
  SET_SHOULD_SHOW_GAP,
  SET_TOTAL_CONFLICTS_WARNINGS,
  SET_UPDATE_ROLE_NOTE,
  SET_USER_COLOR,
} from 'src/constants/actionNames';
import { getSelfInfo } from 'src/utils/getLocalAuth';
import { allowedSchedulingCellBackgroundColors } from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';
import AppTheme from 'src/assets/styles/theme';
import identifyGapsRanges from 'src/utils/schedulingHelper/identifyGapsRanges';
import identifyGapsRangesWithOvernight from 'src/utils/schedulingHelper/identifyGapsRangesWithOvernight';

const monthlyRoles = [] as Role[];

// formatting base on utc time
function initMonthlyCalendar(startDate?: string, endDate?: string): MonthlyCalendar {
  const monthlyDays = {};

  const startOfMonth = startDate ? moment(startDate).add(2, 'days').startOf('month') : moment().utc().startOf('month');
  const endOfMonth = endDate
    ? moment(endDate).subtract(2, 'days').endOf('month').millisecond(0o00)
    : moment().utc().endOf('month').millisecond(0o00);

  let day = startOfMonth;

  while (day <= endOfMonth) {
    monthlyDays[day.format(REDUCER_DATE_FORMAT)] = monthlyRoles.map((role, index) => {
      return {
        shiftId: 'test',
        role: { ...role },
        assignee: [],
      };
    });
    day = day.clone().add(1, 'd');
  }

  return monthlyDays;
}

function sortByUpdatedAt(payloadA: RoleResultInfo, payloadB: RoleResultInfo) {
  let updatedAtA = moment(payloadA.updatedAt);
  let updatedAtB = moment(payloadB.updatedAt);
  if (payloadA.displayPosition === payloadB.displayPosition) {
    if (updatedAtA.isBefore(updatedAtB)) return -1;
    if (updatedAtA.isAfter(updatedAtB)) return 1;
    return 0;
  }

  return payloadA.displayPosition - payloadB.displayPosition;
}

let initialState: MonthlyScheduleReducerState = {
  roleContainer: [] as Role[],
  monthlyCalendar: initMonthlyCalendar() as MonthlyCalendar,
  scheduleId: null,
  scheduleState: null,
  startDateISOstring: null,
  endDateISOstring: null,
  lastUpdatedAt: null,
  userToHexColorCode: null,
  shouldShowGap: null,
  isGapInRequiredRole: null,
  totalShiftConflictAndWarnings: null,
  roleNotes: null,
  isRoleNoteModalVisible: false,
  currentSelectedRole: null,
};

export interface MonthlyScheduleReducerState {
  roleContainer: Role[];
  monthlyCalendar: MonthlyCalendar;
  scheduleId: number;
  scheduleState: ScheduleStateType;
  shouldShowGap: boolean;
  isGapInRequiredRole: boolean;
  startDateISOstring: string;
  endDateISOstring: string;
  lastUpdatedAt: string;
  totalShiftConflictAndWarnings: {
    numberOfConflicts: number;
    numberOfWarnings: number;
  };
  userToHexColorCode: {
    [userId: string]: string;
  };
  roleNotes: {
    [key: string]: {
      notes: HiddenNotes[];
      roleName: string;
    };
  };
  isRoleNoteModalVisible: boolean;
  currentSelectedRole: {
    roleId: number;
    roleName: string;
  };
}

export const monthlyScheduleReducer = (
  state = initialState,
  action: MonthlyScheduleActionTypes,
): typeof initialState => {
  let newState: MonthlyScheduleReducerState;
  let roleId;
  switch (action.type) {
    case SET_LAST_UPDATED_AT: {
      return {
        ...state,
        lastUpdatedAt: action.payload.lastUpdatedAt,
      };
    }

    case SET_ROLES_AND_SHIFTS: {
      const { roles, startDate, endDate, scheduleInfo, overnightShifts } =
        action.payload as SetRolesAndShiftsActionPayload;

      const monthlyCalendar: MonthlyCalendar = clonedeep(initMonthlyCalendar(startDate, endDate));
      const roleContainer: Role[] = [];

      roles.sort(sortByUpdatedAt).forEach((role, index) => {
        // arbitrary date as role only need range and startTime
        const utcStartTime = moment.utc(`${ARBITRARY_DATE} ${role.startTime}`, 'YYYY/MM/DD HH:mm');
        const utcEndTime = moment.utc(`${ARBITRARY_DATE} ${role.endTime}`, 'YYYY/MM/DD HH:mm');

        const formattedRole = {
          roleId: role.id,
          roleName: role.name,
          startTime: utcStartTime.local(),
          endTime: utcEndTime.local(),
          duration: role.duration,
          repeatRule: role.repeatRule,
          required: role.required ? role.required : false,
          displayPosition: role.displayPosition,
          updatedAt: role.updatedAt,
          index,
        } as Role;

        roleContainer.push(formattedRole);

        Object.keys(monthlyCalendar).forEach((key) => {
          monthlyCalendar[key].push({
            role: formattedRole,
            assignee: [],
            scheduleGap: overnightShifts ? [] : null,
          });
        });

        splitShifts(role, monthlyCalendar, index, overnightShifts);
      });

      // find gaps in ranges
      const isGapInRequiredRole = overnightShifts
        ? identifyGapsRangesWithOvernight(monthlyCalendar)
        : identifyGapsRanges(monthlyCalendar);

      return {
        ...state,
        startDateISOstring: startDate,
        endDateISOstring: endDate,
        scheduleId: scheduleInfo ? scheduleInfo.id : state.scheduleId,
        scheduleState: scheduleInfo ? scheduleInfo.state : state.scheduleState,
        shouldShowGap: (scheduleInfo && scheduleInfo.state === 'published') || !!state.shouldShowGap,
        isGapInRequiredRole,
        roleContainer,
        monthlyCalendar,
        lastUpdatedAt: state.lastUpdatedAt,
        userToHexColorCode: state.userToHexColorCode,
      } as MonthlyScheduleReducerState;
    }

    case SET_TOTAL_CONFLICTS_WARNINGS:
      return {
        ...state,
        totalShiftConflictAndWarnings: action.payload,
      };

    case SET_SCHEDULE_DATE:
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        shouldShowGap: false,
        startDateISOstring: startDate,
        endDateISOstring: endDate,
      };

    case SET_SHOULD_SHOW_GAP:
      const { shouldShowGap } = action.payload;
      return {
        ...state,
        shouldShowGap,
      };

    case SET_USER_COLOR:
      let parsedSelfInfo: User = getSelfInfo();
      let selfID = parsedSelfInfo ? parsedSelfInfo.id : null;
      let { userIdSet } = action.payload;
      let colorsArray = [...allowedSchedulingCellBackgroundColors];

      let userToColorCodeMap = state.userToHexColorCode
        ? {
            ...state.userToHexColorCode,
          }
        : {
            [selfID]: AppTheme.mainGreenColor,
          };

      const existingColors = Object.keys(userToColorCodeMap).map((userId) => userToColorCodeMap[userId]);

      Array.from(userIdSet).forEach((id) => {
        if (id === selfID) return;
        if (userToColorCodeMap[id]) return;

        let pickedColor = colorsArray.splice(Math.floor(Math.random() * colorsArray.length), 1)[0];

        // TODO: current solution still possible to generate alike random colors
        if (!pickedColor || existingColors.includes(pickedColor)) {
          pickedColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
          while (pickedColor.length < 7) pickedColor = pickedColor + '0';
        }

        userToColorCodeMap[id] = pickedColor;
      });

      return {
        ...state,
        userToHexColorCode: { ...userToColorCodeMap },
      };

    case SET_SCHEDULE_ID:
      const { scheduleId } = action.payload;
      return {
        ...state,
        scheduleId,
      };

    case SET_ROLE_NOTES:
      return {
        ...state,
        roleNotes: { ...action.payload },
      };

    case SET_ADD_ROLE_TO_ROLE_NOTES:
      const newRoleObject = {
        [action.payload.id]: {
          notes: [] as HiddenNotes[],
          roleName: action.payload.roleName,
        },
      };
      return { ...state, roleNotes: { ...newRoleObject, ...state.roleNotes } };
    case SET_CLEAR_ROLE_NOTES: {
      return {
        ...state,
        roleNotes: {},
      };
    }

    case SET_ADD_ROLE_NOTE:
      const { id, note } = action.payload;
      newState = { ...state };
      newState.roleNotes[id].notes = [note, ...newState.roleNotes[id]?.notes];
      return newState;

    case SET_DELETE_ROLE_NOTE:
      const { deletedNoteId } = action.payload;
      roleId = action.payload.roleId;
      newState = { ...state };

      newState.roleNotes[roleId].notes = newState.roleNotes[roleId].notes.filter(
        (note: HiddenNotes) => note.id !== deletedNoteId,
      );
      return newState;

    case SET_UPDATE_ROLE_NOTE:
      const { updatedNoteObject } = action.payload;
      roleId = action.payload.roleId;
      newState = { ...state };
      newState.roleNotes[roleId].notes = newState.roleNotes[roleId].notes.map((note: HiddenNotes) =>
        note.id === updatedNoteObject.id ? { ...updatedNoteObject } : note,
      );
      return newState;

    case SET_ROLE_NOTE_MODAL_VISIBILITY:
      let { visibility } = action.payload;
      return {
        ...state,
        isRoleNoteModalVisible: visibility,
      };
    case SET_CURRENT_SELECTED_ROLE:
      let { roleName } = action.payload;
      return {
        ...state,
        currentSelectedRole: {
          roleId: action.payload.roleId,
          roleName,
        },
      };

    default:
      return state;
  }
};
