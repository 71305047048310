import React from 'react';
import { HelperBox, StyledAnchor, StyledSubmitAddressButton } from 'src/pages/LoginPage/components/styles';
import { CONTACT_US, FORGOT_YOUR_PASSWORD, HAVING_SOME_ISSUES } from 'src/constants/strings';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoginPages } from 'src/types';

export const LoginFormFooter = (props: {
  submitting: boolean;
  values: string;
  onClick: () => void;
  submitButtonText: string;
  buttonText: string;
  page: LoginPages;
}) => {
  return (
    <>
      <StyledSubmitAddressButton
        data-testid="addressSubmitButton"
        type="submit"
        disabled={props.submitting || !props.values}
      >
        {props.submitting && (
          <>
            <CircularProgress color="inherit" size={18} style={{ marginRight: 16 }} /> {props.submitButtonText}
          </>
        )}
        {!props.submitting && props.buttonText}
      </StyledSubmitAddressButton>
      <HelperBox>
        {props.page === 'password' && (
          <StyledAnchor
            onClick={props.onClick}
            href="https://app.hypercare.com/resetpassword"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>{FORGOT_YOUR_PASSWORD}</span>
          </StyledAnchor>
        )}
        <StyledAnchor
          onClick={props.onClick}
          href="https://www.hypercare.com/contact"
          rel="noopener noreferrer"
          target="_blank"
        >
          {HAVING_SOME_ISSUES}
          <span>{CONTACT_US}</span>
        </StyledAnchor>
      </HelperBox>
    </>
  );
};
