import React from 'react';
import client from 'src/clients/apolloClient';
import { connect } from 'react-redux';
import PromoteUserMutation from 'src/gql/mutation/PromoteUserMutation';
import { toast } from 'react-toastify';
import {
  User,
  UserOrganizationSwitcherPayload,
  OrganizationScope,
  OrganizationMutationScope,
  FetchUserProfileAdminResult,
  UserScope,
  FetchOrganizationScopeList,
  FetchUserProfileResultFinal,
} from 'src/types';
import OrganizationDropdown from 'src/components/popup-dropdowns/OrganizationDropdown';
import Button from '@material-ui/core/Button';
import clonedeep from 'lodash.clonedeep';
import Chip from '@material-ui/core/Chip';
import generateScopeHash from 'src/utils/generateScopeHash';
import { FETCH_USER_PROFILE, FETCH_USER_PROFILE_DIR_SYNC } from 'src/gql/v2/query/FetchUserProfileQuery';
import { RootState } from 'src/redux/store';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
interface Props {
  profile: User;
  handleCloseModal: () => void;
  currentOrganization: UserOrganizationSwitcherPayload;
}

const ConfirmPromoteToAdminLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmPromoteAdmin = ({ profile, handleCloseModal, currentOrganization }: Props) => {
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const { getOrganizationScopeList } = UserViewModel();

  const filteredOrganization = (orgList: FetchOrganizationScopeList) => {
    if (!orgList?.me?.organizations) {
      return null;
    }

    const isAdmin = (org: OrganizationScope) => org.id === currentOrganization?.organization_id;

    const currentOrg = orgList.me.organizations.find(isAdmin);

    if (!currentOrg) {
      return null;
    }

    const adminHashes = profile.scopes
      ? profile.scopes
          .filter((scope) => scope.status === 'admin')
          .map((scope) => generateScopeHash(scope.organizationId, scope.siteId, scope.departmentId))
      : [];

    const filteredSites = currentOrg.sites
      .filter((site) => !adminHashes.includes(generateScopeHash(currentOrg.id, site.id)))
      .map((site) => ({
        ...site,
        departments: site.departments.filter(
          (department) => !adminHashes.includes(generateScopeHash(currentOrg.id, site.id, department.id)),
        ),
      }));

    return {
      ...currentOrg,
      sites: filteredSites,
    };
  };

  const [selectedOrganizationScope, setSelectedOrganizationScope] = React.useState<OrganizationScope>(null);
  const [organizationScopeList, setOrganizationScopeList] = React.useState(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [selectionCount, setTotalSelectionCount] = React.useState<number>(0);

  const { id } = profile;

  const [isFetchOrganizationListLoading, setIsFetchOrganizationListLoading] = React.useState(false);

  // update selected count when selection/deselection happens
  React.useEffect(() => {
    if (selectedOrganizationScope) {
      const isAllSelected = Number(Boolean(selectedOrganizationScope.isAllSelected));

      const counter = selectedOrganizationScope.sites?.reduce((siteAcc, site) => {
        if (site.isAllSelected) return (siteAcc += site.departments.length + 1);
        return site.departments.reduce(
          (depAcc, department) => (depAcc += Number(Boolean(department.isSelected))),
          siteAcc,
        );
      }, isAllSelected);

      setTotalSelectionCount(counter);
    }
  }, [selectedOrganizationScope]);

  React.useEffect(() => {
    const getOrganizationListData = async () => {
      setIsFetchOrganizationListLoading(true);
      const organizationList = await getOrganizationScopeList();
      setOrganizationScopeList(organizationList);

      if (organizationList?.me) {
        const filteredOrganizationList: OrganizationScope = filteredOrganization(organizationList);
        setSelectedOrganizationScope(filteredOrganizationList);
        setIsFetchOrganizationListLoading(false);
      }
    };

    getOrganizationListData();
  }, []);

  if (isFetchOrganizationListLoading) {
    return (
      <ConfirmPromoteToAdminLoadingContainer>
        <CircularProgress color={'secondary'} />
      </ConfirmPromoteToAdminLoadingContainer>
    );
  }

  if (!selectedOrganizationScope) {
    return null;
  }

  // when directly select organization, set selected flag respectively for all child
  const toggleAllScopes = () => {
    const flag = !selectedOrganizationScope.isAllSelected;
    const scopeClone = {
      ...selectedOrganizationScope,
      isAllSelected: flag,
      sites: selectedOrganizationScope.sites?.map((site) => {
        return {
          ...site,
          isAllSelected: flag,
          departments: site.departments.map((department) => ({ ...department, isSelected: flag })),
        };
      }),
    };

    setSelectedOrganizationScope(scopeClone);
  };

  // when directly select/deselect a site, set selected flag to respectively for all its department
  const toggleAllSites = (optionHash: string) => {
    let targetSiteIndex: number;
    let flag: boolean;
    let isAllSiteSelected: boolean = true;

    // scan all sites to check if isAllSelected for organization level
    selectedOrganizationScope.sites?.forEach((site, index) => {
      let currentHash = generateScopeHash(selectedOrganizationScope.id, site.id);
      if (currentHash === optionHash) {
        targetSiteIndex = index;
        flag = !Boolean(site.isAllSelected);
        if (isAllSiteSelected) isAllSiteSelected = flag;
      } else {
        if (!site.isAllSelected) isAllSiteSelected = false;
      }
    });

    const cloneSiteScope = { ...selectedOrganizationScope.sites[targetSiteIndex] };
    cloneSiteScope.departments = cloneSiteScope.departments.map((department) => {
      return {
        ...department,
        isSelected: flag,
      };
    });

    setSelectedOrganizationScope((orgScope) => {
      orgScope.sites[targetSiteIndex] = {
        ...cloneSiteScope,
        isAllSelected: flag,
      };
      return {
        ...orgScope,
        isAllSelected: isAllSiteSelected,
      };
    });
  };

  const toggleDepartment = (optionHash: string) => {
    let targetSiteIndex: number;
    let targetDepartmentIndex: number;
    let flag: boolean;

    selectedOrganizationScope.sites.forEach((site, j) => {
      site.departments.forEach((department, k) => {
        const currentHash = generateScopeHash(selectedOrganizationScope.id, site.id, department.id);
        if (currentHash === optionHash) {
          targetSiteIndex = j;
          targetDepartmentIndex = k;
          flag = !Boolean(department.isSelected);
        }
      });
    });

    setSelectedOrganizationScope((orgScope) => {
      orgScope.sites[targetSiteIndex].departments[targetDepartmentIndex] = {
        ...orgScope.sites[targetSiteIndex].departments[targetDepartmentIndex],
        isSelected: flag,
      };

      // check isAllSelected for target site level
      const isAllDepartmentSelectedAtSite = !Boolean(
        selectedOrganizationScope.sites[targetSiteIndex].departments.find(
          (department) => !Boolean(department.isSelected),
        ),
      );

      orgScope.sites[targetSiteIndex] = {
        ...orgScope.sites[targetSiteIndex],
        isAllSelected: isAllDepartmentSelectedAtSite,
      };

      // scan all sites again to check isAllSelected for organization level
      const isAllSitesSelected = !Boolean(orgScope.sites.find((site) => !site.isAllSelected));

      return {
        ...orgScope,
        isAllSelected: isAllSitesSelected,
      };
    });
  };

  const handlePromoteUser = () => {
    setLoading(true);

    const scopePayload: OrganizationMutationScope[] = [];

    if (selectedOrganizationScope.isAllSelected) {
      scopePayload.push({
        organizationId: selectedOrganizationScope.id,
      });
    } else {
      selectedOrganizationScope.sites?.forEach((site) => {
        if (site.isAllSelected) {
          scopePayload.push({
            organizationId: selectedOrganizationScope.id,
            siteId: site.id,
          });
        } else {
          site.departments.forEach((department) => {
            if (department.isSelected) {
              scopePayload.push({
                organizationId: selectedOrganizationScope.id,
                siteId: site.id,
                departmentId: department.id,
              });
            }
          });
        }
      });
    }

    client
      .mutate({
        mutation: PromoteUserMutation,
        variables: {
          userId: id,
          scopes: scopePayload,
        },
        update: () => {
          updateLocalCache();
        },
      })
      .then(() => setToast(true))
      .catch(() => setToast(false));
  };

  const updateLocalCache = () => {
    const UserProfileQuery: FetchUserProfileAdminResult = client.readQuery({
      query: ldapDirectorySyncFlag ? FETCH_USER_PROFILE_DIR_SYNC : FETCH_USER_PROFILE,
      variables: {
        organizationalUnit: checkOrganizationalUnit(),
        userId: id,
      },
    });

    // all org-sit-dep string that is selected as admin
    const selectedHash = [];
    // org-sit-dep string: scope
    const hashToScope = {};

    if (selectedOrganizationScope.isAllSelected) selectedHash.push(generateScopeHash(selectedOrganizationScope.id));

    selectedOrganizationScope.sites.forEach((site) => {
      if (site.isAllSelected) {
        let siteHash = generateScopeHash(selectedOrganizationScope.id, site.id);
        selectedHash.push(siteHash);
        hashToScope[siteHash] = {
          organizationId: selectedOrganizationScope.id,
          siteId: site.id,
          departmentId: null,
          status: 'admin',
        } as UserScope;
      }
      site.departments.forEach((department) => {
        if (department.isSelected) {
          let hash = generateScopeHash(selectedOrganizationScope.id, site.id, department.id);
          selectedHash.push(hash);
          hashToScope[hash] = {
            organizationId: selectedOrganizationScope.id,
            siteId: site.id,
            departmentId: department.id,
            status: 'admin',
          } as UserScope;
        }
      });
    });

    const { scopes } = UserProfileQuery.data.adminQuery.organizationalUnit.member;
    const newScopes = scopes.map((scope) => {
      let scopeHash = generateScopeHash(scope.organizationId, scope.siteId, scope.departmentId);
      let status = scope.status;
      if (selectedHash.includes(scopeHash)) {
        status = 'admin';
        selectedHash.splice(selectedHash.indexOf(scopeHash), 1);
      }
      return {
        ...scope,
        status,
      };
    });

    // the result of the hashes remaining are the new scopes that user have not join yet
    // backend will add the user if that user is not in the scope
    const newlySelectedAdminScopes: UserScope[] = selectedHash.map((hash) => {
      return {
        ...hashToScope[hash],
        __typename: 'OrganizationScope',
      };
    });

    client.writeQuery({
      query: ldapDirectorySyncFlag ? FETCH_USER_PROFILE_DIR_SYNC : FETCH_USER_PROFILE,
      variables: {
        organizationalUnit: checkOrganizationalUnit(),
        userId: id,
      },
      data: {
        organizationalUnitQuery: {
          ...UserProfileQuery.data.adminQuery,
          member: {
            ...UserProfileQuery.data.adminQuery.organizationalUnit.member,
            scopes: [...newScopes, ...newlySelectedAdminScopes],
          },
        },
      },
    });
  };

  const setToast = (isSuccess: boolean) => {
    setLoading(false);
    if (isSuccess) {
      handleCloseModal();
      toast.success('User has been promoted to admin', {
        className: 'Toast-Container',
      });
    } else {
      toast.error('Error when promoting user to admin', {
        className: 'Toast-Container',
      });
    }
  };

  const handleDeselectAll = async () => {
    setSelectedOrganizationScope(filteredOrganization(organizationScopeList));
  };

  const handleOnSelect = (option: UserOrganizationSwitcherPayload) => {
    const optionHash = generateScopeHash(option.organization_id, option.site_id, option.department_id);
    switch (option.type) {
      case 'organization':
        toggleAllScopes();
        break;
      case 'site':
        toggleAllSites(optionHash);
        break;
      case 'department':
        toggleDepartment(optionHash);
        break;
    }
  };

  return (
    <div className="promoteAdminModal">
      <div className="promoteAdminModal__title">
        Promote {profile.firstname} {profile.lastname} to Admin{' '}
      </div>
      <div
        className={`promoteAdminModal__dropdownWrapper ${isLoading && 'promoteAdminModal__dropdownWrapper--disabled'}`}
      >
        <div className="promoteAdminModal__dropdownWrapper__labelHelper">
          <div>Location</div>
          <div>
            <Chip
              label={`selected: ${selectionCount} ${
                selectedOrganizationScope && selectedOrganizationScope.isAllSelected ? '(All)' : ''
              }`}
              variant="outlined"
            />
          </div>
        </div>
        <OrganizationDropdown
          options={[selectedOrganizationScope]}
          onSelect={handleOnSelect}
          isOpen={true}
          isReadOnly={false}
        />
      </div>
      <div>
        {profile.firstname} {profile.lastname} will have access to...
      </div>
      <div className="promoteAdminModal__buttonWrapper">
        <Button variant="outlined" disableTouchRipple disabled={isLoading} onClick={handleDeselectAll}>
          deselect all
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disableTouchRipple
          disabled={isLoading || selectionCount === 0}
          onClick={handlePromoteUser}
        >
          {isLoading ? 'sending request...' : 'promote to admin'}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    profile: state.userDataReducer,
    currentOrganization: state.organizationReducer,
  };
};

export default connect(mapStateToProps)(ConfirmPromoteAdmin);
