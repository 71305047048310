import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DialogContent, DialogContentText, Divider } from '@material-ui/core';
import { DialogBoxTitle, PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';
import { StyledModal } from 'src/components/modals/ModalStyleComponents';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  logout: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitle: {
      padding: '0px',
    },
    dialogContent: {
      marginTop: '12px',
      padding: '0 !important',
    },
    dialogDescription: {
      fontFamily: 'open sans !important',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px !important',
      lineHeight: '24px !important',
      color: '#222222 !important',
    },
    divider: {
      border: 'none',
      marginTop: '16px !important',
      marginLeft: '-24px',
      marginRight: '-24px',
    },
    button: {
      width: '117px !important',
      padding: '8px 12px !important',
      border: '1px solid #D8D8D8 !important',
      borderRadius: '4px',
    },
    span: {
      fontFamily: 'Nunito Sans !important',
      fontStyle: 'normal !important',
      fontWeight: 600,
      fontSize: '17px !important',
      lineHeight: '24px !important',
      display: 'flex !important',
      alignItems: 'center !important',
    },
  }),
);

const FlexContainer = styled.div`
  display: flex;
  margin-top: 1.5em;
  justify-content: flex-end;
  gap: 8px;
`;

const SessionTimeoutModalWrapper = ({ isOpen, onClose, logout }: Props) => {
  const classes = useStyles();

  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleLogout = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.sessionTimeoutIdleDialogLogout,
    });
    logout();
    window.location.reload();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onRequestClose={onClose}
    >
      <DialogBoxTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {'Session expiring'}
      </DialogBoxTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogDescription} id="alert-dialog-description">
          You will be logged in{' '}
          {minutes === 0 && seconds === 0 ? null : (
            <b>
              {' '}
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </b>
          )}{' '}
          due to inactivity.
          <br />
          To continue your session, click "Continue session" below.
        </DialogContentText>
      </DialogContent>
      <Divider className={classes.divider} />
      <FlexContainer style={{ marginTop: '16px' }}>
        <SecondaryButton onClick={handleLogout}>
          <span className={classes.span}>Logout Now</span>
        </SecondaryButton>
        <PrimaryButton onClick={onClose}>
          <span className={classes.span}>Continue session</span>
        </PrimaryButton>
      </FlexContainer>
    </StyledModal>
  );
};

export const ModalWrapper = React.memo(SessionTimeoutModalWrapper);
