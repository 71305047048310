import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Grid, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { FieldInputLabel } from 'src/components/shared/HypercareComponents';
import ClockIcon from 'src/assets/svgs/ClockIcon';
import styled from 'styled-components';
import {
  getNextOverNightStartDateTime,
  getNextOverNightEndDateTime,
} from 'src/pages/SchedulingPage/scheduling-layout/schedulingHelper';

const useStyles = makeStyles((theme) => ({
  inputAdornment: {
    paddingRight: 0,
    padding: 0,
    display: 'inherit',
    '& .Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  helperText: {
    marginLeft: 0,
    marginRight: 0,
  },
  endInputAdornment: {
    display: 'inherit',
  },
}));

type GridSizes = 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const OvernightDays = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #134c76;
  margin-top: 2px;
  display: contents;
`;

const StyledIconContainer = styled.span`
  margin-top: 5px;
  display: inline-table;
  margin-right: 4px;
`;

const CreatRoleTimePicker = (props) => {
  const {
    prefillStartTime,
    prefillEndTime,
    isAllDayShift,
    setFieldValue,
    startTimeError,
    endTimeError,
    index,
    span = 3,
    isOvernight,
    prefillDate,
    prefillRole,
  } = props;

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null),
    classes = useStyles({});

  function isMinutesHourBefore() {
    const minutesOfDay = (m) => m.minutes() + m.hours() * 60;
    // only comparing amount of mins and hour
    if (prefillEndTime && prefillEndTime.isValid() && prefillStartTime && prefillStartTime.isValid()) {
      return minutesOfDay(prefillEndTime) <= minutesOfDay(prefillStartTime);
    }
    return false;
  }

  const checkNextOverNightDate = (time, ForEndTime = true) => {
    const initailDate = prefillDate.format('DD/MMM/YY');
    const start = prefillRole.startTime;
    const enddate = ForEndTime
      ? getNextOverNightEndDateTime(time, start, initailDate)
      : getNextOverNightStartDateTime(time, start, initailDate);
    const overNightDates = enddate.format('DD/MMM/YY');

    return moment(overNightDates, 'DD/MMM/YY').isSame(initailDate) ? false : overNightDates;
  };

  const startTimeFieldName = typeof index === 'number' ? `assignee.${index}.startTime` : `startTime`;

  const endTimeFieldName = typeof index === 'number' ? `assignee.${index}.endTime` : `endTime`;

  function handleStartDateChange(date: moment.Moment) {
    if (!date?.isValid()) return;
    if (isOvernight) {
      const initailDate = (prefillDate || moment()).format('DD/MMM/YY');
      const start = prefillRole?.startTime || moment();
      const nextDate = getNextOverNightStartDateTime(date, start, initailDate);
      setSelectedStartDate(nextDate);
      setFieldValue(startTimeFieldName, nextDate, true);
    } else {
      setSelectedStartDate(date);
      setFieldValue(startTimeFieldName, date, true);
    }
  }

  function handleEndDateChange(date: moment.Moment) {
    if (!date?.isValid()) return;
    if (isOvernight) {
      const initailDate = (prefillDate || moment()).format('DD/MMM/YY');
      const start = prefillRole?.startTime || moment();
      const nextDate = getNextOverNightEndDateTime(date, start, initailDate);
      setSelectedEndDate(nextDate);
      setFieldValue(endTimeFieldName, nextDate, true);
    } else {
      setSelectedEndDate(date);
      setFieldValue(endTimeFieldName, date, true);
    }
  }

  React.useEffect(() => {
    if (prefillStartTime) {
      setSelectedStartDate(prefillStartTime);
    }
    if (prefillEndTime) {
      setSelectedEndDate(prefillEndTime);
    }
  }, [prefillStartTime, prefillEndTime]);

  // DOC: https://material-ui-pickers.dev/api/KeyboardTimePicker
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid item lg={span as GridSizes} style={{ marginRight: 4 }}>
        <FieldInputLabel>
          {isOvernight && checkNextOverNightDate(selectedStartDate, false) ? 'Start time (+1 Day)' : 'Start time'}
        </FieldInputLabel>
        <KeyboardTimePicker
          id={startTimeFieldName}
          name={startTimeFieldName}
          keyboardIcon={<ClockIcon />}
          minutesStep={5}
          className={classes.inputAdornment}
          inputVariant={'outlined'}
          KeyboardButtonProps={{
            classes: {
              root: classes.inputAdornment,
            },
          }}
          ampm={false}
          placeholder="HH:mm"
          mask="__:__"
          value={selectedStartDate}
          onChange={handleStartDateChange}
          error={Boolean(startTimeError)}
          helperText={startTimeError ? startTimeError : null}
        />
        {isOvernight && checkNextOverNightDate(selectedStartDate, false) && (
          <>
            <OvernightDays> Starts {checkNextOverNightDate(selectedStartDate, false)}</OvernightDays>
          </>
        )}
      </Grid>
      <Grid item lg={span as GridSizes}>
        {isOvernight ? (
          <FieldInputLabel>
            {checkNextOverNightDate(selectedEndDate) ? 'End Time (+1 Day)' : 'End Time'}
          </FieldInputLabel>
        ) : (
          <FieldInputLabel>{isMinutesHourBefore() ? 'End Time (+1 Day)' : 'End Time'}</FieldInputLabel>
        )}
        <KeyboardTimePicker
          disabled={isAllDayShift}
          id={endTimeFieldName}
          name={endTimeFieldName}
          keyboardIcon={<ClockIcon />}
          minutesStep={5}
          className={classes.inputAdornment}
          inputVariant={'outlined'}
          KeyboardButtonProps={{
            classes: {
              root: classes.inputAdornment,
            },
          }}
          ampm={false}
          placeholder="HH:mm"
          mask="__:__"
          disableToolbar
          InputLabelProps={{
            shrink: true,
          }}
          value={isAllDayShift ? null : selectedEndDate}
          onChange={handleEndDateChange}
          error={Boolean(endTimeError)}
          helperText={endTimeError ? endTimeError : null}
        />
        {isOvernight && checkNextOverNightDate(selectedEndDate) && (
          <OvernightDays> Ends {isOvernight ? checkNextOverNightDate(selectedEndDate) : 'next day'}</OvernightDays>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default CreatRoleTimePicker;
