import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ScheduleCreateRoleForm from 'src/components/forms/ScheduleCreateRoleForm';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import client from 'src/clients/apolloClient';
import CreateRoleMutation from 'src/gql/mutation/CreateRoleMutation';
import store, { RootState } from 'src/redux/store';
import clonedeep from 'lodash.clonedeep';
import FetchRolesAndShiftsQuery from 'src/gql/query/FetchRolesAndShiftsQuery';
import UpdateRoleMutation from 'src/gql/mutation/UpdateRoleMutation';
import { toast } from 'react-toastify';
import { FetchRolesAndShiftsResult, Role, RoleResultInfo } from 'src/types';
import allActions from 'src/redux/actions';
import { CANT_MUTATE_PAST_ROLE, CANT_MUTATE_ARCHIVED_ROLE } from 'src/constants/networkError';
import { Transition, DialogTitleTypography } from 'src/components/shared/HypercareComponents';
import CloseMark from 'src/assets/svgs/CloseMark';
import { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import CreateRoleMutationNew from 'src/gql/mutation/CreateRoleMutationNew';
import { IsFeatureFlagEnabled } from '../../utils/FeatureFlagManager';

interface Props {
  roleContainer: Role[];
  handleCloseModal: () => void;
  handleOpenDeleteRoleModal: () => void;
  handleAddRoleNoteModal?: (role) => void;
  showModal: boolean;
  editRoleData: Role | null;
  flags?: { [key: string]: boolean };
}

interface FormValue {
  endTime: moment.Moment;
  startTime: moment.Moment;
  isAllDayShift: boolean;
  repeatRule: 'daily' | 'weekends' | 'weekdays';
  roleName: string;
  isRequired: boolean;
}

const CreateRoleModal = (props: Props) => {
  const [isWaitingDeletion, setIsWaitingDeletion] = useState(false);
  const hiddenRoleNotesFlag = IsFeatureFlagEnabled('hiddenRoleNotes');
  const { showModal, handleCloseModal, editRoleData } = props;
  const isEditState = Boolean(editRoleData);
  const dispatch = useDispatch();

  const getDuration = (startTime: moment.Moment, endTime: moment.Moment, isAllDayShift: boolean) => {
    return isAllDayShift
      ? 86400 // 24 hour in seconds
      : endTime <= startTime
      ? Math.abs((clonedeep(endTime).add(1, 'days') as moment.Moment).diff(startTime as moment.Moment, 'seconds'))
      : (endTime as moment.Moment).diff(startTime as moment.Moment, 'seconds');
  };

  const formatFormValueToRole = (formValue: FormValue): Role => {
    const { endTime, startTime, isAllDayShift } = formValue;
    const { roleContainer, editRoleData } = props;
    const isEditState = Boolean(editRoleData);
    return {
      ...formValue,
      endTime: isAllDayShift
        ? moment(startTime.add(24, 'hours'), 'YYYY-MM-DD HH:mm')
        : moment(endTime, 'YYYY-MM-DD HH:mm'),
      startTime: moment(startTime, 'YYYY-MM-DD HH:mm'),
      required: formValue.isRequired,
      index: isEditState ? editRoleData!.index : roleContainer.length,
      roleId: ``, // init place holder
      duration: 0, // init place holder
      displayPosition: 0,
      updatedAt: moment(),
    };
  };

  const handleCreateRole = (formValue: FormValue) => {
    const { isAllDayShift } = formValue;
    const newRole = formatFormValueToRole(formValue);
    const { startTime, endTime } = newRole;

    const reduxOrganization = store.getState().organizationReducer;
    const duration = getDuration(startTime, endTime, isAllDayShift);

    const { flags } = props;

    const scheduleId = store.getState().monthlyScheduleReducer.scheduleId;

    return new Promise(async (resolve, reject) => {
      try {
        const result = await client.mutate({
          mutation: flags.createRoleMutationNew ? CreateRoleMutationNew : CreateRoleMutation,
          variables: {
            name: newRole.roleName,
            startTime: startTime.utc().format('HH:mm'),
            duration,
            repeatRule: newRole.repeatRule,
            required: newRole.required,
            departmentId: reduxOrganization.department_id,
            scheduleId,
          },
        });

        const newQuery = result.data.admin.locating.department.createRole;
        newQuery.shifts = [];

        const { department_id } = store.getState().organizationReducer;
        const startOfMonthUTC = store.getState().monthlyScheduleReducer.startDateISOstring;
        const endOfMonthUTC = store.getState().monthlyScheduleReducer.endDateISOstring;

        if (hiddenRoleNotesFlag) {
          dispatch(allActions.monthlyScheduleAction.setRoleNoteModalVisibility({ visibility: true }));
          dispatch(
            allActions.monthlyScheduleAction.setCurrentRoleSelected({ roleId: newQuery.id, roleName: newQuery.name }),
          );
          dispatch(
            allActions.monthlyScheduleAction.setAddRoleToRoleNotes({ roleName: newQuery.name, id: newQuery.id }),
          );
        }

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.createRole,
          params: {
            role_id: newRole.roleId,
            department_id: department_id,
            start_date: startOfMonthUTC,
            end_date: endOfMonthUTC,
            is_full_day_shift: isAllDayShift,
            is_required_shift: newRole.required,
            repeat: newRole.repeatRule,
          },
        });
        await client.reFetchObservableQueries();
        resolve('done!');
        dispatchLastUpdatedAt();
        props.handleCloseModal();
        toast.success('Successfully created new role', {
          className: 'Toast-Container',
        });
      } catch (e) {
        console.error(e); // TODO: proper error handling
        if (e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].code === METRICAIDERRORCODE)
          reject(METRICAIDERRORCODE);
        reject('network error');
      }
    });
  };

  const handleUpdateRole = (formValue: FormValue) => {
    const { editRoleData } = props;
    const { roleId } = editRoleData as Role;
    const { endTime, startTime, isAllDayShift, roleName, isRequired, repeatRule } = formValue;

    const { department_id } = store.getState().organizationReducer;
    const duration = getDuration(startTime, endTime, isAllDayShift);

    const startOfMonthUTC = store.getState().monthlyScheduleReducer.startDateISOstring;
    const endOfMonthUTC = store.getState().monthlyScheduleReducer.endDateISOstring;

    return new Promise(async (resolve, reject) => {
      try {
        const result = await client.mutate({
          mutation: UpdateRoleMutation,
          variables: {
            roleId: parseInt(roleId),
            departmentId: department_id,
            updateRoleDetails: {
              name: roleName,
              startTime: startTime.utc().format('HH:mm'),
              duration,
              repeatRule,
              required: isRequired,
            },
            // for update cached shifts
            startDate: startOfMonthUTC,
            endDate: endOfMonthUTC,
          },
        });

        const updatedRoleRest = result?.data?.admin?.locating?.department?.role?.update;

        await client.reFetchObservableQueries();
        resolve('done!');
        dispatchLastUpdatedAt();
        if (hiddenRoleNotesFlag) {
          dispatch(allActions.monthlyScheduleAction.setRoleNoteModalVisibility({ visibility: true }));
          dispatch(
            allActions.monthlyScheduleAction.setCurrentRoleSelected({
              roleId: updatedRoleRest.id,
              roleName: updatedRoleRest.name,
            }),
          );
        }

        props.handleCloseModal();
        toast.success('Successfully updated role information', {
          className: 'Toast-Container',
        });
      } catch (e) {
        console.error(e);
        // TODO: backend needs to resolve with 500, currently still a 200 status thus gqlError
        if (
          e.graphQLErrors &&
          e.graphQLErrors[0] &&
          (e.graphQLErrors[0].code === CANT_MUTATE_PAST_ROLE || e.graphQLErrors[0].code === CANT_MUTATE_ARCHIVED_ROLE)
        ) {
          await client.reFetchObservableQueries();
          toast.error('Unable to update passed or archived role.', {
            className: 'Toast-Container',
          });
          props.handleCloseModal();
        } else if (e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].code === METRICAIDERRORCODE) {
          reject(METRICAIDERRORCODE);
        }
        reject('network error');
      }
    });
  };

  const handleDeleteRole = async () => {
    props.handleOpenDeleteRoleModal();
  };

  const dispatchLastUpdatedAt = () => {
    store.dispatch(allActions.monthlyScheduleAction.setLastUpdateAt({ lastUpdatedAt: moment().toISOString() }));
  };

  return (
    <Dialog maxWidth={'sm'} fullWidth={true} open={showModal} TransitionComponent={Transition}>
      <MuiDialogTitle>
        <DialogTitleTypography>{isEditState ? 'Edit' : 'Creating New'} Role</DialogTitleTypography>
        <IconButton aria-label="close" onClick={handleCloseModal} style={{ position: 'absolute', top: 16, right: 16 }}>
          <CloseMark />
        </IconButton>
      </MuiDialogTitle>
      <ScheduleCreateRoleForm
        isWaitingDeletion={isWaitingDeletion}
        handleCreateRole={handleCreateRole}
        isEditState={isEditState}
        handleUpdateRole={handleUpdateRole}
        handleDeleteRole={handleDeleteRole}
        closeModal={handleCloseModal}
        prefillData={
          !isEditState
            ? null
            : ({
                endTime: editRoleData!.endTime,
                startTime: editRoleData!.startTime,
                isAllDayShift: editRoleData!.duration === 86400,
                repeatRule: editRoleData!.repeatRule,
                roleName: editRoleData!.roleName,
                isRequired: editRoleData!.required,
              } as FormValue)
        }
      />
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    roleContainer: state.monthlyScheduleReducer.roleContainer,
  };
};

export default connect(mapStateToProps)(withLDConsumer()(CreateRoleModal));
