import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <ContentLoader
    height={800}
    style={{
      height: '100%',
      maxHeight: '800px',
      width: '100%',
    }}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="20" rx="5" ry="5" width="100%" height="800" />
  </ContentLoader>
);
