import React from 'react';
import styled from 'styled-components';
import { FetchRolesAndShiftsResult } from 'src/types';
import { ApolloQueryResult } from 'apollo-boost';
import store from 'src/redux/store';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import clonedeep from 'lodash.clonedeep';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';
import { AdminFetchScheduleResponse } from '../../../../gql/query/AdminFetchSchedule';

const DateSwitcherWrapper = styled.div`
  user-select: none;
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiInputBase-root {
    width: 227px;
    border-radius: 0.5em;
    transition: box-shadow 0.2s ease-out;
    &:hover {
      box-shadow: lightgrey 0 0 0 0.1rem;
    }
  }
  input {
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
  }
  .MuiInput-underline {
    //disable underline effect
    &::before,
    &::after {
      content: none;
    }
  }
`;

const MaterialIconHolder = styled.div`
  cursor: pointer;
  padding: 8px;
  .material-icons {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    .material-icons {
      transform: scale(1.5);
    }
  }
`;

interface Props {
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<AdminFetchScheduleResponse>>;
  startDate: moment.Moment;
}

const SubHeaderMonthSwitcher = ({ refetch, startDate }: Props) => {
  const dispatch = useDispatch();

  // adding 2 days to avoid utc timezone affect and shift month to previous month
  function refetchByDate(startDate: moment.Moment) {
    const startOfMonthUTC = moment(startDate.add(2, 'days').format()).startOf('month').toISOString();
    const endOfMonthUTC = moment(startDate.add(2, 'days').format()).endOf('month').millisecond(0o00).toISOString();
    const reduxOrganization = store.getState().organizationReducer;

    dispatch(
      allActions.monthlyScheduleAction.setScheduleRefetchDate({
        startDate: startOfMonthUTC,
        endDate: endOfMonthUTC,
      }),
    );
    dispatch(allActions.monthlyScheduleAction.setClearRoleNotes({}));

    refetch({
      departmentId: reduxOrganization.department_id,
      month: moment(startOfMonthUTC).format('MMMM').toLowerCase(),
      year: moment(startOfMonthUTC).year(),
      shiftStartDate: startOfMonthUTC,
      shiftEndDate: endOfMonthUTC,
    });
  }

  function handleDateChange(date: moment.Moment) {
    refetchByDate(date);
  }

  function handleForwardMonth() {
    const forwardMonthStartDate = startDate.clone().add(1, 'M');
    refetchByDate(forwardMonthStartDate);
  }

  function handleBackwardMonth() {
    const backwardMonthStartDate = startDate.clone().subtract(1, 'M');
    refetchByDate(backwardMonthStartDate);
  }

  return (
    <DateSwitcherWrapper>
      <MaterialIconHolder onClick={handleBackwardMonth}>
        <i className="material-icons">keyboard_arrow_left</i>
      </MaterialIconHolder>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          variant="dialog"
          openTo="month"
          views={['year', 'month']}
          value={clonedeep(startDate).add(2, 'days')}
          // @ts-ignore
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      <MaterialIconHolder onClick={handleForwardMonth}>
        <i className="material-icons">keyboard_arrow_right</i>
      </MaterialIconHolder>
    </DateSwitcherWrapper>
  );
};

export default SubHeaderMonthSwitcher;
