import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Assignee, MonthlyCalendar, ScheduleGapInfo } from 'src/types';

const moment = extendMoment(Moment);

function sortByStartTime(shiftA: Assignee, shiftB: Assignee) {
  if (shiftA.startTime.isBefore(shiftB.startTime)) return -1;
  if (shiftA.startTime.isAfter(shiftB.startTime)) return 1;
  return 0;
}

function isEndTimeMinutesBeforeStartTime(startTime: moment.Moment, endTime: moment.Moment) {
  const minutesOfDay = (m: moment.Moment) => m.minutes() + m.hours() * 60;
  // only comparing amount of mins and hour
  if (endTime.isValid() && startTime.isValid()) {
    return minutesOfDay(endTime) <= minutesOfDay(startTime);
  }
  return false;
}

function identifyGapsRanges(monthlyCalendarSchedule: MonthlyCalendar) {
  let isGapOccurredInRequiredRole: boolean = false;

  Object.keys(monthlyCalendarSchedule).forEach((key) => {
    let calendarShifts = monthlyCalendarSchedule[key];

    calendarShifts.forEach((monthlyCalendarShift) => {
      const { role, assignee } = monthlyCalendarShift;
      // sort startTime is a must for both ui display and data traversal
      assignee.sort(sortByStartTime);
      // assigned to utc string to current iteration of calendar day (key)
      const roleStartTime = moment(moment(`${key} ${role.startTime.format('HH:mm')}`).toISOString());
      const roleEndTime = moment(moment(`${key} ${role.endTime.format('HH:mm')}`).toISOString());
      // since schedule vm is splitted, cross-day shifts will be divided at xx-23:59 (.endOf('day')) or 00:00-xx
      // there can be two role ref in one day e.g. 8:00 - 3:00 is 00:00 - 03:00 and 8:00 - 23:59
      const isCrossDay = isEndTimeMinutesBeforeStartTime(role.startTime, role.endTime);
      let roleRangeStart1: moment.Moment,
        roleRangeStart2: moment.Moment,
        roleRangeEnd1: moment.Moment,
        roleRangeEnd2: moment.Moment;
      // by nature of split, start2 end2 will always be later/greater than start1 end1
      if (isCrossDay) {
        roleRangeStart1 = moment(moment(`${key} 00:00`).toISOString());
        roleRangeEnd1 = roleEndTime.clone();
        roleRangeStart2 = roleStartTime.clone();
        roleRangeEnd2 = roleEndTime.clone().endOf('day');
      } else {
        roleRangeStart1 = roleStartTime.clone();
        roleRangeEnd1 = roleEndTime.clone();
      }

      const gaps: ScheduleGapInfo[] = [];
      const isCalendarDateWeekends = roleStartTime.weekday() === 6 || roleEndTime.weekday() === 0;

      const shouldRender =
        (!isCalendarDateWeekends && role.repeatRule === 'weekdays') ||
        (isCalendarDateWeekends && role.repeatRule === 'weekends') ||
        role.repeatRule === 'daily';

      const isRequired = role.required && shouldRender;

      // when no shifts, all slots are gap
      if (assignee.length === 0) {
        if (isCrossDay) {
          gaps.push(
            {
              startTime: roleRangeStart1,
              endTime: roleRangeEnd1,
              shouldRender,
              isRequired,
            },
            {
              startTime: roleRangeStart2,
              endTime: roleRangeEnd2,
              shouldRender,
              isRequired,
            },
          );
        } else {
          gaps.push({
            startTime: roleRangeStart1,
            endTime: roleRangeEnd1,
            shouldRender,
            isRequired,
          });
        }
        monthlyCalendarShift.scheduleGap = gaps;
      } else {
        // else iterate through
        assignee.forEach((shift, i) => {
          let roleRange1 = moment.range(roleRangeStart1, roleRangeEnd1);
          let roleRange2 = moment.range(roleRangeStart2, roleRangeEnd2);
          let beginningOfGap = assignee[i - 1] ? assignee[i - 1].endTime : roleRange1.start;
          let endOfGap = assignee[i - 1]
            ? shift.startTime > roleRange1.end
              ? roleRange1.end
              : shift.startTime
            : isCrossDay && roleRange1.end < shift.startTime
            ? roleRange1.end
            : shift.startTime;

          let gapOfChoice = moment.range(beginningOfGap, endOfGap);
          // if last end is smaller that current start and its inside the range
          if (beginningOfGap < endOfGap && roleRange1.intersect(gapOfChoice)) {
            gaps.push({
              startTime: beginningOfGap,
              endTime: endOfGap,
              shouldRender,
              isRequired,
            });
          }

          // check again for second range if its across two day
          if (isCrossDay) {
            let beginningOfGap = assignee[i - 1] ? assignee[i - 1].endTime : roleRange2.start;
            let endOfGap = assignee[i - 1]
              ? shift.startTime > roleRange2.end
                ? roleRange2.end
                : shift.startTime
              : shift.startTime;

            let gapOfChoice = moment.range(beginningOfGap, endOfGap);

            if (beginningOfGap < endOfGap && roleRange2.intersect(gapOfChoice)) {
              gaps.push({
                startTime: beginningOfGap,
                endTime: endOfGap,
                shouldRender,
                isRequired,
              });
            }
          }

          // when last start-end sorted check is over, add one more check to last endTime
          if (i === assignee.length - 1) {
            let lastRoleBound = isCrossDay ? roleRange2.end : roleRange1.end;
            let largestEndTimeShift = [...assignee].reduce((a, b) => (a.endTime > b.endTime ? a : b));

            if (largestEndTimeShift.endTime < lastRoleBound) {
              gaps.push({
                startTime: largestEndTimeShift.endTime,
                endTime: lastRoleBound,
                shouldRender,
                isRequired,
              });
            }
          }
        });
      } // end of assignee loop
      const isRequiredOccurred = gaps.find((gap) => gap.isRequired);
      if (isRequiredOccurred) isGapOccurredInRequiredRole = true;

      monthlyCalendarShift.scheduleGap = gaps;
    }); // end of calendar loop
  });

  return isGapOccurredInRequiredRole;
}

export default identifyGapsRanges;
