import React from 'react';
import EscalationLadderListContainer from 'src/pages/EscalationPage/EscalationLadderListContainer';
import EscalationLayout from 'src/pages/EscalationPage/EscalationLayout';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import AppTheme from 'src/assets/styles/theme';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import FetchEscalationLadderForDepartment from 'src/gql/query/FetchEscalationLadderForDepartment';
import { FetchLadderForDepartmentResult } from 'src/types';
import store from 'src/redux/store';
import { toast } from 'react-toastify';
import { CREATE } from 'src/constants/escalation';

const escalationPageTheme = createMuiTheme({
  palette: {
    primary: { main: AppTheme.mainTealColor },
    secondary: { main: AppTheme.mainButtonColor },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: 'white',
        backgroundColor: AppTheme.mainTealColor,
      },
      arrow: {
        color: AppTheme.mainTealColor,
      },
    },
  },
});

const StyledPageContentWrapper = styled.div`
  min-width: '968px';
`;

const EscalationLandingViewWrapper = styled.div`
  position: relative;
  padding: 1em 3%;
  height: 100%;
`;

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const EscalationPage = () => {
  let { escalationLadderId } = useParams<{ escalationLadderId: string | 'create' }>();

  const { department_id } = store.getState().organizationReducer;

  const canSkipQuery = !escalationLadderId || escalationLadderId === 'create';

  const { data: escalationData, error } = useQuery<FetchLadderForDepartmentResult>(FetchEscalationLadderForDepartment, {
    variables: {
      departmentId: department_id,
      escalationLadderId: escalationLadderId,
    },
    fetchPolicy: 'cache-and-network',
    skip: canSkipQuery,
  });

  if (error) {
    toast.error('Failed to fetch escalation ladder, please check your internet connection try again');
  }

  return (
    <StyledPageContentWrapper>
      <ThemeProvider theme={escalationPageTheme}>
        {!!escalationLadderId ? (
          escalationData || escalationLadderId === CREATE ? (
            <EscalationLayout
              escalationLadderId={escalationLadderId}
              fetchedEscalationLadder={escalationData?.locating?.department?.escalationLadder}
            />
          ) : (
            <StyledLoader />
          )
        ) : (
          <EscalationLandingViewWrapper>
            <EscalationLadderListContainer />
          </EscalationLandingViewWrapper>
        )}
      </ThemeProvider>
    </StyledPageContentWrapper>
  );
};

export default EscalationPage;
