import React from 'react';
import moment from 'moment';
import client from 'src/clients/apolloClient';
import store from 'src/redux/store';
import DeleteRoleMutation from 'src/gql/mutation/DeleteRoleMutation';
import { toast } from 'react-toastify';
import { Role } from 'src/types';
import sleep from 'src/utils/sleep';
import allActions from 'src/redux/actions';
import { CANT_MUTATE_PAST_ROLE, CANT_MUTATE_ARCHIVED_ROLE } from 'src/constants/networkError';
import {
  Transition,
  DialogTitleTypography,
  StyledDialogActions,
  PrimaryButton,
  SecondaryButton,
} from 'src/components/shared/HypercareComponents';
import { Dialog, DialogContent, IconButton, Divider, ThemeProvider, createMuiTheme } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseMark from 'src/assets/svgs/CloseMark';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import {
  CONTINUE_EDITING,
  DELETE_CURRENT_OR_FUTURE_ROLE_MODAL_DESCRIPTION_TEXT,
  DELETE_PAST_ROLE_MODAL_DESCRIPTION_TEXT,
  DELETE_ROLE,
  SCHEDULE_DELETE_ROLE_MODAL_TITLE,
  SENDING_REQUEST,
} from 'src/constants/strings';

interface Props {
  handleCloseModal: () => void;
  handleCloseParentModal: () => void;
  showModal: boolean;
  editRoleData: Role | null;
}

const ScheduleDeleteRoleModal = ({ editRoleData, showModal, handleCloseParentModal, handleCloseModal }: Props) => {
  const [isLoading, setLoading] = React.useState(false);

  const startDateISOString = store.getState().monthlyScheduleReducer.startDateISOstring;
  const currentScheduleYear = moment(startDateISOString).year();

  const roleTimeMoment = moment(editRoleData.startTime.toISOString());
  const currentMonthMoment = moment(startDateISOString);

  //compared both month & year for roleTimeMoment and currentMonthMoment
  const isCurrentMonthScheduleBeforeSelectedRoleStartTime =
    currentMonthMoment.year() > roleTimeMoment.year() ||
    (currentMonthMoment.year() === roleTimeMoment.year() && currentMonthMoment.month() >= roleTimeMoment.month());

  const dispatchLastUpdatedAt = () => {
    store.dispatch(allActions.monthlyScheduleAction.setLastUpdateAt({ lastUpdatedAt: moment().toISOString() }));
  };

  // TODO: deletion performance, refetch roles and shifts only
  const handleDeleteRole = async () => {
    const { roleId } = editRoleData as Role;
    const { department_id } = store.getState().organizationReducer;
    try {
      setLoading(true);
      // note: all shifts will be deleted under that role
      await client.mutate({
        mutation: DeleteRoleMutation,
        variables: {
          roleId: parseInt(roleId),
          departmentId: department_id,
        },
      });
      await sleep(1000); // backend input delay, not ideal solution, delete role is the slowest
      await client.reFetchObservableQueries();
      setLoading(false);

      dispatchLastUpdatedAt();
      handleCloseModal();
      handleCloseParentModal();

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.deleteRole,
        params: {
          role_id: roleId,
        },
      });

      toast.success('Successfully deleted the role from schedule', {
        className: 'Toast-Container',
      });
    } catch (e) {
      console.error(e);
      // TODO: backend needs to resolve with 500, currently still a 200 status thus gqlError
      if (
        e.graphQLErrors &&
        e.graphQLErrors[0] &&
        (e.graphQLErrors[0].code === CANT_MUTATE_PAST_ROLE || e.graphQLErrors[0].code === CANT_MUTATE_ARCHIVED_ROLE)
      ) {
        await client.reFetchObservableQueries();
        toast.error('Unable to delete passed or archived role.', {
          className: 'Toast-Container',
        });
      } else {
        toast.error('Error when delete role, please try again', {
          className: 'Toast-Container',
        });
      }
      setLoading(false);
    }
  };

  const DeleteRoleModalConfirmationTheme = createMuiTheme({
    typography: {
      fontFamily: 'Open Sans',
    },
  });

  return (
    <ThemeProvider theme={DeleteRoleModalConfirmationTheme}>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={showModal}
        onClose={() => handleCloseModal()}
        TransitionComponent={Transition}
      >
        <MuiDialogTitle>
          <DialogTitleTypography>{SCHEDULE_DELETE_ROLE_MODAL_TITLE(editRoleData.roleName)}</DialogTitleTypography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            style={{ position: 'absolute', top: 16, right: 16 }}
          >
            <CloseMark />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>
          {isCurrentMonthScheduleBeforeSelectedRoleStartTime
            ? DELETE_CURRENT_OR_FUTURE_ROLE_MODAL_DESCRIPTION_TEXT(
                moment(startDateISOString).format('MMMM'),
                currentScheduleYear,
              )
            : DELETE_PAST_ROLE_MODAL_DESCRIPTION_TEXT(moment(startDateISOString).format('MMMM'), currentScheduleYear)}
        </DialogContent>
        <Divider />
        <StyledDialogActions>
          <SecondaryButton type="submit" disabled={isLoading} onClick={handleCloseModal}>
            {CONTINUE_EDITING}
          </SecondaryButton>
          <PrimaryButton type="submit" onClick={handleDeleteRole} disabled={isLoading}>
            {isLoading ? SENDING_REQUEST : DELETE_ROLE}
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ScheduleDeleteRoleModal;
