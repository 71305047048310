import gql from 'graphql-tag';
import ShiftFragment from 'src/gql/fragment/ShiftFragment';

export const roleFragmentStringWithoutShifts = `
  id
  name
  auditId
  startTime
  endTime
  duration
  repeatRule
  pagerNumber
  required
  displayPosition
  createdAt
  updatedAt 
  currentShift {
    ...ShiftFragment
  }
  nextShift {
    ...ShiftFragment
  }
  __typename
`;

export default gql`
  fragment RoleFragment on Role {
    ${roleFragmentStringWithoutShifts}
    shifts(startDate: $startDate, endDate: $endDate) {
      ...ShiftFragment
    }
  }
  ${ShiftFragment}
`;
