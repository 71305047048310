import React from 'react';
import { connect } from 'react-redux';
import { Mutation, MutationFunction } from 'react-apollo';
import { GET_USERS_WITH_LICENSE } from 'src/gql/query/GetUsersQuery';
import { REMOVE_USER } from 'src/gql/mutation/RemoveUserMutation';
import { toast } from 'react-toastify';
import { getSelfInfo } from 'src/utils/getLocalAuth';
import Button from '@material-ui/core/Button';
import { AuthContext } from 'src/auth';
import { GetAllLicensingUsersResult, User, UserOrganizationSwitcherPayload } from 'src/types';
import { RootState } from 'src/redux/store';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { AppRoutes } from '../../../router/AppRoutes';

const RemoveUserStatement = ({
  firstname,
  currentOrganization,
}: {
  firstname: string;
  currentOrganization: UserOrganizationSwitcherPayload;
}) => {
  return (
    <div className="modal__confirmation__statement">
      Remove <span>{firstname}</span> from {currentOrganization.name || 'your organization'}?
      <br />
      <br />
      {currentOrganization.type === 'organization' && (
        <small>
          They will lose access to all chats and contacts within {currentOrganization.name || 'your organization'}. They
          only be able to be added back by an administrator of the organization.
        </small>
      )}
      {currentOrganization.type === 'site' && (
        <small>
          They will stay part of the organization but won't be part of the {currentOrganization.name || ''} site.
        </small>
      )}
      {currentOrganization.type === 'department' && (
        <small>
          They will stay part of the organization but won't be part of the {currentOrganization.name || ''} department.
        </small>
      )}
    </div>
  );
};

const RemoveUserButtons = ({
  closeHandler,
  removeHandler,
  isConfirm,
}: {
  closeHandler: () => void;
  removeHandler: () => void;
  isConfirm: boolean;
}) => {
  return (
    <div className="modal__confirmation__buttons">
      <Button disableTouchRipple variant="outlined" onClick={closeHandler}>
        Cancel
      </Button>
      <Button disableTouchRipple disabled={isConfirm} variant="contained" color="secondary" onClick={removeHandler}>
        Confirm
      </Button>
    </div>
  );
};

interface Props {
  profile: User;
  handleCloseModal: () => void;
  logout: () => void;
  currentOrganization: UserOrganizationSwitcherPayload;
}

const ConfirmRemoveUser = ({ profile, handleCloseModal, currentOrganization, logout }: Props) => {
  const { id, firstname, username } = profile;
  const [isConfirm, setIsConfirm] = React.useState(false);

  const handleUserRemoval = (removeUser: MutationFunction) => {
    setIsConfirm(true);
    const selfId = getSelfInfo().id;
    removeUser({
      variables: {
        userId: id,
        scope: {
          organizationId: currentOrganization.organization_id,
          siteId: currentOrganization.site_id,
          departmentId: currentOrganization.department_id,
        },
      },
    })
      .then(() => {
        if (selfId === id) {
          logout();
          successRemoveSelf();
        } else {
          successRemoveUser();
        }
      })
      .catch(() => {
        errorRemoveUser();
      });
  };

  const successRemoveSelf = () => {
    toast.success('You have removed yourself from the organization, please login again', {
      autoClose: false,
      className: 'Toast-Container',
    });
  };

  const successRemoveUser = () => {
    handleCloseModal();
    toast.success('User has been removed.', {
      className: 'Toast-Container',
    });
    window.routerHistory.push(AppRoutes.Home);
  };

  const errorRemoveUser = () => {
    toast.error('Error when removing member.', {
      className: 'Toast-Container',
    });
  };

  return (
    <Mutation
      mutation={REMOVE_USER}
      update={(cache, { data }) => {
        const query: GetAllLicensingUsersResult = cache.readQuery({ query: GET_USERS_WITH_LICENSE });
        const { users, pendingUsers, blacklistedUsers } = query.admin;
        const targetUser = users.find((user) => user.username === username);
        const updatedUserList = users.filter((user) => user.username !== username);
        cache.writeQuery({
          query: GET_USERS_WITH_LICENSE,
          data: {
            admin: {
              users: updatedUserList,
              pendingUsers,
              blacklistedUsers: [...blacklistedUsers, targetUser],
              __typename: 'AdminQuery',
            },
          },
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.removeMember,
          params: {
            user_id: id,
          },
        });
      }}
    >
      {(removeUser: MutationFunction) => {
        return (
          <React.Fragment>
            <RemoveUserStatement firstname={firstname} currentOrganization={currentOrganization} />
            <RemoveUserButtons
              closeHandler={handleCloseModal}
              removeHandler={() => handleUserRemoval(removeUser)}
              isConfirm={isConfirm}
            />
          </React.Fragment>
        );
      }}
    </Mutation>
  );
};

const getProfileData = (state, ownProps) => {
  const { profile } = ownProps;

  if (profile) {
    return profile;
  } else {
    return state.userDataReducer;
  }
};

const mapStateToProps = (state: RootState, ownProps) => {
  return {
    currentOrganization: state.organizationReducer,
    profile: getProfileData(state, ownProps),
  };
};

const ConnectedRemoveUserModal = connect(mapStateToProps)(ConfirmRemoveUser);

export default (props) => (
  <AuthContext.Consumer>{({ logout }) => <ConnectedRemoveUserModal {...props} logout={logout} />}</AuthContext.Consumer>
);
