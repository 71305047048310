import gql from 'graphql-tag';
import { ProfileFieldFragment, ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type FetchUserProfileCustomFieldsQueryResult = {
  adminQuery: {
    organizationalUnit: {
      member: {
        id: string;
        customFields: Array<{
          id: string;
          sectionId: string;
          label: string;
          labelOptions: string[];
          maxFields: number;
          sections: Array<{ id: string; label: string }>;
          fields: Array<ProfileFieldFragmentType>;
          __typename: string;
        }>;
      };
    };
  };
};

export const FETCH_PROFILE_FIELDS_FOR_USER = gql`
  query FetchProfileFieldsForUser($organizationalUnit: OrganizationalUnitInput!, $userId: ID!) {
    adminQuery {
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            member(id: $userId) {
              ... on FullOrganizationMember {
                id
                customFields {
                  id
                  sectionId
                  label
                  labelOptions
                  maxFields
                  sections {
                    id
                    label
                  }
                  fields {
                    ... on Node {
                      id
                    }
                    ...ProfileFieldFragment
                    __typename
                  }
                  __typename
                }
              }
            }
          }
          ... on AdminDepartmentQuery {
            member(id: $userId) {
              ... on FullOrganizationMember {
                id
                customFields {
                  id
                  sectionId
                  label
                  labelOptions
                  maxFields
                  sections {
                    id
                    label
                  }
                  fields {
                    ... on Node {
                      id
                    }
                    ...ProfileFieldFragment
                    __typename
                  }
                  __typename
                }
              }
            }
          }
          ... on AdminSiteQuery {
            member(id: $userId) {
              ... on FullOrganizationMember {
                id
                customFields {
                  id
                  sectionId
                  label
                  labelOptions
                  maxFields
                  sections {
                    id
                    label
                  }
                  fields {
                    ... on Node {
                      id
                    }
                    ...ProfileFieldFragment
                    __typename
                  }
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
  ${ProfileFieldFragment}
`;
