import styled from 'styled-components';

interface CustomStyledAttributes {
  height: string;
  width: string;
}
export const StyledLink = styled.a`
  color: #00859a;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledToolTipContainer = styled.div.attrs((props: CustomStyledAttributes) => ({
  ...props,
}))`
  text-align: left;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '337px')};
  padding: 14px;
  gap: 4px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 18px 28px 0px rgba(9, 30, 66, 0.15);
`;
