import { VirtualPagerStatusTypes, AlertTypes, AudioModalType } from '../types/VirtualPagers';

// REUSED STRINGS
export const VIRTUAL_PAGERS = 'Virtual Pagers';
export const LEARN_MORE = 'Learn more';
export const DONE = 'Done';

export const SEARCH_VIEW_HEADER = 'Get numbers';
export const CLEAR_FILTERS = 'Clear Filters';
export const SEARCH = 'Search';
export const CANCEL = 'Cancel';
export const PURCHASE = 'Purchase';
export const REMOVE = 'Remove';
export const OPTIONAL = 'Optional';

// BUTTON STRINGS
export const GET_NUMBERS = 'Get Numbers';
export const WATCH_VIDEO = 'Watch Video';
export const VISIT_SUPPORT_CENTER = ' Visit support center';

// NO NUMBERS VIEW STRINGS
export const WHITEBOX_1_TITLE = 'See how this works';
export const WHITEBOX_1_DESCRIPTION =
  'Callers leave a voicemail according to instructions from your customizable, pre-recorded voice message. Accurate call-back info sent directly from the patient to provider.';
export const WHITEBOX_2_TITLE = 'Get a virtual number in just minutes';
export const WHITEBOX_2_DESCRIPTION =
  'Instantly find and provision local phone numbers tailored to your organization needs.';
export const WHITEBOX_3_TITLE = 'Learn more about Hypercare’s virtual pager';
export const WHITEBOX_3_DESCRIPTION =
  'Visit our support center to understand the end-to-end functionality of the Hypercare virtual paging system.';
export const TEALBLOX_TITLE = 'Replace your physical pager with Hypercare’s virtual pager!';
export const TEALBLOX_DESCRIPTION =
  'Voicemails are automatically forwarded to the on-call provider, eliminating communication errors and delays in patient care. ';

export const NEW = 'NEW';
export const GET_STARTED = 'Get Started';

//NUMBER LIST VIEW STRING
export const PAGER_PAGE_HEADER_DESCRIPTION =
  'Virtual pager numbers are connected to individual provider(s) or call schedules and function like a traditional alphanumeric pager, at just a fraction of the price.';

export const ALL = 'All';
export const ACTIVE = 'Active';
export const PENDING_CONFIGURATION = 'Pending configuration';
export const DISABLED = 'Disabled';

export const VirtualPagerStatusMap = {
  [VirtualPagerStatusTypes.PENDING]: 'Pending Configuration',
  [VirtualPagerStatusTypes.ACTIVE]: 'Active',
  [VirtualPagerStatusTypes.DISABLED]: 'Disabled',
};

export const AlertTypeMap = {
  [AlertTypes.REGULAR]: 'Regular',
  [AlertTypes.URGENT]: 'Urgent',
  [AlertTypes.STAT]: 'STAT',
};

export const NUMBER = 'Number';
export const ASSIGNEE = 'Assignee';
export const DISPLAY_NAME = 'Display name';
export const STATUS = 'Status';
export const ASSIGNED_TO = 'Assigned to';
export const ALERT = 'Alert';
export const VOICEMAIL = 'Voicemail?';
export const CREATED = 'Created';
export const REGION = 'Region';
export const LAST_PAGED = 'Last Paged';
export const CONFIGURE = 'Configure';
export const REACTIVATE = 'Reactivate';
export const RECOMMENDED = 'Recommended';
export const CLOSE = 'Close';
export const SAVED = 'Saved';
export const SAVE = 'Save';

export const GO_TO_CONFIGURE = 'Go to configure';

//MODAL STRINGS
export const NUMBER_CELL_PURCHASING = 'Purchasing number...';
export const NUMBER_CELL_PURCHASE_FAILED = 'Purchase failed';
export const NUMBER_CELL_PURCHASE_SUCCESS = 'Success';

export const SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_1 = 'You can only purchase up to 20 numbers at a time.';
export const SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_2 = ` To avoid long-distance premium charges, try selecting numbers that are within your desired local area. If
you’re only looking for toll-free numbers, please `;
export const SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_3 = 'contact Hypercare Support';
export const SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_4 = ' for further assistance.';

export const CONFIRM_PURCHASES = 'Confirm Purchases';
export const PURCHASING = 'Purchasing...';
export const PROCESSING = 'Processing...';
export const PURCHASE_SUCCESS = 'The following numbers have been successfully purchased.';
export const PURCHASE_FAIL =
  'The following numbers are no longer available, please try again. You have not been charged for these numbers.';

export const CONFIRM_PURCHASE_TEXT_PART_1 = 'By clicking ';
export const CONFIRM_PURCHASE_TEXT_PART_2 = PURCHASE;
export const CONFIRM_PURCHASE_TEXT_PART_3 =
  ', you agree to pay for the cost of any successfully activated numbers. Your billing administrators will either receive an invoice or be automatically billed. Failure to pay may result in cancellation of your services.';

export const PAGER_PURCHASE_PROCESSING_COMPLETE = 'Processing completed';

export const NO_NUMBERS_FOUND = 'No numbers found.';
export const MODIFY_FILTER = 'Please modify the filters and try again. ';
export const TOLL_FREE_NUMBER_PART_1 = 'If you’re only looking for toll-free numbers, please ';
export const CONTACT_HYPERCARE_SUPPORT = 'contact Hypercare Support';
export const TOLL_FREE_NUMBER_PART_2 = ' for further assistance.';

//Countries
export const COUNTRY = 'Country';
export const countriesMapping = {
  US: 'United States (+1)',
  CA: 'Canada (+1)',
  // PAK: 'Pakistan (+92)',
  // IRL: 'Ireland (+353)',
  // ESP: 'Spain (+34)',
};

export const SEARCH_AVAIlABLE_NUMBERS_ONLY_NUMERIC_ALLOWED_ERROR = 'Only numeric values allowed.';
export const SEARCH_AVAIlABLE_NUMBERS_HEADER = 'Starting with';

export const alertMapping = (input: string) => {
  const mapping = {
    urgent: 'Urgent',
    regular: 'Regular',
    stat: 'STAT',
  };
  return mapping[input] || null;
};

export const PopUpMappingHeading = (input: string) => {
  const mapping = {
    'pending configuration': 'Configure voice & alert preferences',
    active: 'Number is in use',
    disabled: 'Reactivate number to resume activities',
  };

  return mapping[input] || null;
};

export const PopUpDescriptionMapping = (input: VirtualPagerStatusTypes) => {
  const mapping = {
    [VirtualPagerStatusTypes.PENDING]:
      'To start using this number, please set up voice & messaging preferences and assign it to at least 1 user or on-call role.',
    [VirtualPagerStatusTypes.ACTIVE]:
      'This number is able to receive pages and take call back numbers and/or voicemails.',
    [VirtualPagerStatusTypes.DISABLED]: 'This number was disabled and is currently out of service.',
  };

  return mapping[input] || null;
};

export const PopUpDescriptionMappingConfigurePage = (input: VirtualPagerStatusTypes) => {
  const mapping = {
    [VirtualPagerStatusTypes.PENDING]:
      'To start using this number, please set up voice & messaging preferences and assign it to at least 1 user or on-call role.',
    [VirtualPagerStatusTypes.ACTIVE]:
      'This number can now be in use. We recommend testing your numbers first by calling them internally to make sure the audio and messages come through properly.',
    [VirtualPagerStatusTypes.DISABLED]: 'This number was disabled and is currently out of service.',
  };

  return mapping[input] || null;
};

// Tooltip
export const ALERT_HEADER_TOOLTIP = 'How incoming pages are delivered to the assignee’s Hypercare messaging app';
export const VOICEMAIL_HEADER_TOOLTIP = 'Allow callers to leave a voicemail detailing reasons for calling';
export const ASSIGNE_TO_HEADER_TOOLTIP = 'Numbers can be assigned to multiple users or on-call roles';

//? Entry Page
export const NO_SEARCH_RESULT_FOUND = 'No results found';
export const MODIFY_SEARCH_RESULT = 'Click on another tab or modify the search criteria and try again';
export const SEARCH_TYPE_DROPDOWN_HEADER = 'Search by';
export const getAssigneeSummary = (assigneeList: string[]) => {
  switch (assigneeList.length) {
    case 0:
      return '';
    case 1:
      return assigneeList[0];
    default:
      return `${assigneeList[0]} and ${assigneeList.length - 1} more`;
  }
};

//? Configure Page
// Accordions
export const UNSAVED_CHANGES_WARNING = 'Changes are unsaved or missing fields';
// Headers
export const ACCORDION_DISPLAY_NAME_HEADER = '1. Set display name';
export const ACCORDION_ASSIGNEE_HEADER = '2. Assign number to';
export const ACCORDION_ALERT_PREFERENCE_HEADER = '3. Alerts & Notification';
export const ACCORDION_VOICEMAIL_HEADER = '4. Audio handling';
// Descriptions
export const ACCORDION_DISPLAY_NAME_DEFAULT_DESCRIPTION =
  'Give this number an internal-only display name to help you and others identify it.';
export const ACCORDION_ASSIGNEE_DEFAULT_DESCRIPTION = 'You can assign this number to at least 1 user or on-call role.';
export const ACCORDION_ALERT_PREFERENCE_DEFAULT_DESCRIPTION =
  'Manage how incoming pages are delivered to the assignee’s Hypercare messaging app.';
export const ACCORDION_VOICEMAIL_DEFAULT_DESCRIPTION =
  'Customize the voice messages to be played when patients call this number. If you’d like to have a separate greeting for after-hours callers, please configure using a different number.';
export const ACCORDION_VOICEMAIL_SPECIFIC_DESCRIPTION = 'Ask callers to leave a call back number and voicemail';

// Friendly Name
export const DISPLAY_NAME_ACCORDION_INPUT_LABEL = 'Display Name';
export const DISPLAY_NAME_ACCORDION_INPUT_PLACEHOLDER = 'Example: Jane’s virtual page';
// Assign To
export const ASSIGNEE_ACCORDION_INPUT_LABLE = 'Assign to';
export const ASSIGNEE_TYPE_ACCORDION_INPUT_LABLE = 'Select an Option';
// Alert
export const ALERT_TYPE_ACCORDION_INPUT_LABLE = 'SEND PAGE AS';
export const ALERT_TYPE_STAT_OPTION = 'STAT message';
export const ALERT_TYPE_URGENT_OPTION = 'Urgent message';
export const ALERT_TYPE_REGULAR_OPTION = 'Regular message';
export const PAGER_SOUND_BY_PASS_DND_ALERT = 'Pages will bypass do-not-disturb mode and play a loud notification sound';
// Chat Preference
export const GROUP_CHAT_ACCORDION_INPUT_LABLE = 'PAGES WILL BE SENT TO';
export const SINGLE_CHAT_OPTION = 'Single Chat';
export const SINGLE_CHAT_OPTION_DESCRIPTION = 'Pages will be sent to the assignee(s) individually';
export const GROUP_CHAT_OPTION = 'Group Chat';
export const GROUP_CHAT_OPTION_DESCRIPTION = 'Pages will be sent to a group chat with all selected assignees';
// Voicemail
export const ADD_NEW_CALLBACK = 'Add new call back message';
export const ADD_NEW_VOICEMAIL = 'Add new voicemail message';
export const ASK_TO_LEAVE_CALLBACK = 'Ask callers to leave a call back number';
export const ASK_TO_LEAVE_VOICEMAIL = 'Ask callers to leave a voicemail';

export const NAME = 'Name';
export const MODIFY = 'Modify';
export const UPLOAD = 'Upload';
export const GENERATE = 'Generate';
export const USE_AUDIO = 'Use audio';
export const SAMPLE_AUDIO = 'Sample audio';
export const AUDIO_UPLOAD = 'Audio upload';
export const ENTER_TEXT_SPEECH = 'Enter text to speech';
export const SUPPORTED_FILE_TYPES = 'Supported file types: .mp3';
export const BROWSER_NOT_SUPPORTED = 'Your browser does not support the audio element.';
export const INVALID_FILE_TYPE_ALRERT = 'Invalid file format. Please upload an MP3 file.';
export const NAME_IS_REQUIRED = 'This is a required field.';
export const DUPLICATE_AUDIO_FILE_NAME = 'Another audio file with the same name already exists.';

export const TEXT_TO_SPEECH_AREA_PLACEHOLDER = 'Hello! You have reached our office.';

export const getHeaderText = (type: AudioModalType) => {
  return type === 'callback' ? 'Add new call back message' : 'Add new voicemail message';
};

export const getDescriptionText = (type: AudioModalType) => {
  return type === 'callback'
    ? 'This message will prompt callers to leave their phone number if they wish to receive a callback. The number will be sent to the assignee(s) via Hypercare.'
    : 'This message will prompt callers to provide a reason for the call. The voicemail recording will be transcribed and sent to the assignee(s) via Hypercare.';
};

export const getSamplePromptText = (type: AudioModalType) => {
  return type === 'callback'
    ? '“Hi, you have reached [full name] of [speciality if applicable] at [organization] paging system powered by Hypercare. Please leave your 10-digit call back number followed by the pound key and I will get back to you.”'
    : "“Please leave a voicemail message and I'll get back to you.”";
};

export const getTextFieldPlaceHolder = (type: AudioModalType) => {
  return type === 'callback' ? 'Leave callback message' : 'Leave a voicemail';
};

export const UPLOAD_AUDIO = 'Upload audio';
export const TEXT_TO_SPEECH = 'Text-to-speech';
export const DISABLE_NUMBER_DANGER_ZONE_TITLE = 'Temporarily disable this number';
export const DISABLE_NUMBER_DESCRIPTION_TEXT =
  'Temporarily disable a number while keeping it reserved for future use. You will still be charged for these numbers.';
export const DISABLING_NUMBER_HEADER = 'Temporarily disable this number?';
export const DISABLING_NUMBER_BANNER_MESSAGE_PART_1 =
  'If you wish to cancel and release this number from your organization instead, please ';
export const DISABLING_NUMBER_BANNER_MESSAGE_PART_2 = 'contact Hypercare support ';
export const DISABLING_NUMBER_BANNER_MESSAGE_PART_3 = 'for further assistance.';
export const DISABLING_NUMBER_ACKNOWLEDGEMENT_ITEM_1 = 'You will still be charged for this number';
export const DISABLING_NUMBER_ACKNOWLEDGEMENT_ITEM_2 =
  'The number will be temporarily out of service and no calls can come through until it is reactivated';
export const DISABLE_NUMBER_BUTTON_TEXT = 'Disable Number';
export const DISABLING_NUMBER_WARNING_TEXT_PART_1 = 'By clicking ';
export const DISABLING_NUMBER_WARNING_TEXT_PART_2 = 'Disable number';
export const DISABLING_NUMBER_WARNING_TEXT_PART_3 = ', you acknowledge:';

export const REACTIVATE_NUMBER_DANGER_ZONE_TITLE = 'Reactivate this number';
export const REACTIVATE_NUMBER_DESCRIPTION_TEXT =
  'Reactivate now to modify any configurations and resume all activities.';
export const REACTIVATING_NUMBER_HEADER = 'Reactivate this number?';
export const REACTIVATING_NUMBER_WARNING_TEXT = 'Upon reactivating, this number will be back in service.';

export const RELEASE_NUMBER_DANGER_ZONE_TITLE = 'Release this number';
export const RELEASE_NUMBER_DESCRIPTION_TEXT = 'Release or cancel an unused number to avoid charges.';

export const CONFIGURE_PAGE_PENDING_BANNER_DESCRIPTION =
  'This virtual number will remain out-of-service until the following configurations are completed.';
export const CONFIGURE_PAGE_PENDING_BANNER_TITLE = 'Complete configuration to activate this number';
export const CONFIGURE_PAGE_DISABLED_BANNER_DESCRIPTION =
  'This number was temporarily disabled and is currently out of service. Reactivate now to modify any configurations and resume all activities.';
export const CONFIGURE_PAGE_DISABLED_BANNER_TITLE = 'Reactivate number to resume all activities';
