import React, { useEffect, useRef } from 'react';
import UserListItem from 'src/components/shared/UserListItem';
import { MaterialUiOption } from 'src/components/shared/ReactSelectMaterialUI';
import readFullUserFragmentById from 'src/utils/readFullUserFragmentById';
import { FixedSizeList, VariableSizeList } from 'react-window';
import Checkbox from '@material-ui/core/Checkbox';
import 'src/assets/styles/SharedStyles.scss';

export const menuListMaxHeight = 300;

/**
 * Custom react-select components
 */
export const FixedHeightMenuList = (props) => {
  const { options, children, getValue } = props;
  const [value] = getValue();
  const itemHeight = 70;
  const initialOffset = options.indexOf(value) * itemHeight;
  // Credit: https://codesandbox.io/s/bvaughnreactwindow-integrationwithreactselect-chsp4?autoresize=1&fontsize=12
  // render performance of react select workaround using react-window, and fix scrolling by useRef
  // Scroll to item API of `react-window`
  // See https://react-window.now.sh/#/api/FixedSizeList
  const scrollToIndex = children.length ? children.findIndex((child) => child.props.isFocused) : 0;
  // Use `useRef` hook to maintain the access to the menu list
  const listRef = useRef(null);
  // effect that happens after rendering to properly adjust the selection
  useEffect(() => {
    if (listRef.current) {
      // @ts-ignore
      listRef.current.scrollToItem(scrollToIndex);
    }
  }, [scrollToIndex]);

  return children.length ? (
    <FixedSizeList
      ref={listRef}
      height={menuListMaxHeight}
      itemCount={children.length}
      itemSize={itemHeight}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  ) : (
    <div className={'react-select__menuList__notFound'}>No Users Found</div>
  );
};

// BUG: render performance issue https://github.com/JedWatson/react-select/issues/2850
export const AutoHeightMenuList = (props) => {
  const { options, children, getValue } = props;
  const [value] = getValue();
  const itemHeight = 70; // same as Material UI MenuItem
  const menuListFixedHeight = 330;
  const menuListDynamicHeight = itemHeight * children.length;
  const initialOffset = options.indexOf(value) * itemHeight;

  const scrollToIndex = children.length ? children.findIndex((child) => child.props.isFocused) : 0;
  // Use `useRef` hook to maintain the access to the menu list
  const listRef = useRef(null);
  // effect that happens after rendering to properly adjust the selection
  useEffect(() => {
    if (listRef && listRef.current) {
      // @ts-ignore
      listRef.current.scrollToItem(scrollToIndex);
    }
  }, [scrollToIndex]);

  function getOptionSize(option) {
    if (option.options) {
      return option.options.length * itemHeight;
    }
    return itemHeight;
  }

  function getItemSize(i) {
    return getOptionSize(options[i]);
  }

  const totalHeight = options.reduce((height, option) => {
    return height + getOptionSize(option);
  }, 0);

  const estimatedItemSize = totalHeight / options.length;

  return (
    <div id="dropdownMenuList" className="react-select__menuList">
      {children.length ? (
        <VariableSizeList
          ref={listRef}
          height={Math.min(menuListFixedHeight, menuListDynamicHeight)}
          itemCount={children.length}
          itemSize={getItemSize}
          estimatedItemSize={estimatedItemSize}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </VariableSizeList>
      ) : (
        <div className="react-select__menuList__notFound">No Users Found</div>
      )}
    </div>
  );
};

export const NoCircleOption = (props) => {
  const colleague = readFullUserFragmentById(props.value);
  return (
    <MaterialUiOption {...props}>
      <div className="react-select__optionLayout">
        <UserListItem colleague={colleague} />
      </div>
    </MaterialUiOption>
  );
};

export const CircleOption = (props) => {
  // BUG: render performance issue https://github.com/JedWatson/react-select/issues/2850
  const colleague = readFullUserFragmentById(props.value);
  return (
    <MaterialUiOption {...props}>
      <div className="react-select__optionLayout">
        <UserListItem colleague={colleague} />
        {props.isSelected ? <Checkbox checked={true} /> : <Checkbox checked={false} />}
      </div>
    </MaterialUiOption>
  );
};
