import React from 'react';
import theme from 'src/assets/styles/theme';
import ClearIcon from 'src/assets/svgs/ClearIcon';
import WatchVideo from 'src/assets/svgs/WatchVideo';
import PauseIcon from 'src/assets/svgs/PauseIcon';
import { HCBodySpan, HCLabelFour } from 'src/components/shared/HypercareComponents';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
`;

const FileNameAndSizeContainer = styled.div`
  flex-grow: 9;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
`;

const AudioPlayerContainer = styled.div`
  margin-top: 12px;
  background-color: ${theme.ghostWhite};
  display: flex;
  min-width: 632px;
  width: 100%;
  height: 56px;
`;

type Props = {
  src: string;
  fileName: string;
  fileSize: number;
  onPlay?: () => void;
  onPause?: () => void;
  onClear?: () => void;
};

const AudioPlayer = ({ src, fileName, fileSize, onPlay = () => {}, onPause = () => {}, onClear = () => {} }: Props) => {
  const [audio] = React.useState(new Audio(src));
  const [isPlaying, setIsPlaying] = React.useState(false);

  audio.addEventListener('ended', () => {
    setIsPlaying(false);
  });

  const pauseAudio = () => {
    audio.pause();
    onPause();
    setIsPlaying(false);
  };

  const playAudio = () => {
    audio.play();
    onPlay();
    setIsPlaying(true);
  };

  return (
    <AudioPlayerContainer>
      <IconContainer onClick={isPlaying ? pauseAudio : playAudio}>
        {isPlaying ? (
          <PauseIcon width={24} height={24} color={theme.mainTealColor} />
        ) : (
          <WatchVideo width={24} height={24} color={theme.mainTealColor} />
        )}
      </IconContainer>
      <FileNameAndSizeContainer>
        <HCBodySpan>{fileName}</HCBodySpan>
        <HCLabelFour color={theme.textLightTertiary}>{`${fileSize} KB`}</HCLabelFour>
      </FileNameAndSizeContainer>
      <IconContainer
        onClick={() => {
          pauseAudio();
          onClear();
        }}
      >
        <ClearIcon />
      </IconContainer>
    </AudioPlayerContainer>
  );
};

export default AudioPlayer;
