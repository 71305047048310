import React from 'react';
import styled from 'styled-components';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

export const METRICAIDERRORCODE = 'CannotModifyDisabledSchedule';

const BaseDescriptionWrapper = styled.div`
  border-radius: 3px;
  padding: 12px 16px 12px 16px;
  margin-top: 8px;
  display: flex;
`;

const ScheduleConflictWrapper = styled(BaseDescriptionWrapper)`
  border: 1px solid #ff9faa;
  background-color: #ffd8dd;
  margin-bottom: 8px;
`;

const DescriptionContainer = styled.div`
  margin-left: 16px;
`;

const IconContainer = styled.div``;

const DescriptionHeader = styled.div`
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
`;

const DescriptionTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-family: 'Open Sans';
`;

const SupportLink = styled.a`
  color: inherit;
`;

const MetricaidComponent = () => {
  return (
    <ScheduleConflictWrapper id={'metricaid-wrapper'}>
      <IconContainer>
        <NotInterestedIcon style={{ color: '#FF3E55' }} />
      </IconContainer>
      <DescriptionContainer>
        <DescriptionHeader>This schedule cannot be modified via Hypercare</DescriptionHeader>
        <DescriptionTitle>
          Contact your Department Admin or{' '}
          <SupportLink href={'mailto:support@hypercare.com'}>Hypercare support</SupportLink> for more information.
        </DescriptionTitle>
      </DescriptionContainer>
    </ScheduleConflictWrapper>
  );
};

export default MetricaidComponent;
