import React from 'react';
import * as ReactModal from 'react-modal';
import client from 'src/clients/apolloClient';
import { typedUseSelector } from 'src/redux/store';
import { DepartmentFragments } from 'src/gql/fragment/ManagementFragments';
import { CreateEscalationLadderResult, DepartmentScope, EscalationLevelDetails } from 'src/types';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import AppTheme from 'src/assets/styles/theme';
import TextField from '@material-ui/core/TextField';
import AlertTriangle from 'src/assets/svgs/AlertTriangle';
import CheckIcon from 'src/assets/svgs/CheckIcon';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';
import {
  CustomToastFlexContainer,
  ToastMessageContainer,
} from 'src/pages/EscalationPage/escalation-layout/SharedEscalationStyles';
import CreateEscalationLadder from 'src/gql/mutation/CreateEscalationLadder';
import { CREATE } from 'src/constants/escalation';
import { AppRoutes } from '../../../../router/AppRoutes';

const StyledTextField = styled(TextField)`
  .MuiFormHelperText-root {
    text-align: right;
    margin-right: 0 !important;
  }
`;

const ButtonsHolder = styled.div`
  display: flex;
  float: right;
  margin-top: 0.5em;

  button {
    min-width: 90px;
    margin-left: 12px;

    &:first-child {
      color: ${AppTheme.mainGreenColor};
    }
  }
`;

const ErrorHolder = styled.div`
  padding-left: 4px;
  display: flex;
  align-items: center;
  color: red;

  div:first-child {
    margin-right: 8px;
  }
`;

interface Props {
  showModal: boolean;
  escalationName?: string;
  handleCloseModal: () => void;
}

const SecondaryConfirmationPopup = ({ showModal, handleCloseModal }: Props) => {
  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__confirmation"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseModal}
    >
      <div className="close" onClick={handleCloseModal} />
      <div className="modal__header">Error</div>
      <p>
        We can’t publish this escalation yet because you haven't added a policy to the escalation. Please add a policy
        before proceeding to publish.
      </p>
      <ButtonsHolder>
        <Button variant="outlined" onClick={handleCloseModal} disableTouchRipple>
          Continue editing
        </Button>
      </ButtonsHolder>
    </ReactModal>
  );
};

const CustomizedActionToast = () => {
  return (
    <CustomToastFlexContainer>
      <ToastMessageContainer>
        <CheckIcon />
        <div>Your escalation has been published</div>
      </ToastMessageContainer>
    </CustomToastFlexContainer>
  );
};

const SaveEscalationModal = ({ showModal, handleCloseModal }: Props) => {
  const [isLoading, setLoading] = React.useState(false);

  const { department_id, site_id } = typedUseSelector((state) => state.organizationReducer);
  const escalationLadder = typedUseSelector((state) => state.escalationReducer.escalationLadder);

  const dispatch = useDispatch();

  const departmentFragment: DepartmentScope = client.readFragment({
    id: `Site:${site_id}-Department:${department_id}`,
    fragment: DepartmentFragments,
  });

  const [escalationName, setEscalationName] = React.useState<string>(departmentFragment.name + ' Escalation');

  const CHARACTER_LIMIT = 50;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEscalationName(`${event.target.value}`);
  };

  const [showSecondaryConfirmationPopup, setShowSecondaryConfirmationPopup] = React.useState(false);

  const handleCloseAll = () => {
    setShowSecondaryConfirmationPopup(false);
    handleCloseModal();
  };

  const handleSaveEscalation = async () => {
    const VALIDATION_ERROR = 'incomplete';
    try {
      setLoading(true);
      const escalationArrList = escalationLadder.toArray();
      let lastLevel = escalationArrList[escalationArrList.length - 1];
      if (escalationArrList.length > 1 && !lastLevel.escalationPolicy && lastLevel.escalationMembers.length === 0) {
        escalationArrList.pop();
      }
      if (
        escalationArrList.find(
          (escalationLevel) => escalationLevel.escalationMembers.length === 0 || !escalationLevel.escalationPolicy,
        )
      ) {
        throw new Error(VALIDATION_ERROR);
      }

      const escalationLevels: EscalationLevelDetails[] = escalationArrList.map((escalationLevel, i) => {
        // TODO: backend policy CURD
        const isPolicyEdited = escalationLevel.escalationPolicy.id === CREATE;

        const detail = {
          level: i + 1,
          escalationMembers: escalationLevel.escalationMembers.map((member) => {
            if (member.role) return { roleId: member.role.auditId };
            if (member.user) return { userId: member.user.id };
            return null;
          }),
        } as EscalationLevelDetails;

        if (isPolicyEdited) {
          detail['escalationPolicy'] = {
            name: escalationLevel.escalationPolicy.name,
            channels: [
              {
                ...escalationLevel.escalationPolicy.channels[0],
              },
            ],
          };
        } else {
          detail['escalationPolicyId'] = escalationLevel.escalationPolicy.id;
        }

        return detail;
      });

      const result = await client.mutate<CreateEscalationLadderResult>({
        mutation: CreateEscalationLadder,
        variables: {
          ladderName: escalationName,
          escalationLevels,
        },
      });

      // dispatch(allActions.escalationAction.changeEscalationState('published'));
      dispatch(allActions.escalationAction.clearEscalationLadder());

      toast.info(CustomizedActionToast());
      handleCloseModal();

      window.routerHistory.push(`${AppRoutes.Escalation}/${result.data.admin.createEscalationLadder.id}`);
    } catch (e) {
      setLoading(false);
      if (e.message === VALIDATION_ERROR) {
        setShowSecondaryConfirmationPopup(true);
      } else {
        toast.error('Failed publishing escalation, please check your internet connection try again');
      }
    }
  };

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__confirmation"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseModal}
    >
      <div className="close" onClick={handleCloseModal} />
      <div className="modal__header">
        <span>Publish escalation</span>
      </div>

      <div>
        <StyledTextField
          fullWidth
          autoFocus
          label="Escalation Name"
          inputProps={{
            maxLength: CHARACTER_LIMIT,
          }}
          disabled={isLoading}
          value={escalationName}
          helperText={`${escalationName.length}/${CHARACTER_LIMIT} characters remaining`}
          onChange={handleChange}
          error={escalationName.length > CHARACTER_LIMIT}
          margin="normal"
          variant="outlined"
        />

        <ErrorHolder>
          <div>
            <AlertTriangle />
          </div>
          <div>
            Note: Please note that by publishing this escalation ladder, the proposed flow will be activated immediately
            on Hypercare.
          </div>
        </ErrorHolder>

        <ButtonsHolder>
          <Button variant="outlined" disableTouchRipple disabled={isLoading} onClick={handleCloseModal}>
            cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            disableTouchRipple
            onClick={handleSaveEscalation}
          >
            {isLoading ? (
              <React.Fragment>
                <CircularProgress size={14} color={'inherit'} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span>save</span>
              </React.Fragment>
            )}
          </Button>
        </ButtonsHolder>

        {showSecondaryConfirmationPopup && (
          <SecondaryConfirmationPopup showModal={showSecondaryConfirmationPopup} handleCloseModal={handleCloseAll} />
        )}
      </div>
    </ReactModal>
  );
};

export default SaveEscalationModal;
