import moment from 'moment';

export const getTimestampString = (date: string) => {
  const now = moment(new Date()).startOf('day');
  const givenDate = moment(date);
  const days = now.diff(givenDate.startOf('day'), 'days');

  if (days < 1) {
    return moment(date).format('[Today], H:mm');
  }
  if (days === 1) {
    return moment(date).format('[Yesterday], H:mm');
  }
  if (days <= 7) {
    return moment(date).format('dddd, H:mm');
  }
  return moment(date).format('MMM D, YYYY [at] H:mm');
};
