import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { StyledTooltip } from 'src/components/shared/HypercareComponents';
import { CheckOutlinedIcon } from 'src/assets/svgs/CheckIcon';
import WarningIcon from 'src/assets/svgs/WarningIcon';
import AlertTriangle from 'src/assets/svgs/AlertTriangle';

const BaseDescriptionWrapper = styled.div`
  border-radius: 6px;
  padding: 12px 16px 12px 16px;
  margin-top: 8px;
  display: flex;
`;

const ScheduleConflictWrapper = styled(BaseDescriptionWrapper)`
  border: 1px solid #fa7a23;
  background-color: #fdece1;
`;

const ScheduleWarningWrapper = styled(BaseDescriptionWrapper)`
  border: 1px solid #ecad0b;
  background-color: #fff4cd;
`;

const IconContainer = styled.div``;

const DescriptionContainer = styled.div`
  margin-left: 16px;
`;

const DescriptionHeader = styled.div`
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
`;

const NoDescriptionAvailableWrapper = styled(BaseDescriptionWrapper)`
  border: 1px solid #006777;
  background-color: #e6f2f4;
  align-items: center;
`;

const UnorderedList = styled.ul`
  margin-top: 4px;
  font-size: 14px;
  font-family: 'Open sans', sans-serif !important;
  padding-inline-start: 25px;
  margin-bottom: 4px;
  line-height: 20px;
`;

const ConflictList = styled.div<{ index: number }>`
  margin-top: ${(props) => (props.index === 0 ? '4px' : '12px')} !important;
`;

const StyledTooltipWrapper = styled.span`
  border-bottom: 2px dotted ${(props) => props.theme.mainFontColor};
  cursor: pointer;
  &:hover {
    border-bottom: 2px dotted ${(props) => props.theme.mainTealColor};
  }
`;

const ScheduleDetailDescriptionView = ({ conflictedShifts }) => {
  const [conflictCount, setConflictCount] = React.useState(0),
    [warningCount, setWarningCount] = React.useState(0);

  React.useEffect(() => {
    let conflictCount = 0,
      warningCount = 0;

    conflictedShifts?.forEach((assignee) => {
      conflictCount = conflictCount + assignee.conflictedShift.length;
      warningCount = warningCount + assignee.warningShifts.length;
    });
    setConflictCount(conflictCount);
    setWarningCount(warningCount);
  }, [conflictedShifts]);

  const ConflictAndWarningListView = ({ issueShift }) => {
    return (
      <UnorderedList>
        {issueShift.map((conflict, conflictIndex) => {
          const { shiftName, department, siteName, startDate, endDate } = conflict;
          return (
            <li key={`assignee-conflict-${conflictIndex}`}>
              {shiftName} in {department} at {siteName} on {moment(startDate).format('DD/MMM/YYYY HH:mm')} -{' '}
              {moment(endDate).format('DD/MMM/YYYY HH:mm')}
            </li>
          );
        })}
      </UnorderedList>
    );
  };

  return (
    <div style={{ marginBottom: 16 }}>
      {conflictCount === 0 && warningCount === 0 && (
        <NoDescriptionAvailableWrapper>
          <IconContainer>
            <CheckOutlinedIcon />
          </IconContainer>
          <DescriptionContainer>
            <DescriptionHeader>No conflicts and warnings found</DescriptionHeader>
          </DescriptionContainer>
        </NoDescriptionAvailableWrapper>
      )}
      {conflictCount > 0 && (
        <ScheduleConflictWrapper>
          <IconContainer>
            <AlertTriangle color={'#FA7A23'} />
          </IconContainer>
          <DescriptionContainer>
            <DescriptionHeader>
              {conflictCount > 0 && (
                <React.Fragment>
                  Please review the following&nbsp;
                  <StyledTooltip
                    arrow
                    title={'Conflicts are identified when a provider has two shifts occurring at the same time.'}
                  >
                    <StyledTooltipWrapper>conflict(s)</StyledTooltipWrapper>
                  </StyledTooltip>
                </React.Fragment>
              )}
            </DescriptionHeader>
            {conflictedShifts?.length > 0 &&
              conflictedShifts.map((shift, assigneeIndex) => {
                const { userName, conflictedShift } = shift;
                if (conflictedShift?.length > 0)
                  return (
                    <ConflictList index={assigneeIndex} key={`assignee-${assigneeIndex}`}>
                      <div>{userName} is also scheduled for:</div>
                      <ConflictAndWarningListView issueShift={conflictedShift} />
                    </ConflictList>
                  );
                else return null;
              })}
          </DescriptionContainer>
        </ScheduleConflictWrapper>
      )}
      {warningCount > 0 && (
        <ScheduleWarningWrapper>
          <IconContainer>
            <WarningIcon />
          </IconContainer>
          <DescriptionContainer>
            <DescriptionHeader>
              {warningCount > 0 && (
                <React.Fragment>
                  Please review the following&nbsp;
                  <StyledTooltip
                    arrow
                    title={'Warnings are identified when a provider has two shifts occurring on the same calendar day.'}
                  >
                    <StyledTooltipWrapper>warning(s)</StyledTooltipWrapper>
                  </StyledTooltip>
                </React.Fragment>
              )}
            </DescriptionHeader>
            {conflictedShifts?.length > 0 &&
              conflictedShifts.map((shift, assigneeIndex) => {
                const { userName, warningShifts } = shift;
                if (warningShifts?.length > 0)
                  return (
                    <ConflictList index={assigneeIndex} key={`assignee-${assigneeIndex}`}>
                      <div>{userName} is also scheduled for:</div>
                      <ConflictAndWarningListView issueShift={warningShifts} />
                    </ConflictList>
                  );
                else return null;
              })}
          </DescriptionContainer>
        </ScheduleWarningWrapper>
      )}
    </div>
  );
};

export default ScheduleDetailDescriptionView;
