import client from 'src/clients/apolloClient';
import store from 'src/redux/store';
import RoleFragment from 'src/gql/fragment/RoleFragment';

export default function (roleId) {
  const { startDateISOstring, endDateISOstring } = store.getState().monthlyScheduleReducer;
  const cachedRole = client.readFragment({
    id: `Role:${roleId}`,
    variables: {
      startDate: startDateISOstring,
      endDate: endDateISOstring,
    },
    fragment: RoleFragment,
    fragmentName: 'RoleFragment',
  });
  return cachedRole;
}
