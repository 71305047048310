import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Header from '../../components/header';
import { GetNumbersButton } from '../../components/buttons';
import PagerSearchBar from './components/SearchBar';
import VirtualPagersManagmentHeader from './components/VirtualPagersManagementHeader';

import {
  FetchOrgPurchasedNumbersResponse,
  OrganizationRoleFragment,
  PagerStats,
  VirtualPagerStatusTypes,
  VirtualPagersMenuOptionStatus,
} from 'src/types/VirtualPagers';
import { VirtualPagers } from 'src/types/VirtualPagers';

import SortButtons from './components/PagerSortButton';
import VirtualPagerMenuItem from './components/VirtualPagerMenuItem';

import EntryPageViewModel from '../EntryPageViewModel';
import NoPagerMenuItem from './components/NoPagerMenuItem';
import VirtualPagerMenuItemLoader from './components/VirtualPagerMenuItemLoader';

import { typedUseSelector } from 'src/redux/store';
import GetNumbersModal from '../../GetNumbersModal/GetNumbersModalCoordinator';

const MainContainer = styled.div`
  max-width: unset !important;
  padding-left: 5%;
  padding-right: 2.5%;
  min-height: 100vh;
  margin: 20px;
`;

interface NumbersListViewProps {
  firstPage: VirtualPagers[];
  firstPageNextCursor: string | null;
  initalPagerStats: PagerStats;
}

const PurchasedNumberView: React.FC<NumbersListViewProps> = ({ firstPage, firstPageNextCursor, initalPagerStats }) => {
  const { getAssigneeStringMapFromPagerList, searchPagers, fetchOrganizationRoles } = EntryPageViewModel();

  const [isGetNumbersModalVisible, setIsGetNumbersModalVisible] = useState<boolean>(false);
  const [menuOptions, setMenuOptions] = useState<VirtualPagersMenuOptionStatus>(VirtualPagerStatusTypes.ALL);

  const [pagerList, setPagerList] = useState<VirtualPagers[]>(firstPage);
  const [pagerListCursor, setPagerListCursor] = useState<string | null>(firstPageNextCursor);
  const [pagerStats, setPagerStats] = useState<PagerStats>(initalPagerStats);

  const [fullCollectionCache, setFullCollectionCache] = useState<VirtualPagers[]>(firstPage);
  const [fullCollectionCacheCursor, setFullCollectionCacheCursor] = useState<string | null>(firstPageNextCursor);
  const [fullCollectionPagerStats, setFullCollectionPagerStats] = useState<PagerStats>(initalPagerStats);

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);
  const [assigneeAndRoleMap, setAssigneeAndRoleMap] = useState<Map<string, string[]>>(new Map());

  const [tableWidth, setTableWidth] = useState<number>(0);

  const [roleList, setRoleList] = useState<OrganizationRoleFragment[]>([]);

  const orgId = typedUseSelector((state) => state.organizationReducer.organization_id);
  const { searchText, sortField, sortOrder, searchType } = typedUseSelector((state) => state.virtualPagerReducer);

  const fetchNextPagersPage = async () => {
    if (searchText === '') {
      const res = await searchPagers(orgId, sortField, sortOrder, fullCollectionCacheCursor, 'all');
      const pagers = res.fetchedNumbers;
      setPagerList([...fullCollectionCache, ...pagers]);
      setPagerListCursor(res.nextResponseCursor);
      setFullCollectionCache([...fullCollectionCache, ...pagers]);
      setFullCollectionCacheCursor(res.nextResponseCursor);
    } else {
      const res = await searchPagers(orgId, sortField, sortOrder, pagerListCursor, searchType, searchText);
      const pagers = res.fetchedNumbers;
      setPagerListCursor(res.nextResponseCursor);
      setPagerList([...pagerList, ...pagers]);
    }
  };

  const fetchPagersWithSearchCriteria = async () => {
    setIsSearching(true);
    setPagerStats({
      [VirtualPagerStatusTypes.ALL]: 0,
      [VirtualPagerStatusTypes.ACTIVE]: 0,
      [VirtualPagerStatusTypes.PENDING]: 0,
      [VirtualPagerStatusTypes.DISABLED]: 0,
    });

    let res: FetchOrgPurchasedNumbersResponse;
    if (searchText === '') {
      res = await searchPagers(orgId, sortField, sortOrder, null, 'all');
      setFullCollectionCache(res.fetchedNumbers);
      setFullCollectionCacheCursor(res.nextResponseCursor);
      setFullCollectionPagerStats(res.pagerStatus);
    } else {
      res = await searchPagers(orgId, sortField, sortOrder, null, searchType, searchText);
    }
    setPagerList(res.fetchedNumbers);
    setPagerListCursor(res.nextResponseCursor);
    setPagerStats(res.pagerStatus);
    setIsSearching(false);
  };

  const showGetNumbersModal = () => {
    setIsGetNumbersModalVisible(true);
  };

  const onPurchase = async () => {
    fetchPagersWithSearchCriteria();
  };

  const onScrolledToBottom = async () => {
    if (!isFetchingNextPage && pagerListCursor) {
      setIsFetchingNextPage(true);
      await fetchNextPagersPage();
      setIsFetchingNextPage(false);
    }
  };

  useEffect(() => {
    // Cache Control: Everytime fullCollectionCache is updated, we cache the assignee and role map with the old ones
    const getAssigneeAndRoleMap = async () => {
      setAssigneeAndRoleMap(await getAssigneeStringMapFromPagerList(assigneeAndRoleMap, fullCollectionCache, roleList));
    };

    getAssigneeAndRoleMap();
  }, [fullCollectionCache, roleList]);

  useEffect(() => {
    fetchPagersWithSearchCriteria();
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (searchText === '' && fullCollectionCache.length > 0) {
      setPagerList(fullCollectionCache);
      setPagerListCursor(fullCollectionCacheCursor);
      setPagerStats(fullCollectionPagerStats);
      return;
    }
    fetchPagersWithSearchCriteria();
  }, [searchType, searchText]);

  useEffect(() => {
    const updateTableWidth = () => {
      const table = document.getElementById('tableEntry');
      if (table) {
        setTableWidth(table.clientWidth);
      }
    };

    updateTableWidth();

    window.addEventListener('resize', updateTableWidth);

    return () => {
      window.removeEventListener('resize', updateTableWidth);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setRoleList(await fetchOrganizationRoles());
    };
    fetchData();
  }, []);

  const filteredPagerList = pagerList.filter((pager) => {
    switch (menuOptions) {
      case VirtualPagerStatusTypes.ACTIVE:
      case VirtualPagerStatusTypes.PENDING:
      case VirtualPagerStatusTypes.DISABLED:
        return pager.status === menuOptions;
      case VirtualPagerStatusTypes.ALL:
      default:
        return true;
    }
  });

  return (
    <MainContainer id="PurchasedPagersPage">
      <Header
        hasNumbers={true}
        buttonComponent={
          <GetNumbersButton isDisabled={false} handleOnClick={showGetNumbersModal} virtualPagerSectionState="Table" />
        }
      />
      <PagerSearchBar />
      <div>
        <VirtualPagersManagmentHeader
          allPagerCount={pagerStats[VirtualPagerStatusTypes.ALL]}
          activePagerCount={pagerStats[VirtualPagerStatusTypes.ACTIVE]}
          pendingPagerCount={pagerStats[VirtualPagerStatusTypes.PENDING]}
          disabledPagerCount={pagerStats[VirtualPagerStatusTypes.DISABLED]}
          currentMenuOption={menuOptions}
          setMenuOptions={setMenuOptions}
        />
        <SortButtons />
        {isSearching ? (
          <VirtualPagerMenuItemLoader />
        ) : pagerList.length === 0 ? (
          <NoPagerMenuItem />
        ) : (
          <React.Fragment>
            {filteredPagerList.map((pager, index) => (
              <VirtualPagerMenuItem
                key={index}
                pager={pager}
                onScrolledToBottom={onScrolledToBottom}
                assignedToList={assigneeAndRoleMap.get(pager._id) || []}
                isLast={index === filteredPagerList.length - 1}
                isPopUpTopLeft={index >= filteredPagerList.length - 3}
                width={tableWidth}
              />
            ))}

            {pagerListCursor && <VirtualPagerMenuItemLoader />}
          </React.Fragment>
        )}
      </div>
      <GetNumbersModal
        onPurchase={onPurchase}
        isOpen={isGetNumbersModalVisible}
        closeModal={() => setIsGetNumbersModalVisible(false)}
      />
    </MainContainer>
  );
};

export default PurchasedNumberView;
