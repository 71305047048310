import React from 'react';
import * as ReactModal from 'react-modal';
import Button from '@material-ui/core/Button';
import AlertTriangle from 'src/assets/svgs/AlertTriangle';
import styled from 'styled-components';

const StyledModalHeader = styled.div`
  svg {
    transform: translateY(4px);
    margin-right: 8px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1em !important;
  float: right;
`;

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  handleProceed: () => void;
}

const EditPolicyWarningModal = ({ showModal, handleCloseModal, handleProceed }: Props) => {
  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__confirmation"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseModal}
    >
      <div className="close" onClick={handleCloseModal} />
      <StyledModalHeader className="modal__header">
        <AlertTriangle />
        <span>Warning</span>
      </StyledModalHeader>

      <div>
        <p>
          You are about to make changes that will deviate from your organization's pre-defined escalation policy.
          <strong>&nbsp;Changes will not affect the default policy and will only be applied to this instance.</strong>
          &nbsp;Please ensure that you have the authorization to make this change. Do you still want to proceed?
        </p>
        <StyledButton variant="contained" color="primary" onClick={handleProceed} disableTouchRipple>
          Proceed anyway
        </StyledButton>
      </div>
    </ReactModal>
  );
};

export default EditPolicyWarningModal;
