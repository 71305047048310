import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import Button from '@material-ui/core/Button';
import { getDeviceName } from 'src/utils/getDeviceName';
import { DEVICE_LOGOUT_BUTTON, WEB_DEVICE } from 'src/constants/user';
import { useUserAgent } from '@oieduardorabelo/use-user-agent';

const DeviceDetailsContainer = styled.div`
  padding-bottom: 8px;
  margin-left: 4%;
`;

const LogoutButtonContainer = styled.div`
  display: grid;
  align-items: center;
`;

const DeviceTitle = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.darkenFontColor};
  margin-bottom: 8px;
  width: 24rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DeviceInfo = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
  margin-bottom: 4px;
`;

const ButtonStyled = styled(Button)`
  font-family: 'Nunito Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 24px !important;
  color: #4a4a4a !important;
  padding: 8px 12px !important;
  background: #ffffff !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  text-transform: inherit !important;
  &:hover {
    background-color: #d8d8d8 !important;
  }
`;

const DeviceListContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  padding: 16px;
  gap: 8px;
  background: #fafafa;
  border: 1px solid ${theme.borderBottomLight};
  border-radius: 4px;
`;

const UserDeviceItem = ({ device, handleOpenModal }) => {
  let uastring = device.userAgent;
  let deviceDetails = useUserAgent(uastring);

  let deviceVersion = device.userAgent
    ? device.type === WEB_DEVICE
      ? `${deviceDetails?.browser?.name} ${deviceDetails?.browser?.version}`
      : deviceDetails?.device?.model
      ? `${deviceDetails?.device?.model} (OS v${deviceDetails?.os?.version})`
      : `${deviceDetails?.os?.name} ${deviceDetails?.os?.version}`
    : 'Unknown';

  return (
    <DeviceDetailsContainer>
      <DeviceListContainer>
        <div>
          <DeviceTitle>{getDeviceName(device.type)}</DeviceTitle>
          <DeviceInfo>Device ID: {device.id}</DeviceInfo>
          <DeviceInfo>Version: {deviceVersion}</DeviceInfo>
        </div>
        <LogoutButtonContainer>
          <ButtonStyled onClick={() => handleOpenModal(device, deviceVersion)}>{DEVICE_LOGOUT_BUTTON}</ButtonStyled>
        </LogoutButtonContainer>
      </DeviceListContainer>
    </DeviceDetailsContainer>
  );
};

export default UserDeviceItem;
