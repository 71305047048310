import React from 'react';
import HypercareModal from 'src/components/shared/HypercareModal';
import {
  DIR_SYNC_DISABLED_TOAST_DESC,
  DIR_SYNC_DISABLED_TOAST_HEADING,
  DIR_SYNC_DISABLE_MODAL_BANNER_DESC,
  DIR_SYNC_DISABLE_MODAL_BANNER_HEADING,
  DIR_SYNC_DISABLE_MODAL_HEADING,
  DIR_SYNC_DISABLE_MODAL_POINTS_ONE,
  DIR_SYNC_DISABLE_MODAL_POINTS_TWO,
  DIR_SYNC_ENABLED_TOAST_DESC,
  DIR_SYNC_ENABLED_TOAST_HEADING,
  DIR_SYNC_ENABLE_MODAL_BANNER_DESC,
  DIR_SYNC_ENABLE_MODAL_BANNER_HEADING,
  DIR_SYNC_ENABLE_MODAL_HEADING,
  DIR_SYNC_ENABLE_MODAL_POINTS_ONE,
  DIR_SYNC_ENABLE_MODAL_POINTS_THREE,
  DIR_SYNC_ENABLE_MODAL_POINTS_TWO,
  DISABLE_DIRECTORY_MANAGEMENT_FOR,
  DISABLE_SYNC,
  ENABLE_DIRECTORY_MANAGEMENT_FOR,
  ENABLE_SYNC,
} from 'src/constants/dirSyncConstants';
import styled from 'styled-components';
import WarningBanner from '../shared/WarningBanner';
import DirSyncViewModel from 'src/pages/HomePage/viewModels/DirSyncViewModel';
import { User } from 'src/types';
import { toast } from 'react-toastify';
import client from 'src/clients/apolloClient';
import { FullOrganizationMemberDirSyncFragment } from 'src/gql/v2/fragment/FullOrganizationMemberDirSyncFragment';
import { getFullOrganizationMemberFragmentFromCache } from 'src/utils/getFullOrganizationMemberFragment';
import { NETWORK_ERROR } from 'src/constants/networkError';

const StyledHeading = styled.div`
  color: #222;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledToast = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: #4a4a4a;

  & .text-wrapper {
    color: #fff;

    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

type Props = {
  open: boolean;
  handleCloseModal: () => void;
  user: User;
};

const DirSyncUsersListModal = ({ open, handleCloseModal, user }: Props) => {
  const viewModel = DirSyncViewModel();
  const { refetch: refetchProfileData } = viewModel.fetchMemberProfile(user.id);
  const fullName = `${user.firstname || ''} ${user.lastname || ''}`;
  const {
    updateMemberDirSyncStatus,
    loading: isUpdateMemberDirSyncStatusLoading,
    error: updateMemberDirSyncStatusError,
  } = viewModel.handleUpdateMemberDirSyncStatus();

  if (updateMemberDirSyncStatusError) {
    handleCloseModal();
    toast(
      <StyledToast>
        <div className="text-wrapper">{NETWORK_ERROR}</div>
      </StyledToast>,
      {
        className: 'toast-dir-message toast-discard',
        autoClose: 5000,
      },
    );
  }

  const updateDirectoryService = (userId: string) => {
    if (!user.isDirectorySynced) {
      enableDirectoryService(userId, true);
    } else {
      disableDirectoryService(userId, false);
    }
  };

  const enableDirectoryService = async (userId: string, syncStatus: boolean) => {
    const result = await updateMemberDirSyncStatus(userId, syncStatus);
    const responseObject = result?.data?.adminMutation?.organizationalUnit?.member?.updateSyncStatus;

    const FullOrganizationMemberFragment = getFullOrganizationMemberFragmentFromCache(userId);

    client.writeFragment({
      id: `FullOrganizationMember:${user.id}`,
      fragment: FullOrganizationMemberDirSyncFragment,
      data: {
        ...FullOrganizationMemberFragment,
        isDirectorySynced: true,
      },
    });

    if (responseObject?.message) {
      toast.error(responseObject.message, {
        className: 'Toast-Container',
        autoClose: 10000,
      });
    }

    if (responseObject?.isDirectorySynced) {
      toast(
        <StyledToast>
          <div>
            <div className="text-wrapper">{DIR_SYNC_ENABLED_TOAST_HEADING}</div>
            <div className="text-wrapper">{DIR_SYNC_ENABLED_TOAST_DESC}</div>
          </div>
        </StyledToast>,
        {
          className: 'toast-dir-message toast-discard',
          autoClose: 5000,
        },
      );
    }

    refetchProfileData();
    handleCloseModal();
  };

  const disableDirectoryService = async (userId: string, syncStatus: boolean) => {
    const result = await updateMemberDirSyncStatus(userId, syncStatus);
    const responseObject = result?.data?.adminMutation?.organizationalUnit?.member?.updateSyncStatus;

    const FullOrganizationMemberFragment = getFullOrganizationMemberFragmentFromCache(userId);

    client.writeFragment({
      id: `FullOrganizationMember:${userId}`,
      fragment: FullOrganizationMemberDirSyncFragment,
      data: {
        ...FullOrganizationMemberFragment,
        isDirectorySynced: false,
      },
    });

    if (responseObject?.message) {
      toast.error(responseObject.message, {
        className: 'Toast-Container',
        autoClose: 10000,
      });
    }

    if (!responseObject?.isDirectorySynced) {
      toast(
        <StyledToast>
          <div>
            <div className="text-wrapper">{DIR_SYNC_DISABLED_TOAST_HEADING}</div>
            <div className="text-wrapper">{DIR_SYNC_DISABLED_TOAST_DESC} </div>
          </div>
        </StyledToast>,
        {
          className: 'toast-dir-message toast-discard',
          autoClose: 5000,
        },
      );
    }

    refetchProfileData();
    handleCloseModal();
  };

  return (
    <HypercareModal
      title={
        !user.isDirectorySynced ? ENABLE_DIRECTORY_MANAGEMENT_FOR(fullName) : DISABLE_DIRECTORY_MANAGEMENT_FOR(fullName)
      }
      body={
        <div>
          <WarningBanner
            title={
              !user.isDirectorySynced ? DIR_SYNC_ENABLE_MODAL_BANNER_HEADING : DIR_SYNC_DISABLE_MODAL_BANNER_HEADING
            }
            description={
              !user.isDirectorySynced ? DIR_SYNC_ENABLE_MODAL_BANNER_DESC : DIR_SYNC_DISABLE_MODAL_BANNER_DESC
            }
          />
          <div>
            <br />
            <StyledHeading>
              {!user.isDirectorySynced ? DIR_SYNC_ENABLE_MODAL_HEADING : DIR_SYNC_DISABLE_MODAL_HEADING}
              <br />
            </StyledHeading>
            <ul className="text-wrapper-2">
              <li>{!user.isDirectorySynced ? DIR_SYNC_ENABLE_MODAL_POINTS_ONE : DIR_SYNC_DISABLE_MODAL_POINTS_ONE}</li>

              <li>{!user.isDirectorySynced ? DIR_SYNC_ENABLE_MODAL_POINTS_TWO : DIR_SYNC_DISABLE_MODAL_POINTS_TWO}</li>

              {!user.isDirectorySynced && <li>{DIR_SYNC_ENABLE_MODAL_POINTS_THREE}</li>}
            </ul>
          </div>
        </div>
      }
      size={'md'}
      closeModal={handleCloseModal}
      isModalVisible={open}
      modalButtons={[
        {
          buttonLabel: 'Cancel',
          type: 'secondary',
          onClickHandler: handleCloseModal,
        },
        {
          buttonLabel: !user.isDirectorySynced ? ENABLE_SYNC : DISABLE_SYNC,
          type: 'primary',
          onClickHandler: () => {
            refetchProfileData();
            updateDirectoryService(user.id);
          },
        },
      ]}
    />
  );
};

export default DirSyncUsersListModal;
