import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14">
    <defs>
      <filter id="a9m6o328xa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 0.244095 0 0 0 0 0.331081 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#a9m6o328xa)" transform="translate(-1094 -14)">
      <path
        fill="#212B36"
        d="M1102.425 21l4.405-4.405c.273-.274.273-.716 0-.99-.274-.274-.716-.274-.99 0l-4.405 4.405-4.405-4.405c-.274-.274-.716-.274-.99 0s-.274.716 0 .99l4.405 4.405-4.405 4.405c-.274.274-.274.716 0 .99.136.136.316.205.495.205.18 0 .358-.069.495-.205l4.405-4.405 4.405 4.405c.136.136.316.205.495.205.18 0 .358-.069.495-.205.273-.274.273-.716 0-.99L1102.425 21z"
      />
    </g>
  </svg>
);
