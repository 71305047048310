import gql from 'graphql-tag';

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    address
    type
    access
    id
  }
`;

export const UpdateAddressPreferenceResultFragment = gql`
  fragment UpdateAddressPreferenceResultFragment on UpdateAddressPreferenceResult {
    ... on FullOrganizationMember {
      addresses {
        ...AddressFragment
      }
    }
    ... on InvalidAddressUpdateRequest {
      message
      __typename
    }
    ... on DuplicateAddresses {
      message
      __typename
    }
    ... on UnverifiedAddress {
      message
      __typename
    }
    ... on InvalidAddressCount {
      message
      __typename
    }
  }
  ${AddressFragment}
`;
