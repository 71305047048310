import { IAnalyticReportMember } from '../../../types';
import { AnalyticsRepository } from '../../../data/repository/AnalyticsRepository';
import { downloadContent } from '../helpers';
import { EXPORT_MEMBER_REPORT_ERROR } from '../../../constants/networkError';

export const AnalyticsViewModel = () => {
  const { fetchMembersAnalyticsReport } = AnalyticsRepository();

  const generateMemberCSV = (data: IAnalyticReportMember[]) => {
    const rows: Array<Array<string | number>> = [['First Name', 'Last Name', 'Username', 'Role', 'Message Sent']];
    data.forEach((x) => rows.push([x.firstname, x.lastname, x.username, x.role, x.messagesentcount]));
    const content = rows.map((row) => row.join(',')).join('\r\n');
    downloadContent(content, 'members.csv', 'text/csv;charset=utf-8');
  };

  const handleGenerateMemberCSVFile = async ({ departmentId, siteId, endDate, organizationId, startDate }) => {
    try {
      const analyticsMemberData = await fetchMembersAnalyticsReport({
        departmentId,
        siteId,
        endDate,
        organizationId,
        startDate,
      });

      if (analyticsMemberData && analyticsMemberData.data) {
        generateMemberCSV(analyticsMemberData.data.analytics.members);
        return { success: true };
      }
    } catch (e) {
      return { error: EXPORT_MEMBER_REPORT_ERROR };
    }
  };
  return {
    handleGenerateMemberCSVFile,
  };
};
