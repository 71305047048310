import gql from 'graphql-tag';
import { RoleResultInfo, ScheduleStateType } from '../../types';

export type AdminFetchScheduleResponse = {
  admin: {
    organization: {
      site: {
        department: {
          schedule: {
            id: number;
            name: string;
            createdAt: string;
            endDate: string;
            roles: RoleResultInfo[];
            state: ScheduleStateType;
          };
        };
      };
    };
  };
};

export const ADMIN_FETCH_SCHEDULES = gql`
  query AdminFetchSchedule(
    $siteId: Int!
    $departmentId: Int!
    $month: ScheduleMonths!
    $year: Int!
    $shiftStartDate: String!
    $shiftEndDate: String!
  ) {
    admin {
      organization {
        site(id: $siteId) {
          __typename

          department(id: $departmentId) {
            __typename

            schedule(month: $month, year: $year) {
              ...ScheduleFragment
              __typename
            }
            __typename
          }
        }
      }
    }
  }

  fragment ScheduleFragment on Schedule {
    id
    name
    startDate
    endDate
    state
    roles {
      ...RoleFragment
      __typename
    }
    createdAt
    updatedAt
    __typename
  }

  fragment RoleFragment on Role {
    id
    name
    auditId
    startTime
    endTime
    duration
    repeatRule
    pagerNumber
    required
    displayPosition
    currentShift {
      ...ShiftFragment
    }
    nextShift {
      ...ShiftFragment
    }
    shifts(startDate: $shiftStartDate, endDate: $shiftEndDate) {
      ...ShiftFragment
      __typename
    }
    createdAt
    updatedAt
    __typename
  }

  fragment ShiftFragment on Shift {
    id
    user {
      id
      username
      firstname
      lastname
      profilePic {
        url
      }
    }
    startDate
    endDate
    warningShifts {
      conflictShift {
        ...ConflictShiftFragment
      }
    }
    conflictingShifts {
      conflictShift {
        ...ConflictShiftFragment
      }
    }
    __typename
  }
  fragment ConflictShiftFragment on Shift {
    id
    startDate
    endDate
    role {
      id
      name
      department {
        id
        name
      }
      site {
        id
        name
      }
    }
    __typename
  }
`;
