import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import RadioButton from 'src/components/shared/RadioButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Query, QueryResult } from 'react-apollo';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ScheduleDownloadModal from 'src/components/modals/ScheduleDownloadModal';
import FormHelperText from '@material-ui/core/FormHelperText';
import { GET_SELF_PROFILE } from 'src/gql/query/GetUserProfileQuery';
import store from 'src/redux/store';
import MultiSelect from 'src/components/shared/MultiSelect';
import { organizationDropDownAction } from 'src/redux/actions/flagAction';
import { useDispatch } from 'react-redux';
import Loader from 'src/components/loaders/InputContentLoader';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { UserViewModel } from '../../HomePage/viewModels/UserViewModel';
import allActions from '../../../redux/actions';

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 40px;
  font-size: 28px;
  line-height: 38px;
`;
const PageBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 492px;
  padding-left: 2em;
  button {
    max-width: 40%;
    text-transform: capitalize;
  }
  .MuiTypography-h3 {
    text-align: center;
    font-size: 19px;
    line-height: 29px;
    padding: 17px 24px;
  }
  .MuiFormHelperText-root {
    margin-left: 30px;
    padding-bottom: 10px;
  }
`;
const Label = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-family: Open Sans;
  color: grey;
  padding-bottom: 5px;
`;
const ModalBodyContainer = styled.div`
  display: flex;
  margin-top: 1em;
  margin-bottom: 4px;
  justify-content: space-between;
  border: input {
    color: black !important;
    font-size: 12px;
  }
  .MuiFormLabel-root {
    font-size: 3px;
    min-width: max-content;
    text-transform: capitalize !important;
    border-radius: 0px;
  }
  & > div {
    width: 48%;
  }
`;
const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background-color: #f6f6f9;
  background: #f6f6f9;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
`;
const UnCheckedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
`;
interface department {
  id: number;
  name: string;
}

let options: department[] = [];
let siteArray: any[];
const SchedulingSiteContainer = (props) => {
  const { isOpen } = store.getState().flagReducer;
  const initialStartDate = moment(moment(moment().subtract(1, 'month')).startOf('month'));
  const initialEndDate = moment(moment(moment().subtract(1, 'month')).endOf('month'));
  const [startDate, setSelectedStartDate] = React.useState(initialStartDate);
  const [endDate, setSelectedEndDate] = React.useState(initialEndDate);
  const [showDownloadModal, setDownloadModal] = React.useState(false);
  const viewDept =
    'Please select a department from the dropdown menu on the top left corner to view the department schedules';
  const downloadEquity =
    "Select a site (if applicable) and departments whose reports you'd want to download in a desired date range. Reports will be exported as an XLS (Microsoft Excel) file.";
  const [helperText, setHelperText] = React.useState(viewDept);
  const [radioButtonValue, setButton] = React.useState('view');
  const [selectedSiteName, setName] = React.useState('');
  const { organization_id } = store.getState().organizationReducer;
  const { site_id } = store.getState().organizationReducer;
  const [selectedsite, setSite] = React.useState(site_id);

  const { getOrganizationScopeList } = UserViewModel();
  const userOrganization = store.getState().userOrganizationReducer.organizations;
  //MultiSelect functions -- Start
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const getOptionLabel = (option) => `${option.name}`;
  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = async (isSelected) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };
  //MultiSelect functions -- end
  const handleDownloadButton = () => {
    setDownloadModal(true);
    if (!selectedSiteName) {
      if (!selectedsite) {
        let { id } = siteArray[0] as any;
        let { name } = siteArray[0] as any;
        setSite(id);
        setName(name);
      } else {
        let { name } = siteArray.find((v) => v.id === selectedsite);
        setName(name);
      }
    }
  };
  const handleSiteChange = async (event: React.ChangeEvent<{}>, selectedOption: any[]) => {
    setSelectedOptions([]);
    let { id } = selectedOption as any;
    let { name } = selectedOption as any;
    setSite(id);
    setName(name);
  };

  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === 'viewDept') {
      setButton('view');
      setHelperText(viewDept);
    } else if ((event.target as HTMLInputElement).value === 'downloadequity') {
      setName('');
      setSelectedOptions([]);
      setHelperText(downloadEquity);
      setButton('Download');
    }

    AnalyticsManager.applyAnalytics({
      eventName:
        event.target.value === 'viewDept'
          ? EVENTS.viewDepartmentSchedulesRadioButton
          : EVENTS.viewDownoadEquityReportRadioButton,
    });
  };

  function handleStartDateChange(date: moment.Moment) {
    setSelectedStartDate(date);
  }
  function handleEndDateChange(date: moment.Moment) {
    setSelectedEndDate(date);
  }
  const dispatch = useDispatch();
  const handleDepartment = async () => {
    dispatch(organizationDropDownAction(isOpen ? false : true));

    if (!userOrganization) {
      const result = await getOrganizationScopeList();
      if (result) {
        dispatch(allActions.userOrganizationAction.setUserOrganizations(result.me.organizations));
      }
    }
  };
  return (
    <React.Fragment>
      <PageHeader>Schedules</PageHeader>
      <PageBody>
        <Typography variant="h3">Do you want to view department schedules or department equity reports?</Typography>
        <FormControl component="fieldset">
          <RadioGroup defaultValue="viewDept" aria-label="schedule" name="schedule-radios" onChange={handleRadioChange}>
            {radioButtonValue === 'view' && (
              <React.Fragment>
                <ViewWrapper>
                  <FormControlLabel value="viewDept" control={<RadioButton />} label="View Department Schedules" />
                  <FormHelperText>{helperText}</FormHelperText>
                  <Button variant="outlined" color="primary" style={{ marginLeft: '2em' }} onClick={handleDepartment}>
                    Select Department
                  </Button>
                </ViewWrapper>
                <UnCheckedWrapper>
                  <FormControlLabel value="downloadequity" control={<RadioButton />} label="Download Equity Reports" />
                </UnCheckedWrapper>
              </React.Fragment>
            )}
            {radioButtonValue !== 'view' && (
              <React.Fragment>
                <UnCheckedWrapper>
                  <FormControlLabel value="viewDept" control={<RadioButton />} label="View Department Schedules" />
                </UnCheckedWrapper>
                {radioButtonValue === 'Download' && (
                  <ViewWrapper>
                    <FormControlLabel
                      value="downloadequity"
                      control={<RadioButton />}
                      label="Download Equity Reports"
                    />
                    <FormHelperText>{helperText}</FormHelperText>
                    <Label>Select Site</Label>
                    <Query query={GET_SELF_PROFILE} fetchPolicy="cache-and-network">
                      {({ loading, error, data }: QueryResult<any>) => {
                        if (error || loading) return <Loader />;
                        if (data.me) {
                          const { organizations } = data.me;
                          let orgArray = [];
                          let filterSite;
                          let siteID;
                          for (let i = 0; i < organizations.length; i++) {
                            if (organizations[i].id === organization_id) {
                              if (site_id) {
                                filterSite = organizations[i].sites;
                                let filteredSite;
                                let filteredSiteID;
                                let filteredDepartments;
                                for (let j = 0; j < filterSite.length; j++) {
                                  if (site_id === filterSite[j].id) {
                                    filteredSite = filterSite[j].name;
                                    filteredSiteID = filterSite[j].id;
                                    filteredDepartments = filterSite[j].departments;
                                  }
                                }
                                orgArray.push({
                                  name: filteredSite,
                                  id: filteredSiteID,
                                  departments: filteredDepartments,
                                });
                              } else {
                                orgArray = organizations[i].sites.sort((a, b) =>
                                  a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                                );
                              }

                              if (orgArray && orgArray.length === 0) {
                                orgArray.push({ name: 'No sites available', id: 0 });
                              }

                              if (!selectedSiteName && orgArray && orgArray.length > 0) {
                                if (!selectedsite) {
                                  let { id } = orgArray[0] as any;
                                  siteID = id;
                                }
                                siteArray = orgArray;
                              }
                            }
                            if (selectedsite || siteID) {
                              let selectedID = selectedsite || siteID;
                              for (let i = 0; i < orgArray.length; i++) {
                                if (orgArray[i].id === selectedID) {
                                  options = orgArray[i].departments.sort((a, b) =>
                                    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                                  );
                                }
                              }
                            }
                          }

                          return (
                            <React.Fragment>
                              <FormControl variant="outlined">
                                <Autocomplete
                                  noOptionsText={'No Sites Available'}
                                  options={orgArray}
                                  getOptionLabel={(option) => option.name}
                                  defaultValue={orgArray.find((v) =>
                                    selectedsite ? v.id === (selectedsite as any) && v.name : v.name[0],
                                  )}
                                  disableClearable
                                  onChange={handleSiteChange}
                                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                              </FormControl>
                              <Label>Select Department</Label>
                              <FormControl>
                                <MultiSelect
                                  noOptionsText={'Department not found'}
                                  items={options}
                                  getOptionLabel={getOptionLabel}
                                  selectedValues={selectedOptions}
                                  selectAllLabel="Select all"
                                  placeHolder={
                                    options && options.length > 0 ? 'Select Departments' : 'No Department Found'
                                  }
                                  onToggleOption={handleToggleOption}
                                  onClearOptions={handleClearOptions}
                                  onSelectAll={handleSelectAll}
                                />
                              </FormControl>
                            </React.Fragment>
                          );
                        }
                      }}
                    </Query>

                    <ModalBodyContainer>
                      <div>
                        <Label>From</Label>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            autoOk={true}
                            id="start-date"
                            format="MMM DD, yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <Label>To</Label>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            inputVariant="outlined"
                            id="end-date"
                            format="MMM DD, yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </ModalBodyContainer>
                    <div>
                      <Button
                        style={{
                          marginLeft: '0px',
                          marginTop: '16px',
                        }}
                        variant="contained"
                        color="secondary"
                        disableTouchRipple
                        disabled={selectedOptions.length <= 0}
                        onClick={handleDownloadButton}
                      >
                        Download Reports
                      </Button>
                    </div>
                  </ViewWrapper>
                )}
              </React.Fragment>
            )}
          </RadioGroup>
        </FormControl>
      </PageBody>
      {showDownloadModal && (
        <ScheduleDownloadModal
          showModal={showDownloadModal}
          handleCloseModal={() => setDownloadModal(false)}
          siteId={selectedsite}
          siteName={selectedSiteName}
          departmentIds={selectedOptions}
          prefillStartDate={startDate}
          prefillendDate={endDate}
        />
      )}
    </React.Fragment>
  );
};
export default SchedulingSiteContainer;
