import React, { useState } from 'react';
import clonedeep from 'lodash.clonedeep';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Divider, Grid, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'src/assets/styles/FormStyles.scss';
import client from 'src/clients/apolloClient';
import {
  PrimaryButton,
  SecondaryButton,
  StyledDialogActions,
  TextButton,
} from 'src/components/shared/HypercareComponents';
import { formatStringToMomentObject } from 'src/utils/formatDateString';
import FetchConflictAndWarningShifts from 'src/gql/query/FetchConflictAndWarningShift';
import UsernameInputSelectContainer from 'src/components/shared/UsernameInputSelectContainer';
import StartEndTimePicker from 'src/pages/SchedulingPage/scheduling-layout/StartEndTimePicker';
import ScheduleRoleInputSelect from 'src/pages/SchedulingPage/scheduling-layout/ScheduleModalRoleInputSelect';
import ScheduleDetailDescriptionView from 'src/pages/SchedulingPage/scheduling-layout/ScheduleDetailDescriptionView';
import MetricaidComponent, { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { Assignee } from 'src/types';
import moment from 'moment';

import {
  getNextOverNightStartDateTime,
  getNextOverNightEndDateTime,
} from 'src/pages/SchedulingPage/scheduling-layout/schedulingHelper';

const useStyles = makeStyles((_) => ({
  dialogContent: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
  addTempShift: {
    marginTop: 24,
    marginBottom: 16,
  },
  formError: {
    color: 'red',
  },
  progressbar: {
    marginRight: 4,
  },
}));

/**
 * Function to validate time in every assignee row.
 * @param startTime {moment.Moment} start time field to check if is empty or is a valid moment object.
 * @param endTime {moment.Moment} end time field to check if is empty or is a valid moment object.
 * @param setFieldError {() => {}} callback fn. to set the error in a useState hook fn.
 * @param index {number} index of the assignee row for which this error message is being validated.
 */
const isTimeValid = (startTime, endTime, setFieldError, index, overnightShifts = false) => {
  const startTimeFieldName = typeof index === 'number' ? `assignee.${index}.startTime` : `startTime`;

  const endTimeFieldName = typeof index === 'number' ? `assignee.${index}.endTime` : `endTime`;

  if (!startTime) {
    setFieldError({ [startTimeFieldName]: 'Start time is required!' });
    return false;
  }
  if (!endTime) {
    setFieldError({ [endTimeFieldName]: 'End time is required!' });
    return false;
  }
  if (!startTime.isValid()) {
    setFieldError({ [startTimeFieldName]: 'Start time is not valid!' });
    return false;
  }
  if (!endTime.isValid()) {
    setFieldError({ [endTimeFieldName]: 'End time is not valid!' });
    return false;
  }

  if (overnightShifts && !endTime.isAfter(startTime)) {
    setFieldError({ [endTimeFieldName]: 'End time is not valid!' });
    return false;
  }

  return true;
};

const fixShiftDates = (startTime, endTime, selectedDate) => {
  // this is alaways assuming that shifts modal are dependent on startTime
  startTime.set('month', selectedDate.month());
  startTime.set('year', selectedDate.year());
  startTime.set('date', selectedDate.date());
  endTime.set('month', selectedDate.month());
  endTime.set('year', selectedDate.year());
  endTime.set('date', selectedDate.date());
  // add endtime to next day
  if (endTime <= startTime) endTime.add(1, 'd');
  return {
    startTime,
    endTime,
  };
};

const sortByStartTime = (shiftA: Assignee, shiftB: Assignee) => {
  if (shiftA.startTime.isBefore(shiftB.startTime)) return -1;
  if (shiftA.startTime.isAfter(shiftB.startTime)) return 1;
  return 1;
};

/**
 * Material UI input form dialog to update or add shifts in the specified role
 * @param handleModalFormSubmission {() => {}} callback fn. to make the API call for shift update
 * @param prefillDate {string} date to set the shift in
 * @param prefillRole {Role} Role under which this shift has to be modified
 * @param prefillAssignee {Assignee[]} Array of assignees to be prefilled in the form
 * @param closeModal {() => {}} callback fn. to close the modal on click of cancel button
 * @param targetAssignee {Assignee} Assignee on which the edit shift button was clicked
 */
const InputScheduleForm = ({
  closeModal,
  prefillDate,
  prefillRole,
  targetAssignee,
  prefillAssignee,
  handleModalFormSubmission,
}) => {
  const overnightShifts = IsFeatureFlagEnabled('overnightShifts');
  let initialAssignee = {
    startTime: prefillAssignee.length === 0 && prefillRole ? prefillRole.startTime : null,
    endTime: prefillAssignee.length === 0 && prefillRole ? prefillRole.endTime : null,
    userId: '',
    userFullName: '',
  };
  let newSortedDatas = prefillAssignee;
  if (overnightShifts) {
    newSortedDatas = prefillAssignee?.sort(sortByStartTime);
  }
  let initialValues = {
    assignee: prefillAssignee && prefillAssignee.length > 0 ? newSortedDatas : [initialAssignee],
    roleName: prefillRole ? prefillRole.roleName : '',
    roleIndex: prefillRole ? prefillRole.index : '',
    selectedDates: [formatStringToMomentObject(prefillDate)],
  };

  const classes = useStyles({}),
    [formError, setFormError] = useState<string>(''),
    [inputShiftFormValues, setInputShiftFormValues] = useState(clonedeep(initialValues)),
    [assigneeFieldError, setAssigneeFieldError] = useState({}),
    [deletedAssignees, setDeletedAssignees] = useState([]),
    [conflictedShifts, setConflictedShifts] = useState([]),
    [showConflictView, setConflictViewVisibility] = useState<boolean>(false),
    [loadingConflictsWarnings, setLoadingConflictsWarnings] = useState<boolean>(false),
    [metricaidErrorState, setMetricaidErrorState] = React.useState<boolean>(false),
    [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  // Function to check Conflicting shifts for every assignee in the input schedule form.

  // Temporarily disabling conflict shift check, https://hypercare.atlassian.net/browse/AIOP-1242
  // const checkShiftConflicts = async (): Promise<any> => {
  //   let usersConflictShifts = [];
  //
  //   const conflictCheck = async (shift) => {
  //     const { selectedDates } = inputShiftFormValues;
  //     const selectedDate = selectedDates[0];
  //     const { startTime, endTime, userId, userFullName, shiftId } = shift;
  //     const { startTime: selectedStartTime, endTime: selectedEndTime } = fixShiftDates(
  //       clonedeep(startTime),
  //       clonedeep(endTime),
  //       selectedDate,
  //     );
  //
  //     try {
  //       const conflictAndWarningShiftResult = await client.query({
  //         query: FetchConflictAndWarningShifts,
  //         variables: {
  //           userId,
  //           endDate: selectedEndTime.toISOString(),
  //           startDate: selectedStartTime.toISOString(),
  //         },
  //         fetchPolicy: 'no-cache',
  //       });
  //
  //       const { conflictingShifts, warningShifts } = conflictAndWarningShiftResult.data.locating;
  //       let conflictedShiftArray = [],
  //         warningShiftArray = [];
  //       if (conflictingShifts && conflictingShifts.length > 0) {
  //         conflictingShifts.forEach((_conflictShift) => {
  //           const {
  //             startDate,
  //             endDate,
  //             role: { site, department, name },
  //           } = _conflictShift.conflictShift;
  //           if (_conflictShift.conflictShift?.id !== shiftId)
  //             conflictedShiftArray.push({
  //               startDate: startDate,
  //               endDate: endDate,
  //               siteName: site?.name,
  //               department: department?.name,
  //               department_id: department?.id,
  //               shiftName: name,
  //             });
  //         });
  //       }
  //
  //       if (warningShifts && warningShifts.length > 0) {
  //         warningShifts.forEach((warningShift) => {
  //           const {
  //             startDate,
  //             endDate,
  //             role: { site, department, name },
  //           } = warningShift.conflictShift;
  //           if (warningShift.conflictShift?.id !== shiftId)
  //             warningShiftArray.push({
  //               startDate: startDate,
  //               endDate: endDate,
  //               siteName: site?.name,
  //               department: department?.name,
  //               department_id: department?.id,
  //               shiftName: name,
  //             });
  //         });
  //       }
  //       usersConflictShifts.push({
  //         userId: userId,
  //         userName: userFullName,
  //         conflictedShift: conflictedShiftArray,
  //         warningShifts: warningShiftArray,
  //       });
  //     } catch (e) {
  //       toast.error(`Failed to check conflicts for ${userFullName}`, {
  //         className: 'Toast-Container',
  //         autoClose: false,
  //       });
  //     }
  //   };
  //   await Promise.all(inputShiftFormValues.assignee.map((shift) => conflictCheck(shift)));
  //   return usersConflictShifts;
  // };

  // Fn. adds new shift row in the UI on click of Add Shift button
  function handleAddTempShift() {
    let { assignee } = inputShiftFormValues;
    let initalClone = { ...initialAssignee };
    Object.keys(initalClone).forEach((key) => (initalClone[key] = null));
    if (overnightShifts) {
      assignee = assignee?.sort(sortByStartTime);
      let tempStartTime, tempEndTime;
      if (assignee.length > 0 && assignee[assignee.length - 1]) {
        const initailDate = (prefillDate || moment()).format('DD/MMM/YY');
        const start = prefillRole?.startTime || moment();
        tempStartTime = getNextOverNightStartDateTime(assignee[assignee.length - 1].endTime, start, initailDate);
        tempEndTime = getNextOverNightEndDateTime(prefillRole?.endTime, start, initailDate);
      }
      initalClone = {
        ...initalClone,
        startTime: tempStartTime || null,
        endTime: tempEndTime || null,
      };
    }
    assignee.push(initalClone);
    setInputShiftFormValues({ ...inputShiftFormValues, assignee });
  }

  /**
   * Fn. to remove the shift on click of cross button after every assignee row, removes from the useState variable
   * @param index {number} index of the row to be removed
   */
  function removeTempShift(index: number): void {
    let assignee = inputShiftFormValues?.assignee;
    assignee.splice(index, 1);
    setInputShiftFormValues({ ...inputShiftFormValues, assignee });
  }

  const roleFieldValueHandler = (field, value, _) => {
    setInputShiftFormValues((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  /**
   * fn. to change the assignee name of the shift
   * @param field since there can be more than one shifts, so to specify which assignee at which index value is changed,
   * then the field values comes in the form of `assignee.{index}.{fieldName}'
   * @param value value of the specified field in the field param.
   */
  const handleAssigneValueChangeHandler = (field, value, _) => {
    let fieldKeys = field.split('.');
    let { assignee } = inputShiftFormValues;
    assignee[fieldKeys[1]][fieldKeys[2]] = value;
    setInputShiftFormValues({ ...inputShiftFormValues, assignee });
  };

  // fn. to validate the data in the form and make the API call with it
  const updateShifts = () => {
    let { assignee, selectedDates } = inputShiftFormValues;
    const isAllTimeValid = assignee.every((ppl, index) => {
      const { startTime, endTime } = ppl;
      return isTimeValid(startTime, endTime, setAssigneeFieldError, index, overnightShifts);
    });

    if (isAllTimeValid) {
      setIsSubmitted(true);
      if (!overnightShifts) {
        const selectedDate = selectedDates[0];
        assignee.forEach((ppl) => {
          const { startTime, endTime } = fixShiftDates(ppl.startTime, ppl.endTime, selectedDate);
          ppl.startTime = startTime;
          ppl.endTime = endTime;
        });
      }

      handleModalFormSubmission(inputShiftFormValues, initialValues, deletedAssignees).catch((e) => {
        switch (e.graphQLErrors[0].code) {
          case METRICAIDERRORCODE:
            setIsSubmitted(false);
            setMetricaidErrorState(true);
            break;
          default:
            setIsSubmitted(false);
            setFormError('Unknown backend error occurred, please try again');
            //reset the form
            break;
        }
      });
    }
  };

  const renderAssigneeShifts = () => {
    return (
      <React.Fragment>
        {inputShiftFormValues?.assignee?.map((ppl, index) => {
          let startTimeErrorKey = `assignee.${index}.startTime`;
          let endTimeErrorKey = `assignee.${index}.endTime`;
          return (
            <Grid
              container
              key={`assignee-${index}`}
              style={{ marginTop: 20, order: ppl?.startTime?.format('HH:mm').replace(':', '') }}
              wrap={'nowrap'}
            >
              <Grid item lg={6} style={{ marginRight: 12, width: '100%' }}>
                <UsernameInputSelectContainer
                  index={index}
                  userId={ppl.userId}
                  isSingleUserForm={false}
                  setFieldValue={handleAssigneValueChangeHandler}
                  userFullNameError={!inputShiftFormValues?.assignee[index]?.userFullName ? 'Name is required!' : null}
                />
              </Grid>
              <StartEndTimePicker
                index={index}
                isAllDayShift={false}
                prefillEndTime={ppl.endTime}
                prefillStartTime={ppl.startTime}
                setFieldValue={handleAssigneValueChangeHandler}
                endTimeError={assigneeFieldError[endTimeErrorKey] ? assigneeFieldError[endTimeErrorKey] : null}
                startTimeError={assigneeFieldError[startTimeErrorKey] ? assigneeFieldError[startTimeErrorKey] : null}
                prefillDate={formatStringToMomentObject(prefillDate)}
                isOvernight={overnightShifts}
                prefillRole={prefillRole}
              />
              {inputShiftFormValues.assignee.length > 1 && (
                <div
                  style={{ marginTop: 8 }}
                  className="deleteRowIconHolder"
                  onClick={() => {
                    setDeletedAssignees([...deletedAssignees, ppl]);
                    removeTempShift(index);
                  }}
                >
                  <i className="material-icons">close</i>
                </div>
              )}
            </Grid>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <div className="modalForm inputScheduleForm">
      <DialogContent classes={{ root: classes.dialogContent }}>
        {metricaidErrorState && <MetricaidComponent />}
        {showConflictView && <ScheduleDetailDescriptionView conflictedShifts={conflictedShifts} />}
        <ScheduleRoleInputSelect
          roleNameError={''}
          isSingleUserForm={false}
          onNewRoleSelection={null}
          prefillRole={prefillRole}
          setFieldValue={roleFieldValueHandler}
        />
        {renderAssigneeShifts()}
        {inputShiftFormValues.assignee.length < 5 && (
          <div className={classes.addTempShift}>
            <TextButton onClick={() => handleAddTempShift()}>
              <i className="material-icons">add</i>
              ADD SHIFT
            </TextButton>
          </div>
        )}
        <div className={classes.formError}>{formError}</div>
      </DialogContent>
      <Divider />
      <StyledDialogActions>
        <Grid container justify={'space-between'}>
          <Grid item>
            {/*<SecondaryButton*/}
            {/*  disabled={inputShiftFormValues.assignee.filter((ppl) => ppl.userFullName === null)?.length > 0}*/}
            {/*  onClick={async (_) => {*/}
            {/*    setLoadingConflictsWarnings(true);*/}
            {/*    let conflicts = await checkShiftConflicts();*/}
            {/*    setConflictedShifts(conflicts);*/}
            {/*    setConflictViewVisibility(true);*/}
            {/*    setLoadingConflictsWarnings(false);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {loadingConflictsWarnings && (*/}
            {/*    <CircularProgress className={classes.progressbar} color="inherit" size={16} />*/}
            {/*  )}*/}
            {/*  {loadingConflictsWarnings ? 'Checking...' : 'Check for conflict'}*/}
            {/*</SecondaryButton>*/}
          </Grid>
          <Grid item>
            <SecondaryButton style={{ marginRight: 8 }} onClick={() => closeModal()}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={
                isSubmitted || inputShiftFormValues.assignee.filter((ppl) => ppl.userFullName === null)?.length > 0
              }
              onClick={() => updateShifts()}
            >
              OK
            </PrimaryButton>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </div>
  );
};

export default InputScheduleForm;
