import React from 'react';
import styled from 'styled-components';
import {
  ADDRESS_BANNER_BOTH_ADDRESS_DESCRIPTION,
  ADDRESS_BANNER_BOTH_ADDRESS_TITLE,
  ADDRESS_BANNER_EMAIL_DESCRIPTION,
  ADDRESS_BANNER_EMAIL_TITLE,
  ADDRESS_BANNER_LEARN_MORE_SUPPORT_TEXT,
  ADDRESS_BANNER_PHONE_DESCRIPTION,
  ADDRESS_BANNER_PHONE_TITLE,
} from 'src/constants/strings';
import WarningAltIcon from '../../../assets/svgs/WarningAltIcon';
import { HCCustomSpan, HCTextContext } from '../../shared/HypercareComponents';
import { LEARN_MORE } from '../../../constants/virtualPagerStrings';

const Banner = styled.div`
  background: #fff4cd;
  border-radius: 3px;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  position: relative;
  justify-content: center;
  margin-bottom: 16px;
`;

const StyledContent = styled.div`
  align-items: center;
  gap: 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
`;

const StyledLink = styled.a`
  color: black;
`;

export enum MissingBannerType {
  EMAIL = 'email',
  PHONE = 'phoneNumber',
  BOTH = 'both',
}

interface Props {
  type: MissingBannerType;
}

const bannerContent = {
  email: {
    title: ADDRESS_BANNER_EMAIL_TITLE,
    description: ADDRESS_BANNER_EMAIL_DESCRIPTION,
  },
  phoneNumber: {
    title: ADDRESS_BANNER_PHONE_TITLE,
    description: ADDRESS_BANNER_PHONE_DESCRIPTION,
  },
  both: {
    title: ADDRESS_BANNER_BOTH_ADDRESS_TITLE,
    description: ADDRESS_BANNER_BOTH_ADDRESS_DESCRIPTION,
  },
};

export const AddressBanner: React.FC<Props> = ({ type }) => {
  const { title, description } = bannerContent[type];

  return (
    <Banner>
      <WarningAltIcon />
      <StyledContent>
        <HCTextContext>{title}</HCTextContext>
        <HCCustomSpan>{description}</HCCustomSpan>
        <StyledLink target="_blank" href={ADDRESS_BANNER_LEARN_MORE_SUPPORT_TEXT}>
          <HCCustomSpan>{LEARN_MORE}</HCCustomSpan>
        </StyledLink>
      </StyledContent>
    </Banner>
  );
};
