import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export default ({ width, height, color }: Props) => (
  <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" fill="white" stroke={color || '#4A4A4A'} />
    <path
      d="M6.7625 11.0125C6.59583 11.1209 6.42717 11.1272 6.2565 11.0315C6.08583 10.9359 6.00033 10.7879 6 10.5875V5.41254C6 5.21254 6.0855 5.06454 6.2565 4.96854C6.4275 4.87254 6.59617 4.87888 6.7625 4.98754L10.8375 7.57504C10.9875 7.67504 11.0625 7.81671 11.0625 8.00005C11.0625 8.18338 10.9875 8.32505 10.8375 8.42505L6.7625 11.0125Z"
      fill={color || '#4A4A4A'}
    />
  </svg>
);
