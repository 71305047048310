import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { handleGraphQLErrors, handleNetworkError } from 'src/clients/apolloErrorLink';
import getApiEndpoint from 'src/utils/getApiEndpoint';
import { print } from 'graphql/language/printer';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import requestLink from 'src/clients/requestLink';
import cache from 'src/clients/inMemoryCache';
import resolvers from 'src/clients/resolvers';

// this is assuming apollo is writing the cache queue in order for the bug below
const parseResponse = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const context = operation.getContext();
    const { response } = context;

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.networkRequest,
      params: {
        status_code: response.status,
        query: print(operation.query),
        duration: new Date().getTime() - operation.getContext().start,
        error_code: '',
        url_path: response.url,
      },
    });

    return result;
  });
});

const afterwareLink = parseResponse.concat(
  new HttpLink({
    uri: `${getApiEndpoint()}/graphql/private`,
  }),
);

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    handleNetworkError(networkError, operation, forward);
  }
  if (graphQLErrors) {
    return handleGraphQLErrors({ graphQLErrors, operation, forward });
  }
});

// note: order matters - errorLink has to be set at beginning of the pipeline
// error -> retry -> request with auth -> afterware with analytics
// https://www.apollographql.com/docs/link/composition/
// TODO: https://github.com/apollographql/apollo-feature-requests/issues/29

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, requestLink, afterwareLink]),
  cache,
  resolvers,
});

export default client;
