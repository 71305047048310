import React, { useState } from 'react';
import AccountManagementHeader from 'src/pages/HomePage/views/AccountManagementHeader';
import Loader from 'src/components/loaders/HomeLayoutLoader';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { UserMenuOptionStatus } from 'src/types';
import { typedUseSelector } from 'src/redux/store';
import TopPanel from 'src/pages/HomePage/views/TopPanel';
import store from 'src/redux/store';
import DirSyncViewModel from 'src/pages/HomePage/viewModels/DirSyncViewModel';
import UsersListLoader from 'src/components/loaders/UsersListLoader';
import 'src/assets/styles/AccountStyles.scss';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';
import AllUsersSectionLayoutV2 from 'src/pages/HomePage/views/AllUsersSectionLayoutV2';
import { AdminSearchBar } from 'src/pages/HomePage/views/AdminSearchBar';
import { PENDING_ADMIN, PENDING_USER } from 'src/constants/inviteUserTypes';
import debounce from 'lodash/debounce';

import { usePaginatedSearchState } from '../hooks/usePaginatedSearchState';
import { usePaginatedDataState } from '../hooks/usePaginatedDataState';
const AccountManagementContainer = () => {
  const { site_id, department_id } = store.getState().organizationReducer;
  const shouldShowPendingInvitesAndRemovedUser = site_id === null && department_id === null;

  const [menuOptions, setMenuOptions] = useState<UserMenuOptionStatus>('licensed');

  const isWaitingSwitchOrganization = typedUseSelector((state) => state.flagReducer.isWaitingSwitchOrganization);

  const viewModel = DirSyncViewModel();

  const userViewModel = UserViewModel();

  const {
    searchLicensedUserData,
    searchBlackListedUserData,
    searchPendingInviteData,
    setSearchPendingInviteData,
    setSearchBlackListedUserData,
    setSearchLicensedUserData,
    setSearchPendingAdminApprovalInviteData,
    searchPendingAdminApprovalInviteData,
    searchLoading,
    setSearchLoading,
    setLocalSearchText,
    localSearchText,
  } = usePaginatedSearchState();

  const { isDoneRows, setIsDoneRows } = usePaginatedDataState();

  const paginatedUserDataQuery = userViewModel.useFetchPaginatedUsers({
    continuationId: null,
    direction: 'next',
  });

  const paginatedRemovedUserDataQuery = userViewModel.useFetchPaginatedRemovedUsers({
    continuationId: null,
    direction: 'next',
    skip: !shouldShowPendingInvitesAndRemovedUser,
  });

  const paginatedPendingAdminInvitesQuery = userViewModel.useFetchPaginatedInvites({
    continuationId: null,
    direction: 'next',
    inviteStatus: PENDING_ADMIN,
    skip: !shouldShowPendingInvitesAndRemovedUser,
  });

  const paginatedPendingUserInvitesQuery = userViewModel.useFetchPaginatedInvites({
    continuationId: null,
    direction: 'next',
    inviteStatus: PENDING_USER,
    skip: !shouldShowPendingInvitesAndRemovedUser,
  });

  const { data: orgDirSyncStatus } = viewModel.fetchOrgDirSyncStatus();

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.accountManagementView);
  }, []);

  const fetchCombinedSearchData = async (searchValue: string) => {
    const organizationType = store.getState().organizationReducer.type;
    const combinedSearchResult = await userViewModel.getCombinedSearchData({
      text: searchValue,
      skip: !shouldShowPendingInvitesAndRemovedUser,
      continuationId: null,
      orgType: organizationType,
    });

    if (combinedSearchResult.searchRemovedUserResult?.error) {
      setSearchBlackListedUserData(null);
    }

    if (combinedSearchResult.searchUsersResult?.error) {
      setSearchLicensedUserData(null);
    }
    if (combinedSearchResult.searchPendingInvitesResult?.error) {
      setSearchPendingInviteData(null);
    }

    if (combinedSearchResult.searchPendingAdminApprovalInvitesResult?.error) {
      setSearchPendingAdminApprovalInviteData(null);
    }

    if ('users' in combinedSearchResult?.searchUsersResult) {
      setSearchLicensedUserData(combinedSearchResult.searchUsersResult);
    }

    if (combinedSearchResult?.searchRemovedUserResult && 'users' in combinedSearchResult?.searchRemovedUserResult) {
      setSearchBlackListedUserData(combinedSearchResult.searchRemovedUserResult);
    }

    if (
      combinedSearchResult?.searchPendingInvitesResult &&
      'invites' in combinedSearchResult?.searchPendingInvitesResult
    ) {
      setSearchPendingInviteData(combinedSearchResult.searchPendingInvitesResult);
    }

    if (
      combinedSearchResult?.searchPendingAdminApprovalInvitesResult &&
      'invites' in combinedSearchResult?.searchPendingAdminApprovalInvitesResult
    ) {
      setSearchPendingAdminApprovalInviteData(combinedSearchResult.searchPendingAdminApprovalInvitesResult);
    }

    setSearchLoading(false);
  };

  const handleCombinedSearch = React.useCallback(
    debounce(async (searchValue: string) => {
      await fetchCombinedSearchData(searchValue);
    }, 250),
    [],
  );

  if (isWaitingSwitchOrganization) return <Loader />;

  const handleChangeMenuOption = (option: UserMenuOptionStatus) => {
    setMenuOptions(option);
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSearchText = e.target.value;
    setLocalSearchText(updatedSearchText);

    if (updatedSearchText === '') {
      setSearchBlackListedUserData(null);
      setSearchPendingInviteData(null);
      setSearchLicensedUserData(null);
      return;
    }

    if (updatedSearchText.length <= 1) {
      return;
    }

    setSearchLoading(true);

    handleCombinedSearch(updatedSearchText);
  };

  const clearSearchText = () => {
    setLocalSearchText('');
    setSearchLicensedUserData(null);
    setSearchBlackListedUserData(null);
    setSearchPendingInviteData(null);
    setIsDoneRows(false);
  };
  const handleAddUserSuccess = () => {
    paginatedUserDataQuery.refetch();
  };
  const combinedInvites =
    paginatedPendingAdminInvitesQuery?.data && paginatedPendingUserInvitesQuery?.data
      ? [
          ...paginatedPendingAdminInvitesQuery.data.adminQuery.organizationalUnit.paginatedPendingInvites.invites,
          ...paginatedPendingUserInvitesQuery.data.adminQuery.organizationalUnit.paginatedPendingInvites.invites,
        ]
      : [];

  const combinedSearchInvites =
    searchPendingInviteData?.invites && searchPendingAdminApprovalInviteData?.invites
      ? [...searchPendingInviteData.invites, ...searchPendingAdminApprovalInviteData.invites]
      : [];

  const licensedUsers =
    localSearchText.length > 0
      ? searchLicensedUserData?.users || []
      : paginatedUserDataQuery?.data?.adminQuery.organizationalUnit?.paginatedMembers?.users;

  const blackListedUsers =
    localSearchText.length > 0
      ? searchBlackListedUserData?.users || []
      : paginatedRemovedUserDataQuery?.data?.adminQuery?.organizationalUnit?.paginatedBlacklistedUsers?.users;

  const pendingInvites = localSearchText.length > 0 ? combinedSearchInvites : combinedInvites;

  const licensedUsersCount =
    localSearchText.length > 1
      ? searchLicensedUserData?.totalResultsCount || 0
      : paginatedUserDataQuery?.data?.adminQuery.organizationalUnit.paginatedMembers?.totalResultsCount || 0;

  const blackListedUsersCount =
    localSearchText.length > 1
      ? searchBlackListedUserData?.totalResultsCount || 0
      : paginatedRemovedUserDataQuery?.data?.adminQuery.organizationalUnit.paginatedBlacklistedUsers
          ?.totalResultsCount || 0;

  const paginatedPendingUserInvitesCount =
    paginatedPendingUserInvitesQuery?.data?.adminQuery.organizationalUnit.paginatedPendingInvites.totalResultsCount ||
    0;
  const paginatedPendingAdminInvitesCount =
    paginatedPendingAdminInvitesQuery?.data?.adminQuery.organizationalUnit.paginatedPendingInvites.totalResultsCount ||
    0;

  const combinedPendingInvitesCount =
    localSearchText.length > 1
      ? searchPendingInviteData?.totalResultsCount + searchPendingAdminApprovalInviteData?.totalResultsCount
      : paginatedPendingUserInvitesCount + paginatedPendingAdminInvitesCount;

  const shouldShowUserListLoader =
    paginatedPendingUserInvitesQuery?.loading ||
    paginatedPendingAdminInvitesQuery?.loading ||
    paginatedUserDataQuery?.loading ||
    paginatedRemovedUserDataQuery?.loading;

  return (
    <section className="accountSection">
      <TopPanel
        setMenuOptions={handleChangeMenuOption}
        userRecords={paginatedUserDataQuery}
        orgDirSyncStatus={orgDirSyncStatus}
        onAddUserSuccess={handleAddUserSuccess}
        pendingRecords={paginatedUserDataQuery}
      />
      <AdminSearchBar clearSearchText={clearSearchText} handleSearch={handleSearch} localSearchText={localSearchText} />
      {shouldShowUserListLoader ? (
        <UsersListLoader />
      ) : (
        <>
          <AccountManagementHeader
            usersCount={licensedUsersCount}
            blackListedUsersCount={blackListedUsersCount}
            pendingUsersCount={combinedPendingInvitesCount}
            currentMenuOption={menuOptions}
            setMenuOptions={handleChangeMenuOption}
            adminCoSignInvites={shouldShowPendingInvitesAndRemovedUser}
          />
          <AllUsersSectionLayoutV2
            searchLoading={searchLoading}
            localSearchText={localSearchText}
            paginatedUserData={paginatedUserDataQuery?.data}
            paginatedRemovedUserDataQuery={paginatedRemovedUserDataQuery?.data}
            licensedUsers={licensedUsers}
            adminPendingUsers={pendingInvites}
            blackListedUsers={blackListedUsers}
            currentMenuOption={menuOptions}
            pendingRecords={paginatedPendingAdminInvitesQuery.refetch}
            adminCoSignInvites={shouldShowPendingInvitesAndRemovedUser}
            fetchMorePaginatedUserData={paginatedUserDataQuery?.fetchMore}
            fetchMorePaginatedRemovedUserData={paginatedRemovedUserDataQuery?.fetchMore}
            paginatedPendingAdminInvitesQuery={paginatedPendingAdminInvitesQuery?.data}
            paginatedPendingUserInvitesQuery={paginatedPendingUserInvitesQuery?.data}
            fetchMorePaginatedAdminInviteQuery={paginatedPendingAdminInvitesQuery?.fetchMore}
            fetchMorePaginatedUserInvitesQuery={paginatedPendingUserInvitesQuery?.fetchMore}
            searchLicensedUserData={searchLicensedUserData}
            setSearchLicensedUserData={setSearchLicensedUserData}
            searchBlackListedUserData={searchBlackListedUserData}
            setSearchBlackListedUserData={setSearchBlackListedUserData}
            searchPendingInviteData={searchPendingInviteData}
            setSearchPendingInviteData={setSearchPendingInviteData}
            searchPendingAdminApprovalInviteData={searchPendingAdminApprovalInviteData}
            setSearchPendingAdminApprovalInviteData={setSearchPendingAdminApprovalInviteData}
            isDoneRows={isDoneRows}
            setIsDoneRows={setIsDoneRows}
          />
        </>
      )}
    </section>
  );
};

export default AccountManagementContainer;
