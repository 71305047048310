import gql from 'graphql-tag';

export const FullOrganizationMemberMutationFragment = gql`
  fragment FullOrganizationMemberMutationFragment on FullOrganizationMemberMutation {
    __typename
    updateProfile(details: $details) {
      ... on FullOrganizationMember {
        id
        firstname: firstName
        lastname: lastName
        role
        __typename
      }
    }
  }
`;

export const FullOrganizationMemberMutationFragmentSyncStatus = gql`
  fragment FullOrganizationMemberMutationFragment on FullOrganizationMemberMutation {
    __typename
    updateSyncStatus(syncStatus: $syncStatus) {
      ... on FailedToTriggerDirSync {
        __typename
        message
      }
      ... on FullOrganizationMember {
        __typename
        id
        firstname: firstName
        lastname: lastName
        role
        isDirectorySynced
      }
    }
  }
`;
