import * as authorizeAction from './authorizeAction';
import * as headerAction from './headerAction';
import * as escalationAction from './escalationActions';
import * as monthlyScheduleAction from './monthlyScheduleAction';
import * as flagAction from './flagAction';
import * as analyticsAction from './analyticsAction';
import * as hypercareRegionAction from './changeHypercareRegionAction';
import * as virtualPagerAction from './virtualPagersActions';
import * as userOrganizationAction from './userOrganizationAction';

const allActions = {
  authorizeAction,
  headerAction,
  escalationAction,
  monthlyScheduleAction,
  flagAction,
  analyticsAction,
  hypercareRegionAction,
  virtualPagerAction,
  userOrganizationAction,
};

export default allActions;
