import React, { useState } from 'react';
import * as ReactModal from 'react-modal';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import store from 'src/redux/store';
import client from 'src/clients/apolloClient';
import DownloadEquityReport from 'src/gql/query/DownloadEquityReport';
import FetchDepartmentEquityReport from 'src/gql/query/FetchDepartmentEquityReport';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import AppTheme from 'src/assets/styles/theme';
import Loading from 'src/components/shared/Loading';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const ModalHeader = styled.div`
  padding-bottom: 24px;
  padding-right: 16px;
  text-align: left;
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #ff0043;
`;
const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  button {
    margin-top: 2em;
    border-radius: 8px;
    text-transform: capitalize;
  }
`;
const ModalBodyContainer = styled.div`
  display: flex;
  margin-top: 1em;
  margin-bottom: 4px;
  justify-content: space-between;
  border: input {
    color: black !important;
    font-size: 12px;
  }
  .MuiFormLabel-root {
    font-size: 14px;
    min-width: max-content;
    text-transform: capitalize !important;
    border-radius: 0px;
  }
  & > div {
    width: 48%;
  }
`;
const ModalBody = styled.div`
  padding-bottom: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  overflow: auto;
  max-height: calc(100vh - 13rem);
  display: inherit;
`;
const Label = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-family: Open Sans;
  color: grey;
  padding-bottom: 5px;
`;
interface Props {
  handleCloseModal: () => void;
  showModal: boolean;
  siteId: number;
  siteName: string;
  departmentIds: any[];
  prefillStartDate: moment.Moment | null;
  prefillendDate: moment.Moment | null;
}
const ScheduleDownloadModal = ({
  siteId,
  siteName,
  departmentIds,
  prefillStartDate,
  prefillendDate,
  showModal,
  handleCloseModal,
}: Props) => {
  const [startDate, setSelectedStartDate] = useState(
    prefillStartDate || moment(moment(moment().subtract(1, 'month')).startOf('month')),
  );
  const [endDate, setSelectedEndDate] = useState(
    prefillendDate || moment(moment(moment().subtract(1, 'month')).endOf('month')),
  );
  const [isDownloading, setDownloading] = React.useState(false);
  const { name } = store.getState().organizationReducer;

  function handleStartDateChange(date: moment.Moment) {
    setSelectedStartDate(date);
  }

  function handleEndDateChange(date: moment.Moment) {
    setSelectedEndDate(date);
  }
  const handleDownloadReport = async (start_Date, end_Date) => {
    if (siteId && departmentIds.length > 0) {
      try {
        setDownloading(true);
        let dept = [];
        departmentIds.map((i) => dept.push(i.id));
        const { data } = await client.query({
          query: FetchDepartmentEquityReport,
          variables: {
            siteId: siteId,
            departmentIds: dept,
            startDate: startDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endDate: endDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
          fetchPolicy: 'no-cache',
        });

        const { fileName, mimeType, blob } = data.locating.site.equityReport;

        const byteChars = atob(blob);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blobObj = new Blob([byteArray], { type: mimeType });

        const fileUrl = window.URL.createObjectURL(blobObj);
        const anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = fileUrl;
        anchor.click();
        setDownloading(false);
        anchor.remove();
        window.URL.revokeObjectURL(fileUrl);
        handleCloseModal();

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.downloadEquityReport,
          params: {
            site_id: siteId,
            department_ids: dept,
            start_date: startDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end_date: endDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
        });
      } catch (e) {
        console.error(e);
        setDownloading(false);
        if (!start_Date || !end_Date) {
          toast.error(`Please select valid start date and end date`, {
            className: 'Toast-Container',
          });
        } else {
          toast.error(`Failed to download equity report, please check your internet connection and try again`, {
            className: 'Toast-Container',
          });
        }
      }
    } else {
      try {
        setDownloading(true);
        const { department_id } = store.getState().organizationReducer;
        const { data } = await client.query({
          query: DownloadEquityReport,
          variables: {
            departmentId: department_id,
            startDate: start_Date.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            endDate: end_Date.format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
          fetchPolicy: 'no-cache',
        });

        const { fileName, mimeType, blob } = data.locating.department.equityReport;

        const byteChars = atob(blob);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blobObj = new Blob([byteArray], { type: mimeType });

        const fileUrl = window.URL.createObjectURL(blobObj);
        const anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = fileUrl;
        anchor.click();
        setDownloading(false);
        anchor.remove();
        window.URL.revokeObjectURL(fileUrl);
        handleCloseModal();
      } catch (e) {
        console.error(e);
        setDownloading(false);
        if (!start_Date || !end_Date) {
          toast.error(`Please select valid start date and end date`, {
            className: 'Toast-Container',
          });
        } else {
          toast.error(`Failed to download equity report, please check your internet connection and try again`, {
            className: 'Toast-Container',
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <ReactModal
        overlayClassName="modal__overlay"
        className="modal__createRole"
        isOpen={showModal}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseModal}
      >
        <div className="close" onClick={handleCloseModal} />
        <ModalHeader>Download equity reports</ModalHeader>
        {!siteId && (
          <React.Fragment>
            <ModalBody className="custom-scrollbar">
              <span>
                You are about to download equity reports from the {(name?.split('-')[0] ?? '').trim()} department under{' '}
                {(name?.split('-')[0] ?? '').trim()}. Please select a date range in the date picker below. Reports will
                be exported as an XLS (Microsoft Excel) file.
              </span>
            </ModalBody>

            <ModalBodyContainer>
              <div>
                <Label>From</Label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    autoOk={true}
                    id="start-date"
                    format="MMM DD, yyyy"
                    value={startDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'start date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div>
                <Label>To</Label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    id="end-date"
                    format="MMM DD, yyyy"
                    value={endDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'end date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </ModalBodyContainer>
          </React.Fragment>
        )}
        {siteId && (
          <ModalBody className="custom-scrollbar">
            <span>
              You are about to download equity reports ({prefillStartDate.format('MM/DD/YY')} -{' '}
              {prefillendDate.format('MM/DD/YY')}) from {departmentIds && departmentIds.length} departments under{' '}
              {siteName}:
            </span>
            {departmentIds &&
              departmentIds.map((i) => (
                <ul key={i.id}>
                  <li>{i.name}</li>
                </ul>
              ))}
            <span>Reports will be exported as an XLS (Microsoft Excel) file.</span>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            type="submit"
            variant="outlined"
            style={{ color: AppTheme.mainTealColor }}
            disabled={isDownloading}
            onClick={handleCloseModal}
            disableTouchRipple
          >
            cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{ marginLeft: '8px' }}
            onClick={() => handleDownloadReport(startDate, endDate)}
            color="secondary"
            disabled={isDownloading}
            disableTouchRipple
          >
            {isDownloading ? (
              <React.Fragment>
                <CircularProgress size={14} color={'inherit'} />
                <span>&nbsp;Generating Reports</span>
              </React.Fragment>
            ) : (
              <span>Download Reports</span>
            )}
          </Button>
        </ModalFooter>
      </ReactModal>
      <Loading open={isDownloading} message={'Please Wait... Reports are being generated.'} />
    </React.Fragment>
  );
};

export default ScheduleDownloadModal;
