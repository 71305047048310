import React from 'react';
import { TEXT_TO_SPEECH, UPLOAD_AUDIO } from 'src/constants/virtualPagerStrings';
import { AudioModalMenuOptions } from 'src/types/VirtualPagers';
import styled from 'styled-components';

const VirtualPagersMgtHeaderMenu = styled.div`
  margin-top: 16px;
  display: flex;
  border-radius: 5px 5px 0px 0px;
  color: ${(props) => props.theme.mainFontColor};
  font-size: 16px;
  align-items: left;
  background: ${(props) => props.theme.white};
  border-bottom: 1px solid ${(props) => props.theme.borderBottomLight};
`;

const VirtualPagersMgtHeaderMenuButton = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 12px 16px;
  align-self: center;
  user-select: none;
  display: flex;

  height: 100%;
  &:hover {
    color: ${(props) => props.theme.mainFontColor};
  }
  border-bottom: ${(props) => (props.isSelected ? `3px solid ${props.theme.mainTealColor}` : 'unset')} !important;
  span {
    font-weight: ${(props) => (props.isSelected ? 600 : 'auto')};
  }
`;

interface Props {
  currentMenuOption: AudioModalMenuOptions;
  setMenuOptions: React.Dispatch<React.SetStateAction<AudioModalMenuOptions>>;
}

const AudioModalTabHeader = ({ currentMenuOption, setMenuOptions }: Props) => {
  const handleMenuOption = (tab: AudioModalMenuOptions) => {
    setMenuOptions(tab);
  };

  return (
    <VirtualPagersMgtHeaderMenu>
      <VirtualPagersMgtHeaderMenuButton
        isSelected={currentMenuOption === 'upload'}
        onClick={() => handleMenuOption('upload')}
      >
        <span>{UPLOAD_AUDIO}</span>
      </VirtualPagersMgtHeaderMenuButton>

      <VirtualPagersMgtHeaderMenuButton
        isSelected={currentMenuOption === 'textToSpeech'}
        onClick={() => handleMenuOption('textToSpeech')}
      >
        <span>{TEXT_TO_SPEECH}</span>
      </VirtualPagersMgtHeaderMenuButton>
    </VirtualPagersMgtHeaderMenu>
  );
};

export default AudioModalTabHeader;
