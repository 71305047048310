import gql from 'graphql-tag';
import { OrganizationUserResultFragment } from '../fragment/OrganizationUserResultFragment';

export const CREATE_SELF_PROFILE_NOTE = gql`
  mutation CreateProfileNote($details: CreateHiddenNoteDetails!) {
    selfMutation {
      createNote(details: $details) {
        __typename
        ... on ProfileNote {
          id
          note
          createdAt
          updatedAt
          access
          createdBy {
            ...OrganizationUserResultFragment
            __typename
          }
          editedBy {
            ...OrganizationUserResultFragment
            __typename
          }
        }
        ... on ExceedMaxNoteCountError {
          message
        }
        ... on ExceedMaxWordCountError {
          message
          maxCount
        }
      }
    }
  }
  ${OrganizationUserResultFragment}
`;
