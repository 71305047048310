import React from 'react';
import 'src/assets/styles/SharedStyles.scss';
import ChevronUp from 'src/assets/svgs/ChevronUp';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const styles = (theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
  });

const scrollBackToTop = () => {
  const globalHeader = document.getElementById('globalHeader');
  globalHeader.scrollIntoView({
    behavior: 'auto',
    block: 'start',
    inline: 'nearest',
  });
};

interface Props {
  offSetY: number;
  classes?: any;
  handleFABMounted?: (args?: any) => void;
}

interface State {
  showFab: boolean;
}

class BackTopButton extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showFab: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.props.handleFABMounted) this.props.handleFABMounted();
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setFlag = () => {
    const { offSetY } = this.props;
    if (window.scrollY > offSetY) {
      this.setState({ showFab: true });
    } else {
      this.setState({ showFab: false });
    }
  };

  handleScroll = () => {
    const { showFab } = this.state;
    const { offSetY } = this.props;
    const scrollY = window.scrollY;
    if (showFab && scrollY < offSetY) this.setState({ showFab: false });
    if (!showFab && scrollY >= offSetY) this.setState({ showFab: true });
  };

  render() {
    const { classes } = this.props;
    const { showFab } = this.state;
    return (
      <Zoom in={showFab} style={{ transitionDelay: showFab ? '200ms' : '0ms' }}>
        <div
          className={classes && classes.absolute}
          id="backTopFAB"
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <Tooltip title="Back to top" aria-label="Back to top">
            <Fab color="secondary" onClick={scrollBackToTop}>
              <ChevronUp style={{ fill: '#ffffff' }} />
            </Fab>
          </Tooltip>
          {this.props.children}
        </div>
      </Zoom>
    );
  }
}

export default withStyles(styles)(BackTopButton);
