import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';
import { HCHeadingThree, HCLabelOne } from 'src/components/shared/HypercareComponents';
import theme from 'src/assets/styles/theme';
import styled from 'styled-components';
import UncheckedAccordian from 'src/assets/svgs/UncheckedAccordian';
import CheckedAccordion from 'src/assets/svgs/CheckedAccordion';
import WarningConeIcon from 'src/assets/svgs/WarningConeIcon';
import { useState } from 'react';
import CheckIcon from 'src/assets/svgs/CheckIcon';
import { CLOSE, SAVE, SAVED, UNSAVED_CHANGES_WARNING } from 'src/constants/virtualPagerStrings';

const SummaryFormatting = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const AccordianContainer = styled.div`
  &:hover {
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  }
`;

const CustomAccordion = styled(Accordion)`
  &.MuiAccordion-root.Mui-expanded {
    display: flex;
    align-items: baseline;
  }
  padding: 8px;
  border-radius: 4px;
`;

const ExpandedRightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 530px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const UnsavedWarning = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <p
        style={{
          fontSize: '14px',
        }}
      >
        {UNSAVED_CHANGES_WARNING}
      </p>
      <WarningConeIcon width={35} height={35} />
    </div>
  );
};

const StyledPrimaryButton = styled(PrimaryButton)`
  &:disabled {
    background-color: ${theme.lightGrey} !important;
    color: ${theme.warmGreyColor} !important;
  }
`;

const DescriptionText = styled(HCLabelOne)`
  color: ${theme.mainFontColor};
`;

type DescriptionType = {
  Generic: string;
  PagerSpecific: string | null;
};

type BaseAccordionProps = {
  title: string;
  description: DescriptionType;
  AccordionDetail: React.ReactElement;
  isSaveButtonDisabled: boolean;
  isFieldValid: boolean;
  isUnsaved?: boolean;
  isPagerDisabled?: boolean;
  ExtraDescription?: React.ReactElement;
  handleSave: () => Promise<void>;
  trackClick: (boolean) => void;
};

const BaseAccordion: React.FC<BaseAccordionProps> = ({
  title,
  handleSave,
  description,
  AccordionDetail,
  isFieldValid,
  isSaveButtonDisabled,
  isPagerDisabled = false,
  ExtraDescription,
  isUnsaved = false,
  trackClick = (expanded) => {},
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleClick = () => {
    trackClick(!expanded);
    setExpanded(!expanded);
  };

  return (
    <AccordianContainer>
      <CustomAccordion expanded={expanded}>
        <AccordionSummary onClick={() => handleClick()}>
          <SummaryFormatting>
            <div
              style={{
                maxWidth: '610px',
              }}
            >
              <HCHeadingThree style={{ marginBottom: '5px' }} color={theme.black}>
                {title}
              </HCHeadingThree>
              {expanded ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <DescriptionText style={{ width: '314px' }}>{description.Generic}</DescriptionText>
                  {ExtraDescription}
                </div>
              ) : (
                <DescriptionText>
                  {description.PagerSpecific ? description.PagerSpecific : description.Generic}
                </DescriptionText>
              )}
            </div>
            <div style={{ alignSelf: 'center' }}>
              {!expanded &&
                (isUnsaved ? (
                  <UnsavedWarning />
                ) : isFieldValid ? (
                  isPagerDisabled ? (
                    <CheckedAccordion width={35} height={35} fill={theme.warmGrey} />
                  ) : (
                    <CheckedAccordion width={35} height={35} />
                  )
                ) : (
                  <UncheckedAccordian width={35} height={35} />
                ))}
            </div>
          </SummaryFormatting>
        </AccordionSummary>

        <AccordionDetails
          style={{
            flexGrow: 1,
            padding: 0,
            display: expanded ? 'flex' : 'none',
          }}
        >
          <ExpandedRightSection>
            {AccordionDetail}
            <ButtonGroup>
              <SecondaryButton onClick={() => handleClick()}>{CLOSE}</SecondaryButton>
              <StyledPrimaryButton
                onClick={async () => {
                  await handleSave();
                  setExpanded(false);
                }}
                startIcon={isPagerDisabled && <CheckIcon width={15} height={15} stroke={theme.warmGreyColor} />}
                disabled={isPagerDisabled || isSaveButtonDisabled}
              >
                {isPagerDisabled ? SAVED : SAVE}
              </StyledPrimaryButton>
            </ButtonGroup>
          </ExpandedRightSection>
        </AccordionDetails>
      </CustomAccordion>
    </AccordianContainer>
  );
};

export default BaseAccordion;
