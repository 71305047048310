import gql from 'graphql-tag';
import userInviteFragment from 'src/gql/fragment/InviteFragement';

export default gql`
  mutation ResendInvite($inviteId: Int!) {
    admin {
      resendInvite(inviteId: $inviteId) {
        ...UserInviteFragment
      }
    }
  }

  ${userInviteFragment}
`;
