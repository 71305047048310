import * as React from 'react';

type Props = {
  width?: number | 40;
  height?: number | 40;
  fill?: string;
};

export default (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill={props.fill || '#00859A'} />
    <path d="M17 26L29 14" stroke="white" strokeWidth="3" strokeLinecap="round" />
    <path d="M16.2242 25.3685L11.0074 20.9911" stroke="white" strokeWidth="3" strokeLinecap="round" />
  </svg>
);
