import React from 'react';
import HypercareLoginRepository from 'src/pages/LoginPage/HypercareLoginRepository';
import { AuthSSOVendors, HandleContinueLoginAddressParams } from 'src/types';
import ApiHelper from 'src/api';

interface Props {
  handleNextButtonPressed: (values: HandleContinueLoginAddressParams) => void;
}

const HypercareLoginAddressViewModel = ({ handleNextButtonPressed }: Props) => {
  const { checkIfAddressIsSSO, fetchSSOConfiguration } = HypercareLoginRepository();
  const completeAddressStep = async (values: { address: string }) => {
    const ssoProfileResult = await isAddressSSO(values.address);
    sessionStorage.setItem('email', values.address);

    if (ssoProfileResult?.error) {
      return { error: ssoProfileResult.error };
    }
    if (ssoProfileResult?.auth0Id && ssoProfileResult?.ssoVendor) {
      localStorage.setItem('ssoVendor', ssoProfileResult?.ssoVendor);

      if (ssoProfileResult.ssoVendor === AuthSSOVendors.WORKOS) {
        await getSSOAuthorizationUrl(ssoProfileResult.auth0Id, ssoProfileResult.ssoVendor);
      } else {
        handleNextButtonPressed({ auth0Id: ssoProfileResult.auth0Id, address: '' });
      }
    } else {
      handleNextButtonPressed({ address: values.address });
    }
  };

  const isAddressSSO = async (email: string) => {
    const result = await checkIfAddressIsSSO(email);
    localStorage.setItem('email', email);

    if (result?.success && result.data?.ssoProfileForUser) {
      const { auth0Id, ssoId, ssoVendor } = result.data.ssoProfileForUser;
      return { auth0Id, ssoId, ssoVendor };
    }

    if (result?.error) {
      let error = result.error?.response?.data?.errors[0]?.message || result.error[0]?.message;
      return { error };
    }
  };

  const fetchSSOConfigurations = async () => {
    const result = await fetchSSOConfiguration();

    const arrUniq = [...new Map(result.map((SSOProfile) => [SSOProfile.auth0Id, SSOProfile])).values()];

    return arrUniq;
  };

  const getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };

  return { completeAddressStep, isAddressSSO, fetchSSOConfigurations };
};

export default HypercareLoginAddressViewModel;
