import getApiEndpoint from 'src/utils/getApiEndpoint';

const getGqlV2Endpoint = () => {
  const BASE_API_ENDPOINT = getApiEndpoint();
  const GRAPHQL_SCHEMA_ENDPOINT = `${BASE_API_ENDPOINT}/v2/graphql/private`;

  return GRAPHQL_SCHEMA_ENDPOINT;
};

export default getGqlV2Endpoint;
