import gql from 'graphql-tag';
import FullUserFragment, { publicUserFragment } from 'src/gql/fragment/FullUserFragment';
import { scopeFragmentString } from 'src/gql/fragment/ScopeFragment';

export const GET_USERS_WITH_SCOPES = gql`
  query GetUsersQueryWithScopes($scope: OrganizationScopeInput) {
    admin {
      users(scope: $scope) {
        ...FullUserFragment
        scopes {
          ${scopeFragmentString}
        }
      }
    }
  }
  ${FullUserFragment}
`;

export const GET_SCOPES = gql`
  query GetScopesForUsers($scope: OrganizationScopeInput) {
    admin {
      users(scope: $scope) {
        id
        scopes {
          ${scopeFragmentString}
        }
      }
  
    }
  }
`;

export const GET_USERS = gql`
  query GetUsersQuery($scope: OrganizationScopeInput) {
    admin {
      users(scope: $scope) {
        ...FullUserFragment
      }
    }
  }
  ${FullUserFragment}
`;

// backend is unable to to add a licenseStatus at full userFragment atm
export const GET_CURRENT_AND_BLACKLISTED_USERS = gql`
  query GetCurrentAndBlacklistedUsers($scope: OrganizationScopeInput) {
    admin {
      users(scope: $scope) {
        ...FullUserFragment
      }
      blacklistedUsers {
        ${publicUserFragment}
      }
    }
  }
  ${FullUserFragment}
`;

export const GET_USERS_WITH_LICENSE = gql`
  query GetUsersQuery($scope: OrganizationScopeInput) {
    admin {
      users(scope: $scope) {
        ...FullUserFragment
      }
      pendingUsers {
        ${publicUserFragment}
      }
      blacklistedUsers {
        ${publicUserFragment}
      }
    }
  }
  ${FullUserFragment}
`;

export const GET_UNLICENSED_USERS = gql`
  query GetUnlicensedUsersQuery {
    admin {
      pendingUsers {
        ${publicUserFragment}
      }
      blacklistedUsers {
        ${publicUserFragment}
      }
    }
  }
`;
