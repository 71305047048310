import React from 'react';
import ContentLoader from 'react-content-loader';

const loaderHeight = 30;

export default () => (
  <ContentLoader
    height={loaderHeight}
    className="contentLoader"
    style={{
      height: `${loaderHeight}px`,
      width: '100%',
      backgroundColor: 'transparent',
      marginTop: '10px',
      marginBottom: '5px',
    }}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="0" rx="4" ry="4" width="100%" height={loaderHeight} />
  </ContentLoader>
);
