import React, { useEffect } from 'react';

import PieChart from 'src/components/analytics/PieChart';
import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import {
  GET_CHAT_BY_TYPE,
  GET_HIGHTLIGHTS_ACTIVE_USERS,
  GET_HIGHTLIGHTS_MESSAGE_SENT,
} from 'src/gql/query/AnalyticsQuery';
import { IChatByTypeResponse, IHighLightResult } from 'src/types';
import { typedUseSelector } from 'src/redux/store';
import ActiveMemberTimelineChart from 'src/pages/AnalyticsDemoPage/ActiveMemberTimelineChart';
import BreakdownByMessageTypeChart from 'src/pages/AnalyticsDemoPage/BreakdownByMessageTypeChart';

const HighlightTabPanel = () => {
  const theme = useTheme();
  const dateRange = typedUseSelector((state) => state.analyticsReducer.dateRange);
  const refreshOn = typedUseSelector((state) => state.analyticsReducer.refreshOn);
  const organizationId = typedUseSelector((state) => state.organizationReducer.organization_id);
  const departmentId = typedUseSelector((state) => state.organizationReducer.department_id);
  const highlightsActiveUser = useQuery<IHighLightResult>(GET_HIGHTLIGHTS_ACTIVE_USERS, {
    variables: {
      startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
      organizationId,
      departmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const highlightsMessageSent = useQuery<IHighLightResult>(GET_HIGHTLIGHTS_MESSAGE_SENT, {
    variables: {
      startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
      organizationId,
      departmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const chatbyType = useQuery<IChatByTypeResponse>(GET_CHAT_BY_TYPE, {
    variables: {
      startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
      organizationId,
      departmentId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    highlightsActiveUser.refetch();
    highlightsMessageSent.refetch();
    chatbyType.refetch();
    // eslint-disable-next-line
  }, [refreshOn]);

  return (
    <Grid container spacing={4}>
      <Grid item md={4} sm={12}>
        <Paper
          style={{
            padding: '34px 24px',
            height: 200,
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          }}
          elevation={0}
        >
          <Box>
            <Typography
              variant="body1"
              style={{ fontSize: 18, fontWeight: theme.typography.fontWeightBold, marginBottom: theme.spacing(0.5) }}
            >
              Total active members
            </Typography>
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Total members who are active - sent at least one message
            </Typography>
          </Box>
          <Typography variant="h4" style={{ fontSize: 40, fontWeight: 'bold' }}>
            {highlightsActiveUser.loading
              ? 'Loading'
              : highlightsActiveUser.data
              ? highlightsActiveUser.data.analytics.highlights.activeUsers.value.value
              : 'N/A'}
          </Typography>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12}>
        <Paper
          style={{
            padding: '34px 24px',
            height: 200,
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          }}
          elevation={0}
        >
          <Box>
            <Typography
              variant="body1"
              style={{ fontSize: 18, fontWeight: theme.typography.fontWeightBold, marginBottom: theme.spacing(0.5) }}
            >
              Messages sent
            </Typography>
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Total messages sent within the selected period
            </Typography>
          </Box>
          <Typography variant="h4" style={{ fontSize: 40, fontWeight: 'bold' }}>
            {highlightsMessageSent.loading
              ? 'Loading'
              : highlightsMessageSent.data
              ? highlightsMessageSent.data.analytics.highlights.messageSent.value.value
              : 'N/A'}
          </Typography>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12}>
        <Paper
          style={{
            padding: 16,
            height: 200,
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
          }}
          elevation={0}
        >
          <Box style={{ padding: '18px 0px 18px 8px' }}>
            <Typography
              variant="body1"
              style={{ fontSize: 18, fontWeight: theme.typography.fontWeightBold, whiteSpace: 'nowrap' }}
            >
              Single vs. Group chat
            </Typography>
            <Typography variant="subtitle2" style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
              Where conversations are held
            </Typography>
            {chatbyType.loading ? (
              <Typography variant="body2">Loading...</Typography>
            ) : chatbyType.data ? (
              <React.Fragment>
                {chatbyType.data.analytics.chatByType.values.map((element, index) => (
                  <Box marginY={2.5} display="flex" flexDirection="row" alignItems="center" key={element.label}>
                    <Box
                      bgcolor={['#80C2CC', '#FFE498'][index]}
                      height={10}
                      width={10}
                      borderRadius={5}
                      marginRight={1}
                    />
                    <Typography variant="body2">
                      {element.label} :{' '}
                      <span style={{ fontWeight: theme.typography.fontWeightBold }}>{element.value}</span>
                    </Typography>
                  </Box>
                ))}
              </React.Fragment>
            ) : (
              <Typography variant="body2">N/A</Typography>
            )}
          </Box>
          {!chatbyType.loading && chatbyType.data && (
            <Box style={{ width: 154, height: 154 }}>
              <PieChart
                height="100%"
                width="100%"
                data={chatbyType.data.analytics.chatByType.values}
                colors={['#80C2CC', '#FFE498']}
                dataKey="value"
                innerRadius={44}
              />
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item md={12} sm={12}>
        <Paper
          style={{
            padding: 16,
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
          }}
          elevation={0}
        >
          <ActiveMemberTimelineChart />
        </Paper>
      </Grid>
      <Grid item md={12} sm={12}>
        <Paper
          style={{
            padding: 16,
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
          }}
          elevation={0}
        >
          <BreakdownByMessageTypeChart />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HighlightTabPanel;
