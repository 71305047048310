import gql from 'graphql-tag';

export const MessageTemplateFormString = `
    id
    formTitle
`;

export default gql`
fragment MessageTemplateFormFragment on MessageTemplateForm {
    ${MessageTemplateFormString}
  }
`;
