import React, { useState } from 'react';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import SystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt';
import { downloadContent } from 'src/pages/AnalyticsDemoPage/helpers';
import { useApolloClient } from 'react-apollo';
import DownloadIcon from 'src/assets/svgs/DownloadIcon';
import moment from 'moment';
import {
  GET_MEMBERS,
  GET_ACTIVE_MEMBER_TIMELINE,
  GET_MESSAGE_BY_TYPE_TIMELINE,
  GET_HIGHTLIGHTS_ACTIVE_USERS,
  GET_HIGHTLIGHTS_MESSAGE_SENT,
} from 'src/gql/query/AnalyticsQuery';
import { typedUseSelector } from 'src/redux/store';
import { IMember } from 'src/types';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

interface IHighlightExportData {
  date: string;
  dailyActiveMember: number;
  dailyMessageSent: number;
  monthlyActiveMember: number;
  monthlyMessageSent: number;
  weeklyTextMessage: number;
  weeklyMediaSent: number;
  weeklyMedicalConsults: number;
  weeklyAttachments: number;
  monthlyTextMessage: number;
  monthlyMediaSent: number;
  monthlyMedicalConsults: number;
  monthlyAttachments: number;
  activeMembers: number;
  messageSent: number;
}

const generateMemberCSV = (data: IMember[]) => {
  const rows: Array<Array<string | number>> = [['First Name', 'Last Name', 'Username', 'Role', 'Message Sent']];
  data.forEach((x) => rows.push([x.firstname, x.lastname, x.username, x.role, x.messagesentcount]));
  const content = rows.map((row) => row.join(',')).join('\r\n');
  downloadContent(content, 'members.csv', 'text/csv;charset=utf-8');
};

const generateMemberExcel = (data: IMember[]) => {
  let content = '<table>';
  content += '<thead>';
  content += '<tr style="font-weight: bold;">';
  content += '<td>First Name</td>';
  content += '<td>Last Name</td>';
  content += '<td>Username</td>';
  content += '<td>Role</td>';
  content += '<td>Message Sent</td>';
  content += '</tr>';
  content += '</thead>';
  content += '<tbody>';
  content += data
    .map(
      (x) =>
        `<tr><td>${x.firstname}</td><td>${x.lastname}</td><td>${x.username}</td><td>${x.role}</td><td>${x.messagesentcount}</td></tr>`,
    )
    .join('\r\n');
  content += '</tbody>';

  downloadContent(content, 'members.xls', 'application/vnd.ms-excel');
};

const generateHighlightCSV = (data: IHighlightExportData[]) => {
  const rows: Array<Array<string | number>> = [
    [
      'Date',
      'Daily Active Members',
      'Daily Message Sent',
      'Monthly Active Members',
      'Monthly Message Sent',
      'Weekly Text Message',
      'Weekly Media Sent',
      'Weekly Medical Consults',
      'Weekly Attachment',
      'Monthly Text Message',
      'Monthly Media Sent',
      'Monthly Medical Consults',
      'Monthly Attachment',
      'Active Members',
      'Message Sent',
    ],
  ];
  data.forEach((x) =>
    rows.push([
      x.date,
      x.dailyActiveMember,
      x.dailyMessageSent,
      x.monthlyActiveMember,
      x.monthlyMessageSent,
      x.weeklyTextMessage,
      x.weeklyMediaSent,
      x.weeklyMedicalConsults,
      x.weeklyAttachments,
      x.monthlyTextMessage,
      x.monthlyMediaSent,
      x.monthlyMedicalConsults,
      x.monthlyAttachments,
      x.activeMembers,
      x.messageSent,
    ]),
  );
  const content = rows.map((row) => row.join(',')).join('\r\n');
  downloadContent(content, 'analytics.csv', 'text/csv;charset=utf-8');
};

const generateHighlightExcel = (data: IHighlightExportData[]) => {
  let content = '<table>';
  content += '<thead>';
  content += '<tr style="font-weight: bold;">';
  content += '<td>Date</td>';
  content += '<td>Daily Active Members</td>';
  content += '<td>Daily Message Sent</td>';
  content += '<td>Monthly Active Members</td>';
  content += '<td>Monthly Message Sent</td>';
  content += '<td>Weekly Text Message</td>';
  content += '<td>Weekly Media Sent</td>';
  content += '<td>Weekly Medical Consults</td>';
  content += '<td>Weekly Attachment</td>';
  content += '<td>Monthly Text Message</td>';
  content += '<td>Monthly Media Sent</td>';
  content += '<td>Monthly Medical Consults</td>';
  content += '<td>Monthly Attachment</td>';
  content += '<td>Active Members</td>';
  content += '<td>Message Sent</td>';
  content += '</tr>';
  content += '</thead>';
  content += '<tbody>';
  content += data
    .map(
      (x) =>
        `<tr><td>${x.date || ''}</td><td>${x.dailyActiveMember || ''}</td><td>${x.dailyMessageSent || ''}</td><td>${
          x.monthlyActiveMember || ''
        }</td><td>${x.monthlyMessageSent || ''}</td><td>${x.weeklyTextMessage || ''}</td><td>${
          x.weeklyMediaSent || ''
        }</td><td>${x.weeklyMedicalConsults || ''}</td><td>${x.weeklyAttachments || ''}</td><td>${
          x.monthlyTextMessage || ''
        }</td><td>${x.monthlyMediaSent || ''}</td><td>${x.monthlyMedicalConsults || ''}</td><td>${
          x.monthlyAttachments || ''
        }</td><td>${x.activeMembers || ''}</td><td>${x.messageSent || ''}</td></tr>`,
    )
    .join('\r\n');
  content += '</tbody>';

  downloadContent(content, 'analytics.xls', 'application/vnd.ms-excel');
};

interface IExportButton {
  tabIndex: number;
}

const ExportButton: React.FC<IExportButton> = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const client = useApolloClient();
  const dateRange = typedUseSelector((state) => state.analyticsReducer.dateRange);
  const organizationId = typedUseSelector((state) => state.organizationReducer.organization_id);
  const departmentId = typedUseSelector((state) => state.organizationReducer.department_id);
  const siteId = typedUseSelector((state) => state.organizationReducer.site_id);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getHightlightsData = async (): Promise<IHighlightExportData[]> => {
    const startDate = moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss');
    const activeMemberTimelineDaily = await client.query({
      query: GET_ACTIVE_MEMBER_TIMELINE,
      variables: {
        startDate,
        endDate,
        groupBy: 'daily',
        organizationId,
        departmentId,
      },
    });

    const activeMemberTimelineMonthly = await client.query({
      query: GET_ACTIVE_MEMBER_TIMELINE,
      variables: {
        startDate,
        endDate,
        groupBy: 'monthly',
        organizationId,
        departmentId,
      },
    });

    const weeklyMessageSent = await client.query({
      query: GET_MESSAGE_BY_TYPE_TIMELINE,
      variables: {
        startDate,
        endDate,
        groupBy: 'weekly',
        organizationId,
        departmentId,
        priorityOnly: false,
      },
    });

    const monthlyMessageSent = await client.query({
      query: GET_MESSAGE_BY_TYPE_TIMELINE,
      variables: {
        startDate,
        endDate,
        groupBy: 'monthly',
        organizationId,
        departmentId,
        priorityOnly: false,
      },
    });

    const highlightsActiveUser = await client.query({
      query: GET_HIGHTLIGHTS_ACTIVE_USERS,
      variables: {
        startDate,
        endDate,
        organizationId,
        departmentId,
      },
      fetchPolicy: 'no-cache',
    });

    const highlightsMessageSent = await client.query({
      query: GET_HIGHTLIGHTS_MESSAGE_SENT,
      variables: {
        startDate,
        endDate,
        organizationId,
        departmentId,
      },
      fetchPolicy: 'no-cache',
    });

    const resultData = [];

    activeMemberTimelineDaily.data.analytics.activeMemberTimelines.series[0].values.forEach((x) => {
      resultData.push({
        date: x.label,
        dailyActiveMember: x.value,
      });
    });

    activeMemberTimelineDaily.data.analytics.messageSentTimelines.series[0].values.forEach((x, index) => {
      resultData[index].dailyMessageSent = x.value;
    });

    activeMemberTimelineMonthly.data.analytics.activeMemberTimelines.series[0].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].monthlyActiveMember = x.value;
      }
    });

    activeMemberTimelineMonthly.data.analytics.messageSentTimelines.series[0].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].monthlyMessageSent = x.value;
      }
    });

    weeklyMessageSent.data.analytics.messageByTypesTimelines.series[1].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].weeklyTextMessage = x.value;
      }
    });

    weeklyMessageSent.data.analytics.messageByTypesTimelines.series[2].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).utc().format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].weeklyMediaSent = x.value;
      }
    });

    weeklyMessageSent.data.analytics.messageByTypesTimelines.series[3].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).utc().format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].weeklyMedicalConsults = x.value;
      }
    });

    weeklyMessageSent.data.analytics.messageByTypesTimelines.series[4].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).utc().format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].weeklyAttachments = x.value;
      }
    });

    monthlyMessageSent.data.analytics.messageByTypesTimelines.series[1].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].monthlyTextMessage = x.value;
      }
    });

    monthlyMessageSent.data.analytics.messageByTypesTimelines.series[2].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).utc().format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].monthlyMediaSent = x.value;
      }
    });

    monthlyMessageSent.data.analytics.messageByTypesTimelines.series[3].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).utc().format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].monthlyMedicalConsults = x.value;
      }
    });

    monthlyMessageSent.data.analytics.messageByTypesTimelines.series[4].values.forEach((x, index) => {
      const _index = resultData.findIndex(
        (y) => moment(y.date).format('YYYY-MM-DD') === moment(x.label).utc().format('YYYY-MM-DD'),
      );
      if (_index !== -1) {
        resultData[_index].monthlyAttachments = x.value;
      }
    });

    resultData[0].activeMembers = highlightsActiveUser.data.analytics.highlights.activeUsers.value.value;
    resultData[0].messageSent = highlightsMessageSent.data.analytics.highlights.messageSent.value.value;

    return resultData;
  };

  const getMemberData = async (): Promise<IMember[]> => {
    const result = await client.query({
      query: GET_MEMBERS,
      variables: {
        startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
        organizationId,
        departmentId,
        siteId,
      },
    });

    return result.data.analytics.members;
  };

  const handleExportAsCSV = async () => {
    setAnchorEl(null);
    setDownloading(true);
    try {
      if (props.tabIndex === 0) {
        const data = await getHightlightsData();
        generateHighlightCSV(data);
      } else {
        const data = await getMemberData();
        generateMemberCSV(data);
      }
      setDownloading(false);
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.membersExportUsersCsv,
      });
    } catch (error) {
      setDownloading(false);
    }
  };

  const handleExportAsExcel = async () => {
    setAnchorEl(null);
    setDownloading(true);
    try {
      if (props.tabIndex === 0) {
        const data = await getHightlightsData();
        generateHighlightExcel(data);
      } else {
        const data = await getMemberData();
        generateMemberExcel(data);
      }
      setDownloading(false);
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.membersExportUsersXls,
      });
    } catch (error) {
      setDownloading(false);
    }
  };

  return (
    <React.Fragment>
      <Button
        style={{ textTransform: 'none', height: 47 }}
        color="primary"
        variant="outlined"
        endIcon={<ExpandMore />}
        onClick={handleOpenMenu}
        disabled={downloading}
      >
        {downloading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <DownloadIcon size={20} style={{ marginRight: 4 }} />
        )}
        &nbsp;Export report
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={handleExportAsCSV}>Export as CSV</MenuItem>
        <MenuItem onClick={handleExportAsExcel}>Export as Excel</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ExportButton;
