import React from 'react';
import styled from 'styled-components';
import ScheduleDownloadModal from 'src/components/modals/ScheduleDownloadModal';
import ScheduleCreateRoleModal from 'src/components/modals/ScheduleCreateRoleModal';
import SchedulePublishModal from 'src/components/modals/SchedulePublishModal';
import ScheduleInputModal from 'src/components/modals/ScheduleInputModal';
import InputScheduleBtnPortal from 'src/pages/SchedulingPage/scheduling-layout/scheduling-sub-header/FABPortal';
import { Button, ButtonGroup, Divider, CircularProgress, Popover } from '@material-ui/core';
import client from 'src/clients/apolloClient';
import { toast } from 'react-toastify';
import { DRAFT, PUBLISHED } from 'src/constants/scheduler';
import DownloadSchedules from 'src/gql/query/DownloadSchedules';
import DownloadEquityReport from 'src/gql/query/DownloadEquityReport';
import DownloadIcon from 'src/assets/svgs/DownloadIcon';
import CalendarIcon from 'src/assets/svgs/CalendarIcon';
import PlusUserIcon from 'src/assets/svgs/PlusUserIcon';
import AlertTriangle from 'src/assets/svgs/AlertTriangle';
import WarningIcon from 'src/assets/svgs/WarningIcon';
import SubHeaderSavingIndicator from 'src/pages/SchedulingPage/scheduling-layout/scheduling-sub-header/SubHeaderSavingIndicator';
import AppTheme from 'src/assets/styles/theme';
import { typedUseSelector } from 'src/redux/store';
import { StyledTooltip } from 'src/components/shared/HypercareComponents';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { DISABLED_DOWNLOAD_TOOLTIP_TEXT, DOWNLOAD, DOWNLOADING } from 'src/constants/strings';
import moment from 'moment';
const SubHeaderSettingsWrapper = styled.div`
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const ConflictAndWarningWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  border-radius: 8px;
  align-items: center;
  padding: 0 8px;
  margin-left: 16px;
  div {
    display: flex;
  }
`;

const SettingsButtonHolder = styled.div`
  display: flex;
  button {
    svg {
      height: 14px;
      width: 14px;
    }
    span {
      &:last-child {
        transform: translateY(1px);
      }
    }
    span.btn-label {
      padding-left: 12px;
    }
    height: 40px;
    color: ${AppTheme.mainTealColor};
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    padding: 8px 12px;
    border-color: #bcbfc3;
    text-transform: capitalize;
    border-radius: 4px;
  }
`;

const SettingsStatusIndicator = styled.div`
  display: flex;
`;

const PopoverContent = styled.div`
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  padding: 0 1em;
  font-weight: 600;
  color: ${AppTheme.darkenFontColor};
  &:first-of-type {
    border-bottom: 1px solid lightgrey;
  }
  &:hover {
    background-color: lightgrey;
  }
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    height: 80px;
    overflow: unset;
  }
`;

const ScheduleText = styled.div`
  font-size: 16px;
  font-family: 'Open Sans' !important;
  line-height: 24px;
  margin-left: 8px;
  color: ${(props) => props.theme.mainFontColor};
`;

const StyledTooltipWrapper = styled.span`
  border-bottom: 2px dotted ${(props) => props.theme.mainFontColor};
  cursor: pointer;
  &:hover {
    border-bottom: 2px dotted ${(props) => props.theme.mainTealColor};
  }
`;

type DownloadType = 'equity' | 'schedule';

const SubHeaderSettings = () => {
  const [showInputScheduleModal, setInputScheduleModal] = React.useState(false);
  const [showDownloadModal, setDownloadModal] = React.useState(false);
  const [showCreateRoleModal, setCreateRoleModal] = React.useState(false);
  const [showPublishScheduleModal, setPublishScheduleModal] = React.useState(false);
  const [isDownloading, setDownloading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const department_id = typedUseSelector((state) => state.organizationReducer.department_id);
  const monthlyScheduleReducer = typedUseSelector((state) => state.monthlyScheduleReducer);
  const { scheduleId, endDateISOstring, startDateISOstring, scheduleState, totalShiftConflictAndWarnings } =
    monthlyScheduleReducer;

  const [isToolTipOpen, setIsToolTipOpen] = React.useState(false);
  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadSchedule = () => {
    setAnchorEl(null);
    setDownload('schedule');
  };

  const handleDownloadEquity = () => {
    setAnchorEl(null);
    setDownloadModal(true);
  };

  const setDownload = async (downloadType: DownloadType) => {
    try {
      setDownloading(true);
      const { data } = await client.query({
        query: downloadType === 'schedule' ? DownloadSchedules : DownloadEquityReport,
        variables: {
          departmentId: department_id,
          startDate: startDateISOstring,
          endDate: endDateISOstring,
          month: moment(startDateISOstring).local().format('MMMM').toLowerCase(),
          year: moment(startDateISOstring).local().year(),
        },
        fetchPolicy: 'no-cache',
      });

      AnalyticsManager.applyAnalytics({
        eventName: downloadType ? EVENTS.downloadSchedule : EVENTS.downloadEquityReportButtonPressed,
        params: {
          department_id: department_id,
          start_date: startDateISOstring,
          end_date: endDateISOstring,
        },
      });

      const { fileName, mimeType, blob } =
        downloadType === 'schedule'
          ? data.locating.department.schedule.downloadFile
          : data.locating.department.equityReport;

      const byteChars = atob(blob);
      const byteNumbers = new Array(byteChars.length);
      for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blobObj = new Blob([byteArray], { type: mimeType });

      const fileUrl = window.URL.createObjectURL(blobObj);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = fileUrl;
      anchor.click();
      setDownloading(false);
      // destroy
      anchor.remove();
      window.URL.revokeObjectURL(fileUrl);
    } catch (e) {
      console.error(e);
      setDownloading(false);
      toast.error(`Failed to download ${downloadType} report, please check your internet connection and try again`, {
        className: 'Toast-Container',
      });
    }
  };

  const handleMixpanelEvent = (eventName) => {
    AnalyticsManager.applyAnalytics({
      eventName: eventName,
    });
  };
  const handleOpenDisabledDownloadTooltip = () => {
    if (scheduleState === PUBLISHED) {
      return;
    }
    setIsToolTipOpen(true);
  };
  const handleCloseDisabledDownloadTooltip = () => {
    if (scheduleState === PUBLISHED) {
      return;
    }
    setIsToolTipOpen(false);
  };

  return (
    <React.Fragment>
      <SubHeaderSettingsWrapper>
        <SettingsStatusIndicator>
          <Button
            variant="outlined"
            disabled
            style={{
              fontWeight: 'bold',
              borderColor: scheduleState === DRAFT ? AppTheme.mainFontColor : AppTheme.mainGreenColor,
              color: scheduleState === DRAFT ? AppTheme.mainFontColor : AppTheme.mainGreenColor,
            }}
          >
            {scheduleState === DRAFT ? DRAFT : PUBLISHED}
          </Button>
          {(totalShiftConflictAndWarnings?.numberOfConflicts > 0 ||
            totalShiftConflictAndWarnings?.numberOfWarnings > 0) && (
            <ConflictAndWarningWrapper>
              {totalShiftConflictAndWarnings?.numberOfConflicts > 0 && (
                <div>
                  <AlertTriangle color={'#FA7A23'} />
                  <ScheduleText>
                    <StyledTooltip
                      arrow
                      title={
                        'Conflicts are identified when a provider has two shifts occurring at the same time. This conflict icon will show up on the top right corner of the affected shift(s).'
                      }
                    >
                      <StyledTooltipWrapper>
                        {totalShiftConflictAndWarnings?.numberOfConflicts} schedule conflicts
                      </StyledTooltipWrapper>
                    </StyledTooltip>
                  </ScheduleText>
                </div>
              )}
              {totalShiftConflictAndWarnings?.numberOfWarnings > 0 && (
                <div style={{ marginLeft: 16 }}>
                  <WarningIcon />
                  <ScheduleText>
                    <StyledTooltip
                      arrow
                      title={
                        'Warnings are identified when a provider has two shifts occurring on the same calendar day. This warning icon will show up on the top right corner of the affected shift(s).'
                      }
                    >
                      <StyledTooltipWrapper>
                        {totalShiftConflictAndWarnings?.numberOfWarnings} schedule warnings
                      </StyledTooltipWrapper>
                    </StyledTooltip>
                  </ScheduleText>
                </div>
              )}
            </ConflictAndWarningWrapper>
          )}
        </SettingsStatusIndicator>

        <SettingsButtonHolder>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {
              <StyledTooltip
                title={DISABLED_DOWNLOAD_TOOLTIP_TEXT}
                placement="bottom"
                open={isToolTipOpen}
                onMouseEnter={() => handleOpenDisabledDownloadTooltip()}
                onMouseLeave={() => handleCloseDisabledDownloadTooltip()}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
              >
                <div>
                  <Button
                    variant="outlined"
                    disabled={isDownloading || scheduleState === DRAFT}
                    onClick={handleDownloadClick}
                  >
                    {isDownloading ? (
                      <React.Fragment>
                        <CircularProgress size={14} color={'inherit'} />
                        <span className="btn-label">{DOWNLOADING}</span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <DownloadIcon />
                        <span className="btn-label">{DOWNLOAD}</span>
                      </React.Fragment>
                    )}
                  </Button>
                </div>
              </StyledTooltip>
            }
            <StyledPopover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <PopoverContent onClick={handleDownloadSchedule}>Download schedule</PopoverContent>
              <PopoverContent onClick={handleDownloadEquity}>Download equity report</PopoverContent>
            </StyledPopover>

            <Button
              variant="outlined"
              onClick={() => {
                setInputScheduleModal(true);
                handleMixpanelEvent(EVENTS.inputScheduleButtonPressed);
              }}
            >
              <CalendarIcon />
              <span className="btn-label">Input Schedule</span>
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                setCreateRoleModal(true);
                handleMixpanelEvent(EVENTS.createNewRoleButtonPressed);
              }}
            >
              <PlusUserIcon />
              <span className="btn-label">Create New Role</span>
            </Button>
          </ButtonGroup>

          {Boolean(scheduleState) && scheduleState === DRAFT && (
            <Button
              style={{
                backgroundColor: AppTheme.mainButtonColor,
                color: 'white',
                marginLeft: '8px',
                minWidth: '180px',
              }}
              onClick={() => {
                setPublishScheduleModal(true);
                handleMixpanelEvent(EVENTS.publishAndNotifyButtonPressed);
              }}
            >
              Publish & Notify
            </Button>
          )}
        </SettingsButtonHolder>
      </SubHeaderSettingsWrapper>

      <SubHeaderSavingIndicator />
      <Divider />

      <InputScheduleBtnPortal onBtnClick={() => setInputScheduleModal(true)} />
      {showDownloadModal && (
        <ScheduleDownloadModal
          showModal={showDownloadModal}
          handleCloseModal={() => setDownloadModal(false)}
          siteId={null}
          siteName={null}
          departmentIds={null}
          prefillStartDate={null}
          prefillendDate={null}
        />
      )}

      {showCreateRoleModal && (
        <ScheduleCreateRoleModal
          showModal={showCreateRoleModal}
          editRoleData={null}
          handleOpenDeleteRoleModal={null}
          handleCloseModal={() => setCreateRoleModal(false)}
        />
      )}
      {showInputScheduleModal && (
        <ScheduleInputModal
          payload={null}
          hasCalendar={true}
          showModal={showInputScheduleModal}
          handleCloseModal={() => setInputScheduleModal(false)}
        />
      )}
      {showPublishScheduleModal && (
        <SchedulePublishModal
          scheduleId={scheduleId}
          showModal={showPublishScheduleModal}
          handleCloseModal={() => setPublishScheduleModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default SubHeaderSettings;
