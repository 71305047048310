import gql from 'graphql-tag';
import ShiftFragment from 'src/gql/fragment/ShiftFragment';

export default gql`
  mutation CreateShift(
    $roleId: Int!
    $userId: ID!
    $startTime: String!
    $endTime: String!
    $departmentId: Int!
    $scheduleId: Int!
  ) {
    admin {
      locating {
        department(id: $departmentId) {
          schedule(id: $scheduleId) {
            createShift(
              roleId: $roleId
              details: { userId: $userId, startTime: $startTime, endTime: $endTime } #CreateShiftDetails
            ) {
              ...ShiftFragment
            }
          }
        }
      }
    }
  }
  ${ShiftFragment}
`;
