import { LAST_ACTIVE_TIME } from 'src/constants/sessionTimeoutTypes';
import { Login } from 'src/types';
import {
  DEFAULT_ERROR,
  LOGIN_ERROR_CODE_INVALID_PASSWORD,
  LOGIN_ERROR_MESSAGE_INVALID_PASSWORD,
  NETWORK_ERROR,
  LOGIN_ERROR_CODE_INVALID_USER,
  LOGIN_ERROR_MESSAGE_INVALID_USER,
  LOGIN_ERROR_CODE_ACCOUNT_LOCKED,
  LOGIN_ERROR_MESSAGE_ACCOUNT_LOCKED,
  LOGIN_ERROR_CODE_INVALID_CHALLENGE,
  LOGIN_ERROR_MESSAGE_INVALID_CHALLENGE,
} from 'src/constants/networkError';

export interface HypercareLoginPasswordViewModelProps {
  login: Login;
}

const HypercareLoginPasswordViewModel = ({ login }: HypercareLoginPasswordViewModelProps) => {
  const handleNextButtonPressed = async (username: string, password: string) => {
    window.localStorage.removeItem(LAST_ACTIVE_TIME);
    const result = await login(username, password);
    const error = result.error?.code ?? result.error;
    if (error) {
      switch (error) {
        case LOGIN_ERROR_CODE_INVALID_PASSWORD:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_INVALID_PASSWORD,
              code: LOGIN_ERROR_CODE_INVALID_PASSWORD,
            },
          };
        case LOGIN_ERROR_CODE_INVALID_USER:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_INVALID_USER,
              code: LOGIN_ERROR_CODE_INVALID_USER,
            },
          };
        case LOGIN_ERROR_CODE_ACCOUNT_LOCKED:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_ACCOUNT_LOCKED,
              code: LOGIN_ERROR_CODE_ACCOUNT_LOCKED,
            },
          };
        case LOGIN_ERROR_CODE_INVALID_CHALLENGE:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_INVALID_CHALLENGE,
              code: LOGIN_ERROR_CODE_INVALID_CHALLENGE,
            },
          };
        default:
          console.log(error);
          return {
            error: {
              message: NETWORK_ERROR,
              code: DEFAULT_ERROR,
            },
          };
      }
    }
  };

  return { handleNextButtonPressed };
};

export default HypercareLoginPasswordViewModel;
