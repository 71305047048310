import React from 'react';
import client from 'src/clients/apolloClient';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerSingleDateController, CalendarDay } from 'react-dates';
import store from 'src/redux/store';
import moment from 'moment';
import { REDUCER_DATE_FORMAT } from 'src/constants/scheduler';
import RoleFragment from 'src/gql/fragment/RoleFragment';
import generateBlockDates from 'src/utils/schedulingHelper/generateBlockDates';

// Doc: Calendar component with react-dates
// https://github.com/airbnb/react-dates
// https://github.com/airbnb/react-dates/blob/master/src/components/CalendarDay.jsx
// https://github.com/airbnb/react-dates/issues/190
// isDayBlocked
// isDayHighlighted

// Optional: swap to faltpicker: rendering performance issue renderCalendarDay and bundle size
// if https://github.com/airbnb/react-dates/pull/1654 not get resolved the issue
// https://flatpickr.js.org/

interface WrapperProps {
  roleIndex: number;
  setFieldValue: any;
  startTime: moment.Moment | null;
  endTime: moment.Moment | null;
}

interface PickerProps extends WrapperProps {
  blockFunction: (day?) => boolean;
  selectedDates: any[];
  roleIndex: number;
  isRoleExisting: boolean;
  currentMonth: moment.Moment;
}

// TODO: give user the option to switch single-day picker or range-day picker
const MultiDatePicker = (props) => {
  const { blockFunction, isRoleExisting, setFieldValue, roleIndex, currentMonth } = props as PickerProps;

  const [selectedDates, setSelectedDatesState] = React.useState([]);

  React.useEffect(() => {
    const filteredNewDates = selectedDates.filter((date) => !blockFunction(date));
    setSelectedDatesState(filteredNewDates);
    setFieldValue('selectedDates', filteredNewDates, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleIndex]);

  function handleChange(date: moment.Moment) {
    const newDates = selectedDates.some((d) => d.isSame(date))
      ? selectedDates.filter((d) => !date.isSame(d))
      : [...selectedDates, date];

    const filteredNewDates = isRoleExisting ? newDates.filter((date) => !blockFunction(date)) : newDates;

    setSelectedDatesState(filteredNewDates);
    setFieldValue('selectedDates', filteredNewDates, true);
  }

  return (
    <DayPickerSingleDateController
      numberOfMonths={1}
      focused={true}
      // isOutsideRange={(day) => day.isBefore(moment(), 'day')}
      onDateChange={handleChange}
      initialVisibleMonth={() => currentMonth}
      isDayBlocked={blockFunction}
      isDayHighlighted={() => null}
      transitionDuration={0}
      daySize={50}
      noBorder
      hideKeyboardShortcutsPanel={true}
      navPrev={<div />}
      navNext={<div />}
      renderCalendarDay={(props) => {
        const { day, modifiers } = props;
        if (modifiers) {
          if (selectedDates.some((d) => d.isSame(day))) {
            modifiers.add('selected');
          } else {
            modifiers.delete('selected');
          }
        }
        return <CalendarDay {...props} modifiers={new Set(modifiers)} />;
      }}
      renderCalendarInfo={() => <ExtraCalendarInfo />}
    />
  );
};

const RenderMultiDatePicker = React.memo(MultiDatePicker);

class ExtraCalendarInfo extends React.PureComponent {
  render() {
    return (
      <div className="calendar__extraInfo">
        <div className="calendar__extraInfo__selectedDate">
          <span>31</span>Selected Dates
        </div>
        <div className="calendar__extraInfo__unavailableDate">
          <span>31</span> Unavailable
        </div>
        <div className="calendar__extraInfo__availableDate">
          <span>31</span> Available
        </div>
      </div>
    );
  }
}

const MultiDatePickerWrapper = (props: WrapperProps) => {
  const { roleContainer, monthlyCalendar, startDateISOstring, endDateISOstring } =
    store.getState().monthlyScheduleReducer;

  const { roleIndex, startTime, endTime } = props;

  const isRoleExisting = Boolean(roleContainer[roleIndex]);

  let blockedDates: Set<number>;
  if (isRoleExisting) {
    const { roleId } = roleContainer[roleIndex];
    const cachedRole = client.readFragment({
      id: `Role:${roleId}`,
      fragment: RoleFragment,
      variables: {
        endDate: endDateISOstring,
        startDate: startDateISOstring,
      },
      fragmentName: 'RoleFragment',
    });
    const { shifts } = cachedRole;

    blockedDates = generateBlockDates({
      startTime,
      endTime,
      cachedShifts: shifts,
    });
  }

  const blockByUser = (day: moment.Moment) => {
    let dateFormat = day.format(REDUCER_DATE_FORMAT);
    if (
      dateFormat in monthlyCalendar &&
      monthlyCalendar[dateFormat][roleIndex] &&
      monthlyCalendar[dateFormat][roleIndex].assignee.length > 0 &&
      startTime &&
      startTime.isValid() &&
      endTime &&
      endTime.isValid()
    ) {
      return blockedDates.has(day.date());
    }
    return false;
  };

  const blockweekend = (day: moment.Moment) => day.weekday() === 6 || day.weekday() === 0 || blockByUser(day);

  const blockweekday = (day: moment.Moment) => (day.weekday() !== 6 && day.weekday() !== 0) || blockByUser(day);

  const blockFunction = (day?: moment.Moment) => {
    if (isRoleExisting) {
      const { repeatRule } = roleContainer[roleIndex];
      if (repeatRule === 'weekdays') return blockweekend(day);
      if (repeatRule === 'weekends') return blockweekday(day);
      return blockByUser(day);
    }
    return false;
  };

  // no timezone diff greater than 2 days
  const currentScheduleMonth = moment(startDateISOstring).add(2, 'days').local();

  return (
    <RenderMultiDatePicker
      {...props}
      blockFunction={blockFunction}
      roleIndex={roleIndex}
      currentMonth={currentScheduleMonth}
      isRoleExisting={isRoleExisting}
    />
  );
};

export default React.memo(MultiDatePickerWrapper);
