import {
  SET_ESCALATION_LADDER,
  SET_LEVEL_POLICY,
  SET_LEVEL_ONCALL,
  DELETE_LEVEL_POLICY,
  DELETE_LEVEL_ONCALL,
  DELETE_ESCALATION_LEVEL,
  CLEAR_ESCALATION_REDUCER,
  CHANGE_ESCALATION_STATE,
} from 'src/constants/actionNames';
import {
  EscalationLevelPolicyActionPayload,
  EscalationLevelOnCallActionPayload,
  EscalationLadderStateType,
} from 'src/types';

interface SetEscalationLadderAction {
  type: typeof SET_ESCALATION_LADDER;
}

interface ChangeEscalationStateAction {
  type: typeof CHANGE_ESCALATION_STATE;
  state: EscalationLadderStateType;
}

interface ClearEscalationLadderAction {
  type: typeof CLEAR_ESCALATION_REDUCER;
}
interface SetEscalationLevelPolicyAction {
  type: typeof SET_LEVEL_POLICY;
  payload: EscalationLevelPolicyActionPayload;
}

interface SetEscalationLevelOnCallAction {
  type: typeof SET_LEVEL_ONCALL;
  payload: EscalationLevelOnCallActionPayload;
}

interface DeleteEscalationLevelPolicyAction {
  type: typeof DELETE_LEVEL_POLICY;
  payload: EscalationLevelPolicyActionPayload;
}

interface DeleteEscalationLevelOncallAction {
  type: typeof DELETE_LEVEL_ONCALL;
  payload: EscalationLevelOnCallActionPayload;
}

interface DeleteEscalationLevelAction {
  type: typeof DELETE_ESCALATION_LEVEL;
  payload: { index: number };
}

export type EscalationActionTypes =
  | SetEscalationLadderAction
  | SetEscalationLevelPolicyAction
  | SetEscalationLevelOnCallAction
  | DeleteEscalationLevelPolicyAction
  | DeleteEscalationLevelOncallAction
  | DeleteEscalationLevelAction
  | ClearEscalationLadderAction
  | ChangeEscalationStateAction;

export const setEscalationLadder = (): SetEscalationLadderAction => {
  return {
    type: SET_ESCALATION_LADDER,
  };
};

export const setEscalationLevelPolicy = (
  payload: EscalationLevelPolicyActionPayload,
): SetEscalationLevelPolicyAction => {
  return {
    type: SET_LEVEL_POLICY,
    payload,
  };
};

export const setEscalationLevelOnCall = (
  payload: EscalationLevelOnCallActionPayload,
): SetEscalationLevelOnCallAction => {
  return {
    type: SET_LEVEL_ONCALL,
    payload,
  };
};

export const deleteEscalationLevelPolicy = (
  payload: EscalationLevelPolicyActionPayload,
): DeleteEscalationLevelPolicyAction => {
  return {
    type: DELETE_LEVEL_POLICY,
    payload,
  };
};

export const deleteEscalationLevelOnCall = (
  payload: EscalationLevelOnCallActionPayload,
): DeleteEscalationLevelOncallAction => {
  return {
    type: DELETE_LEVEL_ONCALL,
    payload,
  };
};

export const deleteEscalationLevel = (index: number): DeleteEscalationLevelAction => {
  return {
    type: DELETE_ESCALATION_LEVEL,
    payload: { index },
  };
};

export const clearEscalationLadder = (): ClearEscalationLadderAction => {
  return {
    type: CLEAR_ESCALATION_REDUCER,
  };
};

export const changeEscalationState = (state: EscalationLadderStateType): ChangeEscalationStateAction => {
  return {
    type: CHANGE_ESCALATION_STATE,
    state,
  };
};
