import React from 'react';
import { SITE } from 'src/constants/organizationTypes';
import RenderDepartments from 'src/components/popup-dropdowns/dropdown-partials/RenderDepartments';
import CheckIcon from 'src/assets/svgs/CheckIcon';
import { OrganizationScope, UserOrganizationSwitcherPayload, SiteScope } from 'src/types';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  organization: OrganizationScope;
  currentOrganization: UserOrganizationSwitcherPayload;
  handleChange: (option: UserOrganizationSwitcherPayload, index: number) => void;
  isReadOnly: boolean;
}

const RenderSites = ({ organization, currentOrganization, handleChange, isReadOnly }: Props) => {
  const isSomeChildSelected = (site: SiteScope): boolean => {
    if (isReadOnly) return false;
    return Boolean(site.departments.find((department) => department.isSelected));
  };

  return (
    <React.Fragment>
      {organization?.sites
        ?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        .map((site, j) => (
          <React.Fragment key={`scope-${organization.id}-${site.id}-${j}`}>
            {site.isAdmin && (
              <li
                title={`${site.id}`}
                className={`dropdownOrganizationMenu__item dropdownOrganizationMenu__item--site ${
                  site.departments.length > 0 ? 'dropdownOrganizationMenu__item--siteBorder' : ''
                }`}
                key={`${organization.id}-${site.id}-${j}`}
                onClick={() =>
                  handleChange(
                    {
                      name: site.name,
                      type: SITE,
                      site_id: site.id,
                      image: site.image,
                      organization_id: organization.id,
                      department_id: null,
                      organization_name: organization.name,
                    },
                    j,
                  )
                }
              >
                {!isReadOnly && (
                  <Checkbox
                    color="primary"
                    indeterminate={!site.isAllSelected && site.departments.length > 0 && isSomeChildSelected(site)}
                    checked={Boolean(site.isAllSelected) || (site.departments.length > 0 && isSomeChildSelected(site))}
                  />
                )}
                {isReadOnly &&
                currentOrganization.site_id === site.id &&
                currentOrganization.type === SITE &&
                currentOrganization.organization_id === organization.id ? (
                  <React.Fragment>
                    <strong>{site.name}</strong>
                    <CheckIcon />
                  </React.Fragment>
                ) : (
                  site.name
                )}
              </li>
            )}
            <RenderDepartments
              isReadOnly={isReadOnly}
              organization={organization}
              site={site}
              handleChange={handleChange}
              currentOrganization={currentOrganization}
            />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default RenderSites;
