import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { QueryResult } from 'react-apollo';
import * as ReactModal from 'react-modal';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import InviteUserActionContainer from 'src/components/modals/invite-user/InviteUserActionContainer';
import {
  UserOrganizationSwitcherPayload,
  UserMenuOptionStatus,
  GetAllLicensingUsersResult,
  GetPendingUsersResult,
} from 'src/types';
import { RootState } from 'src/redux/store';
import { FetchPaginatedUsersQueryResponse } from '../../gql/v2/query/FetchPaginatedUsersQuery';

interface UserModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
  onAddUserSuccess: () => void;
  userFullNameLists: string[];
  currentOrganization: UserOrganizationSwitcherPayload;
  setMenuOptions: React.Dispatch<React.SetStateAction<UserMenuOptionStatus>>;
  userRecords: QueryResult<FetchPaginatedUsersQueryResponse | GetAllLicensingUsersResult>;
  pendingRecords?: QueryResult<GetPendingUsersResult | FetchPaginatedUsersQueryResponse>;
}

const UserModal = (props: UserModalProps) => {
  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.inviteExistingMemberView);
  }, []);

  const {
    showModal,
    handleCloseModal,
    currentOrganization,
    userFullNameLists,
    setMenuOptions,
    userRecords,
    pendingRecords,
    onAddUserSuccess,
  } = props;

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__inviteUser"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleCloseModal}
    >
      <div className="modal__header">
        Add users to <span>{currentOrganization.name || 'your organization'}</span>
        <div className="close" onClick={handleCloseModal} />
      </div>

      <InviteUserActionContainer
        closeModal={handleCloseModal}
        userFullNameLists={userFullNameLists}
        currentOrganization={currentOrganization}
        setMenuOptions={setMenuOptions}
        userRecords={userRecords}
        pendingRecords={pendingRecords}
        onAddUserSuccess={onAddUserSuccess}
      />
    </ReactModal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    currentOrganization: state.organizationReducer,
  };
};

export default connect(mapStateToProps)(UserModal);
