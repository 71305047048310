import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  body: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
  },
  undo: {
    marginRight: '30px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: '#FFFFFF',
  },
}));

const CustomToaster = (props) => {
  const classes = useStyles();
  const { logo, title, body, isVisibleUndo, handleUndo } = props;

  const handleClick = () => {
    handleUndo();
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={logo ? 3 : 2} alignItems="center">
        {logo && <Grid item>{logo}</Grid>}
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              {title && (
                <Typography gutterBottom variant="subtitle1" className={classes.title}>
                  {title}
                </Typography>
              )}
              <Typography variant="body2" gutterBottom className={classes.body}>
                {body}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {isVisibleUndo && (
          <Grid item>
            <Typography variant="body2" gutterBottom className={classes.undo} onClick={handleClick}>
              Undo
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CustomToaster;
