import React from 'react';
import * as ReactModal from 'react-modal';
import { AuthContext } from 'src/auth/AuthProvider';
import ApiHelper from 'src/api';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import trySwitchOrganization from 'src/utils/trySwitchOrganization';
import { UserOrganizationSwitcherPayload } from 'src/types';
import styled from 'styled-components';
import { PREVIOUS_ORGANIZATION } from 'src/constants/storageKeys';
import { AGREE, DISAGREE, SENDING_REQUEST, TOS_MODAL_BODY_TEXT, TOS_MODAL_HEADER_TEXT } from 'src/constants/strings';

const EulaLabelHolder = styled.span`
  a {
    padding-left: 5px;
    font-weight: bold;
    color: inherit;
  }
`;

interface Props {
  tosURL: string;
  isOpen: boolean;
  currentOrganization: UserOrganizationSwitcherPayload;
  handleCloseModal: () => void;
  logout: () => void;
}

const AcceptTOSModal = ({ tosURL, isOpen, currentOrganization, handleCloseModal, logout }: Props) => {
  const [isAgreementChecked, setAgreementChecked] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  React.useEffect(() => {
    document.body.className += ' blured';
    return () => {
      document.body.classList.remove('blured');
      sessionStorage.removeItem(PREVIOUS_ORGANIZATION);
    };
  }, []);

  async function onAgreementSubmit() {
    setLoading(true);
    setErrorMsg('');
    const { organization_id } = currentOrganization;
    const result = await ApiHelper.acceptingTOS(organization_id);
    if (result.success) {
      handleCloseModal();
    } else {
      setLoading(false);
      setErrorMsg('Failed to accept terms of service, please check your internet connection and try again');
    }
  }

  function onCancelAgreement() {
    const previousOrganization = sessionStorage.getItem(PREVIOUS_ORGANIZATION);

    if (
      Boolean(previousOrganization) &&
      currentOrganization.organization_id !== JSON.parse(previousOrganization).organization_id
    ) {
      trySwitchOrganization(JSON.parse(previousOrganization) as UserOrganizationSwitcherPayload);
    } else {
      if (window.confirm('You will be logged out, are you sure?')) {
        sessionStorage.removeItem(PREVIOUS_ORGANIZATION);
        logout();
      }
    }
  }

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__TOS"
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleCloseModal}
    >
      <div className="modal__header">{TOS_MODAL_HEADER_TEXT}</div>
      <div className="tosDescription">
        <div className="tosDescription__actions">
          <FormControlLabel
            control={
              <Checkbox
                id="acceptTOS"
                name="acceptTOS"
                checked={isAgreementChecked}
                onChange={() => setAgreementChecked((prevState) => !prevState)}
              />
            }
            disabled={isLoading}
            label={
              <EulaLabelHolder>
                {' '}
                I agree to these
                <a href={tosURL} rel="noopener noreferrer" target="_blank">
                  terms and conditions
                </a>
              </EulaLabelHolder>
            }
          />
        </div>
      </div>
      <p>{TOS_MODAL_BODY_TEXT}</p>
      <div className="modal__confirmation__buttons">
        <Button type="button" disabled={isLoading} variant="outlined" disableTouchRipple onClick={onCancelAgreement}>
          {DISAGREE}
        </Button>
        <Button
          type="submit"
          disabled={isLoading || !isAgreementChecked}
          variant="contained"
          color="secondary"
          onClick={onAgreementSubmit}
          disableTouchRipple
        >
          {isLoading ? SENDING_REQUEST : AGREE}
        </Button>
      </div>
      {Boolean(errorMsg) && <div className="tosErrorMsg">{errorMsg}</div>}
    </ReactModal>
  );
};

export default (props) => (
  <AuthContext.Consumer>{({ logout }) => <AcceptTOSModal {...props} logout={logout} />}</AuthContext.Consumer>
);
