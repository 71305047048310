import {
  OFF_WAITING_SWITCH_ORGANIZATION,
  SET_WAITING_SWITCH_ORGANIZATION,
  SORT_LICENSING_STATUS,
  SORT_JOIN_DATE,
  SORT_LASTNAME,
  ORGANIZATION_DROPDOWN,
} from 'src/constants/actionNames';

interface SortLastNameAction {
  type: typeof SORT_LASTNAME;
}

interface SortJoinDateAction {
  type: typeof SORT_JOIN_DATE;
}

interface SortLicensingStatusAction {
  type: typeof SORT_LICENSING_STATUS;
}
interface SetWaitingSwitchOrganizationAction {
  type: typeof SET_WAITING_SWITCH_ORGANIZATION;
}

interface OffWaitingSwitchOrganizationAction {
  type: typeof OFF_WAITING_SWITCH_ORGANIZATION;
}

interface organizationDropDownAction {
  type: typeof ORGANIZATION_DROPDOWN;
  isOpen: boolean;
}
export type FlagActionTypes =
  | SortJoinDateAction
  | SortLastNameAction
  | SortLicensingStatusAction
  | SetWaitingSwitchOrganizationAction
  | OffWaitingSwitchOrganizationAction
  | organizationDropDownAction;

export const sortLastName = (): SortLastNameAction => {
  return {
    type: SORT_LASTNAME,
  };
};

export const sortLicensingStatus = (): SortLicensingStatusAction => {
  return {
    type: SORT_LICENSING_STATUS,
  };
};

export const sortJoinDate = (): SortJoinDateAction => {
  return {
    type: SORT_JOIN_DATE,
  };
};

export const setWaitingSwitchOrganization = (): SetWaitingSwitchOrganizationAction => {
  return {
    type: SET_WAITING_SWITCH_ORGANIZATION,
  };
};

export const offWaitingSwitchOrganization = (): OffWaitingSwitchOrganizationAction => {
  return {
    type: OFF_WAITING_SWITCH_ORGANIZATION,
  };
};

export function organizationDropDownAction(bool) {
  return {
    type: 'ORGANIZATION_DROPDOWN',
    isOpen: bool,
  };
}
