import React from 'react';
import store from 'src/redux/store';
import Select from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import OverriddenSelectComponents, { useStyles } from 'src/components/shared/ReactSelectMaterialUI';
import { FieldInputLabel } from 'src/components/shared/HypercareComponents';

const ScheduleModalInputSelect = ({
  isSingleUserForm,
  roleNameError,
  setFieldValue,
  prefillRole,
  onNewRoleSelection,
}) => {
  const classes = useStyles({});
  const theme = useTheme();
  const [role, setRole] = React.useState(null);

  const state = store.getState();

  const suggestions = state.monthlyScheduleReducer.roleContainer.map((role) => ({
    value: role.index,
    label:
      role.roleName +
      ` 
        (${role.startTime.format ? role.startTime.format('HH:mm') : role.startTime}
        - ${role.endTime.format ? role.endTime.format('HH:mm') : role.endTime})
      `,
    startTime: role.startTime,
    endTime: role.endTime,
  }));

  function handleChangeRole(value) {
    setRole(value);
    setFieldValue('roleName', value ? value.label : '', true);
    setFieldValue('roleIndex', value ? value.value : null, false);

    if (isSingleUserForm) {
      const { startTime, endTime } = value;
      // const roleStartTime = typeof (startTime) === 'string'
      //   ? moment(new Date(`${ARBITRARY_DATE}, ${startTime}`))
      //   : startTime
      // const roleEndTime = typeof (endTime) === 'string'
      //   ? moment(new Date(`${ARBITRARY_DATE}, ${endTime}`))
      //   : endTime

      onNewRoleSelection(startTime, endTime);
      setFieldValue('startTime', startTime);
      setFieldValue('endTime', endTime);
    }
  }

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div className={classes.root}>
      <FieldInputLabel>Role</FieldInputLabel>
      <Select
        classes={classes}
        styles={selectStyles}
        inputId="roleName"
        TextFieldProps={{
          name: 'roleName',
          InputLabelProps: {
            htmlFor: 'roleName',
          },
          FormHelperTextProps: {
            classes: { contained: classes.helperText },
          },
          variant: 'outlined',
          helperText: roleNameError || '',
          error: Boolean(roleNameError),
        }}
        autoFocus={isSingleUserForm}
        options={suggestions}
        components={OverriddenSelectComponents}
        isDisabled={!isSingleUserForm}
        value={
          role
            ? role
            : prefillRole
            ? {
                label:
                  prefillRole.roleName +
                  ` (
                ${prefillRole.startTime.format ? prefillRole.startTime.format('HH:mm') : prefillRole.startTime}-${
                    prefillRole.endTime.format ? prefillRole.endTime.format('HH:mm') : prefillRole.endTime
                  }
                )`,
                value: prefillRole.index,
              }
            : null
        }
        placeholder={'Search a role...'}
        menuPortalTarget={document.body}
        onChange={handleChangeRole}
      />
    </div>
  );
};

export default ScheduleModalInputSelect;
