import {
  AppBar,
  Box,
  Button,
  Container,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { DateRange } from 'react-date-range';
import moment from 'moment';

import HighlightTabPanel from 'src/pages/AnalyticsDemoPage/highlights.tab';
import MemberTabPanel from 'src/pages/AnalyticsDemoPage/members.tab';
import ReportTabPanel from 'src/pages/AnalyticsDemoPage/report.tab';
// import DepartmentTabPanel from 'src/pages/AnalyticsDemoPage/department.tab';
import { toast } from 'react-toastify';

import { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { IDateRange } from 'src/types';
import ExportButton from 'src/pages/AnalyticsDemoPage/ExportButton';
import RefreshButton from 'src/pages/AnalyticsDemoPage/RefreshButton';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../utils/FeatureFlags';
import { MessagesReportTemplateContainer } from './AdminReports/MessagesReportTemplateContainer';
import {
  MEMBER_MESSAGES_REPORT_DESCRIPTION,
  MEMBER_MESSAGES_REPORT_EXPORT_BUTTON_TEXT,
  MEMBER_MESSAGES_REPORT_TITLE,
} from '../../constants/strings';
import { AnalyticsViewModel } from './viewModels/AnalyticsViewModel';
import { EXPORT_MEMBER_REPORT_ERROR } from '../../constants/networkError';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00859A',
    },
    secondary: {
      main: '#FFC705',
    },
  },
  typography: {
    fontFamily: 'Nunito',
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

type DateRangeType = '7 Days' | '30 days' | 'Today' | 'Yesterday';

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...rest } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box paddingTop={2} paddingBottom={2}>
          {children}
        </Box>
      )}
    </div>
  );
};

const style = makeStyles((theme: Theme) => ({
  selectedTab: {
    backgroundColor: 'white',
    fontWeight: 700,
  },
  container: {
    maxWidth: 'unset !important',
    paddingLeft: '5%',
    paddingRight: '2.5%',
  },
}));

const AnalyticsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const dateRange = typedUseSelector((state) => state.analyticsReducer.dateRange);
  const organizationId = typedUseSelector((state) => state.organizationReducer.organization_id);
  const departmentId = typedUseSelector((state) => state.organizationReducer.department_id);
  const siteId = typedUseSelector((state) => state.organizationReducer.site_id);
  const theme = useTheme();
  const classes = style();
  const [dateRangeMenuEl, setDateRangeMenuEl] = React.useState<null | HTMLElement>(null);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);

  const templateMessageReportFlag = IsFeatureFlagEnabled(FeatureFlagResult.templateMessageReport);
  const newMemberAnalyticsUIFlag = IsFeatureFlagEnabled(FeatureFlagResult.newMemberAnalyticsUi);

  const { handleGenerateMemberCSVFile } = AnalyticsViewModel();

  const dispatch = useDispatch();
  const setDateRange = (dateRange: IDateRange[]) =>
    dispatch(allActions.analyticsAction.updateDateRangeAction(dateRange));
  const [internalDateRange, setInternalDateRange] = useState<IDateRange[]>([
    {
      startDate: moment().subtract(7, 'day').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDateRangeElClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDateRangeMenuEl(event.currentTarget);
  };

  const handleDateRangeElClose = () => {
    setDateRangeMenuEl(null);
  };

  const selectDefaultRange = (dateRangeType: DateRangeType) => {
    let datesRange = {
      startDate: null,
      endDate: null,
      key: 'selection',
    };
    switch (dateRangeType) {
      case '7 Days':
        datesRange.startDate = moment().subtract(7, 'day').toDate();
        datesRange.endDate = moment().endOf('day').toDate();
        break;
      case '30 days':
        datesRange.startDate = moment().subtract(30, 'day').toDate();
        datesRange.endDate = moment().endOf('day').toDate();
        break;
      case 'Today':
        datesRange.startDate = moment().startOf('day').toDate();
        datesRange.endDate = moment().endOf('day').toDate();
        break;
      case 'Yesterday':
        datesRange.startDate = moment().subtract(1, 'day').endOf('day').toDate();
        datesRange.endDate = moment().subtract(1, 'day').endOf('day').toDate();
        break;
    }
    setDateRange([datesRange]);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.fetchAnalyticReportByDate,
      params: {
        start_date: datesRange.startDate.toString(),
        end_date: datesRange.endDate.toString(),
      },
    });

    handleDateRangeClose();
  };

  const handleTabChange = (index: number) => {
    let tabEvent;
    switch (index) {
      case 0:
        tabEvent = EVENTS.highlightsTabPressed;
        break;
      case 1:
        tabEvent = EVENTS.membersTabPressed;
        break;
      case 2:
        tabEvent = EVENTS.reportsTabPressed;
        break;
    }

    setActiveTab(index);

    AnalyticsManager.applyAnalytics({
      eventName: tabEvent,
    });
  };

  const handleApplyClick = () => {
    setDateRangeMenuEl(null);
    setDateRangeVisible(false);
    setDateRange(internalDateRange);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.fetchAnalyticReportByDate,
      params: {
        start_date: internalDateRange[0]?.startDate.toString(),
        end_date: internalDateRange[0]?.endDate.toString(),
      },
    });
  };

  const handleDateRangeClose = () => {
    setDateRangeMenuEl(null);
    setDateRangeVisible(false);
    setInternalDateRange(dateRange);
  };

  const handleExportMembersReport = async () => {
    const res = await handleGenerateMemberCSVFile({
      startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
      organizationId,
      departmentId,
      siteId,
    });

    if (res.error) {
      toast.error(EXPORT_MEMBER_REPORT_ERROR, { toastId: 'exportMemberReportErrorToast' });
    }
  };

  return (
    <Container id="AnalyticsDemoPage" className={classes.container} style={{ marginTop: 16 }}>
      <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
        <Typography variant="h6" style={{ fontWeight: theme.typography.fontWeightBold }}>
          Analytics
        </Typography>
        <RefreshButton />
        <Box flexGrow="1" />
        {activeTab === 1 && !newMemberAnalyticsUIFlag && <ExportButton tabIndex={activeTab} />}
        <Box position="relative">
          <Button
            style={{ textTransform: 'none', marginLeft: 16, height: 47 }}
            disableElevation
            variant="contained"
            endIcon={dateRangeVisible ? <ExpandLess /> : <ExpandMore />}
            onClick={handleDateRangeElClick}
          >
            {moment(dateRange[0].startDate).format('MMM DD, YYYY')} -{' '}
            {moment(dateRange[0].endDate).format('MMM DD, YYYY')}
          </Button>
          <Menu anchorEl={dateRangeMenuEl} keepMounted open={Boolean(dateRangeMenuEl)} onClose={handleDateRangeElClose}>
            <MenuItem onClick={() => selectDefaultRange('7 Days')} style={{ minWidth: theme.spacing(30) }}>
              Last 7 days
            </MenuItem>
            <MenuItem onClick={() => selectDefaultRange('30 days')} style={{ minWidth: theme.spacing(30) }}>
              Last 30 days
            </MenuItem>
            <MenuItem onClick={() => selectDefaultRange('Today')} style={{ minWidth: theme.spacing(30) }}>
              Today
            </MenuItem>
            <MenuItem onClick={() => selectDefaultRange('Yesterday')} style={{ minWidth: theme.spacing(30) }}>
              Yesterday
            </MenuItem>
            <MenuItem onClick={() => setDateRangeVisible(true)} style={{ minWidth: theme.spacing(30) }}>
              Custom
            </MenuItem>
          </Menu>
          <Box
            position="absolute"
            left={-190}
            top={190}
            bgcolor={theme.palette.common.white}
            zIndex={10000}
            marginTop={1}
            boxShadow={1}
            hidden={!dateRangeVisible}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item: any) => setInternalDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={internalDateRange}
            />
            <Box justifyContent="flex-end" display="flex" paddingBottom={2} paddingRight={2}>
              <Button
                style={{ textTransform: 'none', paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }}
                disableElevation
                disableTouchRipple
                color="secondary"
                onClick={() => setDateRangeVisible(false)}
              >
                Cancel
              </Button>
              <Button
                style={{ textTransform: 'none', paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }}
                disableElevation
                disableTouchRipple
                color="primary"
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          position="absolute"
          left={0}
          right={0}
          top={0}
          bottom={0}
          zIndex={dateRangeVisible ? 9999 : -10000}
          hidden={!dateRangeVisible}
          onClick={handleDateRangeClose}
        />
      </Box>
      <AppBar
        position="static"
        style={{
          marginTop: 8,
          backgroundColor: 'rgb(242,242,242)',
          color: 'black',
          borderWidth: 1,
          borderColor: theme.palette.grey[300],
          borderStyle: 'solid',
          borderRadius: 4,
        }}
        elevation={0}
      >
        <Tabs
          value={activeTab}
          onChange={(_, index) => handleTabChange(index)}
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
        >
          <Tab
            label="Highlights"
            style={{ textTransform: 'none' }}
            classes={{ selected: classes.selectedTab }}
            disableTouchRipple
          />
          <Tab
            label="Members"
            style={{ textTransform: 'none' }}
            classes={{ selected: classes.selectedTab }}
            disableTouchRipple
          />

          {templateMessageReportFlag && (
            <Tab
              label="Reports"
              style={{ textTransform: 'none' }}
              classes={{ selected: classes.selectedTab }}
              disableTouchRipple
            />
          )}

          {/* <Tab
            label="Departments"
            style={{textTransform: 'none'}}
            classes={{selected: classes.selectedTab}}
            disableTouchRipple
          /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={activeTab} index={0}>
        <HighlightTabPanel />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {newMemberAnalyticsUIFlag ? (
          <MessagesReportTemplateContainer
            title={MEMBER_MESSAGES_REPORT_TITLE}
            description={MEMBER_MESSAGES_REPORT_DESCRIPTION}
            handleExportButtonClick={handleExportMembersReport}
            exportButtonText={MEMBER_MESSAGES_REPORT_EXPORT_BUTTON_TEXT}
          />
        ) : (
          <MemberTabPanel />
        )}
      </TabPanel>

      {templateMessageReportFlag && (
        <TabPanel value={activeTab} index={2}>
          <ReportTabPanel />
        </TabPanel>
      )}

      {/* <TabPanel value={activeTab} index={2}>
        <DepartmentTabPanel />
      </TabPanel> */}
    </Container>
  );
};

export default () => (
  <MuiThemeProvider theme={theme}>
    <AnalyticsPage />
  </MuiThemeProvider>
);
