import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { Checkbox } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${theme.borderBottomLight};
`;

export const TH = styled.th`
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.mainFontColor};

  margin: 0;
  background: #f2f2f2 !important;
  text-align: left;
  text-transform: uppercase;
`;

const TC = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: 0;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 20px;
  }
`;

const StyledTBody = styled.tbody`
  overflow: auto;
`;

const StyledTHead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
`;

const SearchResultLoader = () => {
  return (
    <div>
      <CustomTable>
        <StyledTHead>
          <tr>
            <TH>
              <Checkbox checked={false} disabled />
            </TH>
            <TH>Number</TH>
            <TH>Locality</TH>
          </tr>
        </StyledTHead>
        <StyledTBody>
          <>
            {[...Array(6)].map((_, index) => (
              <tr key={index}>
                <TC>
                  <Checkbox checked={false} disabled />
                </TC>
                <TC>
                  <ContentLoader height={40} width={286} style={{ padding: '0 5%', height: '100%', width: '100%' }}>
                    <rect y="19.75" width="112" height="8" rx="4" fill="#E4E5E7" />
                  </ContentLoader>
                </TC>
                <TC>
                  <ContentLoader height={40} width={286} style={{ padding: '0 5%', height: '100%', width: '100%' }}>
                    <rect y="19.75" width="193" height="8" rx="4" fill="#E4E5E7" />
                  </ContentLoader>
                </TC>
              </tr>
            ))}
          </>
        </StyledTBody>
      </CustomTable>
    </div>
  );
};

export default SearchResultLoader;
