import React from 'react';
import store from 'src/redux/store';
import { Query, QueryResult } from 'react-apollo';
import { GET_USERS } from 'src/gql/query/GetUsersQuery';
import { UserSelectOptionType, GetUsersResult } from 'src/types';
import MultiUsernameInputSelect from 'src/components/shared/MultiUsernameInputSelect';
import Loader from 'src/components/loaders/InputContentLoader';
import client from 'src/clients/apolloClient';
import { IsFeatureFlagEnabled } from '../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../utils/FeatureFlags';

const MultiUserInputDropdownContainer = ({ setFieldValue, userNameArrError }) => {
  const currentOrganization = store.getState().organizationReducer;
  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  const existingUsersQuery = !paginatedContactsFeatureFlag && client.readQuery({ query: GET_USERS });

  const onClickColleague = (selectedValue: UserSelectOptionType[]) => {
    if (!selectedValue || selectedValue.length === 0) {
      setFieldValue('username', []);
    } else {
      const userNameArray = selectedValue.map((value) => value.username);
      setFieldValue('username', userNameArray);
    }
  };

  return (
    <Query
      query={GET_USERS}
      fetchPolicy="cache-first"
      variables={{
        scope: {
          organizationId: currentOrganization.organization_id,
        },
      }}
    >
      {({ loading, error, data }: QueryResult<GetUsersResult>) => {
        if (error || loading || !data) return <Loader />;
        const colleagues = data?.admin.users;
        const existingcolleagues = existingUsersQuery?.admin?.users;

        const suggestions = !paginatedContactsFeatureFlag
          ? colleagues
              .filter((colleague) => {
                return !Boolean(
                  existingcolleagues.find((existingcolleague) => existingcolleague?.id === colleague?.id),
                );
              })
              .map((colleague) => {
                return {
                  value: colleague.id,
                  label: colleague.firstname + ' ' + colleague.lastname,
                  username: colleague.username,
                  role: colleague.role ? colleague.role : '',
                } satisfies UserSelectOptionType;
              })
          : colleagues.map((colleague) => {
              return {
                value: colleague.id,
                label: colleague.firstname + ' ' + colleague.lastname,
                username: colleague.username,
                role: colleague.role ? colleague.role : '',
              } satisfies UserSelectOptionType;
            });

        return (
          <MultiUsernameInputSelect
            userNameArrError={userNameArrError}
            onClickColleague={onClickColleague}
            suggestions={suggestions}
          />
        );
      }}
    </Query>
  );
};

export default React.memo(MultiUserInputDropdownContainer);
