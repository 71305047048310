import gql from 'graphql-tag';
import { BasicProfileFieldFragment } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type DeleteProfileFieldMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        profileField: {
          deleteField: {
            id: string;
          };
        };
      };
    };
  };
};

const DeleteProfileSectionFragment = gql`
  fragment DeleteProfileSectionFragment on ProfileFieldMutationResult {
    ... on Node {
      id
    }
    ... on ProfilePhoneField {
      ...BasicProfileFieldFragment
      phoneNumber
      placeholder
    }
    ... on ProfileTextField {
      ...BasicProfileFieldFragment
      text
      placeholder
    }
    ... on ProfileDropdownField {
      options
      selectedOptions
    }
    ... on ProfileNumericField {
      value
    }
    ... on ProfileLinkField {
      url
    }
    ... on ProfileEmailField {
      email
    }
    ... on ProfileDateField {
      date
    }
  }
`;

export const ADMIN_DELETE_PROFILE_FIELD_MUTATION = gql`
  mutation DeleteProfileFields($organizationalUnit: OrganizationalUnitInput!, $userId: ID!, $uniqueFieldId: ID!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              profileField(id: $uniqueFieldId) {
                deleteField {
                  ...DeleteProfileSectionFragment
                }
              }
            }
          }
          ... on AdminSiteMutation {
            member(id: $userId) {
              profileField(id: $uniqueFieldId) {
                deleteField {
                  ...DeleteProfileSectionFragment
                }
              }
            }
          }
          ... on AdminDepartmentMutation {
            member(id: $userId) {
              profileField(id: $uniqueFieldId) {
                deleteField {
                  ...DeleteProfileSectionFragment
                }
              }
            }
          }
        }
      }
    }
  }

  ${DeleteProfileSectionFragment}
  ${BasicProfileFieldFragment}
`;
