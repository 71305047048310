import React from 'react';
import moment from 'moment';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import UserAvatar from 'src/components/shared/UserAvatar';
import { getParsedAuthInfo } from 'src/utils/localStorageHelper';
import { Box } from '@material-ui/core';
import { PENDING_USER, PresentedUserLicensingStatus } from 'src/constants/inviteUserTypes';
import client from 'src/clients/apolloClient';
import { toast } from 'react-toastify';
import CustomToaster from 'src/components/CustomToaster';
import ResendInvite from 'src/gql/mutation/ResendInvite';
import {
  StyledButton,
  StyledMenu,
  StyledMenuItem,
  StyledStatus,
  UserListContainer,
  useStyles,
} from 'src/pages/HomePage/InviteStyle';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { getUserFullName } from 'src/utils/getUserFullName';
import { AppRoutes } from '../../../router/AppRoutes';
import { IsFeatureFlagEnabled } from '../../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../../utils/FeatureFlags';
import { PaginatedInvites } from '../../../types/PaginatedTypes';
import { usePaginatedDataState } from '../hooks/usePaginatedDataState';
import { checkOrganizationalUnit } from '../../../utils/getOrganizationalUnitObject';
import {
  FetchPaginatedInvitesQueryResponse,
  PaginatedPendingInvites,
} from '../../../gql/v2/query/FetchPaginatedInvitesQuery';
import InfiniteScroll from 'react-infinite-scroller';
import { HCHeadingFour } from '../../../components/shared/HypercareComponents';
import theme from '../../../assets/styles/theme';
import { END_OF_USER_LIST_MESSAGE } from '../../../constants/strings';
import { UserViewModel } from '../viewModels/UserViewModel';

interface Props {
  users: PaginatedInvites[];
  status: PresentedUserLicensingStatus;
  paginatedPendingUserInvitesQuery: FetchPaginatedInvitesQueryResponse;
  fetchMorePaginatedUserInvitesQuery: any;
  searchPendingInviteData: PaginatedPendingInvites;
  setSearchPendingInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvites>>;
  localSearchText: string;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

const PendingUsersList: React.FC<Props> = (props) => {
  const {
    users,
    status,
    paginatedPendingUserInvitesQuery,
    fetchMorePaginatedUserInvitesQuery,
    localSearchText,
    searchPendingInviteData,
    setSearchPendingInviteData,
    isDoneRows,
    setIsDoneRows,
  } = props;

  const classes = useStyles();
  const [selectUser, setSelectedUser] = React.useState(null);
  const [actionTypeEl, setActionTypeEl] = React.useState<null | HTMLElement>(null);
  const [activeAction, setActiveAction] = React.useState(null);
  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  const { setSeenContinuationIds, seenContinuationIds } = usePaginatedDataState();

  const { getSearchedPendingInvites } = UserViewModel();

  const getInvitedBy = React.useCallback((user) => {
    const userId = getParsedAuthInfo()?.user?.id;
    let invitedBy = user.invitedBy.id === userId ? 'You' : user.invitedBy.firstname + ' ' + user.invitedBy.lastname;

    return invitedBy;
  }, []);

  const getTimestampString = (invitedDate: string) => {
    const date = new Date(invitedDate);
    const now = moment(new Date()).startOf('day');
    const givenDate = moment(date);
    const days = now.diff(givenDate.startOf('day'), 'days');
    if (days < 1) {
      return 'Today';
    }
    if (days === 1) {
      return 'Yesterday';
    }
    return moment(date).format('DD/MMM/YYYY');
  };
  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: PaginatedInvites,
    index: string,
    status: string,
  ) => {
    setActionTypeEl(event.currentTarget);
    setSelectedUser(user);
    setActiveAction(index);
  };

  const handleResend = async () => {
    try {
      const name = selectUser.user
        ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
        : selectUser?.address?.address;
      setActionTypeEl(null);
      await client.mutate({
        mutation: ResendInvite,
        variables: {
          inviteId: selectUser.id,
        },
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.resendInvite,
        params: {
          invite_id: selectUser.id,
        },
      });

      toast(<CustomToaster body={`Invite for “${name}” has been resent.`} isVisibleUndo={false} />, {
        className: classes.rejectToastr,
      });
    } catch (e) {
      let message = 'Failed to send request, please check your internet connection and try again!';
      if (e?.networkError?.result?.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        if (errorCodeName) {
          message = e.networkError.result.errors[0].message;
        }
      }
      toast.error(message);
    }
  };

  const handleCloseMenu = () => {
    setActionTypeEl(null);
    setSelectedUser(null);
  };

  const getMoreSearchPendingInvites = async () => {
    const continuationId = searchPendingInviteData.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await getSearchedPendingInvites({
        text: localSearchText,
        limit: 30,
        continuationId,
        inviteStatus: PENDING_USER,
      });

      if ('error' in result) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchPendingInviteData({
          ...searchPendingInviteData,
          continuationId: result.continuationId,
          invites: [...searchPendingInviteData.invites, ...result.invites],
        });

        if (result.continuationId === null) {
          setIsDoneRows(true);
        }
      }
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const getMorePaginatedPendingInvites = async () => {
    const continuationId =
      paginatedPendingUserInvitesQuery.adminQuery.organizationalUnit.paginatedPendingInvites.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      await fetchMorePaginatedUserInvitesQuery({
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          continuationId,
          direction: 'next',
          inviteStatus: PENDING_USER,
          limit: 30,
        },
        updateQuery: (
          previousResult: FetchPaginatedInvitesQueryResponse,
          { fetchMoreResult }: { fetchMoreResult: FetchPaginatedInvitesQueryResponse },
        ) => {
          const newData = fetchMoreResult;

          newData.adminQuery.organizationalUnit.paginatedPendingInvites.invites = [
            ...previousResult.adminQuery.organizationalUnit.paginatedPendingInvites.invites,
            ...newData.adminQuery.organizationalUnit.paginatedPendingInvites.invites,
          ];

          const newFetchedUsers = fetchMoreResult.adminQuery.organizationalUnit.paginatedPendingInvites;

          if (newFetchedUsers.continuationId === null) {
            setIsDoneRows(true);
          }

          return newData;
        },
      });
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  return (
    <UserListContainer paginatedContactsFeatureFlag={paginatedContactsFeatureFlag}>
      <div style={{ maxHeight: '325px', overflow: 'auto', minHeight: '100px' }} id="scrollableDiv">
        <InfiniteScroll
          loadMore={localSearchText.length >= 1 ? getMoreSearchPendingInvites : getMorePaginatedPendingInvites}
          hasMore={!isDoneRows}
          initialLoad={false}
          useWindow={false}
        >
          {users.map((user: PaginatedInvites, index: number) => {
            const invitedBy = getInvitedBy(user);
            const uniqueIndex = `${index}-${user.id}`;
            return (
              <div
                className={`usersBlock ${classes.userBlockSection}`}
                key={`user-${index}`}
                data-testid={`user-${user.id}`}
              >
                <div className="usersBlock__avatarIcon">
                  <UserAvatar profileSize="default" user={user} />
                </div>

                <div className={`usersBlock__userNames ${classes.userNameSection}`}>
                  <span className={classes.userFullName}>{getUserFullName(user)}</span>
                  {user.username && <span className="usersBlock__userNames--username">@{user.username}</span>}
                </div>

                <div className="usersBlock__userTitle">
                  Invited by &nbsp;
                  <span
                    className={classes.invitedBy}
                    onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.invitedBy.id}`)}
                  >
                    {invitedBy}
                  </span>
                  , {moment(user.createdAt).format('DD/MMM/YYYY')}
                </div>
                {user.resolvedBy && user.resolvedBy.id !== user.invitedBy.id && (
                  <div className={`usersBlock__userTags ${classes.approvedBy}`}>
                    Approved by &nbsp;
                    <span
                      className={classes.invitedBy}
                      onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.resolvedBy.id}`)}
                    >
                      {invitedBy}
                    </span>
                    , <span>{getTimestampString(user.createdAt)}</span>
                  </div>
                )}

                <div className={`usersBlock__userStatus ${classes.status}`}>
                  <StyledStatus status={status} data-testid="status">
                    {'Pending invitee response'}
                  </StyledStatus>
                </div>

                <div className={classes.actionButton}>
                  <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                    <StyledButton
                      id={`action-button-${user.id}`}
                      aria-haspopup="true"
                      className={classes.inviteeActionButtonBorder}
                      onClick={(e) => handleActionClick(e, user, uniqueIndex, status)}
                      endIcon={actionTypeEl && uniqueIndex === activeAction ? <ExpandLess /> : <ExpandMore />}
                    >
                      Action
                    </StyledButton>
                  </Box>
                </div>
              </div>
            );
          })}
          {isDoneRows && (
            <div style={{ height: '25px', margin: '5px' }}>
              <HCHeadingFour fontWeight={400} color={theme.mainFontColor}>
                {END_OF_USER_LIST_MESSAGE}
              </HCHeadingFour>
            </div>
          )}
        </InfiniteScroll>
      </div>
      <StyledMenu
        id={`action-menu-${selectUser?.id}`}
        keepMounted
        anchorEl={actionTypeEl}
        open={Boolean(actionTypeEl)}
        onClose={() => handleCloseMenu()}
      >
        <StyledMenuItem onClick={() => handleResend()}>Resend invite</StyledMenuItem>
      </StyledMenu>
    </UserListContainer>
  );
};

export default PendingUsersList;
