import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
// DOC: https://github.com/rotaready/moment-range#overlaps

const moment = extendMoment(Moment);

// StartTime and endTime are not UTC,
// they also has a arbitary day followed by the current day of user,
// convert all shifts start end date to local moment date and return a blocked local day array

const generateBlockDates = ({ startTime, endTime, cachedShifts }): Set<number> => {
  const blockedDateSet: Set<number> = new Set();

  if (!startTime || !startTime.isValid() || !endTime || !endTime.isValid()) {
    return blockedDateSet;
  }

  const tryAddToSetByRange = ({
    localStartDate,
    localEndDate,
    startDateISOString, // origin cached info
    endDateISOString, // origin cached info
    isCrossDateShift, // cross date flag (endTime < startTime)
  }) => {
    // range from user input, with modified date
    let userSelectedRange = moment.range(localStartDate, localEndDate);
    // range from shift that onverted to local moment time
    let shiftRange = moment.range(moment(startDateISOString).local(), moment(endDateISOString).local());

    if (isCrossDateShift) {
      // check either the previous day, or the day next
      let minusStartDayUserSelectedRange = moment.range(localStartDate.clone().subtract(1, 'days'), localEndDate);
      let plusEndDayUserSelectedRange = moment.range(localStartDate, localEndDate.clone().add(1, 'days'));

      if (shiftRange.overlaps(minusStartDayUserSelectedRange) || shiftRange.overlaps(plusEndDayUserSelectedRange)) {
        blockedDateSet.add(localStartDate.date());
      }
    } else {
      if (userSelectedRange.overlaps(shiftRange)) {
        blockedDateSet.add(localStartDate.date());
      }
    }
  };

  cachedShifts.forEach((shift) => {
    const startDateISOString = shift.startDate;
    const endDateISOString = shift.endDate;

    let localStartDate = moment(startDateISOString).local();
    let localEndDate = localStartDate.clone(); // follow same day by startDate of the shift

    localStartDate.set('hour', startTime.hour());
    localStartDate.set('minute', startTime.minute());
    localEndDate.set('hour', endTime.hour());
    localEndDate.set('minute', endTime.minute());

    let isCrossDateShift = false;

    if (
      startTime.hour() > endTime.hour() ||
      (startTime.hour() === endTime.hour() && startTime.minute() >= endTime.minute())
    ) {
      isCrossDateShift = true;
    }

    tryAddToSetByRange({
      localStartDate,
      localEndDate,
      startDateISOString,
      endDateISOString,
      isCrossDateShift,
    });
  });

  return blockedDateSet;
};

export default generateBlockDates;
