import { USER_AUTHORIZED, RESET, TOKEN_ALERT } from 'src/constants/actionNames';

interface AuthorizeUserAction {
  type: typeof USER_AUTHORIZED;
}

interface ResetAction {
  type: typeof RESET;
}

interface TokenLastUpdatedAtAction {
  type: typeof TOKEN_ALERT;
}

export type AuthorizeActionActionTypes = AuthorizeUserAction | ResetAction | TokenLastUpdatedAtAction;

export const authorizeUserAction = (): AuthorizeUserAction => {
  return {
    type: USER_AUTHORIZED,
  };
};

export const resetAction = (): ResetAction => {
  return {
    type: RESET,
  };
};

export const updateTokenLastUpdatedAt = (): TokenLastUpdatedAtAction => {
  return {
    type: TOKEN_ALERT,
  };
};
