import { CURRENT_ORGANIZATION } from 'src/constants/storageKeys';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(CURRENT_ORGANIZATION);
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(CURRENT_ORGANIZATION, serializedState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
