import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
  styles?: React.CSSProperties;
  fill?: string;
};
export default (props: Props) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...props.styles }}
  >
    <path
      d="M9 7V1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9Z"
      fill={props.fill || 'FF3E55'}
    />
  </svg>
);
