import * as React from 'react';
import { HCHeadingFour, HCLabelOne } from '../../../components/shared/HypercareComponents';
import theme from '../../../assets/styles/theme';
import styled from 'styled-components';
import ChevronRight from '../../../assets/svgs/ChevronRight';

type MessagesReportTemplateContainerProps = {
  title: string;
  description: string;
  handleExportButtonClick: () => Promise<void>;
  exportButtonText: string;
};

const MessagesReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${theme.white};
  border: 1px solid ${theme.borderColor};
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  gap: 8px;
  width: 437px;
  height: 204px;
  &:hover {
    background-color: ${theme.lightGrey};
  }
`;

const MessagesReportTemplateContainerLink = styled.a`
  text-decoration: none;
  height: 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: 18px;
`;

export const MessagesReportTemplateContainer = ({
  title,
  description,
  handleExportButtonClick,
  exportButtonText,
}: MessagesReportTemplateContainerProps) => {
  return (
    <MessagesReportContainer>
      <HCHeadingFour color={theme.black}>{title}</HCHeadingFour>
      <HCLabelOne color={theme.textLightTertiary}>{description}</HCLabelOne>
      <MessagesReportTemplateContainerLink onClick={() => handleExportButtonClick()}>
        <HCHeadingFour color={theme.blueBorder}>{exportButtonText}</HCHeadingFour>
        <ChevronRight fill={theme.blueBorder} />
      </MessagesReportTemplateContainerLink>
    </MessagesReportContainer>
  );
};
