import gql from 'graphql-tag';
import { OrganizationFragments, SiteFragments } from 'src/gql/fragment/ManagementFragments';
import { userFragmentString, publicUserFragment } from 'src/gql/fragment/FullUserFragment';
import { scopeFragmentString } from 'src/gql/fragment/ScopeFragment';

export const GET_SELF_SCOPE = gql`
  query SelfScope($filter: Boolean = false) {
    me {
      id
      organizations {
        ...OrganizationFragments
        sites(filter: $filter) {
          ...SiteFragments
        }
      }
    }
  }
  ${OrganizationFragments}
  ${SiteFragments}
`;

export const GET_SELF_PROFILE = gql`
  query SelfProfile($filter: Boolean = false) {
    me { 
      ${userFragmentString}
      organizations {
        ...OrganizationFragments
        sites(filter: $filter) {
          ...SiteFragments
        }
      }
    }
  }
  ${OrganizationFragments}
  ${SiteFragments}
`;

export const GET_SELF_SCOPES = gql`
  query SelfProfile {
    me {
      scopes {
        organizationId
        siteId
        departmentId
        status
      }
    }
  }
`;
export const GET_USERS_ORGANIZATIONS = gql`
  query SelfProfile($filter: Boolean = false) {
    me {
      id
      organizations {
        id
        ...OrganizationFragments
        sites(filter: $filter) {
          id
          ...SiteFragments
        }
      }
    }
  }
  ${OrganizationFragments}
  ${SiteFragments}
`;

export const GET_USERS_PROFILE = gql`
  query GetUser($userId: ID!) {
    profile(id: $userId) {
      ${publicUserFragment}
    }
  }
`;

// unlicensed user and Full_USER fragment cant fetched under admin { ...
// for pending and unlicensed
export const GET_USER_PROFILE_ALT = gql`
  query UserGetUserProfileByIds($ids: [ID!]!) {
    users (ids: $ids) {
      ${publicUserFragment}
      addresses {
        id
        address
        type
        ordinal
        access
      }
    }
  }
`;

// licensed user only
export const GET_USER_PROFILE = gql`
  query AdminGetUserProfile($userId: ID!) {
    admin {
      user(id: $userId) {
        ${userFragmentString}
        addresses {
          id
          address
          type
          ordinal
          access
        }
        organizations {
          id
          name
        }
        scopes {
          ${scopeFragmentString}
        }
      }
    }
  }
`;
