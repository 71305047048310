import gql from 'graphql-tag';
import { OrganizationUserResultFragment } from '../fragment/OrganizationUserResultFragment';

export const ADMIN_CREATE_ROLE_NOTE = gql`
  mutation CreateRoleNote(
    $organizationalUnit: OrganizationalUnitInput!
    $scheduleId: Int!
    $roleId: Int!
    $details: CreateHiddenNoteDetails!
  ) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminDepartmentMutation {
            __typename
            schedule(id: $scheduleId) {
              role(id: $roleId) {
                createNote(details: $details) {
                  ... on RoleNote {
                    __typename
                    id
                    note
                    access
                    createdAt
                    updatedAt
                    createdBy {
                      ...OrganizationUserResultFragment
                      __typename
                    }
                    editedBy {
                      ...OrganizationUserResultFragment
                      __typename
                    }
                  }
                  ... on ExceedMaxNoteCountError {
                    __typename
                    message
                  }
                  ... on ExceedMaxWordCountError {
                    __typename
                    message
                    maxCount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${OrganizationUserResultFragment}
`;
