import { User } from 'src/types';
import { PaginatedPublicUser, PaginatedUsersType } from 'src/types/PaginatedTypes';

export function ByLastName(a: User, b: User) {
  const currentUser = a.lastname ? a.lastname : a?.address?.address;
  const previousUser = b.lastname ? b.lastname : b?.address?.address;
  if (currentUser && previousUser && currentUser.toLowerCase() < previousUser.toLowerCase()) {
    return -1;
  }
  if (currentUser && previousUser && currentUser.toLowerCase() > previousUser.toLowerCase()) {
    return 1;
  }
  return 0;
}

export function ByLastNameRev(a: User, b: User) {
  const currentUser = a.lastname ? a.lastname : a?.address?.address;
  const previousUser = b.lastname ? b.lastname : b?.address?.address;
  if (currentUser && previousUser && currentUser.toLowerCase() < previousUser.toLowerCase()) {
    return 1;
  }
  if (currentUser && previousUser && currentUser.toLowerCase() > previousUser.toLowerCase()) {
    return -1;
  }
  return 0;
}

export const SortPaginatedUsersByLastName = (
  a: PaginatedUsersType | PaginatedPublicUser,
  b: PaginatedUsersType | PaginatedPublicUser,
) => {
  const currentUser = a.lastname || '';
  const previousUser = b.lastname || '';
  if (currentUser && previousUser && currentUser.toLowerCase() < previousUser.toLowerCase()) {
    return -1;
  }
  if (currentUser && previousUser && currentUser.toLowerCase() > previousUser.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const SortPaginatedUsersByLastNameReversed = (
  a: PaginatedUsersType | PaginatedPublicUser,
  b: PaginatedUsersType | PaginatedPublicUser,
) => {
  const currentUser = a.lastname || '';
  const previousUser = b.lastname || '';
  if (currentUser && previousUser && currentUser.toLowerCase() < previousUser.toLowerCase()) {
    return 1;
  }
  if (currentUser && previousUser && currentUser.toLowerCase() > previousUser.toLowerCase()) {
    return -1;
  }
  return 0;
};
