import React from 'react';
import useRepository from 'src/components/hidden-notes/HiddenNotesRepository';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import {
  ACCESS_FORBIDDEN_ERROR,
  ACCESS_FORBIDDEN_ERROR_MESSAGE,
  EXCEED_MAX_NOTE_COUNT_ERROR,
  EXCEED_MAX_NOTE_COUNT_ERROR_MESSAGE,
  EXCEED_MAX_WORD_COUNTER_ERROR,
  EXCEED_MAX_WORD_COUNTER_ERROR_MESSAGE,
  NOTE_DELETED_ERROR,
  NOTE_DELETED_ERROR_MESSAGE,
  NOTE_NOTE_FOUND_ERROR,
  NOTE_NOTE_FOUND_ERROR_MESSAGE,
  ROLE_NOTES_CREATING_DEFAULT_ERROR_MESSAGE,
  ROLE_NOTES_DELETING_DEFAULT_ERROR_MESSAGE,
  ROLE_NOTES_UPDATING_DEFAULT_ERROR_MESSAGE,
} from 'src/constants/networkError';
import { BasicRoleField, DepartmentRoleNoteObject, IRoleNote } from 'src/types';
import { sortByUpdatedAt } from 'src/utils/userSorting/sortByUpdatedAt';

export default function HypercareRoleNotesViewModel() {
  const { adminCreateRoleNote, adminUpdateRoleNote, adminDeleteRoleNote, fetchRoleNotesForDepartment } =
    useRepository();
  const scheduleId = typedUseSelector((state) => state.monthlyScheduleReducer.scheduleId);

  const dispatch = useDispatch();
  const handleCreateRoleNote = async ({ access, description, noteId, roleId }: IRoleNote) => {
    let payload = {
      organizationUnit: checkOrganizationalUnit(),
      roleId,
      scheduleId,
      details: {
        note: description,
        access,
      },
    };

    const { result, error } = await adminCreateRoleNote(payload);

    if (error) {
      return { error };
    }

    const newCreatedRoleNoteObject = result.data?.adminMutation.organizationalUnit.schedule.role.createNote;

    if (newCreatedRoleNoteObject.note) {
      dispatch(allActions.monthlyScheduleAction.setAddRoleNote({ note: newCreatedRoleNoteObject, id: noteId }));

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteCreated,
        params: {
          note_id: newCreatedRoleNoteObject.id,
          visibility: newCreatedRoleNoteObject.access,
        },
      });

      return { result: true, error: false };
    }

    if (newCreatedRoleNoteObject.message) {
      switch (newCreatedRoleNoteObject.__typename) {
        case EXCEED_MAX_NOTE_COUNT_ERROR:
          return {
            error: EXCEED_MAX_NOTE_COUNT_ERROR_MESSAGE,
          };
        case EXCEED_MAX_WORD_COUNTER_ERROR:
          return { error: EXCEED_MAX_WORD_COUNTER_ERROR_MESSAGE };
        default:
          return { error: ROLE_NOTES_CREATING_DEFAULT_ERROR_MESSAGE };
      }
    }
  };

  const handleDeleteRoleNote = async (noteId: string, roleId: number): Promise<{ error?: any; result?: any }> => {
    const { result, error } = await adminDeleteRoleNote(scheduleId, roleId, noteId);

    if (error) {
      return { error };
    }

    const deletedNoteObject = result.data.adminMutation.organizationalUnit.schedule.role.note.deleteNote;
    const responseObject = result.data.adminMutation.organizationalUnit.schedule.role.note;

    if (deletedNoteObject) {
      dispatch(allActions.monthlyScheduleAction.setDeleteRoleNote({ deletedNoteId: deletedNoteObject.id, roleId }));

      if (deletedNoteObject) {
        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.profileNoteDeleted,
          params: {
            note_id: deletedNoteObject.id,
          },
        });
        return { error: null, result: 'success' };
      }
    }

    if (responseObject) {
      switch (responseObject.__typename) {
        case NOTE_DELETED_ERROR:
          return {
            error: NOTE_DELETED_ERROR_MESSAGE,
          };
        case ACCESS_FORBIDDEN_ERROR:
          return { error: ACCESS_FORBIDDEN_ERROR_MESSAGE };
        case NOTE_NOTE_FOUND_ERROR:
          return { error: NOTE_NOTE_FOUND_ERROR_MESSAGE };
        default:
          return { error: ROLE_NOTES_DELETING_DEFAULT_ERROR_MESSAGE };
      }
    }
    return { result, error };
  };

  const handleUpdateRoleNote = async ({ access, description, roleId, noteId }: IRoleNote) => {
    let payload = {
      organizationUnit: checkOrganizationalUnit(),
      roleId,
      scheduleId,
      noteId,
      details: {
        note: description,
        access,
      },
    };

    const { result, error } = await adminUpdateRoleNote(payload);

    if (error) {
      return { error };
    }

    const responseObject = result.data.adminMutation.organizationalUnit.schedule.role.note;

    const updatedNoteObject = result.data.adminMutation.organizationalUnit.schedule.role.note.update;

    if (updatedNoteObject?.note) {
      dispatch(allActions.monthlyScheduleAction.setUpdateRoleNote({ updatedNoteObject, roleId }));
      return { result: true, error: false };
    }

    if (responseObject.message) {
      switch (responseObject.__typename) {
        case NOTE_DELETED_ERROR:
          return {
            error: NOTE_DELETED_ERROR_MESSAGE,
          };
        case ACCESS_FORBIDDEN_ERROR:
          return { error: ACCESS_FORBIDDEN_ERROR_MESSAGE };
        case NOTE_NOTE_FOUND_ERROR:
          return { error: NOTE_NOTE_FOUND_ERROR_MESSAGE };
        default:
          return { error: ROLE_NOTES_UPDATING_DEFAULT_ERROR_MESSAGE };
      }
    }
  };

  const buildDepartmentRoleNoteObject = (roles: BasicRoleField[]): DepartmentRoleNoteObject => {
    return roles.reduce((roleNoteObject, role) => {
      const sortedRoleNotesArray = sortByUpdatedAt(role.notes);

      roleNoteObject[role.id] = {
        notes: sortedRoleNotesArray,
        roleName: role.name,
      };

      return roleNoteObject;
    }, {} as DepartmentRoleNoteObject);
  };

  const getRoleNotesForDepartment = async ({ scheduleId }) => {
    const { result, error } = await fetchRoleNotesForDepartment({ scheduleId });

    if (result) {
      let roleResultObject = result.data.organizationalUnitQuery.organizationalUnit.scheduling.schedule.roles;
      let departmentRoleNoteObject = buildDepartmentRoleNoteObject(roleResultObject);
      dispatch(allActions.monthlyScheduleAction.setRoleNotes(departmentRoleNoteObject));
    }

    if (error) {
      return { error };
    }

    return { result };
  };

  return {
    handleCreateRoleNote,
    handleDeleteRoleNote,
    handleUpdateRoleNote,
    getRoleNotesForDepartment,
  };
}
