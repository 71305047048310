import { TextField, withStyles, FormControl, Select } from '@material-ui/core';
import theme from 'src/assets/styles/theme';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

export const StyledTextField = withStyles({
  root: {
    caretColor: theme.mainTealColor,
    '& .MuiOutlinedInput-input': {
      padding: '10px 8px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.mainTealColor,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.mainTealColor,
      },
      '&.Mui-error fieldset': {
        borderColor: theme.errorRedBorder,
      },
    },
  },
})(TextField);

export const StyledFormControl = withStyles({
  root: {
    borderColor: theme.mainTealColor,
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
      borderBottomColor: 'none',
      content: 'none',
    },

    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.mainTealColor,
      },
    },
    '& .MuiInput-underline:before': {
      content: 'none',
    },
    '& .MuiInputBase-root': {
      borderColor: theme.mainTealColor,
      '&.Mui-focused div': {
        borderColor: theme.mainTealColor,
      },
    },
  },
})(FormControl);

export const StyledLink = styled.a`
  color: #00859a;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledSelect = withStyles({
  root: {
    padding: '8px 16px',
    width: '100%',
    borderRadius: '2px',
    background: 'white',
  },
})(Select);
export const StyledCheckBox = withStyles({
  root: {
    padding: '0px',
    '&.Mui-checked': {
      color: theme.mainTealColor,
    },
  },
})(Checkbox);
