import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
  stroke?: string;
  strokeWidth?: number;
};

export default ({ width, height, stroke, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke || 'lightgrey'}
    strokeWidth={strokeWidth || 2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-search"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);
