import type { OrganizationType } from 'src/types';

export const MANAGED_BY_DIRECTORY_SERVICE = 'MANAGED BY DIRECTORY SERVICE';
export const DANGER_ZONE = 'Danger Zone';
export const SHELL_ACCOUNT = 'SHELL ACCOUNT';
export const MANAGED = 'MANAGED';
export const UNMANAGED = 'UNMANAGED';
export const ENABLE_DIRECTORY_SERVICE_MANAGEMENT = 'Enable directory service management';
export const DISABLE_DIRECTORY_SERVICE_MANAGEMENT = 'Disable directory service management';
export const ENABLE_DIRECTORY_MANAGEMENT_DESC =
  "This action will enable syncing of this account to the organization's directory service. Changes made in the directory will be reflected in the Hypercare profile.";
export const DISABLE_DIRECTORY_MANAGEMENT_DESC =
  "This action will disable syncing of this account to the organization's directory service. Changes made in the directory will not be reflected in the Hypercare profile, and changes must be made directly in Hypercare.";
export const ENABLE_DIRECTORY_MANAGEMENT = 'Enable directory management';
export const DISABLE_DIRECTORY_MANAGEMENT = 'Disable directory management';
export const LOGOUT_ALL_DEVICES = 'Logout of All devices';
export const LOGOUT_ALL_DEVICES_DESC = 'Ends all active sessions for this user across devices';
export const RESET_PASSWORD = 'Reset Password';
export const RESET_PASSWORD_DESC = 'Helps the user reset/recover their password';
export const REMOVE_MEMBER_ORG_TITLE = (orgType: OrganizationType) => `Remove member from ${orgType}`;
export const REMOVE_MEMBER_ORG_DESC = (orgType: OrganizationType) => {
  if (orgType === 'organization') {
    return "This will remove the user's access to your organization, and will remove them from all contact lists and on-call schedules";
  }
  return `This will remove the user's access to your ${orgType}, but they will stay part of the organization.`;
};
export const REMOVE_MEMBER_FROM_ORGANIZATION = (orgType: string) => `Remove from ${orgType}`;

export const REMOVE_MEMBER = 'Remove Member';
export const VIEW_NAME_PROFILE = (name: string) => `View ${name} Profile`;
export const ENABLE_DIRECTORY_MANAGEMENT_FOR = (name: string) => `Enable directory management for ${name}`;
export const DISABLE_DIRECTORY_MANAGEMENT_FOR = (name: string) => `Disable directory management for ${name}`;
export const DIR_SYNC_ENABLE_MODAL_BANNER_HEADING = 'User will be managed by your directory service';
export const DIR_SYNC_ENABLE_MODAL_BANNER_DESC =
  'Upon syncing, information will be imported from the directory to the Hypercare profile. This may cause some information to be overwritten. Account may also be removed from the organization on Hypercare if the SSO email is modified, removed or inactive in the directory.';
export const DIR_SYNC_ENABLE_MODAL_HEADING = 'As a managed account:';
export const DIR_SYNC_ENABLE_MODAL_POINTS_ONE = 'Users can log in via organization single-sign on credentials';
export const DIR_SYNC_ENABLE_MODAL_POINTS_TWO = 'Changes to their account via directory will be synced in Hypercare';
export const DIR_SYNC_ENABLE_MODAL_POINTS_THREE =
  'Information synced to the directory will not be modifiable in Hypercare, and must be edited in the directory';

export const DIR_SYNC_DISABLE_MODAL_BANNER_HEADING = 'User will no longer be managed by directory service';
export const DIR_SYNC_DISABLE_MODAL_BANNER_DESC =
  'On the next sync, profile information won’t be connected to the directory service and must be updated in Hypercare directly.';
export const DIR_SYNC_DISABLE_MODAL_HEADING = 'As a non-managed account:';
export const DIR_SYNC_DISABLE_MODAL_POINTS_ONE =
  'User can still login via SSO (if they have the appropriate credentials)';
export const DIR_SYNC_DISABLE_MODAL_POINTS_TWO =
  'Changes to user profile information and access must be managed on Hypercare (i.e. changes to the directory service will not be reflected in Hypercare)';

export const ENABLE_SYNC = 'Enable sync';
export const DISABLE_SYNC = 'Disable sync';
export const DIR_SYNC_ENABLED_TOAST_HEADING = 'Directory management for this user will be enabled on the next sync';
export const DIR_SYNC_ENABLED_TOAST_DESC =
  'Some information will be synced and imported into Hypercare from your source directory.';
export const DIR_SYNC_DISABLED_TOAST_HEADING = 'Directory sync for this user will be disabled on the next sync';
export const DIR_SYNC_DISABLED_TOAST_DESC =
  'User account will become non-AD managed. You can still make changes before the next directory sync (08:00 EDT).';

export const DIR_SYNC_PROFILE_PAGE_WARNING_BANNER_TITLE =
  "Fields synced with your organization's directory service cannot be edited in Hypercare";
export const DIR_SYNC_PROFILE_PAGE_WARNING_BANNER_DESCRIPTION =
  'Changes to synced attributes must be made via directory and then synced to Hypercare. ';

export const DIR_SYNC_MANAGEMENT_ENABLED = 'Directory Service Management Enabled';
export const DIR_SYNC_MANAGEMENT_DISABLED = 'Directory Service Management Disabled';

export const DIR_SYNC_MANAGEMENT_ENABLED_TOOLTIP_TEXT =
  'User lifecycles and information are managed through your corporate directory and synced to Hypercare.';
export const DIR_SYNC_MANAGEMENT_DISABLED_TOOLTIP_TEXT =
  'Unlock the power of directory management and take control of user lifecycles and information in your corporate directory. Discover how it seamlessly syncs with Hypercare.';

export const MANAGED_LABEL_TOOLTIP_TEXT = 'This user is managed by your corporate directory and synced to Hypercare.';
export const UNMANAGED_LABEL_TOOLTIP_TEXT = 'This user is not managed by your corporate directory and is out of sync.';
