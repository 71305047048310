import React from 'react';
import styled from 'styled-components';
import {
  VirtualPagerStatusMap,
  PopUpDescriptionMappingConfigurePage,
  PopUpMappingHeading,
  REACTIVATE,
  STATUS,
  CREATED,
  REGION,
  LAST_PAGED,
} from 'src/constants/virtualPagerStrings';
import {
  HCBodySpan,
  HCHeadingFour,
  HCHeadingThree,
  HCLabelOne,
  HCLabelTwo,
  HCTextContext,
} from 'src/components/shared/HypercareComponents';

import theme from 'src/assets/styles/theme';
import { Icon, IconButton } from '@material-ui/core';
import ChevronLeft from 'src/assets/svgs/ChevronLeft';
import StatusPill from '../../components/StatusPill';
import moment from 'moment';
import ConfigureNumberViewModel from '../ConfigureNumberViewModel';
import Popup from 'reactjs-popup';
import { typedUseSelector } from 'src/redux/store';
import { VirtualPagerStatusTypes } from 'src/types';

const HeaderContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NumberHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 24px;
  gap: 12px;
`;
const NumberInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--border-grey, #d8d8d8);
  border-bottom: 1px solid var(--border-grey, #d8d8d8);
`;

const NumberInfoContainer = styled.div`
  display: flex;
  padding: 16px 0px;
  max-width: 920px;
  width: 80%;
  align-items: flex-start;
  gap: 16px;
`;

const NumberInfoInternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  white-space: nowrap;
  border-right: 1px solid var(--border-grey, #d8d8d8);
  gap: 4px;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    border-right: 0px;
    padding-right: 0px;
  }
`;

const PopupContainer = styled.div<{ status: VirtualPagerStatusTypes }>`
  position: relative;
  border-radius: 8px;
  background: var(--White, #fff);
  ${(props) =>
    props.status === VirtualPagerStatusTypes.PENDING
      ? 'border-left: 6px solid #ffe382;'
      : props.status === VirtualPagerStatusTypes.ACTIVE
      ? 'border-left: 6px solid #00859A;'
      : 'border-left: 6px solid #BCBCBC;'}
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 18px 28px 0px rgba(9, 30, 66, 0.15);
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const PopUpActionButton = styled.div`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
`;

interface NumberInfoSectionProps {
  label: string;
  value: string | React.ReactNode;
}

const NumberInfoSection: React.FC<NumberInfoSectionProps> = ({ label, value }) => (
  <NumberInfoInternalContainer>
    <HCBodySpan color={theme.mainFontColor}>{label}</HCBodySpan>
    {typeof value === 'string' ? <HCLabelOne>{value}</HCLabelOne> : <div>{value}</div>}
  </NumberInfoInternalContainer>
);

const ConfigureNumberHeader: React.FC = () => {
  const pager = typedUseSelector((state) => state.virtualPagerReducer.currentPager);
  const { formatPhoneNumber } = ConfigureNumberViewModel();
  const formattedPhoneNumber = formatPhoneNumber(pager.pagerNumber);

  const handleReturnButton = () => {
    const configureRoute = `/virtualpagers`;
    window.routerHistory.push(configureRoute);
  };

  return (
    <HeaderContainer>
      <NumberHeaderContainer>
        <IconButton onClick={handleReturnButton} style={{ padding: '0px' }}>
          <Icon>
            <ChevronLeft />
          </Icon>
        </IconButton>
        <HCHeadingThree color={theme.black}>{formattedPhoneNumber}</HCHeadingThree>
      </NumberHeaderContainer>
      <NumberInfoWrapper>
        <NumberInfoContainer>
          <NumberInfoSection
            label={STATUS}
            value={
              <Popup
                trigger={<StatusPill status={pager.status}>{VirtualPagerStatusMap[pager.status]}</StatusPill>}
                position="bottom left"
                on="hover"
                closeOnDocumentClick
                arrow={true}
              >
                <PopupContainer status={pager.status}>
                  <div
                    style={{
                      width: 'min-content',
                      minWidth: '200px',
                      gap: '0.4rem',
                      flexDirection: 'column',
                      display: 'flex',
                    }}
                  >
                    {pager.status === 'disabled' ? (
                      <HCTextContext style={{ width: 'max-content' }}>
                        {PopUpMappingHeading(pager.status)}
                      </HCTextContext>
                    ) : (
                      <HCTextContext style={{ width: 'max-content' }}>
                        {PopUpMappingHeading(pager.status)}
                      </HCTextContext>
                    )}
                    <HCLabelTwo style={{ width: '100%' }}>
                      {PopUpDescriptionMappingConfigurePage(pager.status)}
                    </HCLabelTwo>
                  </div>
                  {pager.status === 'disabled' ? (
                    <PopUpActionButton onClick={() => {}}>
                      <HCHeadingFour fontWeight={700} color={theme.mainTealColor}>
                        {REACTIVATE}
                      </HCHeadingFour>
                    </PopUpActionButton>
                  ) : null}
                </PopupContainer>
              </Popup>
            }
          ></NumberInfoSection>
          <NumberInfoSection label={REGION} value={pager.locality} />
          <NumberInfoSection label={CREATED} value={moment(pager.dateCreated).format('DD/MMM/YYYY')} />
          <NumberInfoSection
            label={LAST_PAGED}
            value={pager.lastUsed ? moment(pager.lastUsed).format('DD/MMM/YYYY') : '--'}
          />
        </NumberInfoContainer>
      </NumberInfoWrapper>
    </HeaderContainer>
  );
};

export default ConfigureNumberHeader;
