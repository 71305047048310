import gql from 'graphql-tag';

export default gql`
  mutation DeleteShift($departmentId: Int!, $scheduleId: Int!, $shiftId: Int!) {
    admin {
      locating {
        department(id: $departmentId) {
          schedule(id: $scheduleId) {
            shift(id: $shiftId) {
              remove
            }
          }
        }
      }
    }
  }
`;
