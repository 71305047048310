import React from 'react';
import { QueryResult, useQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import UserProfile from 'src/pages/UserProfilePage/profile-layout/UserProfile';
import { GET_USER_PROFILE_ALT } from 'src/gql/query/GetUserProfileQuery';
import { FETCH_USER_PROFILE, FETCH_USER_PROFILE_DIR_SYNC } from 'src/gql/v2/query/FetchUserProfileQuery';
import Loader from 'src/components/loaders/UserProfileLayoutLoader';
import UserProfileSubHeader from 'src/components/headers/UserProfileSubHeader';
import {
  ShorthandOrganizationType,
  FetchUserProfileResultFinal,
  GetAllLicensingUsersResult,
  User,
  UserLicensingStatus,
} from 'src/types';
import getAdminTypeBaseOnScopes from 'src/utils/getAdminTypeBaseOnScopes';
import { GET_UNLICENSED_USERS } from 'src/gql/query/GetUsersQuery';
import { typedUseSelector } from 'src/redux/store';
import { useParams } from 'react-router-dom';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { LICENSED, PENDING, UNLICENSED } from 'src/constants/user';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import UserProfileOPC from './profile-layout/UserProfileOPC';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { ORG } from '../../constants/strings';

/**
 * NOTE: since backend unable to add licenseStatus to user fragment, and profile need addressees info that are expensive to fetch initially in userList
 * in the case of user refresh the page at user profile page, FOUR queries will be fetched initially if haven't already
 *  - GET_USER_PROFILE: this only works iff user is licensed, so if has the result, the user will be licensed
 *  - GET_USER_PROFILE_ALT: this fetches the user that is unlicensed(aka blacklist in backend terms) OR pending; also this is public user, those scopes and expiry time are unable to be fetched
 *  - pendingUsersList,
 *  - blacklistedUsersList,
 *
 * NOTE: admin can only edit licensed users profile
 */

const UserProfileLayoutLicensingContainer = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data, error, loading } = useQuery<GetAllLicensingUsersResult>(GET_UNLICENSED_USERS);

  if (loading) return <Loader />;

  if (error) {
    return (
      <div className="networkErrorHolder">
        <span>An Error Occurred, Please Check Your Internet Connection And Try Again.</span>
      </div>
    );
  }

  const { pendingUsers, blacklistedUsers } = data.admin;

  const isPendingUser = pendingUsers.find((user) => user.id === userId);
  const isBlacklistedUser = blacklistedUsers.find((user) => user.id === userId);

  let userLicenseStatus: UserLicensingStatus;

  if (isPendingUser) userLicenseStatus = PENDING;
  if (isBlacklistedUser) userLicenseStatus = UNLICENSED;
  if (!isPendingUser && !isBlacklistedUser) userLicenseStatus = LICENSED;

  return <UserProfileLayout userLicenseStatus={userLicenseStatus} />;
};

const UserProfileLayout = ({ userLicenseStatus }: { userLicenseStatus: UserLicensingStatus }) => {
  const { userId } = useParams<{ userId: string }>();
  const isLicensedUser = userLicenseStatus === LICENSED;
  const dispatch = useDispatch();

  const sendProfileToStore = (user: User) => dispatch(allActions.headerAction.sendProfileToStore(user));

  const isAuthorized = typedUseSelector((state) => state.flagReducer.isAuthorized);
  const currentOrganization = typedUseSelector((state) => state.organizationReducer);
  const [adminType, setAdminType] = React.useState<ShorthandOrganizationType | ''>('');
  const hiddenNotes = IsFeatureFlagEnabled('hiddenNotes');
  const adminContactPreferences = IsFeatureFlagEnabled('orderPreferenceContacts');
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabels);

  const queryData = useQuery<any>(
    isLicensedUser ? (ldapDirectorySyncFlag ? FETCH_USER_PROFILE_DIR_SYNC : FETCH_USER_PROFILE) : GET_USER_PROFILE_ALT,
    {
      variables: isLicensedUser
        ? {
            organizationalUnit: checkOrganizationalUnit(),
            userId,
            isProfileAddressLabelFlagEnabled: profileAddressLabelFlag,
          }
        : {
            ids: [userId],
          },
      fetchPolicy: 'no-cache',
    },
  );
  const {
    data: profileData,
    error,
    loading,
    refetch,
  } = isLicensedUser
    ? (queryData as QueryResult<FetchUserProfileResultFinal>)
    : (queryData as QueryResult<{ users: User[] }>);

  React.useEffect(() => {
    if (profileData && userId && isLicensedUser) {
      const scopes = (profileData as FetchUserProfileResultFinal).adminQuery?.organizationalUnit?.member?.scopes;
      const adminType = getAdminTypeBaseOnScopes(scopes);
      setAdminType(adminType);
    }
  }, [isLicensedUser, userId, profileData, currentOrganization]);

  React.useEffect(() => {
    if (isLicensedUser) {
      let typedProfileData = profileData as FetchUserProfileResultFinal;
      if (profileData) {
        sendProfileToStore(typedProfileData?.adminQuery?.organizationalUnit?.member);
      }
    } else {
      let typedProfileData = profileData as { users: User[] };
      if (typedProfileData && typedProfileData.users && typedProfileData.users[0]) {
        sendProfileToStore(typedProfileData.users[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLicensedUser, profileData]);

  if (!isAuthorized) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  if (loading) return <Loader />;

  if (error) {
    return (
      <div className="networkErrorHolder">
        <span>An Error Occurred, Please Check Your Internet Connection And Try Again.</span>
      </div>
    );
  }

  const user = isLicensedUser
    ? (profileData as FetchUserProfileResultFinal)?.adminQuery?.organizationalUnit?.member
    : (profileData as { users: User[] }).users[0];

  return (
    <>
      <UserProfileSubHeader adminType={adminType} profile={user} />
      {!adminContactPreferences ? (
        <UserProfile
          user={user}
          isAdmin={adminType === ORG}
          reFetchData={refetch}
          userLicenseStatus={userLicenseStatus}
          hiddenNotes={hiddenNotes}
        />
      ) : (
        <UserProfileOPC
          user={user}
          isAdmin={adminType === ORG}
          reFetchData={refetch}
          userLicenseStatus={userLicenseStatus}
          hiddenNotes={hiddenNotes}
        />
      )}
    </>
  );
};

export default UserProfileLayoutLicensingContainer;
