import { FullOrganizationMemberDirSyncFragment } from 'src/gql/v2/fragment/FullOrganizationMemberDirSyncFragment';
import client from 'src/clients/apolloClient';

export const getFullOrganizationMemberFragmentFromCache = (userId: string) => {
  const currentUserFragment = client.readFragment({
    id: `FullOrganizationMember:${userId}`,
    fragment: FullOrganizationMemberDirSyncFragment,
  });
  return currentUserFragment;
};
