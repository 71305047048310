import throttle from 'lodash/throttle';

// Credit: https://gist.github.com/orlandovallejos/ed9c79dd67e96c25cfcc8f1008df9489

// drag source height
const OFFSET = 80;
// scroll speed
const VELOCITY = 15;

/*------------------------------------------------*/
/* GLOBAL VARIABLES
/*------------------------------------------------*/
let scrollIncrement = 0;
let isScrolling = false;
let sidebarElement = null;

/*------------------------------------------------*/
/* METHODS
/*------------------------------------------------*/

/**
 * Scroll up in the sidebar.
 */
const goUp = () => {
  scrollIncrement -= VELOCITY;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement >= 0) {
    window.requestAnimationFrame(goUp);
  }
};

/**
 * Scroll down in the sidebar.
 */
const goDown = () => {
  scrollIncrement += VELOCITY;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement <= sidebarElement.scrollHeight) {
    window.requestAnimationFrame(goDown);
  }
};

const onDragOver = (event) => {
  const clientRect = sidebarElement.getBoundingClientRect();
  const isMouseOnTop =
    scrollIncrement >= 0 && event.clientY > clientRect.top && event.clientY < clientRect.top + OFFSET;
  const isMouseOnBottom =
    scrollIncrement <= sidebarElement.scrollHeight &&
    event.clientY > window.innerHeight - OFFSET &&
    event.clientY <= window.innerHeight;

  if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
    isScrolling = true;
    scrollIncrement = sidebarElement.scrollTop;

    if (isMouseOnTop) {
      window.requestAnimationFrame(goUp);
    } else {
      window.requestAnimationFrame(goDown);
    }
  } else if (!isMouseOnTop && !isMouseOnBottom) {
    isScrolling = false;
  }
};

const throttleOnDragOver = throttle(onDragOver, 300);

const addEventListenerForSidebar = () => {
  sidebarElement = document.getElementById('EscalationLadderWrapper');
  sidebarElement.addEventListener('dragover', throttleOnDragOver);
};

const removeEventListenerForSidebar = () => {
  sidebarElement.removeEventListener('dragover', throttleOnDragOver);
  isScrolling = false;
};

export default {
  addEventListenerForSidebar,
  removeEventListenerForSidebar,
};
