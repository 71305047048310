import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Info from 'src/assets/svgs/Info';
import theme from 'src/assets/styles/theme';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { AddNoteButton } from 'src/components/hidden-notes/SharedCompoment';
import {
  DELETE_NOTE,
  EDIT_NOTE,
  PUBLIC_ACCESS,
  EDIT,
  PROFILE,
  NOTE_LIMIT,
  DISCARDED,
  NOTE_DELETED,
  NO_NOTES_FOUND,
  NO_ROLE_NOTES_FOUND,
} from 'src/constants/hiddenNotes';
import AvatarComponent from 'src/components/shared/AvatarComponent';
import { PublicAccess, PrivateAccess } from 'src/components/hidden-notes/SharedCompoment';
import { IconButton, Menu, MenuItem, withStyles } from '@material-ui/core';
import DeleteNoteModal from 'src/components/hidden-notes/DeleteNoteModal';
import DiscardNoteModal from 'src/components/hidden-notes/DiscardNoteModal';
import NoteForm from 'src/components/hidden-notes/NoteForm';
import { SCHEDULING } from 'src/constants/scheduler';
import { AUTH_INFO } from 'src/constants/storageKeys';
import { AuthPayload } from 'src/types/Auth';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { HCBodyTwo } from 'src/components/shared/HypercareComponents';
import { typedUseSelector } from 'src/redux/store';
import { NOTE_CONTENT } from 'src/constants/strings';
import { HiddenNotes } from 'src/types';

const AddNoteButtonContainer = styled.div`
  margin-bottom: 16px;
  display: inline-flex;
`;

const NotesListInnerContainer = styled.div`
  padding-bottom: 8px;
  line-break: anywhere;
`;

const NotesListContainer = styled.div`
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  padding: 16px;
  gap: 8px;
  background: #fafafa;
  border: 1px solid ${theme.borderBottomLight};
  border-radius: 4px;
  margin-bottom: 8px;
`;

const NoNotesContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const StyledInfo = styled(Info)`
  height: 16px !important;
  width: 16px !important;
`;

const NoNotesFound = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.mainFontColor};
  margin-left: 8px;
`;

const NoteUserContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const RightSection = styled.div`
  flex: 5;
  min-width: 0;
`;

const MessageDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  align-items: center;
  color: ${theme.darkenFontColor};
`;

const MessageContent = styled.div<{ page: string }>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  white-space: pre-wrap;
  justify-content: space-between;
  color: ${theme.mainFontColor};
  max-width: ${(props) => (props.page === SCHEDULING ? '425px' : '')};
  word-break: ${(props) => (props.page === SCHEDULING ? 'break-word' : '')};
`;

const NoteAction = styled.div`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
`;

const NoteDescription = styled.div`
  margin-top: 12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.darkenFontColor};
  display: grid;
`;

const NoteAccess = styled.div<{ access: string; page: string }>`
  display: flex;
  max-width: fit-content;
  align-items: center;
  padding: 4px 12px;
  height: ${(props) => (props.page === SCHEDULING ? '34px' : '28px')};
  background: ${(props) => (props.access === PUBLIC_ACCESS ? theme.lighterTeal : theme.lighterGrey)};
  border-radius: 19px;
  margin-top: ${(props) => (props.page === SCHEDULING ? '' : '8px')};
`;

const StyledMenu = withStyles({
  paper: {
    background: theme.white,
    boxShadow: '0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    borderRadius: '4px',
    width: '180px',
  },
  list: {
    padding: '8px',
  },
})(Menu);

const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    height: '44px',
    '&:hover': {
      color: theme.errorRed,
    },
  },
})(MenuItem);

const StyledIconButton = styled(IconButton)<{ active: string }>`
  width: 24px !important;
  height: 24px !important;
  border-radius: 0px !important;
  background: ${(props) => props.active === 'true' && `${theme.borderBottomLight} !important;`};
  :hover {
    background: ${theme.borderBottomLight} !important;
    border-radius: 0px !important;
  }
`;

const NoRoleNotesContainer = styled.div`
  color: ${theme.mainFontColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const RoleNoteAddButtonContainer = styled.div`
  margin-top: 24px;
`;

interface NoteListProps {
  page: string;
  isAddNotesActive: boolean;
  setIsAddNotesActive: (isAddOpen: boolean) => void;
  submitForm: any;
  selectedNote: any;
  selectedNoteId: string;
  editableNoteId: string;
  isDeleteModalOpen: boolean;
  setSelectedNote: (selectedNote: HiddenNotes) => void;
  setSelectedNoteId: (selectedNoteId: string) => void;
  setEditableNoteId: (editableNoteId: string) => void;
  setDeleteModal: (isDeleteModalOpen: boolean) => void;
  isDiscard: boolean;
  setIsDiscard: (boolean) => void;
  notes: any;
  deleteNote: (noteId?: string, roleId?: number) => Promise<{ error?: any; result?: undefined | string }>;
}

const NoteList = ({
  page,
  isAddNotesActive,
  setIsAddNotesActive,
  submitForm,
  selectedNote,
  selectedNoteId,
  editableNoteId,
  isDeleteModalOpen,
  setSelectedNote,
  setSelectedNoteId,
  setEditableNoteId,
  setDeleteModal,
  isDiscard,
  setIsDiscard,
  notes,
  deleteNote,
}: NoteListProps) => {
  const [action, setAction] = React.useState(null);
  const currentRoleSelected = typedUseSelector((state) => state.monthlyScheduleReducer.currentSelectedRole);

  const breakNewLineFormat = (note: string) => {
    const formatedDescription = note.split('\n').map((str, index) => <p key={index}>{str}</p>);

    return formatedDescription;
  };

  const handleOpenNoteAction = (event, note: HiddenNotes) => {
    if (isAddNotesActive) {
      handleDiscardModal();
      return;
    }
    setAction(event.currentTarget);
    setSelectedNote(note);
    setSelectedNoteId(note.id);
  };

  const handleNoteAction = (actionItem: string) => {
    setAction(null);
    if (actionItem === DELETE_NOTE) {
      setDeleteModal(true);
    } else {
      if (editableNoteId !== selectedNoteId) {
        setIsAddNotesActive(true);
        setEditableNoteId(selectedNoteId);
      }
    }
  };

  const handleClose = () => {
    setAction(null);
    setSelectedNoteId(null);
  };

  const handleDiscardModal = () => {
    setIsDiscard(true);
  };

  const handleDiscard = () => {
    setIsAddNotesActive(false);
    setEditableNoteId(null);
    setSelectedNoteId(null);
  };

  const handleDiscardNotes = () => {
    handleDiscard();
    setIsDiscard(false);
    toast.success(DISCARDED, {
      className: 'toast-message toast-discard ',
      autoClose: 5000,
    });
  };

  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload = authInfo ? JSON.parse(authInfo) : null;
  const userId = parsedAuthInfo?.user?.id;

  const handleDeleteNotes = async (noteId: string, setIsSubmitting: any) => {
    setIsSubmitting(true);

    const result = await deleteNote(noteId, currentRoleSelected?.roleId);

    if (result?.result) {
      toast.success(NOTE_DELETED, {
        className: 'toast-message toast-discard ',
        autoClose: 5000,
      });
    }

    if (result?.error) {
      toast.error(result.error, {
        className: 'Toast-Container',
        autoClose: 10000,
      });
    }

    setDeleteModal(false);
    setIsSubmitting(false);
  };

  const deleteNoteOptionButtonPressed = () => {
    handleNoteAction(DELETE_NOTE);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileNoteDeleteMenuOptionPressed,
    });
  };

  const editNoteOptionButtonPressed = () => {
    handleNoteAction(EDIT_NOTE);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileNoteEditMenuOptionPressed,
    });
  };

  return (
    <>
      {notes.length > 0 ? (
        <>
          {!isAddNotesActive && (
            <AddNoteButtonContainer>
              <AddNoteButton
                color={
                  notes.length >= NOTE_LIMIT
                    ? theme.warmGreyColor
                    : page === PROFILE
                    ? theme.errorRed
                    : theme.mainTealColor
                }
                isNoteExceedLimit={notes.length >= NOTE_LIMIT}
                setIsAddNotesActive={setIsAddNotesActive}
              />
            </AddNoteButtonContainer>
          )}
          {notes.map((note, index) => {
            return editableNoteId === note.id ? (
              <NoteForm
                key={index}
                page={page}
                action={EDIT}
                selectedNoteId={selectedNoteId}
                noteDescription={note.note}
                selectedAccess={note.access}
                handleCancelledNote={handleDiscardModal}
                handleResetInputs={handleDiscard}
                submitForm={submitForm}
              />
            ) : (
              <NotesListInnerContainer id={note.id} key={note.id}>
                <NotesListContainer>
                  <NoteUserContainer>
                    <LeftSection>
                      <AvatarComponent profileSize="medium" user={note.createdBy} />
                    </LeftSection>
                    <RightSection>
                      <MessageDetails>
                        <UserInfo>
                          {note.createdBy?.firstName} {note.createdBy?.lastName}{' '}
                          {note.createdBy?.id === userId ? '(You)' : ''}
                        </UserInfo>
                        <NoteAction>
                          <StyledIconButton
                            id={`action-menu-${note.id}`}
                            aria-label="more"
                            aria-controls="action-items"
                            aria-haspopup="true"
                            onClick={(e) => handleOpenNoteAction(e, note)}
                            active={selectedNoteId === note.id ? 'true' : 'false'}
                          >
                            <MoreHorizIcon />
                          </StyledIconButton>
                        </NoteAction>
                      </MessageDetails>
                      <MessageContent page={page}>{NOTE_CONTENT(note, userId)}</MessageContent>
                    </RightSection>
                  </NoteUserContainer>
                  <NoteDescription>{breakNewLineFormat(note.note)}</NoteDescription>
                  <NoteAccess access={note.access} page={page}>
                    {note.access === PUBLIC_ACCESS ? <PublicAccess page={page} /> : <PrivateAccess page={page} />}
                  </NoteAccess>
                </NotesListContainer>
              </NotesListInnerContainer>
            );
          })}
          <StyledMenu
            id="action-items"
            anchorEl={action}
            keepMounted
            open={Boolean(action)}
            onClose={handleClose}
            getContentAnchorEl={null}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            disableScrollLock={true}
          >
            <StyledMenuItem key={EDIT_NOTE} onClick={editNoteOptionButtonPressed}>
              {EDIT_NOTE}
            </StyledMenuItem>
            <StyledMenuItem key={DELETE_NOTE} onClick={deleteNoteOptionButtonPressed}>
              {DELETE_NOTE}
            </StyledMenuItem>
          </StyledMenu>
        </>
      ) : !isAddNotesActive ? (
        page === SCHEDULING ? (
          <NoRoleNotesContainer>
            <HCBodyTwo>{NO_ROLE_NOTES_FOUND}</HCBodyTwo>
            <RoleNoteAddButtonContainer>
              <AddNoteButton color={theme.mainTealColor} setIsAddNotesActive={setIsAddNotesActive} />
            </RoleNoteAddButtonContainer>
          </NoRoleNotesContainer>
        ) : (
          <NotesListContainer>
            <NoNotesContainer>
              <StyledInfo circlefill={theme.warmGreyColor} strokefill={'white'} />
              <NoNotesFound data-testid="no-notes-found">{NO_NOTES_FOUND}</NoNotesFound>
            </NoNotesContainer>
            <AddNoteButton
              color={page === PROFILE ? theme.errorRed : theme.mainTealColor}
              setIsAddNotesActive={setIsAddNotesActive}
            />
          </NotesListContainer>
        )
      ) : (
        <></>
      )}
      {isDeleteModalOpen && (
        <DeleteNoteModal
          openModal={isDeleteModalOpen}
          note={selectedNote}
          handleCloseModal={() => {
            setSelectedNoteId(null);
            setDeleteModal(false);
          }}
          handlePrimayButton={handleDeleteNotes}
        />
      )}
      {isDiscard && (
        <DiscardNoteModal
          open={isDiscard}
          handleCloseModal={() => setIsDiscard(false)}
          handleDiscard={() => handleDiscardNotes()}
        />
      )}
    </>
  );
};

export default NoteList;
