import React from 'react';
import { DEPARTMENT } from 'src/constants/organizationTypes';
import CheckIcon from 'src/assets/svgs/CheckIcon';
import { OrganizationScope, SiteScope, UserOrganizationSwitcherPayload } from 'src/types';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  organization: OrganizationScope;
  site: SiteScope;
  currentOrganization: UserOrganizationSwitcherPayload;
  handleChange: (option: UserOrganizationSwitcherPayload, index: number) => void;
  isReadOnly: boolean;
}

const RenderDepartments = ({ organization, site, currentOrganization, handleChange, isReadOnly }: Props) => {
  return (
    <React.Fragment>
      {site.departments &&
        site.departments
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .map((department, k) => {
            return (
              department.isAdmin && (
                <li
                  title={`${department.id}`}
                  className={`dropdownOrganizationMenu__item dropdownOrganizationMenu__item--department`}
                  key={`${organization.id}-${site.id}-${department.id}-${k}`}
                  onClick={() =>
                    handleChange(
                      {
                        name: site.name + ' - ' + department.name,
                        type: DEPARTMENT,
                        department_id: department.id,
                        image: department.image,
                        site_id: site.id,
                        organization_id: organization.id,
                        organization_name: organization.name,
                      },
                      k,
                    )
                  }
                >
                  {!isReadOnly && <Checkbox color="primary" checked={Boolean(department.isSelected)} />}
                  {isReadOnly ? '・ ' : ''}
                  {isReadOnly &&
                  currentOrganization.department_id === department.id &&
                  currentOrganization.type === DEPARTMENT &&
                  currentOrganization.site_id === site.id ? (
                    <React.Fragment>
                      <strong>{department.name}</strong>
                      <CheckIcon />
                    </React.Fragment>
                  ) : (
                    department.name
                  )}
                </li>
              )
            );
          })}
    </React.Fragment>
  );
};

export default RenderDepartments;
