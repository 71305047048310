import { VirtualPagerStatusTypes } from 'src/types/VirtualPagers';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';

const StatusPill = styled.span<{ status: VirtualPagerStatusTypes }>`
  background: ${(props) =>
    props.status === VirtualPagerStatusTypes.PENDING
      ? theme.yellowBackground
      : props.status === VirtualPagerStatusTypes.ACTIVE
      ? theme.blueBackground
      : props.status === VirtualPagerStatusTypes.DISABLED
      ? theme.greyBackground
      : 'auto'} !important;
  border: ${(props) =>
    props.status === VirtualPagerStatusTypes.PENDING
      ? `1px solid ${theme.yellowBorder}`
      : props.status === VirtualPagerStatusTypes.ACTIVE
      ? `1px solid ${theme.blueBorder}`
      : props.status === VirtualPagerStatusTypes.DISABLED
      ? `1px solid ${theme.greyBorder}`
      : 'none'};

  border-radius: 19px;
  padding: 2px 8px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
`;

export default StatusPill;
