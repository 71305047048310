import React from 'react';
import moment from 'moment';
import PageCallbackModal from 'src/components/modals/SchedulePageCallbackModal';
import ShiftAssignees from 'src/pages/SchedulingPage/scheduling-layout/scheduling-grid/ShiftAssignees';
import ScheduleRemoveShiftModal from 'src/components/modals/ScheduleRemoveShiftModal';
import { Assignee, RemoveShiftPayload, MonthlyCalendar, Role, ScheduleModalPayload } from 'src/types';
import {
  GridBodyRow,
  GridBodyRowDate,
  RowDateHolder,
  BoldDate,
  GridBodyRowRole,
  RoleHolderOvernight,
  HiddenEditRoleHolderOvernight,
  RequiredShiftGapHolderOvernight,
  GapInfo,
  StyledPlusOneContainer,
  HolidayDataHolder,
} from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';
import { isMinutesHourBefore, minutesOfDay } from 'src/pages/SchedulingPage/scheduling-layout/schedulingHelper';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';

interface Props {
  monthlyCalendar: MonthlyCalendar;
  flag: boolean;
  handleOpenScheduleModal: (args: ScheduleModalPayload) => void;
  isDepartment: boolean;
  gridBodyWidth: any;
  holidays: any;
}

const RoleBodyDetailOvernight = ({
  monthlyCalendar,
  gridBodyWidth,
  flag,
  handleOpenScheduleModal,
  isDepartment,
  holidays,
}: Props) => {
  const [selectedAssignee, setSelectedAssignee] = React.useState<Assignee>(null);
  const [showPageCallbackModal, setPageCallbackModal] = React.useState<boolean>(false);
  const [removeShiftPayload, setRemoveShiftPayload] = React.useState<RemoveShiftPayload>(null);

  // TODO: only show isRequired shift for now for all the scheduleState

  function onMouseUp(role: Role, date: string, targetAssignee = null) {
    if (flag || !isDepartment) return;
    handleOpenScheduleModal({
      role,
      date,
      targetAssignee: null,
      initialAssigneeGap: targetAssignee,
    });
  }

  function isHoliday(date, flag) {
    let month = date?.toString().split('-')[1];
    let holidaysthismonth = [];
    let isHolidayDate = null;
    holidays.map((holiday) => {
      if (holiday.date.split('-')[1] == month) {
        holidaysthismonth.push({ day: parseInt(holiday.date.split('-')[2]), holidayName: holiday.nameEn });
      }
    });
    holidaysthismonth.map((holiday) => {
      if (holiday.day === parseInt(date?.toString().split('-')[2])) {
        isHolidayDate = holiday;
      }
    });
    if (flag === 2) {
      if (isHolidayDate === null) return false;
      else return true;
    }
    if (flag) {
      return moment(date).weekday() === 6 || moment(date).weekday() === 0 || isHolidayDate !== null;
    }
    return isHolidayDate?.holidayName;
  }

  const RenderDefaultEditHolder = ({ role, date, gap }: { role: Role; date: string; gap?: any }) => {
    return (
      <HiddenEditRoleHolderOvernight
        shiftOrder={gap?.startTime?.format('HH:mm').replace(':', '')}
        onMouseUp={() => onMouseUp(role, date, gap?.initialAssignee)}
        className={'HiddenEmptyShift' + role.roleId}
      >
        <div>
          <span>Edit Shift</span>
          <span>
            <>
              {`${gap?.startTime?.format('HH:mm')} - ${gap?.endTime?.format('HH:mm')}`}
              {minutesOfDay(gap?.endTime) <= minutesOfDay(gap?.startTime) && (
                <StyledPlusOneContainer>+1</StyledPlusOneContainer>
              )}
            </>
          </span>
        </div>
      </HiddenEditRoleHolderOvernight>
    );
  };

  return (
    <React.Fragment>
      {Object.keys(monthlyCalendar).map((date, index) => {
        const rolesPerDate = monthlyCalendar[date];
        return (
          <GridBodyRow key={index} style={{ width: gridBodyWidth }} isHoliday={isHoliday(date, 1)}>
            <GridBodyRowDate isHoliday={isHoliday(date, 1)}>
              <RowDateHolder>
                {moment(date, 'YYYY-MM-DD').format('dddd')}
                <br />
                <BoldDate>{moment(date, 'YYYY-MM-DD').format('MMM-DD')}</BoldDate>
                {isHoliday(date, 2) && (
                  <HolidayDataHolder title={isHoliday(date, 0)}>{isHoliday(date, 0)}</HolidayDataHolder>
                )}
              </RowDateHolder>
            </GridBodyRowDate>

            <GridBodyRowRole>
              {rolesPerDate.map((nestedPayload, index) => {
                const { role, assignee, scheduleGap } = nestedPayload;

                return (
                  <RoleHolderOvernight
                    key={index}
                    onMouseEnter={() => {
                      const boxes = Array.from(
                        document.getElementsByClassName(
                          'HiddenEmptyShift' + role.roleId,
                        ) as HTMLCollectionOf<HTMLElement>,
                      );

                      boxes.forEach((box) => {
                        box.style.visibility = 'visible';
                      });
                    }}
                    onMouseLeave={() => {
                      const boxes = Array.from(
                        document.getElementsByClassName(
                          'HiddenEmptyShift' + role.roleId,
                        ) as HTMLCollectionOf<HTMLElement>,
                      );

                      boxes.forEach((box) => {
                        box.style.visibility = 'hidden';
                      });
                    }}
                  >
                    <ShiftAssignees role={role} date={date} isDepartment={isDepartment} assignees={assignee} />
                    {scheduleGap.length > 0 &&
                      scheduleGap.map((gap, i) => {
                        if (gap.shouldRender && gap.isRequired) {
                          return (
                            <RequiredShiftGapHolderOvernight
                              key={i}
                              isRequired={gap.isRequired}
                              shiftOrder={gap?.startTime?.format('HH:mm').replace(':', '')}
                              onMouseUp={() => onMouseUp(role, date, gap?.initialAssignee)}
                            >
                              <GapInfo>
                                <span>{gap.isRequired && 'required'} shift empty</span>
                                <span>
                                  {gap?.startTime?.format('HH:mm')} - {gap?.endTime?.format('HH:mm')}
                                  {minutesOfDay(gap?.endTime) <= minutesOfDay(gap?.startTime) && (
                                    <StyledPlusOneContainer color="red">+1</StyledPlusOneContainer>
                                  )}
                                </span>
                              </GapInfo>
                            </RequiredShiftGapHolderOvernight>
                          );
                        }
                      })}
                    {!role.required &&
                      scheduleGap &&
                      scheduleGap.length > 0 &&
                      scheduleGap.map((gap, i) => {
                        if (gap.shouldRender && !gap.isRequired) {
                          return <RenderDefaultEditHolder key={i} role={role} date={date} gap={gap} />;
                        }
                      })}
                  </RoleHolderOvernight>
                );
              })}
            </GridBodyRowRole>
          </GridBodyRow>
        );
      })}
      {showPageCallbackModal && (
        <PageCallbackModal
          showModal={showPageCallbackModal}
          selectedAssignee={selectedAssignee}
          handleCloseModal={() => setPageCallbackModal(false)}
        />
      )}
      {Boolean(removeShiftPayload) && (
        <ScheduleRemoveShiftModal
          showModal={Boolean(removeShiftPayload)}
          removeShiftPayload={removeShiftPayload}
          handleCloseModal={() => setRemoveShiftPayload(null)}
        />
      )}
    </React.Fragment>
  );
};

export default RoleBodyDetailOvernight;
