import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { ARBITRARY_DATE } from 'src/constants/scheduler';
import StartEndTimePicker from 'src/pages/SchedulingPage/scheduling-layout/StartEndTimePicker';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  ThemeProvider,
  createMuiTheme,
  DialogContent,
  Divider,
} from '@material-ui/core';
import theme from 'src/assets/styles/theme';
import {
  FieldInputLabel,
  PrimaryButton,
  SecondaryButton,
  StyledDialogActions,
} from 'src/components/shared/HypercareComponents';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import 'src/assets/styles/FormStyles.scss';
import MetricaidComponent, { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';

const CreateRoleTheme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans',
  },
  palette: {
    primary: { main: theme.mainTealColor },
  },
});

const newRoleValidationSchema = Yup.object().shape({
  roleName: Yup.string().required('Role name is required!'),
  repeatRule: Yup.string().required('Repeat rule is required!'),
});

const isTimeValid = (startTime, endTime, setFieldError, isAllDayShift) => {
  if (!startTime) {
    setFieldError('startTime', 'Start time for this role is required!');
    return false;
  }
  if (!isAllDayShift) {
    if (!endTime) {
      setFieldError('endTime', 'End time for this role is required!');
      return false;
    }
    if (!endTime.isValid()) {
      setFieldError('endTime', 'End time is not valid!');
      return false;
    }
  }
  if (!startTime.isValid()) {
    setFieldError('startTime', 'Start time is not valid!');
    return false;
  }
  if (endTime - startTime === 0 && !isAllDayShift) {
    setFieldError('startTime', 'Start time cannot be same as end time!');
    setFieldError('endTime', 'Please select 24 hour shift if you intend to set it the next day');
    return false;
  }
  return true;
};

interface FormValue {
  endTime: moment.Moment;
  startTime: moment.Moment;
  isAllDayShift: boolean;
  repeatRule: 'daily' | 'weekends' | 'weekdays';
  roleName: string;
  isRequired: boolean;
}

interface Props {
  handleCreateRole: (formValue: FormValue) => Promise<any>;
  isWaitingDeletion: boolean;
  isEditState: boolean;
  handleDeleteRole: () => Promise<void>;
  handleUpdateRole: (formValue: FormValue) => Promise<any>;
  prefillData: FormValue;
  closeModal: () => void;
}

const ScheduleCreateRoleForm = ({
  handleCreateRole,
  isWaitingDeletion,
  isEditState,
  handleDeleteRole,
  handleUpdateRole,
  prefillData,
  closeModal,
}: Props) => {
  const [metricaidErrorState, setMetricaidErrorState] = React.useState<boolean>(false);
  return (
    <Formik
      initialValues={{
        roleName: isEditState ? prefillData.roleName : '',
        startTime: isEditState ? prefillData.startTime : null,
        endTime: isEditState ? prefillData.endTime : null,
        repeatRule: isEditState ? prefillData.repeatRule : 'daily',
        isAllDayShift: isEditState ? prefillData.isAllDayShift : false,
        isRequired: isEditState ? prefillData.isRequired : false,
      }}
      validationSchema={newRoleValidationSchema}
      onSubmit={(values, { resetForm, setFieldError, setSubmitting }) => {
        const { isAllDayShift, startTime, endTime } = values;

        const isValid = isTimeValid(startTime, endTime, setFieldError, isAllDayShift);
        if (!isValid) {
          setSubmitting(false);
          return;
        }

        const formatedFormValues = {
          ...values,
          startTime: moment(new Date(`${ARBITRARY_DATE}, ${startTime.format('HH:mm')}`)),
          endTime: isAllDayShift ? null : moment(new Date(`${ARBITRARY_DATE}, ${endTime.format('HH:mm')}`)),
        };

        if (isEditState) {
          handleUpdateRole(formatedFormValues).catch((e) => {
            switch (e) {
              case 'network error':
                setSubmitting(false);
                setFieldError('general', 'unknown backend error occurred, please try again');
                break;
              case METRICAIDERRORCODE:
                setMetricaidErrorState(true);
                setSubmitting(false);
                resetForm();
                break;
              default:
                resetForm();
                setFieldError('general', 'Unknown error occurred, please try again');
            }
          });
        } else {
          handleCreateRole(formatedFormValues).catch((e) => {
            switch (e) {
              case 'network error':
                setSubmitting(false);
                setFieldError('general', 'unknown backend error occurred, please try again');
                break;
              case METRICAIDERRORCODE:
                setMetricaidErrorState(true);
                setSubmitting(false);
                resetForm();
                break;
              default:
                resetForm();
                setFieldError('general', 'Unknown error occurred, please try again');
            }
          });
        }
      }}
      render={(props) => {
        const { values, errors, touched, isSubmitting, handleChange, setFieldValue, handleSubmit } =
          props as FormikProps<any>;
        const { roleName, repeatRule, isAllDayShift, isRequired, startTime, endTime } = values as FormValue;

        const hasErrors = Object.keys(errors).length > 0;
        return (
          <ThemeProvider theme={CreateRoleTheme}>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {metricaidErrorState && <MetricaidComponent />}
                <FieldInputLabel>Role Name</FieldInputLabel>
                <TextField
                  fullWidth
                  autoFocus
                  id="roleName"
                  name="roleName"
                  placeholder="Role Name"
                  value={roleName}
                  variant={'outlined'}
                  onChange={handleChange}
                  helperText={touched.roleName ? errors.roleName : ''}
                  error={touched.roleName && Boolean(errors.roleName)}
                />

                <div style={{ display: 'flex', margin: '16px 0' }}>
                  <StartEndTimePicker
                    index={null}
                    span={6}
                    prefillStartTime={startTime}
                    prefillEndTime={endTime}
                    startTimeError={errors.startTime}
                    endTimeError={errors.endTime}
                    setFieldValue={setFieldValue}
                    isAllDayShift={isAllDayShift}
                  />
                </div>

                <FormControlLabel
                  control={
                    <Checkbox id="isAllDayShift" name="isAllDayShift" checked={isAllDayShift} onChange={handleChange} />
                  }
                  label="This is a 24 hour shift"
                />
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox id="isRequired" name="isRequired" checked={isRequired} onChange={handleChange} />
                    }
                    label="This is a required shift"
                  />
                </div>
                <div className="createRoleForm__dropdownHolder">
                  <FieldInputLabel>Repeat</FieldInputLabel>
                  <FormControl fullWidth variant={'outlined'}>
                    <Select
                      variant={'outlined'}
                      id="repeatRule"
                      name="repeatRule"
                      value={repeatRule}
                      onChange={handleChange}
                      IconComponent={KeyboardArrowDownIcon}
                      error={touched.repeatRule && Boolean(errors.repeatRule)}
                    >
                      <MenuItem value={'daily'}>Daily</MenuItem>
                      <MenuItem value={'weekdays'}>Weekdays</MenuItem>
                      <MenuItem value={'weekends'}>Weekends</MenuItem>
                    </Select>
                    {touched.repeatRule && (
                      <FormHelperText error={Boolean(errors.repeatRule)}>{errors.repeatRule}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                {(isSubmitting || isWaitingDeletion) && (
                  <span className="modalForm__sendRequestLabel">sending request...</span>
                )}
                {Boolean(errors.general) && <span className="modalForm__inputError">{errors.general}</span>}
              </DialogContent>
              <Divider />
              <StyledDialogActions>
                {!isEditState ? (
                  <React.Fragment>
                    <SecondaryButton onClick={() => closeModal()}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit" disabled={hasErrors || isSubmitting}>
                      Create Roles
                    </PrimaryButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <SecondaryButton
                      type="button"
                      disabled={hasErrors || isSubmitting || isWaitingDeletion}
                      onClick={handleDeleteRole}
                    >
                      Delete role
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={hasErrors || isSubmitting || isWaitingDeletion}>
                      Save
                    </PrimaryButton>
                  </React.Fragment>
                )}
              </StyledDialogActions>
            </form>
          </ThemeProvider>
        );
      }}
    />
  );
};

export default React.memo(ScheduleCreateRoleForm);
