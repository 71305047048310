import gql from 'graphql-tag';

export default gql`
  mutation InviteUserToOrganization(
    $organizationId: Int!
    $addresses: [CreateAddressInput!]!
    $licenseExpiryTime: String
  ) {
    admin {
      locating {
        organization(id: $organizationId) {
          inviteUsers(addresses: $addresses, licenseExpiryTime: $licenseExpiryTime)
        }
      }
    }
  }
`;
