import gql from 'graphql-tag';

export const DepartmentFragments = gql`
  fragment DepartmentFragments on Department {
    name
    id
    image
    isAdmin
  }
`;

export const OrganizationFragments = gql`
  fragment OrganizationFragments on Organization {
    name
    id
    image
    isAdmin
    termsOfServiceUrl
  }
`;

export const SiteFragments = gql`
  fragment SiteFragments on Site {
    name
    id
    image
    isAdmin
    departments(filter: false) {
      ...DepartmentFragments
    }
  }
  ${DepartmentFragments}
`;
