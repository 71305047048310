import gql from 'graphql-tag';
import { FullOrganizationMemberMutationFragment } from 'src/gql/v2/fragment/FullOrganizationMemberMutationFragment';

export const UPDATE_PROFILE_FIELDS = gql`
  mutation updateProfile($organizationalUnit: OrganizationalUnitInput!, $userId: ID!, $details: MemberDetails!) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationMutation {
            __typename
            member(id: $userId) {
              __typename
              ...FullOrganizationMemberMutationFragment
            }
          }
          ... on AdminSiteMutation {
            __typename
            member(id: $userId) {
              __typename
              ...FullOrganizationMemberMutationFragment
            }
          }
          ... on AdminDepartmentMutation {
            __typename
            member(id: $userId) {
              __typename
              ...FullOrganizationMemberMutationFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationMemberMutationFragment}
`;
