import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <ContentLoader height={150} width={340} style={{ padding: '0 5%', height: '100%', width: '100%' }}>
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="330" height="10" />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="330" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="330" height="10" />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="105" rx="5" ry="5" width="330" height="10" />
    <circle cx="10" cy="140" r="8" />
    <rect x="25" y="135" rx="5" ry="5" width="330" height="10" />
  </ContentLoader>
);
