import gql from 'graphql-tag';
import { roleFragmentStringWithoutShifts } from 'src/gql/fragment/RoleFragment';
import ShiftFragment from '../fragment/ShiftFragment';

export default gql`
  mutation CreateRole(
    $name: String!
    $startTime: String!
    $duration: Int! #seconds
    $required: Boolean! #default = false
    $departmentId: Int!
    $repeatRule: RoleRepeatRule!
    $scheduleId: Int!
  ) {
    admin {
      locating {
        department(id: $departmentId) {
          createRole(
            details: {
              name: $name
              startTime: $startTime
              duration: $duration
              required: $required
              repeatRule: $repeatRule
              scheduleId: $scheduleId
            } #CreateRoleDetails
          ) {
            id
            name
            auditId
            startTime
            endTime
            duration
            repeatRule
            pagerNumber
            required
            displayPosition
            currentShift {
              ...ShiftFragment
            }
            nextShift {
              ...ShiftFragment
            }
            createdAt
            updatedAt
            __typename
          }
        }
      }
    }
  }
  ${ShiftFragment}
`;
