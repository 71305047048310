import { useState } from 'react';
import { HCFilterOption } from 'src/components/@hc-ui/filters/variants/HCFilterSimple';

export const useInviteUsersFilter = () => {
  const [selectedUsers, setSelectedUsers] = useState<HCFilterOption[]>([]);

  const clearAllFilters = () => {
    setSelectedUsers([]);
  };

  const clearAllFiltersAndApply = () => {
    clearAllFilters();
  };

  return {
    selectedUsers,
    setSelectedUsers,
    clearAllFilters,
    clearAllFiltersAndApply,
  };
};
