import React, { useState } from 'react';
import { FormikHandlers, FormikActions, FormikErrors } from 'formik';
import { Mutation } from 'react-apollo';
import { FetchResult } from 'apollo-link';
import { ApolloQueryResult } from 'apollo-client';
import ProfileBox from 'src/pages/UserProfilePage/profile-layout/ProfileBox';
import ProfileForm from 'src/components/forms/ProfilePageForm';
import ProfileFooter from 'src/pages/UserProfilePage/profile-layout/ProfileFooter';
import { toast } from 'react-toastify';
import { EDIT_PROFILE, EDIT_PROFILE_WITHOUT_NOTE } from 'src/gql/mutation/EditProfileMutation';
import { ADDRESS_ALREADY_INUSE, INVALID_PHONE_NUMBER } from 'src/constants/formErrors';
import client from 'src/clients/apolloClient';
import AddUserAddressMutation from 'src/gql/mutation/AddUserAddressesMutation';
import RemoveUserAddressesMutation from 'src/gql/mutation/RemoveUserAddressesMutation';
import { diffTwoAddressesArrays } from 'src/utils/diffTwoAddressArray';
import {
  UserProfileValues,
  UpdateUserProfileResult,
  UserAddress,
  User,
  UserLicensingStatus,
  FetchUserProfileResultFinal,
} from 'src/types';
import clonedeep from 'lodash.clonedeep';
import sleep from 'src/utils/sleep';
import { removePlusSignWithNumber } from 'src/utils/formatPhoneNumber';
import { AuthContext } from 'src/auth';
import SetUserLicenseExpiryMutation from 'src/gql/mutation/SetUserLicenseExpiryMutation';
import UserDeviceList from 'src/pages/UserProfilePage/profile-layout/UserDeviceList';
import ProfileHiddenNotes from 'src/pages/UserProfilePage/profile-layout/ProfileHiddenNotes';
import 'src/assets/styles/ProfileStyles.scss';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { LICENSED, UNLICENSED } from 'src/constants/user';
import ProfileDangerZone from 'src/pages/UserProfilePage/profile-layout/ProfileDangerZone';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { ProfileConfigurableFieldsForm } from './components/ProfileConfigurableFieldsForm';

interface Props {
  user: User;
  isAdmin: boolean;
  selfId: string;
  userLicenseStatus: UserLicensingStatus;
  updateAuthUserInfo: (user: User) => void;
  hiddenNotes: boolean;
  reFetchData: (variables?: Record<string, any>) => Promise<ApolloQueryResult<FetchUserProfileResultFinal>>;
}

const UserProfile = ({
  user,
  isAdmin,
  selfId,
  userLicenseStatus,
  updateAuthUserInfo,
  hiddenNotes,
  reFetchData,
}: Props) => {
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const configurableProfileFieldsFlag = IsFeatureFlagEnabled(FeatureFlagResult.configurableProfileFields);
  const isUserDeviceListFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.userDeviceList);

  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCPFEditing, setIsCPFEditing] = useState(false);
  const [currentAddresses, setCurrentAddresses] = useState<UserAddress[]>(user?.addresses);

  const formikRenderProps = {
    submitFormik: null,
    errors: null,
    resetForm: null,
  } as {
    submitFormik: FormikHandlers['handleSubmit'];
    errors: FormikErrors<UserProfileValues>;
    resetForm: FormikActions<any>['resetForm'];
  };

  const bindFormik = (
    submitForm: FormikHandlers['handleSubmit'],
    errors: FormikErrors<UserProfileValues>,
    resetForm: FormikActions<any>['resetForm'],
  ) => {
    formikRenderProps.submitFormik = submitForm;
    formikRenderProps.errors = errors;
    formikRenderProps.resetForm = resetForm;
  };

  // TODO: note there is currently no updateAddress, have to delete then add (also have to diff beforehand)
  // wait on backend to implement updating, or else adding failed all address will be lost...

  const handleAddDeletedAddresses = async (diffResult: { added: UserAddress[]; deleted: UserAddress[] }) => {
    let result: FetchResult<any>;
    try {
      const userId = user.id;
      const { added, deleted } = diffResult;
      const removedAddressesPayload = deleted.map((ele) => ele.id);
      const addedAddressesPayload = added.map((ele) => {
        if (ele.type === 'phoneNumber') ele.address = `+1${ele.address}`;
        delete ele['id'];
        return ele;
      });

      if (deleted.length > 0) {
        await sleep(500); // backend input delay
        result = await client.mutate({
          mutation: RemoveUserAddressesMutation,
          variables: {
            userId,
            addressIds: removedAddressesPayload,
          },
        });
      }
      if (added.length > 0) {
        result = await client.mutate({
          mutation: AddUserAddressMutation,
          variables: {
            userId,
            addresses: addedAddressesPayload,
          },
        });
      }
      return Promise.resolve(result);
    } catch (e) {
      const mutationResult = result
        ? result.data.admin.user.removeAddresses || result.data.admin.user.addAddresses
        : null;
      if (e.networkError && e.networkError.result && e.networkError.result.errors) {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: e.networkError.result.errors[0].message,
          name: e.networkError.result.errors[0].name,
          mutationResult: mutationResult,
        };
      }
      // eslint-disable-next-line no-throw-literal
      throw {
        error: e,
        mutationResult: mutationResult,
      };
    }
  };

  const handleOnSubmitForm = async (values: UserProfileValues) => {
    try {
      setIsSubmitting(true);
      setIsEditing(true);
      if (formikRenderProps.errors && Object.keys(formikRenderProps.errors).length > 0)
        throw new Error('form validation error');

      const { addresses: preEditedAddress } = user;
      const { addresses: editedAddress } = values;
      const diffResult = diffTwoAddressesArrays(editedAddress, preEditedAddress);
      // addition && deletion mutation first, currently no set mutation to update
      await handleAddDeletedAddresses(diffResult);

      const { firstname, lastname, role } = values;
      const editedInfo = {
        userId: user.id,
        firstname,
        lastname,
        role,
      };

      // update rest of the field: first name, lastname, and role
      const updateResult = await client.mutate<UpdateUserProfileResult>({
        mutation: EDIT_PROFILE_WITHOUT_NOTE,
        variables: { ...editedInfo },
      });

      const { updateProfile } = updateResult.data.admin.user;
      updateLocalCache(updateProfile);

      handleAfterSubmitForm(true);

      const licenseStart = values.licenseStartDate.toISOString();
      const licenseEndDate = values.licenseEndDate ? values.licenseEndDate.toISOString() : null;
      await client.mutate({
        mutation: SetUserLicenseExpiryMutation,
        variables: {
          userId: user.id,
          licenseStart: licenseStart,
          licenseEnd: licenseEndDate,
        },
      });

      updateLocalCache({
        ...updateProfile,
        licenseStartTime: licenseStart,
        licenseExpiryTime: licenseEndDate,
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.updateProfile,
        params: {
          user_id: user.id,
        },
      });

      return Promise.resolve('success');
    } catch (e) {
      let errMsg: string;
      console.error(e);
      if (formikRenderProps.errors.licenseEndDate) {
        errMsg = 'The license end date you entered is incorrect';
      } else if (e.name && e.name === INVALID_PHONE_NUMBER) {
        errMsg = e.message;
      } else if (e.name && e.name === ADDRESS_ALREADY_INUSE) {
        errMsg = e.message;
      } else if (e.networkError && e.networkError.result && e.networkError.result.errors) {
        const errorName = e.networkError.result.errors[0].name;
        if (errorName === ADDRESS_ALREADY_INUSE) errMsg = 'This address already in use for other account';
      }
      handleAfterSubmitForm(false, errMsg);
      return Promise.reject('failed');
    }
  };

  const updateLocalCache = (updatedUser: User) => {
    const { id } = updatedUser;
    if (selfId === id) updateAuthUserInfo(updatedUser);
    reFetchData();
  };

  const handleCancelForm = () => {
    setIsEditing(false);
    formikRenderProps.resetForm();
  };

  const handleAfterSubmitForm = (isSuccess: boolean, customErrorMsg?: string) => {
    // TODO: hold editing state for optional error such as license date
    setIsEditing(false);
    if (isSuccess) {
      toast.success('Profile updated', {
        className: 'Toast-Container',
      });
    } else {
      toast.error(customErrorMsg ? customErrorMsg : 'Error when editing profile', {
        className: 'Toast-Container',
      });
      formikRenderProps.resetForm();
    }
    setIsSubmitting(false);
  };

  const userProfile = user;
  const isLicensed = userLicenseStatus === LICENSED;

  if (!userProfile) return null;

  const formattedUserProfile = {
    ...userProfile,
    addresses: clonedeep(userProfile.addresses).map((address) => {
      delete address['__typename'];
      if (address.type === 'phoneNumber') {
        address.address = removePlusSignWithNumber(address.address);
      }
      return address;
    }),
  };

  return (
    <Mutation mutation={EDIT_PROFILE}>
      {() => {
        return (
          <>
            <section className="userProfile">
              <div className="userProfile__wrapper">
                <ProfileBox
                  userLicenseStatus={userLicenseStatus}
                  user={formattedUserProfile}
                  onSubmitForm={() => formikRenderProps.submitFormik()}
                  onCancelForm={() => handleCancelForm()}
                  isSubmitting={isSubmitting}
                  isInputDisabled={!isEditing || isSubmitting}
                  enableEditing={() => setIsEditing(true)}
                />
                <ProfileForm
                  isInputDisabled={!isEditing || !isLicensed || isSubmitting}
                  user={formattedUserProfile}
                  isLicensed={isLicensed}
                  handleOnSubmitForm={handleOnSubmitForm}
                  onModeChange={() => {}}
                  bindFormik={bindFormik}
                  setCurrentAddresses={setCurrentAddresses}
                  currentAddresses={currentAddresses}
                />
                {isLicensed && <ProfileFooter isAdmin={isAdmin} />}
              </div>
            </section>
            {configurableProfileFieldsFlag && (
              <ProfileConfigurableFieldsForm
                user={user}
                mode={isCPFEditing ? 'edit' : 'view'}
                onSubmitCompleted={() => setIsCPFEditing(false)}
                isSelf={selfId === user.id}
                onModeChange={(mode) => setIsCPFEditing(mode === 'edit')}
              />
            )}
            {hiddenNotes && userLicenseStatus !== UNLICENSED && <ProfileHiddenNotes user={formattedUserProfile} />}
            {isUserDeviceListFlagEnabled && <UserDeviceList user={formattedUserProfile} />}
            {ldapDirectorySyncFlag && userLicenseStatus !== UNLICENSED && (
              <ProfileDangerZone user={formattedUserProfile} />
            )}
          </>
        );
      }}
    </Mutation>
  );
};

export default (props) => (
  <AuthContext.Consumer>
    {({ authInfo, updateAuthUserInfo }) => (
      <UserProfile {...props} selfId={authInfo.user.id} updateAuthUserInfo={updateAuthUserInfo} />
    )}
  </AuthContext.Consumer>
);
