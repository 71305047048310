import { Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import {
  PENDING_ADMIN,
  PENDING_USER,
  PresentedUserLicensingStatus,
  USER_MENU_OPTION,
} from '../../constants/inviteUserTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const StyledButton = styled(Button)`
  background: #ffffff !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  padding: 4px 12px !important;
  font-family: 'Nunito Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #4a4a4a !important;
  text-transform: inherit !important;
  flex-grow: 0 !important;
  margin: 0px 8px !important;
`;

export const StyledMenu = withStyles({
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    borderRadius: '8px',
  },
})(Menu);

export const StyledMenuItem = styled(MenuItem)<{ $remove?: boolean }>`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: ${(props) => (props.$remove ? '#FF0000' : '')} !important;
`;

export const UserListContainer = styled.div<{ paginatedContactsFeatureFlag: boolean }>`
  display: inline-block;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  width: 100%;
  min-width: 510px;
`;

export const StyledStatus = styled.span<{ status: PresentedUserLicensingStatus }>`
  ${(props) => {
    if (props.status === USER_MENU_OPTION.Licensed) return `color: ${props.theme.mainFontColor}; margin-left: 24px;`;
    if (props.status === USER_MENU_OPTION.Unlicensed)
      return `color: ${props.theme.mainButtonColor}; margin-left: 24px;`;
    if (props.status === PENDING_ADMIN)
      return `color: ${props.theme.mainTealColor}; 
    background: #E9FCF8; 
    border-radius: 3px; 
    padding: 4px 8px; 
    text-transform: uppercase;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;`;
    if (props.status === PENDING_USER)
      return `color: ${props.theme.darkenFontColor};font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-transform: none;`;
  }};
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      marginTop: '-9px',
      marginLeft: '76px',
    },
    licensedStatus: {
      marginTop: '-9px',
      marginLeft: '50px',
    },
    menu: {
      color: 'red',
    },
    actionButton: {
      gridArea: 'none',
      marginTop: '-46px',
    },
    adminActionButtonBorder: {
      border: '1px solid #00859A !important',
      borderRadius: '4px',
    },
    inviteeActionButtonBorder: {
      border: '1px solid #D8D8D8 !important',
      borderRadius: '4px',
    },
    categoryContainer: {
      position: 'relative',
      userSelect: 'none',
      display: 'flex',
      padding: '12px 12px 12px 0px',
    },
    categoryHeader: {
      width: '100%',
      minWidth: '510px',
      paddingTop: '12px',
      paddingBottom: '4px',
      display: 'flex',
      background: '#FAFAFA',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '17px',
      lineHeight: '24px',
      color: '#000000',
    },
    reject: {
      color: '#FF3E55',
    },
    userBlockSection: {
      gridTemplateColumns: '0.4fr 2fr 1fr 1fr 0.4fr',
      paddingRight: '15px',
      paddingLeft: '50px',
    },
    userNameSection: {
      cursor: 'default',
      marginBottom: '4px',
    },
    userFullName: {
      color: '#222222',
      fontSize: '17px',
      lineHeight: '24px',
      alignSelf: 'center',
      fontWeight: 700,
      maxWidth: '50%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:hover': {
        color: '#4A4A4A !important',
      },
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      display: 'flex',
      alignItems: 'center',
    },
    invitedBy: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    approvedBy: {
      textTransform: 'inherit',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#767676',
      marginTop: '-12px',
    },
    rejectToastr: {
      background: '#4A4A4A',
    },
    acceptToastr: {
      background: '#00859A',
    },
    joinDate: {
      marginLeft: '28px',
    },
  }),
);
