import React from 'react';
import { useDispatch } from 'react-redux';
import { authRegionData } from 'src/auth/authRegionData';
import { HYPERCARE_REGION } from 'src/constants/storageKeys';
import allActions from 'src/redux/actions';
import { typedUseSelector } from 'src/redux/store';
import { AuthRegion } from 'src/types';

export const ChangeRegionViewModel = () => {
  const dispatch = useDispatch();
  const AuthRegionData = authRegionData;

  const currentRegion = typedUseSelector((state) => state.hypercareRegionReducer.hypercareRegion);

  const submitRegionChange = (selectedRegion: AuthRegion) => {
    try {
      dispatch(allActions.hypercareRegionAction.setHypercareRegion(selectedRegion));
      window.localStorage.setItem(HYPERCARE_REGION, selectedRegion);
      return { success: true, error: null };
    } catch (err) {
      return { success: null, error: err };
    }
  };

  return { currentRegion, AuthRegionData, submitRegionChange };
};
