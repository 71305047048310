import { SEND_PROFILE_TO_STORE, SET_CURRENT_ORGANIZATION, SET_DIRECTORY_SYNCED } from 'src/constants/actionNames';
import { User, UserOrganizationSwitcherPayload } from 'src/types';

export interface SendProfileToStoreAction {
  type: typeof SEND_PROFILE_TO_STORE;
  profile: User;
}

export interface SetCurrentOrganizationAction {
  type: typeof SET_CURRENT_ORGANIZATION;
  currentOrganization: UserOrganizationSwitcherPayload;
}

export interface setIsUserDirectorySyncedAction {
  type: typeof SET_DIRECTORY_SYNCED;
  isDirectorySynced: boolean;
}

export type HeaderActionTypes = SendProfileToStoreAction | setIsUserDirectorySyncedAction;

export const sendProfileToStore = (profile: User): SendProfileToStoreAction => {
  return {
    type: SEND_PROFILE_TO_STORE,
    profile,
  };
};

export const setCurrentOrganization = (
  currentOrganization: UserOrganizationSwitcherPayload,
): SetCurrentOrganizationAction => {
  return {
    type: SET_CURRENT_ORGANIZATION,
    currentOrganization,
  };
};

export const setIsUserDirectorySynced = (isDirectorySynced: boolean): setIsUserDirectorySyncedAction => {
  return {
    type: SET_DIRECTORY_SYNCED,
    isDirectorySynced,
  };
};
