import React, { PureComponent } from 'react';
import UserAvatar from 'src/components/shared/UserAvatar';

interface Props {
  colleague: any;
  onClick?: (colleague: any) => void;
}

class UserListItem extends PureComponent<Props> {
  public render() {
    const { colleague } = this.props;
    const { firstname, role, lastname } = colleague;
    return (
      <div onClick={this.onClick} className="userList__layout">
        <UserAvatar user={colleague} profileSize="medium" />
        <div className="userList__userDescription">
          <div className="userList__layout__userFullName">
            {firstname} {lastname}
          </div>
          <div className="userList__layout__userRole">{role}</div>
        </div>
      </div>
    );
  }

  private onClick = () => {
    const { onClick, colleague } = this.props;
    if (onClick) {
      onClick(colleague);
    }
  };
}

export default UserListItem;
