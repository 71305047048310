import { SET_HYPERCARE_REGION } from 'src/constants/actionNames';
import { AuthRegion } from 'src/types';

export interface SetCurrentHypercareRegionAction {
  type: typeof SET_HYPERCARE_REGION;
  hypercareRegion: AuthRegion;
}

export type ChangeHypercareRegionActionTypes = SetCurrentHypercareRegionAction;

export const setHypercareRegion = (hypercareRegion: AuthRegion): SetCurrentHypercareRegionAction => {
  return {
    type: SET_HYPERCARE_REGION,
    hypercareRegion,
  };
};
