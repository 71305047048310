import React from 'react';
import AccountManagementContainer from 'src/pages/HomePage/account-management/AccountManagementContainer';
import { createMuiTheme } from '@material-ui/core/styles';
import AppTheme from 'src/assets/styles/theme';
import { ThemeProvider } from '@material-ui/styles';
import 'src/assets/styles/HomeStyles.scss';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import AccountManagementContainerV2 from './views/AccountManagementContainerV2';

const homePageTheme = createMuiTheme({
  palette: {
    primary: { main: AppTheme.mainTealColor },
    secondary: { main: AppTheme.mainButtonColor },
  },
});

const HomePage = () => {
  const isPaginatedContactsFeatureFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  if (isPaginatedContactsFeatureFlagEnabled === undefined) {
    return null;
  }

  return (
    <div className="appContent">
      <ThemeProvider theme={homePageTheme}>
        {isPaginatedContactsFeatureFlagEnabled ? <AccountManagementContainerV2 /> : <AccountManagementContainer />}
      </ThemeProvider>
    </div>
  );
};

export default HomePage;
