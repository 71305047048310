import { SET_USER_ORGANIZATIONS } from 'src/constants/actionNames';
import { OrganizationScope } from '../../types';

interface SetUserOrganizationAction {
  type: typeof SET_USER_ORGANIZATIONS;
  payload: OrganizationScope[];
}

export type UserOrganizationActionTypes = SetUserOrganizationAction;

export const setUserOrganizations = (payload: OrganizationScope[]): SetUserOrganizationAction => {
  return {
    type: SET_USER_ORGANIZATIONS,
    payload,
  };
};
