export const AppRoutes = {
  Home: `/home`,
  VirtualPagers: `/virtualpagers`,
  AuditLogs: `/auditlogs`,
  Scheduling: `/scheduling`,
  UserProfile: `/user-profile`,
  Dashboard: `/dashboard`,
  Escalation: `/escalation`,
  TemplateReport: `/templatereport`,
  Login: `/login`,
  SsoRedirect: `/ssoredirect`,
};
