import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const LoginFormWrapper = styled.div<{ loading?: boolean }>`
  position: relative;
  z-index: 1;
  background-color: white;

  border-radius: 8px;
  min-height: 385px !important;
  width: 600px;
  max-height: 800px;
  padding: 24px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.2)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  opacity: ${(props) => (props.loading ? '0.5' : '')};
`;
export const ProgressContainer = styled.div`
  z-index: 2;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  & * {
    border-radius: 8px 8px 0px 0px;
  }
`;
export const InputsWrapper = styled.div`
  .MuiOutlinedInput-input {
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
    height: 52px;
    padding: 16px 14px;
  }
  .MuiTextField-root {
    &:first-child {
      margin-bottom: 1em;
    }
  }
  .MuiFormHelperText-root {
    margin-right: 0 !important;
    margin-left: 2px !important;
  }
`;

export const StyledSubmitAddressButton = styled.button`
  font-family: Nunito, sans-serif;
  font-weight: bold;
  color: white;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.disabled};
  }
  background-color: #ff3e55;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  border: none;
`;

export const HelperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const StyledAnchor = styled.a`
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #4a4a4a;
  margin-top: 16px;
  text-decoration: none;
  float: right;
  span {
    padding-left: 4px;
    color: ${(props) => props.theme.blueBorder};
    &:hover {
      text-decoration: underline;
    }
  }

  line-height: 20px;
`;

export const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: rgb(0, 133, 154);
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(0, 133, 154);
    }
  }
  & .MuiInputBase-input {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
