import { Button } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownProps } from '../../Dropdown/Dropdown';
import ChevronUp from '../../../../assets/svgs/ChevronUp';
import ChevronDown from '../../../../assets/svgs/ChevronDown';
import { HCLabelOne } from '../../../shared/HypercareComponents';
import theme from '../../../../assets/styles/theme';

export type FormFieldInputDropdownProps<TOption> = Pick<
  DropdownProps<TOption>,
  'options' | 'onOptionClick' | 'renderOptionContent' | 'getOptionId' | 'getIsOptionSelected' | 'onClickAway'
> & {
  value?: TOption;
  renderValue: (value?: TOption) => ReactNode;
  placeholder: string;
  isRequired: boolean;
  disabled: boolean;
  mode: 'view' | 'edit';
  error?: string;
};

const FormFieldInputDropdownContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export function FormFieldInputDropdown<TOption>(props: FormFieldInputDropdownProps<TOption>) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const endIcon = isOpen ? (
    <ChevronUp style={{ color: props.disabled ? '#808080' : 'black' }} />
  ) : (
    <ChevronDown style={{ color: props.disabled ? '#808080' : 'black' }} />
  );

  const onDropdownClickAway = () => {
    setIsOpen(false);
    props.onClickAway();
  };

  const onButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClick = (o: TOption) => {
    setIsOpen(false);
    props.onOptionClick(o);
  };

  return (
    <FormFieldInputDropdownContainer>
      {props.mode === 'edit' && (
        <>
          <Button
            variant="text"
            disabled={props.disabled}
            style={{ textTransform: 'none' }}
            endIcon={endIcon}
            ref={setAnchorElement}
            onClick={onButtonClick}
          >
            {props.value ? (
              <HCLabelOne color={props.disabled && 'grey'}>{props.renderValue(props.value)}</HCLabelOne>
            ) : (
              <HCLabelOne color={theme.textLightTertiary}>{props.placeholder}</HCLabelOne>
            )}
          </Button>
          <Dropdown
            options={props.options}
            getIsOptionSelected={props.getIsOptionSelected}
            getOptionId={props.getOptionId}
            renderOptionContent={props.renderOptionContent}
            onOptionClick={onOptionClick}
            onClickAway={onDropdownClickAway}
            anchorElement={anchorElement}
            isOpen={isOpen}
          />
        </>
      )}

      {props.mode === 'view' && <HCLabelOne>{props.renderValue(props.value)}</HCLabelOne>}
    </FormFieldInputDropdownContainer>
  );
}
