import React from 'react';
import { ORGANIZATION } from 'src/constants/organizationTypes';
import CheckIcon from 'src/assets/svgs/CheckIcon';
import RenderSites from 'src/components/popup-dropdowns/dropdown-partials/RenderSites';
import { OrganizationScope, UserOrganizationSwitcherPayload } from 'src/types';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppTheme from 'src/assets/styles/theme';
import { toast } from 'react-toastify';

interface Props {
  organizations: OrganizationScope[];
  currentOrganization: UserOrganizationSwitcherPayload;
  handleChange: (option: UserOrganizationSwitcherPayload, index: number) => void;
  isReadOnly: boolean;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppTheme.mainTealColor,
    },
  },
});

const RenderOrganizations = ({ organizations, currentOrganization, handleChange, isReadOnly }: Props) => {
  const atLeastOneChildSelected: boolean = React.useMemo(() => {
    if (isReadOnly) return false;
    if (!organizations[0]) return false;

    const someDepartment = Boolean(
      organizations[0].sites &&
        organizations[0].sites.find((site) => site.departments.find((department) => department.isSelected)),
    );

    const someSite = Boolean(organizations[0].sites?.find((site) => site.isAllSelected));
    return someDepartment || someSite;
  }, [isReadOnly, organizations]);

  const atLeastOneChildIsAdmin = React.useCallback(
    (organization: OrganizationScope) => {
      if (!isReadOnly) return false;
      const someDepartment = Boolean(
        organization.sites &&
          organization.sites.find((site) => site.departments.find((department) => department.isAdmin)),
      );
      const someSite = Boolean(organization.sites.find((site) => site.isAdmin));
      return someDepartment || someSite;
    },
    [isReadOnly],
  );

  const handleOnClick = (organization: OrganizationScope, index: number) => {
    if (organization.isAdmin) {
      handleChange(
        {
          name: organization.name,
          type: ORGANIZATION,
          organization_id: organization.id,
          image: organization.image,
          site_id: null,
          department_id: null,
          organization_name: organization.name,
        },
        index,
      );
    } else {
      toast.warn(`You are not this organization admin`, {
        className: 'Toast-Container',
      });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      {organizations.map((organization, i) => (
        <React.Fragment key={`org-${i}`}>
          {(organization.isAdmin || atLeastOneChildIsAdmin(organization)) && (
            <li
              title={organization.isAdmin ? `${organization.id}` : 'not admin'}
              className={`dropdownOrganizationMenu__item ${i > 0 ? 'dropdownOrganizationMenu__item--border' : ''}`}
              key={`${organization.id}-${i}`}
              onClick={() => handleOnClick(organization, i)}
            >
              {!isReadOnly && (
                <Checkbox
                  color="primary"
                  indeterminate={!organization.isAllSelected && atLeastOneChildSelected}
                  checked={Boolean(organization.isAllSelected) || atLeastOneChildSelected}
                />
              )}
              {isReadOnly &&
              currentOrganization.organization_id === organization.id &&
              currentOrganization.type === ORGANIZATION ? (
                <React.Fragment>
                  <strong>{organization.name}</strong>
                  <CheckIcon />
                </React.Fragment>
              ) : (
                <React.Fragment>{organization.name}</React.Fragment>
              )}
            </li>
          )}
          <RenderSites
            isReadOnly={isReadOnly}
            handleChange={handleChange}
            organization={organization}
            currentOrganization={currentOrganization}
          />
        </React.Fragment>
      ))}
    </MuiThemeProvider>
  );
};

export default RenderOrganizations;
