import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { NO_SEARCH_RESULT_FOUND, MODIFY_SEARCH_RESULT } from 'src/constants/virtualPagerStrings';
import { HCHeadingFour, HCLabelOne } from 'src/components/shared/HypercareComponents';
import SearchIcon2 from 'src/assets/svgs/SearchIcon2';

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${theme.borderBottomLight};
  border-bottom: 1px solid ${theme.borderBottomLight};
  border-radius: 0px;
`;

const StyledTBody = styled.tbody`
  overflow: auto;
  background: white;
  border-radius: 0px;
`;

const TC = styled.td`
  text-align: left;
  border-bottom: 1px solid #ccc;
  height: 272px;
  padding: 16px 0px;
  text-align: center;

  &.number {
    padding: 16px 12px;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  &.redirect {
    align-items: center;
    justify-self: center;
  }
`;

const NoPagerMenuItem: React.FC = () => {
  return (
    <CustomTable>
      <StyledTBody>
        <tr>
          <TC>
            <SearchIcon2 />
            <HCHeadingFour fontWeight={700} color="black">
              {NO_SEARCH_RESULT_FOUND}
            </HCHeadingFour>
            <HCLabelOne color="#4A4A4A">{MODIFY_SEARCH_RESULT}</HCLabelOne>
          </TC>
        </tr>
      </StyledTBody>
    </CustomTable>
  );
};

export default NoPagerMenuItem;
