import client from 'src/clients/apolloClient';
import FullUserFragment from 'src/gql/fragment/FullUserFragment';

// TODO: update fragment id naming via apollo-cache
export default function getFullUserFragments(userId: string) {
  const userFragment = client.readFragment({
    id: `FullUser:${userId}`,
    fragment: FullUserFragment,
    fragmentName: 'FullUserFragment',
  });
  return userFragment;
}
