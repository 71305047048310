import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { HCHeadingFour, HCLabelOne } from 'src/components/shared/HypercareComponents';

const WhiteBoxContainer = styled.div`
  display: flex;
  width: calc(33%);
  height: 250px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;

  @media (max-width: 1380px) {
    width: 100%;
  }
`;

const ColumnwiseBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

interface WhiteBoxProps {
  title: string;
  description: string;
  buttonComponent: React.ReactNode;
}

const WhiteBox: React.FC<WhiteBoxProps> = ({ title, description, buttonComponent }) => {
  return (
    <WhiteBoxContainer>
      <ColumnwiseBoxContainer>
        <HCHeadingFour color={theme.black} fontWeight={700}>
          {title}
        </HCHeadingFour>
        <HCLabelOne color={theme.mainFontColor}>{description}</HCLabelOne>
      </ColumnwiseBoxContainer>
      {buttonComponent}
    </WhiteBoxContainer>
  );
};

export default WhiteBox;
