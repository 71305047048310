import { CalenderViewDelegateProtocol } from '@hypercare/hc-web-sdk';
import React from 'react';
import styled from 'styled-components';
import { GridBody } from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';
import store from 'src/redux/store';
import { DEPARTMENT } from 'src/constants/organizationTypes';
import ShiftAssignees from './scheduling-grid/ShiftAssignees';
import { GRIDSCHEDULINGVIEW } from 'src/constants/scheduler';
import { AssigneeWithRole } from 'src/types';

const AssigneeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export class GridViewCalenderDelegate extends CalenderViewDelegateProtocol {
  monthlyCalendar = null;
  isDepartment = store.getState().organizationReducer.type === DEPARTMENT;

  constructor(startDateISOstring, monthlyCalendar, holidays) {
    super(startDateISOstring, holidays);
    this.monthlyCalendar = monthlyCalendar;
  }

  renderData(): void {
    Object.keys(this.monthlyCalendar).map((dateString, i) => {
      const shifts = this.monthlyCalendar[dateString];
      var result: AssigneeWithRole[] = [];
      shifts.forEach((monthlyCalendarShift) => {
        if (monthlyCalendarShift.assignee.length === 0) return;
        monthlyCalendarShift.assignee.forEach((assignee) => {
          result.push({ role: monthlyCalendarShift.role, assignee: assignee });
        });
      });
      const assigneeToRole = result;
      this.addDatatoCalender(
        i,
        <GridBody>
          <AssigneeWrapper>
            <ShiftAssignees
              assignees={assigneeToRole}
              date={dateString}
              isDepartment={this.isDepartment}
              type={GRIDSCHEDULINGVIEW}
            />
          </AssigneeWrapper>
        </GridBody>,
      );
    });
  }
}

export default GridViewCalenderDelegate;
