import * as React from 'react';

type Props = {
  width?: number | 40;
  height?: number | 40;
};

export default (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#d8d8d8" />
    <circle cx="20" cy="20" r="10.5" transform="rotate(35 20 20)" stroke="white" strokeWidth="3" />
  </svg>
);
