import React from 'react';
import classNames from 'classnames';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Assignee, User } from 'src/types';
import { getUserInitials } from 'src/utils/getUserInitials';

const ImageAvatarStyles = createStyles({
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: '50px',
    height: '50px',
    position: 'initial',
  },
  largeAvatar: {
    width: 157,
    height: 157,
    position: 'initial',
  },
  smallAvatar: {
    width: 32,
    height: 32,
    position: 'initial',
  },
});

const LetterAvatarStyles = createStyles({
  avatar: {
    width: '50px',
    height: '50px',
    'font-size': '24px',
    'font-weight': '600',
    'font-family': 'Nunito',
    'background-color': '#c7c7cc',
    'letter-spacing': '-0.6px',
  },
  smallAvatar: {
    width: 26,
    height: 26,
    'font-size': '12px',
  },
  mediumAvatar: {
    width: 50,
    height: 50,
    'font-size': '13px',
  },
  largeAvatar: {
    width: 157,
    height: 157,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  root2: {
    position: 'initial',
    'font-size': 80,
  },
  root: {
    position: 'initial',
  },
});

const avatarSize = (profileSize: string, classes) => {
  switch (profileSize) {
    case 'small':
      return classes.smallAvatar;
    case 'medium':
      return classes.avatar;
    case 'large':
      return classes.largeAvatar;
    default:
      return classes.avatar;
  }
};

interface ImageAvatarProps extends WithStyles<typeof ImageAvatarStyles> {
  user: User | Assignee;
  profileSize: profileSize;
}

const ImageAvatars = (props: ImageAvatarProps) => {
  const { classes, user, profileSize } = props;
  const altName = `${user.firstname} ${user.lastname}`;
  return (
    <div className={classes.row}>
      <Avatar alt={altName} src={user.profilePic.url} className={classNames(avatarSize(profileSize, classes))} />
    </div>
  );
};

const RenderImageAvatar = withStyles(ImageAvatarStyles)(ImageAvatars);

interface LetterAvatarProps extends WithStyles<typeof LetterAvatarStyles> {
  user: User | Assignee;
  profileSize: profileSize;
}

const LetterAvatars = (props: LetterAvatarProps) => {
  const { classes, user, profileSize } = props;
  // const avatarSize = profileSize ? classes.largeAvatar : classes.avatar;
  const displaySettings = profileSize === 'large' ? '' : classes.row;
  const root = profileSize === 'large' ? classes.root2 : classes.root;
  return (
    <div className={displaySettings}>
      <Avatar className={classNames(root, avatarSize(profileSize, classes))}>{getUserInitials(user)}</Avatar>
    </div>
  );
};

const RenderLetterAvatar = withStyles(LetterAvatarStyles)(LetterAvatars);

type profileSize = 'default' | 'small' | 'medium' | 'large';

interface UserAvatarProps {
  profileSize: profileSize;
  user: User | Assignee;
}

const UserAvatar = ({ user, profileSize }: UserAvatarProps) => {
  if (user && user.profilePic) return <RenderImageAvatar user={user} profileSize={profileSize} />;

  return <RenderLetterAvatar user={user} profileSize={profileSize} />;
};

export default UserAvatar;
