import gql from 'graphql-tag';
import FullUserFragment from 'src/gql/fragment/FullUserFragment';
import { scopeFragmentString } from 'src/gql/fragment/ScopeFragment';

export const INVITE_EXIST_USER = gql`
  mutation AddExistingUser($username: ID!, $email: String, $scope: OrganizationScopeInput) {
    admin {
      addUser(username: $username, email: $email, scope: $scope) {
        ...FullUserFragment
        scopes {
          ${scopeFragmentString}
        }
      }
    }
  }
  ${FullUserFragment}
`;

export const INVITE_NEW_USER = gql`
  mutation InviteNewUser(
    $username: String!
    $firstname: String!
    $lastname: String!
    $role: String
    $addresses: [CreateAddressInput!]!
  ) {
    admin {
      createUser(
        username: $username
        userDetails: {firstname: $firstname, lastname: $lastname, role: $role, addresses: $addresses}
      ) {
        ...FullUserFragment
        scopes {
          ${scopeFragmentString}
        }
      }
    }
  }
  ${FullUserFragment}
`;

export const CREATE_SHELL_USER = gql`
  mutation InviteShellUser(
    $username: String!
    $firstname: String!
    $lastname: String!
    $role: String
    $addresses: [CreateAddressInput!]!
  ) {
    admin {
      createShellAccount(
        username: $username
        userDetails: {firstname: $firstname, lastname: $lastname, role: $role, addresses: $addresses}
      ) {
        ...FullUserFragment
        scopes {
          ${scopeFragmentString}
        }
      }
    }
  }
  ${FullUserFragment}
`;
