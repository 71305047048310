import * as React from 'react';
import { useEffect, useState } from 'react';
import { MenuItem, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Chip from '@material-ui/core/Chip';
import { HCBodyTwo, HCLabelOne, HCLabelTwo, HCTextContext } from 'src/components/shared/HypercareComponents';
import styled from 'styled-components';
import { OrganizationMemberFragment, OrganizationRoleFragment } from 'src/types';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import { StyledTextField, StyledCheckBox } from '../../../../components/shared';
import theme from 'src/assets/styles/theme';
import { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import { setEditedPager } from 'src/redux/actions/virtualPagersActions';
import { ASSIGNEE_ACCORDION_INPUT_LABLE, ASSIGNEE_TYPE_ACCORDION_INPUT_LABLE } from 'src/constants/virtualPagerStrings';
import { FetchPaginatedUsersQueryResponse, PaginatedLicensedMembers } from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import { ApolloQueryResult } from 'apollo-client';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import { usePaginatedDataState } from 'src/pages/HomePage/hooks/usePaginatedDataState';

const StyledChip = withStyles({
  root: {
    backgroundColor: theme.hoverGrey,
    fontSize: '14px',
    borderRadius: '3px',
    color: '#2a2a2a',
    border: 'none',
    '&&:hover': {
      borderWidth: '1px',
      borderColor: '#D8D8D8',
    },
  },
})(Chip);

const CircleIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #bcbcbc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
`;

type assigneeType = 'User' | 'On-call role';
const assigneeTypeList: assigneeType[] = ['User', 'On-call role'];

type AssigneeAccordionDetailsProps = {
  searchLoading: boolean;
  selectedUserList: OrganizationMemberFragment[];
  roleList: OrganizationRoleFragment[];
  users: OrganizationMemberFragment[];
  localSearchText: string;
  setLocalSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: (updatedSearchText: string) => Promise<void>;
  isPagerDisabled: boolean;
  fetchMorePaginatedUserData: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedUsersQueryResponse>>;
  paginatedUserData: FetchPaginatedUsersQueryResponse;
  searchLicensedUserData: PaginatedLicensedMembers;
  setSearchLicensedUserData: React.Dispatch<React.SetStateAction<PaginatedLicensedMembers>>;
};
const AssigneeAccordionDetails: React.FC<AssigneeAccordionDetailsProps> = ({
  searchLoading,
  selectedUserList,
  roleList,
  users,
  localSearchText,
  setLocalSearchText,
  handleSearch,
  isPagerDisabled,
  fetchMorePaginatedUserData,
  paginatedUserData,
  searchLicensedUserData,
  setSearchLicensedUserData,
}) => {
  const dispatch = useDispatch();
  const editedPager = typedUseSelector((state) => state.virtualPagerReducer.editedPager);

  const noOptionText = 'No results found, please try again.';

  const [assigneeSelection, setAssigneeSelection] = useState<assigneeType>(
    editedPager.userIds.length > 0 ? 'User' : editedPager.roleIds.length > 0 ? 'On-call role' : 'User',
  );

  const [selectedUserAssignees, setSelectedUserAssignees] = useState<OrganizationMemberFragment[]>([]);
  const [selectedRoleAssignees, setSelectedRoleAssignees] = useState<OrganizationRoleFragment[]>([]);

  const {
    setIsLoadingAdditionalData,
    isLoadingAdditionalData,
    setSeenContinuationIds,
    seenContinuationIds,
    setIsDoneRows,
    isDoneRows,
    additionalRows,
    setAdditionalRows,
  } = usePaginatedDataState();

  const [continuationId, setContinuationId] = useState(
    paginatedUserData?.adminQuery?.organizationalUnit?.paginatedMembers?.continuationId,
  );

  const newUserListData: OrganizationMemberFragment[] = [
    ...(users || []),
    ...(localSearchText.length <= 1 ? additionalRows : []),
  ];

  const { getSearchedUsers } = UserViewModel();
  const getMorePaginatedSearchData = async () => {
    if (!searchLicensedUserData) return;
    const continuationId = searchLicensedUserData.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await getSearchedUsers({ text: localSearchText, limit: 30, continuationId });

      if ('error' in result) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchLicensedUserData({
          ...searchLicensedUserData,
          continuationId: result.continuationId,
          users: [...searchLicensedUserData.users, ...result.users],
        });

        if (result.continuationId === null) {
          setIsDoneRows(true);
        }
      }
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const getMorePaginatedData = async () => {
    if (seenContinuationIds.includes(continuationId) || continuationId === null) {
      setIsDoneRows(true);
      return;
    }
    setIsLoadingAdditionalData(true);

    try {
      await fetchMorePaginatedUserData({
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          continuationId,
          direction: 'next',
        },
        updateQuery: (
          previousResult: FetchPaginatedUsersQueryResponse,
          { fetchMoreResult }: { fetchMoreResult: FetchPaginatedUsersQueryResponse },
        ) => {
          const newFetchedUsers = fetchMoreResult.adminQuery.organizationalUnit.paginatedMembers;

          if (newFetchedUsers.continuationId === null) {
            setIsDoneRows(true);
          }

          setAdditionalRows([...additionalRows, ...(newFetchedUsers.users || [])]);
          setContinuationId(newFetchedUsers.continuationId);
        },
      });
    } finally {
      setIsLoadingAdditionalData(false);
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const handleLoadMore = async () => {
    if (localSearchText.length > 0 && searchLoading) {
      await getMorePaginatedSearchData();
    } else {
      await getMorePaginatedData();
    }
  };

  const clearSearchText = () => {
    setLocalSearchText('');
    setSearchLicensedUserData(null);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoadingAdditionalData,
    hasNextPage: !isDoneRows,
    onLoadMore: handleLoadMore,
  });

  const renderSentry = () => {
    return continuationId || isLoadingAdditionalData ? (
      <li ref={sentryRef} key="sentry">
        Loading...
      </li>
    ) : null;
  };

  const handleRenderOption = (option, { selected }) => {
    if (option?.id === 'sentry') {
      return renderSentry();
    }

    if (searchLoading) {
      return;
    }

    return (
      <li key={option?.id}>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <StyledCheckBox checked={selected} />
          <CircleIcon>
            <HCBodyTwo color="white">
              {option?.firstname?.charAt(0)}
              {option?.lastname?.charAt(0)}
            </HCBodyTwo>
          </CircleIcon>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <HCTextContext>
              {option?.firstname} {option?.lastname}
            </HCTextContext>
            <HCLabelTwo>{option?.role}</HCLabelTwo>
          </div>
        </div>
      </li>
    );
  };

  const sentryOption = {
    id: 'sentry',
    firstname: '',
    lastname: '',
  };

  // Behaviour: selectedUserAssignees elements must point to the same memory location
  // as the elements in userList. Without this, MUI will not be able to render the
  // selected user in the Autocomplete component.
  useEffect(() => {
    setSelectedUserAssignees(selectedUserList.filter((user) => editedPager.userIds.includes(user.id)));
  }, [selectedUserList]);

  // Similar to roleList, When the roleList finshed fetching, we want to reset selectedRoleAssinees to
  // populate the UI component with the appropriate role name with corresponding roleId
  useEffect(() => {
    setSelectedRoleAssignees(roleList.filter((role) => editedPager.roleIds.includes(`${role.roleAuditId}`)));
  }, [roleList]);

  return (
    <>
      <HCLabelTwo afterContent={' *'} color={theme.mainFontColor}>
        {ASSIGNEE_TYPE_ACCORDION_INPUT_LABLE}
      </HCLabelTwo>
      <StyledTextField
        select
        disabled={isPagerDisabled}
        variant="outlined"
        onChange={async (e) => {
          const selection = e.target.value as assigneeType;
          setAssigneeSelection(selection);
          selection === 'User'
            ? dispatch(
                setEditedPager({ ...editedPager, roleIds: [], userIds: selectedUserAssignees.map((user) => user.id) }),
              )
            : dispatch(
                setEditedPager({
                  ...editedPager,
                  userIds: [],
                  roleIds: selectedRoleAssignees.map((role) => `${role.roleAuditId}`),
                }),
              );
        }}
        value={assigneeSelection}
        style={{ width: '275px', height: '40px', borderRadius: '2px', marginBottom: '28px' }}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {assigneeTypeList.map((type, index) => (
          <MenuItem key={index} value={type}>
            <HCLabelOne color={isPagerDisabled ? theme.warmGrey : 'inheret'}>{type}</HCLabelOne>
          </MenuItem>
        ))}
      </StyledTextField>
      <HCLabelTwo afterContent={' *'} color={theme.mainFontColor}>
        {ASSIGNEE_ACCORDION_INPUT_LABLE}
      </HCLabelTwo>

      {assigneeSelection === 'User' ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          noOptionsText={noOptionText}
          disabled={isPagerDisabled}
          style={{ marginBottom: '16px' }}
          options={newUserListData}
          value={selectedUserAssignees}
          defaultValue={selectedUserAssignees}
          getOptionSelected={(option, value) => {
            return option.id === value.id;
          }}
          renderTags={(value, getTagProps) => {
            return (
              <>
                {value.map((option, index) => (
                  <StyledChip
                    {...getTagProps({ index })}
                    variant="outlined"
                    label={`${option.firstname} ${option.lastname}`}
                    deleteIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CloseIcon width="8" height="8" color={theme.mainFontColor} />
                      </div>
                    }
                  />
                ))}
              </>
            );
          }}
          onChange={(_, newValue: OrganizationMemberFragment[]) => {
            setSelectedUserAssignees(newValue);
            dispatch(setEditedPager({ ...editedPager, userIds: newValue.map((user) => user.id) }));
          }}
          renderInput={(params) => <StyledTextField disabled={isPagerDisabled} {...params} variant="outlined" />}
          onInputChange={async (event, value, reason) => {
            await handleSearch(value);
          }}
          getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
          ListboxProps={{ ref: rootRef }}
          filterOptions={(filterOptions) => {
            //filterOptions need for adding sentry to your options
            const shouldRenderSentry = !isDoneRows;

            if (shouldRenderSentry) {
              filterOptions.push(sentryOption);
            }

            return filterOptions;
          }}
          renderOption={(option, { selected }) => {
            return handleRenderOption(option, { selected });
          }}
        />
      ) : (
        <Autocomplete
          multiple
          disableCloseOnSelect
          noOptionsText={noOptionText}
          disabled={isPagerDisabled}
          options={roleList}
          value={selectedRoleAssignees}
          defaultValue={selectedRoleAssignees}
          getOptionLabel={(option) => `${option?.roleName}`}
          style={{ marginBottom: '16px' }}
          renderTags={(value, getTagProps) => {
            return (
              <>
                {value.map((option, index) => (
                  <StyledChip
                    {...getTagProps({ index })}
                    variant="outlined"
                    label={option.roleName}
                    deleteIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CloseIcon width="8" height="8" color={theme.mainFontColor} />
                      </div>
                    }
                  />
                ))}
              </>
            );
          }}
          onChange={(_, newValue: OrganizationRoleFragment[]) => {
            setSelectedRoleAssignees(newValue);
            dispatch(setEditedPager({ ...editedPager, roleIds: newValue.map((role) => `${role.roleAuditId}`) }));
          }}
          renderInput={(params) => <StyledTextField {...params} variant="outlined" />}
          renderOption={(option, { selected }) => {
            return (
              <li key={option?.roleAuditId}>
                <div
                  style={{
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center',
                  }}
                >
                  <StyledCheckBox checked={selected} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <HCTextContext>{option?.roleName}</HCTextContext>
                    <HCLabelTwo>{option?.department}</HCLabelTwo>
                  </div>
                </div>
              </li>
            );
          }}
        />
      )}
    </>
  );
};

export default AssigneeAccordionDetails;
