import {
  SET_CURRENT_PAGER,
  SET_EDITED_PAGER,
  SET_EDITED_AND_CURRENT_PAGER,
  SET_PAGER_LIST,
  SET_SEARCH_TEXT,
  SET_SEARCH_TYPE,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
  SET_NEXT_CURSOR,
} from 'src/constants/actionNames';
import { VirtualPagers, SearchFieldType, SortOrderType, SortFieldType } from 'src/types';
import { virtualPagerActions } from '../actions/virtualPagersActions';

export interface VirtualPagerReducer {
  editedPager: VirtualPagers;
  currentPager: VirtualPagers;
  pagerList: VirtualPagers[];
  nextCursor: string | null;
  searchText: string;
  searchType: SearchFieldType;
  sortField: SortFieldType;
  sortOrder: SortOrderType;
}

let initialState: VirtualPagerReducer = {
  editedPager: null,
  currentPager: null,
  pagerList: [],
  nextCursor: null,
  searchText: '',
  searchType: 'number',
  sortField: 'dateCreated',
  sortOrder: 'desc',
};

export const virtualPagerReducer = (state = initialState, action: virtualPagerActions): typeof initialState => {
  switch (action.type) {
    case SET_CURRENT_PAGER:
      return {
        ...state,
        currentPager: action.pager,
      };
    case SET_EDITED_PAGER:
      return {
        ...state,
        editedPager: action.pager,
      };
    case SET_EDITED_AND_CURRENT_PAGER:
      return {
        ...state,
        editedPager: action.pager,
        currentPager: action.pager,
      };
    case SET_PAGER_LIST:
      return {
        ...state,
        pagerList: action.pagerList,
      };
    case SET_NEXT_CURSOR:
      return {
        ...state,
        nextCursor: action.nextCursor,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.searchText,
      };
    case SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.searchType,
      };
    case SET_SORT_FIELD:
      return {
        ...state,
        sortField: action.sortField,
      };
    case SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.sortOrder,
      };
    default:
      return state;
  }
};
