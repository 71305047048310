import gql from 'graphql-tag';
import { userFragmentString } from 'src/gql/fragment/FullUserFragment';

export default gql`
  mutation RemoveAddresses(
    $userId: ID!, 
    $addressIds: [String!]!
  ) {
    admin {
      user(id: $userId) {
        removeAddresses(addressIds: $addressIds) {
          ${userFragmentString}
          addresses {
            id
            address
            type
            ordinal
            access
          }
        }
      }
    }
  }
`;
