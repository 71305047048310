export const getUserFullName = (user) => {
  const selectedUser = user.user || user;
  if (!selectedUser) {
    return 'Unknown User';
  }
  if (selectedUser.firstname || selectedUser.lastname)
    return `${selectedUser.firstname || ''} ${selectedUser.lastname || ''}`;
  if (user.address) return `${user.address.address}`;
  return 'Unknown User';
};
