import { ApolloLink, Observable, Operation } from 'apollo-link';
import { getAuthToken, getScopeToken, getAdminScopeToken } from 'src/utils/getLocalAuth';
import moment from 'moment-timezone';
import { REMOVE_USER } from 'src/constants/modalTypes';
import getGqlV2Endpoint from 'src/utils/getGqlSchemaEndpoint';
import getApiEndpoint from 'src/utils/getApiEndpoint';
import { V2_OPERATIONS_OBJECT } from 'src/constants/v2OperationName';

const request = async (operation: Operation) => {
  const token = await getAuthToken();
  const scopeToken = await getAdminScopeToken();
  const timezone =
    operation.operationName === 'CreateRole' || operation.operationName === 'UpdateRole' ? '' : moment.tz.guess();

  const ApiURL = V2_OPERATIONS_OBJECT[operation.operationName]
    ? getGqlV2Endpoint()
    : `${getApiEndpoint()}/graphql/private`;

  operation.setContext({
    uri: ApiURL,
    headers: {
      'Cache-Control': 'no-cache,no-store,must-revalidate,private',
      Pragma: 'no-cache',
      Expires: 0,
      'hc-timezone': timezone,
      'X-Timezone': timezone,
      'hypercare-scope': scopeToken ? `${scopeToken}` : '',
      Authorization: `Bearer ${token}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((op: Operation) => request(op))
        .then(() => {
          handle =
            forward &&
            forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

export default requestLink;
