import gql from 'graphql-tag';
import ShiftFragment from 'src/gql/fragment/ShiftFragment';

export default gql`
  mutation UpdateShift($departmentId: Int!, $scheduleId: Int!, $shiftId: Int!, $shiftDetails: UpdateShiftDetails!) {
    admin {
      locating {
        department(id: $departmentId) {
          schedule(id: $scheduleId) {
            shift(id: $shiftId) {
              update(details: $shiftDetails) {
                ...ShiftFragment
              }
            }
          }
        }
      }
    }
  }
  ${ShiftFragment}
`;
