import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'src/auth/AuthProvider';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { AuthSSOVendors, LoginViaSSO } from 'src/types';
import sleep from 'src/utils/sleep';
import { LoginSSOErrorModal } from 'src/components/modals/LoginSSOErrorModal';

interface Props {
  isLoggedIn: boolean;
  loginViaSSO: LoginViaSSO;
  auth0props: Auth0ContextInterface;
}

const SSORedirectLoadingPage = ({ isLoggedIn, loginViaSSO, auth0props }: Props) => {
  const { isAuthenticated, getAccessTokenSilently, logout, user } = auth0props;

  React.useEffect(() => {
    const getSSOVendor = localStorage.getItem('ssoVendor') || '';

    const getAccessToken = async () => {
      if (isAuthenticated && getSSOVendor === AuthSSOVendors.AUTH0) {
        const token = await getAccessTokenSilently();

        await loginViaSSO(token, AuthSSOVendors.AUTH0);

        if (!token) {
          console.error(token);
          await sleep(3000);
          logout({ federated: true });
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code') || '';

        await loginViaSSO(code, AuthSSOVendors.WORKOS);
      }
    };

    getAccessToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoggedIn) return <Redirect to="/home" />;
  return <div className="loadSprinner" />;
};

export default () => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlErrorDescription = urlParams.get('error_description');

  return (
    <AuthContext.Consumer>
      {({ isLoggedIn, loginViaSSO, auth0props }) => {
        if (urlErrorDescription) {
          return <LoginSSOErrorModal description={urlErrorDescription} />;
        }
        return <SSORedirectLoadingPage auth0props={auth0props} isLoggedIn={isLoggedIn} loginViaSSO={loginViaSSO} />;
      }}
    </AuthContext.Consumer>
  );
};
