import React from 'react';
import 'src/assets/styles/FormStyles.scss';
import { FormikProps } from 'formik';
import {
  PROFILE_PAGE_FIRST_NAME_FIELD_LABEL,
  PROFILE_PAGE_JOB_TITLE_FIELD_LABEL,
  PROFILE_PAGE_LAST_NAME_FIELD_LABEL,
} from 'src/constants/strings';
import { User } from 'src/types';

interface Props {
  user: User;
  formikProps: FormikProps<any>;
  isInputDisabled: boolean;
}

const ProfileFormFields = ({ user, formikProps, isInputDisabled }: Props) => {
  const { values, errors, handleChange } = formikProps;
  return (
    <React.Fragment>
      <div className="profileForm__inputWrapper">
        <label htmlFor="firstname">{PROFILE_PAGE_FIRST_NAME_FIELD_LABEL}</label>
        <input
          id="firstname"
          name="firstname"
          type="text"
          autoComplete="off"
          value={values.firstname}
          style={!isInputDisabled && user?.isDirectorySynced ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
          onChange={handleChange}
          onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
          readOnly={isInputDisabled || user?.isDirectorySynced}
        />
        {errors.firstname && <span>{errors.firstname}</span>}
      </div>

      <div className="profileForm__inputWrapper">
        <label htmlFor="lastname">{PROFILE_PAGE_LAST_NAME_FIELD_LABEL}</label>
        <input
          id="lastname"
          name="lastname"
          type="text"
          autoComplete="off"
          value={values.lastname}
          style={!isInputDisabled && user?.isDirectorySynced ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
          onChange={handleChange}
          onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
          readOnly={isInputDisabled || user?.isDirectorySynced}
        />
        {errors.lastname && <span>{errors.lastname}</span>}
      </div>

      <div className="profileForm__inputWrapper">
        <label htmlFor="role">{PROFILE_PAGE_JOB_TITLE_FIELD_LABEL}</label>
        <input
          id="role"
          name="role"
          type="text"
          autoComplete="off"
          value={values.role ? values.role : ''}
          style={!isInputDisabled && user?.isDirectorySynced ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
          onChange={handleChange}
          onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
          readOnly={isInputDisabled || user?.isDirectorySynced}
        />
        {errors.role && <span>{errors.role}</span>}
      </div>
    </React.Fragment>
  );
};

export default ProfileFormFields;
