import React from 'react';
import SchedulingLayout from 'src/pages/SchedulingPage/SchedulingLayout';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';

class SchedulingPage extends React.Component {
  public componentDidMount() {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.schedulingPage);
  }

  public render() {
    return (
      <>
        <div className="appContent">
          <SchedulingLayout />
        </div>
      </>
    );
  }
}

export default SchedulingPage;
