import { UPDATE_DATE_RANGE, REFETCH } from 'src/constants/actionNames';
import { IDateRange } from 'src/types';

export interface UpdateDateRangeAction {
  type: typeof UPDATE_DATE_RANGE;
  payload: IDateRange[];
}

export interface RefetchAction {
  type: typeof REFETCH;
  payload: Date | string;
}

export type AnalyticsActionTypes = UpdateDateRangeAction | RefetchAction;

export const updateDateRangeAction = (dateRange: IDateRange[]): UpdateDateRangeAction => {
  return {
    type: UPDATE_DATE_RANGE,
    payload: dateRange,
  };
};

export const refetch = (date: Date | string): RefetchAction => {
  return {
    type: REFETCH,
    payload: date,
  };
};
