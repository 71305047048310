import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { User } from 'src/types';
import AppTheme from 'src/assets/styles/theme';
import { ARBITRARY_DATE } from 'src/constants/scheduler';
import {
  DropItemType,
  DropSourceItem,
  EscalationLevelOnCallActionPayload,
  EscalationMember,
} from 'src/types/Escalation';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import ClearIcon from 'src/assets/svgs/ClearIcon';
import {
  BaseOnCallMemberHolder,
  OnCallNameHolder,
  OnCallRoleTime,
  OnCallRoleTitle,
} from 'src/pages/EscalationPage/escalation-layout/SharedEscalationStyles';

interface OnCallSourceHolderProps {
  isDragging: boolean;
}

const OnCallSourceHolder = styled(BaseOnCallMemberHolder)`
  cursor: move;
  border: ${(props: OnCallSourceHolderProps) =>
    props.isDragging ? `2px solid ${AppTheme.mainTealColor}` : '1px solid lightgrey'};
`;

const DeleteOnCallBtnHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 32px;
  padding: 6px;
  border-radius: 20px;
  border: solid 1px lightgrey;
  background-color: white;
  overflow: unset !important;
  text-align: center;
  line-height: 1em;
  cursor: pointer;
  color: ${AppTheme.mainFontColor};
  width: 20% !important;
  min-width: 92px !important;
  font-size: 14px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

interface Props {
  dropSourceItem: DropSourceItem;
}

const EscalationOnCallSource: React.FC<Props> = ({ dropSourceItem }) => {
  const dispatch = useDispatch();
  const deleteEscalationOncall = (payload: EscalationLevelOnCallActionPayload) =>
    dispatch(allActions.escalationAction.deleteEscalationLevelOnCall(payload));

  const [{ isDragging }, drag] = useDrag({
    item: dropSourceItem,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const dropType = dropSourceItem.type as DropItemType;
  const dropData = dropSourceItem.data as EscalationMember;

  const handleDeleteOnCall = () => {
    deleteEscalationOncall({
      levelIndex: dropSourceItem.originLevelIndex,
      onCallData: dropData,
    });
  };

  if (dropType === 'user' || dropType === 'droppedUser') {
    const userData = dropData.user as User;

    return (
      <OnCallSourceHolder ref={drag} isDragging={isDragging}>
        <OnCallNameHolder>
          <div>
            {userData.firstname} {userData.lastname}
          </div>
          {dropType === 'droppedUser' && (
            <DeleteOnCallBtnHolder onClick={handleDeleteOnCall}>
              <ClearIcon />
              <span>Remove</span>
            </DeleteOnCallBtnHolder>
          )}
        </OnCallNameHolder>
        <OnCallRoleTitle>{userData.role || '--'}</OnCallRoleTitle>
      </OnCallSourceHolder>
    );
  }

  if (dropType === 'role' || dropType === 'droppedRole') {
    const roleData = dropData.role;
    const startTime = moment.utc(`${ARBITRARY_DATE} ${roleData.startTime}`, 'YYYY/MM/DD HH:mm').local();
    const endTime = moment.utc(`${ARBITRARY_DATE} ${roleData.endTime}`, 'YYYY/MM/DD HH:mm').local();

    return (
      <OnCallSourceHolder ref={drag} isDragging={isDragging}>
        <OnCallNameHolder>
          <div>{roleData.name}</div>
          {dropType === 'droppedRole' && (
            <DeleteOnCallBtnHolder onClick={handleDeleteOnCall}>
              <ClearIcon />
              <span>Remove</span>
            </DeleteOnCallBtnHolder>
          )}
        </OnCallNameHolder>
        <OnCallRoleTime>
          <span>
            {roleData.repeatRule} ({startTime.format('HH:mm')} - {endTime.format('HH:mm')}
          </span>
          {startTime >= endTime && <sup> +1</sup>})
        </OnCallRoleTime>
      </OnCallSourceHolder>
    );
  }

  return null;
};

export default EscalationOnCallSource;
