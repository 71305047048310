import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogBoxTitle, PrimaryButton } from 'src/components/shared/HypercareComponents';
import { AppRoutes } from '../../router/AppRoutes';
import { LOGIN_PAGE_SSO_ERROR_MODAL_TITLE } from 'src/constants/strings';

export const LoginSSOErrorModal = ({ description }: { description: string }) => {
  return (
    <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogBoxTitle id="alert-dialog-title">{LOGIN_PAGE_SSO_ERROR_MODAL_TITLE}</DialogBoxTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton type="button" onClick={() => window.routerHistory.push(AppRoutes.Login)}>
          Dismiss
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
