import { SET_USER_ORGANIZATIONS } from 'src/constants/actionNames';
import { UserOrganizationActionTypes } from '../actions/userOrganizationAction';
import { OrganizationScope } from '../../types';

export interface UserOrganizationReducer {
  organizations: OrganizationScope[];
}

let initialState: UserOrganizationReducer = {
  organizations: null,
};

export const userOrganizationReducer = (
  state = initialState,
  action: UserOrganizationActionTypes,
): typeof initialState => {
  switch (action.type) {
    case SET_USER_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    default:
      return state;
  }
};
