import React from 'react';
import styled from 'styled-components';
import store from 'src/redux/store';
import moment from 'moment';
import { GET_USERS } from 'src/gql/query/GetUsersQuery';
import {
  EscalationPolicyResult,
  User,
  GetRolesResult,
  GetUsersResult,
  GetPolicyResult,
  EscalationRole,
} from 'src/types';
import { useQuery } from 'react-apollo';
import { GET_ROLES } from 'src/gql/query/FetchRolesAndShiftsQuery';
import { CircularProgress } from '@material-ui/core';
import EscalationSourceSearchWrapper from 'src/pages/EscalationPage/escalation-layout/side-panel/EscalationOnCallSearchWrapper';
import EscalationSourcePolicyList from 'src/pages/EscalationPage/escalation-layout/side-panel/EscalationSourcePolicyList';
import FetchEscalationPoliciesForOrganization from 'src/gql/query/FetchEscalationPoliciesForOrganization';

const EscalationSourceWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  width: calc(400px - 1em);
  min-height: fit-content;
  border-right: 1px solid grey;
  padding-right: 1em;
  padding-left: 0.5em;
  overflow-y: auto;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: auto;
`;

interface Props {}

const EscalationSourceContainer: React.FC<Props> = () => {
  const { department_id, organization_id } = store.getState().organizationReducer;
  const startOfMonth = moment().utc().startOf('month');
  const endOfMonth = moment().utc().endOf('month').millisecond(0o00);

  const { data: usersData, error: usersError, loading: usersLoading } = useQuery<GetUsersResult>(GET_USERS);
  const {
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
  } = useQuery<GetRolesResult>(GET_ROLES, {
    variables: {
      departmentId: department_id,
      startDate: startOfMonth,
      endDate: endOfMonth,
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: policyData,
    error: policyError,
    loading: policyLoading,
  } = useQuery<GetPolicyResult>(FetchEscalationPoliciesForOrganization, {
    variables: {
      organizationId: organization_id,
    },
  });

  if (usersError || usersLoading || rolesError || rolesLoading || policyError || policyLoading)
    return (
      <EscalationSourceWrapper>
        <StyledCircularProgress />
      </EscalationSourceWrapper>
    );

  const users: User[] = usersData.admin.users;

  const roles: EscalationRole[] = rolesData.locating.department.roles.map((role) => {
    const { id, auditId, name, repeatRule, startTime, endTime } = role;
    return {
      id,
      auditId,
      name,
      repeatRule,
      startTime,
      endTime,
    } as EscalationRole;
  });

  const policies: EscalationPolicyResult[] = policyData.locating.organization.escalationPolicies.map((policy, i) => {
    return {
      policyIndex: i,
      policyData: {
        type: policy.channels[0].type,
        name: policy.name,
        repeatLength: policy.channels[0].timeBetweenAttempts,
        repeatAttempts: policy.channels[0].numAttempts,
        id: policy.id,
      },
    } as EscalationPolicyResult;
  });

  return (
    <EscalationSourceWrapper id="EscalationSourceWrapper">
      <EscalationSourceSearchWrapper users={users} roles={roles} />
      <EscalationSourcePolicyList policies={policies} />
    </EscalationSourceWrapper>
  );
};

export default EscalationSourceContainer;
