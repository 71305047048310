export const LASTNAME = 'lastName';
export const LASTSEEN = 'lastSeen';
export const JOIN_DATE = 'joinDate';
export const LICENSING_STATUS = 'licensingStatus';

//Virtual Pagers Sorter Types
export const DATE_CREATED = 'dateCreated';
export const DATE_LAST_PAGED = 'lastUsed';
export const DESCEND = 'desc';
export const ASCEND = 'asc';
export const DISPLAY_NAME = 'friendlyName';
export const NUMBER = 'number';
export const USERD_IDS = 'userIds';
