import React, { useState } from 'react';
import ScheduleCreateRoleModal from 'src/components/modals/ScheduleCreateRoleModal';
import ScheduleDeleteRoleModal from 'src/components/modals/ScheduleDeleteRoleModal';
import { Role } from 'src/types/Scheduler';
import {
  cellGap,
  cellWidth,
  dateFieldWidth,
  GridHeaderArea,
  GridHeader,
  GridHeaderNameHolder,
  GridHeaderRoleName,
  GridHeaderRepeatRule,
  RoleNoteGridHeader,
  StyledPlusOneContainer,
} from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import GridRoleNoteHeader from 'src/pages/SchedulingPage/scheduling-layout/scheduling-grid/GridRoleNoteHeader';
import { HypercareRoleNotesModal } from 'src/components/hidden-notes/role-notes/HypercareRoleNotesModal';
import { typedUseSelector } from 'src/redux/store';
import { minutesOfDay } from 'src/pages/SchedulingPage/scheduling-layout/schedulingHelper';

interface RolesNamesProps {
  roleContainer: Role[];
  setCreateRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEditRoleData: React.Dispatch<React.SetStateAction<Role>>;
  isDepartment: boolean;
}

const RoleNames = ({ roleContainer, setCreateRoleModal, setEditRoleData, isDepartment }: RolesNamesProps) => {
  return (
    <React.Fragment>
      {roleContainer.map((role: Role, index: number) => {
        return (
          <GridHeaderNameHolder
            key={role.roleId}
            title={role.roleId}
            onClick={() => {
              if (!isDepartment) return;
              setEditRoleData(role);
              setCreateRoleModal(true);
            }}
          >
            <GridHeaderRoleName>{role.roleName}</GridHeaderRoleName>
            <GridHeaderRepeatRule>
              {role.repeatRule.charAt(0).toUpperCase() + role.repeatRule.slice(1)} (
              {`${role.startTime.format('HH:mm')} - ${role.endTime.format('HH:mm')}`}
              {minutesOfDay(role?.endTime) <= minutesOfDay(role?.startTime) && (
                <StyledPlusOneContainer>+1</StyledPlusOneContainer>
              )}
              )
            </GridHeaderRepeatRule>
            <GridHeaderRepeatRule>{role.duration === 86400 && <span>(24 hour shift)</span>}</GridHeaderRepeatRule>
          </GridHeaderNameHolder>
        );
      })}
    </React.Fragment>
  );
};

interface GridRoleHeaderAreaProps {
  roleContainer: Role[];
  isDepartment: boolean;
  scheduleId: number;
}

const GridRoleHeaderArea = ({ roleContainer, isDepartment, scheduleId }: GridRoleHeaderAreaProps) => {
  const [showCreateRoleModal, setCreateRoleModal] = useState(false);
  const [showDeleteRoleModal, setDeleteRoleModal] = useState(false);
  const [editRoleData, setEditRoleData] = useState<Role>(null);
  const showRoleNoteModal = typedUseSelector((state) => state.monthlyScheduleReducer.isRoleNoteModalVisible);

  const hiddenRoleNotesFlag = IsFeatureFlagEnabled('hiddenRoleNotes');

  const numberOfRoles = roleContainer.length;
  const gridBodyWidth = `calc(${cellWidth} * ${numberOfRoles} + ${cellGap} * ${numberOfRoles} + ${dateFieldWidth})`;

  return (
    <React.Fragment>
      {numberOfRoles > 0 && (
        <GridHeaderArea id="gridHeaderArea">
          <GridHeader gridBodyWidth={gridBodyWidth}>
            <RoleNames
              setCreateRoleModal={setCreateRoleModal}
              setEditRoleData={setEditRoleData}
              roleContainer={roleContainer}
              isDepartment={isDepartment}
            />
          </GridHeader>
          {hiddenRoleNotesFlag && (
            <RoleNoteGridHeader gridBodyWidth={gridBodyWidth}>
              <GridRoleNoteHeader scheduleId={scheduleId} />
            </RoleNoteGridHeader>
          )}
        </GridHeaderArea>
      )}

      {showCreateRoleModal && (
        <ScheduleCreateRoleModal
          showModal={showCreateRoleModal}
          editRoleData={editRoleData}
          handleOpenDeleteRoleModal={() => setDeleteRoleModal(true)}
          handleCloseModal={() => setCreateRoleModal(false)}
          handleAddRoleNoteModal={null}
        />
      )}
      {showCreateRoleModal && (
        <ScheduleDeleteRoleModal
          showModal={showDeleteRoleModal}
          editRoleData={editRoleData}
          handleCloseParentModal={() => setCreateRoleModal(false)}
          handleCloseModal={() => setDeleteRoleModal(false)}
        />
      )}
      {showRoleNoteModal && <HypercareRoleNotesModal isOpen={showRoleNoteModal} />}
    </React.Fragment>
  );
};

export default GridRoleHeaderArea;
