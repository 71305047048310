import React from 'react';
import DirSyncRepository from '../DirSyncRepository';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';

export default function DirSyncViewModel() {
  const repo = DirSyncRepository();

  const fetchSelfProfile = () => {
    const result = repo.useFetchSelProfile();
    return {
      data: result?.data?.me,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const fetchMembers = () => {
    const result = repo.useFetchMembers();
    return {
      data: result?.data?.adminQuery?.organizationalUnit?.members,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const fetchMemberProfile = (userId: string) => {
    const result = repo.useFetchUserProfilePage(userId);
    return {
      data: result?.data?.adminQuery?.organizationalUnit?.member,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const fetchOrgDirSyncStatus = () => {
    const result = repo.useFetchOrgDirSyncStatus();
    return {
      data: result?.data?.adminQuery?.organizationalUnit?.syncStatus,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const handleUpdateMemberDirSyncStatus = () => {
    const result = repo.useUpdateMemberDirSyncStatus();

    return {
      updateMemberDirSyncStatus: (userId: string, syncStatus: boolean) => {
        const orgUnit = checkOrganizationalUnit();

        if (orgUnit.type === 'error') {
          return;
        }

        return result.UpdateMemberDirSyncStatus({
          variables: {
            userId,
            syncStatus,
            organizationalUnit: {
              id: orgUnit.id.toString(),
              type: orgUnit.type,
            },
          },
        });
      },
      loading: result.loading,
      error: result.error,
    };
  };

  return {
    fetchSelfProfile,
    fetchMembers,
    fetchMemberProfile,
    fetchOrgDirSyncStatus,
    handleUpdateMemberDirSyncStatus,
  };
}
