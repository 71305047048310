import React from 'react';
import HypercareModal from 'src/components/shared/HypercareModal';

const DiscardNoteModal = ({ open, handleCloseModal, handleDiscard }) => {
  return (
    <HypercareModal
      title={'Discard unsaved changes?'}
      body={''}
      size={'xs'}
      closeModal={handleCloseModal}
      isModalVisible={open}
      modalButtons={[
        {
          buttonLabel: 'Keep editing',
          type: 'secondary',
          onClickHandler: handleCloseModal,
        },
        {
          buttonLabel: 'Discard',
          type: 'primary',
          onClickHandler: () => handleDiscard(),
        },
      ]}
    />
  );
};

export default DiscardNoteModal;
