import { SEND_PROFILE_TO_STORE, SET_DIRECTORY_SYNCED } from 'src/constants/actionNames';
import { HeaderActionTypes } from 'src/redux/actions/headerAction';
import { User } from 'src/types';

let initialState: UserDataReducerState = null;
export interface UserDataReducerState extends User {}

export const userDataReducer = (state = initialState, action: HeaderActionTypes): typeof initialState => {
  switch (action.type) {
    case SEND_PROFILE_TO_STORE: {
      const newState = Object.assign({}, state, action.profile);
      return newState;
    }
    case SET_DIRECTORY_SYNCED: {
      const newState = { ...state, isDirectorySynced: action.isDirectorySynced };
      return newState;
    }
    default:
      return state;
  }
};
