import React, { useEffect } from 'react';
import { AuthContext } from 'src/auth';
import { defaultTime } from 'src/constants/sessionTimeoutTypes';
import { getCurrentOrganizationId } from 'src/utils/getLocalAuth';
import FirebaseRemoteConfigModule from 'src/components/SessionTimeout/FirebaseRemoteConfigModule';
import { isFirebaseRemoteConfigSupported } from 'src/firebase/firebaseSetup';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

interface Props {
  logout: () => void;
}

const ForcedLogoutTimerHandler = ({ logout }: Props) => {
  const firebaseRemoteConfigModule = FirebaseRemoteConfigModule();
  const currentOrganizationid = getCurrentOrganizationId();

  const handleLogout = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.sessionTimeoutForceLogout,
    });
    logout();
  };

  useEffect(() => {
    let forcedLogoutTimeout = setTimeout(handleLogout, defaultTime.FORCED_TIMEOUT);

    const getForcedTimeoutData = async () => {
      const result = await isFirebaseRemoteConfigSupported();

      if (result) {
        const forcedTimeoutData = (await firebaseRemoteConfigModule.getOrganizationConfig(currentOrganizationid))
          ?.forced_timeout;
        if (forcedTimeoutData) {
          clearTimeout(forcedLogoutTimeout);
          forcedLogoutTimeout = setTimeout(handleLogout, forcedTimeoutData);
        }
      }
    };

    getForcedTimeoutData();

    return () => clearTimeout(forcedLogoutTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default () => (
  <AuthContext.Consumer>
    {({ isLoggedIn, logout }) => (isLoggedIn ? <ForcedLogoutTimerHandler logout={logout} /> : null)}
  </AuthContext.Consumer>
);
