import { combineReducers, AnyAction } from 'redux';
import { monthlyScheduleReducer, MonthlyScheduleReducerState } from 'src/redux/reducers/monthlyScheduleReducer';
import { flagReducer, FlagReducerState } from 'src/redux/reducers/flagReducer';
import { userDataReducer, UserDataReducerState } from 'src/redux/reducers/userDataReducer';
import { escalationReducer, EscalationReducerState } from 'src/redux/reducers/escalationReducers';
import { organizationReducer, OrganizationReducerState } from 'src/redux/reducers/organizationReducer';
import { analyticsReducer, AnalyticsDataReducer } from 'src/redux/reducers/analyticsReducer';
import { hypercareRegionReducer, CurrentRegionReducerState } from 'src/redux/reducers/regionReducer';
import { virtualPagerReducer, VirtualPagerReducer } from './virtualPagersReducer';
import { userOrganizationReducer, UserOrganizationReducer } from './userOrganizationReducer';
import { RESET } from 'src/constants/actionNames';

interface State {
  readonly flagReducer: FlagReducerState;
  readonly userDataReducer: UserDataReducerState;
  readonly escalationReducer: EscalationReducerState;
  readonly organizationReducer: OrganizationReducerState;
  readonly monthlyScheduleReducer: MonthlyScheduleReducerState;
  readonly analyticsReducer: AnalyticsDataReducer;
  readonly hypercareRegionReducer: CurrentRegionReducerState;
  readonly virtualPagerReducer: VirtualPagerReducer;
  readonly userOrganizationReducer: UserOrganizationReducer;
}

const appReducer = combineReducers({
  flagReducer,
  userDataReducer,
  escalationReducer,
  organizationReducer,
  monthlyScheduleReducer,
  analyticsReducer,
  hypercareRegionReducer,
  virtualPagerReducer,
  userOrganizationReducer,
});

// TODO: add action types
const rootReducer = (state: State, action: AnyAction) => {
  // @ts-ignore
  if (action.type === RESET) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
