import React from 'react';
import moment from 'moment';
import store from 'src/redux/store';
import { useDispatch } from 'react-redux';
import { ApolloQueryResult } from 'apollo-boost';
import allActions from 'src/redux/actions';
import { typedUseSelector } from 'src/redux/store';
import { DEPARTMENT } from 'src/constants/organizationTypes';
import ScheduleGridLoader from 'src/components/loaders/ScheduleGridLoader';
import SchedulingSubHeader from 'src/pages/SchedulingPage/scheduling-layout/SchedulingSubHeader';
import SchedulingGridWrapper from 'src/pages/SchedulingPage/scheduling-layout/SchedulingGridWrapper';
import { useCreateSchedule } from 'src/pages/SchedulingPage/scheduling-layout/hooks';
import { RoleResultInfo, ScheduleStateType } from 'src/types';
import GridViewCalenderDelegate from './GridViewCalenderDelegate';
import { GRIDSCHEDULINGVIEW, LISTSCHEDULINGVIEW } from 'src/constants/scheduler';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { getParsedAuthRegion } from 'src/utils/localStorageHelper';
import { AdminFetchScheduleResponse } from 'src/gql/query/AdminFetchSchedule';
import { SchedulingViewModel } from '../SchedulingViewModel';
interface RenderGridContentProps {
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<AdminFetchScheduleResponse>>;
  isLoading: boolean;
  startDateMomentUTC: moment.Moment;
  payload: {
    roles: RoleResultInfo[];
    startDate: string;
    endDate: string;
    scheduleInfo: {
      state: ScheduleStateType;
      id: number;
    };
  };
}

const RenderGridContent = ({ refetch, isLoading, startDateMomentUTC, payload }: RenderGridContentProps) => {
  const payloadRef = React.useRef(null);
  const dispatch = useDispatch();
  const [holidays, setholidays] = React.useState([]);
  const monthlyScheduleReducer = typedUseSelector((state) => state.monthlyScheduleReducer);
  const calenderViewDelegate = new GridViewCalenderDelegate(
    monthlyScheduleReducer.startDateISOstring,
    monthlyScheduleReducer.monthlyCalendar,
    holidays,
  );
  const overnightShifts = IsFeatureFlagEnabled('overnightShifts');
  const { search } = useLocation();
  let urlSearchParams = new URLSearchParams(search);
  const isGridView = urlSearchParams.has('view') && urlSearchParams.get('view') === 'grid';

  const fetchHolidays = async () => {
    await axios
      .get('https://canada-holidays.ca/api/v1/holidays')
      .then((data) => {
        setholidays(data.data.holidays);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    getParsedAuthRegion() === 'CA' && fetchHolidays();
  }, []);

  React.useEffect(() => {
    if (JSON.stringify(payload) === JSON.stringify(payloadRef.current)) return;
    payloadRef.current = payload;
    if (payload) {
      let conflictCount = 0;
      let warningsCount = 0;
      const userIdSet = new Set<string>();
      payload.roles?.forEach((role) => {
        role.shifts?.forEach((shift) => {
          userIdSet.add(shift.user.id);
          conflictCount = conflictCount + (shift.conflictingShifts?.length > 0 && 1);
          warningsCount = warningsCount + (shift.warningShifts?.length > 0 && 1);
        });
      });

      dispatch(
        allActions.monthlyScheduleAction.setNumberOfConflictAndWarnings({
          numberOfConflicts: conflictCount,
          numberOfWarnings: warningsCount,
        }),
      );
      dispatch(allActions.monthlyScheduleAction.setRoleAndShifts({ ...payload, overnightShifts }));

      const currentAssignment = store.getState().monthlyScheduleReducer.userToHexColorCode;
      const containsUnAssignedUserId =
        !currentAssignment ||
        Boolean(Array.from(userIdSet).find((userId) => !currentAssignment.hasOwnProperty(userId)));

      if (containsUnAssignedUserId) dispatch(allActions.monthlyScheduleAction.setUserColor({ userIdSet }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  if (isLoading) return <ScheduleGridLoader />;

  return (
    <React.Fragment>
      <SchedulingSubHeader
        refetch={refetch}
        startDate={startDateMomentUTC}
        selectedCalendarView={isGridView ? GRIDSCHEDULINGVIEW : LISTSCHEDULINGVIEW}
      />
      {!isGridView ? (
        <SchedulingGridWrapper
          scheduleId={payload.scheduleInfo.id}
          holidays={holidays}
          overnightShifts={overnightShifts}
        />
      ) : (
        calenderViewDelegate.renderShift()
      )}
    </React.Fragment>
  );
};

interface CreateAndFetchScheduleInfoProps {
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<AdminFetchScheduleResponse>>;
  departmentId: number;
  startDateMomentUTC: moment.Moment;
}

const CreateAndFetchScheduleInfo = ({ refetch, startDateMomentUTC, departmentId }: CreateAndFetchScheduleInfoProps) => {
  const { isCreationDone } = useCreateSchedule({
    startDateMomentUTC,
    departmentId,
  });

  return (
    <RenderGridContent
      refetch={refetch}
      isLoading={!isCreationDone}
      startDateMomentUTC={startDateMomentUTC}
      payload={null}
    />
  );
};

const SchedulingGridContainer = () => {
  const department_id = typedUseSelector((state) => state.organizationReducer.department_id);
  const siteId = typedUseSelector((state) => state.organizationReducer.site_id);

  const organizationType = typedUseSelector((state) => state.organizationReducer.type);
  const startDateISOstring = typedUseSelector((state) => state.monthlyScheduleReducer.startDateISOstring);
  const endDateISOstring = typedUseSelector((state) => state.monthlyScheduleReducer.endDateISOstring);

  const viewModel = SchedulingViewModel();

  // ISO 8601
  const startOfMonthUTC = Boolean(startDateISOstring) ? startDateISOstring : moment().startOf('month').toISOString();
  const endOfMonthUTC = Boolean(endDateISOstring)
    ? endDateISOstring
    : moment().endOf('month').millisecond(0o00).toISOString();

  const adminFetchSchedulePayload = {
    departmentId: department_id,
    month: moment(startOfMonthUTC).format('MMMM').toLowerCase(),
    year: moment(startOfMonthUTC).year(),
    shiftStartDate: startOfMonthUTC,
    shiftEndDate: endOfMonthUTC,
    siteId,
  };

  const { loading, error, data, refetch } = viewModel.fetchDepartmentSchedule(adminFetchSchedulePayload);

  if (loading || error || !data) return <ScheduleGridLoader />;

  // Bug: https://github.com/apollographql/apollo-client/issues/3633
  // refetch with NEW VARIABLE wont update current local cache...(at first render)
  // use redux along with refetch for accurate variables

  const startDateMomentUTC = moment(startOfMonthUTC).utc();
  const roles = data.admin?.organization?.site?.department?.schedule?.roles;
  const schedule = data.admin?.organization?.site?.department?.schedule;

  // TODO: complete schedule VM types
  const roleAndShiftsPayload = {
    roles,
    startDate: startOfMonthUTC,
    endDate: endOfMonthUTC,
    scheduleInfo: {
      state: schedule ? schedule.state : undefined,
      id: schedule ? schedule.id : undefined,
    },
  };

  if ((!schedule && organizationType === DEPARTMENT) || !schedule.id) {
    return (
      <CreateAndFetchScheduleInfo
        refetch={refetch}
        startDateMomentUTC={startDateMomentUTC}
        departmentId={department_id}
      />
    );
  }

  return (
    <RenderGridContent
      refetch={refetch}
      isLoading={false}
      startDateMomentUTC={startDateMomentUTC}
      payload={roleAndShiftsPayload}
    />
  );
};

export default SchedulingGridContainer;
