import * as React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill={props.color || '#4a4a4a'}
    width={props.width || '24'}
    height={props.height || '24'}
    transform={props.transform ? `rotate(${props.transform})` : undefined}
    viewBox="0 0 24 24"
  >
    <path id="chevronStyle" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
