import * as React from 'react';
import styled from 'styled-components';
import SyncIcon from '../../../../assets/svgs/SyncIcon';

const FormFieldLabelIconWrapper = styled.span`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 19px;
  border: 1px solid #d8d8d8;
  background: #f2f2f2;
`;

type FormFieldSyncWrapper = {
  showIconWrapper?: boolean;
};
export const FormFieldSyncWrapper = ({ showIconWrapper }: FormFieldSyncWrapper) => {
  return showIconWrapper ? (
    <FormFieldLabelIconWrapper>
      <SyncIcon />
    </FormFieldLabelIconWrapper>
  ) : (
    <SyncIcon />
  );
};
