import moment from 'moment';

export const isMinutesHourBefore = (role) => {
  const prefillStartTime = role.startTime;
  const prefillEndTime = role.endTime;
  const minutesOfDay = (m) => m.minutes() + m.hours() * 60;
  // only comparing amount of mins and hour
  if (prefillEndTime && prefillEndTime.isValid() && prefillStartTime && prefillStartTime.isValid()) {
    return minutesOfDay(prefillEndTime) <= minutesOfDay(prefillStartTime);
  }
  return false;
};

export const getNextOverNightStartDateTime = (time, prefillRole, initailDate) => {
  let start = prefillRole;
  let end = time || moment();
  let roleStart = start.hour();
  let roleEnd = end.hour();
  let roleEndMin = end.minute();
  let endDate = moment(initailDate, 'DD/MMM/YY');
  if (minutesOfDay(end) < minutesOfDay(start)) {
    endDate = endDate.add(1, 'day');
  }
  endDate = endDate.set('hour', roleEnd).set('minute', roleEndMin);

  return endDate;
};

export const getNextOverNightEndDateTime = (time, prefillRole, initailDate) => {
  let start = prefillRole;
  let end = time || moment();
  let roleStart = start.hour();
  let roleEnd = end.hour();
  let roleEndMin = end.minute();
  let endDate = moment(initailDate, 'DD/MMM/YY');

  if (minutesOfDay(end) <= minutesOfDay(start)) {
    endDate = endDate.add(1, 'day');
  }
  endDate = endDate.set('hour', roleEnd).set('minute', roleEndMin);

  return endDate;
};

export const minutesOfDay = function (m) {
  return m.minutes() + m.hours() * 60;
};
