import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  page: (name) => {
    mixpanel.track(name);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
  reset: () => {
    mixpanel.reset();
  },
};

export let Mixpanel = actions;
