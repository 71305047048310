import React from 'react';
import { AddressBannerViewModel } from './AddressBannerViewModel';
import { AddressBanner } from './AddressBanner';
import { UserAddress } from '../../../types';

export const AddressBannerCoordinator = ({ addresses }: { addresses: UserAddress[] }) => {
  if (!addresses) {
    return null;
  }

  const { getMissingAddressInformation } = AddressBannerViewModel();

  const { type } = getMissingAddressInformation({ addresses });

  if (!type) {
    return null;
  }

  return (
    <>
      <AddressBanner type={type} />
    </>
  );
};
