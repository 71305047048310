import gql from 'graphql-tag';

export default gql`
  query GetOrganizationTOSStatus($organizationId: Int!) {
    me {
      id
      organizationTosStatus(organizationId: $organizationId)
    }
  }
`;
