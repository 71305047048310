import React from 'react';
import UserProfileLayout from 'src/pages/UserProfilePage/UserProfileLayout';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';

class UserProfilePage extends React.Component {
  public componentDidMount() {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.userProfileView);
  }

  public render() {
    return (
      <div className="appContent">
        <UserProfileLayout />
      </div>
    );
  }
}

export default UserProfilePage;
