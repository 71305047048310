import { InMemoryCache, defaultDataIdFromObject, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schemaTypes from 'src/schemaTypes.json';

let trackedSiteId: string;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schemaTypes,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      // TODO-bug: rename cacheDataId for site and department:
      // e.g. if exist same site or department id in different scope,
      // isAdmin status can be overwritten since they are same fragment
      // can expose siteId or orgId in department fragment to ensure the uniqueness of the department fragment

      // track last occurred site fragment id
      case 'Site':
        trackedSiteId = object.id;
        break;

      // the department fragment id will follow the last site id
      case 'Department':
        return `Site:${trackedSiteId}-Department:${object.id}`;

      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

export default cache;
