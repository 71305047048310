import React from 'react';
import store from 'src/redux/store';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { NOTE_LIMIT } from 'src/constants/hiddenNotes';

const HiddenNotesInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  justify-content: flex-start;
  width: calc(100% - 67%);
  padding-top: 32px;
`;

const HiddenNotesInfoHeading = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  margin-bottom: 8px;
  color: ${theme.darkenFontColor};
`;

const HiddenNotesInfoDescription = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
`;

const HiddenNotesInfo = () => {
  return (
    <HiddenNotesInfoContainer>
      <HiddenNotesInfoHeading data-testid="hidden-notes-container">Notes</HiddenNotesInfoHeading>
      <HiddenNotesInfoDescription>
        {`On behalf of this user, you can add up to ${NOTE_LIMIT} notes to let other administrators and members of ${
          store.getState()?.organizationReducer?.name
        } know of their contact preferences.`}
      </HiddenNotesInfoDescription>
    </HiddenNotesInfoContainer>
  );
};

export default HiddenNotesInfo;
