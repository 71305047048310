import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <ContentLoader
    height={250}
    width={400}
    style={{
      height: '100%',
      maxHeight: '800px',
      width: '100%',
    }}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="22" y="30" rx="5" ry="5" width="360" height="200" />
    <rect x="25" y="10" rx="4" ry="4" width="354" height="15" />
  </ContentLoader>
);
