import React, { useEffect, useState } from 'react';
import NoNumbersView from './NoPurchasedNumbers/NoPurchasedNumberView';
import PurchasedNumberView from './PurchasedNumbers/PurchasedNumbersView';
import store, { typedUseSelector } from 'src/redux/store';
import EntryPageViewModel from './EntryPageViewModel';
import NumberListLoader from './PurchasedNumbers/components/PurchasedNumberLoader';
import { PagerStats, VirtualPagerStatusTypes, VirtualPagers } from 'src/types';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const EntryPage = () => {
  const { fetchOrgPurchasedNumbers } = EntryPageViewModel();
  const { sortField, sortOrder } = typedUseSelector((state) => state.virtualPagerReducer);

  const [isFetching, setIsFetching] = useState(true);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [firstPagerPage, setFirstPagerListPage] = useState<VirtualPagers[]>([]);
  const [initalPagerStats, setInitalPagerStats] = useState<PagerStats>({
    [VirtualPagerStatusTypes.ALL]: 0,
    [VirtualPagerStatusTypes.ACTIVE]: 0,
    [VirtualPagerStatusTypes.PENDING]: 0,
    [VirtualPagerStatusTypes.DISABLED]: 0,
  });

  const fetchFirstPagerPage = async () => {
    const orgId = store.getState()?.organizationReducer?.organization_id;
    const res = await fetchOrgPurchasedNumbers(orgId, sortField, sortOrder, null);
    const pagers = res.fetchedNumbers;
    setNextCursor(res.nextResponseCursor);
    setInitalPagerStats(res.pagerStatus);
    setFirstPagerListPage(pagers);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchFirstPagerPage();
  }, []);

  if (isFetching) {
    return <NumberListLoader />;
  } else if (firstPagerPage.length === 0) {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaMainPageViewed,
      params: {
        virtual_pager_section_state: 'Empty',
      },
    });
    return <NoNumbersView onPurchase={fetchFirstPagerPage} />;
  } else {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaMainPageViewed,
      params: {
        virtual_pager_section_state: 'Table',
      },
    });
    return (
      <PurchasedNumberView
        firstPage={firstPagerPage}
        firstPageNextCursor={nextCursor}
        initalPagerStats={initalPagerStats}
      />
    );
  }
};

export default EntryPage;
