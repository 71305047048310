import { SET_HYPERCARE_REGION } from 'src/constants/actionNames';
import { HYPERCARE_REGION } from 'src/constants/storageKeys';
import { SetCurrentHypercareRegionAction } from 'src/redux/actions/changeHypercareRegionAction';
import { AuthRegion } from 'src/types/Auth';

interface CurrentRegionInterface {
  hypercareRegion: AuthRegion;
}

let initialState: CurrentRegionReducerState = {
  hypercareRegion: window.localStorage.getItem(HYPERCARE_REGION) as AuthRegion,
};

export interface CurrentRegionReducerState extends CurrentRegionInterface {}

export const hypercareRegionReducer = (
  state = initialState,
  action: SetCurrentHypercareRegionAction,
): CurrentRegionReducerState => {
  switch (action.type) {
    case SET_HYPERCARE_REGION: {
      return {
        hypercareRegion: action.hypercareRegion,
      };
    }
    default:
      return state;
  }
};
