import React, { useState } from 'react';
import ChevronRight from 'src/assets/svgs/ChevronRight';
import UserAvatar from 'src/components/shared/UserAvatar';
import { User, UserMenuOptionStatus } from 'src/types';
import { getUserFullName } from 'src/utils/getUserFullName';
import { AppRoutes } from 'src/router/AppRoutes';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { StyledHypercareChip } from 'src/components/shared/StyledHypercareChip';
import { UNMANAGED_LABEL_TOOLTIP_TEXT } from 'src/constants/dirSyncConstants';
import DirSyncViewModel from 'src/pages/HomePage/viewModels/DirSyncViewModel';
import { HCBodyTwo, HCHeadingFour } from 'src/components/shared/HypercareComponents';
import { StyledLink, StyledToolTipContainer } from 'src/components/shared/SharedStyles';
import {
  DIR_SYNC_USER_MANAGED_PILL_SUPPORT_LINK,
  END_OF_USER_LIST_MESSAGE,
  NO_UNLICENSED_USERS,
} from 'src/constants/strings';
import theme from 'src/assets/styles/theme';
import { LEARN_MORE } from 'src/constants/virtualPagerStrings';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import InfiniteScroll from 'react-infinite-scroller';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import { usePaginatedDataState } from 'src/pages/HomePage/hooks/usePaginatedDataState';
import { PaginatedPublicUser } from 'src/types/PaginatedTypes';
import {
  FetchPaginatedRemovedUsersQueryResponse,
  PaginatedBlackListedMembers,
} from 'src/gql/v2/query/FetchPaginatedRemovedUsersQuery';
import { StyledStatus, useStyles } from 'src/pages/HomePage/InviteStyle';
import { NoSearchResultsFound } from 'src/pages/HomePage/views/NoSearchResultsFound';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';

interface Props {
  currentMenuOption: UserMenuOptionStatus;
  blacklistUsers: PaginatedPublicUser[];
  fetchMorePaginatedRemovedUserData: any;
  paginatedRemovedUserDataQuery: FetchPaginatedRemovedUsersQueryResponse;
  localSearchText: string;
  searchBlackListedUserData: PaginatedBlackListedMembers;
  setSearchBlackListedUserData: React.Dispatch<React.SetStateAction<PaginatedBlackListedMembers>>;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

const RemovedUsersList: React.FC<Props> = ({
  blacklistUsers,
  paginatedRemovedUserDataQuery,
  fetchMorePaginatedRemovedUserData,
  localSearchText,
  searchBlackListedUserData,
  setSearchBlackListedUserData,
  isDoneRows,
  setIsDoneRows,
}) => {
  const classes = useStyles();
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const viewModel = DirSyncViewModel();
  const { data: orgDirSyncStatus } = viewModel.fetchOrgDirSyncStatus();
  const [showDirSyncToolTip, setShowDirSyncToolTip] = useState<HTMLElement | null | any>(null);

  const { setSeenContinuationIds, seenContinuationIds } = usePaginatedDataState();

  const { getSearchedRemovedUsers } = UserViewModel();
  const getMorePaginatedSearchData = async () => {
    const continuationId = searchBlackListedUserData.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await getSearchedRemovedUsers({ text: localSearchText, limit: 30, continuationId });

      if ('error' in result) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchBlackListedUserData({
          ...searchBlackListedUserData,
          continuationId: result.continuationId,
          users: [...searchBlackListedUserData.users, ...result.users],
        });

        if (result.continuationId === null) {
          setIsDoneRows(true);
        }
      }
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };
  const getMorePaginatedData = async () => {
    const continuationId =
      paginatedRemovedUserDataQuery.adminQuery.organizationalUnit.paginatedBlacklistedUsers.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      await fetchMorePaginatedRemovedUserData({
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          continuationId,
          direction: 'next',
        },
        updateQuery: (
          previousResult: FetchPaginatedRemovedUsersQueryResponse,
          { fetchMoreResult }: { fetchMoreResult: FetchPaginatedRemovedUsersQueryResponse },
        ) => {
          const newData = fetchMoreResult;

          newData.adminQuery.organizationalUnit.paginatedBlacklistedUsers.users = [
            ...previousResult.adminQuery.organizationalUnit.paginatedBlacklistedUsers.users,
            ...newData.adminQuery.organizationalUnit.paginatedBlacklistedUsers.users,
          ];

          const newFetchedUsers = fetchMoreResult.adminQuery.organizationalUnit.paginatedBlacklistedUsers;

          if (newFetchedUsers.continuationId === null) {
            setIsDoneRows(true);
          }

          return newData;
        },
      });
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>) => {
    setShowDirSyncToolTip(e.currentTarget);
  };

  const handlePopoverClose = (e: React.MouseEvent) => {
    setShowDirSyncToolTip(null);
  };

  if (blacklistUsers?.length === 0 || !blacklistUsers) {
    return (
      <div>
        {localSearchText.length >= 1 ? <NoSearchResultsFound /> : <p className="noUser">{NO_UNLICENSED_USERS}</p>}
      </div>
    );
  }

  return (
    <div style={{ maxHeight: `calc(100vh - 305px)`, overflow: 'auto' }}>
      <InfiniteScroll
        loadMore={localSearchText.length >= 1 ? getMorePaginatedSearchData : getMorePaginatedData}
        hasMore={!isDoneRows}
        initialLoad={false}
        useWindow={false}
      >
        {showDirSyncToolTip && (
          <Popover
            open={Boolean(showDirSyncToolTip)}
            anchorEl={showDirSyncToolTip}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className="popper__container notes__popover"
            disablePortal
          >
            <ClickAwayListener onClickAway={() => setShowDirSyncToolTip(null)}>
              <StyledToolTipContainer width={'225px'} onMouseLeave={handlePopoverClose}>
                <HCBodyTwo>
                  {UNMANAGED_LABEL_TOOLTIP_TEXT}
                  <StyledLink href={DIR_SYNC_USER_MANAGED_PILL_SUPPORT_LINK}>
                    <span style={{ color: theme.blueBorder }}>{LEARN_MORE}</span>
                  </StyledLink>
                </HCBodyTwo>
              </StyledToolTipContainer>
            </ClickAwayListener>
          </Popover>
        )}
        {blacklistUsers.map((user: User, index: number) => {
          return (
            <div className={`usersBlock`} key={`user-${index}`}>
              <div className="usersBlock__avatarIcon">
                <UserAvatar profileSize="default" user={user} />
              </div>

              <div
                className={`usersBlock__userNames ${classes.userNameSection}`}
                onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.id}`)}
              >
                <span className="usersBlock__userNames--fullName">{getUserFullName(user)}</span>
                {user.username && <span className="usersBlock__userNames--username">@{user.username}</span>}
                <ChevronRight />
              </div>

              <div className="usersBlock__userTitle">{user.role ? `${user.role}` : '--'}</div>
              <div className="usersBlock__userTags">
                <>
                  {ldapDirectorySyncFlag && orgDirSyncStatus && !user.isDirectorySynced && (
                    <span onMouseEnter={(e) => handlePopoverOpen(e)}>
                      <StyledHypercareChip type={'warning'} text={'Unmanaged'} valueClassName="chip-instance" />
                    </span>
                  )}
                </>
              </div>

              <div className={`usersBlock__userStatus `}>
                <StyledStatus status={'unlicensed'}>{'unlicensed'}</StyledStatus>
              </div>
            </div>
          );
        })}
        {isDoneRows && (
          <div style={{ height: '25px', margin: '5px' }}>
            <HCHeadingFour fontWeight={400} color={theme.mainFontColor}>
              {END_OF_USER_LIST_MESSAGE}
            </HCHeadingFour>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default RemovedUsersList;
