import gql from 'graphql-tag';
// import {publicUserFragment} from 'src/gql/fragment/FullUserFragment';

export default gql`
  mutation InviteUserToOrganization($organizationId: Int!, $userId: ID!, $licenseExpiryTime: String) {
    admin {
      locating {
        organization(id: $organizationId) {
          inviteUser(userId: $userId, licenseExpiryTime: $licenseExpiryTime) {
            id
          }
        }
      }
    }
  }
`;
