import React from 'react';
import styled from 'styled-components';
import EscalationOnCallSource from 'src/pages/EscalationPage/escalation-layout/drop-source/EscalationOnCallSource';
import { DropSourceItem } from 'src/types';
import { useDrop } from 'react-dnd';
import { DropItemType, EscalationLevelOnCallActionPayload, EscalationMember } from 'src/types/Escalation';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { typedUseSelector } from 'src/redux/store';
interface StyledOnCallSourceDragWrapperProps {
  isOver: boolean;
  isAllowedType: boolean;
}

const EscalationOnCallSourceDragWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  min-height: 400px;
  padding-right: 8px;
  opacity: ${(props: StyledOnCallSourceDragWrapperProps) => (props.isOver ? 0.4 : 'unset')};
  filter: ${(props: StyledOnCallSourceDragWrapperProps) => (!props.isAllowedType ? 'blur(2px)' : 'unset')};
`;

const ONCALL_TOOLTIPS = 'Start creating your escalation by dragging a role or person to the canvas';
interface Props {
  searchBy: 'user' | 'role';
  dropSourceItems: DropSourceItem[];
}

const EscalationSourceOnCallList: React.FC<Props> = ({ dropSourceItems, searchBy }) => {
  const ALLOWED_TYPES: DropItemType[] = ['droppedUser', 'droppedRole'];

  const escalationLadder = typedUseSelector((state) => state.escalationReducer.escalationLadder);
  const dispatch = useDispatch();
  const deleteEscalationLevelOnCall = (payload: EscalationLevelOnCallActionPayload) =>
    dispatch(allActions.escalationAction.deleteEscalationLevelOnCall(payload));

  const [{ isAllowedType, isOver }, drop] = useDrop({
    accept: ALLOWED_TYPES,
    drop(item: DropSourceItem) {
      if (item.type.toLowerCase().includes(searchBy)) {
        deleteEscalationLevelOnCall({
          levelIndex: item.originLevelIndex,
          onCallData: item.data as EscalationMember,
        });
      }
    },
    collect: (monitor) => ({
      isOver: (() => {
        let droppedItem: DropSourceItem = monitor.getItem();
        if (!droppedItem) return false;
        return droppedItem.type.toLowerCase().includes(searchBy) && monitor.isOver();
      })(),
      isAllowedType:
        monitor.getItem() === null ||
        (monitor.getItem() && (monitor.getItem().type as DropItemType).toLowerCase().includes(searchBy)),
    }),
  });

  const shouldShowToolTip = escalationLadder.length === 1 && escalationLadder.get(0).escalationMembers.length === 0;

  return (
    <Tooltip
      title={ONCALL_TOOLTIPS}
      placement="right"
      open={shouldShowToolTip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
    >
      <EscalationOnCallSourceDragWrapper ref={drop} isOver={isOver} isAllowedType={isAllowedType}>
        {dropSourceItems.map((dropSourceItem, i) => (
          <EscalationOnCallSource dropSourceItem={dropSourceItem} key={i} />
        ))}
      </EscalationOnCallSourceDragWrapper>
    </Tooltip>
  );
};

export default EscalationSourceOnCallList;
