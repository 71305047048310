import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';
import { GET_NUMBERS, WATCH_VIDEO, VISIT_SUPPORT_CENTER, REACTIVATE } from 'src/constants/virtualPagerStrings';
import WatchVideo from 'src/assets/svgs/WatchVideo';
import AddIcon from 'src/assets/svgs/AddIcon';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
`;

interface GetNumbersButtonProps {
  isDisabled: boolean;
  handleOnClick: (boolean) => void;
  virtualPagerSectionState: string;
}

interface ButtonProps {
  isDisabled: boolean;
  handleClick?: () => void;
}

export const GetNumbersButton: React.FC<GetNumbersButtonProps> = ({
  isDisabled,
  handleOnClick,
  virtualPagerSectionState,
}) => {
  return (
    <React.Fragment>
      <PrimaryButton
        type="button"
        disabled={isDisabled}
        onClick={() => {
          AnalyticsManager.applyAnalytics({
            eventName: EVENTS.pvpaGetNumbersPressed,
            params: {
              virtual_pager_section_state: virtualPagerSectionState,
            },
          });
          handleOnClick(true);
        }}
      >
        <AddIcon fill={theme.white} styles={{ marginRight: '8px' }} />
        {GET_NUMBERS}
      </PrimaryButton>
    </React.Fragment>
  );
};

export const VideoButton: React.FC<ButtonProps> = ({ isDisabled }) => {
  const videoUrl = 'https://hypercare.wistia.com/medias/94yg9qe5qm';

  const openInNewTabLink = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaEmptyStateMainPageSeeHowThisWorksVideoPressed,
    });
    window.open(videoUrl, '_blank');
  };

  return (
    <SecondaryButton disabled={isDisabled} color={theme.mainFontColor} onClick={openInNewTabLink}>
      <ButtonIcon style={{ marginRight: '8px' }}>
        <WatchVideo />
      </ButtonIcon>
      {WATCH_VIDEO}
    </SecondaryButton>
  );
};

export const VisitSupportCenterButton: React.FC<ButtonProps> = ({ isDisabled }) => {
  const supportCenterUrl = 'https://support.hypercare.com/hc/en-us/sections/17834105417741-Virtual-pagers';

  const openInNewTabLink = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaEmptyStateMainPageVisitSupportCenterPressed,
    });
    window.open(supportCenterUrl, '_blank');
  };

  return (
    <SecondaryButton disabled={isDisabled} color={theme.mainFontColor} onClick={openInNewTabLink}>
      {VISIT_SUPPORT_CENTER}
    </SecondaryButton>
  );
};

export const DisableNumberButton: React.FC<ButtonProps> = ({ isDisabled, handleClick }) => {
  return (
    <SecondaryButton disabled={isDisabled} onClick={handleClick} color={theme.errorRed}>
      Temporarily Disable Number
    </SecondaryButton>
  );
};

export const ReactivateNumberButton: React.FC<ButtonProps> = ({ isDisabled, handleClick }) => {
  return (
    <PrimaryButton disabled={isDisabled} onClick={handleClick}>
      {REACTIVATE}
    </PrimaryButton>
  );
};

export const ContactSupportCenterButton: React.FC<ButtonProps> = ({ isDisabled, handleClick }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    handleClick();
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Email copied to clipboard!"
        >
          <SecondaryButton disabled={isDisabled} color={theme.errorRed} onClick={handleTooltipOpen}>
            Contact Hypercare support
          </SecondaryButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

interface GetNumbersNextButtonProps {
  isDisabled: boolean;
  handleOnClick: (boolean) => void;
  totalPurchases: number;
}

export const GetNumbersNextButton: React.FC<GetNumbersNextButtonProps> = ({
  isDisabled,
  handleOnClick,
  totalPurchases,
}) => {
  return (
    <React.Fragment>
      <PrimaryButton type="button" disabled={isDisabled} onClick={() => handleOnClick(true)}>
        Next &#40;{totalPurchases}&#41;
      </PrimaryButton>
    </React.Fragment>
  );
};
