import {
  SET_CURRENT_PAGER,
  SET_EDITED_PAGER,
  SET_PAGER_LIST,
  SET_NEXT_CURSOR,
  SET_SEARCH_TEXT,
  SET_SEARCH_TYPE,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
  SET_EDITED_AND_CURRENT_PAGER,
} from 'src/constants/actionNames';

import { SearchFieldType, SortFieldType, SortOrderType, VirtualPagers } from 'src/types';

//? Configure Pager Actions
interface setCurrentPagerAction {
  type: typeof SET_CURRENT_PAGER;
  pager: VirtualPagers;
}

interface setEditedPagerAction {
  type: typeof SET_EDITED_PAGER;
  pager: VirtualPagers;
}

interface setEditedAndCurrentPagerAction {
  type: typeof SET_EDITED_AND_CURRENT_PAGER;
  pager: VirtualPagers;
}

//? Pager List Actions
interface setPagerListAction {
  type: typeof SET_PAGER_LIST;
  pagerList: VirtualPagers[];
}

interface setNextCursorAction {
  type: typeof SET_NEXT_CURSOR;
  nextCursor: string;
}

interface setSearchTextAction {
  type: typeof SET_SEARCH_TEXT;
  searchText: string;
}

interface setSearchTypeAction {
  type: typeof SET_SEARCH_TYPE;
  searchType: SearchFieldType;
}

interface setSortFieldAction {
  type: typeof SET_SORT_FIELD;
  sortField: SortFieldType;
}

interface setSortOrderAction {
  type: typeof SET_SORT_ORDER;
  sortOrder: SortOrderType;
}

export const setCurrentPager = (pager: VirtualPagers): setCurrentPagerAction => {
  return {
    type: SET_CURRENT_PAGER,
    pager,
  };
};

export const setEditedPager = (pager: VirtualPagers): setEditedPagerAction => {
  return {
    type: SET_EDITED_PAGER,
    pager,
  };
};

export const setEditedAndCurrentPager = (pager: VirtualPagers): setEditedAndCurrentPagerAction => {
  return {
    type: SET_EDITED_AND_CURRENT_PAGER,
    pager,
  };
};

export const setPagerList = (pagerList: VirtualPagers[]): setPagerListAction => {
  return {
    type: SET_PAGER_LIST,
    pagerList,
  };
};

export const setNextCursor = (nextCursor: string): setNextCursorAction => {
  return {
    type: SET_NEXT_CURSOR,
    nextCursor,
  };
};

export const setSearchText = (searchText: string): setSearchTextAction => {
  return {
    type: SET_SEARCH_TEXT,
    searchText,
  };
};

export const setSearchType = (searchType: SearchFieldType): setSearchTypeAction => {
  return {
    type: SET_SEARCH_TYPE,
    searchType,
  };
};

export const setSortField = (sortField: SortFieldType): setSortFieldAction => {
  return {
    type: SET_SORT_FIELD,
    sortField,
  };
};

export const setSortOrder = (sortOrder: SortOrderType): setSortOrderAction => {
  return {
    type: SET_SORT_ORDER,
    sortOrder,
  };
};

export type configurePagerActions = setCurrentPagerAction | setEditedPagerAction | setEditedAndCurrentPagerAction;
export type pagerListActions =
  | setPagerListAction
  | setNextCursorAction
  | setSortFieldAction
  | setSortOrderAction
  | setSearchTextAction
  | setSearchTypeAction;
export type virtualPagerActions = configurePagerActions | pagerListActions;
