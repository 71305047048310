/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import { QueryResult } from 'react-apollo';
import { JOIN_DATE, LASTNAME } from 'src/constants/SorterTypes';
import SortButton from 'src/pages/HomePage/views/SortButton';
import { SorterType, User, UserMenuOptionStatus, GetPendingUsersResult } from 'src/types';
import UsersList from 'src/pages/HomePage/account-management/UsersList';
import { ByLastName, ByLastNameRev, ByISOstring, ByISOstringRev, sortByInviteStatus } from 'src/utils/userSorting';
import PendingUserListContainer from 'src/pages/HomePage/views/PendingUserListContainer';
import { typedUseSelector } from 'src/redux/store';
import { ORGANIZATION } from 'src/constants/organizationTypes';
import { USER_MENU_OPTION } from 'src/constants/inviteUserTypes';
import { PENDING_ADMIN, PENDING_USER } from 'src/constants/inviteUserTypes';

const StyledContainer = styled.div`
  padding: 12px 0px !important;
  margin-top: 28px !important;
`;

interface Props {
  licensedUsers: User[];
  blackListedUsers: User[];
  pendingRecords: QueryResult<GetPendingUsersResult>;
  adminPendingUsers: User[];
  currentMenuOption: UserMenuOptionStatus;
  adminCoSignInvites: boolean;
}

const AllUsersSectionLayout = ({
  licensedUsers,
  blackListedUsers,
  pendingRecords,
  adminPendingUsers,
  currentMenuOption,
  adminCoSignInvites,
}: Props) => {
  const [sorterType, setSorterType] = React.useState<SorterType>('lastName');
  const [pendingByAdmin, setPendingByAdmin] = React.useState([]);
  const [pendingByInvitee, setPendingByInvitee] = React.useState([]);

  const isSortLastName = typedUseSelector((state) => state.flagReducer.sortLastNameRev);
  const isSortJoinDate = typedUseSelector((state) => state.flagReducer.sortJoinDateRev);
  const currentOrganizationType = typedUseSelector((state) => state.organizationReducer.type);

  const pendingUsers = adminCoSignInvites
    ? pendingRecords?.data?.locating?.organization?.userInvites
    : adminPendingUsers;

  const pendingTypes = [PENDING_ADMIN, PENDING_USER];

  const sortLastNamePattern = isSortLastName ? ByLastNameRev : ByLastName;
  const sortJoinDatePattern = isSortJoinDate ? ByISOstring : ByISOstringRev;

  let sortSelector = ByLastName;

  if (sorterType === LASTNAME) {
    sortSelector = sortLastNamePattern;
  } else if (sorterType === JOIN_DATE) {
    sortSelector = sortJoinDatePattern;
  }
  if (sorterType === LASTNAME) {
    sortSelector = sortLastNamePattern;
  } else if (sorterType === JOIN_DATE) {
    sortSelector = sortJoinDatePattern;
  }

  const presentedUsers: User[] = React.useMemo(() => {
    if (currentOrganizationType !== ORGANIZATION) return licensedUsers.sort(sortSelector);
    if (currentMenuOption === USER_MENU_OPTION.All) {
      if (adminCoSignInvites) {
        if (sorterType === JOIN_DATE && isSortJoinDate) {
          return [
            ...licensedUsers.sort(sortSelector),
            ...blackListedUsers.sort(sortSelector),
            ...pendingByAdmin.sort(sortByInviteStatus),
            ...pendingByInvitee.sort(sortByInviteStatus),
          ];
        }
        return [
          ...pendingByAdmin.sort(sortByInviteStatus),
          ...pendingByInvitee.sort(sortByInviteStatus),
          ...licensedUsers.sort(sortSelector),
          ...blackListedUsers.sort(sortSelector),
        ];
      } else {
        return [
          ...licensedUsers.sort(sortSelector),
          ...blackListedUsers.sort(sortSelector),
          ...pendingUsers.sort(sortSelector),
        ];
      }
    }
    if (currentMenuOption === USER_MENU_OPTION.Licensed) return licensedUsers.sort(sortSelector);
    if (currentMenuOption === USER_MENU_OPTION.Pending) return pendingUsers.sort(sortSelector);
    if (adminCoSignInvites && currentMenuOption === USER_MENU_OPTION.Unlicensed) {
      return blackListedUsers.sort(sortSelector);
    } else {
      return [...blackListedUsers.sort(sortSelector), ...pendingUsers.sort(sortSelector)];
    }
  }, [
    currentOrganizationType,
    licensedUsers,
    blackListedUsers,
    pendingUsers,
    currentMenuOption,
    sortSelector,
    pendingByAdmin,
    pendingByInvitee,
    sorterType,
    isSortJoinDate,
  ]);

  React.useEffect(() => {
    const formatUserData = async (status: string, users: User[]) => {
      const pendingUser = users.filter((user) => user.inviteStatus === status).sort(sortByInviteStatus);
      status === PENDING_ADMIN ? setPendingByAdmin(pendingUser) : setPendingByInvitee(pendingUser);
    };

    formatUserData(PENDING_ADMIN, pendingUsers);
    formatUserData(PENDING_USER, pendingUsers);
  }, [pendingUsers]);

  return adminCoSignInvites && currentMenuOption === USER_MENU_OPTION.Pending ? (
    pendingByAdmin.length === 0 && pendingByInvitee.length === 0 ? (
      <StyledContainer className="usersList">
        <div className="usersList__wrapper">
          <div className="usersList__wrapper__content">
            <p className="noUser">There are no users yet</p>
          </div>
        </div>
      </StyledContainer>
    ) : (
      <>
        {pendingTypes.map((type, index) => (
          <PendingUserListContainer
            key={index}
            users={type === PENDING_ADMIN ? pendingByAdmin : pendingByInvitee}
            status={type}
            pendingByAdmin={pendingByAdmin}
            setPendingByAdmin={setPendingByAdmin}
            pendingByInvitee={pendingByInvitee}
            setPendingByInvitee={setPendingByInvitee}
            pendingRecords={pendingRecords}
          />
        ))}
      </>
    )
  ) : (
    <div className="usersList">
      <SortButton sorterType={sorterType} setSorterType={setSorterType} currentMenuOption={currentMenuOption} />
      <div className="usersList__wrapper">
        <div className="usersList__wrapper__content">
          <UsersList
            currentMenuOption={currentMenuOption}
            users={presentedUsers}
            sorterType={sorterType}
            pendingUsers={pendingUsers}
            blacklistUsers={blackListedUsers}
            pendingByAdmin={pendingByAdmin}
            setPendingByAdmin={setPendingByAdmin}
            pendingByInvitee={pendingByInvitee}
            setPendingByInvitee={setPendingByInvitee}
            pendingRecords={pendingRecords}
            adminCoSignInvites={adminCoSignInvites}
          />
        </div>
      </div>
    </div>
  );
};

export default AllUsersSectionLayout;
