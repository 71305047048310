import React, { useState } from 'react';
import ConfirmationActionModal from 'src/components/modals/ConfirmationActionModal';
import 'src/assets/styles/ProfileStyles.scss';
import ChevronRight from 'src/assets/svgs/ChevronRight';
import { REMOTE_LOGOUT, REMOVE_USER, PROMOTE_ADMIN } from 'src/constants/modalTypes';
import { ModalActionType } from 'src/types/Modal';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';

type Props = {
  isAdmin: boolean;
};

const ProfileFooter = ({ isAdmin }: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [ModalActionType, setModalActionType] = useState<ModalActionType>('');
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);

  const setModalStatus = (type: ModalActionType, bool: boolean) => {
    setShowConfirmationModal(bool);
    setModalActionType(type);
  };

  const closeModal = () => {
    if (document.getElementById('profile-footer')) setShowConfirmationModal(false);
    setModalActionType('');
  };

  const handleMixpanelEvent = (eventType) => {
    AnalyticsManager.applyAnalytics({
      eventName: eventType,
    });
  };

  return (
    <>
      <div className="profile-footer" id="profile-footer">
        {!isAdmin && (
          <div
            className="profileForm__buttonText"
            onClick={() => {
              setModalStatus(PROMOTE_ADMIN, true);
              handleMixpanelEvent('promoteToAdminButtonPressed');
            }}
          >
            Promote to Admin
            <ChevronRight />
          </div>
        )}
        <div className="divider" />

        {!ldapDirectorySyncFlag && (
          <div
            className="profileForm__buttonText"
            onClick={() => {
              setModalStatus(REMOVE_USER, true);
              handleMixpanelEvent(EVENTS.removeMemberPressed);
            }}
          >
            Remove Member
            <ChevronRight />
          </div>
        )}
      </div>
      {showConfirmationModal && (
        <ConfirmationActionModal
          profile={null}
          showModal={showConfirmationModal}
          confirmationType={ModalActionType}
          handleCloseModal={closeModal}
        />
      )}
    </>
  );
};

export default ProfileFooter;
