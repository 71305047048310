import React, { CSSProperties } from 'react';
import Select, { createFilter } from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import { ValueType } from 'react-select/src/types';
import overriddenComponents, { useStyles } from 'src/components/shared/ReactSelectMaterialUI';
import { UserSelectOptionType } from 'src/types';
import {
  CircleOption as Option,
  FixedHeightMenuList as MenuList,
} from 'src/components/shared/ReactSelectSharedComponents';

/**
 * Search by label and role
 */
const stringify = (option) => {
  return option.label + option.data.role;
};
const filterOption = createFilter({ ignoreCase: true, stringify });

const AddMemberListSelect = ({ userNameArrError, onClickColleague, suggestions }) => {
  const classes = useStyles({});
  const theme = useTheme();
  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([]);

  function handleChangeMulti(value: ValueType<UserSelectOptionType>) {
    onClickColleague(value);
    setSelectedColleague(value);
  }

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    // tslint:disable jsx-no-lambda
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Select
        autoFocus
        classes={classes}
        styles={selectStyles}
        inputId="react-select-multiple"
        TextFieldProps={{
          label:
            selectedColleague && selectedColleague.length >= 1
              ? 'Click Add when finished'
              : 'Type the name of a colleague',
          InputLabelProps: {
            htmlFor: 'react-select-multiple',
            shrink: true,
          },
          error: Boolean(userNameArrError),
        }}
        placeholder={'Search a name...'}
        options={suggestions}
        components={{
          ...overriddenComponents,
          Option,
          MenuList,
        }}
        value={selectedColleague}
        onChange={handleChangeMulti}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        filterOption={filterOption}
        isMulti
        menuIsOpen
      />
    </div>
  );
};

export default React.memo(AddMemberListSelect);
