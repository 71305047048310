import React from 'react';

import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import ContentLoader from 'react-content-loader';

const StyledTBody = styled.tbody`
  overflow: auto;
  background: white;
`;

const TC = styled.td`
  text-align: left;
  border-bottom: 1px solid #ccc;
  /* padding: 16px 0px; */
`;

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${theme.borderBottomLight};
  border-bottom: 1px solid ${theme.borderBottomLight};
`;

const VirtualPagerMenuItemLoader = () => {
  return (
    <div>
      <CustomTable>
        <StyledTBody>
          <tr>
            <TC
              className="number"
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={80} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="26" x="12" width="148" height="12" rx="4" fill="#E4E5E7" />
                <rect y="46" x="12" width="112" height="8" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>

            <TC
              className="status"
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={80} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="40" width="110" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '20.8%',
              }}
            >
              <ContentLoader height={80} width={293} style={{ height: '100%', width: '100%' }}>
                <rect y="40" width="220" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              className="alert"
              style={{
                width: '8.8%',
              }}
            >
              <ContentLoader height={80} width={120} style={{ height: '100%', width: '100%' }}>
                <rect y="40" width="80" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '8.8%',
              }}
            >
              <ContentLoader height={80} width={120} style={{ height: '100%', width: '100%' }}>
                <rect y="40" width="80" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={80} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="40" width="110" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={80} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="40" width="110" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              className="redirect"
              style={{
                width: '8.8%',
              }}
            >
              <ContentLoader height={80} width={120} style={{ height: '100%', width: '100%' }}>
                <rect y="40" width="80" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
          </tr>
          <tr>
            <TC
              className="number"
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={56} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="28" x="12" width="148" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>

            <TC
              className="status"
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={56} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="28" width="110" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '20.8%',
              }}
            >
              <ContentLoader height={56} width={293} style={{ height: '100%', width: '100%' }}>
                <rect y="28" width="220" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              className="alert"
              style={{
                width: '8.8%',
              }}
            >
              <ContentLoader height={56} width={120} style={{ height: '100%', width: '100%' }}>
                <rect y="28" width="80" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '8.8%',
              }}
            >
              <ContentLoader height={56} width={120} style={{ height: '100%', width: '100%' }}>
                <rect y="28" width="80" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={56} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="28" width="110" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              style={{
                width: '13.2%',
              }}
            >
              <ContentLoader height={56} width={185} style={{ height: '100%', width: '100%' }}>
                <rect y="28" width="110" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
            <TC
              className="redirect"
              style={{
                width: '8.8%',
              }}
            >
              <ContentLoader height={56} width={120} style={{ height: '100%', width: '100%' }}>
                <rect y="28" width="80" height="12" rx="4" fill="#E4E5E7" />
              </ContentLoader>
            </TC>
          </tr>
        </StyledTBody>
      </CustomTable>
    </div>
  );
};

export default VirtualPagerMenuItemLoader;
