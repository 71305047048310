import React from 'react';
import classNames from 'classnames';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Assignee, User } from 'src/types';

const ImageAvatarStyles = createStyles({
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: '40px',
    height: '40px',
    position: 'initial',
  },
  largeAvatar: {
    width: 157,
    height: 157,
    position: 'initial',
  },
  smallAvatar: {
    width: 32,
    height: 32,
    position: 'initial',
  },
});

const LetterAvatarStyles = createStyles({
  avatar: {
    width: '40px',
    height: '40px',
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Nunito',
    backgroundColor: '#c7c7cc',
    letterSpacing: '-0.6px',
  },
  smallAvatar: {
    width: 26,
    height: 26,
    fontSize: '12px',
  },
  mediumAvatar: {
    width: 40,
    height: 40,
    fontSize: '13px',
  },
  largeAvatar: {
    width: 157,
    height: 157,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  root2: {
    position: 'initial',
    fontSize: 80,
  },
  root: {
    position: 'initial',
  },
});

const avatarSize = (profileSize: string, classes) => {
  switch (profileSize) {
    case 'small':
      return classes.smallAvatar;
    case 'medium':
      return classes.avatar;
    case 'large':
      return classes.largeAvatar;
    default:
      return classes.avatar;
  }
};

interface ImageAvatarProps extends WithStyles<typeof ImageAvatarStyles> {
  user: User | Assignee | any;
  profileSize: profileSize;
}

const ImageAvatars = (props: ImageAvatarProps) => {
  const { classes, user, profileSize } = props;
  const altName = `${user.firstName} ${user.lastName}`;
  return (
    <div className={classes.row}>
      <Avatar alt={altName} src={user.avatar.url} className={classNames(avatarSize(profileSize, classes))} />
    </div>
  );
};

const RenderImageAvatar = withStyles(ImageAvatarStyles)(ImageAvatars);

interface LetterAvatarProps extends WithStyles<typeof LetterAvatarStyles> {
  user: User | Assignee | any;
  profileSize: profileSize;
}

const LetterAvatars = (props: LetterAvatarProps) => {
  const { classes, user, profileSize } = props;
  const Initials = `${user.firstName ? user.firstName[0]?.toUpperCase() : ''}${
    user.lastName ? user.lastName[0]?.toUpperCase() : ''
  }`;
  const displaySettings = profileSize === 'large' ? '' : classes.row;
  const root = profileSize === 'large' ? classes.root2 : classes.root;

  return (
    <div className={displaySettings}>
      <Avatar className={classNames(root, avatarSize(profileSize, classes))}>{Initials}</Avatar>
    </div>
  );
};

const RenderLetterAvatar = withStyles(LetterAvatarStyles)(LetterAvatars);

type profileSize = 'default' | 'small' | 'medium' | 'large';

interface UserAvatarProps {
  profileSize: profileSize;
  user: User | Assignee | any;
}

const AvatarComponent = ({ user, profileSize }: UserAvatarProps) => {
  if (user && user.avatar) return <RenderImageAvatar user={user} profileSize={profileSize} />;

  return <RenderLetterAvatar user={user} profileSize={profileSize} />;
};

export default AvatarComponent;
