import { useState } from 'react';

export const usePaginatedDataState = () => {
  const [isLoadingAdditionalData, setIsLoadingAdditionalData] = useState(false);
  const [isDoneRows, setIsDoneRows] = useState(false);
  const [additionalRows, setAdditionalRows] = useState([]);
  const [seenContinuationIds, setSeenContinuationIds] = useState([]);

  return {
    isLoadingAdditionalData,
    setIsLoadingAdditionalData,
    isDoneRows,
    setIsDoneRows,
    additionalRows,
    setAdditionalRows,
    seenContinuationIds,
    setSeenContinuationIds,
  };
};
