import gql from 'graphql-tag';
import { removeUserFragmentString } from 'src/gql/fragment/FullUserFragment';

export const REMOVE_USER = gql`
  mutation removeUser($userId: ID!, $scope: OrganizationScopeInput) {
    admin {
      user(id: $userId) {
        remove(scope: $scope) {
          ...FullUserFragment
        }
      }
    }
  }

  fragment FullUserFragment on FullUser {
    ${removeUserFragmentString}
  }
`;
