import gql from 'graphql-tag';

export const FETCH_SSO_PROFILE = gql`
  query FetchSSOProfiles {
    listSSOProfiles {
      organization {
        id
        name
        imageUrl
      }
      auth0Id
      domain
      ssoVendor
      ssoId
    }
  }
`;
