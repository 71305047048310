import * as React from 'react';
import ReactModal from 'react-modal';

export default ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className as string | undefined}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
};
