import React from 'react';
import client from 'src/clients/apolloClient';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { GetAllLicensingUsersResult, User } from 'src/types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import WhitelistUserMutation from 'src/gql/mutation/WhitelistUserMutation';
import ReinviteUserMutation from 'src/gql/mutation/ReinviteUserMutation';
import { typedUseSelector } from 'src/redux/store';
import { GET_USERS_WITH_LICENSE } from 'src/gql/query/GetUsersQuery';
import sleep from 'src/utils/sleep';

const ModalContentWrapper = styled.div<{ disabled: boolean }>`
  margin-bottom: 1em;
  input {
    pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
    color: black !important;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const LicenseDatePickerContainer = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
  .MuiFormLabel-root {
    min-width: max-content;
    text-transform: capitalize !important;
  }
  & > div {
    width: 48%;
  }
`;

const InputsWrapper = styled.div`
  margin-bottom: 1em;
`;
interface Props {
  showModal: boolean;
  user: User;
  isPending: boolean;
  handleCloseModal: () => void;
}

// TODO: reinvite should hide addresses with 'access' of 'public' only,
// confirm with backend if it should reinvite an addresses with private access

const ResendInviteModal = ({ showModal, isPending, user, handleCloseModal }: Props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [licenseEndDate, setLicenseEndDate] = React.useState<moment.Moment>(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const currentOrganization = typedUseSelector((state) => state.organizationReducer);

  // pick first occurrence email and phone type where input email has higher priority than phone-umber
  const firstOccurrenceEmail = user.addresses.find((address) => address.type === 'email');
  const firstOccurrencePhone = user.addresses.find((address) => address.type === 'phoneNumber');

  const handleReinvite = async () => {
    setLoading(true);
    try {
      if (!isPending) {
        await client.mutate({
          mutation: WhitelistUserMutation,
          variables: {
            userId: user.id,
          },
        });

        await sleep(1000);
      }

      await client.mutate({
        mutation: ReinviteUserMutation,
        variables: {
          organizationId: currentOrganization.organization_id,
          userId: user.id,
          licenseExpiryTime: licenseEndDate ? licenseEndDate.toISOString() : null,
        },
      });
      toast.success('User has been reinvited', {
        className: 'Toast-Container',
      });
      handleCloseModal();
    } catch (e) {
      console.error(e);
      setLoading(false);
      toast.error('Failed to reinvite user', {
        className: 'Toast-Container',
      });
    }
  };

  const handleDateChange = (date: moment.Moment) => {
    if (date) {
      setLicenseEndDate(date.endOf('day').millisecond(0o00));
    } else {
      setLicenseEndDate(null);
    }
    if (errorMessage) setErrorMessage('');
  };

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__confirmation"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={handleCloseModal}
    >
      <div className="close" onClick={handleCloseModal} />
      <div className="modal__header">Reinvite {user.firstname}</div>
      <ModalContentWrapper disabled={isLoading}>
        <InputsWrapper>
          <TextField
            fullWidth
            disabled
            id="email"
            label="Email"
            name="email"
            InputLabelProps={{
              shrink: true,
            }}
            value={firstOccurrenceEmail ? firstOccurrenceEmail.address : '--'}
            margin="normal"
          />
          {!firstOccurrenceEmail && firstOccurrencePhone && (
            <TextField
              fullWidth
              disabled
              id="phone"
              label="Phone number"
              name="phone"
              value={firstOccurrencePhone ? firstOccurrencePhone.address : ''}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          )}
          <TextField
            fullWidth
            disabled
            id="role"
            label="Role title"
            name="role"
            InputLabelProps={{
              shrink: true,
            }}
            value={user.role || '--'}
            margin="normal"
          />
        </InputsWrapper>

        <LicenseDatePickerContainer>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              id="licenseStartDate"
              name="licenseStartDate"
              disabled
              disableToolbar
              variant="inline"
              label="License Start Date"
              helperText="The date the user's account becomes active in your organization"
              value={new Date()}
              InputLabelProps={{
                shrink: true,
              }}
              format="MM/DD/yyyy"
              onChange={() => null}
            />
            <KeyboardDatePicker
              id="licenseEndDate"
              name="licenseEndDate"
              placeholder="MM/DD/YYYY"
              disabled={isLoading}
              disableToolbar
              autoOk
              autoFocus
              variant="inline"
              label="License End Date (optional)"
              helperText="The date that the user's account gets automatically removed from the organization"
              value={licenseEndDate}
              InputLabelProps={{
                shrink: true,
              }}
              format="MM/DD/yyyy"
              onError={(err) => setErrorMessage(err ? (err as string) : errorMessage)}
              minDate={moment().add(1, 'days')}
              onChange={(date) => handleDateChange(date)}
            />
          </MuiPickersUtilsProvider>
        </LicenseDatePickerContainer>
      </ModalContentWrapper>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <Button
        disabled={isLoading || !!errorMessage}
        fullWidth
        disableTouchRipple
        color="secondary"
        variant="contained"
        onClick={handleReinvite}
      >
        Reinvite user
      </Button>
    </ReactModal>
  );
};

export default ResendInviteModal;
