import styled from 'styled-components';

export const EscalationLadderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  min-width: calc(968px - 400px - 1em); // min-break pt - source width - right padding
  overflow-y: scroll;
  padding-top: 2em;
  padding-bottom: 12px;
`;

export const EscalationLevelWrapper = styled.div`
  position: relative;
  padding: 0 2em;
  width: 80%;
  height: min-content;
  border: 1px dashed transparent;
`;

export const EscalationLevelArrow = styled.div`
  position: relative;
  width: 2px;
  height: 80px;
  min-height: 80px;
  background: black;
  margin: 0 auto;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: -9px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  &::after {
    bottom: 0;
    border-top: 15px solid black;
  }
`;

export const BaseEscalationOnCallMembersWrapper = styled.div`
  user-select: none;
  position: relative;
  min-height: 120px;
  min-width: 340px;
  color: black;
  padding: 2em 1.5em;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  border: 1px solid lightgrey;
  align-items: left;
  box-shadow: 0 4px 4px 0 rgba(219, 219, 219, 0.25);
`;

export const EscalationLevelTitle = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1em;
`;

export const OnCallMemberListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BaseOnCallMemberHolder = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 84px;
  padding: 0.5em 1em;
  background-color: white;
  margin-bottom: 1em;
  border: 1px solid grey;
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const OnCallNameHolder = styled.div`
  display: flex;
  font-weight: 600;
  height: 32px;
  line-height: 32px;
  justify-content: space-between;
  div {
    width: 100%;
  }
`;

export const OnCallRoleTitle = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

export const OnCallRoleTime = styled.div`
  font-size: 14px;
  text-transform: capitalize;
  line-height: 30px;
  height: 28px;
  max-width: min-content;
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 0 4px;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
`;

export const BaseEscalationPolicyWrapper = styled.div`
  position: relative;
  user-select: none;
  height: 140px;
  width: 220px;
  color: black;
  padding: 2em 1em;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border: 1px solid black;
  box-shadow: 0 4px 4px 0 rgba(219, 219, 219, 0.25);
`;

export const EscalationName = styled.div``;

export const EscalationDetail = styled.div`
  font-weight: 800;
`;

export const CustomToastFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1em;
  line-height: 1.5em;
  font-weight: lighter;
  svg {
    margin-right: 0.5em;
  }
`;

export const ToastMessageContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    stroke: white !important;
  }
`;

export default null;
