import React from 'react';
import moment from 'moment';
import { ActionType } from 'src/types';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import UserAvatar from 'src/components/shared/UserAvatar';
import { getParsedAuthInfo } from 'src/utils/localStorageHelper';
import { Box } from '@material-ui/core';
import {
  APPROVE,
  PENDING_ADMIN,
  PENDING_USER,
  PresentedUserLicensingStatus,
  REJECT,
  TIMER,
} from 'src/constants/inviteUserTypes';
import client from 'src/clients/apolloClient';
import { APPROVE_INVITE } from 'src/gql/mutation/ApproveInvite';
import { DECLINE_INVITE } from 'src/gql/mutation/DeclineInvite';
import { toast } from 'react-toastify';
import CustomToaster from 'src/components/CustomToaster';
import CheckSuccess from 'src/assets/svgs/CheckSuccess';
import PendingInviteModal from 'src/components/modals/PendingInviteModal';
import {
  StyledButton,
  StyledMenu,
  StyledMenuItem,
  StyledStatus,
  UserListContainer,
  useStyles,
} from 'src/pages/HomePage/InviteStyle';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { getUserFullName } from 'src/utils/getUserFullName';
import { AppRoutes } from 'src/router/AppRoutes';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { PaginatedInvites } from 'src/types/PaginatedTypes';
import { usePaginatedDataState } from 'src/pages/HomePage/hooks/usePaginatedDataState';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import {
  FetchPaginatedInvitesQueryResponse,
  PaginatedPendingInvites,
} from 'src/gql/v2/query/FetchPaginatedInvitesQuery';

import InfiniteScroll from 'react-infinite-scroller';
import { HCHeadingFour } from 'src/components/shared/HypercareComponents';
import theme from 'src/assets/styles/theme';
import { END_OF_USER_LIST_MESSAGE } from 'src/constants/strings';
import { UserViewModel } from '../viewModels/UserViewModel';
import { OrganizationalUnitInputType } from '../../../gql/v2/types/input';
import { FetchPaginatedInviteRequestInput } from '../../../data/repository/UserRepository';
import { ApolloQueryResult } from 'apollo-client';

interface Props {
  users: PaginatedInvites[];
  status: PresentedUserLicensingStatus;
  pendingByAdmin: PaginatedInvites[];
  setPendingByAdmin: React.Dispatch<React.SetStateAction<PaginatedInvites[]>>;
  pendingByInvitee: PaginatedInvites[];
  setPendingByInvitee: React.Dispatch<React.SetStateAction<PaginatedInvites[]>>;
  pendingRecords: (
    variables?: OrganizationalUnitInputType & FetchPaginatedInviteRequestInput,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponse>>;
  paginatedPendingUserInvitesQuery: FetchPaginatedInvitesQueryResponse;
  paginatedPendingAdminInvitesQuery: FetchPaginatedInvitesQueryResponse;
  fetchMorePaginatedAdminInviteQuery: any;
  searchPendingAdminApprovalInviteData: PaginatedPendingInvites;
  setSearchPendingAdminApprovalInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvites>>;
  localSearchText: string;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

const PendingAdminUserList: React.FC<Props> = (props) => {
  const {
    users,
    status,
    pendingByAdmin,
    setPendingByAdmin,
    pendingByInvitee,
    setPendingByInvitee,
    pendingRecords,
    fetchMorePaginatedAdminInviteQuery,
    paginatedPendingAdminInvitesQuery,
    localSearchText,
    setSearchPendingAdminApprovalInviteData,
    searchPendingAdminApprovalInviteData,
    isDoneRows,
    setIsDoneRows,
  } = props;

  const classes = useStyles();
  const [selectUser, setSelectedUser] = React.useState(null);
  const [actionStatus, setActiveStatus] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [actionTypeEl, setActionTypeEl] = React.useState<null | HTMLElement>(null);
  const [action, setAction] = React.useState<null | ActionType>(null);
  const [rejectReason, setRejectReason] = React.useState('');
  const [activeAction, setActiveAction] = React.useState(null);

  const { getSearchedPendingInvites } = UserViewModel();

  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  let undo = false;

  const { setSeenContinuationIds, seenContinuationIds } = usePaginatedDataState();

  const getInvitedBy = React.useCallback((user) => {
    const userId = getParsedAuthInfo()?.user?.id;
    let invitedBy = user.invitedBy.id === userId ? 'You' : user.invitedBy.firstname + ' ' + user.invitedBy.lastname;

    return invitedBy;
  }, []);

  const getTimestampString = (invitedDate: string) => {
    const date = new Date(invitedDate);
    const now = moment(new Date()).startOf('day');
    const givenDate = moment(date);
    const days = now.diff(givenDate.startOf('day'), 'days');
    if (days < 1) {
      return 'Today';
    }
    if (days === 1) {
      return 'Yesterday';
    }
    return moment(date).format('DD/MMM/YYYY');
  };

  const handleAction = async (status: string) => {
    undo = false;
    let indexforselectedUser;
    setIsModalOpen(false);
    new Promise(async (resolve, reject) => {
      try {
        indexforselectedUser = pendingByAdmin.findIndex((user) => user.id === selectUser.id);
        pendingByAdmin.splice(indexforselectedUser, 1);
        setPendingByAdmin(pendingByAdmin);

        if (status === APPROVE) {
          pendingByInvitee.unshift({ ...selectUser });
          let updatedInvitee = pendingByInvitee.map((user) => {
            if (user.id === selectUser.id) {
              user.inviteStatus = PENDING_USER;
              user.updatedAt = `${moment()}`;
            }
            return user;
          });
          setPendingByInvitee(updatedInvitee);
        }

        await showToaster(resolve, status);
        setTimeout(async () => {
          resolve(true);
        }, TIMER); //miliseconds
      } catch (e) {
        reject('error');
      }
    })
      .then((result) => {
        if (!undo && result) {
          const variable = {
            inviteId: selectUser.id,
          };
          if (status !== APPROVE) {
            variable['note'] = rejectReason;
          }
          const response = client.mutate({
            mutation: status === APPROVE ? APPROVE_INVITE : DECLINE_INVITE,
            variables: variable,
          });
          if (status === REJECT) {
            pendingRecords();
          }

          AnalyticsManager.applyAnalytics({
            eventName: status === APPROVE ? EVENTS.approveInvite : EVENTS.declineInvite,
            params: {
              invite_id: selectUser.id,
            },
          });

          return response;
        } else {
          let updatedInviteeList = pendingByInvitee.filter(
            (user) => user.id !== selectUser.id && user.inviteStatus === PENDING_USER,
          );
          setPendingByInvitee(updatedInviteeList);

          pendingByAdmin.splice(indexforselectedUser, 0, { ...selectUser });
          let updatedAdminList = pendingByAdmin.map((user) => {
            if (user.id === selectUser.id) {
              user.inviteStatus = PENDING_ADMIN;
              user.updatedAt = selectUser.updatedAt;
            }
            return user;
          });
          setPendingByAdmin(updatedAdminList);

          return;
        }
      })
      .catch((e) => {
        let message = 'Failed to send request, please check your internet connection and try again!';
        if (e?.networkError?.result?.errors[0]) {
          let errorCodeName = e.networkError.result.errors[0].code;
          if (errorCodeName) {
            message = e.networkError.result.errors[0].message;
          }
        }
        toast.error(message);

        let updatedInviteeList = pendingByInvitee.filter(
          (user) => user.id !== selectUser.id && user.inviteStatus === PENDING_USER,
        );
        setPendingByInvitee(updatedInviteeList);

        pendingByAdmin.splice(indexforselectedUser, 0, { ...selectUser });
        let updatedAdminList = pendingByAdmin.map((user) => {
          if (user.id === selectUser.id) {
            user.inviteStatus = PENDING_ADMIN;
            user.updatedAt = selectUser.updatedAt;
          }
          return user;
        });
        setPendingByAdmin(updatedAdminList);
      });
  };

  const showToaster = async (resolve: (value: unknown) => void, status: string) => {
    const name = selectUser.user
      ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
      : selectUser?.address?.address;
    toast(
      <CustomToaster
        logo={status === APPROVE && <CheckSuccess />}
        body={`Invite for “${name}” has been ${status === APPROVE ? 'approved' : 'rejected'}.`}
        isVisibleUndo={true}
        handleUndo={() => {
          undo = true;
          resolve(true);
        }}
      />,
      {
        autoClose: TIMER,
        className: status === APPROVE ? classes.acceptToastr : classes.rejectToastr,
      },
    );

    return true;
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: PaginatedInvites,
    index: string,
    status: string,
  ) => {
    setActionTypeEl(event.currentTarget);
    setSelectedUser(user);
    setActiveAction(index);
    setActiveStatus(status);
  };

  const handleChangeActionType = (type: null | ActionType) => {
    setRejectReason('');
    setActionTypeEl(null);
    setIsModalOpen(true);
    setAction(type);
    setActiveStatus('');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRejectReason(event.target.value);
  };

  const handleCloseMenu = () => {
    setActionTypeEl(null);
    setSelectedUser(null);
    setActiveStatus('');
  };

  const getMoreSearchAdminApprovalInvites = async () => {
    const continuationId = searchPendingAdminApprovalInviteData.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await getSearchedPendingInvites({
        text: localSearchText,
        limit: 30,
        continuationId,
        inviteStatus: PENDING_ADMIN,
      });

      if ('error' in result) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchPendingAdminApprovalInviteData({
          ...searchPendingAdminApprovalInviteData,
          continuationId: result.continuationId,
          invites: [...searchPendingAdminApprovalInviteData.invites, ...result.invites],
        });

        if (result.continuationId === null) {
          setIsDoneRows(true);
        }
      }
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const getMorePaginatedPendingAdminApprovalInvites = async () => {
    const continuationId =
      paginatedPendingAdminInvitesQuery.adminQuery.organizationalUnit.paginatedPendingInvites.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      await fetchMorePaginatedAdminInviteQuery({
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          continuationId,
          direction: 'next',
          inviteStatus: PENDING_ADMIN,
          limit: 30,
        },
        updateQuery: (
          previousResult: FetchPaginatedInvitesQueryResponse,
          { fetchMoreResult }: { fetchMoreResult: FetchPaginatedInvitesQueryResponse },
        ) => {
          const newData = fetchMoreResult;

          newData.adminQuery.organizationalUnit.paginatedPendingInvites.invites = [
            ...previousResult.adminQuery.organizationalUnit.paginatedPendingInvites.invites,
            ...newData.adminQuery.organizationalUnit.paginatedPendingInvites.invites,
          ];

          const newFetchedUsers = fetchMoreResult.adminQuery.organizationalUnit.paginatedPendingInvites;

          if (newFetchedUsers.continuationId === null) {
            setIsDoneRows(true);
          }

          return newData;
        },
      });
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  return (
    <UserListContainer paginatedContactsFeatureFlag={paginatedContactsFeatureFlag}>
      <div style={{ maxHeight: '325px', overflow: 'auto', minHeight: '100px' }} id="scrollableDiv">
        <InfiniteScroll
          loadMore={
            localSearchText.length >= 1
              ? getMoreSearchAdminApprovalInvites
              : getMorePaginatedPendingAdminApprovalInvites
          }
          hasMore={!isDoneRows}
          initialLoad={false}
          useWindow={false}
        >
          {users.map((user: PaginatedInvites, index: number) => {
            const invitedBy = getInvitedBy(user);
            const uniqueIndex = `${index}-${user.id}`;
            return (
              <div
                className={`usersBlock ${classes.userBlockSection}`}
                key={`user-${index}`}
                data-testid={`user-${user.id}`}
              >
                <div className="usersBlock__avatarIcon">
                  <UserAvatar profileSize="default" user={user} />
                </div>

                <div className={`usersBlock__userNames ${classes.userNameSection}`}>
                  <span className={classes.userFullName}>{getUserFullName(user)}</span>
                  {user.username && <span className="usersBlock__userNames--username">@{user.username}</span>}
                </div>

                <div className="usersBlock__userTitle">
                  Invited by &nbsp;
                  <span
                    className={classes.invitedBy}
                    onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.invitedBy.id}`)}
                  >
                    {invitedBy}
                  </span>
                  , {moment(user.createdAt).format('DD/MMM/YYYY')}
                </div>
                {user.resolvedBy && user.resolvedBy.id !== user.invitedBy.id && (
                  <div className={`usersBlock__userTags ${classes.approvedBy}`}>
                    Approved by &nbsp;
                    <span
                      className={classes.invitedBy}
                      onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.resolvedBy.id}`)}
                    >
                      {invitedBy}
                    </span>
                    , <span>{getTimestampString(user.createdAt)}</span>
                  </div>
                )}

                <div className={`usersBlock__userStatus ${classes.status}`}>
                  <StyledStatus status={status} data-testid="status">
                    {'Pending admin approval'}
                  </StyledStatus>
                </div>

                <div className={classes.actionButton}>
                  <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                    <StyledButton
                      id={`action-button-${user.id}`}
                      aria-haspopup="true"
                      className={classes.adminActionButtonBorder}
                      onClick={(e) => handleActionClick(e, user, uniqueIndex, status)}
                      endIcon={actionTypeEl && uniqueIndex === activeAction ? <ExpandLess /> : <ExpandMore />}
                    >
                      Action
                    </StyledButton>
                  </Box>
                </div>
              </div>
            );
          })}
          {isDoneRows && (
            <div style={{ height: '25px', margin: '5px' }}>
              <HCHeadingFour fontWeight={400} color={theme.mainFontColor}>
                {END_OF_USER_LIST_MESSAGE}
              </HCHeadingFour>
            </div>
          )}
        </InfiniteScroll>
      </div>
      <StyledMenu
        id={`action-menu-${selectUser?.id}`}
        keepMounted
        anchorEl={actionTypeEl}
        open={Boolean(actionTypeEl)}
        onClose={() => handleCloseMenu()}
      >
        <div key={activeAction}>
          <StyledMenuItem onClick={() => handleChangeActionType(APPROVE)}>Approve</StyledMenuItem>
          <StyledMenuItem onClick={() => handleChangeActionType(REJECT)} className={classes.reject}>
            Reject
          </StyledMenuItem>
        </div>
      </StyledMenu>
      {isModalOpen && (
        <PendingInviteModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          action={action}
          selectUser={selectUser}
          handleAction={handleAction}
          rejectReason={rejectReason}
          handleChange={handleChange}
        />
      )}
    </UserListContainer>
  );
};

export default PendingAdminUserList;
