import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

interface IButtonGroup {
  onChange: (value: any) => void;
  active: string;
  values: string[];
  enabledValues: string[];
}

const ButtonGroupComponent: React.FC<IButtonGroup> = (props) => {
  const { onChange, active, values } = props;
  return (
    <ButtonGroup color="default" variant="text" disableElevation>
      {values.map((element) => (
        <Button
          onClick={() => onChange(element)}
          key={element}
          disableRipple
          disableTouchRipple
          disabled={props.enabledValues.indexOf(element) === -1}
          style={{
            borderRight: 0,
            textTransform: 'capitalize',
            borderBottomWidth: 2,
            borderRadius: 0,
            borderBottomStyle: 'solid',
            borderBottomColor: active === element ? '#80C2CC' : 'transparent',
          }}
        >
          {element}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonGroupComponent;
