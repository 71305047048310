import React from 'react';
import { FormikProps, Formik } from 'formik';
import * as Yup from 'yup';
import 'src/assets/styles/FormStyles.scss';
import { HandleContinueLoginAddressParams } from 'src/types';
import { HYPERCARE_USERNAME } from 'src/constants/storageKeys';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ADDRESS, ADDRESS_IS_REQUIRED, NEXT, VERIFYING_AVAILABILITY } from 'src/constants/strings';
import NewLoginFormHeader from 'src/pages/LoginPage/components/NewLoginFormHeader';
import HypercareLoginAddressViewModel from 'src/pages/LoginPage/HypercareLoginAddress/HypercareLoginAddressViewModel';
import {
  InputsWrapper,
  LoginFormWrapper,
  ProgressContainer,
  StyledTextField,
} from 'src/pages/LoginPage/components/styles';
import { LoginFormFooter } from 'src/pages/LoginPage/components/LoginFormFooter';

const loginUserValidationSchema = Yup.object().shape({
  address: Yup.string().required(ADDRESS_IS_REQUIRED),
});

interface HypercareLoginAddressViewProps {
  handleNextButtonPressed: (values: HandleContinueLoginAddressParams) => void;
}

const HypercareLoginAddressView = ({ handleNextButtonPressed }: HypercareLoginAddressViewProps) => {
  const { completeAddressStep } = HypercareLoginAddressViewModel({ handleNextButtonPressed });
  const onClickContact = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.loginContactSupport,
    });
  };

  return (
    <Formik
      initialValues={{
        address: localStorage.getItem(HYPERCARE_USERNAME) ? localStorage.getItem(HYPERCARE_USERNAME) : '',
      }}
      validationSchema={loginUserValidationSchema}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.loginButtonPressed,
          params: {
            username: values.address,
          },
        });
        const result = await completeAddressStep(values);

        if (result?.error) {
          setFieldError('address', result.error);
          setSubmitting(false);
        }
      }}
      render={(props: FormikProps<{ address: string }>) => {
        const { values, errors, touched, isSubmitting, handleChange, handleSubmit, handleBlur } = props as FormikProps<{
          address: string;
        }>;
        return (
          <form onSubmit={handleSubmit} className="modalForm loginForm">
            <LoginFormWrapper loading={isSubmitting ? true : undefined}>
              {isSubmitting && (
                <ProgressContainer>
                  <LinearProgress color="secondary" />
                </ProgressContainer>
              )}
              <NewLoginFormHeader isAccountLocked={null} />
              <InputsWrapper>
                <StyledTextField
                  onKeyUp={() => localStorage.setItem(HYPERCARE_USERNAME, values.address)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  autoFocus={!Boolean(localStorage.getItem(HYPERCARE_USERNAME))}
                  id="address"
                  data-testid="addressInput"
                  fullWidth
                  variant="outlined"
                  placeholder="you@acme-hospital.com"
                  label="Email or username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="address"
                  value={values.address}
                  helperText={touched.address && errors.address}
                  error={touched.address && Boolean(errors.address)}
                />
              </InputsWrapper>
              <LoginFormFooter
                submitting={isSubmitting}
                values={values.address}
                onClick={onClickContact}
                buttonText={NEXT}
                submitButtonText={VERIFYING_AVAILABILITY}
                page={ADDRESS}
              />
            </LoginFormWrapper>
          </form>
        );
      }}
    />
  );
};
export default HypercareLoginAddressView;
