import React from 'react';
import styled from 'styled-components';
import { TextField, colors } from '@material-ui/core';
import theme from '../../../../assets/styles/theme';

type FormFieldInputTextProps = {
  value: string;
  placeholder: string;
  isRequired: boolean;
  disabled: boolean;
  mode: 'view' | 'edit';
  error?: string;
  fieldId: string;
  inputType: 'text' | 'email' | 'number' | 'url';
  onBlur: () => void;
  onChange: (value: string) => void;
};

const FormFieldInputTextContainer = styled.div`
  flex: 1;
  width: 100%;
  a {
    &:hover {
      text-decoration: underline;
    }
    text-decoration: none;
    color: ${theme.mainTealColor};
    cursor: pointer;
  }
`;

const StyledTextField = styled(TextField)<{ showDisabledInput: boolean }>`
  .Mui-disabled {
    color: ${(props) => (props.showDisabledInput ? 'black !important' : '')};
  }
`;

export const FormFieldInputText = (props: FormFieldInputTextProps) => {
  if (props.mode === 'view') {
    if (props.inputType === 'email') {
      return (
        <FormFieldInputTextContainer>
          <a target="_blank" href={`mailto:${props.value}`}>
            {props.value}
          </a>
        </FormFieldInputTextContainer>
      );
    }
  }

  return (
    <FormFieldInputTextContainer>
      <StyledTextField
        showDisabledInput={props.disabled && props.mode === 'view'}
        fullWidth
        InputProps={{
          type: props.inputType,
          disableUnderline: !props.error,
        }}
        disabled={props.disabled}
        margin="none"
        id={props.fieldId}
        name=""
        placeholder={props.placeholder}
        value={props.value}
        onBlur={props.onBlur}
        onChange={(e) => props.onChange(e.target.value)}
        helperText={props.error}
        error={!!props.error}
      />
    </FormFieldInputTextContainer>
  );
};
