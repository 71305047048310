import gql from 'graphql-tag';
import userInviteFragment from 'src/gql/fragment/InviteFragement';

export const APPROVE_INVITE = gql`
  mutation ApproveInvite($inviteId: Int!) {
    admin {
      approveInvite(inviteId: $inviteId) {
        ...UserInviteFragment
      }
    }
  }

  ${userInviteFragment}
`;
