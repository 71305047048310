import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { DropItemType, DropSourceItem, EscalationPolicy } from 'src/types/Escalation';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import { EscalationLevelPolicyActionPayload } from 'src/types';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AppTheme from 'src/assets/styles/theme';
import EditIcon from 'src/assets/svgs/EditIcon';
import TrashIcon from 'src/assets/svgs/TrashIcon';
import CheckIcon from 'src/assets/svgs/CheckIcon';
import InputBase from '@material-ui/core/InputBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import EditPolicyWarningModal from '../popup-modal/EditPolicyWarningModal';
import {
  BaseEscalationPolicyWrapper,
  EscalationName,
  EscalationDetail,
} from 'src/pages/EscalationPage/escalation-layout/SharedEscalationStyles';
import { CREATE } from 'src/constants/escalation';

const BasePolicyControlBtnHolder = styled.div`
  display: none;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.6;
  z-index: 2;
  transition: opacity 1s;
  padding: 4px;
  top: 0;
  button {
    padding: 8px;
  }
  svg {
    width: 22px;
    height: 22px;
  }
`;

const DeletePolicyButtonHolder = styled(BasePolicyControlBtnHolder)`
  background-color: lightgrey;
  position: absolute;
  left: 0;
  transform: translate(-40%, -40%);
  &:hover {
    opacity: 1;
    background-color: red;
    svg {
      stroke: white;
    }
  }
`;

const EditPolicyButtonHolder = styled(BasePolicyControlBtnHolder)`
  background-color: lightgrey;
  position: absolute;
  right: 0;
  transform: translate(40%, -40%);
  &:hover {
    opacity: 1;
    background-color: ${AppTheme.mainTealColor};
    svg {
      stroke: white;
    }
  }
`;

const SavePolicyChangeButtonHolder = styled(BasePolicyControlBtnHolder)`
  display: block;
  opacity: 1;
  background-color: ${AppTheme.mainTealColor};
  position: absolute;
  right: 0;
  transform: translate(40%, -40%);
  svg {
    stroke: white;
  }
`;

const AddIconHolder = styled.div`
  svg {
    width: 52px;
    height: 52px;
    color: lightgrey;
  }
`;

const AddTextHolder = styled.div`
  margin-top: 8px;
  line-height: 1.5em;
  color: grey;
  font-size: 16px;
  font-weight: 600;
`;

interface StyledDropzoneHolderProps {
  isOver: boolean;
  hasPolicy: boolean;
  isAllowedType: boolean;
  isEditState: boolean;
  isDragging: boolean;
}

const DropzoneHolder = styled(BaseEscalationPolicyWrapper)`
  height: 240px !important;
  border: ${(props: StyledDropzoneHolderProps) =>
    props.isOver || props.hasPolicy ? '1px solid lightgrey' : '1px dashed grey'};
  background-color: ${(props: StyledDropzoneHolderProps) => (props.isOver ? 'whitesmoke' : 'inherit')};
  box-shadow: ${(props: StyledDropzoneHolderProps) =>
    props.isOver ? '0 4px 4px 0 rgba(219, 219, 219, 0.25)' : 'unset'};
  border-color: ${(props: StyledDropzoneHolderProps) =>
    props.isEditState || (props.isAllowedType && props.isDragging) ? AppTheme.mainTealColor : 'auto'};
  border-width: ${(props: StyledDropzoneHolderProps) =>
    props.isEditState || (props.isAllowedType && props.isDragging) ? '2px' : 'auto'};
  border-style: ${(props: StyledDropzoneHolderProps) => (props.isAllowedType && props.isDragging ? 'solid' : 'auto')};
  filter: ${(props: StyledDropzoneHolderProps) => (!props.isAllowedType ? 'blur(2px)' : 'unset')};
  &:hover {
    ${BasePolicyControlBtnHolder} {
      display: block;
    }
  }
`;

const EscalationEditableWrapper = styled.div`
  line-height: 2em;
  font-weight: 800;
  font-size: 18px;
  input {
    padding-bottom: 2px;
    margin: 0 8px;
    border-bottom: 1px solid black;
    &.escalationPolicyEditLength {
      width: 3em;
    }
    &.escalationPolicyEditAttempts {
      width: 2.2em;
    }
  }
`;

interface Props {
  levelIndex: number;
  escalationPolicy: EscalationPolicy;
}

const EscalationPolicyDropZone = ({ levelIndex, escalationPolicy }: Props) => {
  const ALLOWED_TYPE: DropItemType = 'policy';
  const [isEditState, setEditState] = React.useState(false);
  // note length in clone will be in minute
  const [escalationPolicyEditClone, setEscalationPolicyEditClone] = React.useState<EscalationPolicy>(null);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  // move up state if the warning shown should only once in ladder than each policy
  // note this only memorizes per index, warning will be shown up again if newly added dep policy trying to be edited
  const isWarningShownRef = React.useRef(false);

  React.useEffect(() => {
    setEscalationPolicyEditClone(
      escalationPolicy
        ? {
            ...escalationPolicy,
            repeatLength: moment.duration(escalationPolicy.repeatLength).asMinutes(),
          }
        : null,
    );
  }, [escalationPolicy]);

  const dispatch = useDispatch();
  const setEscalationPolicy = (payload: EscalationLevelPolicyActionPayload) =>
    dispatch(allActions.escalationAction.setEscalationLevelPolicy(payload));

  const deleteEscalationPolicy = (payload: EscalationLevelPolicyActionPayload) =>
    dispatch(allActions.escalationAction.deleteEscalationLevelPolicy(payload));

  const [{ isDragging, isAllowedType, isOver }, drop] = useDrop({
    accept: ALLOWED_TYPE,
    drop(item: DropSourceItem) {
      setEscalationPolicy({
        levelIndex,
        policyData: item.data as EscalationPolicy,
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDragging: !!monitor.getItem(),
      isAllowedType: monitor.getItemType() === null || (monitor.getItemType() as DropItemType) === ALLOWED_TYPE,
    }),
  });

  const onTryEditPolicy = () => {
    if (!isWarningShownRef.current) {
      setShowWarningModal(true);
    } else {
      setEditState(true);
      isWarningShownRef.current = true;
    }
  };

  const handleWarningProceed = () => {
    isWarningShownRef.current = true;
    setShowWarningModal(false);
    // TODO: avoid conflicting handleClickAway
    setTimeout(() => {
      setEditState(true);
    }, 100);
  };

  // TODO: backend CURD on policy
  const handleChangeEscalationPolicy = () => {
    const updatedEscalationPolicy = {
      ...escalationPolicyEditClone,
      repeatAttempts: escalationPolicyEditClone.repeatAttempts || escalationPolicy.repeatAttempts,
      repeatLength: escalationPolicyEditClone.repeatLength
        ? escalationPolicyEditClone.repeatLength * 60000
        : escalationPolicy.repeatLength,
    };
    setEscalationPolicy({
      levelIndex,
      policyData: {
        ...updatedEscalationPolicy,
        id: CREATE,
      },
    });
    setEditState(false);
  };

  const handleDeleteEscalationPolicy = () => {
    deleteEscalationPolicy({
      levelIndex,
      policyData: escalationPolicy,
    });
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 3) return;
    let targetValue = e.target.value.replace(/^0+/, '');
    setEscalationPolicyEditClone({
      ...escalationPolicyEditClone,
      repeatLength: targetValue ? parseInt(targetValue) : undefined,
    });
  };

  const handleChangeAttempts = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2) return;
    let targetValue = e.target.value.replace(/^0+/, '');
    setEscalationPolicyEditClone({
      ...escalationPolicyEditClone,
      repeatAttempts: targetValue ? parseInt(targetValue) : undefined,
    });
  };

  const handleClickAway = () => {
    if (!escalationPolicy) return;
    if (isEditState) {
      setEscalationPolicyEditClone({
        ...escalationPolicy,
        repeatLength: moment.duration(escalationPolicy.repeatLength).asMinutes(),
      });
      setEditState(false);
    }
  };

  const preventNaNInput = (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
  };

  const renderEmptyPlaceHolder = () => (
    <div>
      <AddIconHolder>
        <AddIcon />
      </AddIconHolder>
      <AddTextHolder>Drag a policy here to determine how a level can be contacted</AddTextHolder>
    </div>
  );

  const renderEscalationPolicy = React.useCallback(() => {
    const { name, repeatAttempts, repeatLength } = escalationPolicyEditClone;
    return (
      <React.Fragment>
        {isEditState ? (
          <EscalationEditableWrapper>
            <span>Try each channel every </span>
            <InputBase
              type="number"
              onKeyDown={preventNaNInput}
              inputProps={{
                className: 'escalationPolicyEditLength',
                min: '1',
              }}
              autoFocus
              onChange={handleChangeNumber}
              value={repeatLength || ''}
            />
            <span>minutes for</span>
            <InputBase
              type="number"
              onKeyDown={preventNaNInput}
              inputProps={{
                className: 'escalationPolicyEditAttempts',
                min: '1',
              }}
              onChange={handleChangeAttempts}
              value={repeatAttempts || ''}
            />
            <span>attempts</span>
            <SavePolicyChangeButtonHolder>
              <IconButton aria-label="save" onClick={handleChangeEscalationPolicy}>
                <CheckIcon />
              </IconButton>
            </SavePolicyChangeButtonHolder>
          </EscalationEditableWrapper>
        ) : (
          <React.Fragment>
            <EscalationName>{name}</EscalationName>
            <EscalationDetail>
              Try each channel every {repeatLength} minutes for {repeatAttempts} attempts
            </EscalationDetail>

            <DeletePolicyButtonHolder>
              <IconButton aria-label="delete" onClick={handleDeleteEscalationPolicy}>
                <TrashIcon />
              </IconButton>
            </DeletePolicyButtonHolder>
            <EditPolicyButtonHolder>
              <IconButton aria-label="edit" onClick={onTryEditPolicy}>
                <EditIcon />
              </IconButton>
            </EditPolicyButtonHolder>
          </React.Fragment>
        )}
      </React.Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escalationPolicyEditClone, isEditState]);

  const hasPolicy = !!escalationPolicyEditClone;

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Tooltip
          title={hasPolicy ? 'Replace Policy' : 'Drop Here'}
          PopperProps={{
            disablePortal: true,
          }}
          placement="right"
          open={isOver || (isAllowedType && isDragging)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
        >
          <DropzoneHolder
            ref={drop}
            isOver={isOver}
            hasPolicy={hasPolicy}
            isEditState={isEditState}
            isAllowedType={isAllowedType}
            isDragging={isDragging}
          >
            {hasPolicy ? renderEscalationPolicy() : renderEmptyPlaceHolder()}
          </DropzoneHolder>
        </Tooltip>
      </ClickAwayListener>

      {showWarningModal && (
        <EditPolicyWarningModal
          showModal={showWarningModal}
          handleProceed={handleWarningProceed}
          handleCloseModal={() => setShowWarningModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default EscalationPolicyDropZone;
