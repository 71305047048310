import React from 'react';
import Chart from 'src/components/analytics/Chart';
import { Bar, BarChart, CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { CircularProgress } from '@material-ui/core';

interface IProps {
  loading: boolean;
  data: any[];
  height: number | string;
  width: number | string;
  xAxis: string;
  tickFormatter?: (value: any) => void;
  xTickFormatter?: (value: any) => any;
  customTooltip?: any;
  stacked?: boolean;
  colors: string[];
  yAxis: string[];
}

const BarChartComponent: React.FC<IProps> = (props) => {
  const { height, width, data, xAxis, tickFormatter, xTickFormatter, customTooltip, stacked, colors, yAxis } = props;

  return (
    <React.Fragment>
      <div
        style={{
          display: props.loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: '#ffffffa0',
          zIndex: 1000,
        }}
      >
        <CircularProgress />
      </div>
      <Chart height={height} width={width}>
        <BarChart stackOffset="sign" data={data}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey={xAxis}
            tickFormatter={xTickFormatter}
            minTickGap={data && data.length ? data.length / 2 : 1}
            tick={{ fontSize: 12 }}
          />
          <YAxis tickFormatter={tickFormatter} tick={{ fontSize: 12 }} />
          {Boolean(customTooltip) && <Tooltip cursor={{ fill: 'transparent' }} content={customTooltip} />}
          {!Boolean(customTooltip) && <Tooltip cursor={{ fill: 'transparent' }} />}
          <ReferenceLine y={0} strokeWidth={2} stroke="#b3b3b3" />
          {!Boolean(stacked) &&
            yAxis.map((k, i) => <Bar dataKey={k} fill={colors[i] || '#747BF3'} barSize={30} key={i} />)}
          {Boolean(stacked) &&
            yAxis.map((k, i) => <Bar dataKey={k} fill={colors[i] || '#747BF3'} stackId="stack" barSize={30} key={i} />)}
        </BarChart>
      </Chart>
    </React.Fragment>
  );
};

BarChartComponent.defaultProps = {
  height: 100,
  width: 100,
  tickFormatter: (value: any) => value,
};

export default BarChartComponent;
