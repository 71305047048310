import gql from 'graphql-tag';

export const REMOTE_LOGOUT = gql`
  mutation RemoteLogout($id: ID!) {
    admin {
      user(id: $id) {
        remoteLogout
      }
    }
  }
`;

export const DEVICE_LOGOUT = gql`
  mutation LogoutUserDevice($organizationId: Int!, $userId: ID!, $deviceId: ID!) {
    adminMutation {
      ... on AdminMutation {
        organization(id: $organizationId) {
          user(id: $userId) {
            device(id: $deviceId) {
              logout {
                ... on Node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LOGOUT_ALL_DEVICES = gql`
  mutation LogoutUserDevice($organizationId: Int!, $userId: ID!) {
    adminMutation {
      ... on AdminMutation {
        organization(id: $organizationId) {
          user(id: $userId) {
            remoteLogoutAllDevices {
              id
              username
            }
          }
        }
      }
    }
  }
`;
