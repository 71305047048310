import gql from 'graphql-tag';
import { UpdateAddressPreferenceResultFragment } from 'src/gql/v2/fragment/AddressFieldFragment';

export const UPDATE_ADDRESS_PREFERENCE = gql`
  mutation UpdateUserAddressPreference(
    $userId: ID!
    $address: [String]!
    $organizationalUnit: OrganizationalUnitInput!
  ) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }

      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationMutation {
            __typename
            member(id: $userId) {
              updateAddressPreference(address: $address) {
                ...UpdateAddressPreferenceResultFragment
                __typename
              }
            }
          }
          ... on AdminSiteMutation {
            __typename
            member(id: $userId) {
              updateAddressPreference(address: $address) {
                ...UpdateAddressPreferenceResultFragment
                __typename
              }
            }
          }
          ... on AdminDepartmentMutation {
            __typename
            member(id: $userId) {
              updateAddressPreference(address: $address) {
                ...UpdateAddressPreferenceResultFragment
                __typename
              }
            }
          }
        }
      }
    }
  }
  ${UpdateAddressPreferenceResultFragment}
`;
