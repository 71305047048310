import * as React from 'react';

export default (props) => (
  <svg width="16" height="16" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7218 5.59688C12.2406 2.47656 10.0015 0.90625 6.99997 0.90625C3.99684 0.90625 1.75934 2.47656 0.278093 5.59844C0.21868 5.72425 0.187866 5.86165 0.187866 6.00078C0.187866 6.13991 0.21868 6.27732 0.278093 6.40312C1.75934 9.52344 3.99841 11.0938 6.99997 11.0938C10.0031 11.0938 12.2406 9.52344 13.7218 6.40156C13.8422 6.14844 13.8422 5.85469 13.7218 5.59688V5.59688ZM6.99997 9.96875C4.47966 9.96875 2.63434 8.69062 1.33278 6C2.63434 3.30938 4.47966 2.03125 6.99997 2.03125C9.52028 2.03125 11.3656 3.30938 12.6672 6C11.3672 8.69062 9.52184 9.96875 6.99997 9.96875ZM6.93747 3.25C5.41872 3.25 4.18747 4.48125 4.18747 6C4.18747 7.51875 5.41872 8.75 6.93747 8.75C8.45622 8.75 9.68747 7.51875 9.68747 6C9.68747 4.48125 8.45622 3.25 6.93747 3.25ZM6.93747 7.75C5.97028 7.75 5.18747 6.96719 5.18747 6C5.18747 5.03281 5.97028 4.25 6.93747 4.25C7.90466 4.25 8.68747 5.03281 8.68747 6C8.68747 6.96719 7.90466 7.75 6.93747 7.75Z"
      fill="#4A4A4A"
    />
  </svg>
);
