import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Assignee, MonthlyCalendar, ScheduleGapInfo } from 'src/types';
import { REDUCER_DATE_FORMAT } from 'src/constants/scheduler';

const moment = extendMoment(Moment);

function sortByStartTime(shiftA: Assignee, shiftB: Assignee) {
  if (shiftA.startTime.isBefore(shiftB.startTime)) return -1;
  if (shiftA.startTime.isAfter(shiftB.startTime)) return 1;
  return 0;
}

function isEndTimeMinutesBeforeStartTime(startTime: string, endTime: string) {
  const roleStartTime = moment().hours(parseInt(startTime)).hours();
  const roleEndTime = moment().hours(parseInt(endTime)).hours();
  return roleEndTime <= roleStartTime;
}

function identifyGapsRangesWithOvernight(monthlyCalendarSchedule: MonthlyCalendar) {
  let isGapOccurredInRequiredRole: boolean = false;

  Object.keys(monthlyCalendarSchedule).forEach((key) => {
    let calendarShifts = monthlyCalendarSchedule[key];
    calendarShifts.forEach((monthlyCalendarShift, index) => {
      let { role, assignee } = monthlyCalendarShift;
      // sort startTime is a must for both ui display and data traversal
      assignee = assignee.sort(sortByStartTime);
      // assigned to utc string to current iteration of calendar day (key)
      let roleStartTime = moment(moment(`${key} ${role?.startTime?.format('HH:mm')}`).toISOString());
      let roleEndTime = moment(moment(`${key} ${role?.endTime?.format('HH:mm')}`).toISOString());

      const gaps: ScheduleGapInfo[] = [];
      const isCalendarDateWeekends = roleStartTime.clone().weekday() === 6 || roleEndTime.clone().weekday() === 0;

      const shouldRender =
        (!isCalendarDateWeekends && role.repeatRule === 'weekdays') ||
        (isCalendarDateWeekends && role.repeatRule === 'weekends') ||
        role.repeatRule === 'daily';

      const isRequired = role.required && shouldRender;

      // since schedule vm is splitted, cross-day shifts will be divided at xx-23:59 (.endOf('day')) or 00:00-xx
      // there can be two role ref in one day e.g. 8:00 - 3:00 is 00:00 - 03:00 and 8:00 - 23:59
      const isCrossDay = isEndTimeMinutesBeforeStartTime(
        role?.startTime?.format('HH:mm'),
        role?.endTime?.format('HH:mm'),
      );
      let roleRangeStart1: moment.Moment, roleRangeEnd1: moment.Moment;
      // by nature of split, start2 end2 will always be later/greater than start1 end1
      if (isCrossDay) {
        roleEndTime = moment(roleEndTime.add(1, 'day').toISOString());

        let nextDayLoop = moment(moment(`${key} 00:00`).toISOString()).add(1, 'days');
        if (nextDayLoop) {
          let nextShifts = monthlyCalendarSchedule[nextDayLoop.format(REDUCER_DATE_FORMAT)];
          if (
            nextShifts &&
            nextShifts.length > 0 &&
            nextShifts[index] &&
            nextShifts[index]?.assignee &&
            nextShifts[index]?.assignee.length > 0
          ) {
            const nextAssignee = nextShifts[index].assignee;
            assignee = assignee.concat(nextAssignee);
            assignee.sort(sortByStartTime);
          }
        }
      }
      roleRangeStart1 = roleStartTime.clone();
      roleRangeEnd1 = roleEndTime.clone();

      // when no shifts, all slots are gap
      if (assignee.length === 0) {
        gaps.push({
          startTime: roleRangeStart1,
          endTime: roleRangeEnd1,
          shouldRender,
          isRequired,
          initialAssignee: {
            shiftId: null,
            startTime: roleRangeStart1,
            endTime: roleRangeEnd1,
            userId: null,
            userFullName: null,
          },
        });
      } else {
        // else iterate through
        let gapRange = moment.range(roleRangeStart1, roleRangeEnd1); // 19 -19

        assignee.forEach((shift, i) => {
          if (moment(shift.startTime).isBetween(roleStartTime, roleEndTime, undefined, '[)')) {
            if (shift.startTime > gapRange.start) {
              gaps.push({
                startTime: gapRange.start.clone(),
                endTime: shift.startTime.clone(),
                shouldRender,
                isRequired,
                initialAssignee: {
                  shiftId: null,
                  startTime: gapRange.start.clone(),
                  endTime: shift.startTime.clone(),
                  userId: null,
                  userFullName: null,
                },
              });
            }
            if (moment(shift.startTime).diff(gapRange.start, 'minutes') === 0) {
              gapRange = moment.range(shift.endTime, gapRange.end);
            } else {
              gapRange = moment.range(shift.endTime, gapRange.end);
            }
          }
        });
        if (moment(gapRange.start).diff(gapRange.end, 'minutes') !== 0) {
          if (moment(gapRange.start).isBetween(roleStartTime, roleEndTime, undefined, '[)')) {
            gaps.push({
              startTime: gapRange.start,
              endTime: gapRange.end,
              shouldRender,
              isRequired,
              initialAssignee: {
                shiftId: null,
                startTime: gapRange.start,
                endTime: gapRange.end,
                userId: null,
                userFullName: null,
              },
            });
          }
        }
      } // end of assignee loop
      const isRequiredOccurred = gaps.find((gap) => gap.isRequired);
      if (isRequiredOccurred) isGapOccurredInRequiredRole = true;

      gaps.map((gap) => {
        if (monthlyCalendarSchedule[gap.startTime.format(REDUCER_DATE_FORMAT)]) {
          monthlyCalendarSchedule[gap.startTime.format(REDUCER_DATE_FORMAT)][index]?.scheduleGap.push(gap);
        }
      });
    }); // end of calendar loop
  });

  return isGapOccurredInRequiredRole;
}

export default identifyGapsRangesWithOvernight;
