import gql from 'graphql-tag';
import {
  FullOrganizationMemberDirSyncFragment,
  FullOrganizationMemberFragment,
} from 'src/gql/v2/fragment/FullOrganizationMemberDirSyncFragment';

export const FETCH_MEMBERS = gql`
  query FetchMembers($organizationalUnit: OrganizationalUnitInput!) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            members {
              ...FullOrganizationMemberFragment
            }
          }
          ... on AdminSiteQuery {
            members {
              ...FullOrganizationMemberFragment
            }
          }
          ... on AdminDepartmentQuery {
            members {
              ...FullOrganizationMemberFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationMemberFragment}
`;

export const FETCH_DIR_SYNC_MEMBERS = gql`
  query FetchMembers($organizationalUnit: OrganizationalUnitInput!) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            members {
              ...FullOrganizationMemberDirSyncFragment
            }
          }
          ... on AdminSiteQuery {
            members {
              ...FullOrganizationMemberDirSyncFragment
            }
          }
          ... on AdminDepartmentQuery {
            members {
              ...FullOrganizationMemberDirSyncFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationMemberDirSyncFragment}
`;
