import React from 'react';

import CustomSearchField from '../../../components/shared/CustomSearchField';
import { USERS_PAGE_SEARCH_FIELD_PLACEHOLDER } from '../../../constants/strings';

type Props = {
  localSearchText: any;
  handleSearch: any;
  clearSearchText: any;
};
export const AdminSearchBar = ({ localSearchText, handleSearch, clearSearchText }: Props) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  return (
    <CustomSearchField
      clearSearchText={clearSearchText}
      handleKeyDown={handleKeyDown}
      handleChange={handleSearch}
      localSearchText={localSearchText}
      placeHolder={USERS_PAGE_SEARCH_FIELD_PLACEHOLDER}
      inputWidth={'360px'}
    />
  );
};
