// auth
export const INVALID_TOKEN_UNDERSCORE = 'invalid_token';
export const ACCESS_TOKEN_UNDERSCORE = 'access_token';
export const UNAUTH_REQUEST_UNDERSCORE = 'unauthorized_request';
export const NO_RECORD = 'RecordNotFoundError';
export const INVALID_ORG_SCOPE = 'InvalidOrganizationScope';
export const INVALID_ACCESS_TOKEN = 'InvalidAccessToken';

// login
export const USER_NOT_FOUND = 'UserNotFound';
export const LOGIN_ERROR_CODE_INVALID_PASSWORD = 'InvalidPassword';
export const LOGIN_ERROR_MESSAGE_INVALID_PASSWORD = 'Invalid password. Please try again.';
export const LOGIN_ERROR_CODE_INVALID_USER = 'InvalidUsername';
export const LOGIN_ERROR_MESSAGE_INVALID_USER = 'User not found. Please try again.';
export const LOGIN_ERROR_CODE_ACCOUNT_LOCKED = 'AccountLocked';
export const LOGIN_ERROR_MESSAGE_ACCOUNT_LOCKED =
  'Account has been locked due to too many failed login attempts. Please contact Hypercare Support at +1 (877) 379-3533.';
export const LOGIN_ERROR_CODE_INVALID_CHALLENGE = 'InvalidChallenge';
export const LOGIN_ERROR_MESSAGE_INVALID_CHALLENGE = 'Token expired, please try again.';
export const UNKNOWN_ERROR_STRING = 'Unknown error occurred';
export const LOCKED_ACCOUNT = 'LockedAccount';

export const DEFAULT_ERROR = 'DefaultError';

export const NETWORK_ERROR = 'Network error, please try again';

// schedule
export const CANT_MUTATE_PAST_SHIFT = 'CantMutatePastShift';
export const CANT_MUTATE_ARCHIVED_SHIFT = 'CantMutateArchivedShift';
export const CANT_MUTATE_PAST_ROLE = 'CantMutatePastRole';
export const CANT_MUTATE_ARCHIVED_ROLE = 'CantMutateArchivedRole';

// Invite users
export const NO_SUCH_INVITE_FOR_USER = 'NoSuchInviteForUser';

//Hidden notes

export const EXCEED_MAX_NOTE_COUNT_ERROR = 'ExceedMaxNoteCountError';
export const EXCEED_MAX_NOTE_COUNT_ERROR_MESSAGE =
  'The maximum limit of notes (25) has been reached. To create a new one, kindly delete an existing one,';

export const EXCEED_MAX_WORD_COUNTER_ERROR = 'ExceedMaxWordCountError';
export const EXCEED_MAX_WORD_COUNTER_ERROR_MESSAGE = 'Note description exceeds the max word count';

export const ROLE_NOTES_CREATING_DEFAULT_ERROR_MESSAGE =
  'An Error has occured while creating role note, please refresh page and try again';

export const NOTE_DELETED_ERROR = 'NoteDeletedError';
export const NOTE_DELETED_ERROR_MESSAGE =
  "The note you're looking for has already been deleted, please refresh page to see the updated notes";

export const ACCESS_FORBIDDEN_ERROR = 'AccessForbiddenError';

export const ACCESS_FORBIDDEN_ERROR_MESSAGE = 'You do not have access to delete this Note';

export const NOTE_NOTE_FOUND_ERROR = 'NoteNotFoundError';
export const NOTE_NOTE_FOUND_ERROR_MESSAGE = 'Note not found!';

export const ROLE_NOTES_DELETING_DEFAULT_ERROR_MESSAGE =
  'An Error has occured while deleting note, please refresh page and try again';

export const ROLE_NOTES_UPDATING_DEFAULT_ERROR_MESSAGE =
  'An Error has occured while updating note, please refresh page and try again';

export const ONE_ADDRESS_REQUIRED = 'OneAddressRequired';

export const INVALID_ADDRESS_UPDATE_REQUEST = 'InvalidAddressUpdateRequest';
export const DUPLICATE_ADDRESSES = 'DuplicateAddresses';
export const UNVERIFIED_ADDRESS = 'UnverifiedAddress';
export const INVALID_ADDRESS_COUNT = 'InvalidAddressCount';

export const GET_OPC_NETWORK_ERROR = (error: string) => {
  return `${error} - Failed to update address preferences.`;
};
export const OPC_DEFAULT_ERROR = 'Failed to update address preferences.';

export const UPDATE_PROFILE_FIELDS_NETWORK_ERROR = 'Error occurred when updating profile form fields';
export const UPDATE_PROFILE_ERROR = 'Error occurred when updating profile';
export const UPDATE_LICENSE_DATE_ERROR = 'Failed to update license start/end date';

export const UPDATE_DIR_SYNC_STATUS_ERROR = 'Error occurred when updating Directory Management Status';

export const EXPORT_MEMBER_REPORT_ERROR = 'Error occurred when exporting member messages reports';
export const GEOGRAPHICAL_API_ERROR = 'Error occurred when fetching users geographical location';
