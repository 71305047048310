import * as React from 'react';

export default (props) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49999 21C2.99999 21 2.49999 20.9 1.99999 20.6C0.599987 19.8 0.0999871 17.9 0.899987 16.5L9.39999 2.29999C9.69999 1.89999 9.99999 1.49999 10.4 1.29999C11.1 0.899994 11.9 0.799994 12.7 0.999994C13.5 1.19999 14.1 1.69999 14.6 2.39999L23 16.5C23.3 17 23.4 17.5 23.4 18C23.4 18.8 23.1 19.6 22.5 20.1C22 20.7 21.3 21 20.5 21H3.49999ZM11.1 3.39999L2.69999 17.5C2.39999 18 2.59999 18.6 3.09999 18.9C3.19999 19 3.39999 19 3.49999 19H20.4C20.7 19 20.9 18.9 21.1 18.7C21.3 18.5 21.4 18.3 21.4 18C21.4 17.8 21.4 17.7 21.3 17.5L12.9 3.39999C12.6 2.89999 12 2.79999 11.5 2.99999C11.3 3.09999 11.2 3.19999 11.1 3.39999Z"
      fill="#FA7A23"
    />
    <path
      d="M12 13C11.4 13 11 12.6 11 12V7.99999C11 7.39999 11.4 6.99999 12 6.99999C12.6 6.99999 13 7.39999 13 7.99999V12C13 12.6 12.6 13 12 13Z"
      fill="#FA7A23"
    />
    <path
      d="M12 17C11.7 17 11.5 16.9 11.3 16.7C11.1 16.5 11 16.3 11 16C11 15.9 11 15.7 11.1 15.6C11.2 15.5 11.2 15.4 11.3 15.3C11.4 15.2 11.5 15.1 11.6 15.1C11.8 15 12 15 12.2 15C12.3 15 12.3 15 12.4 15.1C12.5 15.1 12.5 15.1 12.6 15.2C12.6 15.2 12.7 15.3 12.8 15.3C12.9 15.4 13 15.5 13 15.6C13 15.7 13.1 15.9 13.1 16C13.1 16.3 13 16.5 12.8 16.7C12.5 16.9 12.3 17 12 17Z"
      fill="#FA7A23"
    />
  </svg>
);
