import React from 'react';
import { WHITEBOX_1_TITLE } from 'src/constants/virtualPagerStrings';
import { HCHeadingFour } from 'src/components/shared/HypercareComponents';
import theme from 'src/assets/styles/theme';
import styled from 'styled-components';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const StyledLink = styled.a`
  text-decoration: none;
  color: ${theme.mainTealColor};
  &:hover {
    text-decoration: underline;
  }
`;

function VirtualPagerTutorialButton(source: string) {
  const eventName =
    source === 'alertPreference'
      ? EVENTS.pvpaAlertAndNotificationSeeHowThisWorksPressed
      : EVENTS.pvpaAudioHandlingConfigurationSeeHowThisWorksPressed;
  return (
    <StyledLink
      href="https://hypercare.wistia.com/medias/94yg9qe5qm"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        AnalyticsManager.applyAnalytics({
          eventName: eventName,
        });
      }}
    >
      <HCHeadingFour color={theme.mainTealColor}>{WHITEBOX_1_TITLE}</HCHeadingFour>
    </StyledLink>
  );
}

export default VirtualPagerTutorialButton;
