import gql from 'graphql-tag';
import { userFragmentString } from 'src/gql/fragment/FullUserFragment';

export default gql`
  mutation AddAddresses($userId: ID!, $addresses: [CreateAddressInput!]!) {
    admin {
      user(id: $userId) {
        addAddresses(addresses: $addresses) {
          addresses {
            id
          }
        }
      }
    }
  }
`;
