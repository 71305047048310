import React from 'react';
import { withRouter } from 'react-router-dom';
import ChevronRight from 'src/assets/svgs/ChevronRight';
import Chip from '@material-ui/core/Chip';
import { RouteComponentProps } from 'react-router';
import { ShorthandOrganizationType, User } from 'src/types';
import { typedUseSelector } from 'src/redux/store';

import 'src/assets/styles/HeaderStyles.scss';
import { AppRoutes } from 'src/router/AppRoutes';
import { StyledSyncDirectoryChip } from 'src/components/shared/StyledSyncDirectoryChip';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { MANAGED_BY_DIRECTORY_SERVICE } from 'src/constants/dirSyncConstants';
import DirSyncViewModel from 'src/pages/HomePage/viewModels/DirSyncViewModel';
import { ACCOUNT_MANAGEMENT, PROFILE } from 'src/constants/user';

interface Props extends RouteComponentProps {
  adminType: ShorthandOrganizationType | '';
  profile: User;
}

const SubHeader = (props: Props) => {
  const { history, adminType, profile } = props;
  const { firstname, lastname, username, status, id } = profile;
  const fullName = `${firstname || ''} ${lastname || ''}`;
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);

  const isDirectorySynced = typedUseSelector((state) => state.userDataReducer?.isDirectorySynced);

  return (
    <div className="globalSubHeader">
      <div className="globalSubHeader__titleHolder">
        <div className="globalSubHeader__titleHolder__fullname">
          {fullName}
          {ldapDirectorySyncFlag && isDirectorySynced && (
            <StyledSyncDirectoryChip text={MANAGED_BY_DIRECTORY_SERVICE} valueClassName={'profileChip'} />
          )}
        </div>
        <div className="globalSubHeader__titleHolder__username">@{username}</div>
        <div className="globalSubHeader__titleHolder__statusChips">
          {status === 'shell' && <Chip className="shell" label="shell account" />}
          {adminType && <Chip className="admin" label={`${adminType} admin`} />}
        </div>
      </div>
      <div className="globalSubHeader__path">
        <div className="globalSubHeader__path__prevPage" onClick={() => history.push(AppRoutes.Home)}>
          {ACCOUNT_MANAGEMENT.toUpperCase()}
        </div>
        <div className="globalSubHeader__path__currPage">
          <ChevronRight fill="#4a4a4a" />
          {PROFILE.toUpperCase()}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SubHeader);
