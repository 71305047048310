import React from 'react';
import {
  FetchPaginatedInviteRequestInput,
  FetchPaginatedRequestInput,
  FetchSearchPaginatedInviteRequestInput,
  SearchRequestInput,
  UserRepository,
} from 'src/data/repository/UserRepository';
import { PENDING_ADMIN, PENDING_USER } from '../../../constants/inviteUserTypes';

export const UserViewModel = () => {
  const repo = UserRepository();

  const useFetchPaginatedUsers = ({ continuationId, direction }: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedUsers({ continuationId, direction });

    return {
      ...result,
    };
  };

  const useFetchPaginatedRemovedUsers = ({ continuationId, direction, skip }: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedRemovedUsers({ continuationId, direction, skip });
    return {
      ...result,
    };
  };

  const useFetchPaginatedInvites = ({
    continuationId,
    direction,
    inviteStatus,
    skip,
  }: FetchPaginatedInviteRequestInput) => {
    const result = repo.useFetchPaginatedInvites({ continuationId, direction, inviteStatus, skip });
    return {
      ...result,
    };
  };

  const getSearchedUsers = async ({ text, limit, continuationId, organizationType }: SearchRequestInput) => {
    const result = await repo.searchUsers({ text, limit, continuationId, organizationType });

    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }

    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchUsers,
    };
  };

  const getSearchedRemovedUsers = async ({ text, limit, continuationId }: SearchRequestInput) => {
    const result = await repo.searchRemovedUsers({ text, limit, continuationId });
    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }
    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchRemovedUsers,
    };
  };

  const getSearchedPendingInvites = async ({
    text,
    limit,
    continuationId,
    inviteStatus,
  }: FetchSearchPaginatedInviteRequestInput) => {
    const result = await repo.searchPendingInvites({ text, limit, continuationId, inviteStatus });
    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }

    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchPendingInvites,
    };
  };

  const getCombinedSearchData = async ({ text, skip, continuationId, orgType }) => {
    const searchPaginatedResultPayload = {
      limit: 30,
      continuationId,
      text,
      organizationType: orgType,
    };

    const searchUsersPromise = getSearchedUsers(searchPaginatedResultPayload);
    let searchRemovedUserPromise;
    let searchPendingInvitesPromise;
    let searchPendingAdminApprovalPromise;

    if (!skip) {
      searchRemovedUserPromise = getSearchedRemovedUsers(searchPaginatedResultPayload);
      searchPendingInvitesPromise = getSearchedPendingInvites({
        ...searchPaginatedResultPayload,
        inviteStatus: PENDING_USER,
      });
      searchPendingAdminApprovalPromise = getSearchedPendingInvites({
        ...searchPaginatedResultPayload,
        inviteStatus: PENDING_ADMIN,
      });
    }

    let [
      searchUsersResult,
      searchRemovedUserResult,
      searchPendingInvitesResult,
      searchPendingAdminApprovalInvitesResult,
    ] = await Promise.all([
      searchUsersPromise,
      searchRemovedUserPromise,
      searchPendingInvitesPromise,
      searchPendingAdminApprovalPromise,
    ]);

    return {
      searchUsersResult,
      searchRemovedUserResult,
      searchPendingInvitesResult,
      searchPendingAdminApprovalInvitesResult,
    };
  };

  const getOrganizationScopeList = async () => {
    const organizationList = await repo.fetchOrganizationScopeList();

    return organizationList.data;
  };

  return {
    useFetchPaginatedUsers,
    useFetchPaginatedRemovedUsers,
    useFetchPaginatedInvites,
    getOrganizationScopeList,
    getCombinedSearchData,
    getSearchedUsers,
    getSearchedPendingInvites,
    getSearchedRemovedUsers,
  };
};
