import { User } from 'src/types';
import { PaginatedUsersType } from '../../types/PaginatedTypes';

export function ByISOstring(a: User, b: User) {
  return a.joinDate < b.joinDate ? -1 : a.joinDate > b.joinDate ? 1 : 0;
}

export function ByISOstringRev(a: User, b: User) {
  return a.joinDate < b.joinDate ? 1 : a.joinDate > b.joinDate ? -1 : 0;
}

export function SortPaginatedUsersByISOstring(a: PaginatedUsersType, b: PaginatedUsersType) {
  return a.joinDate < b.joinDate ? -1 : a.joinDate > b.joinDate ? 1 : 0;
}

export function SortPaginatedUsersByISOstringReversed(a: PaginatedUsersType, b: PaginatedUsersType) {
  return a.joinDate < b.joinDate ? 1 : a.joinDate > b.joinDate ? -1 : 0;
}
