import gql from 'graphql-tag';
import { BasicProfileFieldFragment, ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type AdminAddProfileFieldsMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        profileSection: {
          addFields: {
            fields: Array<ProfileFieldFragmentType & { id: string }>;
          };
        };
      };
    };
  };
};

const AddProfileSectionFragment = gql`
  fragment AddProfileSectionFragment on ProfileSectionMutationResult {
    ... on ProfileSection {
      fields {
        ... on Node {
          id
        }
        ...BasicProfileFieldFragment
        __typename
        ... on ProfilePhoneField {
          phoneNumber
          placeholder
        }
        ... on ProfileTextField {
          text
          placeholder
        }
        ... on ProfileDropdownField {
          options
          selectedOptions
        }
        ... on ProfileNumericField {
          value
        }
        ... on ProfileLinkField {
          url
        }
        ... on ProfileEmailField {
          email
        }
        ... on ProfileDateField {
          date
        }
      }
    }
  }
`;

export const ADMIN_ADD_PROFILE_FIELDS_MUTATION = gql`
  mutation AddProfileFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $uniqueSectionId: ID!
    $fields: [CreateProfileFieldInput!]!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              profileSection(id: $uniqueSectionId) {
                addFields(fields: $fields) {
                  ...AddProfileSectionFragment
                }
              }
            }
          }

          ... on AdminDepartmentMutation {
            member(id: $userId) {
              profileSection(id: $uniqueSectionId) {
                addFields(fields: $fields) {
                  ...AddProfileSectionFragment
                }
              }
            }
          }

          ... on AdminSiteMutation {
            member(id: $userId) {
              profileSection(id: $uniqueSectionId) {
                addFields(fields: $fields) {
                  ...AddProfileSectionFragment
                }
              }
            }
          }
        }
      }
    }
  }

  ${BasicProfileFieldFragment}
  ${AddProfileSectionFragment}
`;
