import { MenuItem } from '@material-ui/core';
import React from 'react';
import { HCLabelOne } from 'src/components/shared/HypercareComponents';
import { COUNTRY, countriesMapping } from 'src/constants/virtualPagerStrings';
import { StyledFormControl, StyledSelect } from '../../components/shared';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const CountryDropdown = ({ selectedCountry, setSelectedCountry }) => {
  //Temporarily Removed other countries as there seems to be an issue on the Twilio side

  const countries = {
    US: 'US',
    CA: 'CA',
    // PAK: 'PA',
    // IRL: 'IRL',
    // ESP: 'ESP',
  };

  const countryList = Object.keys(countries).map((code) => ({
    isoCode: code,
  }));

  function handleCountrySelect(e) {
    const countrySel = e.target.value;
    setSelectedCountry(countrySel);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaPurchaseVirtualPagerCountryFieldChanged,
      params: {
        selected_country: countrySel.toString(),
      },
    });
  }

  return (
    <div style={{ width: '210px' }}>
      <HCLabelOne>{COUNTRY}</HCLabelOne>
      <StyledFormControl style={{ width: '100%' }}>
        <StyledSelect
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          variant="outlined"
          onChange={(e) => handleCountrySelect(e)}
          value={selectedCountry}
        >
          {countryList.map((country, key) => (
            <MenuItem key={key} value={country.isoCode}>
              <HCLabelOne>{countriesMapping[country.isoCode]}</HCLabelOne>
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </div>
  );
};

export default CountryDropdown;
