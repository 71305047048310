import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import 'src/assets/styles/FormStyles.scss';
import TextField from '@material-ui/core/TextField';

const pageNumberValidationSchema = Yup.object().shape({
  pageNumber: Yup.number().typeError('Page number is invalid').required('Page number is required!'),
});

const PageCallbackForm = ({ handleModalFormSubmission }) => (
  <Formik
    initialValues={{
      pageNumber: '',
    }}
    validationSchema={pageNumberValidationSchema}
    onSubmit={(values, { resetForm, setFieldError, setSubmitting }) => {
      handleModalFormSubmission(values).catch((e) => {
        switch (e) {
          case 'unknown backend error':
            setSubmitting(false);
            setFieldError('general', 'unknown backend error occurred, please try again');
            break;
          default:
            resetForm();
        }
      });
    }}
    render={(props) => {
      const {
        values: { pageNumber },
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
      } = props as FormikProps<any>;
      const hasErrors = Object.keys(errors).length > 0;
      return (
        <form onSubmit={handleSubmit} className="modalForm">
          <TextField
            fullWidth
            autoFocus
            id="pageNumber"
            name="pageNumber"
            label="CALL BACK NUMBER"
            placeholder="Enter call back number here"
            value={pageNumber}
            margin="normal"
            onChange={handleChange}
            helperText={errors.pageNumber}
            error={Boolean(errors.pageNumber)}
          />

          <div className="modalForm__buttonWrapper" style={{ width: '300px' }}>
            <button type="submit" disabled={hasErrors || isSubmitting}>
              Send
            </button>
          </div>
          {isSubmitting && <span className="modalForm__sendRequestLabel">sending request...</span>}
          {Boolean(errors.general) && <span className="modalForm__inputError">{errors.general}</span>}
        </form>
      );
    }}
  />
);

export default PageCallbackForm;
