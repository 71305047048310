import gql from 'graphql-tag';
import userInviteFragment from 'src/gql/fragment/InviteFragement';

export const DECLINE_INVITE = gql`
  mutation DeclineInvite($inviteId: Int!, $note: String) {
    admin {
      declineInvite(inviteId: $inviteId, note: $note) {
        ...UserInviteFragment
      }
    }
  }

  ${userInviteFragment}
`;
