import * as React from 'react';

export default (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="chevron--sort" clipPath="url(#clip0_483_107237)">
      <path
        id="Vector"
        d="M8.70711 13.2928C8.31658 13.6833 7.68342 13.6833 7.29289 13.2928L4.85266 10.8526C4.65789 10.6578 4.65789 10.342 4.85266 10.1473C5.0473 9.95262 5.36284 9.95248 5.55766 10.1469L8 12.5849L10.4423 10.1469C10.6372 9.95248 10.9527 9.95262 11.1473 10.1473C11.3421 10.342 11.3421 10.6578 11.1473 10.8526L8.70711 13.2928Z"
        fill="#4A4A4A"
      />
      <path
        id="Vector_2"
        d="M7.29289 2.70711C7.68342 2.31658 8.31658 2.31658 8.70711 2.70711L11.1473 5.14734C11.3421 5.34211 11.3421 5.65789 11.1473 5.85266C10.9527 6.0473 10.6372 6.04744 10.4423 5.85297L8 3.415L5.55766 5.85297C5.36284 6.04744 5.0473 6.0473 4.85266 5.85266C4.65789 5.65789 4.65789 5.34211 4.85266 5.14734L7.29289 2.70711Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_483_107237">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
