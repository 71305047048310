import gql from 'graphql-tag';

export default gql`
  mutation PageUser($userId: ID!, $callbackNum: String!) {
    admin {
      user(id: $userId) {
        page(callback: $callbackNum)
      }
    }
  }
`;
