import gql from 'graphql-tag';

export default gql`
  mutation DeleteRole($roleId: Int!, $departmentId: Int!) {
    admin {
      locating {
        department(id: $departmentId) {
          role(id: $roleId) {
            deleteRole
          }
        }
      }
    }
  }
`;
