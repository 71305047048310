import gql from 'graphql-tag';
import userInviteFragment from 'src/gql/fragment/InviteFragement';

// TODO: channels differentiation

export const GET_PENDING_INVITES = gql`
  query GetInvitesForOrganization(
    $organizationId: Int!
    $status: [UserInvitationStatus]
    $afterDate: String
    $beforeDate: String
  ) {
    locating {
      organization(id: $organizationId) {
        userInvites(status: $status, afterDate: $afterDate, beforeDate: $beforeDate) {
          ...UserInviteFragment
        }
      }
    }
  }

  ${userInviteFragment}
`;
