import React from 'react';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { StyledMenuItem } from 'src/pages/HomePage/InviteStyle';
import { AppRoutes } from '../../../router/AppRoutes';
import ResetPasswordModal from 'src/components/modals/ResetPasswordModal';
import ConfirmationActionModal from 'src/components/modals/ConfirmationActionModal';
import { REMOTE_LOGOUT, REMOVE_USER } from 'src/constants/modalTypes';
import { ModalActionType } from 'src/types/Modal';
import { User } from 'src/types';
import {
  DISABLE_DIRECTORY_MANAGEMENT,
  ENABLE_DIRECTORY_MANAGEMENT,
  LOGOUT_ALL_DEVICES,
  REMOVE_MEMBER_FROM_ORGANIZATION,
  RESET_PASSWORD,
  VIEW_NAME_PROFILE,
} from 'src/constants/dirSyncConstants';
import DirSyncViewModel from '../viewModels/DirSyncViewModel';
import DirSyncUsersListModal from 'src/components/modals/DirSyncUsersListModal';
import { ORG } from 'src/constants/strings';
import store from 'src/redux/store';
import { ORGANIZATION } from '../../../constants/organizationTypes';
import { isRemoveButtonDisabledForOrganizationLevel } from '../../../utils/organizationHelper/organizationHelper';
interface Props {
  selectedUser: User;
  handleCloseMenu: () => void;
  adminType: string;
}

const UsersListActions: React.FC<Props> = ({ selectedUser, handleCloseMenu, adminType }) => {
  const [dirSyncModalOpen, setDirSyncModalOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const [modalActionType, setModalActionType] = React.useState(null);
  const viewModel = DirSyncViewModel();
  const { data: orgDirSyncStatus } = viewModel.fetchOrgDirSyncStatus();

  const { type: orgType } = store.getState().organizationReducer;

  const handleMixpanelEvent = (eventType) => {
    AnalyticsManager.applyAnalytics({
      eventName: eventType,
    });
  };

  const handleOpenUserProfile = () => {
    window.routerHistory.push(`${AppRoutes.UserProfile}/${selectedUser.id}`);
  };

  const handleResetPassword = () => {
    setShowModal(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.resetPasswordButtonPressed,
    });
  };

  const setModalStatus = (type: ModalActionType, bool: boolean) => {
    setShowConfirmationModal(bool);
    setModalActionType(type);
  };

  const closeModal = () => {
    setShowConfirmationModal(false);
    setModalActionType('');
  };

  const handleDirSyncModalClose = () => {
    setDirSyncModalOpen(false);
  };

  const handleDirSyncModalOpen = () => {
    setDirSyncModalOpen(true);
  };

  const getName = () => {
    if (selectedUser?.firstname) {
      return selectedUser.firstname + ' ' + selectedUser.lastname + "'s";
    } else {
      return null;
    }
  };

  return (
    <>
      <div>
        <StyledMenuItem
          onClick={() => {
            handleOpenUserProfile();
            handleMixpanelEvent('viewProfileUsersListPressed');
          }}
        >
          {VIEW_NAME_PROFILE(getName())}
        </StyledMenuItem>
        {orgDirSyncStatus && (
          <StyledMenuItem
            disabled={adminType !== ORG}
            onClick={() => {
              handleDirSyncModalOpen();
              handleMixpanelEvent('disableDirectoryManagementUsersListPressed');
              handleCloseMenu();
            }}
          >
            {selectedUser?.isDirectorySynced ? DISABLE_DIRECTORY_MANAGEMENT : ENABLE_DIRECTORY_MANAGEMENT}
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => {
            setModalStatus(REMOTE_LOGOUT, true);
            handleMixpanelEvent('allDeviceLogoutButtonUsersListPressed');
            handleCloseMenu();
          }}
        >
          {LOGOUT_ALL_DEVICES}
        </StyledMenuItem>
        <StyledMenuItem
          disabled={selectedUser?.isSSOEnabled}
          onClick={() => {
            handleResetPassword();
            handleMixpanelEvent('resetPasswordButtonUsersListPressed');
            handleCloseMenu();
          }}
        >
          {RESET_PASSWORD}
        </StyledMenuItem>
        <StyledMenuItem
          $remove
          disabled={isRemoveButtonDisabledForOrganizationLevel(selectedUser?.isDirectorySynced, orgType)}
          onClick={() => {
            setModalStatus(REMOVE_USER, true);
            handleMixpanelEvent('removeMemberButtonUsersListPressed');
            handleCloseMenu();
          }}
        >
          {REMOVE_MEMBER_FROM_ORGANIZATION(orgType)}
        </StyledMenuItem>
      </div>
      {showModal && (
        <ResetPasswordModal profile={selectedUser} showModal={showModal} handleCloseModal={() => setShowModal(false)} />
      )}
      {showConfirmationModal && (
        <ConfirmationActionModal
          profile={selectedUser}
          showModal={showConfirmationModal}
          confirmationType={modalActionType}
          handleCloseModal={closeModal}
        />
      )}
      {dirSyncModalOpen && (
        <DirSyncUsersListModal
          open={dirSyncModalOpen}
          handleCloseModal={() => handleDirSyncModalClose()}
          user={selectedUser}
        />
      )}
    </>
  );
};

export default UsersListActions;
