import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { Checkbox } from '@material-ui/core';
import { TwilioNumbers } from 'src/types';
import GetNumberViewModal from '../GetNumberViewModel';
import { HCLabelSix, HCLabelThree } from 'src/components/shared/HypercareComponents';
import {
  CONTACT_HYPERCARE_SUPPORT,
  MODIFY_FILTER,
  NO_NUMBERS_FOUND,
  TOLL_FREE_NUMBER_PART_1,
  TOLL_FREE_NUMBER_PART_2,
} from 'src/constants/virtualPagerStrings';

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${theme.borderBottomLight};
`;

export const TH = styled.th`
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.mainFontColor};

  margin: 0;
  background: #f2f2f2 !important;
  text-align: left;
  text-transform: uppercase;
`;

const TC = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #d8d8d8;
  padding: 0;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 20px;
  }
`;

const StyledTBody = styled.tbody`
  overflow: auto;
`;

const StyledTHead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
  border-radius: 4px 4px 0px 0px;
`;

const NoResultContainer = styled.div`
  align-items: center;
  display: flex;
  height: 128px;
  padding: 0px 100px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  text-align: center;
`;

const ConfigureLink = styled.a`
  color: ${(props) => props.theme.mainTealColor};
  cursor: pointer;
  text-decoration-line: underline;
`;

interface SearchNumberResultGroupProps {
  numberData: TwilioNumbers[];
  purchasingNumberList: TwilioNumbers[];
  setPurchasingNumberList: (numberList: TwilioNumbers[]) => void;
}

const SearchNumberResultGroup: React.FC<SearchNumberResultGroupProps> = ({ numberData, setPurchasingNumberList }) => {
  const { formatPhoneNumber } = GetNumberViewModal();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualSelections, setIndividualSelections] = useState<number[]>([]);

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numberData.length > 0) {
      const checked = event.target.checked;
      setSelectAllChecked(checked);
      if (checked) {
        const allIndexes = numberData.map((_, index) => index);
        setIndividualSelections(allIndexes);
      } else {
        setIndividualSelections([]);
      }
    }
  };

  const handleIndividualSelection = (index: number) => {
    const updatedSelections = individualSelections.includes(index)
      ? individualSelections.filter((i) => i !== index)
      : [...individualSelections, index];

    setIndividualSelections(updatedSelections);
    setSelectAllChecked(updatedSelections.length === numberData.length);

    const selectedNumbers = updatedSelections.map((i) => numberData[i]);
    setPurchasingNumberList(selectedNumbers);
  };

  useEffect(() => {
    setSelectAllChecked(false);
    setIndividualSelections([]);
  }, [numberData]);

  useEffect(() => {
    setSelectAllChecked(individualSelections.length === numberData.length);
    const selectedNumbers = individualSelections.map((i) => numberData[i]);
    setPurchasingNumberList(selectedNumbers);
  }, [individualSelections, numberData]);

  return (
    <div>
      <CustomTable>
        <StyledTHead>
          <tr>
            <TH>
              {numberData.length > 0 ? (
                <Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />
              ) : (
                <Checkbox checked={false} disabled />
              )}
            </TH>
            <TH>Number</TH>
            <TH>Locality</TH>
          </tr>
        </StyledTHead>
        <StyledTBody>
          {numberData.length > 0 ? (
            <>
              {numberData.map((row, index) => (
                <tr key={index}>
                  <TC>
                    <Checkbox
                      checked={individualSelections.includes(index)}
                      onChange={() => handleIndividualSelection(index)}
                    />
                  </TC>
                  <TC>{formatPhoneNumber(row.phoneNumber)}</TC>
                  <TC>{row.locality}</TC>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <TC colSpan={3}>
                <NoResultContainer>
                  <HCLabelSix>{NO_NUMBERS_FOUND}</HCLabelSix>
                  <div>
                    <HCLabelThree>{MODIFY_FILTER}</HCLabelThree>
                    <HCLabelThree>
                      {TOLL_FREE_NUMBER_PART_1}
                      <ConfigureLink href="mailto:support@hypercare.com">{CONTACT_HYPERCARE_SUPPORT}</ConfigureLink>
                      {TOLL_FREE_NUMBER_PART_2}
                    </HCLabelThree>
                  </div>
                </NoResultContainer>
              </TC>
            </tr>
          )}
        </StyledTBody>
      </CustomTable>
    </div>
  );
};

export default SearchNumberResultGroup;
