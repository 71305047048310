import gql from 'graphql-tag';
// import {publicUserFragment} from 'src/gql/fragment/FullUserFragment';

export default gql`
  mutation whitelistUser($userId: ID!) {
    admin {
      whitelist(userId: $userId) {
        id
      }
    }
  }
`;
