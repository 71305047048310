import React, { useEffect, useState } from 'react';
import {
  GridHeaderRoleNotes,
  GridHeaderRoleNotesTitle,
  AddRoleNoteButtonContainer,
  RoleNoteModalContainer,
  RoleNoteModalBody,
  AddEditRoleNoteButton,
} from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';
import { ClickAwayListener, Divider, Popover } from '@material-ui/core';
import {
  HCBodyOne,
  HCBodyTwo,
  HCCustomText,
  HCHeadingFour,
  HCLabelTwo,
  SecondaryButton,
} from 'src/components/shared/HypercareComponents';
import { ADD_NOTES, ROLE_NOTES } from 'src/constants/hiddenNotes';
import theme from 'src/assets/styles/theme';
import store, { typedUseSelector } from 'src/redux/store';
import HypercareRoleNotesViewModel from 'src/components/hidden-notes/role-notes/HypercareRoleNotesViewModel';
import { HiddenNotes, Role } from 'src/types';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  margin: 0 -24px 0 -24px !important;
`;

const Note = styled.span`
  margin-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 5.9em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  word-break: break-word;
`;

const RoleNoteModalHeader = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

interface IGridRoleNoteHeader {
  scheduleId: number;
}

const GridRoleNoteHeader = ({ scheduleId }: IGridRoleNoteHeader) => {
  const [notesPopup, setNotesPopup] = useState<HTMLElement | null>(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const dispatch = useDispatch();
  const [addNoteVisibility, setAddNoteVisibility] = useState(false);

  const roleContainer = store.getState().monthlyScheduleReducer.roleContainer;

  const roleNoteData = typedUseSelector((state) => state.monthlyScheduleReducer.roleNotes);

  const { getRoleNotesForDepartment } = HypercareRoleNotesViewModel();
  useEffect(() => {
    const fetchRoleNotes = async () => {
      await getRoleNotesForDepartment({ scheduleId });
    };

    fetchRoleNotes();
  }, []);

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>, note, key) => {
    setNotesPopup(e.currentTarget);
    setSelectedNote({ ...note, key });
  };
  const handlePopoverClose = (e) => {
    if (notesPopup && notesPopup.contains(e.target)) return;
    setNotesPopup(null);
    setSelectedNote(null);
  };

  const addRoleNoteButtonClicked = (id: string) => {
    dispatch(allActions.monthlyScheduleAction.setRoleNoteModalVisibility({ visibility: true }));

    dispatch(
      allActions.monthlyScheduleAction.setCurrentRoleSelected({
        roleId: parseInt(id),
        roleName: roleNoteData[id].roleName,
      }),
    );
  };

  return (
    <>
      <GridHeaderRoleNotesTitle>
        {' '}
        <HCHeadingFour fontWeight={700} color={theme.mainFontColor}>
          {ROLE_NOTES}
        </HCHeadingFour>
      </GridHeaderRoleNotesTitle>

      {roleContainer?.map((role: Role, index) => {
        return (
          <div key={role.roleId} onMouseLeave={handlePopoverClose}>
            {roleNoteData?.[role.roleId]?.notes.length === 0 ? (
              <AddRoleNoteButtonContainer
                onMouseEnter={() => setAddNoteVisibility(true)}
                onMouseLeave={() => setAddNoteVisibility(false)}
              >
                {addNoteVisibility && (
                  <SecondaryButton type="button" onClick={() => addRoleNoteButtonClicked(role.roleId)}>
                    {ADD_NOTES}
                  </SecondaryButton>
                )}
              </AddRoleNoteButtonContainer>
            ) : (
              <div key={role.roleId} style={{ margin: '10px 0 10px 0', height: '100px' }}>
                <GridHeaderRoleNotes
                  aria-describedby={index.toString()}
                  onMouseEnter={(e) => handlePopoverOpen(e, roleNoteData?.[role.roleId], role.roleId)}
                >
                  {roleNoteData?.[role.roleId]?.notes.map((note, index) => (
                    <HCBodyTwo key={note.id}>
                      <Note>• {note.note}</Note>
                    </HCBodyTwo>
                  ))}
                </GridHeaderRoleNotes>
              </div>
            )}
          </div>
        );
      })}

      {selectedNote?.notes?.length && notesPopup ? (
        <Popover
          open={Boolean(notesPopup)}
          anchorEl={notesPopup}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className="popper__container notes__popover"
          disablePortal
        >
          <div className="popper__content__wrapper">
            <ClickAwayListener onClickAway={handlePopoverClose}>
              <RoleNoteModalContainer onMouseLeave={handlePopoverClose}>
                <RoleNoteModalHeader>
                  <HCBodyOne>{selectedNote.roleName}</HCBodyOne>
                </RoleNoteModalHeader>
                <RoleNoteModalBody>
                  {selectedNote.notes.map((note: HiddenNotes) => (
                    <HCLabelTwo key={note.id}>
                      <span>&#8226;</span>
                      <span style={{ marginLeft: '6px' }}>{`${note.note}`}</span>
                    </HCLabelTwo>
                  ))}
                </RoleNoteModalBody>
                <StyledDivider />
                <AddEditRoleNoteButton onClick={() => addRoleNoteButtonClicked(selectedNote.key)}>
                  <HCCustomText
                    fontSize={'15px'}
                    fontWeight={600}
                    fontFamily={'Nunito Sans'}
                    color={theme.mainTealColor}
                  >{`Open & edit notes`}</HCCustomText>
                </AddEditRoleNoteButton>
              </RoleNoteModalContainer>
            </ClickAwayListener>
          </div>
        </Popover>
      ) : null}
    </>
  );
};
export default GridRoleNoteHeader;
