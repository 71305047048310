import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { StyledModal, CloseBtn, Header, Footer } from 'src/components/modals/ModalStyleComponents';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import {
  HCHeadingThree,
  HCLabelOne,
  HCHeadingFour,
  HCBodyTwo,
  SecondaryButton,
  PrimaryButton,
  TextButton,
} from 'src/components/shared/HypercareComponents';
import InfoIcon from 'src/assets/svgs/Info';
import {
  AudioModalMenuOptions,
  generatedLinkResponse,
  AudioModalType,
  uploadAudioResponse,
  audioObject,
} from 'src/types/VirtualPagers';

import AudioModalTabHeader from './components/AudioModalTabMenu';
import UploadIcon from 'src/assets/svgs/UploadIcon';
import ConfigureNumberViewModel from './ConfigureNumberViewModel';
import { StyledTextField } from 'src/pages/VirtualPagersPage/components/shared';
import {
  AUDIO_UPLOAD,
  BROWSER_NOT_SUPPORTED,
  CANCEL,
  DUPLICATE_AUDIO_FILE_NAME,
  ENTER_TEXT_SPEECH,
  GENERATE,
  MODIFY,
  NAME,
  NAME_IS_REQUIRED,
  SAMPLE_AUDIO,
  SUPPORTED_FILE_TYPES,
  TEXT_TO_SPEECH_AREA_PLACEHOLDER,
  UPLOAD,
  USE_AUDIO,
  getDescriptionText,
  getHeaderText,
  getSamplePromptText,
  getTextFieldPlaceHolder,
} from 'src/constants/virtualPagerStrings';
import AudioPlayer from './components/UploadedAudioPlayer';
import store, { typedUseSelector } from 'src/redux/store';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const StylizedModal = styled(StyledModal)`
  .ReactModal__Content {
    width: 680px;
    padding: 0;
    max-height: calc(90%);
  }
`;

const ModalContainer = styled.div`
  display: flex;
  padding: 0px 24px 16px 24px;
  flex-direction: column;
  overflow-y: scroll;
`;

const ModalHeader = styled(Header)`
  padding: 24px 24px 0px 24px;
  margin-bottom: 0;
`;

const ModalFooter = styled(Footer)`
  gap: 8px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) inset !important;
`;

const InfoBox = styled.div`
  gap: 16px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  background: ${theme.lightGrey};
`;

const InfoIconContainer = styled.div`
  padding: 0px;
  margin: 0px;
`;

const ButtonGroup = styled.div`
  gap: 8px;
  display: flex;
  padding-top: 16px;
  justify-content: flex-end;
`;

const StyledAudio = styled.audio`
  width: 100%;
  margin-top: 12px;
  padding-top: 12px;
  border-radius: 8px;
  color: ${theme.mainFontColor};
  border-top: 1px solid ${theme.greyBackground};
`;

interface Props {
  isOpen: boolean;
  type: AudioModalType;
  closeModal: () => void;
  onCreateAudioSuccess?: (audio: audioObject) => Promise<void>;
  orgCallbackAudioNames: string[] | null;
  orgVoicemailAudioNames: string[] | null;
}

const ModalAddAudioView = ({
  isOpen,
  closeModal,
  type,
  onCreateAudioSuccess = async () => {},
  orgCallbackAudioNames,
  orgVoicemailAudioNames,
}: Props) => {
  const { generateTextToSpeech, uploadAudioToMainAPI, createAudioSteps, uploadAudioToPVPA, fetchSingleAudio } =
    ConfigureNumberViewModel();
  const orgId = store.getState()?.organizationReducer?.organization_id;
  const { currentPager } = typedUseSelector((state) => state.virtualPagerReducer);

  const [menuOptions, setMenuOptions] = useState<AudioModalMenuOptions>('upload');
  const [uploadedAudioInfo, setUploadedAudioInfo] = useState<uploadAudioResponse>(null);
  const [localAudio, setLocalAudio] = useState<File | null>(null);

  // Audio Name
  const CHARACTER_LIMIT = 75;
  const [name, setName] = useState<string>('');
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const exceededCharacterLimit = name.length >= CHARACTER_LIMIT;

  // Text to Speech
  const TEXTAREA_LIMIT = 2000;
  const [textToSpeechText, setTextToSpeechText] = useState<string>('');
  const exceededTextAreaLimit = textToSpeechText.length >= TEXTAREA_LIMIT;
  const [isTextAreaDisable, setTextAreaDisabled] = useState<boolean>(false);

  const [textGeneratedAudio, setTextGeneratedAudio] = useState<generatedLinkResponse | null>(null);

  const hiddenFileInput = useRef(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const clearStates = () => {
    setUploadedAudioInfo(null);
    setLocalAudio(null);
    setName('');
    setTextToSpeechText('');
    setTextGeneratedAudio(null);
    setMenuOptions('upload');
  };

  const validateName = () => {
    const errorMessage =
      name.length === 0
        ? NAME_IS_REQUIRED
        : !_.isEmpty(orgCallbackAudioNames) &&
          !_.isEmpty(orgVoicemailAudioNames) &&
          (orgCallbackAudioNames.includes(name) || orgVoicemailAudioNames.includes(name))
        ? DUPLICATE_AUDIO_FILE_NAME
        : '';
    setNameErrorMessage(errorMessage);
    return errorMessage;
  };

  const isUseAudioDisabled: boolean =
    name.length === 0 || (menuOptions === 'upload' ? !uploadedAudioInfo : !textGeneratedAudio);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const allowedFormats = ['audio/mp3', 'audio/wav', 'audio/x-m4a', 'audio/mpeg'];
      if (allowedFormats.includes(file.type)) {
        setUploadedAudioInfo(await uploadAudioToMainAPI(file));
        setLocalAudio(file);
        AnalyticsManager.applyAnalytics({
          eventName: type === 'callback' ? EVENTS.pvpaAddNewCallbackUploadAudio : EVENTS.pvpaAddNewVoicemailUploadAudio,
          params: {
            virtual_pager_number: currentPager.pagerNumber,
            audio_file_type: file.type,
            response: !!uploadedAudioInfo ? 'File uploaded successfully' : 'Upload failed',
          },
        });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
    setNameErrorMessage('');
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextToSpeechText(event.target.value);
  };

  const generateAudio = async () => {
    const errorMessage = validateName();
    if (errorMessage.length > 0) {
      return;
    }
    setTextAreaDisabled(null);
    const generatedAudio = await generateTextToSpeech(name, textToSpeechText);
    setTextGeneratedAudio(generatedAudio);
    setTextAreaDisabled(true);
    AnalyticsManager.applyAnalytics({
      eventName:
        type === 'callback'
          ? EVENTS.pvpaAddNewCallbackTextToSpeechGeneratePressed
          : EVENTS.pvpaAddNewVoicemailTextToSpeechGeneratePressed,
      params: {
        virtual_pager_number: currentPager.pagerNumber,
        message_content: textToSpeechText,
      },
    });
  };

  const onUseAudio = async () => {
    const errorMessage = validateName();
    if (errorMessage.length > 0) {
      return;
    }
    AnalyticsManager.applyAnalytics({
      eventName:
        type === 'callback' ? EVENTS.pvpaAddNewCallbackUseAudioPressed : EVENTS.pvpaAddNewVoicemailUseAudioPressed,
      params: {
        virtual_pager_number: currentPager.pagerNumber,
        audio_name: name,
        source: menuOptions === 'upload' ? 'Upload Audio' : 'Text-to-speech',
      },
    });
    const PVPAUploadRes = await uploadAudioToPVPA(
      menuOptions === 'upload' ? uploadedAudioInfo.url : textGeneratedAudio.Location,
      name,
    );
    const audioStep = await createAudioSteps(orgId, name, type, PVPAUploadRes.url);
    const audioStepWithPresignedURL = await fetchSingleAudio(audioStep.id);

    await onCreateAudioSuccess(audioStepWithPresignedURL);
    clearStates();
    closeModal();
  };

  const headerText = getHeaderText(type);
  const descriptionText = getDescriptionText(type);
  const samplePromptText = getSamplePromptText(type);
  const textFieldPlaceHolder = getTextFieldPlaceHolder(type);

  return (
    <StylizedModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <ModalHeader>
        <HCHeadingThree>{headerText}</HCHeadingThree>
        <CloseBtn onClick={closeModal} />
      </ModalHeader>
      <ModalContainer>
        <HCLabelOne style={{ marginBottom: '16px' }}>{descriptionText}</HCLabelOne>
        <InfoBox>
          <InfoIconContainer>
            <InfoIcon
              width={24}
              height={24}
              circlefill={theme.mainTealColor}
              strokefill={theme.white}
              stroke={theme.white}
            />
          </InfoIconContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              marginBottom: '16px',
            }}
          >
            <HCHeadingFour fontWeight={700} color="black">
              {SAMPLE_AUDIO}
            </HCHeadingFour>
            <HCBodyTwo>{samplePromptText}</HCBodyTwo>
          </div>
        </InfoBox>
        <AudioModalTabHeader currentMenuOption={menuOptions} setMenuOptions={setMenuOptions} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '4px',
            marginBottom: '24px',
          }}
        >
          <div style={{ display: 'flex', width: '50%', marginTop: '16px' }}>
            <HCBodyTwo afterContent={' *'} color="#4A4A4A" fontWeight={600} style={{ flexGrow: '1' }}>
              {NAME}
            </HCBodyTwo>
            <HCBodyTwo color={name.length >= CHARACTER_LIMIT ? theme.errorRedBorder : theme.textLightTertiary}>
              {name.length}/{CHARACTER_LIMIT}
            </HCBodyTwo>
          </div>
          <StyledTextField
            id="outlined-basic"
            variant="outlined"
            placeholder={textFieldPlaceHolder}
            style={{ width: '50%' }}
            inputProps={{
              maxLength: CHARACTER_LIMIT,
              style: {
                padding: exceededCharacterLimit ? '6px 12px 6px 12px' : '6px 0px 6px 12px',
                height: '40px',
              },
            }}
            InputProps={{ style: { borderRadius: 0, border: '1px solid #D8D8D8' } }}
            value={name}
            onChange={handleInputChange}
            error={exceededCharacterLimit || nameErrorMessage.length > 0}
            onBlur={validateName}
          />
          {nameErrorMessage.length > 0 && <HCBodyTwo color={theme.errorRed}>{nameErrorMessage}</HCBodyTwo>}
        </div>

        {menuOptions === 'upload' ? (
          <div style={{ display: 'flex', width: '50%', flexDirection: 'column', gap: '4px' }}>
            <HCBodyTwo afterContent={' *'} color="#4A4A4A" fontWeight={600} style={{ flexGrow: '1' }}>
              {AUDIO_UPLOAD}
            </HCBodyTwo>
            <HCBodyTwo style={{ marginBottom: '16px' }}>{SUPPORTED_FILE_TYPES}</HCBodyTwo>
            <SecondaryButton
              type="button"
              disabled={false}
              style={{ width: 'fit-content' }}
              color="#4A4A4A"
              onClick={handleClick}
            >
              <UploadIcon fill={theme.mainTealColor} styles={{ marginRight: '8px' }} />
              {UPLOAD}
            </SecondaryButton>
            <input
              type="file"
              accept=".mp3"
              onChange={handleFileUpload}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
            />
            {uploadedAudioInfo && localAudio && (
              <AudioPlayer
                fileSize={localAudio.size / 1000}
                fileName={localAudio.name}
                src={uploadedAudioInfo.url}
                onPause={() => {
                  AnalyticsManager.applyAnalytics({
                    eventName:
                      type === 'callback'
                        ? EVENTS.pvpaAddNewCallbackUploadedAudioPlaybackPressed
                        : EVENTS.pvpaAddNewVoicemailUploadedAudioPlaybackPressed,
                    params: {
                      virtual_pager_number: currentPager.pagerNumber,
                      audio_playback_event: 'Pause',
                    },
                  });
                }}
                onPlay={() => {
                  AnalyticsManager.applyAnalytics({
                    eventName:
                      type === 'callback'
                        ? EVENTS.pvpaAddNewCallbackUploadedAudioPlaybackPressed
                        : EVENTS.pvpaAddNewVoicemailUploadedAudioPlaybackPressed,
                    params: {
                      virtual_pager_number: currentPager.pagerNumber,
                      audio_playback_event: 'Play',
                    },
                  });
                }}
                onClear={() => {
                  setUploadedAudioInfo(null);
                  setLocalAudio(null);
                  AnalyticsManager.applyAnalytics({
                    eventName:
                      type === 'callback'
                        ? EVENTS.pvpaAddNewCallbackUploadedAudioDeletePressed
                        : EVENTS.pvpaAddNewVoicemailUploadedAudioDeletePressed,
                    params: {
                      virtual_pager_number: currentPager.pagerNumber,
                    },
                  });
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '4px' }}>
            <div style={{ display: 'flex', width: '100%' }}>
              <HCBodyTwo afterContent={' *'} color="#4A4A4A" fontWeight={600} style={{ flexGrow: '1' }}>
                {ENTER_TEXT_SPEECH}
              </HCBodyTwo>
              <HCBodyTwo
                color={textToSpeechText.length >= TEXTAREA_LIMIT ? theme.errorRedBorder : theme.textLightTertiary}
              >
                {textToSpeechText.length}/{TEXTAREA_LIMIT}
              </HCBodyTwo>
            </div>
            <StyledTextField
              multiline
              variant="outlined"
              id="outlined-basic"
              style={{ width: '100%' }}
              disabled={isTextAreaDisable}
              placeholder={TEXT_TO_SPEECH_AREA_PLACEHOLDER}
              inputProps={{
                maxLength: TEXTAREA_LIMIT,
                style: { width: '100%', height: '96px' },
              }}
              InputProps={{ style: { borderRadius: 0, border: '1px solid #D8D8D8', padding: '8px 12px' } }}
              value={textToSpeechText}
              onChange={handleTextAreaChange}
              error={exceededTextAreaLimit}
            />
            <ButtonGroup>
              <TextButton
                color="#4A4A4A"
                disabled={!isTextAreaDisable}
                onClick={() => {
                  setTextAreaDisabled(false);
                  AnalyticsManager.applyAnalytics({
                    eventName:
                      type === 'callback'
                        ? EVENTS.pvpaAddNewCallbackTextToSpeechModifyPressed
                        : EVENTS.pvpaAddNewVoicemailTextToSpeechModifyPressed,
                    params: {
                      virtual_pager_number: currentPager.pagerNumber,
                      message_content: textToSpeechText,
                    },
                  });
                }}
              >
                {MODIFY}
              </TextButton>
              <SecondaryButton
                onClick={generateAudio}
                color="#4A4A4A"
                disabled={!(textToSpeechText.length > 0 && name.length > 0)}
              >
                {GENERATE}
              </SecondaryButton>
            </ButtonGroup>
            {textGeneratedAudio && (
              <StyledAudio
                ref={audioRef}
                controls
                src={textGeneratedAudio.signedURL}
                onPlay={() => {
                  AnalyticsManager.applyAnalytics({
                    eventName:
                      type === 'callback'
                        ? EVENTS.pvpaAddNewCallbackTextToSpeechGeneratedAudioPlaybackPressed
                        : EVENTS.pvpaAddNewVoicemailTextToSpeechGeneratedAudioPlaybackPressed,
                    params: {
                      virtual_pager_number: currentPager.pagerNumber,
                    },
                  });
                }}
              >
                {BROWSER_NOT_SUPPORTED}
              </StyledAudio>
            )}
          </div>
        )}
      </ModalContainer>
      <ModalFooter>
        <SecondaryButton
          onClick={() => {
            AnalyticsManager.applyAnalytics({
              eventName:
                type === 'callback' ? EVENTS.pvpaAddNewCallbackCancelPressed : EVENTS.pvpaAddNewVoicemailCancelPressed,
              params: {
                virtual_pager_number: currentPager.pagerNumber,
                audio_name: name,
                source: menuOptions === 'upload' ? 'Upload Audio' : 'Text-to-speech',
              },
            });
            closeModal();
          }}
        >
          {CANCEL}
        </SecondaryButton>
        <PrimaryButton onClick={onUseAudio} disabled={isUseAudioDisabled}>
          {USE_AUDIO}
        </PrimaryButton>
      </ModalFooter>
    </StylizedModal>
  );
};

export default ModalAddAudioView;
