import MomentUtils from '@date-io/moment';
import { Dialog, Grid, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import CalendarIcon from '../../../../assets/svgs/CalendarIcon';

type FormFieldInputDateProps = {
  value?: Date;
  placeholder: string;
  onChange(value: Date): void;
  disabled: boolean;
  mode: 'view' | 'edit';
  error?: string;
};

const getFormattedDate = (value?: Date) => {
  if (!value) return null;

  return moment(value).format('DD/MM/YYYY');
};

const StyledTextField = styled(TextField)<{ showDisabledInput: boolean }>`
  .Mui-disabled {
    color: ${(props) => (props.showDisabledInput ? 'black !important' : '')};
  }
`;
export const FormFieldInputDate = (props: FormFieldInputDateProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const isInputDisabled = isDatePickerOpen || props.disabled || props.mode === 'view';
  const startAdornment = <CalendarIcon style={{ width: 16, height: 16, marginRight: 4 }} />;
  return (
    <Grid container xs={12}>
      <StyledTextField
        showDisabledInput={props.disabled && props.mode === 'view'}
        value={getFormattedDate(props.value)}
        placeholder={props.placeholder}
        InputProps={{
          startAdornment,
        }}
        onFocus={() => setIsDatePickerOpen(true)}
        disabled={isInputDisabled}
        error={!!props.error}
        helperText={props.error}
        fullWidth
      />
      <Dialog open={isDatePickerOpen}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            open={true}
            variant="dialog"
            openTo="date"
            views={['date']}
            value={props.value || new Date()}
            onChange={(newDate) => {
              setIsDatePickerOpen(false);
              if (!newDate) return;
              props.onChange(newDate.toDate());
            }}
            onClose={() => setIsDatePickerOpen(false)}
          />
        </MuiPickersUtilsProvider>
      </Dialog>
    </Grid>
  );
};
