import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <ContentLoader speed={2} width={476} height={150} viewBox="0 0 476 150">
    <rect x="53" y="2" rx="3" ry="3" width="504" height="13" />
    <circle cx="20" cy="20" r="20" />
    <rect x="54" y="23" rx="3" ry="3" width="504" height="13" />
    <rect x="4" y="55" rx="3" ry="3" width="504" height="61" />
    <rect x="6" y="125" rx="13" ry="13" width="218" height="19" />
  </ContentLoader>
);
