import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import 'src/assets/styles/FormStyles.scss';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const resetPasswordEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a correct email format').nullable().required('Email is required!'),
});

// TODO: catch UserNotSuperAdminForOrganization error response

const ResetAccountPasswordForm = ({ handlePasswordReset, profile, mutation, passwordSuccess, data }) => (
  <Formik
    initialValues={{
      email: profile.email ? profile.email : '',
    }}
    validationSchema={resetPasswordEmailValidationSchema}
    onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
      try {
        await mutation({
          variables: {
            id: profile.id,
            email: values.email,
          },
        });
        handlePasswordReset(true);
        toast.success('Password has been reset!', {
          className: 'Toast-Container',
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.resetPassword,
          params: {
            user_id: profile.id,
          },
        });

        resetForm();
      } catch (e) {
        setSubmitting(false);
        console.error(e);
        toast.error('Error when reset password', {
          className: 'Toast-Container',
        });
        setFieldError(
          'email',
          'please check if the user has a valid email address and phone number; or you do not have sufficient privileges to reset user passwords',
        );
      }
    }}
    render={(props) => {
      const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
      const hasErrors = Object.keys(errors).length > 0;
      const isPasswordReseted =
        passwordSuccess && data && data.admin && data.admin.user && data.admin.user.resetPassword;
      return (
        <form onSubmit={handleSubmit} className="modalForm">
          {!isPasswordReseted ? (
            <TextField
              fullWidth
              id="email"
              name="email"
              placeholder="Enter email here"
              label="Email"
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              value={values.email}
              margin="normal"
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.email ? errors.email : ''}
              error={touched.email && Boolean(errors.email)}
            />
          ) : (
            <React.Fragment>
              <TextField
                fullWidth
                id="newpwd"
                name="newpwd"
                placeholder={data.admin.user.resetPassword || ''}
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                value={data.admin.user.resetPassword || ''}
                margin="normal"
                onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
              />
              <div className="copy-password">
                <span
                  onClick={() => {
                    (document.getElementById('newpwd') as HTMLInputElement).select();
                    document.execCommand('copy');
                    toast.success('New password copied', {
                      className: 'Toast-Container',
                    });
                  }}
                >
                  Copy Password
                </span>
              </div>
            </React.Fragment>
          )}
          {isSubmitting && <span className="modalForm__sendRequestLabel">sending request...</span>}
          {!isPasswordReseted && (
            <div className={`modalForm__buttonWrapper ${hasErrors || isSubmitting ? 'not-allowed' : ''}`}>
              <button type="submit" disabled={hasErrors || isSubmitting}>
                Send Password Reset
              </button>
            </div>
          )}
        </form>
      );
    }}
  />
);

export default ResetAccountPasswordForm;
