import { ProfileFieldVisibility } from 'src/types';
import { exhaustiveGuard } from 'src/utils/exhaustiveGuard';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';
import { BasicProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';
import { CreateProfileFieldInput, UpdateProfileFieldInput } from 'src/gql/v2/types/input';
import { CustomFieldsRepository } from 'src/pages/UserProfilePage/profile-layout/CustomFieldsRepository';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import { FetchUserProfileCustomFieldsQueryResult } from 'src/gql/v2/query/FetchProfileFieldsForUser';
import { FetchOrgProfileFieldsSchemaQueryResult } from 'src/gql/v2/query/FetchOrgProfileFieldsSchemaQuery';

type CPFFieldBase = {
  id: string;
  fieldId: string;
  label: string;
  labelOptions: string[];
  visibility: ProfileFieldVisibility;
  isSynced: boolean;
  isEditable: boolean;
  isRemovable: boolean;
  isRequired: boolean;
  validationRules: {
    regex: string | null;
  };
  isDirty?: boolean;
  isNew?: boolean;
  isMarkedForDeletion?: boolean;
};

type CPFPhoneField = CPFFieldBase & {
  fieldType: 'phone';
  phoneNumber: string;
  placeholder: string;
};

type CPFTextField = CPFFieldBase & {
  fieldType: 'text';
  text: string;
  placeholder: string;
};

type CPFDropdownField = CPFFieldBase & {
  fieldType: 'dropdown';
  options: string[];
  selectedOptions: string[];
};

type CPFNumericField = CPFFieldBase & {
  fieldType: 'number';
  value: string;
};

type CPFLinkField = CPFFieldBase & {
  fieldType: 'url';
  url: string;
};

type CPFEmailField = CPFFieldBase & {
  fieldType: 'email';
  email: string;
};

type CPFDateField = CPFFieldBase & {
  fieldType: 'date';
  date: string;
};

export type CPFField =
  | CPFPhoneField
  | CPFTextField
  | CPFDropdownField
  | CPFNumericField
  | CPFLinkField
  | CPFEmailField
  | CPFDateField;

export type CPFSection = {
  id: string;
  sectionId: string;
  label: string;
  labelOptions: string[];
  maxFields: number;
  fields: CPFField[];
};

export type CPFSchema = CPFSection[];

const getProfileFieldVisibility = (visibility: 'public' | 'private'): ProfileFieldVisibility => {
  switch (visibility) {
    case 'public':
      return ProfileFieldVisibility.PUBLIC;
    case 'private':
      return ProfileFieldVisibility.PRIVATE;
  }
};
const transformFieldResult = (field: ProfileFieldFragmentType & { id: string }): CPFField => {
  const baseField: BasicProfileFieldFragmentType = {
    ...field,
  };
  const commonFieldData = {
    id: field.id,
    visibility: getProfileFieldVisibility(field.visibility),
    isDirty: false,
    isNew: false,
  };
  switch (field.__typename) {
    case 'ProfilePhoneField':
      return {
        ...baseField,
        fieldType: 'phone',
        phoneNumber: field.phoneNumber || '',
        placeholder: field.placeholder || '',
        ...commonFieldData,
      };
    case 'ProfileDateField':
      return {
        ...baseField,
        fieldType: 'date',
        date: field.date || '',
        ...commonFieldData,
      };
    case 'ProfileLinkField':
      return {
        ...baseField,
        fieldType: 'url',
        url: field.url || '',
        ...commonFieldData,
      };
    case 'ProfileEmailField':
      return {
        ...baseField,
        fieldType: 'email',
        email: field.email || '',
        ...commonFieldData,
      };
    case 'ProfileNumericField':
      return {
        ...baseField,
        fieldType: 'number',
        value: field.value || '',
        ...commonFieldData,
      };
    case 'ProfileDropdownField':
      return {
        ...baseField,
        fieldType: 'dropdown',
        options: field.options,
        selectedOptions: field.selectedOptions ?? [],
        ...commonFieldData,
      };
    case 'ProfileTextField':
      return {
        ...baseField,
        fieldType: 'text',
        text: field.text || '',
        placeholder: field.placeholder || '',
        ...commonFieldData,
      };
  }
};

const transformUserProfileFieldsQueryData = (data: FetchUserProfileCustomFieldsQueryResult): CPFSection[] => {
  if (!data) return [];

  // TODO:: this is a stopgap until we do proper error handling at a graphql level
  if (!data.adminQuery.organizationalUnit.member.customFields) return [];

  // TODO:: this is a stopgap until we do proper error handling at a graphql level
  if (!data.adminQuery.organizationalUnit.member.customFields) return [];

  return data?.adminQuery?.organizationalUnit?.member?.customFields.map((section) => {
    return {
      ...section,
      fields: section.fields.map(transformFieldResult),
    };
  });
};

const transformProfileFieldsSchemaData = (data: FetchOrgProfileFieldsSchemaQueryResult): CPFSchema => {
  if (!data) return [];

  return data?.organizationalUnitQuery?.organizationalUnit?.profileTemplate?.template.map((section) => {
    return {
      ...section,
      fields: section.fields.map(transformFieldResult),
    };
  });
};

const getVisibilityInput = (visibility: ProfileFieldVisibility): CreateProfileFieldInput['visibility'] => {
  switch (visibility) {
    case ProfileFieldVisibility.PUBLIC:
      return 'public';
    case ProfileFieldVisibility.PRIVATE:
      return 'private';
    default:
      return exhaustiveGuard(visibility);
  }
};

const getDetails = (field: CPFField): CreateProfileFieldInput['details'] => {
  switch (field.fieldType) {
    case 'date':
      return { date: new Date(field.date) };
    case 'email':
      return { email: field.email };
    case 'text':
      return { text: field.text };
    case 'url':
      return { link: field.url };
    case 'phone':
      return { phoneNumber: field.phoneNumber };
    case 'number':
      return { value: field.value };
    case 'dropdown':
      return { selectedOptions: field.selectedOptions };
    default:
      return exhaustiveGuard(field);
  }
};

const getCreateProfileDetailsInput = (field: CPFField): CreateProfileFieldInput => {
  return {
    fieldId: field.fieldId,
    visibility: getVisibilityInput(field.visibility),
    label: field.label,
    details: getDetails(field),
  };
};

const getUpdateProfileFieldInput = (field: CPFField): UpdateProfileFieldInput => {
  return {
    label: field.label,
    visibility: getVisibilityInput(field.visibility),
    details: getDetails(field),
  };
};

const validatePhoneField = (field: CPFPhoneField): string[] => {
  const errors: string[] = [];
  // TODO: Implement phone number validation properly? Was not working correctly.

  if (field.validationRules?.regex) {
    const regexp = new RegExp(field.validationRules.regex);
    if (!regexp.test(field.phoneNumber)) {
      errors.push('Invalid');
    }
  }

  return errors;
};

const validateURLField = (field: CPFLinkField): string[] => {
  const errors: string[] = [];

  try {
    const validPrefixes = ['http://', 'https://'];
    const urlIncludesValidPrefix = validPrefixes.reduce((prev: boolean, current: string) => {
      return prev || field.url.startsWith(current);
    }, false);
    const stringToValidateAsURL = urlIncludesValidPrefix ? field.url : `https://${field.url}`;
    new URL(stringToValidateAsURL);
  } catch {
    errors.push('Invalid URL');
  }

  return errors;
};

const validateTextField = (field: CPFTextField): string[] => {
  const errors: string[] = [];

  if (!field.text) {
    errors.push('Field cannot be empty');
  }

  if (field.validationRules?.regex) {
    const regexp = new RegExp(field.validationRules.regex);
    if (!regexp.test(field.text)) {
      errors.push('Invalid');
    }
  }

  return errors;
};

const validateDateField = (field: CPFDateField): string[] => {
  const errors: string[] = [];

  if (!field.date) {
    errors.push('Field cannot be empty');
  }

  return errors;
};

const validateNumberField = (field: CPFNumericField): string[] => {
  const errors: string[] = [];

  if (!field.value) {
    errors.push('Field cannot be empty');
  }

  if (field.validationRules?.regex) {
    const regexp = new RegExp(field.validationRules.regex);
    if (!regexp.test(field.value)) {
      errors.push('Invalid');
    }
  }

  return errors;
};

const validateDropdownField = (field: CPFDropdownField): string[] => {
  const errors: string[] = [];
  return errors;
};

const validateEmailField = (field: CPFEmailField): string[] => {
  const errors: string[] = [];
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (!emailRegex.test(field.email)) {
    errors.push('Invalid Email');
  }

  if (field.validationRules?.regex) {
    const regexp = new RegExp(field.validationRules.regex);
    if (!regexp.test(field.email)) {
      errors.push('Invalid');
    }
  }

  return errors;
};

const validateField = (field: CPFField): string[] => {
  if (field.isMarkedForDeletion) {
    return [];
  }
  switch (field.fieldType) {
    case 'phone':
      return validatePhoneField(field);
    case 'url':
      return validateURLField(field);
    case 'text':
      return validateTextField(field);
    case 'date':
      return validateDateField(field);
    case 'number':
      return validateNumberField(field);
    case 'dropdown':
      return validateDropdownField(field);
    case 'email':
      return validateEmailField(field);
    default:
      return exhaustiveGuard(field);
  }
};

export const CustomFieldsViewModel = () => {
  const repo = CustomFieldsRepository();
  const fetchUserProfileFieldsQuery = (userId: string) => {
    const result = repo.useFetchUserProfileFields(userId);

    return {
      data: transformUserProfileFieldsQueryData(result.data),
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };
  const fetchProfileFieldsSchemaQuery = () => {
    const result = repo.useFetchProfileFieldsSchema();

    return {
      data: transformProfileFieldsSchemaData(result.data),
      loading: result.loading,
      error: result.error,
    };
  };
  const createProfileFieldsSectionMutation = repo.useCreateProfileSection;
  const createProfileFieldsMutation = () => {
    const result = repo.useCreateProfileFields();

    return {
      createProfileFields: (sectionId: string, fields: CPFField[], userId: string) => {
        const org = checkOrganizationalUnit();
        if (org.type === 'error') {
          return;
        }
        return result.createProfileFields({
          variables: {
            organizationalUnit: org,
            userId,
            uniqueSectionId: sectionId,
            fields: fields.map(getCreateProfileDetailsInput),
          },
        });
      },
      loading: result.loading,
      error: result.error,
    };
  };
  const updateProfileFieldMutation = () => {
    const result = repo.useUpdateProfileField();
    return {
      updateProfileField: (field: CPFField, userId: string) => {
        const org = checkOrganizationalUnit();
        if (org.type === 'error') {
          return;
        }
        return result.updateProfileField({
          variables: {
            organizationalUnit: org,
            userId,
            uniqueFieldId: field.id,
            fieldData: getUpdateProfileFieldInput(field),
          },
        });
      },
      loading: result.loading,
      error: result.error,
    };
  };
  const deleteProfileFieldMutation = () => {
    const result = repo.useDeleteProfileField();
    return {
      deleteProfileField: (field: CPFField, userId: string) => {
        const org = checkOrganizationalUnit();
        if (org.type === 'error') {
          return;
        }
        return result.deleteProfileField({
          variables: {
            organizationalUnit: org,
            userId,
            uniqueFieldId: field.id,
          },
        });
      },
      loading: result.loading,
      error: result.error,
    };
  };

  return {
    fetchUserProfileFieldsQuery,
    fetchProfileFieldsSchemaQuery,
    createProfileFieldsSectionMutation,
    createProfileFieldsMutation,
    updateProfileFieldMutation,
    deleteProfileFieldMutation,
    validateField,
  };
};
