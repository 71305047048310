import React from 'react';
import AccountManagementHeader from 'src/pages/HomePage/views/AccountManagementHeader';
import Loader from 'src/components/loaders/HomeLayoutLoader';
import { useQuery } from 'react-apollo';
import { GET_USERS_WITH_LICENSE } from 'src/gql/query/GetUsersQuery';
import { GET_PENDING_INVITES } from 'src/gql/query/FetchInvitesPendingAdminApproval';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { GetAllLicensingUsersResult, User, UserMenuOptionStatus, GetPendingUsersResult } from 'src/types';
import { typedUseSelector } from 'src/redux/store';
import TopPanel from 'src/pages/HomePage/views/TopPanel';
import UserLayoutContainer from 'src/pages/HomePage/account-management/UserLayoutContainer';
import { PENDING_ADMIN, PENDING_USER } from 'src/constants/inviteUserTypes';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import store from 'src/redux/store';
import DirSyncViewModel from '../viewModels/DirSyncViewModel';
import UsersListLoader from 'src/components/loaders/UsersListLoader';
import 'src/assets/styles/AccountStyles.scss';

const AccountManagementContainer = () => {
  const { organization_id, site_id, department_id } = store.getState().organizationReducer;
  const adminCoSignInvites = IsFeatureFlagEnabled('adminCoSignInvites') && site_id === null && department_id === null;

  const [menuOptions, setMenuOptions] = React.useState<UserMenuOptionStatus>('licensed');

  const isWaitingSwitchOrganization = typedUseSelector((state) => state.flagReducer.isWaitingSwitchOrganization);

  const viewModel = DirSyncViewModel();

  const { data: orgDirSyncStatus } = viewModel.fetchOrgDirSyncStatus();

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.accountManagementView);
  }, []);

  const pendingRecords = useQuery<GetPendingUsersResult>(GET_PENDING_INVITES, {
    variables: {
      organizationId: organization_id,
      status: [PENDING_ADMIN, PENDING_USER],
    },
    skip: !adminCoSignInvites,
  });

  const userRecords = useQuery<GetAllLicensingUsersResult>(GET_USERS_WITH_LICENSE);
  const { data: members, loading: membersLoading, refetch: refetchMembers } = viewModel.fetchMembers();

  React.useEffect(() => {
    adminCoSignInvites && menuOptions === 'pending' && pendingRecords.refetch();
    !adminCoSignInvites && menuOptions === 'pending' && setMenuOptions('licensed');
  }, [adminCoSignInvites]);

  if (userRecords.loading || isWaitingSwitchOrganization || pendingRecords.loading) return <Loader />;

  if (userRecords.error || pendingRecords.error) {
    return (
      <div className="networkErrorHolder">
        <span>An Error Occurred, Please Check Your Internet Connection And Try To Refresh The Page.</span>
        <p>If The Problem Persists, Please Let Us Know By Contacting Hypercare Support.</p>
      </div>
    );
  }

  const usersData = userRecords?.data;
  const LicensedMembers: User[] = members;
  const users: User[] = usersData?.admin?.users;
  const blackListedUsers = usersData?.admin ? usersData.admin.blacklistedUsers : [];
  const pendingUsers = adminCoSignInvites
    ? pendingRecords?.data?.locating?.organization?.userInvites
    : usersData.admin
    ? usersData.admin.pendingUsers
    : [];
  const totalUsers = [...users, ...blackListedUsers, ...pendingUsers];

  const handleAddUserSuccess = () => {
    userRecords.refetch();
    refetchMembers();
  };

  return (
    <section className="accountSection">
      <TopPanel
        allUsers={totalUsers}
        setMenuOptions={setMenuOptions}
        userRecords={userRecords}
        pendingRecords={pendingRecords}
        orgDirSyncStatus={orgDirSyncStatus}
        onAddUserSuccess={handleAddUserSuccess}
      />
      <AccountManagementHeader
        usersCount={users.length}
        blackListedUsersCount={blackListedUsers.length}
        pendingUsersCount={pendingUsers.length}
        currentMenuOption={menuOptions}
        setMenuOptions={setMenuOptions}
        adminCoSignInvites={adminCoSignInvites}
      />
      {membersLoading ? (
        <UsersListLoader />
      ) : (
        LicensedMembers && (
          <UserLayoutContainer
            menuOptions={menuOptions}
            usersData={usersData}
            LicensedMembers={LicensedMembers}
            pendingRecords={pendingRecords}
            adminCoSignInvites={adminCoSignInvites}
          />
        )
      )}
    </section>
  );
};

export default AccountManagementContainer;
