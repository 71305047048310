import { Button, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CachedIcon from '@material-ui/icons/Cached';
import { typedUseSelector } from 'src/redux/store';
import moment from 'moment';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const RefreshButton: React.FC = () => {
  const theme = useTheme();
  const [, setNow] = useState(Date.now());
  const dispatch = useDispatch();
  const updatedOn = typedUseSelector((state) => state.analyticsReducer.updatedOn);
  const refetch = (date) => dispatch(allActions.analyticsAction.refetch(date));

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);
    return function clean() {
      clearInterval(interval);
    };
  }, []);

  const handleRefresh = () => {
    refetch(new Date());
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.refreshAnalyticReports,
      params: {
        start_date: moment().startOf('day').toDate().toString(),
        end_date: moment().endOf('day').toDate().toString(),
      },
    });
  };

  return (
    <Button
      startIcon={<CachedIcon color="disabled" />}
      style={{ textTransform: 'none', color: theme.palette.grey[700], marginLeft: theme.spacing() }}
      onClick={handleRefresh}
    >
      Updated {moment(updatedOn).fromNow()}. Click to refresh
    </Button>
  );
};

export default RefreshButton;
