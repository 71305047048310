import axios from 'axios';
import * as qs from 'qs';
import client from 'src/clients/apolloClient';
import axiosConfig from 'src/api/AxiosConfig';
import AcceptOrganizationTOSMutation from 'src/gql/mutation/AcceptOrganizationTOSMutation';
import { AuthHelper } from 'src/auth';
import { getRefreshToken, updateLocalStorageTokenInfo } from 'src/utils/getLocalAuth';
import { AuthSSOVendors, LoginResponse, UserGeoData } from 'src/types';
import { FETCH_SSO_PROFILE } from 'src/gql/v2/query/FetchSSOProfile';
import FETCH_SSO_PROFILE_FOR_USER from 'src/gql/v2/query/FetchSSOProfilesForUser';
import { v4 as uuid } from 'uuid';
import { print } from 'graphql';
import getApiEndpoint from 'src/utils/getApiEndpoint';
import { getParsedAuthRegion } from '../utils/localStorageHelper';
import { CANADA_REGION_CODE, EUROPE_REGION_CODE, UNITED_STATES_REGION_CODE } from '../constants/strings';
import { GEOGRAPHICAL_API_ERROR, UNKNOWN_ERROR_STRING } from '../constants/networkError';

const HYPERCARE_AUTH_TOKEN = 'Basic ZGVtb2NsaWVudDpkZW1vY2xpZW50c2VjcmV0';
const GEOGRAPHICAL_API_URL = 'https://ipapi.co/json/';

axiosConfig();

class ApiHelper {
  public static getV2PublicEndpoint() {
    return `${getApiEndpoint()}/v2/graphql/public`;
  }

  public static getOAuthURL() {
    const region = getParsedAuthRegion();
    let endPointUrl = '';

    switch (region) {
      case CANADA_REGION_CODE:
        endPointUrl = process.env.REACT_APP_AUTH_URL_CA;
        break;
      case UNITED_STATES_REGION_CODE:
        endPointUrl = process.env.REACT_APP_AUTH_URL_US;
        break;
      case EUROPE_REGION_CODE:
        endPointUrl = process.env.REACT_APP_API_URL_EU;
        break;
      default:
        endPointUrl = process.env.REACT_APP_AUTH_URL_CA;
        break;
    }

    return `${endPointUrl}/oauth/v1`;
  }

  public static getAuthTokenEndpoint() {
    return `${getApiEndpoint()}/authenticate/auth0/adfs/token`;
  }

  private static getCommonHeaders() {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Request-ID': uuid(),
    };
  }
  public static fetchAuthToken = async (payload: { username: string; password: string }) => {
    try {
      const endpoint = `${getApiEndpoint()}/oauth/token`;
      const headerOptions = {
        headers: {
          Authorization: HYPERCARE_AUTH_TOKEN,
        },
      };
      const params = {
        ...payload,
        grant_type: 'password',
      };
      const result = await axios.post(endpoint, qs.stringify(params), headerOptions);
      return {
        data: result.data.response,
        success: true,
      };
    } catch (e) {
      console.error(e);
      AuthHelper.clearLocalStorage(payload.username);
      return {
        success: false,
        error: e?.response?.data?.errors?.[0] ?? UNKNOWN_ERROR_STRING,
      };
    }
  };

  public static refreshAccessToken = async () => {
    const endpoint = `${getApiEndpoint()}/oauth/token`;
    const headerOptions = {
      headers: {
        Authorization: HYPERCARE_AUTH_TOKEN,
      },
    };
    const params = {
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken(),
    };

    try {
      const result = await axios.post(endpoint, qs.stringify(params), headerOptions);
      const authInfo = result.data.response || result.data;
      const resultResponse: LoginResponse = {
        data: authInfo,
        success: true,
      };
      updateLocalStorageTokenInfo(resultResponse.data);
      return Promise.resolve(resultResponse);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  public static getCodeGrantRequestData(token: string, provider: AuthSSOVendors) {
    const requestData = qs.stringify({
      code: token,
      grant_type: 'authorization_code',
      client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
      redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
      provider,
    });

    const config = {
      headers: this.getCommonHeaders(),
    };

    return {
      requestData,
      config,
    };
  }

  public static getAuth0RequestData(token: string) {
    const requestData = qs.stringify({
      token,
      grant_type: 'auth0_sso_grant',
    });

    const config = {
      headers: {
        Authorization: HYPERCARE_AUTH_TOKEN,
        ...this.getCommonHeaders(),
      },
    };

    return {
      requestData,
      config,
    };
  }

  public static async fetchSSOAccessToken(token: string, provider: AuthSSOVendors) {
    try {
      const { requestData, config } =
        provider === AuthSSOVendors.WORKOS
          ? this.getCodeGrantRequestData(token, provider)
          : this.getAuth0RequestData(token);

      const result = await axios.post(this.getAuthTokenEndpoint(), requestData, config);
      const authInfo = result.data.response || result.data;
      return {
        success: true,
        data: authInfo,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async acceptingTOS(organizationId: number) {
    try {
      const result = await client.mutate({
        mutation: AcceptOrganizationTOSMutation,
        variables: {
          organizationId,
        },
      });
      const { data } = result;

      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async fetchSSOProfiles() {
    try {
      const result = await axios({
        url: this.getV2PublicEndpoint(),
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          query: print(FETCH_SSO_PROFILE),
        },
      });
      const { data } = result.data;
      return {
        success: true,
        data,
        error: result.data.errors,
      };
    } catch (error) {
      throw error;
    }
  }
  public static async getSSODomainsForUsername(email: string) {
    try {
      const result = await axios({
        url: this.getV2PublicEndpoint(),
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          query: print(FETCH_SSO_PROFILE_FOR_USER),
          variables: { email },
        },
      });
      const { data } = result.data;
      return {
        success: true,
        data,
        error: result.data.errors,
      };
    } catch (error) {
      throw error;
    }
  }
  public static async getSSOAuthorizationUrl(connectionId: string, provider: AuthSSOVendors) {
    const queryParams = {
      response_type: 'code',
      client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
      redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
      connection_id: connectionId,
      provider,
    };

    const queryString = qs.stringify(queryParams);

    const url = `${this.getOAuthURL()}/sso/web/authorize?${queryString}`;

    try {
      const result = await fetch(url, { method: 'GET' });
      const data = await result.json();
      window.location.href = data.url;
    } catch (e) {
      console.error(e);
      return {
        data: false,
        error: e,
      };
    }
  }

  public static async fetchUserGeographicalLocation() {
    try {
      const result = await fetch(GEOGRAPHICAL_API_URL);
      const res = await result.json();
      return {
        data: res as UserGeoData,
        error: false,
      };
    } catch (error) {
      console.error(GEOGRAPHICAL_API_ERROR);
      return {
        data: null,
        error,
      };
    }
  }
}

export default ApiHelper;
