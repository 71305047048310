import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import HiddenNotesInfo from 'src/components/hidden-notes/HiddenNotesInfo';
import { ADD, HN_NETWORK_ERROR, PROFILE } from 'src/constants/hiddenNotes';
import NoteForm from 'src/components/hidden-notes/NoteForm';
import NoteList from 'src/components/hidden-notes/NoteList';
import HiddenNotesAdminViewModel from 'src/components/hidden-notes/HiddenNotesAdminViewModel';
import HiddenNotesSelfViewModel from 'src/components/hidden-notes/HiddenNotesSelfViewModel';

import { getSelfInfo } from 'src/utils/getLocalAuth';
import { useParams } from 'react-router-dom';
import HiddenNotesLoader from 'src/components/loaders/HiddenNotesLoader';

const HiddenNotesContainer = styled.section`
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 5%;
  padding-right: 5%;
`;

const HiddenNotesInnerContainer = styled.div`
  background: ${theme.white};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  min-height: 13rem;
  display: flex;
`;

const HiddenNotesListGridContainer = styled.div`
  width: 100%;
  padding-top: 34px;
  margin-left: 4%;
`;

const ProfileHiddenNotes = ({ user }) => {
  const [isAddNotesActive, setIsAddNotesActive] = React.useState<boolean>(false);
  const [editableNoteId, setEditableNoteId] = React.useState(null);
  const [isDiscard, setIsDiscard] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(null);
  const [selectedNoteId, setSelectedNoteId] = React.useState(null);
  const [isDeleteModalOpen, setDeleteModal] = React.useState(false);

  const getUserInfo = getSelfInfo();
  const loggedInUserId = getUserInfo?.id;
  const { userId } = useParams<{ userId: string }>();
  const isSelf = userId === loggedInUserId;

  const { notes, error, loading, handleDeleteProfileNote, handleCreateProfileNotes, handleUpdateProfileNote } = isSelf
    ? HiddenNotesSelfViewModel()
    : HiddenNotesAdminViewModel(userId);

  return (
    <>
      <HiddenNotesContainer>
        <HiddenNotesInnerContainer>
          <HiddenNotesInfo />
          <HiddenNotesListGridContainer>
            {error && (
              <div className="networkErrorHolder">
                <span>{HN_NETWORK_ERROR}</span>
              </div>
            )}
            {loading && <HiddenNotesLoader />}
            {isAddNotesActive && !editableNoteId && (
              <NoteForm
                page={PROFILE}
                action={ADD}
                handleCancelledNote={() => setIsDiscard(true)}
                submitForm={handleCreateProfileNotes}
                handleResetInputs={() => setIsAddNotesActive(false)}
              />
            )}
            {notes && (
              <NoteList
                notes={notes}
                isDiscard={isDiscard}
                setIsDiscard={setIsDiscard}
                page={PROFILE}
                isAddNotesActive={isAddNotesActive}
                setIsAddNotesActive={setIsAddNotesActive}
                submitForm={handleUpdateProfileNote}
                selectedNote={selectedNote}
                setSelectedNote={setSelectedNote}
                selectedNoteId={selectedNoteId}
                setSelectedNoteId={setSelectedNoteId}
                editableNoteId={editableNoteId}
                setEditableNoteId={setEditableNoteId}
                isDeleteModalOpen={isDeleteModalOpen}
                setDeleteModal={setDeleteModal}
                deleteNote={handleDeleteProfileNote}
              />
            )}
          </HiddenNotesListGridContainer>
        </HiddenNotesInnerContainer>
      </HiddenNotesContainer>
    </>
  );
};

export default ProfileHiddenNotes;
