import React from 'react';
import styled from 'styled-components';
import {
  GO_TO_CONFIGURE,
  REACTIVATE,
  PopUpDescriptionMapping,
  PopUpMappingHeading,
  VirtualPagerStatusMap,
} from 'src/constants/virtualPagerStrings';
import StatusPill from './StatusPill';
import Popup from 'reactjs-popup';
import theme from 'src/assets/styles/theme';
import { PopupPosition } from 'reactjs-popup/dist/types';

import { HCHeadingFour, HCLabelTwo, HCTextContext } from 'src/components/shared/HypercareComponents';
import { VirtualPagerStatusTypes } from 'src/types';

const PopUpActionButton = styled.div`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
`;

const PopupContainer = styled.div<{ status: VirtualPagerStatusTypes }>`
  position: relative;
  border-radius: 8px;
  background: var(--White, #fff);
  ${(props) =>
    props.status === VirtualPagerStatusTypes.PENDING
      ? 'border-top: 6px solid #ffe382;'
      : props.status === VirtualPagerStatusTypes.ACTIVE
      ? 'border-top: 6px solid #00859A;'
      : 'border-top: 6px solid #BCBCBC;'}
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 18px 28px 0px rgba(9, 30, 66, 0.15);
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

type Props = {
  pagerStatus: VirtualPagerStatusTypes;
  popupPosition: PopupPosition;
  pagerAction: () => void;
};
export default function PagerStatusPopUp({ pagerStatus, popupPosition, pagerAction }: Props) {
  return (
    <Popup
      on="hover"
      arrow={true}
      closeOnDocumentClick
      position={popupPosition}
      trigger={<StatusPill status={pagerStatus}>{VirtualPagerStatusMap[pagerStatus]}</StatusPill>}
    >
      <PopupContainer status={pagerStatus}>
        <div
          style={{
            width: 'min-content',
            minWidth: '200px',
            gap: '0.4rem',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          {pagerStatus === VirtualPagerStatusTypes.DISABLED ? (
            <HCTextContext style={{ width: 'max-content' }}>{PopUpMappingHeading(pagerStatus)}</HCTextContext>
          ) : (
            <HCTextContext style={{ width: 'max-content' }}>{PopUpMappingHeading(pagerStatus)}</HCTextContext>
          )}
          <HCLabelTwo style={{ width: '100%' }}>{PopUpDescriptionMapping(pagerStatus)}</HCLabelTwo>
        </div>
        {pagerStatus === VirtualPagerStatusTypes.PENDING ? (
          <PopUpActionButton onClick={pagerAction} style={{ cursor: 'pointer' }}>
            <HCHeadingFour fontWeight={700} color={theme.mainTealColor}>
              {GO_TO_CONFIGURE}
            </HCHeadingFour>
          </PopUpActionButton>
        ) : pagerStatus === VirtualPagerStatusTypes.DISABLED ? (
          <PopUpActionButton onClick={pagerAction} style={{ cursor: 'pointer' }}>
            <HCHeadingFour fontWeight={700} color={theme.mainTealColor}>
              {REACTIVATE}
            </HCHeadingFour>
          </PopUpActionButton>
        ) : null}
      </PopupContainer>
    </Popup>
  );
}
