import gql from 'graphql-tag';

// TODO: channels differentiation

export default gql`
  query fetchEscalationPoliciesForOrganization($organizationId: Int!) {
    locating {
      organization(id: $organizationId) {
        escalationPolicies(type: department) {
          id
          name
          channels {
            type
            numAttempts
            timeBetweenAttempts
          }
        }
      }
    }
  }
`;
