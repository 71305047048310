import gql from 'graphql-tag';

export const OrganizationUserResultFields = gql`
  fragment OrganizationUserResultFields on OrganizationUserResult {
    __typename
    ... on Node {
      id
    }
    ... on OrganizationMember {
      username
      firstName
      lastName
      role
      avatar {
        url
      }
    }
    ... on PublicUser {
      username
      firstName
      lastName
      avatar {
        url
      }
    }
  }
`;
