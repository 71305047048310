import gql from 'graphql-tag';

export const FETCH_DEPARTMENT_ROLES_NOTES = gql`
  query roleNotes($organizationalUnit: OrganizationalUnitInput!, $scheduleId: Int!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          message
        }
        ... on Department {
          scheduling {
            schedule(id: $scheduleId) {
              roles {
                ...RoleResultFields
              }
            }
          }
        }
      }
    }
  }

  fragment RoleResultFields on Role {
    id
    name
    notes {
      ...RoleNoteFields
    }
  }

  fragment RoleNoteFields on RoleNote {
    id
    note
    createdBy {
      __typename
      ...OrganizationUserResultFields
    }
    editedBy {
      __typename
      ...OrganizationUserResultFields
    }
    access
    createdAt
    updatedAt
  }

  fragment OrganizationUserResultFields on OrganizationUserResult {
    __typename
    ... on Node {
      id
    }
    ... on OrganizationMember {
      ...NamePartsUserFields
      role
    }
    ... on PublicUser {
      ...NamePartsUserFields
    }
  }

  fragment NamePartsUserFields on GeneralUser {
    username
    firstName
    lastName
    avatar {
      url
    }
  }
`;
