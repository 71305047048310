import * as React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
  & .tooltip {
    max-width: 164px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif !important;
    background-color: #00859a !important;
  }
  & .MuiTooltip-arrow {
    color: #00859a !important;
  }
`;

export const StyledTooltipBlack = styled((props) => (
  <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
  & .tooltip {
    max-width: 279px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    font-family: 'Open Sans', sans-serif !important;
    background-color: #4a4a4a !important;
    background: #4a4a4a !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16) !important;
    border-radius: 4px !important;
  }
  & .MuiTooltip-arrow {
    color: #00859a !important;
  }
`;

export const StyledTooltipWhite = styled((props) => (
  <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
  & .tooltip {
    max-width: 253px;
    padding: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-family: Open Sans !important;
    background-color: #fff !important;
    background: #fff !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16) !important;
    border-radius: 8px !important;
    color: black;
  }
  & .MuiTooltip-arrow {
    color: white !important;
  }
`;

export default StyledTooltip;
