import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from 'src/redux/reducers';
import { saveState, loadState } from 'src/utils/persistState';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const persistedState = loadState();

const composeEnhancers =
  // @ts-ignore
  process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const noLogger = (store) => (next) => (action) => next(action);

const store = createStore(
  reducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk, process.env.NODE_ENV === 'production' ? noLogger : logger)),
);

store.subscribe(() => {
  saveState({
    organizationReducer: store.getState().organizationReducer,
  });
});

export type RootState = ReturnType<typeof reducer>;

export const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
