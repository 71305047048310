import React from 'react';
import styled from 'styled-components';

import { SESSION_TIMEOUT_LOGINPAGE_MODAL } from 'src/constants/sessionTimeoutTypes';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DialogContent, DialogContentText, Divider } from '@material-ui/core';
import { DialogBoxTitle, PrimaryButton } from 'src/components/shared/HypercareComponents';
import { StyledModal } from 'src/components/modals/ModalStyleComponents';

interface Props {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      padding: '0px',
    },
    dialogContent: {
      marginTop: '12px',
      padding: '0 !important',
    },
    dialogDescription: {
      fontFamily: 'open sans !important',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px !important',
      lineHeight: '24px !important',
      color: '#222222 !important',
    },
    divider: {
      border: 'none',
      marginTop: '16px !important',
      marginLeft: '-24px',
      marginRight: '-24px',
    },
    button: {
      width: '117px !important',
      padding: '8px 12px !important',
      border: '1px solid #D8D8D8 !important',
      borderRadius: '4px',
    },
    span: {
      fontFamily: 'Nunito Sans !important',
      fontStyle: 'normal !important',
      fontWeight: 600,
      fontSize: '17px !important',
      lineHeight: '24px !important',
      display: 'flex !important',
      alignItems: 'center !important',
    },
  }),
);

const FlexContainer = styled.div`
  display: flex;
  margin-top: 1.5em;
  justify-content: flex-end;
  gap: 8px;
`;

const LogOutModal = ({ isOpen, setIsOpen, timeoutValue }) => {
  const classes = useStyles();

  const handleModalClose = () => {
    sessionStorage.setItem(SESSION_TIMEOUT_LOGINPAGE_MODAL, 'false');
    setIsOpen(false);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onRequestClose={null}
    >
      <DialogBoxTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {'Session expired'}
      </DialogBoxTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogDescription} id="alert-dialog-description">
          You have been signed out for security purposes because
          <br />
          you were idle for {timeoutValue / 60000} minutes.
        </DialogContentText>
      </DialogContent>
      <Divider className={classes.divider} />
      <FlexContainer style={{ marginTop: '16px' }}>
        <PrimaryButton onClick={handleModalClose}>
          <span className={classes.span}>OK</span>
        </PrimaryButton>
      </FlexContainer>
    </StyledModal>
  );
};

export const LogOutModalWrapper = React.memo(LogOutModal);
