import gql from 'graphql-tag';

export default gql`
  query FetchDepartmentEquityReport($siteId: Int!, $departmentIds: [Int!]!, $startDate: String!, $endDate: String!) {
    locating {
      site(id: $siteId) {
        equityReport(departmentIds: $departmentIds, startDate: $startDate, endDate: $endDate) {
          fileName
          mimeType
          blob(encoding: "base64")
        }
      }
    }
  }
`;
