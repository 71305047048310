import React from 'react';
import { useQuery } from 'react-apollo';
import moment from 'moment';
import styled from 'styled-components';
import AppTheme from 'src/assets/styles/theme';
import { CREATE } from 'src/constants/escalation';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from 'src/assets/svgs/SearchIcon';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import { FetchEscalationLadderPreviewForDepartment } from 'src/gql/query/FetchEscalationLadderForDepartment';
import store from 'src/redux/store';
import { EscalationLadder, FetchAllEscalationLadderForDepartmentsResult } from 'src/types';
import CircularProgress from '@material-ui/core/CircularProgress';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { AppRoutes } from '../../router/AppRoutes';

const SearchInputWrapper = styled.div`
  margin-bottom: 1em;

  .MuiInputBase-root {
    height: 44px;
    width: 550px;
  }

  .MuiChip-label {
    color: grey;
  }
`;

const EscalationLadderListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  margin-bottom: 0.5em;
`;

const BasePreviewBox = styled.div`
  width: 200px;
  height: 240px;
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  user-select: none;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(219, 219, 219, 0.25);

  &:hover {
    * {
      color: lightgrey;
    }
  }
`;

const EscalationPreview = styled(BasePreviewBox)`
  justify-content: space-around;
  border: 1px solid grey;
  align-items: center;
  text-align: center;
  padding: 0 1em;
  margin-bottom: 1em;
`;

const EscalationPreviewTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-break: anywhere;
`;

const EscalationPreviewUpdateAt = styled.div`
  font-size: 14px;
`;

const EscalationCreationPreview = styled(BasePreviewBox)`
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  background-color: ${AppTheme.mainTealColor};

  strong {
    text-align: center;
    font-size: 18px;
    color: white;
  }
`;

const AddIconHolder = styled.div`
  svg {
    width: 52px;
    height: 52px;
    color: white;
  }
`;

const EscalationLadderListContainer = () => {
  const [searchKeyword, setSearchKeyWord] = React.useState<string>('');
  const reduxOrganization = store.getState().organizationReducer;

  const {
    data: escalationData,
    loading,
    error,
  } = useQuery<FetchAllEscalationLadderForDepartmentsResult>(FetchEscalationLadderPreviewForDepartment, {
    variables: {
      departmentId: reduxOrganization.department_id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const existingEscalations: EscalationLadder[] = escalationData?.locating?.department?.escalationLadders;

  const filteredEscalations = React.useMemo(() => {
    if (!existingEscalations) return [];
    return existingEscalations.filter((previews) => previews.name.toLowerCase().includes(searchKeyword.toLowerCase()));
  }, [existingEscalations, searchKeyword]);

  const handleCreateEscalation = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.createNewEscalationButtonPressed,
    });
    window.routerHistory.push(`${AppRoutes.Escalation}/${CREATE}`);
  };

  return (
    <React.Fragment>
      <h2>Escalations</h2>

      <SearchInputWrapper>
        <TextField
          autoFocus
          variant="outlined"
          id="sourceSearch"
          name="sourceSearch"
          placeholder={`Search escalations`}
          value={searchKeyword}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchKeyword ? (
              <InputAdornment position="end">
                <Chip
                  size="small"
                  label="CLEAR"
                  clickable
                  onClick={() => setSearchKeyWord('')}
                  onDelete={() => setSearchKeyWord('')}
                />
              </InputAdornment>
            ) : null,
          }}
          onChange={(event) => setSearchKeyWord(event.target.value)}
        />
      </SearchInputWrapper>

      {loading || error ? (
        <CircularProgress />
      ) : (
        <EscalationLadderListWrapper>
          {filteredEscalations.map((escalationPreview) => (
            <EscalationPreview
              key={escalationPreview.id}
              onClick={() => window.routerHistory.push(`${AppRoutes.Escalation}/${escalationPreview.id}`)}
            >
              <EscalationPreviewTitle>{escalationPreview.name}</EscalationPreviewTitle>
              <EscalationPreviewUpdateAt>
                Valid from {moment(escalationPreview.validFrom).format('YYYY-MM-DD')}
              </EscalationPreviewUpdateAt>
            </EscalationPreview>
          ))}
          <EscalationCreationPreview onClick={() => handleCreateEscalation()}>
            <AddIconHolder>
              <AddIcon />
            </AddIconHolder>
            <strong>Create new escalation</strong>
          </EscalationCreationPreview>
        </EscalationLadderListWrapper>
      )}
    </React.Fragment>
  );
};

export default EscalationLadderListContainer;
