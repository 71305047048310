// custom
export const ALREADY_EXIST = 'User already exists';
export const USER_NOT_ADMIN = 'NOT_ADMIN';
export const USERNAME_ALREADY_EXIST = 'Username already exists';

// backend
export const USER_NOT_FOUND = 'UserNotFound';
export const ADDRESS_ALREADY_INUSE = 'AddressInUse';
export const BACKEND_ALREADY_EXIST = 'UserAlreadyExists';
export const INVALID_PHONE_NUMBER = 'InvalidPhoneNumber';
