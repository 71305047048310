import gql from 'graphql-tag';
import { ProfileNoteFields } from 'src/gql/v2/fragment/ProfileNoteFieldsFragment';

export default gql`
  query FetchProfileNotes($organizationalUnit: OrganizationalUnitInput!, $id: ID!) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationQuery {
            __typename
            member(id: $id) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ... on Node {
                id
              }
              ... on FullOrganizationMember {
                notes {
                  __typename
                  ...ProfileNoteFields
                }
              }
            }
          }
          ... on AdminSiteQuery {
            __typename
            member(id: $id) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ... on Node {
                id
              }
              ... on FullOrganizationMember {
                notes {
                  __typename
                  ...ProfileNoteFields
                }
              }
            }
          }
          ... on AdminDepartmentQuery {
            __typename
            member(id: $id) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ... on Node {
                id
              }
              ... on FullOrganizationMember {
                notes {
                  __typename
                  ...ProfileNoteFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${ProfileNoteFields}
`;
