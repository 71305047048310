import React from 'react';
import styled from 'styled-components';
import {
  WHITEBOX_1_TITLE,
  WHITEBOX_1_DESCRIPTION,
  WHITEBOX_2_TITLE,
  WHITEBOX_2_DESCRIPTION,
  WHITEBOX_3_TITLE,
  WHITEBOX_3_DESCRIPTION,
  TEALBLOX_TITLE,
  TEALBLOX_DESCRIPTION,
  LEARN_MORE,
  GET_STARTED,
} from 'src/constants/virtualPagerStrings';
import { HCHeadingThree, HCHeadingTwo, HCLargeBoxDescription } from 'src/components/shared/HypercareComponents';
import { StyledLink } from '../../components/shared';
import theme from 'src/assets/styles/theme';
import Header from '../../components/header';
import WhiteBox from './components/emptyStateWhiteBox';
import { VideoButton, GetNumbersButton, VisitSupportCenterButton } from '../../components/buttons';
import GetNumbersModal from '../../GetNumbersModal/GetNumbersModalCoordinator';

const MainContainer = styled.div`
  max-width: unset !important;
  padding-left: 5%;
  padding-right: 2.5%;
`;

const MainTealBlock = styled.div`
  background: #cce7eb80;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 240px;
  width: 100%;
  margin-bottom: 40px;
`;

const TextContent = styled.div`
  width: 775px;
  height: 94px;
  text-align: center;
`;

const WhiteBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

type Prop = {
  onPurchase: () => Promise<void>;
};

const NoNumbersView: React.FC<Prop> = ({ onPurchase }) => {
  const [isGetNumbersModalVisible, setIsGetNumbersModalVisible] = React.useState<boolean>(false);

  return (
    <MainContainer id="noPurchasedPagersPage">
      <Header
        buttonComponent={
          <GetNumbersButton
            isDisabled={false}
            handleOnClick={() => setIsGetNumbersModalVisible(true)}
            virtualPagerSectionState="Empty"
          />
        }
        hasNumbers={false}
      />
      <MainTealBlock>
        <TextContent>
          <HCHeadingTwo color={theme.mainFontColor}>{TEALBLOX_TITLE}</HCHeadingTwo>
          <HCLargeBoxDescription>
            {TEALBLOX_DESCRIPTION}
            <StyledLink
              href="https://www.hypercare.com/solution/pager-replacement-solutions-healthcare-professionals"
              target="_blank"
            >
              <span>{LEARN_MORE}</span>
            </StyledLink>
          </HCLargeBoxDescription>
        </TextContent>
      </MainTealBlock>
      <HCHeadingThree style={{ marginBottom: '15px' }} color={theme.black}>
        {GET_STARTED}
      </HCHeadingThree>
      <WhiteBoxContainer>
        <WhiteBox
          title={WHITEBOX_1_TITLE}
          description={WHITEBOX_1_DESCRIPTION}
          buttonComponent={<VideoButton isDisabled={false} />}
        />
        <WhiteBox
          title={WHITEBOX_2_TITLE}
          description={WHITEBOX_2_DESCRIPTION}
          buttonComponent={
            <GetNumbersButton
              isDisabled={false}
              handleOnClick={() => setIsGetNumbersModalVisible(true)}
              virtualPagerSectionState="Empty"
            />
          }
        />
        <WhiteBox
          title={WHITEBOX_3_TITLE}
          description={WHITEBOX_3_DESCRIPTION}
          buttonComponent={<VisitSupportCenterButton isDisabled={false} />}
        />
      </WhiteBoxContainer>
      <GetNumbersModal
        onPurchase={onPurchase}
        isOpen={isGetNumbersModalVisible}
        closeModal={() => setIsGetNumbersModalVisible(false)}
      />
    </MainContainer>
  );
};

export default NoNumbersView;
