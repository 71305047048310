import React, { useEffect, useState } from 'react';
import LoginLayout from 'src/pages/LoginPage/LoginLayout';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { LogOutModalWrapper } from 'src/components/modals/LogoutPageTimeoutModal';
import { IDLE_TIMEOUT, SESSION_TIMEOUT_LOGINPAGE_MODAL } from 'src/constants/sessionTimeoutTypes';

const LoginPage = () => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const idleTimeoutValue = window.sessionStorage.getItem(IDLE_TIMEOUT);

  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.loginView);
    const modalCloseValues = JSON.parse(window.sessionStorage.getItem(SESSION_TIMEOUT_LOGINPAGE_MODAL));
    const initialModalValue = modalCloseValues ? true : false;
    setIsOpen(initialModalValue);
  }, [isOpen]);

  return (
    <>
      {isOpen ? <LogOutModalWrapper isOpen={isOpen} setIsOpen={setIsOpen} timeoutValue={idleTimeoutValue} /> : null}
      <LoginLayout />
    </>
  );
};

export default LoginPage;
