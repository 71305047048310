import gql from 'graphql-tag';

export default gql`
  mutation setLicenseExpiryTime($userId: ID!, $licenseStart: String, $licenseEnd: String) {
    admin {
      user(id: $userId) {
        setLicenseExpiry(licenseStartTime: $licenseStart, licenseEndTime: $licenseEnd)
      }
    }
  }
`;
