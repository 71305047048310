import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { DANGER_ZONE } from 'src/constants/dirSyncConstants';

const DangerZoneInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  justify-content: flex-start;
  width: calc(100% - 67%);
  padding-top: 32px;
`;

const DangerZoneInfoHeading = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  margin-bottom: 8px;
  color: ${theme.darkenFontColor};
`;

const ProfileDangerZoneInfo = () => {
  return (
    <DangerZoneInfoContainer>
      <DangerZoneInfoHeading data-testid="danger-zone-container">{DANGER_ZONE}</DangerZoneInfoHeading>
    </DangerZoneInfoContainer>
  );
};

export default ProfileDangerZoneInfo;
