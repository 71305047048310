import React from 'react';
import {
  CONTACT_US,
  HYPERCARE_SUPPORT_CONTACT_LINK,
  NO_SEARCH_RESULT_FOUND_BODY,
  NO_SEARCH_RESULT_FOUND_BOTTOM_TEXT,
  NO_SEARCH_RESULT_FOUND_TITLE,
} from '../../../constants/strings';
import styled from 'styled-components';
import { HCBodySpan, HCHeadingThree, HCLabelOne } from '../../../components/shared/HypercareComponents';
import theme from '../../../assets/styles/theme';

const NoSearchResultFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
  gap: 8px;
`;

export const StyledAnchor = styled.a`
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #4a4a4a;
  text-decoration: none;
  float: right;
  span {
    cursor: pointer;
    padding-left: 4px;
    color: ${(props) => props.theme.blueBorder};
    &:hover {
      text-decoration: underline;
    }
  }
  line-height: 20px;
`;

export const NoSearchResultsFound = () => {
  return (
    <NoSearchResultFoundContainer>
      <HCHeadingThree color={theme.mainFontColor}>{NO_SEARCH_RESULT_FOUND_TITLE}</HCHeadingThree>
      <HCLabelOne color={theme.textLightTertiary}>{NO_SEARCH_RESULT_FOUND_BODY}</HCLabelOne>
      <div>
        <HCBodySpan fontSize={'13px'} fontWeight={400} color={theme.textLightTertiary}>
          {NO_SEARCH_RESULT_FOUND_BOTTOM_TEXT}{' '}
        </HCBodySpan>
        <StyledAnchor href={HYPERCARE_SUPPORT_CONTACT_LINK} rel="noopener noreferrer" target="_blank">
          <HCBodySpan fontSize={'13px'} fontWeight={400} color={theme.mainTealColor}>
            {CONTACT_US}
          </HCBodySpan>
        </StyledAnchor>
      </div>
    </NoSearchResultFoundContainer>
  );
};
