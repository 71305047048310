import gql from 'graphql-tag';
import { FullOrganizationMemberMutationFragmentSyncStatus } from 'src/gql/v2/fragment/FullOrganizationMemberMutationFragment';

export const ADMIN_UPDATE_MEMBER_SYNC_STATUS = gql`
  mutation updateProfile($organizationalUnit: OrganizationalUnitInput!, $userId: ID!, $syncStatus: Boolean!) {
    adminMutation {
      __typename
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on UserNotAdminForScope {
            __typename
            message
          }
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationMutation {
            __typename
            member(id: $userId) {
              __typename
              ...FullOrganizationMemberMutationFragment
            }
          }

          ... on AdminDepartmentMutation {
            __typename
            member(id: $userId) {
              __typename
              ...FullOrganizationMemberMutationFragment
            }
          }

          ... on AdminSiteMutation {
            __typename
            member(id: $userId) {
              __typename
              ...FullOrganizationMemberMutationFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationMemberMutationFragmentSyncStatus}
`;
