import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Avatar,
  Box,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useQuery } from 'react-apollo';
import { GET_MEMBERS } from 'src/gql/query/AnalyticsQuery';
import { IGetMemberResult, IMember } from 'src/types';
import { typedUseSelector } from 'src/redux/store';

type Order = 'asc' | 'desc' | undefined;

interface ISorting {
  [key: string]: Order;
}

const style = makeStyles((theme: Theme) => ({
  inputRoot: {
    backgroundColor: theme.palette.grey[100],
    fontSize: theme.typography.caption.fontSize,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
        borderColor: theme.palette.grey[300],
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
  },
  filterButton: {
    backgroundColor: theme.palette.grey[100],
    borderWidth: 1,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderRightWidth: 0,
    minWidth: theme.spacing(25),
    justifyContent: 'space-between',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    borderRadius: 0,
    marginTop: theme.spacing(0.5),
    padding: '7.5px 16px 7px 16px',
    color: theme.palette.grey[500],
  },
}));

const MemberTabPanel: React.FC = () => {
  const dateRange = typedUseSelector((state) => state.analyticsReducer.dateRange);
  const refreshOn = typedUseSelector((state) => state.analyticsReducer.refreshOn);
  const organizationId = typedUseSelector((state) => state.organizationReducer.organization_id);
  const departmentId = typedUseSelector((state) => state.organizationReducer.department_id);
  const siteId = typedUseSelector((state) => state.organizationReducer.site_id);
  const memberList = useQuery<IGetMemberResult>(GET_MEMBERS, {
    variables: {
      startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
      organizationId,
      departmentId,
      siteId,
    },
    fetchPolicy: 'no-cache',
  });
  const classes = style();
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState<IMember[]>([]);

  useEffect(() => {
    memberList.refetch();
    // eslint-disable-next-line
  }, [refreshOn]);

  useEffect(() => {
    if (!memberList.data) {
      setFilteredData([]);
      return;
    }
    if (!search) {
      setFilteredData(memberList.data.analytics.members);
    } else {
      const data = memberList.data.analytics.members.filter((x) => {
        if (x.firstname && x.firstname.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (x.lastname && x.lastname.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (x.username && x.username.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (x.role && x.role.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }

        return false;
      });
      setFilteredData(data);
    }
  }, [memberList.data, search]);

  if (memberList.loading) {
    return (
      <Box>
        <Typography>Loading..</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box flexDirection="row" alignItems="center" display="flex" marginBottom={2}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="disabled" />
              </InputAdornment>
            ),
          }}
          fullWidth
          placeholder="Search by member name, role title"
          variant="outlined"
          margin="dense"
          classes={{ root: classes.inputRoot }}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: 'rgb(250,250,250)' }}>
            <TableCell>All members {filteredData.length ? `(${filteredData.length})` : null}</TableCell>
            <TableCell>Role title</TableCell>
            <TableCell>Total messages sent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.length ? (
            filteredData.map((element) => (
              <TableRow key={element.id}>
                <TableCell>
                  <Box flexDirection="row" alignItems="center" display="flex">
                    <Avatar>
                      {element.firstname[0]}
                      {element.lastname[0]}
                    </Avatar>
                    <Box marginLeft={2}>
                      <Typography color="primary">
                        {element.firstname} {element.lastname}
                      </Typography>
                      <Typography variant="body2">@{element.username}</Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>{element.role}</TableCell>
                <TableCell>{element.messagesentcount}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Box height={theme.spacing(20)} justifyContent="center" display="flex" alignItems="center">
                  <Typography>No data available</Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MemberTabPanel;
