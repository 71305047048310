import React from 'react';
import moment from 'moment';
import ShiftAssignees from 'src/pages/SchedulingPage/scheduling-layout/scheduling-grid/ShiftAssignees';
import { MonthlyCalendar, Role, ScheduleModalPayload } from 'src/types';
import {
  GridBodyRow,
  GridBodyRowDate,
  RowDateHolder,
  BoldDate,
  GridBodyRowRole,
  RoleHolder,
  HiddenEditRoleHolder,
  RequiredShiftGapHolder,
  GapInfo,
  GapHoverOverlay,
  HolidayDataHolder,
} from 'src/pages/SchedulingPage/scheduling-layout/CalendarGridStyleSystem';
import { typedUseSelector } from 'src/redux/store';

interface Props {
  monthlyCalendar: MonthlyCalendar;
  flag: boolean;
  handleOpenScheduleModal: (args: ScheduleModalPayload) => void;
  isDepartment: boolean;
  gridBodyWidth: any;
  holidays: any;
}

const RoleBodyDetail = ({
  monthlyCalendar,
  gridBodyWidth,
  flag,
  handleOpenScheduleModal,
  isDepartment,
  holidays,
}: Props) => {
  const shouldShowGap = typedUseSelector((state) => state.monthlyScheduleReducer.shouldShowGap);

  // TODO: only show isRequired shift for now for all the scheduleState
  function onMouseUp(role: Role, date: string) {
    if (flag || !isDepartment) return;
    handleOpenScheduleModal({
      role,
      date,
      targetAssignee: null,
    });
  }

  function isHoliday(date, flag) {
    let month = date?.toString().split('-')[1];
    let holidaysthismonth = [];
    let isHolidayDate = null;
    holidays.map((holiday) => {
      if (holiday.date.split('-')[1] === month) {
        holidaysthismonth.push({ day: parseInt(holiday.date.split('-')[2]), holidayName: holiday.nameEn });
      }
      return;
    });
    holidaysthismonth.map((holiday) => {
      if (holiday.day === parseInt(date?.toString().split('-')[2])) {
        isHolidayDate = holiday;
      }
      return;
    });
    if (flag === 2) {
      if (isHolidayDate === null) return false;
      else return true;
    }
    if (flag) {
      return moment(date).weekday() === 6 || moment(date).weekday() === 0 || isHolidayDate !== null;
    }
    return isHolidayDate?.holidayName;
  }

  const RenderDefaultEditHolder = ({ role, date }: { role: Role; date: string }) => (
    <HiddenEditRoleHolder onMouseUp={() => onMouseUp(role, date)}>
      <div>
        <span>
          <i className="material-icons">edit</i>Edit Shift
        </span>
      </div>
    </HiddenEditRoleHolder>
  );

  return (
    <React.Fragment>
      {Object.keys(monthlyCalendar).map((date, index) => {
        const rolesPerDate = monthlyCalendar[date];
        return (
          <GridBodyRow key={index} style={{ width: gridBodyWidth }} isHoliday={isHoliday(date, 1)}>
            <GridBodyRowDate isHoliday={isHoliday(date, 1)}>
              <RowDateHolder>
                {moment(date, 'YYYY-MM-DD').format('dddd')}
                <br />
                <BoldDate>{moment(date, 'YYYY-MM-DD').format('MMM-DD')}</BoldDate>
                {isHoliday(date, 2) && (
                  <HolidayDataHolder title={isHoliday(date, 0)}>{isHoliday(date, 0)}</HolidayDataHolder>
                )}
              </RowDateHolder>
            </GridBodyRowDate>

            <GridBodyRowRole>
              {rolesPerDate.map((nestedPayload, index) => {
                const { role, assignee, scheduleGap } = nestedPayload;
                return (
                  <RoleHolder key={index}>
                    <ShiftAssignees role={role} date={date} isDepartment={isDepartment} assignees={assignee} />
                    {shouldShowGap &&
                    scheduleGap.length > 0 &&
                    scheduleGap[0].shouldRender &&
                    scheduleGap[0].isRequired ? (
                      scheduleGap.map((gap, i) => (
                        <RequiredShiftGapHolder
                          key={i}
                          isRequired={gap.isRequired}
                          shiftOrder={gap.startTime.format('HH:mm').replace(':', '')}
                          onMouseUp={() => onMouseUp(role, date)}
                        >
                          <GapInfo>
                            <span>{gap.isRequired && 'required'} shift empty</span>
                            <span>
                              {gap.startTime.format('HH:mm')} - {gap.endTime.format('HH:mm')}
                            </span>
                          </GapInfo>
                          <GapHoverOverlay>+ Add Shift</GapHoverOverlay>
                        </RequiredShiftGapHolder>
                      ))
                    ) : (
                      <RenderDefaultEditHolder role={role} date={date} />
                    )}
                  </RoleHolder>
                );
              })}
            </GridBodyRowRole>
          </GridBodyRow>
        );
      })}
    </React.Fragment>
  );
};

export default RoleBodyDetail;
