import { User } from 'src/types';
import { PaginatedInvites } from 'src/types/PaginatedTypes';

export function sortByInviteStatus(a: User, b: User) {
  if (a && b && a.updatedAt < b.updatedAt) {
    return 1;
  }
  if (a && b && a.updatedAt > b.updatedAt) {
    return -1;
  }
  return 0;
}

export function sortByPaginatedInviteStatus(a: PaginatedInvites, b: PaginatedInvites) {
  if (a && b && a.updatedAt < b.updatedAt) {
    return 1;
  }
  if (a && b && a.updatedAt > b.updatedAt) {
    return -1;
  }
  return 0;
}

export function ByPaginatedInviteStatus(a: PaginatedInvites, b: PaginatedInvites) {
  if (a && b && a.updatedAt < b.updatedAt) {
    return 1;
  }
  if (a && b && a.updatedAt > b.updatedAt) {
    return -1;
  }
  return 0;
}
