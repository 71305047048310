import gql from 'graphql-tag';
import { PaginatedInvites } from 'src/types/PaginatedTypes';

export type SearchPendingInvitesQueryResponse = {
  adminQuery: {
    organizationalUnit: {
      searchQuery: {
        searchPendingInvites: {
          continuationId: string;
          totalResultsCount: number;
          invites: PaginatedInvites[];
        };
      };
    };
  };
};

export const SEARCH_PENDING_INVITES_QUERY = gql`
  query SearchPendingInvites(
    $organizationalUnit: OrganizationalUnitInput!
    $text: String!
    $direction: PaginationDirection
    $continuationId: ID
    $limit: Int
    $inviteStatus: [UserInviteStatus!]
  ) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            searchQuery(text: $text) {
              searchPendingInvites(
                continuationId: $continuationId
                direction: $direction
                limit: $limit
                inviteStatus: $inviteStatus
              ) {
                continuationId
                totalResultsCount
                invites {
                  ...UserInviteFragment
                }
              }
            }
          }
        }
      }
    }
  }

  fragment UserInviteFragment on UserInvite {
    id
    user {
      ...PublicUserFragment
    }
    address {
      address
    }
    organization {
      id
    }
    invitedBy {
      ...PublicUserFragment
    }
    resolvedBy {
      ...PublicUserFragment
    }
    inviteStatus
    createdAt
    updatedAt
  }

  fragment PublicUserFragment on PublicUser {
    id
    firstname: firstName
    lastname: lastName
    username
  }
`;
