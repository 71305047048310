import React, { VFC } from 'react';
import { toast } from 'react-toastify';
import { StyledToastWrapper } from 'src/components/hidden-notes/SharedCompoment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface SuccessToastProps {
  title: string;
}

export const SuccessToast = ({ title }: SuccessToastProps) => {
  return (
    <StyledToastWrapper onClick={() => toast.dismiss()}>
      <CheckCircleIcon />
      {title}
    </StyledToastWrapper>
  );
};
