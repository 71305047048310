import React from 'react';
import styled from 'styled-components';
import EscalationOnCallDropZone from 'src/pages/EscalationPage/escalation-layout/drop-target/EscalationOnCallDropZone';
import EscalationPolicyDropZone from 'src/pages/EscalationPage/escalation-layout/drop-target/EscalationPolicyDropZone';
import { typedUseSelector } from 'src/redux/store';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import scrollingDetectionHelper from 'src/utils/escalationHelper/scrollingDetectionHelper';
import TrashIcon from 'src/assets/svgs/TrashIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { EscalationLevel } from 'src/types';
import {
  EscalationLadderWrapper,
  EscalationLevelWrapper,
  EscalationLevelArrow,
} from 'src/pages/EscalationPage/escalation-layout/SharedEscalationStyles';

const DeleteLevelButtonHolder = styled.div`
  display: none;
  border-radius: 50%;
  opacity: 0.6;
  background-color: lightgrey;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translate(-40%, -40%);
  transition: opacity 1s;
  svg {
    width: 22px;
    height: 22px;
  }
  &:hover {
    opacity: 1;
    background-color: red;
    svg {
      stroke: white;
    }
  }
`;

const CustomizedEscalationLevelWrapper = styled(EscalationLevelWrapper)`
  padding: 0.5em 2em !important;
  &:hover {
    border: 1px dashed lightgray;
    ${DeleteLevelButtonHolder} {
      display: block;
    }
  }
`;

const FilteredEscalationLevelArrow = styled(EscalationLevelArrow)`
  opacity: 0.1;
`;

/**
 * Note in escalation ladder to show arrow and next drop placeholder:
 * - an escalation level must be attached with a policy
 * - an escalation level must have at least one role / user that is on call
 */

const LEVEL_TOOLTIPS =
  'Great job setting up your escalation! You can add another escalation level in case the first-level responder couldn’t be reached.';

interface Props {}

const EscalationDropzoneContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const deleteEscalationLevel = (index: number) => dispatch(allActions.escalationAction.deleteEscalationLevel(index));
  const isToolTipShownRef = React.useRef(false);

  React.useEffect(() => {
    scrollingDetectionHelper.addEventListenerForSidebar();
    return () => {
      scrollingDetectionHelper.removeEventListenerForSidebar();
    };
  }, []);

  const escalationLadder = typedUseSelector((state) => state.escalationReducer.escalationLadder);
  const escalationLadderList = escalationLadder.toArray();

  React.useEffect(() => {
    if (isToolTipShownRef.current) return;
    const toolTipEscalationLevel = escalationLadder.get(1);
    if (!toolTipEscalationLevel) return;
    // only show tooltip once
    if (toolTipEscalationLevel.escalationMembers.length > 0 || !!toolTipEscalationLevel.escalationPolicy) {
      isToolTipShownRef.current = true;
    }
  }, [escalationLadder, isToolTipShownRef]);

  const handleDeleteEscalationLevel = (index: number) => {
    // TODO: custom alert
    if (window.confirm(`Are you sure you want to delete escalation level ${index + 1}?`)) {
      deleteEscalationLevel(index);
    }
  };

  const isFulfilledEscalationLevel = (level: EscalationLevel) => {
    return level && level.escalationPolicy && level.escalationMembers.length > 0;
  };

  const shouldShowToolTip = (level: EscalationLevel, i: number) => {
    if (!isToolTipShownRef.current) {
      return level && !level.escalationPolicy && level.escalationMembers.length === 0 && i === 1;
    }
    return false;
  };

  return (
    <EscalationLadderWrapper id="EscalationLadderWrapper">
      {escalationLadderList.map((escalationLevel, i) => {
        return (
          <React.Fragment key={i}>
            <Tooltip
              title={LEVEL_TOOLTIPS}
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              open={shouldShowToolTip(escalationLevel, i)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
            >
              <CustomizedEscalationLevelWrapper>
                <EscalationOnCallDropZone escalationOnCall={escalationLevel.escalationMembers} levelIndex={i} />
                <EscalationLevelArrow />
                <EscalationPolicyDropZone
                  escalationPolicy={
                    escalationLevel.escalationPolicy
                      ? {
                          id: escalationLevel.escalationPolicy.id,
                          type: escalationLevel.escalationPolicy.channels[0].type,
                          name: escalationLevel.escalationPolicy.name,
                          repeatLength: escalationLevel.escalationPolicy.channels[0].timeBetweenAttempts,
                          repeatAttempts: escalationLevel.escalationPolicy.channels[0].numAttempts,
                        }
                      : null
                  }
                  levelIndex={i}
                />
                {((escalationLadderList.length > 1 &&
                  escalationLevel.escalationMembers.length > 0 &&
                  escalationLevel.escalationPolicy) ||
                  (i !== escalationLadderList.length - 1 && i !== 0)) && (
                  <DeleteLevelButtonHolder>
                    <IconButton aria-label="delete" onClick={() => handleDeleteEscalationLevel(i)}>
                      <TrashIcon />
                    </IconButton>
                  </DeleteLevelButtonHolder>
                )}
              </CustomizedEscalationLevelWrapper>
            </Tooltip>

            {i < escalationLadderList.length - 1 ? (
              isFulfilledEscalationLevel(escalationLadderList[i + 1]) ? (
                <EscalationLevelArrow />
              ) : (
                <FilteredEscalationLevelArrow />
              )
            ) : null}
          </React.Fragment>
        );
      })}
    </EscalationLadderWrapper>
  );
};

export default EscalationDropzoneContainer;
