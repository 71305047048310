import * as React from 'react';
import { FormHelperText } from '@material-ui/core';
import { HCLabelOne } from 'src/components/shared/HypercareComponents';
import { StyledTextField } from '../../components/shared';
import {
  SEARCH_AVAIlABLE_NUMBERS_HEADER,
  SEARCH_AVAIlABLE_NUMBERS_ONLY_NUMERIC_ALLOWED_ERROR,
} from 'src/constants/virtualPagerStrings';

const FormattedInputField = ({
  InputFieldValues,
  setInputFieldValues,
  isInvalidNumber,
  setIsInvalidNumber,
  onKeyPress,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!/^\d*$/.test(inputValue)) {
      setIsInvalidNumber(true);
    } else {
      setIsInvalidNumber(false);
    }
    setInputFieldValues(inputValue);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onKeyPress();
    }
  };

  return (
    <div>
      <HCLabelOne>{SEARCH_AVAIlABLE_NUMBERS_HEADER}</HCLabelOne>
      <StyledTextField
        error={isInvalidNumber}
        placeholder="E.g.: 2, 22, 226,..."
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          style: {
            padding: '0',
            textIndent: '12px',
            width: '210px',
            height: '40px',
          },
        }}
        value={InputFieldValues}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        InputProps={{
          style: { borderRadius: 2 },
        }}
        variant="outlined"
      />
      {isInvalidNumber && (
        <FormHelperText error={true}>{SEARCH_AVAIlABLE_NUMBERS_ONLY_NUMERIC_ALLOWED_ERROR}</FormHelperText>
      )}
    </div>
  );
};

export default FormattedInputField;
