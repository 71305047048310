import gql from 'graphql-tag';

export const ADMIN_DELETE_ROLE_NOTE = gql`
  mutation DeleteRoleNote(
    $organizationalUnit: OrganizationalUnitInput!
    $scheduleId: Int!
    $roleId: Int!
    $noteId: ID!
  ) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminDepartmentMutation {
            __typename
            schedule(id: $scheduleId) {
              role(id: $roleId) {
                note(id: $noteId) {
                  __typename
                  ... on AccessForbiddenError {
                    __typename
                    message
                  }
                  ... on NoteNotFoundError {
                    __typename
                    message
                  }
                  ... on NoteDeletedError {
                    __typename
                    message
                  }
                  ... on RoleNoteMutation {
                    deleteNote {
                      ... on RoleNote {
                        __typename
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
