import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as React from 'react';

export default (props: { style?: CSSProperties }) => (
  <svg
    fill="#4a4a4a"
    xmlns="http://www.w3.org/2000/svg"
    width={props.style?.width || 24}
    height={props.style?.height || 24}
    viewBox="0 0 24 24"
    style={props.style}
  >
    <path id="chevronStyle" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" stroke="currentColor" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
