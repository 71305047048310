import gql from 'graphql-tag';
import { BasicProfileFieldFragment, ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type AdminAddProfileSectionMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        addSection: {
          id: string;
          sectionId: string;
          label: string;
          fields: Array<ProfileFieldFragmentType>;
        };
      };
    };
  };
};

export const ADMIN_ADD_PROFILE_SECTION_MUTATION = gql`
  mutation AddProfileFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $sectionDetails: CreateProfileSectionInput!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              addSection(details: $sectionDetails) {
                ... on ProfileSection {
                  id
                  sectionId
                  fields {
                    ... on Node {
                      id
                    }
                    ...BasicProfileFieldFragment
                    __typename
                    ... on ProfilePhoneField {
                      phoneNumber
                      placeholder
                    }
                    ... on ProfileTextField {
                      text
                      placeholder
                    }
                    ... on ProfileDropdownField {
                      options
                      selectedOptions
                    }
                    ... on ProfileNumericField {
                      value
                    }
                    ... on ProfileLinkField {
                      url
                    }
                    ... on ProfileEmailField {
                      email
                    }
                    ... on ProfileDateField {
                      date
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${BasicProfileFieldFragment}
`;
