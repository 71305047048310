import React, { PureComponent } from 'react';
import UserAvatar from 'src/components/shared/UserAvatar';
import UserOptionDropdown from 'src/components/popup-dropdowns/UserOptionDropdown';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthContext } from 'src/auth';
import { User } from 'src/types';
import { AppRoutes } from '../../../router/AppRoutes';

export const GlobalImageAvatar = (user) => {
  return (
    <div className="globalHeader__userAvatar">
      <UserAvatar user={user} profileSize="small" />
    </div>
  );
};

interface Props extends RouteComponentProps {
  fullName: string;
  user: User;
  logout: () => void;
  authInfo: any;
  isLoggedIn: Boolean;
}

class UserOptions extends PureComponent<Props> {
  public handleOptionSelection = (option, index) => {
    const { logout, authInfo } = this.props;
    switch (option.description) {
      case 'View profile':
        const { history } = this.props;
        history.push(`${AppRoutes.UserProfile}/${authInfo.user.id}`);
        break;
      case 'Sign out':
        logout();
        break;
      default:
        return;
    }
  };

  public render() {
    const { authInfo, isLoggedIn } = this.props;
    const fullName = `${authInfo.user.firstname} ${authInfo.user.lastname}`;
    const userOptions = [{ description: 'View profile' }, { description: 'Sign out' }];

    if (authInfo && isLoggedIn) {
      return <UserOptionDropdown placeholder={fullName} options={userOptions} onSelect={this.handleOptionSelection} />;
    }
    return null;
  }
}

const ConnectedUserOptions = withRouter(UserOptions);

export default (props) => (
  <AuthContext.Consumer>
    {({ logout, authInfo, isLoggedIn }) => (
      <ConnectedUserOptions {...props} isLoggedIn={isLoggedIn} logout={logout} authInfo={authInfo} />
    )}
  </AuthContext.Consumer>
);
