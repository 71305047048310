import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import 'src/assets/styles/FormStyles.scss';
import { Login } from 'src/types';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import NewLoginFormPasswordHeader from 'src/pages/LoginPage/components/NewLoginFormPasswordHeader';
import * as yup from 'yup';
import HypercareLoginPasswordViewModel from 'src/pages/LoginPage/HypercarePassword/HypercareLoginPasswordViewModel';
import {
  InputsWrapper,
  LoginFormWrapper,
  ProgressContainer,
  StyledTextField,
} from 'src/pages/LoginPage/components/styles';
import {
  LOGIN_ERROR_CODE_INVALID_PASSWORD,
  LOGIN_ERROR_CODE_INVALID_USER,
  LOGIN_ERROR_CODE_ACCOUNT_LOCKED,
} from 'src/constants/networkError';
import {
  LOG_IN,
  LOGIN_VIEW_HEADER_TITLE,
  PASSWORD,
  PASSWORD_IS_REQUIRED,
  SENDING_REQUEST,
} from 'src/constants/strings';
import { LoginFormFooter } from 'src/pages/LoginPage/components/LoginFormFooter';

interface Props {
  login: Login;
  address: string;
  handleBackButtonClick: () => void;
}

export const HypercareLoginPasswordView = ({ login, address, handleBackButtonClick }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isAccountLocked, setIsAccountLocked] = useState(false);

  const onClickContact = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.loginContactSupport,
    });
  };

  const { handleNextButtonPressed } = HypercareLoginPasswordViewModel({ login });

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={yup.object().shape({
        password: yup.string().required(PASSWORD_IS_REQUIRED),
      })}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        const { password } = values;
        const result = await handleNextButtonPressed(address, password);
        const credentialErrors = [LOGIN_ERROR_CODE_INVALID_USER, LOGIN_ERROR_CODE_INVALID_PASSWORD];
        if (result?.error) {
          if (credentialErrors.includes(result.error?.code)) {
            setFieldError('password', result.error?.message);
            setLoginAttempts((prevValue) => prevValue + 1);
          } else if (result.error.code === LOGIN_ERROR_CODE_ACCOUNT_LOCKED) {
            setFieldError('password', result.error?.message);
            setIsAccountLocked(true);
          } else {
            setFieldError('password', result.error?.message);
          }
          setSubmitting(false);
        }
      }}
      render={(props: FormikProps<{ password: string }>) => {
        const { values, errors, touched, isSubmitting, handleChange, handleSubmit, handleBlur } = props;

        return (
          <form onSubmit={handleSubmit} className="modalForm loginForm">
            <LoginFormWrapper loading={isSubmitting ? true : undefined}>
              {isSubmitting && (
                <ProgressContainer>
                  <LinearProgress color="secondary" />
                </ProgressContainer>
              )}
              <NewLoginFormPasswordHeader
                isAccountLocked={isAccountLocked}
                loginAttempts={loginAttempts}
                title={LOGIN_VIEW_HEADER_TITLE}
                description={`${address}`}
                onBackButtonClick={handleBackButtonClick}
              />
              <InputsWrapper>
                <StyledTextField
                  data-testid="passwordInput"
                  fullWidth
                  variant="outlined"
                  placeholder="**********"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password"
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={touched.password && Boolean(errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          onClick={() => setShowPassword((prev) => !prev)}
                          style={{ marginRight: -8 }}
                        >
                          {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </InputsWrapper>
              <LoginFormFooter
                submitting={isSubmitting}
                values={values.password}
                onClick={onClickContact}
                buttonText={LOG_IN}
                submitButtonText={SENDING_REQUEST}
                page={PASSWORD}
              />
            </LoginFormWrapper>
          </form>
        );
      }}
    />
  );
};
