import * as React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import { HCBodyTwo, HCLabelOne, HCTextContext } from 'src/components/shared/HypercareComponents';
import styled from 'styled-components';
import { typedUseSelector } from 'src/redux/store';
import { MessageAlertType, ChatType } from 'src/types';
import theme from 'src/assets/styles/theme';
import {
  ALERT_TYPE_ACCORDION_INPUT_LABLE,
  ALERT_TYPE_REGULAR_OPTION,
  ALERT_TYPE_STAT_OPTION,
  ALERT_TYPE_URGENT_OPTION,
  GROUP_CHAT_ACCORDION_INPUT_LABLE,
  GROUP_CHAT_OPTION,
  GROUP_CHAT_OPTION_DESCRIPTION,
  PAGER_SOUND_BY_PASS_DND_ALERT,
  RECOMMENDED,
  SINGLE_CHAT_OPTION,
  SINGLE_CHAT_OPTION_DESCRIPTION,
} from 'src/constants/virtualPagerStrings';
const RecommendChip = styled.span`
  background: ${(props) => props.theme.blueBackground} !important;

  border-radius: 19px;
  padding: 2px 8px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

const useStyles = makeStyles(() => ({
  customRadio: {
    paddingTop: 0,
    paddingBottom: 0,
    '&.Mui-checked': {
      color: theme.mainTealColor,
      '&.Mui-disabled': { color: theme.warmGreyColor },
    },
    '&.MuiFormControlLabel-root': {
      alignItems: 'flexStart',
    },
  },
  customFormControlLabel: {
    display: 'flex',
    height: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  customRadioGroup: {
    paddingTop: '20px',
    paddingBottom: '20px',
    display: 'flex',
    gap: '16px',
  },
}));

const RadioButtonLabel = styled(HCLabelOne)`
  color: ${(props) => (props.color ? props.color : props.theme.darkenFontColor)};
`;

const RadioButtonLabelDescription = styled(HCBodyTwo)`
  color: ${(props) => props.theme.mainFontColor};
`;
type AlertPreferenceAccordionDetailsProps = {
  alertPreference: MessageAlertType;
  setAlertPreference: React.Dispatch<React.SetStateAction<MessageAlertType>>;
  chatPreference: ChatType;
  setChatPreference: React.Dispatch<React.SetStateAction<ChatType>>;
  isPagerDisabled: boolean;
};

const AlertPreferenceAccordionDetails = ({
  alertPreference,
  setAlertPreference,
  chatPreference,
  setChatPreference,
  isPagerDisabled,
}: AlertPreferenceAccordionDetailsProps) => {
  const { currentPager, editedPager } = typedUseSelector((state) => state.virtualPagerReducer);

  const classes = useStyles();

  return (
    <FormControl style={{ marginBottom: '16px' }} disabled={isPagerDisabled}>
      <FormLabel>
        <HCTextContext afterContent={' *'}>{ALERT_TYPE_ACCORDION_INPUT_LABLE}</HCTextContext>
      </FormLabel>
      <RadioGroup
        classes={{ root: classes.customRadioGroup }}
        onChange={(e) => {
          setAlertPreference(e.target.value as MessageAlertType);
        }}
        defaultValue={'regular' as MessageAlertType}
        value={alertPreference}
        name="alert-buttons-group"
      >
        <FormControlLabel
          disabled={isPagerDisabled}
          classes={{ root: classes.customFormControlLabel }}
          value={'stat' as MessageAlertType}
          control={<Radio classes={{ root: classes.customRadio }} />}
          label={
            <>
              <div>
                <RadioButtonLabel>{ALERT_TYPE_STAT_OPTION}</RadioButtonLabel>
              </div>
              {alertPreference === 'stat' && (
                <RadioButtonLabelDescription>{PAGER_SOUND_BY_PASS_DND_ALERT}</RadioButtonLabelDescription>
              )}
            </>
          }
        />

        <FormControlLabel
          disabled={isPagerDisabled}
          classes={{ root: classes.customFormControlLabel }}
          value={'urgent' as MessageAlertType}
          control={<Radio classes={{ root: classes.customRadio }} />}
          label={
            <>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <RadioButtonLabel>{ALERT_TYPE_URGENT_OPTION}</RadioButtonLabel>
                <RecommendChip>{RECOMMENDED}</RecommendChip>
              </div>

              {alertPreference === 'urgent' && (
                <RadioButtonLabelDescription>{PAGER_SOUND_BY_PASS_DND_ALERT}</RadioButtonLabelDescription>
              )}
            </>
          }
        />
        <FormControlLabel
          disabled={isPagerDisabled}
          classes={{ root: classes.customFormControlLabel }}
          value={'regular' as MessageAlertType}
          control={<Radio classes={{ root: classes.customRadio }} />}
          label={<RadioButtonLabel>{ALERT_TYPE_REGULAR_OPTION}</RadioButtonLabel>}
        />
      </RadioGroup>
      <FormLabel>
        <HCTextContext afterContent={' *'}>{GROUP_CHAT_ACCORDION_INPUT_LABLE}</HCTextContext>
      </FormLabel>
      <RadioGroup
        classes={{ root: classes.customRadioGroup }}
        onChange={(e) => setChatPreference(e.target.value as ChatType)}
        defaultValue={'single' as ChatType}
        value={chatPreference}
        name="chat-buttons-group"
      >
        <FormControlLabel
          disabled={isPagerDisabled}
          classes={{ root: classes.customFormControlLabel }}
          value={'single' as ChatType}
          control={<Radio classes={{ root: classes.customRadio }} />}
          label={
            <div>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <RadioButtonLabel>{SINGLE_CHAT_OPTION}</RadioButtonLabel>
              </div>
              {chatPreference === 'single' && (
                <RadioButtonLabelDescription>{SINGLE_CHAT_OPTION_DESCRIPTION}</RadioButtonLabelDescription>
              )}
            </div>
          }
        />
        <FormControlLabel
          classes={{ root: classes.customFormControlLabel }}
          value={'group' as ChatType}
          control={<Radio classes={{ root: classes.customRadio }} />}
          label={
            <div>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <RadioButtonLabel
                  color={
                    !isPagerDisabled && editedPager.userIds.length <= 1 && editedPager.roleIds.length <= 1
                      ? theme.warmGreyColor
                      : null
                  }
                >
                  {GROUP_CHAT_OPTION}
                </RadioButtonLabel>
                {(currentPager.userIds.length > 1 || currentPager.roleIds.length > 1) && (
                  <RecommendChip>{RECOMMENDED}</RecommendChip>
                )}
              </div>
              {chatPreference === 'group' && (
                <RadioButtonLabelDescription>{GROUP_CHAT_OPTION_DESCRIPTION}</RadioButtonLabelDescription>
              )}
            </div>
          }
          disabled={isPagerDisabled || (editedPager.userIds.length <= 1 && editedPager.roleIds.length <= 1)}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default AlertPreferenceAccordionDetails;
