import React from 'react';
import { connect } from 'react-redux';
import { Mutation, MutationFunction } from 'react-apollo';
import { REMOTE_LOGOUT } from 'src/gql/mutation/RemoteLogoutMutation';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import { User } from 'src/types';
import { RootState } from 'src/redux/store';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

interface Props {
  profile: User;
  handleCloseModal: () => void;
}

const ConfirmRemoteLogout = ({ profile, handleCloseModal }: Props) => {
  const { firstname, id } = profile;

  const handleUserLogout = (remoteLogout) => {
    remoteLogout({ variables: { id } })
      .then(() => successLogoutUser())
      .catch(() => errorLogoutUser());
  };

  const successLogoutUser = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.allDeviceLogout,
      params: {
        user_id: id,
      },
    });
    handleCloseModal();
    toast.success('User has been logged out.', {
      className: 'Toast-Container',
    });
  };

  const errorLogoutUser = () => {
    toast.error('Error when logging out member.', {
      className: 'Toast-Container',
    });
  };

  return (
    <Mutation mutation={REMOTE_LOGOUT}>
      {(remoteLogout: MutationFunction) => {
        return (
          <React.Fragment>
            <div className="modal__confirmation__statement">
              Are you sure you want to force log out <span>{firstname}</span> from all their devices?
            </div>
            <div className="modal__confirmation__buttons">
              <Button disableTouchRipple variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                disableTouchRipple
                variant="contained"
                color="secondary"
                onClick={() => handleUserLogout(remoteLogout)}
              >
                Confirm
              </Button>
            </div>
          </React.Fragment>
        );
      }}
    </Mutation>
  );
};

const getProfileData = (state, ownProps) => {
  const { profile } = ownProps;

  if (profile) {
    return profile;
  } else {
    return state.userDataReducer;
  }
};

const mapStateToProps = (state: RootState, ownProps) => {
  return {
    profile: getProfileData(state, ownProps),
  };
};

export default connect(mapStateToProps)(ConfirmRemoteLogout);
