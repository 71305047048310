import { Grid, Button, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { ProfileConfigurableFieldsSection } from './ProfileConfigurableFieldsSection';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { Formik, FormikErrors } from 'formik';
import { ExecutionResult } from 'react-apollo';
import { FullOrganizationMember, ProfileFieldVisibility, User } from 'src/types';
import { ProfileField } from './ProfileField';
import {
  CANCEL,
  PROFILE_ADDRESS_UPDATE_SUCCESS,
  PROFILE_PAGE_OTHER_INFORMATION_TITLE,
  PROFILE_PAGE_EMPTY_SECTION_SUBTITLE,
  FIELD_PLACEHOLDER_PHONE,
  FIELD_PLACEHOLDER_DATE,
  FIELD_PLACEHOLDER_EMAIL,
  FIELD_PLACEHOLDER_DROPDOWN,
  FIELD_PLACEHOLDER_TEXT,
  FIELD_PLACEHOLDER_URL,
  FIELD_PLACEHOLDER_NUMBER,
  PROFILE_PAGE_UPDATE_OTHER_FIELDS,
  PROFILE_PAGE_UPDATE_FAILED,
  CPF_PLACEHOLDER_LABEL_TEXT,
  DISCARDED,
  DISCARD_UNSAVED_CHANGES,
  DISCARD,
  KEEP_EDITING,
} from 'src/constants/strings';
import moment from 'moment';
import { toast } from 'react-toastify';
import { SuccessToast } from 'src/components/CustomToasts';
import { CPFField, CPFSection, CustomFieldsViewModel } from '../viewModels/CustomFieldsViewModel';
import { HCTextContextTwo } from 'src/components/shared/HypercareComponents';
import { FormField } from '../../../../components/@hc-ui/FormField/FormField';
import { useParams } from 'react-router-dom';
import { UpdateProfileFieldMutationResult } from 'src/gql/v2/mutation/AdminUpdateProfileFieldMutation';
import { AdminAddProfileFieldsMutationResult } from 'src/gql/v2/mutation/AdminAddProfileFieldsMutation';
import { DeleteProfileFieldMutationResult } from 'src/gql/v2/mutation/AdminDeleteProfileFieldMutation';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import HypercareModal from 'src/components/shared/HypercareModal';

//change type any for user
type ProfileConfigurableFieldsFormProps = {
  user: User;
  mode: 'edit' | 'view';
  onModeChange: (mode: 'edit' | 'view') => void;
  isSelf: boolean;
  onSubmitCompleted: () => void;
};

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  justify-content: flex-start;
  width: calc(100% - 67%);
  padding-top: 32px;
`;

const InfoDescription = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
`;

const Heading = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: ${theme.darkenFontColor};
`;

const Container = styled.section`
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 5%;
  padding-right: 5%;
`;

const InnerContainer = styled.div`
  background: ${theme.white};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  min-height: 13rem;
  display: flex;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-left: 4%;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
`;

export const ProfileConfigurableFieldsForm = (props: ProfileConfigurableFieldsFormProps) => {
  const viewModel = CustomFieldsViewModel();
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  // I am getting the userId from the params and using that for createProfileFields

  const { userId } = useParams<{ userId: string }>();
  const configurableProfileFieldsVisibility = IsFeatureFlagEnabled('configurableProfileFieldsVisibility');

  const { loading: isSchemaLoading, data: schemaData, error: schemaError } = viewModel.fetchProfileFieldsSchemaQuery();
  const schemaLoadedSuccessfully = !isSchemaLoading && !schemaError;

  const {
    loading: isUserFieldsDataLoading,
    data: userFieldsData,
    error: userFieldsDataError,
    refetch: refetchUserFieldsData,
  } = viewModel.fetchUserProfileFieldsQuery(props.user.id);
  const selfFieldsDataLoadedSuccessfully = !isUserFieldsDataLoading && !userFieldsDataError;

  const {
    createProfileFields,
    loading: isCreateProfileFieldsMutationLoading,
    error: createProfileFieldsMutationError,
  } = viewModel.createProfileFieldsMutation();

  const {
    updateProfileField,
    loading: isUpdateProfileFieldMutationLoading,
    error: updateProfileFieldMutationError,
  } = viewModel.updateProfileFieldMutation();

  const {
    deleteProfileField,
    loading: isDeleteProfileFieldMutationLoading,
    error: deleteProfileFieldMutationError,
  } = viewModel.deleteProfileFieldMutation();

  const isSubmitting =
    isCreateProfileFieldsMutationLoading || isUpdateProfileFieldMutationLoading || isDeleteProfileFieldMutationLoading;
  const handleSubmit = async (values: CPFSection[]) => {
    try {
      const updateRequests: Promise<ExecutionResult<UpdateProfileFieldMutationResult>>[] = [];
      const createRequests: Promise<ExecutionResult<AdminAddProfileFieldsMutationResult>>[] = [];
      const deleteRequests: Promise<ExecutionResult<DeleteProfileFieldMutationResult>>[] = [];

      for (const section of values) {
        const fieldsToCreate = section.fields.filter((f) => f.isNew && !f.isMarkedForDeletion);
        const fieldsToUpdate = section.fields.filter((f) => f.isDirty && !f.isNew && !f.isMarkedForDeletion);
        const fieldsToDelete = section.fields.filter((f) => f.isMarkedForDeletion);

        if (fieldsToCreate.length) {
          const createRequest = createProfileFields(section.id, fieldsToCreate, userId);
          createRequests.push(createRequest);
          await createRequest;
        }

        for (const fieldToUpdate of fieldsToUpdate) {
          const updateRequest = updateProfileField(fieldToUpdate, userId);
          updateRequests.push(updateRequest);
          await updateRequest;
        }

        for (const fieldToDelete of fieldsToDelete) {
          const deleteRequest = deleteProfileField(fieldToDelete, userId);
          deleteRequests.push(deleteRequest);
          await deleteRequest;
        }
      }

      const completedUpdateRequests = await Promise.allSettled(updateRequests);
      const completeDeleteRequests = await Promise.allSettled(deleteRequests);
      const completedCreateRequests = await Promise.allSettled(createRequests);

      await refetchUserFieldsData();
      toast.success(<SuccessToast title={PROFILE_ADDRESS_UPDATE_SUCCESS} />, {
        className: 'toast-message',
        autoClose: 5000,
      });
      props.onSubmitCompleted();
    } catch (e) {
      toast.error(PROFILE_PAGE_UPDATE_FAILED);
    }
  };

  const validate = (sections: CPFSection[]) => {
    return sections
      .map((s) => s.fields)
      .flat()
      .reduce<
        FormikErrors<{
          [fieldId: string]: string;
        }>
      >((prevErrors, currentField) => {
        const error = viewModel.validateField(currentField)[0];

        if (!error) return prevErrors;

        return {
          ...prevErrors,
          [currentField.id]: error,
        };
      }, {});
  };
  return (
    <Formik<CPFSection[]> validate={validate} initialValues={userFieldsData} enableReinitialize onSubmit={handleSubmit}>
      {(formikProps) => {
        // TODO: fix this abomination somehow.
        // Formik docs say that validate function determines the type of formikProps.errors but they lied. >:(
        // It seems that T where Formik<T> takes precedence, setting errors type to FormikErrors<T>.

        const errors = formikProps.errors as unknown as { [fieldId: string]: string };
        const fieldIdsWithErrors = Object.keys(errors);
        const hasErrors = fieldIdsWithErrors.length !== 0;
        const hasDirtyErrors =
          formikProps?.values
            ?.map((s) => s.fields)
            .flat()
            .filter((f) => f.isDirty && fieldIdsWithErrors.includes(f.id)).length !== 0;

        const handleDiscardModal = () => {
          formikProps.resetForm();
          props.onModeChange('view');
          setShowDiscardModal(false);
          toast(DISCARDED, {
            className: 'toast-discard',
            autoClose: 2000,
          });
        };

        const onFieldChange = (field: CPFField, section: CPFSection) => {
          const oldValues = formikProps.values;
          const newValues = oldValues.map((s) => {
            if (s.id !== section.id) return s;
            const newFields = s.fields.map((f) => {
              if (f.id !== field.id) return f;
              return field;
            });
            return { ...s, fields: newFields };
          });
          formikProps.setValues(newValues);
        };

        const onAddField = (field: CPFField, section: CPFSection) => {
          const label = field.labelOptions.length > 1 ? '' : field.label;
          const oldValues = formikProps.values;
          const newValues = oldValues.map((s) => {
            if (s.id !== section.id) return s;
            return { ...s, fields: s.fields.concat({ ...field, label, id: String(Date.now()), isNew: true }) };
          });
          formikProps.setValues(newValues);
        };

        const onRemoveField = (field: CPFField, section: CPFSection) => {
          onFieldChange({ ...field, isMarkedForDeletion: true }, section);
        };

        const taintAllFieldsWithErrors = () => {
          formikProps.values.forEach((s) => {
            s.fields.forEach((f) => {
              if (!f.isDirty && fieldIdsWithErrors.includes(f.id)) {
                onFieldChange({ ...f, isDirty: true }, s);
              }
            });
          });
        };

        if (!formikProps.values?.length) {
          return null;
        }

        return (
          <Container>
            <InnerContainer>
              <Info>
                <Heading>{PROFILE_PAGE_OTHER_INFORMATION_TITLE}</Heading>
                {props.mode === 'view' && (
                  <Button
                    fullWidth
                    disableTouchRipple
                    variant="contained"
                    color="secondary"
                    onClick={() => props.onModeChange('edit')}
                  >
                    Edit
                  </Button>
                )}
                {props.mode === 'edit' && (
                  <>
                    <Button
                      fullWidth
                      disableTouchRipple
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        taintAllFieldsWithErrors();
                        return formikProps.submitForm();
                      }}
                      disabled={isSubmitting || hasErrors || hasDirtyErrors}
                    >
                      Update
                    </Button>
                    <br />
                    <Button
                      fullWidth
                      disableTouchRipple
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setShowDiscardModal(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Info>
              <StyledForm>
                {formikProps.values.map((section) => {
                  const addFieldOptions = schemaData?.find((s) => s.sectionId === section.sectionId)?.fields ?? [];
                  const isSectionEmpty = section.fields.filter((f) => !f.isMarkedForDeletion).length === 0;
                  return (
                    <ProfileConfigurableFieldsSection
                      user={props.user}
                      key={section.id}
                      title={section.label}
                      mode={props.mode}
                      isAddFieldDisabled={section.fields.length === section.maxFields}
                      addFieldOptions={addFieldOptions}
                      onAddField={(f) => onAddField(f, section)}
                    >
                      {props.mode === 'view' && isSectionEmpty && PROFILE_PAGE_EMPTY_SECTION_SUBTITLE}
                      {section.fields.map((field) => {
                        if (field.isMarkedForDeletion) return null;

                        const getError = () => {
                          if (!field.isNew) return errors[field.id];
                          if (field.isDirty) return errors[field.id];
                        };

                        const isFieldDisabled =
                          isSubmitting || (field.isSynced && props.user?.isDirectorySynced) || !field.isEditable;
                        const isFieldRemovable =
                          !isSubmitting &&
                          field.isRemovable &&
                          props.mode === 'edit' &&
                          !(field.isSynced && props.user.isDirectorySynced);

                        const shouldShowPlaceHolderLabel = field.label === '' && field.labelOptions.length > 1;
                        const label = shouldShowPlaceHolderLabel ? CPF_PLACEHOLDER_LABEL_TEXT : field.label;

                        const commonFormFieldProps = {
                          key: field.id,
                          fieldId: field.fieldId,
                          mode: props.mode,
                          disabled: isFieldDisabled,
                          isRequired: false,
                          label: label,
                          labelOptions: field.labelOptions,
                          onChangeLabel: (l: string) => onFieldChange({ ...field, label: l, isDirty: true }, section),
                          error: getError(),
                          isSynced: field.isSynced,
                          isManagedUser: props.user.isDirectorySynced,
                        };

                        const profileFieldProps = {
                          isSynced: field.isSynced && props.user.isDirectorySynced,
                          visibility: field.visibility,
                          onRemove: isFieldRemovable ? () => onRemoveField(field, section) : undefined,
                          onChangeVisibility:
                            configurableProfileFieldsVisibility && props.mode === 'edit'
                              ? (newVisibility: ProfileFieldVisibility) =>
                                  onFieldChange({ ...field, visibility: newVisibility, isDirty: true }, section)
                              : undefined,
                          mode: props.mode,
                          isSelf: props.isSelf,
                        };

                        switch (field.fieldType) {
                          case 'phone':
                            return (
                              <ProfileField {...profileFieldProps}>
                                <FormField
                                  {...commonFormFieldProps}
                                  fieldType="phone"
                                  placeholder={field.placeholder || FIELD_PLACEHOLDER_PHONE}
                                  value={field.phoneNumber}
                                  onChange={(v) => onFieldChange({ ...field, phoneNumber: v, isDirty: true }, section)}
                                  onBlur={() => onFieldChange({ ...field, isDirty: true }, section)}
                                />
                              </ProfileField>
                            );
                          case 'date':
                            return (
                              <ProfileField {...profileFieldProps}>
                                <FormField
                                  {...commonFormFieldProps}
                                  fieldType="date"
                                  placeholder={FIELD_PLACEHOLDER_DATE}
                                  value={field.date ? moment(field.date).toDate() : undefined}
                                  onChange={(v) =>
                                    onFieldChange({ ...field, date: v.toISOString(), isDirty: true }, section)
                                  }
                                />
                              </ProfileField>
                            );
                          case 'number':
                            return (
                              <ProfileField {...profileFieldProps}>
                                <FormField
                                  {...commonFormFieldProps}
                                  fieldType="number"
                                  placeholder={FIELD_PLACEHOLDER_NUMBER}
                                  value={field.value}
                                  onChange={(v) =>
                                    onFieldChange({ ...field, value: String(v), isDirty: true }, section)
                                  }
                                  onBlur={() => onFieldChange({ ...field, isDirty: true }, section)}
                                />
                              </ProfileField>
                            );
                          case 'url':
                            return (
                              <ProfileField {...profileFieldProps}>
                                <FormField
                                  {...commonFormFieldProps}
                                  fieldType="url"
                                  placeholder={FIELD_PLACEHOLDER_URL}
                                  value={field.url}
                                  onChange={(v) => onFieldChange({ ...field, url: v, isDirty: true }, section)}
                                  onBlur={() => onFieldChange({ ...field, isDirty: true }, section)}
                                />
                              </ProfileField>
                            );
                          case 'text':
                            return (
                              <ProfileField {...profileFieldProps}>
                                <FormField
                                  {...commonFormFieldProps}
                                  fieldType="text"
                                  placeholder={FIELD_PLACEHOLDER_TEXT}
                                  value={field.text}
                                  onChange={(v) => onFieldChange({ ...field, text: v, isDirty: true }, section)}
                                  onBlur={() => onFieldChange({ ...field, isDirty: true }, section)}
                                />
                              </ProfileField>
                            );
                          case 'dropdown':
                            return (
                              <ProfileField {...profileFieldProps}>
                                <FormField
                                  {...commonFormFieldProps}
                                  mode={commonFormFieldProps.isSynced ? 'view' : commonFormFieldProps.mode}
                                  fieldType="dropdown"
                                  placeholder={FIELD_PLACEHOLDER_DROPDOWN}
                                  value={field.selectedOptions?.[0]}
                                  onChange={(v) =>
                                    onFieldChange({ ...field, selectedOptions: v.split(','), isDirty: true }, section)
                                  }
                                  options={field.options}
                                  renderValue={(v) => v}
                                  renderOptionContent={(o) => o}
                                  getIsOptionSelected={(o) => field.selectedOptions.includes(o)}
                                  getOptionId={(o) => o}
                                  onOptionClick={(v) =>
                                    onFieldChange({ ...field, selectedOptions: v.split(','), isDirty: true }, section)
                                  }
                                  onClickAway={() => onFieldChange({ ...field, isDirty: true }, section)}
                                />
                              </ProfileField>
                            );
                          case 'email':
                            return (
                              <ProfileField {...profileFieldProps}>
                                <FormField
                                  {...commonFormFieldProps}
                                  fieldType="email"
                                  placeholder={FIELD_PLACEHOLDER_EMAIL}
                                  value={field.email}
                                  onChange={(v) => onFieldChange({ ...field, email: v, isDirty: true }, section)}
                                  onBlur={() => onFieldChange({ ...field, isDirty: true }, section)}
                                />
                              </ProfileField>
                            );
                        }
                      })}
                    </ProfileConfigurableFieldsSection>
                  );
                })}
              </StyledForm>
            </InnerContainer>
            <HypercareModal
              id="discard-modal"
              width="xs"
              title={DISCARD_UNSAVED_CHANGES}
              titleFontSize="21px"
              subtitle=""
              closeModal={() => setShowDiscardModal(false)}
              isModalVisible={showDiscardModal}
              modalButtons={[
                {
                  type: 'secondary',
                  buttonLabel: KEEP_EDITING,
                  onClickHandler: () => setShowDiscardModal(false),
                  id: 'discard-nevermind',
                },
                {
                  type: 'primary',
                  buttonLabel: DISCARD,
                  onClickHandler: () => handleDiscardModal(),
                  id: 'discard-confirm',
                },
              ]}
            />
          </Container>
        );
      }}
    </Formik>
  );
};
function getOrganizationalUnitObject(): { id: number; type: string } {
  throw new Error('Function not implemented.');
}
