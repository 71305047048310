import React from 'react';
import localTheme from 'src/assets/styles/theme';
import { fade, withStyles } from '@material-ui/core/styles';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';

export const StyledInputBase = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  error: {
    borderRadius: 4,
    border: `1px solid ${localTheme.errorRed}`,
    '& input': {
      boxShadow: `${fade(localTheme.errorRed, 0.25)} 0 0 0 0.2rem !important`,
      borderColor: `${localTheme.errorRed} !important`,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(localTheme.mainTealColor, 0.25)} 0 0 0 0.2rem`,
      borderColor: localTheme.mainTealColor,
    },
  },
}))(InputBase) as React.ComponentType<InputBaseProps>;
