import React from 'react';
import styled from 'styled-components';
import EscalationHeader from 'src/pages/EscalationPage/escalation-layout/EscalationHeader';
import EscalationDropzoneContainer from 'src/pages/EscalationPage/escalation-layout/EscalationDropzoneContainer';
import EscalationSourceContainer from 'src/pages/EscalationPage/escalation-layout/EscalationSourceContainer';
import EscalationReadOnlyContainer from 'src/pages/EscalationPage/escalation-layout/EscalationReadOnlyContainer';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';
import { typedUseSelector } from 'src/redux/store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CREATE } from 'src/constants/escalation';
import { CircularProgress } from '@material-ui/core';
import { EscalationLadder } from 'src/types';

// DOC: React-dnd concept overview
// https://react-dnd.github.io/react-dnd/docs/overview

const EscalationLayoutWrapper = styled.div`
  margin-bottom: 4px;
`;

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const EscalationFlexContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 0 1em;
  height: calc(100vh - (80px + 60px + 4px)); // sub-header + glob-header + wrapper bottom margin
  border-bottom: 1px solid grey;
`;
interface Props {
  escalationLadderId: string | 'create';
  fetchedEscalationLadder?: EscalationLadder;
}

const EscalationLayout = ({ escalationLadderId, fetchedEscalationLadder }: Props) => {
  const escalationLadder = typedUseSelector((state) => state.escalationReducer.escalationLadder);
  const escalationState = typedUseSelector((state) => state.escalationReducer.escalationLadderState);

  const dispatch = useDispatch();

  // currently published ones are directly the ones from backend payload
  const isReadOnly = escalationLadderId !== CREATE;

  React.useEffect(() => {
    const setEscalationLadder = () => dispatch(allActions.escalationAction.setEscalationLadder());
    if (escalationLadderId === CREATE) setEscalationLadder();
  }, [dispatch, escalationLadderId]);

  React.useEffect(() => {
    return () => {
      if (escalationLadder) dispatch(allActions.escalationAction.clearEscalationLadder());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <EscalationLayoutWrapper>
        {escalationLadder || fetchedEscalationLadder ? (
          <React.Fragment>
            <EscalationHeader escalationState={escalationState} ladderName={fetchedEscalationLadder?.name} />
            <EscalationFlexContainer>
              {isReadOnly && fetchedEscalationLadder ? (
                <EscalationReadOnlyContainer escalationLadder={fetchedEscalationLadder} />
              ) : (
                <React.Fragment>
                  <EscalationSourceContainer />
                  <EscalationDropzoneContainer />
                </React.Fragment>
              )}
            </EscalationFlexContainer>
          </React.Fragment>
        ) : (
          <StyledLoader />
        )}
      </EscalationLayoutWrapper>
    </DndProvider>
  );
};

export default EscalationLayout;
