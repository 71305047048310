import PVPAApiHelper from 'src/api/VirtualPagersAPIEndpoints';

interface GetNumbersViewModelProps {
  fetchTwilioAvailableNumbers: (iso_country: string, contains: string) => Promise<any>;
  formatPhoneNumber: (inputNumber: string) => any;
  purchaseTwilioNumbers: (number: string, OrgId: number, locality: string) => Promise<any>;
}

export default (): GetNumbersViewModelProps => {
  const AsteriskFiller = (startingDigits: string, maxLength: number) => {
    const filledString = startingDigits + '*'.repeat(Math.max(0, maxLength - startingDigits.length));

    return filledString;
  };

  const fetchTwilioAvailableNumbers = async (iso_country: string, contains: string) => {
    try {
      const fillerNumber = AsteriskFiller(contains, 10);

      const response = await PVPAApiHelper.fetchTwilioAvailableNumbers(iso_country, fillerNumber);

      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }
  };

  const purchaseTwilioNumbers = async (number: string, OrgId: number, locality: string) => {
    try {
      const orgId = OrgId.toString();
      const response = await PVPAApiHelper.purchaseTwilioNumbers(number, orgId, locality);

      if (response.message) {
        return 'fail';
      } else {
        return 'success';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return 'fail';
    }
  };

  const formatPhoneNumber = (inputNumber: string) => {
    const cleanedNumber = inputNumber.replace(/\D/g, ''); // Remove non-digit characters
    const countryCode = cleanedNumber.substring(0, 1);
    const areaCode = cleanedNumber.substring(1, 4);
    const firstPart = cleanedNumber.substring(4, 7);
    const secondPart = cleanedNumber.substring(7);

    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  };

  return {
    fetchTwilioAvailableNumbers,
    formatPhoneNumber,
    purchaseTwilioNumbers,
  };
};
