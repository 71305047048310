import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { typedUseSelector } from 'src/redux/store';
// import SubmitTestEscalationModal from 'src/components/escalation-layout/popup-modal/SubmitTestEscalationModal';
import SaveEscalationModal from 'src/pages/EscalationPage/escalation-layout/popup-modal/SaveEscalationModal';
import Tooltip from '@material-ui/core/Tooltip';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { AppRoutes } from '../../../router/AppRoutes';

const EscalationHeaderWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 1em;
  justify-content: space-between;
`;

const TitleHolder = styled.div`
  max-width: 60%;
  font-size: 22px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ButtonsHolder = styled.div`
  display: flex;

  button {
    margin-left: 12px;
  }
`;

interface Props {
  escalationState: 'draft' | 'published';
  ladderName?: string;
}

const EscalationHeader = ({ escalationState, ladderName }: Props) => {
  const escalationName = typedUseSelector((state) => state.escalationReducer.escalationLadderName);

  const [showSaveEscalationModal, setSaveEscalationModal] = React.useState(false);
  // const [showTestEscalationModal, setTestEscalationModal] = React.useState(false);

  const isDraftMode = escalationState === 'draft';
  const headerTitle = isDraftMode && !escalationName ? 'Draft Escalation' : ladderName ? ladderName : escalationName;

  const handlePublishEscalation = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.publishEscalationButtonPressed,
    });
    setSaveEscalationModal(true);
  };

  return (
    <React.Fragment>
      <EscalationHeaderWrapper>
        <TitleHolder title={headerTitle}>{headerTitle}</TitleHolder>
        <ButtonsHolder>
          {/* <Button disableTouchRipple variant="outlined" color="primary" onClick={() => setTestEscalationModal(true)}>
            test escalation
          </Button> */}
          {isDraftMode ? (
            <Button
              disableTouchRipple
              variant="contained"
              color="secondary"
              onClick={() => setSaveEscalationModal(true)}
            >
              Publish escalation
            </Button>
          ) : (
            <React.Fragment>
              <Button
                disableTouchRipple
                variant="outlined"
                onClick={() => window.routerHistory.push(AppRoutes.Escalation)}
              >
                back
              </Button>
              <Tooltip
                title={'Please contact Hypercare support to update or delete escalation ladder'}
                PopperProps={{
                  disablePortal: true,
                }}
                placement="bottom"
                arrow
              >
                <div>
                  <Button disableTouchRipple variant="contained" disabled>
                    Published
                  </Button>
                </div>
              </Tooltip>
            </React.Fragment>
          )}
        </ButtonsHolder>
      </EscalationHeaderWrapper>

      {showSaveEscalationModal && (
        <SaveEscalationModal
          showModal={showSaveEscalationModal}
          escalationName={escalationName}
          handleCloseModal={() => setSaveEscalationModal(false)}
        />
      )}
      {/* {showTestEscalationModal && (
        <SubmitTestEscalationModal
          showModal={showTestEscalationModal}
          handleCloseModal={() => setTestEscalationModal(false)}
        />
      )} */}
    </React.Fragment>
  );
};

export default EscalationHeader;
