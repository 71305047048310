import React, { useState } from 'react';
import moment from 'moment';
import { Formik, FormikProps, FormikHandlers, FormikActions, FormikErrors } from 'formik';
import {
  ProfileFormAddresses,
  ProfileFormFields,
  ProfileFormLicenseDatePicker,
} from 'src/components/forms/profile-form-partials';
import ProfileAddressView from 'src/pages/UserProfilePage/profile-layout/components/ProfileAddressView';
import { User, UserAddress, UserProfileValues } from 'src/types';
import { PHONE_REGEX } from 'src/constants/regex';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import * as Yup from 'yup';
import { removePlusSignWithNumber } from 'src/utils/formatPhoneNumber';
import { v4 as uuid } from 'uuid';

import 'src/assets/styles/FormStyles.scss';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { toast } from 'react-toastify';

// credit: https://github.com/jquense/yup/issues/345
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const profileFormValidationSchemaArray = Yup.object().shape({
  address: Yup.string()
    .nullable()
    .when('type', {
      is: 'phoneNumber',
      then: Yup.string()
        .trim()
        .required('Phone number is required!')
        .matches(PHONE_REGEX, 'Invalid phone number format!')
        .min(6, 'Please enter a valid phone number')
        .max(15, 'Please enter a valid phone number'),
    })
    .when('type', {
      is: 'email',
      then: Yup.string().required('Email is required!').email('Invalid email format!'),
    })
    .when('type', {
      is: 'pager',
      then: Yup.string().required('Pager Number is required!'),
    })
    .when('type', {
      is: 'alphanumeric_pager',
      then: Yup.string().required('Pager Number is required!'),
    }),
  access: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

const profileFormValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required!'),
  lastname: Yup.string().required('Last name is required!'),
  role: Yup.string().max(128, 'Invalid').nullable(),
  addresses: Yup.array()
    .of(profileFormValidationSchemaArray)
    .nullable()
    // @ts-ignore
    .unique('Duplicate addresses occurred!', (a) => a.address),
});

interface Props {
  user: User;
  isInputDisabled: boolean;
  bindFormik: (
    submitForm: FormikHandlers['handleSubmit'],
    errors: FormikErrors<UserProfileValues>,
    resetForm: FormikActions<any>['resetForm'],
  ) => void;
  handleOnSubmitForm: (values: UserProfileValues) => Promise<string>;
  onModeChange: (mode: 'edit' | 'view') => void;
  isLicensed: boolean;
  setCurrentAddresses?: React.Dispatch<React.SetStateAction<UserAddress[]>>;
  currentAddresses: UserAddress[];
}

const ProfileForm = ({
  user,
  isLicensed,
  isInputDisabled,
  onModeChange,
  setCurrentAddresses,
  currentAddresses,
  bindFormik,
  handleOnSubmitForm,
}: Props) => {
  const adminContactPreferences = IsFeatureFlagEnabled('orderPreferenceContacts');
  const profileAddressLabelsFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabels);
  let hasAddress = user.addresses && user.addresses.length > 0;
  let initialFormValues: UserProfileValues = {
    addresses: hasAddress ? (adminContactPreferences ? currentAddresses : user.addresses) : [],
    firstname: user.firstname,
    lastname: user.lastname,
    role: user.role,
    organizations: user.organizations,
    licenseStartDate: user.licenseStartTime ? moment(user.licenseStartTime) : null,
    licenseEndDate: user.licenseExpiryTime ? moment(user.licenseExpiryTime) : null,
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize={true}
      validationSchema={profileFormValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const addressArray = values.addresses.map((address) => {
          return address;
        });
        const hasEmptyLabels = addressArray.some((a) => !a.label);
        if (hasEmptyLabels && profileAddressLabelsFlag) {
          toast.error('Some addresses are missing labels. Please fix and retry.');
          return;
        }
        adminContactPreferences && setCurrentAddresses(addressArray);
        try {
          await handleOnSubmitForm(values);
        } finally {
          resetForm();
          setSubmitting(false);
        }
      }}
      render={(props) => {
        const { errors, handleSubmit, submitForm, resetForm } = props as FormikProps<typeof initialFormValues>;

        bindFormik(submitForm, errors, resetForm);

        return (
          <form onSubmit={handleSubmit} className="profileForm">
            <ProfileFormFields user={user} formikProps={props} isInputDisabled={isInputDisabled} />
            {adminContactPreferences ? (
              <ProfileAddressView
                formikProps={props}
                isInputDisabled={isInputDisabled}
                onModeChange={onModeChange}
                setCurrentAddresses={setCurrentAddresses}
              />
            ) : (
              <ProfileFormAddresses formikProps={props} isInputDisabled={isInputDisabled} />
            )}
            {isLicensed && <ProfileFormLicenseDatePicker formikProps={props} isDisabled={isInputDisabled} />}
          </form>
        );
      }}
    />
  );
};

export default ProfileForm;
