import { useQuery } from 'react-apollo';
import { NETWORK_ERROR } from 'src/constants/networkError';
import { checkOrganizationalUnit, getOrganizationObject } from 'src/utils/getOrganizationalUnitObject';
import { OrganizationalUnitInputType } from 'src/gql/v2/types/input';
import {
  FETCH_PAGINATED_USERS_QUERY,
  FetchPaginatedUsersQueryResponse,
} from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import {
  FETCH_PAGINATED_REMOVED_USERS_QUERY,
  FetchPaginatedRemovedUsersQueryResponse,
} from 'src/gql/v2/query/FetchPaginatedRemovedUsersQuery';
import {
  FETCH_PAGINATED_INVITES_QUERY,
  FetchPaginatedInvitesQueryResponse,
} from 'src/gql/v2/query/FetchPaginatedInvitesQuery';
import { SEARCH_USERS_QUERY, SearchUsersQueryResponse } from 'src/gql/v2/query/SearchUsersQuery';
import { SEARCH_REMOVED_USERS_QUERY, SearchRemovedUsersQueryResponse } from 'src/gql/v2/query/SearchRemovedUsersQuery';
import {
  SEARCH_PENDING_INVITES_QUERY,
  SearchPendingInvitesQueryResponse,
} from 'src/gql/v2/query/SearchPendingInvitesQuery';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import client from 'src/clients/apolloClient';
import { ApolloQueryResult } from 'apollo-client';
import { PresentedUserLicensingStatus } from 'src/constants/inviteUserTypes';
import { FetchOrganizationScopeList } from 'src/types';
import { GET_SELF_SCOPE } from 'src/gql/query/GetUserProfileQuery';
import { ORGANIZATION } from 'src/constants/organizationTypes';
import { check } from 'prettier';

export type FetchPaginatedRequestInput = {
  direction: string;
  continuationId: string;
  limit?: number;
  skip?: boolean;
};

export interface FetchPaginatedInviteRequestInput extends FetchPaginatedRequestInput {
  inviteStatus: PresentedUserLicensingStatus;
}

export type SearchRequestInput = {
  text: string;
  limit: number;
  continuationId?: string;
  organizationType?: string;
};

export interface FetchSearchPaginatedInviteRequestInput extends SearchRequestInput {
  inviteStatus: PresentedUserLicensingStatus;
}

export const UserRepository = () => {
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);

  const useFetchPaginatedUsers = ({ continuationId, direction }: FetchPaginatedRequestInput) => {
    const result = useQuery<
      FetchPaginatedUsersQueryResponse,
      FetchPaginatedRequestInput & OrganizationalUnitInputType & { isDirSyncFeatureFlagEnabled: boolean }
    >(FETCH_PAGINATED_USERS_QUERY, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        direction,
        continuationId,
        isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
        limit: 30,
      },
    });

    return {
      ...result,
    };
  };

  const useFetchPaginatedRemovedUsers = ({ continuationId, direction, skip }: FetchPaginatedRequestInput) => {
    const result = useQuery<
      FetchPaginatedRemovedUsersQueryResponse,
      FetchPaginatedRequestInput & OrganizationalUnitInputType
    >(FETCH_PAGINATED_REMOVED_USERS_QUERY, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        direction,
        continuationId,
        limit: 30,
      },
      skip,
    });

    return {
      ...result,
    };
  };
  const useFetchPaginatedInvites = ({
    continuationId,
    direction,
    inviteStatus,
    skip,
  }: FetchPaginatedInviteRequestInput) => {
    const result = useQuery<
      FetchPaginatedInvitesQueryResponse,
      OrganizationalUnitInputType & FetchPaginatedInviteRequestInput
    >(FETCH_PAGINATED_INVITES_QUERY, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        direction,
        continuationId,
        inviteStatus,
        limit: 30,
      },
      skip,
    });

    return {
      ...result,
    };
  };

  const searchUsers = async ({ text, limit, continuationId, organizationType }: SearchRequestInput) => {
    try {
      const searchUsersDataQuery: ApolloQueryResult<SearchUsersQueryResponse> = await client.query({
        query: SEARCH_USERS_QUERY,
        variables: {
          organizationalUnit: organizationType !== ORGANIZATION ? checkOrganizationalUnit() : getOrganizationObject(),
          text,
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          continuationId,
          limit,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });

      return searchUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchRemovedUsers = async ({ text, limit, continuationId }: SearchRequestInput) => {
    try {
      const searchRemovedUsersDataQuery: ApolloQueryResult<SearchRemovedUsersQueryResponse> = await client.query({
        query: SEARCH_REMOVED_USERS_QUERY,
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          text,
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          limit,
          continuationId,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchRemovedUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchPendingInvites = async ({
    text,
    limit,
    continuationId,
    inviteStatus,
  }: FetchSearchPaginatedInviteRequestInput) => {
    try {
      const searchInvitesDataQuery: ApolloQueryResult<SearchPendingInvitesQueryResponse> = await client.query({
        query: SEARCH_PENDING_INVITES_QUERY,
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          text,
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          limit,
          continuationId,
          inviteStatus,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchInvitesDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const fetchOrganizationScopeList = async () => {
    try {
      const organizationListResult: ApolloQueryResult<FetchOrganizationScopeList> = await client.query({
        query: GET_SELF_SCOPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });
      return organizationListResult;
    } catch (err) {
      console.error(err, 'Error occurred when fetching organizations for users');
    }
  };
  return {
    useFetchPaginatedUsers,
    useFetchPaginatedRemovedUsers,
    useFetchPaginatedInvites,
    searchUsers,
    searchRemovedUsers,
    searchPendingInvites,
    fetchOrganizationScopeList,
  };
};
