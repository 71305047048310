import gql from 'graphql-tag';

export const FETCH_USER = gql`
  query FetchUser($userId: ID!) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        user(id: $userId) {
          ... on Node {
            id
            __typename
          }
          ... on GeneralUser {
            firstName
            lastName
            username
            role
            __typename
          }
          ... on FullOrganizationMember {
            devices {
              id
              type
              userAgent
              environment
              ... on BrowserDevice {
                browser
              }
              __typename
            }
          }
        }
      }
    }
  }
`;
