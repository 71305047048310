import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};
export default ({ width, height, stroke }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke || 'grey'}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-check"
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);

export const CheckOutlinedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" fillOpacity="0.01" />
    <path
      d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM11.2071 15.4861C10.8166 15.8766 10.1834 15.8765 9.79293 15.486L7.34649 13.0396C7.01705 12.7101 7.01704 12.176 7.34645 11.8466C7.67589 11.5171 8.21005 11.5171 8.53951 11.8465L10.5 13.8069L15.4628 8.84465C15.7915 8.51593 16.3243 8.51513 16.654 8.84285C16.9852 9.17203 16.986 9.70764 16.6558 10.0378L11.2071 15.4861Z"
      fill="#00859A"
    />
  </svg>
);
