import React, { useEffect, useState } from 'react';

import BarChart from 'src/components/analytics/BarChart';
import { Box, Button, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { GET_MESSAGE_BY_TYPE_TIMELINE } from 'src/gql/query/AnalyticsQuery';
import { tickNumberFormat, transformMessageByTypePipeline } from 'src/pages/AnalyticsDemoPage/helpers';
import { IMessageByTypePipelineResult, Period } from 'src/types';
import { typedUseSelector } from 'src/redux/store';
import ButtonGroupComponent from 'src/pages/AnalyticsDemoPage/ButtonGroupComponent';
import { TooltipProps } from 'recharts';

const getXDateFormatted = (value: string, period: Period) => {
  let dateValue;

  const timestamp = Date.parse(value);
  if (!isNaN(timestamp)) {
    // If value is a valid timestamp, create a Date object using the timestamp
    dateValue = new Date(timestamp);
  } else {
    // If value is not a valid timestamp, create a Date object using the date string converted to int
    dateValue = new Date(parseInt(value));
  }

  switch (period) {
    case 'monthly':
      return moment(dateValue).format('MMMM yyyy');
    default:
      return moment(dateValue).format('ddd, MMM DD');
  }
};

const getTooltipDateFormatted = (value: string, period: Period) => {
  let dateValue;

  const timestamp = Date.parse(value);
  if (!isNaN(timestamp)) {
    // If value is a valid timestamp, create a Date object using the timestamp
    dateValue = new Date(timestamp);
  } else {
    // If value is not a valid timestamp, create a Date object using the date string converted to int
    dateValue = new Date(parseInt(value));
  }

  switch (period) {
    case 'monthly':
      return moment(dateValue).format('MMMM yyyy');
    default:
      return moment(dateValue).format('MMM DD') + ' - ' + moment(dateValue).add(6, 'days').format('MMM DD');
  }
};

interface IMessageTypeTooltipComponentProps extends TooltipProps {
  statOnly: boolean;
  period: Period;
}

const MessageTypeTooltipComponent = (props: IMessageTypeTooltipComponentProps) => {
  if (props.active && props.payload && props.payload[0].payload) {
    const { name, text, image, consult, attachment } = props.payload[0].payload;
    const total = text + image + consult + attachment;
    const statOnly = props.statOnly;
    return (
      <Box boxShadow={1} bgcolor="white" padding={1}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            style={{ backgroundColor: '#F5F5F5', padding: 2, marginRight: 4 }}
            color="textSecondary"
            variant="body2"
          >
            {getTooltipDateFormatted(name, props.period)}
          </Typography>
          <Typography variant="body2">
            Total messages : <span style={{ fontWeight: 'bold' }}>{total}</span>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" marginY={1}>
          <Box>
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(142,192,202)" marginRight={1} />
          </Box>
          <Box>
            <Typography variant="body2">
              {statOnly ? 'Stat text' : 'Text'} messages : <span style={{ fontWeight: 'bold' }}>{text}</span>
            </Typography>
            {/* <Typography variant="subtitle2" color="textSecondary">9% from previous day</Typography> */}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" marginY={1}>
          <Box>
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(57,131,152)" marginRight={1} />
          </Box>
          <Box>
            <Typography variant="body2">
              {statOnly ? 'Stat media' : 'Media'} messages : <span style={{ fontWeight: 'bold' }}>{image}</span>
            </Typography>
            {/* <Typography variant="subtitle2" color="textSecondary">9% from previous day</Typography> */}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" marginY={1}>
          <Box>
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(250,227,162)" marginRight={1} />
          </Box>
          <Box>
            <Typography variant="body2">
              {statOnly ? 'Stat medical' : 'Medical'} consults : <span style={{ fontWeight: 'bold' }}>{consult}</span>
            </Typography>
            {/* <Typography variant="subtitle2" color="textSecondary">9% from previous day</Typography> */}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" marginY={1}>
          <Box>
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(247,200,69)" marginRight={1} />
          </Box>
          <Box>
            <Typography variant="body2">
              {statOnly ? 'Stat attachments' : 'Attachments'} : <span style={{ fontWeight: 'bold' }}>{attachment}</span>
            </Typography>
            {/* <Typography variant="subtitle2" color="textSecondary">9% from previous day</Typography> */}
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
};

const BreakdownByMessageTypeChart: React.FC = () => {
  const theme = useTheme();
  const dateRange = typedUseSelector((state) => state.analyticsReducer.dateRange);
  const refreshOn = typedUseSelector((state) => state.analyticsReducer.refreshOn);
  const organizationId = typedUseSelector((state) => state.organizationReducer.organization_id);
  const departmentId = typedUseSelector((state) => state.organizationReducer.department_id);
  const [activePeriod, setActivePeriod] = useState<Period>('weekly');
  const [messageTypeEl, setMessageTypeEl] = React.useState<null | HTMLElement>(null);
  const [statOnly, setStatOnly] = React.useState(false);

  const dataPipeline = useQuery<IMessageByTypePipelineResult>(GET_MESSAGE_BY_TYPE_TIMELINE, {
    variables: {
      startDate: moment(dateRange[0].startDate).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(dateRange[0].endDate).endOf('days').format('YYYY-MM-DD HH:mm:ss'),
      groupBy: activePeriod,
      organizationId,
      departmentId,
      priorityOnly: statOnly,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    dataPipeline.refetch();
    // eslint-disable-next-line
  }, [dateRange, activePeriod, organizationId, departmentId, refreshOn, statOnly]);

  const handleMessageTypeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMessageTypeEl(event.currentTarget);
  };

  const handleChangeMessageType = (value: boolean) => {
    setMessageTypeEl(null);
    setStatOnly(value);
  };

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap" justifyContent="center">
        <Box flexGrow="1">
          <Typography variant="body1" style={{ fontSize: 18, fontWeight: theme.typography.fontWeightBold }}>
            Breakdown of total messages by message types
          </Typography>
          <Typography variant="subtitle2">Number of messages sent by message types over selected time range</Typography>
        </Box>
        <Button
          endIcon={messageTypeEl ? <ExpandLess /> : <ExpandMore />}
          variant="contained"
          disableElevation
          style={{ textTransform: 'none' }}
          onClick={handleMessageTypeClick}
        >
          Message Type : {statOnly ? 'Stat Messages' : 'General Messages'}
        </Button>
        <Menu anchorEl={messageTypeEl} open={Boolean(messageTypeEl)} onClose={() => setMessageTypeEl(null)}>
          <MenuItem onClick={() => handleChangeMessageType(false)}>General Messages</MenuItem>
          <MenuItem onClick={() => handleChangeMessageType(true)}>Stat Messages</MenuItem>
        </Menu>
      </Box>
      <Box paddingY={2}>
        <ButtonGroupComponent
          active={activePeriod}
          onChange={setActivePeriod}
          values={['weekly', 'monthly']}
          enabledValues={['weekly', 'monthly']}
        />
      </Box>
      <Box paddingY={2} position="relative">
        <BarChart
          loading={dataPipeline.loading}
          data={transformMessageByTypePipeline(dataPipeline.data)}
          xAxis="name"
          yAxis={['text', 'image', 'consult', 'attachment']}
          colors={['rgb(142,192,202)', 'rgb(57,131,152)', 'rgb(250,227,162)', 'rgb(247,200,69)']}
          height={300}
          width="100%"
          stacked
          customTooltip={(props: TooltipProps) => (
            <MessageTypeTooltipComponent period={activePeriod} statOnly={statOnly} {...props} />
          )}
          xTickFormatter={(value) => getXDateFormatted(value, activePeriod)}
          tickFormatter={tickNumberFormat}
        />

        <Box flexDirection="row" display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(142,192,202)" marginRight={1} marginLeft={3} />
            <Typography>Text</Typography>
          </Box>
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(57,131,152)" marginRight={1} marginLeft={3} />
            <Typography>Media (Photo & Video)</Typography>
          </Box>
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(250,227,162)" marginRight={1} marginLeft={3} />
            <Typography>Medical Consults</Typography>
          </Box>
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box height={10} width={10} borderRadius={5} bgcolor="rgb(247,200,69)" marginRight={1} marginLeft={3} />
            <Typography>Attachments</Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BreakdownByMessageTypeChart;
