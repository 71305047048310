import gql from 'graphql-tag';
import { PaginatedPublicUser } from 'src/types/PaginatedTypes';

export type PaginatedBlackListedMembers = {
  continuationId: string;
  totalResultsCount: number;
  users: PaginatedPublicUser[];
};

export type FetchPaginatedRemovedUsersQueryResponse = {
  adminQuery: {
    organizationalUnit: {
      paginatedBlacklistedUsers: PaginatedBlackListedMembers;
    };
  };
};
export const FETCH_PAGINATED_REMOVED_USERS_QUERY = gql`
  query FetchPaginatedRemovedUsers(
    $organizationalUnit: OrganizationalUnitInput!
    $direction: PaginationDirection
    $continuationId: ID
    $limit: Int
  ) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            paginatedBlacklistedUsers(continuationId: $continuationId, direction: $direction, limit: $limit) {
              ...PaginatedBlackListUsersFragment
            }
          }
        }
      }
    }
  }

  fragment PaginatedBlackListUsersFragment on PaginatedBlackListUsers {
    continuationId
    totalResultsCount
    users {
      ...PublicUserFragment
    }
  }

  fragment PublicUserFragment on PublicUser {
    id
    username
    lastname: lastName
    firstname: firstName
  }
`;
