import gql from 'graphql-tag';
import { OrganizationUserResultFragment } from '../fragment/OrganizationUserResultFragment';

export const ADMIN_CREATE_PROFILE_NOTE = gql`
  mutation CreateProfileNote(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $details: CreateHiddenNoteDetails!
  ) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationMutation {
            __typename
            member(id: $userId) {
              createNote(details: $details) {
                ... on ProfileNote {
                  __typename
                  id
                  note
                  access
                  createdAt
                  updatedAt
                  createdBy {
                    ...OrganizationUserResultFragment
                    __typename
                  }
                  editedBy {
                    ...OrganizationUserResultFragment
                    __typename
                  }
                }
                ... on ExceedMaxNoteCountError {
                  __typename
                  message
                }
                ... on ExceedMaxWordCountError {
                  __typename
                  message
                  maxCount
                }
              }
            }
          }
          ... on AdminSiteMutation {
            __typename
            member(id: $userId) {
              createNote(details: $details) {
                ... on ProfileNote {
                  __typename
                  id
                  note
                  access
                  createdAt
                  updatedAt
                  createdBy {
                    ...OrganizationUserResultFragment
                    __typename
                  }
                  editedBy {
                    ...OrganizationUserResultFragment
                    __typename
                  }
                }
                ... on ExceedMaxNoteCountError {
                  __typename
                  message
                }
                ... on ExceedMaxWordCountError {
                  __typename
                  message
                  maxCount
                }
              }
            }
          }
          ... on AdminDepartmentMutation {
            __typename
            member(id: $userId) {
              createNote(details: $details) {
                ... on ProfileNote {
                  __typename
                  id
                  note
                  access
                  createdAt
                  updatedAt
                  createdBy {
                    ...OrganizationUserResultFragment
                    __typename
                  }
                  editedBy {
                    ...OrganizationUserResultFragment
                    __typename
                  }
                }
                ... on ExceedMaxNoteCountError {
                  __typename
                  message
                }
                ... on ExceedMaxWordCountError {
                  __typename
                  message
                  maxCount
                }
              }
            }
          }
        }
      }
    }
  }
  ${OrganizationUserResultFragment}
`;
