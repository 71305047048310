import { useEffect, useState } from 'react';
import ApiHelper from 'src/api';
import { HYPERCARE_REGION } from '../constants/storageKeys';
import { getParsedAuthRegion } from './localStorageHelper';
import { CANADA_REGION_CODE, EUROPE_REGION_CODE, UNITED_STATES_REGION_CODE } from '../constants/strings';
import { AuthRegion } from '../types';

const DEFAULT_REGION = CANADA_REGION_CODE;

const getRegionFromCountryCode = (countryCode: AuthRegion) => {
  switch (countryCode) {
    case CANADA_REGION_CODE:
      return CANADA_REGION_CODE;
    case UNITED_STATES_REGION_CODE:
      return UNITED_STATES_REGION_CODE;
    case EUROPE_REGION_CODE:
      return EUROPE_REGION_CODE;
    default:
      return DEFAULT_REGION;
  }
};

export const useGetGeoLocation = () => {
  const [region, setRegion] = useState('');

  useEffect(() => {
    const getAndSetRegion = async () => {
      try {
        const currentRegion = getParsedAuthRegion();
        const result = await ApiHelper.fetchUserGeographicalLocation();

        if (result?.data?.country_code && !currentRegion) {
          const newRegion = getRegionFromCountryCode(result.data.country_code);
          setRegion(newRegion);
          window.localStorage.setItem(HYPERCARE_REGION, newRegion);
        } else {
          setRegion(currentRegion || DEFAULT_REGION);
          window.localStorage.setItem(HYPERCARE_REGION, currentRegion || DEFAULT_REGION);
        }
      } catch (error) {
        setRegion(DEFAULT_REGION);
        window.localStorage.setItem(HYPERCARE_REGION, DEFAULT_REGION);
      }
    };

    getAndSetRegion();
  }, []);

  return { region };
};
