// monthlyScheduleAction
export const SET_ROLES_AND_SHIFTS = 'SET_ROLES_AND_SHIFTS';
export const SET_LAST_UPDATED_AT = 'SET_LAST_UPDATED_AT';
export const SET_USER_COLOR = 'SET_USER_COLOR';
export const SET_SCHEDULE_DATE = 'SET_SCHEDULE_DATE';
export const SET_SHOULD_SHOW_GAP = 'SET_SHOULD_SHOW_GAP';
export const SET_TOTAL_CONFLICTS_WARNINGS = 'SET_TOTAL_CONFLICTS_WARNINGS';

export const SET_SCHEDULE_ID = 'SET_SCHEDULE_ID';

export const SET_ROLE_NOTES = 'SET_ROLE_NOTES';

export const SET_CLEAR_ROLE_NOTES = 'SET_CLEAR_ROLE_NOTES';

export const SET_ADD_ROLE_NOTE = 'SET_ADD_ROLE_NOTE';

export const SET_ADD_ROLE_TO_ROLE_NOTES = 'SET_ADD_ROLE_TO_ROLE_NOTES';
export const SET_DELETE_ROLE_NOTE = 'SET_DELETE_ROLE_NOTE';
export const SET_UPDATE_ROLE_NOTE = 'SET_UPDATE_ROLE_NOTE';

export const SET_ROLE_NOTE_MODAL_VISIBILITY = 'SET_ROLE_NOTE_MODAL_VISIBILITY';
export const SET_CURRENT_SELECTED_ROLE = 'SET_CURRENT_SELECTED_ROLE';

// escalationAction
export const SET_ESCALATION_LADDER = 'SET_ESCALATION_LADDER';
export const SET_LEVEL_POLICY = 'SET_LEVEL_POLICY';
export const DELETE_LEVEL_POLICY = 'REMOVE_LEVEL_POLICY';
export const SET_LEVEL_ONCALL = 'SET_LEVEL_ONCALL';
export const DELETE_LEVEL_ONCALL = 'DELETE_LEVEL_ONCALL';
export const DELETE_ESCALATION_LEVEL = 'DELETE_ESCALATION_LEVEL';
export const CLEAR_ESCALATION_REDUCER = 'CLEAR_ESCALATION_REDUCER';
export const CHANGE_ESCALATION_STATE = 'CHANGE_ESCALATION_STATE';

// sortAction
export const SORT_LASTNAME = 'SORT_LASTNAME';
export const SORT_JOIN_DATE = 'SORT_JOIN_DATE';
export const SORTER_TYPE = 'SORTER_TYPE';
export const SORT_LICENSING_STATUS = 'SORT_LICENSING_STATUS';

// headerAction
export const SEND_PROFILE_TO_STORE = 'SEND_PROFILE_TO_STORE';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_WAITING_SWITCH_ORGANIZATION = 'SET_WAITING_SWITCH_ORGANIZATION';
export const OFF_WAITING_SWITCH_ORGANIZATION = 'OFF_WAITING_SWITCH_ORGANIZATION';
export const ORGANIZATION_DROPDOWN = 'ORGANIZATION_DROPDOWN';
export const SET_DIRECTORY_SYNCED = 'SET_DIRECTORY_SYNCED';

// authorizeUserAction
export const RESET = 'RESET';
export const USER_AUTHORIZED = 'USER_AUTHORIZED';
export const TOKEN_ALERT = 'TOKEN_ALERT';

// analyticsAction
export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';
export const REFETCH = 'REFETCH';

// changeRegion
export const SET_HYPERCARE_REGION = 'SET_HYPERCARE_REGION';

// VirtualPager
//? Configure Pager Actions
export const SET_CURRENT_PAGER = 'SET_CURRENT_PAGER';
export const SET_EDITED_PAGER = 'SET_EDITED_PAGER';
export const SET_EDITED_AND_CURRENT_PAGER = 'SET_EDITED_AND_CURRENT_PAGER';

//? Pager list Actions
export const SET_PAGER_LIST = 'SET_PAGER_LIST';
export const SET_NEXT_CURSOR = 'SET_NEXT_CURSOR';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';

// User organization actions

export const SET_USER_ORGANIZATIONS = 'SET_USER_ORGANIZATIONS';
