import React from 'react';
import styled from 'styled-components';
import { SorterType, UserMenuOptionStatus } from 'src/types';
import { sortByPaginatedInviteStatus } from 'src/utils/userSorting';
import { PresentedUserLicensingStatus, USER_MENU_OPTION } from 'src/constants/inviteUserTypes';
import { PENDING_ADMIN, PENDING_USER } from 'src/constants/inviteUserTypes';
import { PaginatedUserList } from './PaginatedUserList';
import { FetchPaginatedUsersQueryResponse, PaginatedLicensedMembers } from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import { PaginatedInvites, PaginatedPublicUser, PaginatedUsersType } from 'src/types/PaginatedTypes';
import {
  FetchPaginatedRemovedUsersQueryResponse,
  PaginatedBlackListedMembers,
} from 'src/gql/v2/query/FetchPaginatedRemovedUsersQuery';
import RemovedUsersList from 'src/pages/HomePage/views/RemovedUsersList';
import PendingUserListContainerV2 from 'src/pages/HomePage/views/PendingUserListContainerV2';
import {
  FetchPaginatedInvitesQueryResponse,
  PaginatedPendingInvites,
} from 'src/gql/v2/query/FetchPaginatedInvitesQuery';
import { NoSearchResultsFound } from 'src/pages/HomePage/views/NoSearchResultsFound';
import { NO_PENDING_INVITES } from 'src/constants/strings';
import { UsersListSearchingLoader } from 'src/components/loaders/UsersListSearchingLoader';
import SortButton from './SortButton';
import { ApolloQueryResult } from 'apollo-client';
import { OrganizationalUnitInputType } from '../../../gql/v2/types/input';
import { FetchPaginatedInviteRequestInput } from '../../../data/repository/UserRepository';

const StyledContainer = styled.div`
  padding: 12px 0px !important;
  margin-top: 28px !important;
`;

interface Props {
  searchLoading: boolean;
  localSearchText: string;
  licensedUsers: PaginatedUsersType[];
  blackListedUsers: PaginatedPublicUser[];
  adminPendingUsers: PaginatedInvites[];
  currentMenuOption: UserMenuOptionStatus;
  adminCoSignInvites: boolean;
  fetchMorePaginatedUserData: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedUsersQueryResponse>>;
  fetchMorePaginatedRemovedUserData: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedRemovedUsersQueryResponse>>;
  paginatedUserData: FetchPaginatedUsersQueryResponse;
  paginatedRemovedUserDataQuery: FetchPaginatedRemovedUsersQueryResponse;
  paginatedPendingUserInvitesQuery: FetchPaginatedInvitesQueryResponse;
  paginatedPendingAdminInvitesQuery: FetchPaginatedInvitesQueryResponse;
  fetchMorePaginatedAdminInviteQuery: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponse>>;
  fetchMorePaginatedUserInvitesQuery: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponse>>;
  searchLicensedUserData: PaginatedLicensedMembers;
  setSearchLicensedUserData: React.Dispatch<React.SetStateAction<PaginatedLicensedMembers>>;
  searchBlackListedUserData: PaginatedBlackListedMembers;
  setSearchBlackListedUserData: React.Dispatch<React.SetStateAction<PaginatedBlackListedMembers>>;
  searchPendingInviteData: PaginatedPendingInvites;
  setSearchPendingInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvites>>;
  searchPendingAdminApprovalInviteData: PaginatedPendingInvites;
  setSearchPendingAdminApprovalInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvites>>;
  pendingRecords: (
    variables?: OrganizationalUnitInputType & FetchPaginatedInviteRequestInput,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponse>>;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

const AllUsersSectionLayout = ({
  licensedUsers,
  localSearchText,
  blackListedUsers,
  adminPendingUsers,
  currentMenuOption,
  adminCoSignInvites,
  fetchMorePaginatedUserData,
  fetchMorePaginatedRemovedUserData,
  paginatedUserData,
  paginatedRemovedUserDataQuery,
  paginatedPendingAdminInvitesQuery,
  paginatedPendingUserInvitesQuery,
  fetchMorePaginatedAdminInviteQuery,
  searchLoading,
  fetchMorePaginatedUserInvitesQuery,
  searchLicensedUserData,
  setSearchLicensedUserData,
  searchBlackListedUserData,
  searchPendingInviteData,
  setSearchPendingInviteData,
  setSearchBlackListedUserData,
  searchPendingAdminApprovalInviteData,
  setSearchPendingAdminApprovalInviteData,
  pendingRecords,
  isDoneRows,
  setIsDoneRows,
}: Props) => {
  const [pendingByAdmin, setPendingByAdmin] = React.useState<PaginatedInvites[]>([]);
  const [pendingByInvitee, setPendingByInvitee] = React.useState<PaginatedInvites[]>([]);
  const [sorterType, setSorterType] = React.useState<SorterType>('lastName');

  //Pending user is an array of invites
  const pendingUsers = adminPendingUsers;

  const pendingTypes: PresentedUserLicensingStatus[] = [PENDING_ADMIN, PENDING_USER];

  React.useEffect(() => {
    const formatUserData = async (status: string, users: PaginatedInvites[]) => {
      const pendingUser = users.filter((user) => user.inviteStatus === status).sort(sortByPaginatedInviteStatus);
      status === PENDING_ADMIN ? setPendingByAdmin(pendingUser) : setPendingByInvitee(pendingUser);
    };

    formatUserData(PENDING_ADMIN, pendingUsers);
    formatUserData(PENDING_USER, pendingUsers);
  }, [pendingUsers]);

  if (searchLoading)
    return (
      <StyledContainer className="usersList">
        <div className="usersList__wrapper">
          <div className="usersList__wrapper__content">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <UsersListSearchingLoader />
            </div>
          </div>
        </div>
      </StyledContainer>
    );

  return adminCoSignInvites && currentMenuOption === USER_MENU_OPTION.Pending ? (
    pendingByAdmin.length === 0 && pendingByInvitee.length === 0 ? (
      <StyledContainer className="usersList">
        <div className="usersList__wrapper">
          <div className="usersList__wrapper__content">
            {localSearchText.length >= 1 ? <NoSearchResultsFound /> : <p className="noUser">{NO_PENDING_INVITES}</p>}
          </div>
        </div>
      </StyledContainer>
    ) : (
      <>
        {pendingTypes.map((type, index) => (
          <PendingUserListContainerV2
            key={index}
            users={type === PENDING_ADMIN ? pendingByAdmin : pendingByInvitee}
            status={type}
            pendingByAdmin={pendingByAdmin}
            pendingRecords={pendingRecords}
            setPendingByAdmin={setPendingByAdmin}
            pendingByInvitee={pendingByInvitee}
            setPendingByInvitee={setPendingByInvitee}
            paginatedPendingAdminInvitesQuery={paginatedPendingAdminInvitesQuery}
            paginatedPendingUserInvitesQuery={paginatedPendingUserInvitesQuery}
            fetchMorePaginatedAdminInviteQuery={fetchMorePaginatedAdminInviteQuery}
            fetchMorePaginatedUserInvitesQuery={fetchMorePaginatedUserInvitesQuery}
            localSearchText={localSearchText}
            searchPendingInviteData={searchPendingInviteData}
            setSearchPendingInviteData={setSearchPendingInviteData}
            searchPendingAdminApprovalInviteData={searchPendingAdminApprovalInviteData}
            setSearchPendingAdminApprovalInviteData={setSearchPendingAdminApprovalInviteData}
            isDoneRows={isDoneRows}
            setIsDoneRows={setIsDoneRows}
          />
        ))}
      </>
    )
  ) : (
    <div className="usersListV2">
      <SortButton sorterType={sorterType} setSorterType={setSorterType} currentMenuOption={currentMenuOption} />
      <div className="usersListV2__wrapper">
        <div className="usersListV2__wrapper__content">
          {currentMenuOption === 'unlicensed' ? (
            <RemovedUsersList
              currentMenuOption={currentMenuOption}
              blacklistUsers={blackListedUsers}
              fetchMorePaginatedRemovedUserData={fetchMorePaginatedRemovedUserData}
              paginatedRemovedUserDataQuery={paginatedRemovedUserDataQuery}
              localSearchText={localSearchText}
              searchBlackListedUserData={searchBlackListedUserData}
              setSearchBlackListedUserData={setSearchBlackListedUserData}
              isDoneRows={isDoneRows}
              setIsDoneRows={setIsDoneRows}
            />
          ) : (
            <PaginatedUserList
              localSearchText={localSearchText}
              paginatedUserData={paginatedUserData}
              currentMenuOption={currentMenuOption}
              users={licensedUsers}
              fetchMorePaginatedUserData={fetchMorePaginatedUserData}
              searchLicensedUserData={searchLicensedUserData}
              setSearchLicensedUserData={setSearchLicensedUserData}
              isDoneRows={isDoneRows}
              setIsDoneRows={setIsDoneRows}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllUsersSectionLayout;
