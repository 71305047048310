import gql from 'graphql-tag';
import RoleFragment from 'src/gql/fragment/RoleFragment';

export default gql`
  mutation UpdateRole(
    $departmentId: Int!
    $roleId: Int!
    $updateRoleDetails: UpdateRoleDetails!
    $startDate: String!
    $endDate: String!
  ) {
    admin {
      locating {
        department(id: $departmentId) {
          role(id: $roleId) {
            update(details: $updateRoleDetails) {
              ...RoleFragment
            }
          }
        }
      }
    }
  }
  ${RoleFragment}
`;
