import { Auth0ContextInterface } from '@auth0/auth0-react';
import { DefaultUser, User } from 'src/types/User';
import { PASSWORD, ADDRESS } from 'src/constants/strings';

export type AuthRegion = 'CA' | 'US' | 'EU';

export type AuthRegionName = 'Canada' | 'United States' | 'Europe';
export interface IAuthRegionData {
  region: AuthRegion;
  regionName: AuthRegionName;
  flagIcon: any;
}

export interface LoginResponse {
  data?: AuthPayload;
  success: boolean;
  error?: any;
}
export interface HandleContinueLoginAddressParams {
  address?: string;
  auth0Id?: string;
}
export type LoginPages = typeof PASSWORD | typeof ADDRESS;

export type Login = (username: string, password: string) => Promise<LoginResponse> | null;

export type LoginViaSSO = (token: string, provider: string) => Promise<LoginResponse> | null;

export interface AuthProviderState {
  isLoggedIn: boolean;
  login: Login;
  logout: () => void;
  authInfo: AuthPayload;
  loginViaSSO: LoginViaSSO;
  auth0props: Auth0ContextInterface;
  updateAuthUserInfo: (user: User) => void;
  setAuthRegion: (region: AuthRegion) => void;
  authRegion: AuthRegion;
}

export interface AuthPayload {
  accessToken?: string;
  accessTokenExpiresAt?: string;
  client?: Client;
  refreshToken?: string;
  refreshTokenExpiresAt?: string;
  scopeToken?: string;
  user: DefaultUser;
}

export interface Client {
  accessTokenLifetime: number;
  client_id: string;
  client_secret: string;
  grants: string[];
  id: number;
  name: string | null;
  redirect_uri: string;
  refreshTokenLifetime: number;
  user_id: string;
}

export enum AuthSSOVendors {
  WORKOS = 'workos',
  AUTH0 = 'auth0',
}
