import { FlagActionTypes } from 'src/redux/actions/flagAction';
import { AuthorizeActionActionTypes } from 'src/redux/actions/authorizeAction';
import {
  OFF_WAITING_SWITCH_ORGANIZATION,
  SET_WAITING_SWITCH_ORGANIZATION,
  SORT_JOIN_DATE,
  SORT_LICENSING_STATUS,
  SORT_LASTNAME,
  TOKEN_ALERT,
  USER_AUTHORIZED,
  ORGANIZATION_DROPDOWN,
} from 'src/constants/actionNames';

let initialState = {
  isAuthorized: false,
  tokenLastRefreshAt: null,
  sortLastNameRev: false,
  sortJoinDateRev: false,
  sortLicensingStatus: false,
  sortNumberRev: false,
  sortAssigneeLastNameRev: false,
  sortDateCreatedRev: false,
  sortDateLastUsedRev: false,
  isWaitingSwitchOrganization: false,
  isOpen: false,
};

export interface FlagReducerState {
  isAuthorized: boolean;
  tokenLastRefreshAt: number;
  sortLastNameRev: boolean;
  sortJoinDateRev: boolean;
  sortLicensingStatus: boolean;
  sortNumberRev: boolean;
  sortAssigneeLastNameRev: boolean;
  sortDateCreatedRev: boolean;
  sortDateLastUsedRev: boolean;
  isWaitingSwitchOrganization: boolean;
  isOpen: boolean;
}

export const flagReducer = (
  state = initialState,
  action: FlagActionTypes | AuthorizeActionActionTypes,
): FlagReducerState => {
  switch (action.type) {
    case USER_AUTHORIZED: {
      return {
        ...state,
        isAuthorized: true,
      };
    }
    case SORT_LASTNAME: {
      return {
        ...state,
        sortJoinDateRev: false,
        sortLastNameRev: !state.sortLastNameRev,
      };
    }
    case SORT_JOIN_DATE: {
      return {
        ...state,
        sortLastNameRev: false,
        sortJoinDateRev: !state.sortJoinDateRev,
      };
    }
    case SORT_LICENSING_STATUS: {
      return {
        ...state,
        sortLicensingStatus: !state.sortLicensingStatus,
      };
    }
    case SET_WAITING_SWITCH_ORGANIZATION: {
      return {
        ...state,
        isWaitingSwitchOrganization: true,
      };
    }
    case OFF_WAITING_SWITCH_ORGANIZATION: {
      return {
        ...state,
        isWaitingSwitchOrganization: false,
      };
    }
    case TOKEN_ALERT: {
      return {
        ...state,
        tokenLastRefreshAt: Date.now(),
      };
    }
    case ORGANIZATION_DROPDOWN: {
      return {
        ...state,
        isOpen: action.isOpen,
      };
    }

    default:
      return state;
  }
};
