import React from 'react';
import ModalNumberSearchView from './ModalNumberSearchView';
import ModalConfirmPurchaseView from './ModalConfirmPurchaseView';
import { TwilioNumbers } from 'src/types';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onPurchase?: () => Promise<void>;
}

const GetNumbersModal = ({ isOpen, closeModal, onPurchase }: Props) => {
  const [isConfirmPurchaseVisible, setConfirmPurchaseVisible] = React.useState<boolean>(false);
  const [purchasingNumberList, setPurchasingNumberList] = React.useState<TwilioNumbers[]>([]);

  return (
    <>
      <ModalNumberSearchView
        isOpen={isOpen}
        closeModal={closeModal}
        purchasingNumberList={purchasingNumberList}
        setPurchasingNumberList={setPurchasingNumberList}
        setConfirmPurchaseVisible={setConfirmPurchaseVisible}
      />
      <ModalConfirmPurchaseView
        onPurchase={onPurchase}
        closeMainModal={closeModal}
        isOpen={isConfirmPurchaseVisible}
        closeModal={() => setConfirmPurchaseVisible(false)}
        incomingTwilioNumberList={purchasingNumberList}
      />
    </>
  );
};

export default GetNumbersModal;
