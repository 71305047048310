export const FeatureFlagResult = {
  adminAlphanumericPaging: 'adminAlphanumericPaging',
  adminCoSignInvites: 'adminCoSignInvites',
  hiddenNotes: 'hiddenNotes',
  hiddenRoleNotes: 'hiddenRoleNotes',
  ldapDirectorySync: 'ldapDirectorySync',
  orderPreferenceContacts: 'orderPreferenceContacts',
  overnightShifts: 'overnightShifts',
  profileNotes: 'profileNotes',
  sessionTimeout: 'sessionTimeout',
  templateMessageReport: 'templateMessageReport',
  virtualPagerNavigationTab: 'virtualPagerNavigationTab',
  configurableProfileFields: 'configurableProfileFields',
  configurableProfileFieldsVisibility: 'configurableProfileFieldsVisibility',
  profileAddressLabels: 'profileAddressLabels',
  paginatedContacts: 'paginatedContacts',
  newMemberAnalyticsUi: 'newMemberAnalyticsUi',
  userDeviceList: 'userDeviceList',
  staticMaintenancePage: 'staticMaintenancePage',
  missingAddressBanner: 'missingAddressBanner',
};
