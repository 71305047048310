import gql from 'graphql-tag';
import { userFragmentString } from 'src/gql/fragment/FullUserFragment';

export const EDIT_PROFILE = gql`
  mutation editUser(
    $userId: ID!,
    $firstname: String,
    $lastname: String,
    $role: String,
    $note: String
  ) {
    admin {
      user(id: $userId) {
        updateNote(note: $note) {
          ${userFragmentString}
          addresses {
            id
            address
            type
            ordinal
            access
          }
        }
        updateProfile(userDetails: {
          firstname: $firstname,
          lastname: $lastname,
          role: $role
        }) {
          ${userFragmentString}
          addresses {
            id
            address
            type
            ordinal
            access
          }
        }
      }
    }
  }
`;

export const EDIT_PROFILE_WITHOUT_NOTE = gql`
  mutation editUser(
    $userId: ID!,
    $firstname: String,
    $lastname: String,
    $role: String
  ) {
    admin {
      user(id: $userId) {
        updateProfile(userDetails: {
          firstname: $firstname,
          lastname: $lastname,
          role: $role
        }) {
          ${userFragmentString}
          addresses {
            id
            address
            type
            ordinal
            access
          }
        }
      }
    }
  }
`;
