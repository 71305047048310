import ApiHelper from 'src/api';
import { getSSOProfileNetworkResponse, SSOProfileResponse } from 'src/types';

interface resultObject {
  success: boolean;
  data?: getSSOProfileNetworkResponse;
  error?: any;
}

export interface HypercareLoginRepositoryProps {
  fetchSSOConfiguration: () => Promise<SSOProfileResponse[]>;
  checkIfAddressIsSSO: (email: string) => Promise<resultObject>;
}

const HypercareLoginRepository = (): HypercareLoginRepositoryProps => {
  let SSOConfiguration: Promise<SSOProfileResponse[]> | null;

  const checkIfAddressIsSSO = async (email: string) => {
    try {
      return await ApiHelper.getSSODomainsForUsername(email);
    } catch (err) {
      return { success: false, data: undefined, error: err };
    }
  };

  const fetchSSOConfiguration = async () => {
    if (SSOConfiguration) {
      return SSOConfiguration;
    }

    try {
      const result = await ApiHelper.fetchSSOProfiles();
      if (result) {
        SSOConfiguration = result.data.listSSOProfiles;
      }
    } catch (err) {
      SSOConfiguration = null;
    }

    return SSOConfiguration;
  };

  return { checkIfAddressIsSSO, fetchSSOConfiguration };
};

export default HypercareLoginRepository;
