import React, { useState } from 'react';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import { AuthRegionFlag } from 'src/components/shared/AuthRegionFlag';
import {
  AuthRegionMap,
  HYPERCARE_SUPPORT_LINK,
  LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT,
  LOGIN_BOX_DESCRIPTION_TEXT,
  LOGIN_PAGE_CURRENT_REGION_TITLE,
  LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_SUB_INFO,
  LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_TITLE,
  LOGIN_PAGE_LOCKED_ACCOUNT_CONTACT_US,
  LOGIN_PAGE_LOCKED_ACCOUNT_HELP_TEXT,
  LOGIN_PAGE_LOCKED_ACCOUNT_MESSAGE,
  UNITED_STATES,
  UNITED_STATES_REGION_CODE,
} from 'src/constants/strings';
import { typedUseSelector } from 'src/redux/store';
import AlertTriangle from 'src/assets/svgs/AlertTriangle';
import ChangeRegionModal from 'src/components/modals/ChangeRegionModal';
import { getParsedAuthRegion } from '../../../utils/localStorageHelper';

const WarningWrapper = styled.div`
  margin: 0 0 12px 0;
  display: flex;
  text-align: left;
  padding: 1em 0.5em;
  opacity: 0.8;
  border-radius: 3px;
  background-color: rgba(255, 62, 85, 0.2);
`;

const WarningIconWrapper = styled.div`
  margin-right: 1em;
  margin-left: 0.5em;
  padding-top: 4px;
  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.errorRed};
  }
`;

const WarningDetailTitle = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  font-weight: bold;
  color: ${(props) => props.theme.darkenFontColor};
  a {
    color: ${(props) => props.theme.blueBorder};
    &:hover {
      filter: opacity(0.8);
    }
  }
`;

const WarningDetailSubInfo = styled(WarningDetailTitle)`
  font-weight: normal;
  font-size: 12px;
`;

const LoginFormHeaderSection = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const HeaderTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.darkenFontColor};
  font-size: 36px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 32px;
    width: 32px;
    margin-left: 8px;
    pointer-events: none;
    margin-right: 8px;
    margin-bottom: 0 !important;
  }
`;

const HeaderContext = styled.div`
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  user-select: none;
  line-height: 1.5;
  color: #4a4a4a;

  span {
    color: ${(props) => props.theme.blueBorder};
    cursor: pointer;
    padding: 4px 0;
    &:hover {
      text-decoration: underline;
    }
  }
`;

interface Values {
  username: string;
  password: string;
}

interface Props {
  isAccountLocked: boolean;
  loginAttempts?: number;
  formikProps?: FormikProps<Values>;
}

const NewLoginFormHeader = ({ isAccountLocked, loginAttempts, formikProps }: Props) => {
  const currentRegion = getParsedAuthRegion();
  const [changeRegionModalOpenStatus, setChangeRegionModalOpenStatus] = useState(false);

  return (
    <>
      {changeRegionModalOpenStatus && (
        <ChangeRegionModal isOpen={changeRegionModalOpenStatus} setIsOpen={setChangeRegionModalOpenStatus} />
      )}
      <LoginFormHeaderSection>
        <HeaderTitle>
          {LOGIN_PAGE_CURRENT_REGION_TITLE} <AuthRegionFlag currentRegion={currentRegion} size="large" />
          {currentRegion === UNITED_STATES_REGION_CODE ? UNITED_STATES : AuthRegionMap[currentRegion]}
        </HeaderTitle>
        <HeaderContext>
          {LOGIN_BOX_DESCRIPTION_TEXT(currentRegion, AuthRegionMap)}{' '}
          <span onClick={() => setChangeRegionModalOpenStatus(true)}>{LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT}</span>
        </HeaderContext>
      </LoginFormHeaderSection>
      {!isAccountLocked && loginAttempts > 2 && (
        <WarningWrapper>
          <WarningIconWrapper>
            <AlertTriangle />
          </WarningIconWrapper>
          <div>
            <WarningDetailTitle>{LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_TITLE}</WarningDetailTitle>
            <WarningDetailSubInfo>{LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_SUB_INFO}</WarningDetailSubInfo>
          </div>
        </WarningWrapper>
      )}
      {isAccountLocked && (
        <WarningWrapper>
          <WarningIconWrapper>
            <AlertTriangle />
          </WarningIconWrapper>
          <div>
            <WarningDetailTitle>
              <span>{LOGIN_PAGE_LOCKED_ACCOUNT_MESSAGE}</span>
              <a href={HYPERCARE_SUPPORT_LINK} rel="noopener noreferrer" target="_blank">
                <br />
                {LOGIN_PAGE_LOCKED_ACCOUNT_CONTACT_US}
              </a>
              <span>{LOGIN_PAGE_LOCKED_ACCOUNT_HELP_TEXT}</span>
            </WarningDetailTitle>
          </div>
        </WarningWrapper>
      )}
    </>
  );
};

export default NewLoginFormHeader;
