import React, { useEffect } from 'react';
import { VirtualPagerStatusTypes, VirtualPagers } from 'src/types/VirtualPagers';
import styled from 'styled-components';
import EmptyCheckBox from 'src/assets/svgs/EmptyCheckBox';
import CheckBox from 'src/assets/svgs/CheckBox';
import { AlertTypeMap, CONFIGURE, REACTIVATE, getAssigneeSummary } from 'src/constants/virtualPagerStrings';
import theme from 'src/assets/styles/theme';
import moment from 'moment';
import ChevronRight from 'src/assets/svgs/ChevronRight';
import PagerStatusPopUp from 'src/pages/VirtualPagersPage/components/PagerStatusPopUp';
import Tooltip from '@material-ui/core/Tooltip';
import { AppRoutes } from 'src/router/AppRoutes';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const CustomTable = styled.table<{ lastItem: boolean }>`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${theme.borderBottomLight};
  border-bottom: 1px solid ${theme.borderBottomLight};

  &:hover {
    background: ${theme.hoverGrey};
  }
  border-radius: ${(props) => (props.lastItem ? '0px 0px 5px 5px' : '0px')};
`;

const StyledTBody = styled.tbody<{ lastItem: boolean }>`
  overflow: auto;
  border-radius: ${(props) => (props.lastItem ? '0px 0px 5px 5px' : '0px')};
`;

const TD = styled.td`
  justify-content: flex-start;
  border-bottom: 1px solid #ccc;
  padding: 16px 0px;
  align-items: center;

  &.number {
    padding: 16px 0px;
    padding-left: 12px;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  &.redirect {
    align-items: center;
    justify-self: center;
  }
`;

const ConfigureLink = styled.a`
  color: ${(props) => props.theme.mainTealColor};
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
`;

const IconButton = styled.button`
  &:hover {
    background: #e6e6e6;
  }
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  aspect-ratio: 1/1;
  border: none;
  cursor: pointer;
  border-radius: 1000px;
`;

const FriendlyNameText = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

const formatPhoneNumber = (inputNumber: string) => {
  const cleanedNumber = inputNumber.replace(/\D/g, '');
  const countryCode = cleanedNumber.substring(0, 1);
  const areaCode = cleanedNumber.substring(1, 4);
  const firstPart = cleanedNumber.substring(4, 7);
  const secondPart = cleanedNumber.substring(7);

  return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
};

type Props = {
  pager: VirtualPagers;
  isLast: boolean;
  isPopUpTopLeft: boolean;
  assignedToList: string[];
  onScrolledToBottom: () => Promise<void>;
  width: number;
};

const VirtualPagerMenuItem: React.FC<Props> = ({
  pager,
  isLast,
  isPopUpTopLeft,
  assignedToList,
  onScrolledToBottom,
  width,
}) => {
  const redirectToConfigurePageReactvatePopUp = () => {
    const configureRoute = `${AppRoutes.VirtualPagers}/${pager._id}?reactivatePopUp=true`;
    window.routerHistory.push(configureRoute);
  };
  const redirectToConfigurePage = (source) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaPagerConfigurationPageEntered,
      params: {
        virtual_pager_number: formatPhoneNumber(pager.pagerNumber),
        virtual_pager_number_status: pager.status.toString(),
        source: source,
      },
    });
    const configureRoute = `${AppRoutes.VirtualPagers}/${pager._id}`;
    window.routerHistory.push(configureRoute);
  };

  useEffect(() => {
    const handleScroll = async () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        await onScrolledToBottom();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [onScrolledToBottom]);

  const formattedNumber = formatPhoneNumber(pager.pagerNumber);
  const friendlyNameWidth = width * 0.13 - 16;

  return (
    <CustomTable id="tableEntry" lastItem={isLast}>
      <StyledTBody lastItem={isLast}>
        <tr>
          <TD
            className="number"
            style={{
              width: '13.27%',
              position: 'relative',
            }}
          >
            <ConfigureLink onClick={() => redirectToConfigurePage('number link')}>{formattedNumber}</ConfigureLink>
            {pager.friendlyName && (
              <FriendlyNameText
                style={{
                  width: `${friendlyNameWidth}px`,
                }}
              >
                {pager.friendlyName}
              </FriendlyNameText>
            )}
          </TD>

          <TD
            className="status"
            style={{
              width: '13.27%',
              position: 'relative',
              whiteSpace: 'nowrap',
            }}
          >
            <PagerStatusPopUp
              pagerStatus={pager.status}
              popupPosition={isPopUpTopLeft ? 'top left' : 'bottom left'}
              pagerAction={() => redirectToConfigurePage('pager status pop up')}
            />
          </TD>
          <TD
            style={{
              width: '21.01%',
              paddingLeft: '12px',
            }}
          >
            {assignedToList.length !== 0 ? (
              <Tooltip title={assignedToList.join(', ')} placement="top-start">
                <div>{getAssigneeSummary(assignedToList)}</div>
              </Tooltip>
            ) : null}
          </TD>
          <TD
            className="alert"
            style={{
              width: '8.62%',
            }}
          >
            {AlertTypeMap[pager.alertPreference]}
          </TD>
          <TD
            style={{
              width: '8.62%',
            }}
          >
            {pager.voicemail === null ? <EmptyCheckBox /> : <CheckBox />}
          </TD>
          <TD
            style={{
              width: '13.27%',
            }}
          >
            {moment(pager.dateCreated).format('DD/MMM/YYYY')}
          </TD>
          <TD
            style={{
              width: '13.27%',
            }}
          >
            {pager.lastUsed && moment(pager.lastUsed).format('DD/MMM/YYYY')}
          </TD>
          <TD
            className="redirect"
            style={{
              width: '8.62%',
            }}
          >
            {pager.status === VirtualPagerStatusTypes.PENDING ? (
              <ConfigureLink onClick={() => redirectToConfigurePage('configure button')}>{CONFIGURE}</ConfigureLink>
            ) : pager.status === VirtualPagerStatusTypes.DISABLED ? (
              <ConfigureLink onClick={redirectToConfigurePageReactvatePopUp}>{REACTIVATE}</ConfigureLink>
            ) : (
              <IconButton onClick={() => redirectToConfigurePage('arrow')}>
                <ChevronRight fill={'#767676'} width={24} height={24} />
              </IconButton>
            )}
          </TD>
        </tr>
      </StyledTBody>
    </CustomTable>
  );
};

export default VirtualPagerMenuItem;
