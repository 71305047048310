import React, { VFC } from 'react';
import styled from 'styled-components';
import {
  HCFilterOption,
  HCFilterSimpleProps,
  SelectableFilterItem,
} from 'src/components/@hc-ui/filters/variants/HCFilterSimple';
import InfiniteScroll from 'react-infinite-scroller';

import CustomSearchField from 'src/components/shared/CustomSearchField';
import {
  END_OF_USER_LIST_MESSAGE,
  INVITE_USER_MODAL_EMPTY_SEARCH_DESCRIPTION,
  INVITE_USER_MODAL_EMPTY_SEARCH_TITLE,
  INVITE_USER_MODAL_FLOW_SEARCH_PLACEHOLDER_TEXT,
  NO_SEARCH_RESULT_FOUND_TITLE,
  SELECTED,
  SHOW_SELECTED,
} from 'src/constants/strings';
import { CircularProgress } from '@material-ui/core';
import { HCHeadingFour, HCLabelOne, HCLabelTwo } from '../../../shared/HypercareComponents';
import theme from '../../../../assets/styles/theme';

const LoadingSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserSearchContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  padding: 8px;
`;

interface HCFilterSearchableProps extends HCFilterSimpleProps {
  onSearch?: (query: string, option: HCFilterOption) => boolean;
  idToBucket?: Map<string, string>;
  onClear?: () => void;
  searchText?: string;
  handleSearch?: (e) => void;
  fetchMorePaginatedData: () => void;
  isDoneFetchingMoreData: boolean;
  isSearchLoading: boolean;
}

const TitleSection = styled.div`
  color: #4a4a4a;
`;

const EmptySearchStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const SearchFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const SearchFieldHeaderActionButton = styled.button`
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${theme.mainTealColor};
  }
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
`;

export const HCFilterPaginatedSearch: VFC<HCFilterSearchableProps> = (props) => {
  const { searchText, handleSearch } = props;

  const selectedIds = new Set(props.selectedIds.map((value) => value.username));

  const renderData = (rows, noRowsString = 'No results found') => {
    return rows.length > 0 ? (
      rows.map((row) => (
        <SelectableFilterItem
          onToggle={props.onToggle}
          option={row}
          optionRender={props.optionRender}
          isSelected={selectedIds.has(row.username)}
        />
      ))
    ) : (
      <div>{noRowsString}</div>
    );
  };

  const dropdownResults = () => {
    if (searchText.length === 0) {
      if (selectedIds.size <= 0) {
        return (
          <EmptySearchStateContainer>
            <HCHeadingFour fontWeight={700} color={theme.textLightTertiary}>
              {INVITE_USER_MODAL_EMPTY_SEARCH_TITLE}
            </HCHeadingFour>
            <HCLabelOne color={theme.textLightTertiary}>{INVITE_USER_MODAL_EMPTY_SEARCH_DESCRIPTION}</HCLabelOne>
          </EmptySearchStateContainer>
        );
      }

      return (
        <div>
          <div style={{ paddingBottom: 10 }}>
            <HCLabelOne>
              {SELECTED} ({selectedIds.size})
            </HCLabelOne>
          </div>
          <div style={{ marginTop: 10 }}>{renderData(props.selectedIds, '')}</div>
        </div>
      );
    }

    if (
      searchText.length > 0 &&
      props.options.options.filter((options) => props.options.options.length > 0).length <= 0
    ) {
      return <div>{NO_SEARCH_RESULT_FOUND_TITLE}</div>;
    }

    return (
      <div>
        <div style={{ marginTop: 0 }}>
          {props.options.options.length > 1 && <TitleSection>{props.options.title}</TitleSection>}
          {props.options.options.length === 0 ? (
            <div style={{ paddingBottom: 10 }}>
              <HCLabelOne>{NO_SEARCH_RESULT_FOUND_TITLE}</HCLabelOne>
            </div>
          ) : (
            renderData(props.options.options)
          )}
        </div>
        {props.isDoneFetchingMoreData && (
          <div style={{ height: '25px', margin: '5px' }}>
            <HCHeadingFour fontWeight={400} color={theme.mainFontColor}>
              {END_OF_USER_LIST_MESSAGE}
            </HCHeadingFour>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <SearchFieldHeader>
        <HCLabelTwo color={theme.mainFontColor}>{INVITE_USER_MODAL_EMPTY_SEARCH_TITLE}</HCLabelTwo>
        {searchText.length >= 1 && (
          <SearchFieldHeaderActionButton type="button">
            <HCLabelTwo color={theme.mainTealColor} onClick={props.onClear}>
              {SHOW_SELECTED} ({selectedIds.size})
            </HCLabelTwo>
          </SearchFieldHeaderActionButton>
        )}
      </SearchFieldHeader>
      <CustomSearchField
        clearSearchText={props.onClear}
        handleChange={(e) => handleSearch(e.target.value)}
        localSearchText={searchText}
        placeHolder={INVITE_USER_MODAL_FLOW_SEARCH_PLACEHOLDER_TEXT}
        inputWidth={'600px'}
        inputHeight={'48px'}
      />
      {props.isSearchLoading ? (
        <LoadingSearchContainer>
          <CircularProgress />
        </LoadingSearchContainer>
      ) : (
        <UserSearchContainer>
          <InfiniteScroll
            loadMore={props.fetchMorePaginatedData}
            hasMore={!props.isDoneFetchingMoreData}
            initialLoad={false}
            useWindow={false}
          >
            {dropdownResults()}
          </InfiniteScroll>
        </UserSearchContainer>
      )}
    </>
  );
};
