import client from 'src/clients/apolloClient';
import { saveScopeToken } from 'src/utils/localStorageHelper';
import AnalyticsManager from 'src/analytics/AnalyticsManager';
import { DEPARTMENT, ORGANIZATION, SITE } from 'src/constants/organizationTypes';
import { USER_NOT_ADMIN } from 'src/constants/formErrors';
import { AuthPayload, OrganizationScope, UserOrganizationSwitcherPayload } from 'src/types';
import { getSelfInfo } from 'src/utils/getLocalAuth';
import { SetCurrentOrganizationAction } from 'src/redux/actions/headerAction';
import { HYPERCARE_REGION, HYPERCARE_USERNAME } from 'src/constants/storageKeys';

class AuthHelper {
  public static async saveScopeInfo(
    organizations: OrganizationScope[],
    setCurrentOrganization: (currentOrganization: UserOrganizationSwitcherPayload) => SetCurrentOrganizationAction,
  ) {
    try {
      const initialScope = await this.getInitialScope(organizations, setCurrentOrganization);
      if (!initialScope) {
        window.localStorage.clear();
        throw new Error(USER_NOT_ADMIN);
      }
      const userScopeString = JSON.stringify(initialScope);
      const getScopeToken = btoa(userScopeString);
      await saveScopeToken(getScopeToken);
      return Promise.resolve('done');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async setUpThirdParty(authInfo: AuthPayload) {
    AnalyticsManager.mixpanelLoginEvent(authInfo);
    this.connectToIntercom(authInfo);
  }

  public static async getInitialScope(
    organizations: OrganizationScope[],
    setCurrentOrganization: (currentOrganization: UserOrganizationSwitcherPayload) => SetCurrentOrganizationAction,
  ) {
    const userScope = {
      organizationId: null,
      siteId: null,
      departmentId: null,
    };
    const organizationHolder = {
      organization_id: null,
      site_id: null,
      department_id: null,
      name: null,
      type: null,
      image: null,
      organization_name: null,
    };
    let isAdminOccured = false;

    const setHolder = (org, type) => {
      organizationHolder.name = org.name;
      organizationHolder.type = type;
      organizationHolder.image = org.image;
    };

    for (let organization of organizations) {
      if (organization.isAdmin) {
        isAdminOccured = true;
        userScope.organizationId = organization.id;
        organizationHolder.organization_id = organization.id;
        organizationHolder.organization_name = organization.name;
        setHolder(organization, ORGANIZATION);
        setCurrentOrganization(organizationHolder);
        return userScope;
      }
      for (let site of organization.sites) {
        if (site.isAdmin) {
          isAdminOccured = true;
          userScope.organizationId = organization.id;
          userScope.siteId = site.id;
          organizationHolder.organization_id = organization.id;
          organizationHolder.site_id = site.id;
          organizationHolder.organization_name = organization.name;
          setHolder(site, SITE);
          setCurrentOrganization(organizationHolder);
          return userScope;
        }
        for (let department of site.departments) {
          if (department.isAdmin) {
            isAdminOccured = true;
            userScope.organizationId = organization.id;
            userScope.siteId = site.id;
            userScope.departmentId = department.id;
            organizationHolder.organization_id = organization.id;
            organizationHolder.site_id = site.id;
            organizationHolder.department_id = department.id;
            organizationHolder.organization_name = organization.name;
            setHolder(department, DEPARTMENT);
            setCurrentOrganization(organizationHolder);
            return userScope;
          }
        }
      }
    }
    if (isAdminOccured) setCurrentOrganization(organizationHolder);
    return isAdminOccured ? userScope : null;
  }

  public static logout() {
    client.clearStore();
    // analytics
    AnalyticsManager.resetMixpanel();
    // intercom
    this.shutdownIntercom();
    // app
    this.clearLocalStorage();
  }

  public static clearLocalStorage(username?: string) {
    const parsedSelfInfo = getSelfInfo();
    const localStoredUsername = localStorage.getItem(HYPERCARE_USERNAME);
    const localStoredRegion = localStorage.getItem(HYPERCARE_REGION);

    window.localStorage.clear();

    if (localStoredRegion) {
      localStorage.setItem(HYPERCARE_REGION, localStoredRegion);
    }

    if (localStoredUsername) {
      localStorage.setItem(HYPERCARE_USERNAME, parsedSelfInfo ? parsedSelfInfo.username : localStoredUsername);
    } else if (parsedSelfInfo) {
      localStorage.setItem(HYPERCARE_USERNAME, parsedSelfInfo.username as string);
    } else if (username) {
      localStorage.setItem(HYPERCARE_USERNAME, username);
    }
  }

  public static async connectToIntercom(authInfo: AuthPayload) {
    // TODO: setup intercom manager handle async boot for each individual page?
    // e.g. [Use your tour everywhere] feature will be invalid as no login event happen when page mount

    let INTERCOM_APP_ID;
    if (process.env.REACT_APP_ENV === 'production') {
      INTERCOM_APP_ID = 'dkvoyj1l';
    } else {
      INTERCOM_APP_ID = 'njhiahrn';
    }
    const { user } = authInfo;
    // @ts-ignore
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      user_id: user.id,
      updated_at: new Date().getTime(),
      hide_default_launcher: true,
    });
  }

  public static shutdownIntercom() {
    // @ts-ignore
    window.Intercom('shutdown');
  }
}

export default AuthHelper;
