import React from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import {
  DropItemType,
  DropSourceItem,
  EscalationLevelOnCallActionPayload,
  EscalationMember,
} from 'src/types/Escalation';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import AppTheme from 'src/assets/styles/theme';
import EscalationOnCallSource from 'src/pages/EscalationPage/escalation-layout/drop-source/EscalationOnCallSource';
import {
  BaseEscalationOnCallMembersWrapper,
  EscalationLevelTitle,
  OnCallMemberListWrapper,
} from 'src/pages/EscalationPage/escalation-layout/SharedEscalationStyles';

const AddIconHolder = styled.div`
  svg {
    width: 52px;
    height: 52px;
    color: lightgrey;
  }
`;

const AddTextHolder = styled.div`
  margin-top: 8px;
  line-height: 1.5em;
  text-align: center;
  color: grey;
  font-size: 16px;
  font-weight: 600;
`;

const PlaceHolderIndicationButtonText = styled.div`
  align-self: flex-start;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 4px;
  color: ${AppTheme.mainTealColor};
`;

const OnCallListDragPlaceHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: min-content;
  border: 1px dashed lightgrey;
  padding: 1.5em 0.5em;
  text-align: center;
  color: grey;
  span {
    font-size: 22px;
  }
`;

interface StyledDropzoneHolderProps {
  isOver: boolean;
  hasOncallList: boolean;
  isAllowedType: boolean;
  isDragging: boolean;
}

const DropzoneHolder = styled(BaseEscalationOnCallMembersWrapper)`
  border: ${(props: StyledDropzoneHolderProps) =>
    props.isOver || props.hasOncallList ? '1px solid lightgrey' : '1px dashed grey'};
  background-color: ${(props: StyledDropzoneHolderProps) => (props.isOver ? 'whitesmoke' : 'inherit')};
  align-items: ${(props: StyledDropzoneHolderProps) => (props.hasOncallList ? 'left' : 'center')};
  box-shadow: ${(props: StyledDropzoneHolderProps) =>
    props.isOver ? '0 4px 4px 0 rgba(219, 219, 219, 0.25)' : 'unset'};
  border-color: ${(props: StyledDropzoneHolderProps) =>
    props.isAllowedType && props.isDragging ? AppTheme.mainTealColor : 'auto'};
  border-style: ${(props: StyledDropzoneHolderProps) => (props.isAllowedType && props.isDragging ? 'solid' : 'auto')};
  border-width: ${(props: StyledDropzoneHolderProps) => (props.isAllowedType && props.isDragging ? '2px' : 'auto')};
  filter: ${(props: StyledDropzoneHolderProps) => (!props.isAllowedType ? 'blur(2px)' : 'unset')};
`;

interface Props {
  levelIndex: number;
  escalationOnCall: EscalationMember[];
}

const EscalationOnCallDropZone = ({ levelIndex, escalationOnCall }: Props) => {
  const ALLOWED_TYPES: DropItemType[] = ['user', 'role', 'droppedRole', 'droppedUser'];
  const PLACE_HOLDER_ID = `escalationMemberPlaceholder-${levelIndex}`;

  const placeHolderIndicationTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>(null);
  const [isPlaceHolderIndicated, setPlaceHolderIndicated] = React.useState(false);

  const dispatch = useDispatch();
  const setEscalationOncall = (payload: EscalationLevelOnCallActionPayload) =>
    dispatch(allActions.escalationAction.setEscalationLevelOnCall(payload));

  const [{ isDragging, isAllowedType, isOver }, drop] = useDrop({
    accept: ALLOWED_TYPES,
    drop(item: DropSourceItem) {
      setEscalationOncall({
        levelIndex,
        onCallData: item.data as EscalationMember,
        originLevelIndex: item.originLevelIndex,
      });
      clearPlaceHolderTimeout();
      setPlaceHolderIndicated(false);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDragging: !!monitor.getItem(),
      isAllowedType: monitor.getItemType() === null || ALLOWED_TYPES.includes(monitor.getItemType() as DropItemType),
    }),
  });

  React.useEffect(() => {
    return () => {
      clearPlaceHolderTimeout();
    };
  }, []);

  const clearPlaceHolderTimeout = () => {
    if (placeHolderIndicationTimeoutRef.current) clearTimeout(placeHolderIndicationTimeoutRef.current);
  };

  const handlePlaceHolderIndication = () => {
    clearPlaceHolderTimeout();

    if (isPlaceHolderIndicated) {
      let targetPlaceHolder = document.getElementById(PLACE_HOLDER_ID);
      if (!targetPlaceHolder) return;
      targetPlaceHolder.className += ' flashAnimation';
      placeHolderIndicationTimeoutRef.current = setTimeout(() => {
        targetPlaceHolder.classList.remove('flashAnimation');
      }, 3000); // fake indicator last for 1sec
    } else {
      setPlaceHolderIndicated(true);
    }
  };

  const renderEmptyPlaceHolder = () => {
    return (
      <React.Fragment>
        <AddIconHolder>
          <AddIcon />
        </AddIconHolder>
        <AddTextHolder>Drag a role or person here to set up escalation</AddTextHolder>
      </React.Fragment>
    );
  };

  const renderEscalationLevelOnCalls = () => {
    return (
      <React.Fragment>
        <EscalationLevelTitle>Escalation Level {levelIndex + 1}</EscalationLevelTitle>
        <OnCallMemberListWrapper>
          {escalationOnCall.map((onCallMember, i) => (
            <EscalationOnCallSource
              dropSourceItem={{
                type: onCallMember.role ? 'droppedRole' : 'droppedUser',
                data: {
                  user: onCallMember.user || null,
                  role: onCallMember.role || null,
                },
                originLevelIndex: levelIndex,
              }}
              key={i}
            />
          ))}
        </OnCallMemberListWrapper>

        {isPlaceHolderIndicated && (
          <OnCallListDragPlaceHolder id={PLACE_HOLDER_ID}>
            <AddIconHolder>
              <AddIcon />
            </AddIconHolder>
            <div>Drag another role or person to trigger simultaneous escalations</div>
          </OnCallListDragPlaceHolder>
        )}
      </React.Fragment>
    );
  };

  const hasOncallList = escalationOnCall.length > 0;

  return (
    <Tooltip
      title={'Drop Here'}
      PopperProps={{
        disablePortal: true,
      }}
      placement="right"
      open={isOver || (isAllowedType && isDragging)}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
    >
      <DropzoneHolder
        ref={drop}
        isOver={isOver}
        hasOncallList={hasOncallList}
        isAllowedType={isAllowedType}
        isDragging={isDragging}
      >
        {!hasOncallList ? renderEmptyPlaceHolder() : renderEscalationLevelOnCalls()}
        {hasOncallList && (
          <PlaceHolderIndicationButtonText onClick={handlePlaceHolderIndication}>
            + Add another role or person
          </PlaceHolderIndicationButtonText>
        )}
      </DropzoneHolder>
    </Tooltip>
  );
};

export default EscalationOnCallDropZone;
