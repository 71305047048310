import React from 'react';
import Routes from './router';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import AuthProvider from 'src/auth/AuthProvider';
import client from 'src/clients/apolloClient';
import { Provider } from 'react-redux';
import store from 'src/redux/store';
import StyledToastContainer from 'src/components/StyledToastContainer';
import { Auth0Provider } from '@auth0/auth0-react';
import PromptsManager from 'src/components/PromptsManager';

import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import ForcedLogoutTimerHandler from 'src/components/SessionTimeout/forcedLogoutTimerHandler';
import IdleTimerHandler from 'src/components/SessionTimeout/IdleTimerHandler';

import theme from 'src/assets/styles/theme';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import 'src/assets/styles/ModalStyles.scss';
import { useGetGeoLocation } from './utils/useGetGeoLocation';

const onRedirectCallback = () => {
  window.history.replaceState({}, document.title, redirectUri);
  window.routerHistory.replace(`/ssoredirect`);
};

const redirectUri = `${window.location.origin}/ssoredirect`;

const App = () => {
  const sessionTimeoutFlag = IsFeatureFlagEnabled('sessionTimeout');

  const { region } = useGetGeoLocation();

  if (!region) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={redirectUri}
            onRedirecting={onRedirectCallback}
          >
            <AuthProvider>
              <div className="App" data-testid="app">
                {sessionTimeoutFlag && (
                  <>
                    <ForcedLogoutTimerHandler />
                    <IdleTimerHandler />
                  </>
                )}
                <Routes />
                <PromptsManager />
                <StyledToastContainer
                  position="top-center"
                  closeOnClick={true}
                  autoClose={4000}
                  hideProgressBar={true}
                />
              </div>
            </AuthProvider>
          </Auth0Provider>
        </Provider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
