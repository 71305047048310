import React from 'react';
import ChevronDown from 'src/assets/svgs/ChevronDown';
import { LASTNAME, JOIN_DATE } from 'src/constants/SorterTypes';
import allActions from 'src/redux/actions';
import { SorterType, UserMenuOptionStatus } from 'src/types';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { typedUseSelector } from 'src/redux/store';
import ChevronUp from 'src/assets/svgs/ChevronUp';
import { USER_MENU_OPTION } from 'src/constants/inviteUserTypes';
import { IsFeatureFlagEnabled } from '../../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../../utils/FeatureFlags';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortButtons: {
      gridTemplateColumns: '0.4fr 0.8fr 0fr 0.8fr 0.4fr !important',
      marginLeft: '30px !important',
    },
  }),
);

const ChevronDownContainer = ({ shouldTransform }) => {
  return <div className="chevronHolder">{shouldTransform ? <ChevronUp /> : <ChevronDown />}</div>;
};

interface Props {
  sorterType: SorterType;
  setSorterType: React.Dispatch<React.SetStateAction<SorterType>>;
  currentMenuOption: UserMenuOptionStatus;
}

const SortButtons = ({ sorterType, setSorterType, currentMenuOption }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSortLastName = typedUseSelector((state) => state.flagReducer.sortLastNameRev);
  const isSortJoinDate = typedUseSelector((state) => state.flagReducer.sortJoinDateRev);
  const sortLastName = () => dispatch(allActions.flagAction.sortLastName());
  const sortJoinDate = () => dispatch(allActions.flagAction.sortJoinDate());

  const isPCFeatureFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  function sortingActions(type: SorterType) {
    if (type === LASTNAME) {
      sortLastName();
    } else if (type === JOIN_DATE) {
      sortJoinDate();
    }

    if (sorterType !== type) setSorterType(type);
  }

  return (
    <div className="sortButtonHolder">
      <div className={`sortButtons ${classes.sortButtons}`}>
        <div title="Sort users by last name" className="sortButtons__user" onClick={() => sortingActions(LASTNAME)}>
          <span>User</span>
          {!isPCFeatureFlagEnabled && (
            <ChevronDownContainer shouldTransform={sorterType === LASTNAME && isSortLastName} />
          )}
        </div>

        <div title="Sort users by license status" className="sortButtons__userStatus" style={{ marginLeft: '30px' }}>
          <span>status</span>
        </div>

        {currentMenuOption === USER_MENU_OPTION.All ||
          (currentMenuOption === USER_MENU_OPTION.Licensed && (
            <div
              title="Sort users by join date"
              className="sortButtons__joinDate"
              onClick={() => sortingActions(JOIN_DATE)}
              style={{ marginLeft: '126px' }}
            >
              <span>Join Date</span>
              {!isPCFeatureFlagEnabled && (
                <ChevronDownContainer shouldTransform={sorterType === JOIN_DATE && isSortJoinDate} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SortButtons;
