import gql from 'graphql-tag';
import {
  FullOrganizationUserResultFragment,
  FullOrganizationUserDirSyncResultFragment,
} from '../fragment/FullOrganizationUserResultFragment';

export const FETCH_USER_PROFILE = gql`
  query FetchUserProfile(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $isProfileAddressLabelFlagEnabled: Boolean!
  ) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserResultFragment
            }
          }
          ... on AdminSiteQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserResultFragment
            }
          }
          ... on AdminDepartmentQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserResultFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationUserResultFragment}
`;

export const FETCH_USER_PROFILE_DIR_SYNC = gql`
  query FetchUserProfileWithDirSyncFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $isProfileAddressLabelFlagEnabled: Boolean!
  ) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
          ... on AdminSiteQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
          ... on AdminDepartmentQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationUserDirSyncResultFragment}
`;
