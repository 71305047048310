import gql from 'graphql-tag';

export const ADMIN_UPDATE_ROLE_NOTE = gql`
  mutation UpdateRoleNote(
    $organizationalUnit: OrganizationalUnitInput!
    $scheduleId: Int!
    $roleId: Int!
    $noteId: ID!
    $details: UpdateHiddenNoteDetails!
  ) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminDepartmentMutation {
            __typename
            schedule(id: $scheduleId) {
              role(id: $roleId) {
                note(id: $noteId) {
                  __typename
                  ... on AccessForbiddenError {
                    __typename
                    message
                  }
                  ... on NoteNotFoundError {
                    __typename
                    message
                  }
                  ... on NoteDeletedError {
                    __typename
                    message
                  }
                  ... on RoleNoteMutation {
                    update(details: $details) {
                      ... on RoleNote {
                        __typename
                        id
                        note
                        access
                        createdAt
                        updatedAt
                        editedBy {
                          __typename
                          ...OrganizationUserResultFields
                        }
                        createdBy {
                          __typename
                          ...OrganizationUserResultFields
                        }
                      }
                      ... on ExceedMaxWordCountError {
                        __typename
                        message
                        maxCount
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment OrganizationUserResultFields on OrganizationUserResult {
    __typename
    ... on Node {
      id
    }
    ... on OrganizationMember {
      ...NamePartsUserFields
      role
    }
    ... on PublicUser {
      ...NamePartsUserFields
    }
  }

  fragment NamePartsUserFields on GeneralUser {
    username
    firstName
    lastName
    avatar {
      url
    }
  }
`;
