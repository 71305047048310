import React from 'react';
import { connect } from 'react-redux';
import { AuthContext } from 'src/auth/AuthProvider';
import store, { RootState } from 'src/redux/store';
import AcceptTOSModal from 'src/components/modals/AcceptTOSModal';
import client from 'src/clients/apolloClient';
import FetchOrganizationTOSStatus from 'src/gql/query/FetchOrganizationTOSStatus';
import GetSelfOrganizations from 'src/gql/query/GetSelfOrganizationQuery';
import { UserOrganizationSwitcherPayload } from 'src/types';

interface Props {
  isLoggedIn: boolean;
  currentOrganization: UserOrganizationSwitcherPayload;
}

const PromptsManager = ({ isLoggedIn, currentOrganization }: Props) => {
  const [showTOSModal, setTOSModal] = React.useState(false);
  const [tosURL, setTOSUrl] = React.useState('');

  /**
   * Show Terms of service if user first login or switch org when:
   * - user tos status is false
   * - organization has a tosUrl
   */
  React.useEffect(() => {
    const determineOrganizationTOSStatus = async () => {
      const organizationId = store.getState().organizationReducer.organization_id;
      if (!organizationId) return;

      const { data } = await client.query({
        query: FetchOrganizationTOSStatus,
        variables: { organizationId },
      });
      // flag for check is user accepted org tos
      const { organizationTosStatus } = data.me;

      if (!organizationTosStatus) {
        const selfOrganizations = await client.query({
          query: GetSelfOrganizations,
        });
        const organizationsList: {
          name: string;
          id: number;
          image: string;
          isAdmin: boolean;
          termsOfServiceUrl: string;
        }[] = selfOrganizations.data.me.organizations;

        const selectedOrg = organizationsList.find((organization) => organization.id === organizationId);
        const { termsOfServiceUrl } = selectedOrg;

        setTOSModal(true);
        setTOSUrl(termsOfServiceUrl);
      }
    };

    if (isLoggedIn) {
      determineOrganizationTOSStatus();
    } else {
      if (showTOSModal) setTOSModal(false);
      if (tosURL) setTOSUrl('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, isLoggedIn]);

  if (isLoggedIn && showTOSModal && tosURL) {
    return (
      <AcceptTOSModal
        tosURL={tosURL}
        isOpen={showTOSModal}
        currentOrganization={currentOrganization}
        handleCloseModal={() => {
          setTOSUrl('');
          setTOSModal(false);
        }}
      />
    );
  }

  return null;
};

const mapStateToProps = (state: RootState) => ({
  currentOrganization: state.organizationReducer,
});

const RenderPropsManager = React.memo(connect(mapStateToProps)(PromptsManager));

export default () => (
  <AuthContext.Consumer>{({ isLoggedIn }) => <RenderPropsManager isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>
);
