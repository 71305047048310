import _ from 'lodash';
import * as React from 'react';
import BaseAccordion from './BaseAccordion';
import {
  ACCORDION_ALERT_PREFERENCE_DEFAULT_DESCRIPTION,
  ACCORDION_ALERT_PREFERENCE_HEADER,
  alertMapping,
} from 'src/constants/virtualPagerStrings';
import AlertPreferenceAccordionDetails from './AccordionDetails/AlertPreferenceAccordionDetails';
import { typedUseSelector } from 'src/redux/store';
import { setCurrentPager } from 'src/redux/actions/virtualPagersActions';
import { useDispatch } from 'react-redux';
import { ChatType, MessageAlertType, VirtualPagerStatusTypes, VirtualPagers } from 'src/types';
import ConfigureNumberViewModel from '../../ConfigureNumberViewModel';
import VirtualPagerTutorialButton from '../VirtualPagerTutorialButton';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const AlertPreferenceAccordion = () => {
  const dispatch = useDispatch();
  const { editedPager, currentPager } = typedUseSelector((state) => state.virtualPagerReducer);
  const [alertPreference, setAlertPreference] = React.useState<MessageAlertType>(null);
  const [chatPreference, setChatPreference] = React.useState<ChatType>(null);

  const { configureChatPreferences } = ConfigureNumberViewModel();

  React.useEffect(() => {
    if (currentPager) {
      setAlertPreference(currentPager.alertPreference);
      setChatPreference(localGroupChatState(currentPager));
    }
  }, []);

  React.useEffect(() => {
    if (editedPager && editedPager.roleIds.length <= 1 && editedPager.userIds.length <= 1) {
      setChatPreference('single');
    }
  }, [editedPager]);

  const localGroupChatState = (pager: VirtualPagers) => {
    if (pager.isGroupChat === null) {
      return null;
    }
    return pager.roleIds.length <= 1 && pager.userIds.length <= 1 ? 'single' : pager.isGroupChat ? 'group' : 'single';
  };

  const isFieldValid = !_.isNil(alertPreference) && !_.isNil(chatPreference);

  const isUnsaved = isFieldValid
    ? currentPager.alertPreference !== alertPreference || currentPager.isGroupChat !== (chatPreference === 'group')
    : false;

  const trackClick = (expanded) => {
    if (!expanded) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaAlertAndNotificationSetupClosedPressed,
        params: {
          virtual_pager_number: currentPager.pagerNumber,
          alertType: alertPreference,
          chatType: chatPreference,
        },
      });
    } else {
      const containerStatus = !isFieldValid ? 'Pending Configuration' : !isUnsaved ? 'Configured' : 'Unsaved Changes';
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaConfigurationSetAlertNotificationPressed,
        params: {
          container_status: containerStatus,
        },
      });
    }
  };

  function TutorialButton() {
    return VirtualPagerTutorialButton('alertPreference');
  }

  return (
    <>
      {editedPager && currentPager ? (
        <BaseAccordion
          title={ACCORDION_ALERT_PREFERENCE_HEADER}
          description={{
            Generic: ACCORDION_ALERT_PREFERENCE_DEFAULT_DESCRIPTION,
            PagerSpecific:
              currentPager.alertPreference &&
              (currentPager.isGroupChat
                ? `${alertMapping(currentPager.alertPreference)} message to a group chat`
                : `${alertMapping(currentPager.alertPreference)} message to a single chat`),
          }}
          trackClick={trackClick}
          handleSave={async () => {
            dispatch(
              setCurrentPager(
                await configureChatPreferences(editedPager._id, alertPreference, chatPreference === 'group'),
              ),
            );
            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.pvpaAlertAndNotificationSavePressed,
              params: {
                virtual_pager_number: currentPager.pagerNumber,
                alertType: alertPreference,
                chatType: chatPreference,
              },
            });
          }}
          isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
          isSaveButtonDisabled={!isUnsaved}
          isUnsaved={isUnsaved}
          ExtraDescription={<TutorialButton />}
          isFieldValid={isFieldValid}
          AccordionDetail={
            <AlertPreferenceAccordionDetails
              isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
              alertPreference={alertPreference}
              setAlertPreference={setAlertPreference}
              chatPreference={chatPreference}
              setChatPreference={setChatPreference}
            />
          }
        />
      ) : null}
    </>
  );
};

export default AlertPreferenceAccordion;
