import gql from 'graphql-tag';
import MessageTemplateFormFragment from 'src/gql/fragment/MessageTemplateFormFragment';

export const GET_TEMPLATE_AUDIT_REPORT = gql`
  query FetchAdminTemplateMessageAuditReport(
    $templateIds: [ID!]!
    $startDate: String!
    $endDate: String!
    $senderUserIds: [ID!]!
    $recipientUserIds: [ID!]!
  ) {
    admin {
      organization {
        messageTemplates {
          report(
            templateIds: $templateIds
            startDate: $startDate
            endDate: $endDate
            senderUserIds: $senderUserIds
            recipientUserIds: $recipientUserIds
          ) {
            requestId
          }
        }
      }
    }
  }
`;

export const GET_MESSAGE_TEMPLATE_REPORT = gql`
  query FetchAdminTemplateMessageAuditReport {
    admin {
      organization {
        messageTemplates {
          templates {
            ...MessageTemplateFormFragment
          }
        }
      }
    }
  }
  ${MessageTemplateFormFragment}
`;
