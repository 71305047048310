import React from 'react';
import { connect } from 'react-redux';
import { AuthContext } from 'src/auth';
import { RootState } from 'src/redux/store';
import { IdentifyUser } from 'src/utils/FeatureFlagManager';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface Props {
  isLoggedIn: boolean;
  isAuthorized: boolean;
  props: RouteProps;
}

const ConnectedProtectedRoute = ({ isAuthorized, isLoggedIn, props }: Props) => {
  if (isLoggedIn) {
    IdentifyUser();
  }

  return isAuthorized ? <Route {...props} /> : <Redirect to="/" />;
};

const mapStateToProps = (state: RootState) => {
  return {
    isAuthorized: state.flagReducer.isAuthorized,
  };
};

const ConnectedProtectedRoutes = connect(mapStateToProps, null)(ConnectedProtectedRoute);

export default (props: RouteProps) => (
  <AuthContext.Consumer>
    {({ isLoggedIn }) => <ConnectedProtectedRoutes isLoggedIn={isLoggedIn} props={props} />}
  </AuthContext.Consumer>
);
