import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Grid, Typography, Card, CardActions, CardContent, CardActionArea } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      padding: '24px',
      marginBottom: '10px',
    },
    card: {
      background: '#FFFFFF',
      border: '0.5px solid #D8D8D8',
      boxShadow: '0px 0.3px 0.9px rgb(0,0,0,0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.14)',
      borderRadius: '4px',
      width: '437px',
      height: '204px',
      left: '111px',
      top: '544px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    header: {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: '#222222',
      flex: 'none',
      order: 0,
      flexGrow: 0,
      margin: '8px 0px',
    },
    body: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      color: '#767676',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      margin: '8px 0px',
    },
    cardAction: {
      padding: '0px',
      marginTop: '18px',
    },
    link: {
      textDecoration: 'none',
      color: '#00859A',
      height: '24px',
      left: '0px',
      right: '24px',
      top: '10px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    arrowIcon: {
      fontSize: 12,
      marginLeft: '8px',
    },
  }),
);

const ReportTabPanel: React.FC = () => {
  const classes = useStyles();

  const handleLink = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.exportReportPressed,
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={4} sm={12}>
        <Card variant="outlined" className={classes.card}>
          <CardActionArea>
            <CardContent className={classes.cardContent}>
              <Typography variant="body1" className={classes.header}>
                Templated messages reports
              </Typography>
              <Typography variant="body2" className={classes.body}>
                Generate summary reports based on templated messages that you did not personally send or receive (e.g
                for a department)
              </Typography>
              <CardActions className={classes.cardAction}>
                <Link to="/templatereport" onClick={() => handleLink()} className={classes.link}>
                  Export Reports<ArrowForwardIosIcon className={classes.arrowIcon}></ArrowForwardIosIcon>
                </Link>
              </CardActions>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ReportTabPanel;
