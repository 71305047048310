import gql from 'graphql-tag';

export default gql`
  mutation CreateEscalationLadder($ladderName: String!, $escalationLevels: [EscalationLevelDetails]) {
    admin {
      createEscalationLadder(ladderName: $ladderName, escalationLevels: $escalationLevels) {
        id
      }
    }
  }
`;
