import React from 'react';

import { ResponsiveContainer } from 'recharts';

interface IProps {
  height: number | string;
  width: number | string;
}

const Chart: React.FC<IProps> = (props) => {
  const { height, width, children } = props;
  return (
    <ResponsiveContainer height={height} width={width}>
      {children}
    </ResponsiveContainer>
  );
};

Chart.defaultProps = {
  height: 100,
  width: 100,
};

export default Chart;
