import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import Chart from 'src/components/analytics/Chart';

interface IProps {
  data: any[];
  height: number | string;
  width: number | string;
  colors: string[];
  dataKey: string;
  innerRadius?: number;
  outerRadius?: number;
  cx?: number;
  cy?: number;
  paddingAngle?: number;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="central" style={{ fontSize: 12 }}>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChartComponent: React.FC<IProps> = (props) => {
  const { width, height, data, colors, dataKey, innerRadius, outerRadius, cx, cy, paddingAngle } = props;
  return (
    <Chart height={height} width={width}>
      <PieChart width={800} height={400} margin={{ top: -10, left: -10, right: -10, bottom: -10 }}>
        <Pie
          data={data}
          cx={cx}
          cy={cy}
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          dataKey={dataKey}
          paddingAngle={paddingAngle}
        >
          {data.map((_, index) => (
            <Cell key={String(index)} fill={colors[index % colors.length]}>
              test
            </Cell>
          ))}
        </Pie>
      </PieChart>
    </Chart>
  );
};

export default PieChartComponent;
