/**
 * Ideally when click into the chat, resolver should diff unread message id and clear them accordingly
 * update the chatList base on that chatId to check if unread message or priority unread message is empty
 * and clear the unread state is necessary
 */

const resolvers = {
  Query: {
    // TODO: add @client for chat resolvers in the future
    // chat(obj, args, { cache: cached }) {
    //   console.log(obj, args, cached.data)
    // }
  },
  Mutation: {},
};

export default resolvers;
