import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import HypercareModal from 'src/components/shared/HypercareModal';
import { ModalProps } from 'src/types/HiddenNotes';

const ModalBodyContainer = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
`;

const ModalNoteDescription = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  white-space: pre-line;
  word-wrap: break-word;
`;

const ModalBody = (props) => {
  return (
    <ModalBodyContainer>
      <p>{`You’re about to delete this note:`}</p>
      <ModalNoteDescription>"{props.note}"</ModalNoteDescription>
      <p>{`This action cannot be undone.`}</p>
    </ModalBodyContainer>
  );
};

const DeleteNoteModal = ({ openModal, note, handleCloseModal, handlePrimayButton }: ModalProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  return (
    <HypercareModal
      title={'Delete this note?'}
      body={<ModalBody note={note.note} />}
      size={'xs'}
      closeModal={handleCloseModal}
      isModalVisible={openModal}
      modalButtons={[
        {
          buttonLabel: 'Cancel',
          type: 'secondary',
          onClickHandler: handleCloseModal,
        },
        {
          buttonLabel: 'Delete',
          type: 'primary',
          disabled: isSubmitting,
          onClickHandler: () => handlePrimayButton(note.id, setIsSubmitting),
        },
      ]}
    />
  );
};

export default DeleteNoteModal;
