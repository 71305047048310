import React from 'react';
import { FlagIcons } from 'src/auth/authRegionData';
import { AuthRegion } from 'src/types';
import styled from 'styled-components';

export interface AuthRegionFlagProps {
  currentRegion: AuthRegion;
  size: 'small' | 'large';
}

const StyledFlagImage = styled.img.attrs((props: AuthRegionFlagProps) => ({ ...props }))`
  width: ${(props) => (props.size === 'large' ? '32px' : '16px')};
  height: ${(props) => (props.size === 'large' ? '32px' : '16px')};
  margin-right: 4px;
  margin-left: 8px;
`;

export const AuthRegionFlag: React.FC<AuthRegionFlagProps> = ({ currentRegion, size }) => {
  const src = FlagIcons[currentRegion];
  const alt = `${currentRegion} flag`;
  return <StyledFlagImage src={src} alt={alt} size={size} />;
};
