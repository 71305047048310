import moment from 'moment';
import { UPDATE_DATE_RANGE, REFETCH } from 'src/constants/actionNames';
import { IDateRange } from 'src/types';
import { AnalyticsActionTypes } from '../actions/analyticsAction';

let initialState: AnalyticsDataReducer = {
  dateRange: [
    {
      startDate: moment().subtract(30, 'day').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
    },
  ],
  updatedOn: new Date(),
  refreshOn: new Date(),
};

export interface AnalyticsDataReducer {
  dateRange: IDateRange[];
  updatedOn: Date;
  refreshOn: Date;
}

export const analyticsReducer = (state = initialState, action: AnalyticsActionTypes): typeof initialState => {
  switch (action.type) {
    case UPDATE_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload,
        updatedOn: new Date(),
      };
    }
    case REFETCH: {
      return {
        ...state,
        updatedOn: new Date(),
        refreshOn: new Date(action.payload),
      };
    }
    default:
      return state;
  }
};
