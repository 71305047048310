import gql from 'graphql-tag';
import FullUserFragment from 'src/gql/fragment/FullUserFragment';

export default gql`
  mutation PromoteUser($userId: ID!, $scopes: [OrganizationScopeInput!]!) {
    admin {
      user(id: $userId) {
        promoteMultiple(scopes: $scopes) {
          ...FullUserFragment
        }
      }
    }
  }
  ${FullUserFragment}
`;
