import {
  CANADA,
  CANADA_REGION_CODE,
  EUROPE,
  EUROPE_REGION_CODE,
  UNITED_STATES,
  UNITED_STATES_REGION_CODE,
} from 'src/constants/strings';
import { IAuthRegionData } from 'src/types';

import canadaFlag from '../assets/static/canadaFlagIcon.svg';
import usFlagIcon from '../assets/static/usFlagIcon.svg';
import euFlagIcon from '../assets/static/euFlagIcon.svg';

export const FlagIcons = {
  [CANADA_REGION_CODE]: canadaFlag,
  [UNITED_STATES_REGION_CODE]: usFlagIcon,
  [EUROPE_REGION_CODE]: euFlagIcon,
};

export const authRegionData: IAuthRegionData[] = [
  {
    region: CANADA_REGION_CODE,
    regionName: CANADA,
    flagIcon: FlagIcons[CANADA_REGION_CODE],
  },
  {
    region: UNITED_STATES_REGION_CODE,
    regionName: UNITED_STATES,
    flagIcon: FlagIcons[UNITED_STATES_REGION_CODE],
  },
  {
    region: EUROPE_REGION_CODE,
    regionName: EUROPE,
    flagIcon: FlagIcons[EUROPE_REGION_CODE],
  },
];
