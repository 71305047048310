import * as React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill="#00859A"
      d="M13.938 12.063h-1.25v-1.25c0-.07-.057-.126-.126-.126h-.75c-.068 0-.124.057-.124.126v1.25h-1.25c-.07 0-.126.056-.126.124v.75c0 .07.057.126.126.126h1.25v1.25c0 .068.056.124.124.124h.75c.07 0 .126-.056.126-.124v-1.25h1.25c.068 0 .124-.057.124-.126v-.75c0-.068-.056-.124-.124-.124zM5.835 7.787c-.014-.135-.022-.273-.022-.412 0-.248.024-.49.067-.727.011-.056-.018-.114-.07-.137-.213-.095-.408-.227-.577-.392-.402-.39-.621-.931-.604-1.49.014-.502.215-.979.567-1.338.386-.396.905-.611 1.456-.605.499.005.98.197 1.344.537.123.116.23.244.319.382.03.048.092.068.145.05.275-.096.566-.163.864-.194.088-.01.138-.103.098-.181-.507-1.005-1.545-1.699-2.745-1.717-1.731-.027-3.175 1.393-3.175 3.125 0 .98.452 1.856 1.16 2.43-.497.229-.955.546-1.352.943-.856.855-1.34 1.983-1.372 3.187 0 .034.012.067.036.09.023.025.055.039.09.039h.876c.067 0 .123-.054.125-.12.03-.907.397-1.755 1.042-2.4.46-.459 1.022-.777 1.636-.932.06-.017.1-.075.092-.138zm7.039-.412c0-1.71-1.373-3.098-3.077-3.125-1.73-.027-3.173 1.394-3.173 3.125 0 .981.453 1.856 1.16 2.43-.503.232-.96.552-1.35.943-.857.855-1.341 1.983-1.373 3.186 0 .034.012.067.036.09.024.025.056.039.09.039h.875c.067 0 .123-.054.125-.12.03-.907.396-1.755 1.042-2.4.673-.673 1.567-1.043 2.52-1.043 1.725 0 3.125-1.398 3.125-3.125zm-1.71 1.414c-.38.378-.88.586-1.415.586-.534 0-1.036-.208-1.414-.586-.381-.38-.593-.897-.586-1.434.005-.513.21-1.008.567-1.375.375-.385.877-.599 1.413-.605.53-.005 1.043.202 1.422.572.387.38.6.887.6 1.428-.002.534-.21 1.036-.588 1.414z"
    />
  </svg>
);
