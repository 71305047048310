import { IActiveMemberPipelineResult, IMessageByTypePipelineResult, Period } from 'src/types';

export const transformActiveMemberPipeline = (data: IActiveMemberPipelineResult) => {
  const result = {};

  if (!data) {
    return [];
  }
  if (data.analytics.activeMemberTimelines.series && data.analytics.activeMemberTimelines.series[0]) {
    data.analytics.activeMemberTimelines.series[0].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['active_member'] = element.value;
    });
  }
  if (data.analytics.messageSentTimelines.series && data.analytics.messageSentTimelines.series[0]) {
    data.analytics.messageSentTimelines.series[0].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['message_sent'] = element.value;
    });
  }

  // console.log(
  //   Object.keys(result).map((key) => ({
  //     name: key,
  //     active_member: result[key].active_member,
  //     message_sent: result[key].message_sent,
  //   })),
  // );
  return Object.keys(result).map((key) => ({
    name: key,
    active_member: result[key].active_member,
    message_sent: result[key].message_sent,
  }));
};

export const transformMessageByTypePipeline = (data: IMessageByTypePipelineResult) => {
  const result = {};

  if (!data) {
    return [];
  }

  if (data.analytics.messageByTypesTimelines.series && data.analytics.messageByTypesTimelines.series[0]) {
    data.analytics.messageByTypesTimelines.series[0].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['total'] = element.value;
    });
  }
  if (data.analytics.messageByTypesTimelines.series && data.analytics.messageByTypesTimelines.series[1]) {
    data.analytics.messageByTypesTimelines.series[1].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['text'] = element.value;
    });
  }
  if (data.analytics.messageByTypesTimelines.series && data.analytics.messageByTypesTimelines.series[2]) {
    data.analytics.messageByTypesTimelines.series[2].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['image'] = element.value;
    });
  }
  if (data.analytics.messageByTypesTimelines.series && data.analytics.messageByTypesTimelines.series[3]) {
    data.analytics.messageByTypesTimelines.series[3].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['system'] = element.value;
    });
  }
  if (data.analytics.messageByTypesTimelines.series && data.analytics.messageByTypesTimelines.series[4]) {
    data.analytics.messageByTypesTimelines.series[4].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['consult'] = element.value;
    });
  }
  if (data.analytics.messageByTypesTimelines.series && data.analytics.messageByTypesTimelines.series[5]) {
    data.analytics.messageByTypesTimelines.series[5].values.forEach((element) => {
      if (!result[element.label]) {
        result[element.label] = {};
      }
      result[element.label]['attachment'] = element.value;
    });
  }

  return Object.keys(result).map((key) => ({
    name: key,
    text: result[key].text,
    image: result[key].image,
    system: result[key].system,
    consult: result[key].consult,
    attachment: result[key].attachment,
  }));
};

export const getDateFormat = (period: Period) => {
  switch (period) {
    case 'hourly':
      return 'ddd, MMM DD HH:mm';
    case 'monthly':
      return 'MMMM yyyy';
    default:
      return 'ddd, MMM DD';
  }
};

export const downloadContent = (content: string, filename: string, type: string = 'text/plain') => {
  if (!content || !filename) {
    return;
  }

  const blob = new Blob([content], { type });

  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(downloadUrl);
};

export const tickNumberFormat = (value: number) => {
  if (!value || value < 1000) {
    return value;
  }

  value = Math.floor(value / 1000);

  if (value < 1000) {
    return value + 'K';
  }

  value = Math.floor(value / 1000);

  return value + 'M';
};
