import React from 'react';
import * as ReactModal from 'react-modal';
import moment from 'moment';
import client from 'src/clients/apolloClient';
import PublishScheduleStateMutation from 'src/gql/mutation/PublishScheduleStateMutation';
import { toast } from 'react-toastify';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import ScheduleFragment from 'src/gql/fragment/ScheduleFragment';
import { ScheduleFragmentResult } from 'src/types';
import { useDispatch } from 'react-redux';
import { typedUseSelector } from 'src/redux/store';
import allActions from 'src/redux/actions';
import WarningIcon from 'src/assets/svgs/WarningIcon';
import MetricaidComponent, { METRICAIDERRORCODE } from 'src/components/shared/MetricaidComponent';
import { PrimaryButton, SecondaryButton, StyledTooltip } from 'src/components/shared/HypercareComponents';

const Label = styled.div`
  font-size: 12px;
  margin-top: 4px;
  font-family: Roboto;
  color: grey;
`;

// const LastUpdatedAtHolder = styled.div`
//   font-size: 14px;
//   margin-top: 8px;
//   margin-bottom: 1.5em;
//   line-height: 1.5;
// `;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 8px;
  }
`;

const ModalBodyContainer = styled.div`
  margin-bottom: 1.5em;
  line-height: 1.5em;
  font-family: Roboto;
  font-size: 16px;
`;

const IssuesWrapper = styled.div`
  background-color: ${(props) => props.theme.lightYellow};
  border: 1px solid ${(props) => props.theme.orangedYellow};
  border-radius: 6px;
  padding: 16px;
  display: flex;
  margin-top: 16px;
  margin-bottom: 24px;
  div:first-child {
    margin-right: 16px;
  }
`;

const UnorderedList = styled.ul`
  margin-top: 4px;
  font-size: 14px !important;
  font-family: 'Open sans', sans-serif !important;
  padding-inline-start: 25px;
  margin-bottom: 4px;
  li {
    line-height: 20px !important;
    list-style-type: disc;
    font-size: 14px !important;
  }
`;

const IssuesHeader = styled.div`
  font-size: 18px;
  font-family: 'Nunito Sans', sans-serif;
  line-height: 24px;
  font-weight: 600;
`;

const StyledTooltipWrapper = styled.span`
  border-bottom: 2px dotted ${(props) => props.theme.mainFontColor};
  cursor: pointer;
  &:hover {
    border-bottom: 2px dotted ${(props) => props.theme.mainTealColor};
  }
`;

interface Props {
  handleCloseModal: () => void;
  showModal: boolean;
  scheduleId: number;
}

type NotifyOption = 'affected' | 'all' | 'none';

const SchedulePublishModal = ({ showModal, handleCloseModal, scheduleId }: Props) => {
  // const [note, setNote] = React.useState('');
  const [isLoading, setLoading] = React.useState(false),
    [notifyOption, setNotifyOption] = React.useState<NotifyOption>('affected'),
    [metricaidErrorState, setMetricaidErrorState] = React.useState<boolean>(false);

  const monthlyScheduleStore = typedUseSelector((state) => state.monthlyScheduleReducer);
  const department_id = typedUseSelector((state) => state.organizationReducer.department_id);
  const name = typedUseSelector((state) => state.organizationReducer.name);
  const {
    isGapInRequiredRole,
    totalShiftConflictAndWarnings: { numberOfWarnings, numberOfConflicts },
  } = monthlyScheduleStore;
  const hasConflictOrWarning = numberOfWarnings > 0 || numberOfConflicts > 0;

  const dispatch = useDispatch();

  React.useEffect(() => {
    const setShouldShowGap = (bool: boolean) =>
      dispatch(
        allActions.monthlyScheduleAction.setScheduleShowGap({
          shouldShowGap: bool,
        }),
      );
    setShouldShowGap(isGapInRequiredRole);
  }, [dispatch, isGapInRequiredRole]);

  const cachedSchedule: ScheduleFragmentResult = client.readFragment({
    id: `Schedule:${scheduleId}`,
    fragment: ScheduleFragment,
    fragmentName: 'ScheduleFragment',
  });

  const handleOptionChange = (event: React.ChangeEvent<{ value: NotifyOption }>) => {
    setNotifyOption(event.target.value);
  };

  // const handleInputChange = (event: React.ChangeEvent<{ value: string }>) => {
  //   setNote(event.target.value);
  // };

  const handlePublishSchedule = async () => {
    try {
      setLoading(true);
      await client.mutate({
        mutation: PublishScheduleStateMutation,
        variables: {
          departmentId: department_id,
          scheduleId,
          notifyOption,
          note: '', // TODO: wait until backend support
        },
      });
      toast.success('Successfully published schedule', {
        className: 'Toast-Container',
      });
      handleCloseModal();
    } catch (err) {
      console.error(err);
      setLoading(false);
      if (err?.graphQLErrors[0]?.code === METRICAIDERRORCODE) setMetricaidErrorState(true);
      else
        toast.error('Failed to publish schedule, please try again', {
          className: 'Toast-Container',
        });
    }
  };

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__createRole"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={handleCloseModal}
    >
      <div className="close" onClick={handleCloseModal} />
      <div className="modal__header">
        {name} - {moment(cachedSchedule.startDate).format('MMMM YYYY')}
      </div>

      <ModalBodyContainer>
        {metricaidErrorState && <MetricaidComponent />}
        {(isGapInRequiredRole || hasConflictOrWarning) && (
          <IssuesWrapper>
            <div>
              <WarningIcon />
            </div>
            <div>
              <IssuesHeader>Please review the following scheduling issue(s):</IssuesHeader>
              <UnorderedList>
                {isGapInRequiredRole && <li>There are required shifts that are not filled in your schedule.</li>}
                {hasConflictOrWarning && (
                  <li>
                    There are {numberOfConflicts > 0 && `${numberOfConflicts}`}
                    <StyledTooltip
                      arrow
                      title={'Conflicts are identified when a provider has two shifts occurring at the same time.'}
                    >
                      <StyledTooltipWrapper>{numberOfConflicts > 0 && ' conflicts '}</StyledTooltipWrapper>
                    </StyledTooltip>
                    {numberOfWarnings > 0 && numberOfConflicts > 0 && 'and '}
                    {numberOfWarnings > 0 && `${numberOfWarnings}`}
                    <StyledTooltip
                      arrow
                      title={
                        'Warnings are identified when a provider has two shifts occurring on the same calendar day.'
                      }
                    >
                      <StyledTooltipWrapper>{numberOfWarnings > 0 && ' warnings.'}</StyledTooltipWrapper>
                    </StyledTooltip>
                  </li>
                )}
              </UnorderedList>
            </div>
          </IssuesWrapper>
        )}

        <Label>Select notification option</Label>
        <FormControl variant="outlined" fullWidth>
          <Select value={notifyOption} onChange={handleOptionChange} style={{ fontFamily: 'Nunito Sans' }}>
            <MenuItem value={'affected'}>Notify affected members only (suggested)</MenuItem>
            <MenuItem value={'all'}>Notify all members</MenuItem>
            <MenuItem value={'none'}>Don't notify anyone</MenuItem>
          </Select>
        </FormControl>

        {/* <TextField
          multiline
          fullWidth
          rows={4}
          rowsMax={4}
          value={note}
          placeholder="Include a message summarizing what changed (Optional)"
          onChange={handleInputChange}
          variant="outlined"
        />
        <div>
          All admins and locating will always receive a copy whenever a schedule is published. 
          By publishing, changes you have made will be live and visible to all department members.
        </div> */}
      </ModalBodyContainer>

      <ModalButtonContainer>
        <SecondaryButton type="submit" variant="outlined" disabled={isLoading} onClick={handleCloseModal}>
          Continue editing
        </SecondaryButton>
        <PrimaryButton type="submit" onClick={handlePublishSchedule} disabled={isLoading}>
          {isLoading
            ? 'sending request...'
            : hasConflictOrWarning || isGapInRequiredRole
            ? 'Publish anyway'
            : 'Publish'}
        </PrimaryButton>
      </ModalButtonContainer>
    </ReactModal>
  );
};

export default SchedulePublishModal;
