import React from 'react';
import ContentLoader from 'react-content-loader';

type ContentLoaderProps = {
  speed: number;
  width: number;
  height: number;
  viewBox: string;
  backgroundColor: string;
  foregroundColor: string;
};

const UsersListLoader = (props: Partial<ContentLoaderProps>) => (
  <ContentLoader
    speed={props.speed || 1}
    width={props.width || 500}
    height={props.height || 350}
    viewBox={props.viewBox || '0 0 500 350'}
    backgroundColor={props.backgroundColor || '#f3f3f3'}
    foregroundColor={props.foregroundColor || '#ecebeb'}
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="500" height="10" />
    <rect x="75" y="21" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="41" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="35" r="10" />
    <rect x="75" y="66" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="86" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="80" r="10" />
    <rect x="75" y="111" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="131" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="120" r="10" />
    <rect x="75" y="156" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="176" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="165" r="10" />
    <rect x="75" y="201" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="221" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="205" r="10" />
    <rect x="75" y="246" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="266" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="250" r="10" />
    <rect x="75" y="291" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="311" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="290" r="10" />
    <rect x="75" y="336" rx="3" ry="3" width="400" height="7" />
    <rect x="75" y="356" rx="3" ry="3" width="143" height="7" />
    <circle cx="30" cy="335" r="10" />
  </ContentLoader>
);

export default UsersListLoader;
