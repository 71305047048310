import { AddressType } from 'src/types/User';
import { HiddenNotes } from 'src/types';
import { getTimestampString } from 'src/utils/getTimestampString';

/**************** Misc ****************/
export const CANCEL = 'Cancel';
export const DISCARDED = 'Discarded!';

export const CANADA = 'Canada';
export const UNITED_STATES = 'United States';
export const EUROPE = 'Europe';

export const CANADA_REGION_CODE = 'CA';
export const UNITED_STATES_REGION_CODE = 'US';
export const EUROPE_REGION_CODE = 'EU';

export const AuthRegionMap = {
  [CANADA_REGION_CODE]: CANADA,
  [UNITED_STATES_REGION_CODE]: UNITED_STATES,
  [EUROPE_REGION_CODE]: EUROPE,
};

/**************** Modals ****************/
export const CHANGE_REGION_MODAL_TITLE_TEXT = 'Which country is your organization located in?';
export const CHANGE_REGION_MODAL_DESCRIPTION_TEXT =
  'We ask to ensure that your data is stored in the right region and to ensure privacy & security.';

export const CHANGE_REGION_MODAL_SWITCH_BUTTON = (selectedRegion, AuthRegionMap) =>
  `Switch ${!selectedRegion ? '' : `to ${AuthRegionMap[selectedRegion]}`}`;

export const LOGIN_BOX_DESCRIPTION_TEXT = (currentRegion, AuthRegionMap) =>
  `If you're an Administrator of an organization outside of ${
    currentRegion === 'US' ? `the ${AuthRegionMap[currentRegion]}` : AuthRegionMap[currentRegion]
  },`;

export const LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT = 'click here to switch region.';

/**************** Pages ****************/
export const HYPERCARE_SUPPORT_LINK = 'https://support.hypercare.com';
export const LOGIN_PAGE_CURRENT_REGION_TITLE = 'Current region:';

export const LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_TITLE = 'You have entered an incorrect password multiple times.';
export const LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_SUB_INFO =
  '  If your username have 10 unsuccessful attempt in record, you will be locked out of your account and will have to call support to get access.';

export const LOGIN_PAGE_LOCKED_ACCOUNT_MESSAGE = 'Your session has been locked for your account security.';
export const LOGIN_PAGE_LOCKED_ACCOUNT_CONTACT_US = 'Please contact us ';
export const LOGIN_PAGE_LOCKED_ACCOUNT_HELP_TEXT = 'for help.';

export const LOGIN_PAGE_LOCKED_ACCOUNT_ERROR_MESSAGE = 'Your account has been locked for your security';
export const LOGIN_PAGE_INVALID_CREDENTIALS_ERROR_MESSAGE = 'Password is incorrect';
export const LOGIN_PAGE_NO_INTERNET_CONNECTION_MESSAGE =
  'Unable to connect to the server. Check your internet connection.';

export const LOGIN_PAGE_SSO_MODAL_NETWORK_ERROR =
  'An error occurred while trying to retrieve Single Sign-On (SSO) organization information. Please try again later or contact support for assistance';

export const LOGIN_PAGE_SSO_ERROR_MODAL_TITLE = 'Single Sign-On Error';

export const LOGIN_PAGE_SSO_MODAL_HEADER = 'Login with organization sign-on';
export const PREFERENCES = 'Preferences';
export const TYPE = 'Type';
export const Label = 'Label';
export const CONTACT = 'Contact';
export const VISIBILITY = 'Visibility';
export const PROFILE_UPDATED = 'Profile updated';
export const INCORRECT_LICENSE_END_DATE = 'The license end date you entered is incorrect';
export const ADDRESS_ALREADY_INUSE_MESSAGE = 'This address already in use for other account';

export const EMAIL = 'Email';
export const SMS = 'SMS';
export const MOBILE_NUMBER = 'Mobile number';
export const NUMERIC_PAGER = 'Numeric Pager';
export const ALPHANUMERIC_PAGER = 'Alphanumeric Pager';

export const PROFILE_PAGE_FIRST_NAME_FIELD_LABEL = 'First Name';
export const PROFILE_PAGE_LAST_NAME_FIELD_LABEL = 'Last Name';
export const PROFILE_PAGE_JOB_TITLE_FIELD_LABEL = 'Job Title';

export const PROFILE_PAGE_PREFERENCE_ORDER_TOOLTIP_TEXT =
  'The list shows the most preferred method at the top to the least in addition to Hypercare messaging. Hypercare may send SMS/notifications to these methods.';
export const PROFILE_PAGE_ADDRESS_RE_ORDER_TOOLTIP_TEXT = `Use this draggable handle to change the order of contact methods from most preferred at the top to the least.`;
export const PROFILE_PAGE_ADD_ANOTHER_ADDRESS_BUTTON_TEXT = 'Add another method';

export const ProfileAddressMap = {
  [AddressType.PHONE_NUMBER]: MOBILE_NUMBER,
  [AddressType.EMAIL]: EMAIL,
  [AddressType.NUMERIC_PAGER]: NUMERIC_PAGER,
  [AddressType.ALPHANUMERIC_PAGER]: ALPHANUMERIC_PAGER,
};

export const ACCESS = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
};

export const ProfileVisibiltyMap = {
  [ACCESS.PRIVATE.toLowerCase()]: 'Private',
  [ACCESS.PUBLIC.toLowerCase()]: 'Public',
};

export const NOTE_PUBLIC_ACCESS_TEXT = (currentOrganization: string) =>
  `  Visible to Everyone at ${currentOrganization}`;

export const NOTE_PRIVATE_ACCESS_TEXT = (currentOrganization: string) =>
  ` Visible to only Admins at ${currentOrganization}`;

export const NOTE_CONTENT = (note: HiddenNotes, userId: string) =>
  `${
    note.editedBy
      ? `Last updated by ${
          note.editedBy.id === userId ? 'You' : `${note.editedBy.firstName} ${note.editedBy.lastName}`
        } ${getTimestampString(note.updatedAt)} •`
      : ''
  } Added ${getTimestampString(note.createdAt)} `;

export const ROLE_NOTES_MODAL_HEADER_CONTENT = (roleName: string) => `'${roleName}' notes`;

export const TOS_MODAL_HEADER_TEXT = 'Terms of Service';

export const TOS_MODAL_AGREE_CHECKBOX_TEXT = 'I agree to theses';
export const TOS_MODAL_BODY_TEXT =
  '        By clicking "Agree", you agree to the Terms of Service set out by the organization. You also grant your\n' +
  '        organization ability to manage your account.';
export const AGREE = 'Agree';
export const DISAGREE = 'Disagree';

export const SENDING_REQUEST = 'Sending request...';

export const DELETE_CURRENT_OR_FUTURE_ROLE_MODAL_DESCRIPTION_TEXT = (currentMonth: string, year: number) =>
  `Changes will be applied from ${currentMonth}/${year} onwards.`;

export const DELETE_PAST_ROLE_MODAL_DESCRIPTION_TEXT = (currentMonth: string, year: number) =>
  `Changes will only be applied in ${currentMonth}/${year}, future schedules will not be affected.`;

export const CONTINUE_EDITING = 'Continue editing';
export const DELETE_ROLE = 'Delete Role';

export const SCHEDULE_DELETE_ROLE_MODAL_TITLE = (roleName: string) => `Deleting ${roleName} ?`;

export const INVALID_CREDENTIALS_MSG = 'Password is incorrect';
export const LOCKED_ACCOUNT_MSG = 'Your account has been locked for your security';
export const VERIFYING_AVAILABILITY = 'Verifying availability...';
export const NEXT = 'Next';

export const ADDRESS_IS_REQUIRED = 'Address is required!';
export const PASSWORD_IS_REQUIRED = 'Password is required!';

export const HAVING_SOME_ISSUES = 'Having some issues?';
export const CONTACT_US = 'Contact us';

export const LOG_IN = 'Log in';

export const LOGIN_VIEW_HEADER_TITLE = 'Welcome back!';

export const FORGOT_YOUR_PASSWORD = 'Forgot your password?';

export const PASSWORD = 'password';
export const ADDRESS = 'address';

export const INCORRECT_PASSWORD_ATTEMPT_MESSAGE =
  'If you enter an incorrect password more than twice, your account will be temporarily locked after 10 unsuccessful attempts. To regain access, please contact support.';

export const MULTIPLE_INCORRECT_PASSWORD_ATTEMPT_MESSAGE = 'You have entered an incorrect password multiple times.';

export const SESSION_LOCKED_MESSAGE_1 = 'Your session has been locked for your account security. Please';
export const SESSION_LOCKED_MESSAGE_2 = 'contact Hypercare support';

export const SESSION_LOCKED_MESSAGE_3 = 'for help.';

export const USER_NOT_ADMIN_ERROR_MESSAGE = 'This account is not an admin of any organization';

export const SESSION_EXPIRED_ERROR_MESSAGE = 'Your session has expired, please login again';

export const DISABLED_DOWNLOAD_TOOLTIP_TEXT = 'Schedule must be published before downloading';

export const DOWNLOAD = 'Download';

export const DOWNLOADING = 'Downloading...';

export const PROFILE_PAGE_ADD_ANOTHER_FIELD_TEXT = 'Add another field';

export const PROFILE_PAGE_OTHER_INFORMATION_TITLE = 'Other Information';

export const PROFILE_PAGE_EMPTY_SECTION_SUBTITLE = "No information to display. Press 'Edit profile' to add one.";

export const PROFILE_PAGE_UPDATE_OTHER_FIELDS = 'Update Other Fields';

export const PROFILE_PAGE_UPDATE_FAILED = 'Update failed';

export const PROFILE_ADDRESS_UPDATE_SUCCESS = 'Profile successfully updated!';

export const PROFILE_NEW_CONTACT_METHOD_SUCCESS = 'New contact method successfully added.';

export const PROFILE_ADDRESS_DELETE_LAST_ADDRESS_ERROR =
  'Unable to remove last address, you need at least one alternate contact method in your profile';

export const PROFILE_PAGE_NO_ADDRESS_MESSAGE = 'This user has no contact methods';

export const PROFILE_PAGE_EMPTY_NOTES_TITLE = 'Your notes can be found here';

export const PROFILE_PAGE_EMPTY_NOTES_SUBTITLE =
  'Notes can be set to public or private. Your administrators can also add and edit them on your behalf.';

export const FIELD_PLACEHOLDER_PHONE = 'Enter a phone number';

export const FIELD_PLACEHOLDER_DATE = 'Select a date';

export const FIELD_PLACEHOLDER_NUMBER = 'Enter a number';

export const FIELD_PLACEHOLDER_URL = 'Enter a url';

export const FIELD_PLACEHOLDER_DROPDOWN = 'Select an option...';

export const FIELD_PLACEHOLDER_EMAIL = 'Enter an email';

export const FIELD_PLACEHOLDER_TEXT = 'Enter text';

export const CPF_PLACEHOLDER_LABEL_TEXT = 'Select a label...';

export const DISCARD_UNSAVED_CHANGES = 'Discard unsaved changes?';

export const DISCARD = 'Discard';

export const KEEP_EDITING = 'Keep editing';

export const CANNOT_EDIT_SYNCED_USERS_PROFILE_FIELD_SUPPORT_LINK =
  'https://support.hypercare.com/hc/en-us/articles/19501293376525-Why-can-t-I-edit-some-fields-in-a-user-s-profile-';

export const DIR_SYNC_TOP_PANEL_PILL_SUPPORT_LINK =
  'https://support.hypercare.com/hc/en-us/articles/22579274663437-What-is-Direct-Synchronization-and-what-does-it-do-';

export const DIR_SYNC_USER_MANAGED_PILL_SUPPORT_LINK =
  'https://support.hypercare.com/hc/en-us/articles/22579575648781-What-is-a-managed-unmanaged-account-user-';

export const ORG = 'org';
export const DEP = 'dep';

export const USERS_PAGE_SEARCH_FIELD_PLACEHOLDER = 'Search people by name or job title';

export const PENDING_ADMIN_APPROVAL_TOOLTIP_TEXT =
  'These users are waiting for admin approval before the invitation gets sent to the invitee.';
export const PENDING_INVITEE_RESPONSE_TOOLTIP_TEXT =
  'These users already received admin approval to join the organization, and is waiting for the invitee to accept the invitation.';

export const NO_UNLICENSED_USERS = 'There are no unlicensed users yet';

export const NO_LICENSED_USERS = 'There are no licensed users yet';

export const NO_PENDING_INVITES = 'There are no pending invites';

export const NO_SEARCH_RESULT_FOUND_TITLE = 'No results found';
export const NO_SEARCH_RESULT_FOUND_BODY =
  ' Please try using different keywords or switching to another tab and try again.';

export const NO_SEARCH_RESULT_FOUND_BOTTOM_TEXT = 'Something went wrong?';

export const HYPERCARE_SUPPORT_CONTACT_LINK = 'https://www.hypercare.com/contact';

export const END_OF_USER_LIST_MESSAGE = 'End of list';

export const NETWORK_ERROR_OCCURRED =
  'An Error Occurred, Please Check Your Internet Connection And Try To Refresh The Page.';

export const CONTACT_SUPPORT_IF_PROBLEM_PERSIST =
  'If The Problem Persists, Please Let Us Know By Contacting Hypercare Support.';

export const INVITE_USER_MODAL_FLOW_SEARCH_PLACEHOLDER_TEXT = 'E.g.: Jane Doe, Physician, Dermatology';

export const INVITE_USER_MODAL_EMPTY_SEARCH_TITLE = 'Search by name or job title';
export const INVITE_USER_MODAL_EMPTY_SEARCH_DESCRIPTION = ' Search results will appear here';

export const SHOW_SELECTED = 'Show selected';

export const SELECTED = 'Selected';

export const MEMBER_MESSAGES_REPORT_TITLE = 'Member messages reports';
export const MEMBER_MESSAGES_REPORT_DESCRIPTION =
  'Generate a report of the number of messages sent by each user in your organization.';
export const MEMBER_MESSAGES_REPORT_EXPORT_BUTTON_TEXT = 'Export Report';

export const ADDRESS_BANNER_EMAIL_TITLE = 'Email required: ';
export const ADDRESS_BANNER_EMAIL_DESCRIPTION = 'Add an email to avoid losing access to Hypercare.';
export const ADDRESS_BANNER_PHONE_TITLE = 'Recommended: ';
export const ADDRESS_BANNER_PHONE_DESCRIPTION = 'Add a mobile number to receive backup notifications.';
export const ADDRESS_BANNER_BOTH_ADDRESS_TITLE = 'Action required: ';
export const ADDRESS_BANNER_BOTH_ADDRESS_DESCRIPTION =
  'Add an email and mobile number to avoid losing access to Hypercare.';
export const ADDRESS_BANNER_LEARN_MORE_SUPPORT_TEXT =
  'https://support.hypercare.com/hc/en-us/articles/24953957201037-How-to-Add-an-Email-Address-and-Mobile-Number-to-Your-Profile';
