import gql from 'graphql-tag';

export default gql`
  query DownloadEquityReport($departmentId: Int!, $startDate: String!, $endDate: String!) {
    locating {
      department(id: $departmentId) {
        equityReport(startDate: $startDate, endDate: $endDate) {
          fileName
          mimeType
          blob(encoding: "base64")
        }
      }
    }
  }
`;
