import gql from 'graphql-tag';

export type FetchOrganizationAddressLabelOptionsResponse = {
  organizationalUnitQuery: {
    organizationalUnit: {
      id: string;
      addressLabels: string[];
    };
  };
};

export const FETCH_ORGANIZATION_ADDRESS_LABEL_OPTIONS = gql`
  query GetAddressLabels($organizationalUnit: OrganizationalUnitInput!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        ... on Node {
          id
        }
        ... on Organization {
          id
          addressLabels
        }
      }
    }
  }
`;
