import gql from 'graphql-tag';

export default gql`
  query DownloadSchedules(
    $departmentId: Int!
    $startDate: String!
    $endDate: String!
    $month: ScheduleMonths
    $year: Int
  ) {
    locating {
      department(id: $departmentId) {
        schedule(startDate: $startDate, endDate: $endDate, month: $month, year: $year) {
          downloadFile {
            fileName
            mimeType
            blob(encoding: "base64")
          }
        }
      }
    }
  }
`;
