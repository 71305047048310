import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import { DialogBoxTitle, PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { AppRoutes } from '../../router/AppRoutes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: '24px 24px 16px 24px',
      padding: '0px',
    },
    dialogDescription: {
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: '#4A4A4A',
      marginBottom: '0px',
    },
    dialogAction: {
      margin: '16px 24px 16px 24px',
      padding: '0px',
    },
    dialogIconButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      float: 'right',
    },
    dialogContent: {
      padding: '0px 24px 40px 24px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  }),
);

const TemplateModal = ({ openModal, handleCloseModal }) => {
  const classes = useStyles();

  const handleQuitAnyway = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.quitAnywayButtonPressed,
    });

    window.routerHistory.push(AppRoutes.Dashboard);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogBoxTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {'Quit editing?'}
        <IconButton onClick={handleCloseModal} className={classes.dialogIconButton}>
          <CloseIcon color="#4A4A4A" width="15" height="15" />
        </IconButton>
      </DialogBoxTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogDescription} id="alert-dialog-description">
          All the information that you have entered so far will not saved. This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <SecondaryButton type="submit" onClick={handleCloseModal}>
          Back to editing
        </SecondaryButton>
        <PrimaryButton type="button" onClick={() => handleQuitAnyway()}>
          Quit anyway
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateModal;
