import { SchedulingRepository } from '../../data/repository/SchedulingRepository';
import { AdminFetchSchedulePayload } from '../../types';

export const SchedulingViewModel = () => {
  const repo = SchedulingRepository();

  const fetchDepartmentSchedule = ({
    departmentId,
    siteId,
    year,
    shiftStartDate,
    shiftEndDate,
    month,
  }: AdminFetchSchedulePayload) => {
    const result = repo.useFetchPaginatedUsers({ departmentId, siteId, year, shiftStartDate, shiftEndDate, month });
    return {
      ...result,
    };
  };

  return {
    fetchDepartmentSchedule,
  };
};
