import { OrganizationMutationScope, OrganizationType } from 'src/types';

export default function (scope: OrganizationMutationScope): OrganizationType {
  if (scope.organizationId && !scope.siteId && !scope.departmentId) {
    return 'organization';
  } else if (scope.organizationId && scope.siteId && !scope.departmentId) {
    return 'site';
  } else {
    return 'department';
  }
}
