import React from 'react';
import styled from 'styled-components';
import { DropSourceItem, EscalationPolicyResult } from 'src/types';
import EscalationPolicySource from 'src/pages/EscalationPage/escalation-layout/drop-source/EscalationPolicySource';
import Tooltip from '@material-ui/core/Tooltip';
import { typedUseSelector } from 'src/redux/store';

const EscalationPolicySourceDragWrapper = styled.div`
  position: relative;
  border-top: 1px solid lightgrey;
  min-height: 320px;
`;

const EscalationPolicyListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 8px;
`;

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin: 1em 0;
`;

interface Props {
  policies: EscalationPolicyResult[];
}

const POLICY_TOOLTIPS = `
  Your escalation is coming together well. Now, let's add a policy by dragging it to the canvas. 
  This policy will apply to the entire escalation ladder.`;

const EscalationSourcePolicyList: React.FC<Props> = ({ policies }) => {
  const escalationLadder = typedUseSelector((state) => state.escalationReducer.escalationLadder);

  const FormattedDragSourceItems: DropSourceItem[] = policies.map((policy) => {
    return {
      type: 'policy',
      data: policy.policyData,
      id: policy.policyData.id.toString(),
    };
  });

  const shouldShowToolTip =
    escalationLadder.length === 1 &&
    escalationLadder.get(0).escalationMembers.length > 0 &&
    !escalationLadder.get(0).escalationPolicy;

  React.useEffect(() => {
    if (document.getElementById('EscalationSourceWrapper') && shouldShowToolTip)
      document.getElementById('EscalationSourceWrapper').scrollTop =
        document.getElementById('EscalationSourceWrapper').scrollHeight;
  }, [shouldShowToolTip]);

  return (
    <React.Fragment>
      <Tooltip
        title={POLICY_TOOLTIPS}
        placement="right"
        open={shouldShowToolTip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
      >
        <EscalationPolicySourceDragWrapper>
          <StyledTitle>Add a policy to your Escalations</StyledTitle>
          <EscalationPolicyListWrapper>
            {FormattedDragSourceItems.map((dropSourceItem, i) => (
              <EscalationPolicySource dropSourceItem={dropSourceItem} key={i} />
            ))}
          </EscalationPolicyListWrapper>
          {FormattedDragSourceItems.length === 0 && (
            <div>
              There are no policy associate with this organization, please contact Hypercare support for further
              assistance.
            </div>
          )}
        </EscalationPolicySourceDragWrapper>
      </Tooltip>
    </React.Fragment>
  );
};

export default EscalationSourcePolicyList;
