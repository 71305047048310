import React from 'react';
import { useQuery } from 'react-apollo';
import { GET_CURRENT_AND_BLACKLISTED_USERS } from 'src/gql/query/GetUsersQuery';
import { GET_MESSAGE_TEMPLATE_REPORT } from 'src/gql/query/TemplateReportQuery';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Box, Button, Grid, useTheme } from '@material-ui/core';
import { toast } from 'react-toastify';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TemplateModal from 'src/components/modals/TemplateModal';
import SelectAvatar from 'src/components/shared/SelectAvatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CalendarIcon from 'src/assets/svgs/CalendarIcon';
import Loader from 'src/components/loaders/HomeLayoutLoader';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
  DateTextField,
  FieldInputLabel,
  PrimaryButton,
  SecondaryButton,
} from 'src/components/shared/HypercareComponents';

import { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';
import type {
  GetAllCurrentAndBlacklistedUsersResult,
  GetAllTemplateResult,
  IDateRange,
  TemplateReport,
  User,
} from 'src/types';

import { DateRange } from 'react-date-range';
import moment from 'moment';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { AppRoutes } from '../../../router/AppRoutes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
    },
    calendarContainer: {
      paddingRight: 6,
      marginTop: theme.spacing(3),
      position: 'relative',
    },
    calendar: {
      position: 'absolute',
      right: '4%',
      top: '54%',
      height: '18px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '18px',
    },
  }),
);

interface FormValue {
  templateIds: string[];
  startDate: string;
  endDate: string;
  senderUserIds: string[];
  recipientUserIds: string[];
}

interface Props {
  handleTemplateReport: (formValue: FormValue) => Promise<any>;
}

const templateReportValidationSchema = Yup.object().shape({
  templateIds: Yup.array().required('Template name is required!'),
  senderUserIds: Yup.array().required('Senders rule is required!'),
  recipientUserIds: Yup.array().required('Recipients rule is required!'),
});

const TemplateReportForm = ({ handleTemplateReport }: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  const ref = React.useRef(null);
  const fromRef = React.useRef(null);
  const toRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [isTouch, setIsTouch] = React.useState(false);
  const [dateRangeVisible, setDateRangeVisible] = React.useState(false);
  const dispatch = useDispatch();
  const setDateRange = (dateRange: IDateRange[]) =>
    dispatch(allActions.analyticsAction.updateDateRangeAction(dateRange));
  const [internalDateRange, setInternalDateRange] = React.useState<IDateRange[]>([
    {
      startDate: moment().subtract(7, 'day').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const templateData = useQuery<GetAllTemplateResult>(GET_MESSAGE_TEMPLATE_REPORT);
  const usersData = useQuery<GetAllCurrentAndBlacklistedUsersResult>(GET_CURRENT_AND_BLACKLISTED_USERS);
  const isWaitingSwitchOrganization = typedUseSelector((state) => state.flagReducer.isWaitingSwitchOrganization);

  React.useEffect(() => {
    usersData.refetch();
    templateData.refetch();
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [usersData, templateData]);

  const filterListByID = (list) => {
    return list.length === 1 ? list[0].value : list.map((elem) => elem.value).filter((elem) => elem !== 'all');
  };

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setDateRangeVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDateRangeVisible(false);
    }
  };

  const handleClickOpen = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.exportTemplateMessageReportQuitEditingButtonPressed,
    });

    if (isTouch) {
      setOpen(true);
    } else {
      window.routerHistory.push(AppRoutes.Dashboard);
    }
  };

  const handleClose = () => {
    setOpen(false);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.backToEditButtonPressed,
    });
  };

  const showDatePicker = (targetInput) => {
    targetInput === 'from' ? fromRef.current.focus() : toRef.current.focus();
    setDateRangeVisible(true);
  };

  const handleTypeChange = (selected, event, setFieldValue, options) => {
    let values = [];
    if (event.action === 'select-option' && event.option.value === 'all') {
      values = options;
    } else if (
      (event.action === 'deselect-option' && event.option.value === 'all') ||
      (event.action === 'remove-value' && event.removedValue.value === 'all')
    ) {
      values = [];
    } else if (event.action === 'deselect-option' || event.action === 'remove-value') {
      values = selected ? selected.filter((select) => select.value !== 'all') : [];
    } else if (selected && selected.length === options.length - 1) {
      values = options;
    } else {
      values = selected;
    }

    setFieldValue(event.name, values);
    setIsTouch(true);
  };

  const formatFormValue = ({
    templateIds,
    startDate,
    endDate,
    senderUserIds,
    recipientUserIds,
  }: FormValue): TemplateReport => {
    return {
      templateIds: templateIds
        .filter((template: any) => template.value !== 'all')
        .map((template: any) => template.value),
      startDate: moment(startDate, 'DD/MMM/YYYY').startOf('day').toISOString(true),
      endDate: moment(endDate, 'DD/MMM/YYYY').endOf('day').toISOString(true),
      senderUserIds: senderUserIds.filter((sender: any) => sender.value !== 'all').map((sender: any) => sender.value),
      recipientUserIds: recipientUserIds
        .filter((recipient: any) => recipient.value !== 'all')
        .map((recipient: any) => recipient.value),
    };
  };

  const handleDate = (setFieldValue) => {
    const startDate = moment(internalDateRange[0].startDate).format('DD/MMM/YYYY');
    const endDate = moment(internalDateRange[0].endDate).format('DD/MMM/YYYY');
    setDateRangeVisible(false);
    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
    setDateRange(internalDateRange);
    setIsTouch(true);
  };

  if (usersData.loading || isWaitingSwitchOrganization || templateData.loading) return <Loader />;

  if (usersData.error || templateData.error) {
    return (
      <div className="networkErrorHolder">
        <span>An Error Occurred, Please Check Your Internet Connection And Try To Refresh The Page.</span>
        <p>If The Problem Persists, Please Let Us Know By Contacting Hypercare Support.</p>
      </div>
    );
  }

  const allUsers = usersData.data;
  const allTemplates = templateData.data.admin.organization.messageTemplates.templates;
  const users: User[] = allUsers?.admin?.users;
  const blackListedUsers = allUsers?.admin ? allUsers.admin.blacklistedUsers : [];
  const totalUsers = [...users, ...blackListedUsers];

  const userOption = totalUsers.map((user) => {
    return {
      value: user.id,
      label: user.firstname + ' ' + user.lastname,
      avatar: user.profilePic,
      title: user.role,
    };
  });

  const templateOption = allTemplates.map((template) => {
    return {
      value: template.id,
      label: template.formTitle,
    };
  });

  const initialValues: FormValue = {
    templateIds: [],
    startDate: moment().subtract(7, 'day').format('DD/MMM/YYYY'),
    endDate: moment().format('DD/MMM/YYYY'),
    senderUserIds: [],
    recipientUserIds: [],
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={templateReportValidationSchema}
        onSubmit={(values, { resetForm, setFieldError, setSubmitting }) => {
          const formData = formatFormValue(values);
          handleTemplateReport(formData)
            .then((response) => {
              toast.success('Request received. You will receive the report via a Hypercare message within 5 minutes.', {
                className: 'Toast-Container',
              });
              resetForm();
              setIsTouch(false);
            })
            .catch((e) => {
              switch (e) {
                case 'network error':
                  setSubmitting(false);
                  setFieldError('general', 'unknown backend error occurred, please try again');
                  break;
                default:
                  resetForm();
                  setFieldError('general', 'Unknown error occurred, please try again');
              }
            });
        }}
        render={(props) => {
          const { values, errors, touched, isSubmitting, setFieldValue, handleSubmit } = props as FormikProps<any>;
          const { templateIds, startDate, endDate, senderUserIds, recipientUserIds } = values as FormValue;
          const hasErrors = Object.keys(errors).length > 0;

          const handleExportButtonPressed = () => {
            const templateIdsList = filterListByID(templateIds);
            const recipientUserIdsList = filterListByID(recipientUserIds);
            const senderUserIdsList = filterListByID(senderUserIds);

            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.exportTemplateMessageReportButtonPressed,
              params: {
                template_ids: templateIdsList,
                start_date: startDate,
                end_date: endDate,
                sender_ids: senderUserIdsList,
                recipient_ids: recipientUserIdsList,
              },
            });
          };

          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FieldInputLabel>Template types</FieldInputLabel>
                  <SelectAvatar
                    name="templateIds"
                    option={[{ label: `Select all (${templateOption.length})`, value: 'all' }, ...templateOption]}
                    selectedValue={templateIds}
                    type="normal"
                    placeholder="Search or select templates"
                    touched={touched.templateIds}
                    isSubmitting={isSubmitting}
                    handleSelect={handleTypeChange}
                    setFieldValue={setFieldValue}
                  />
                  {touched.templateIds && (
                    <FormHelperText error={Boolean(errors.templateIds)}>{errors.templateIds}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.calendarContainer}>
                  <FieldInputLabel>From</FieldInputLabel>
                  <DateTextField
                    size="small"
                    name="startDate"
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    disabled={isSubmitting ? true : false}
                    onClick={() => setDateRangeVisible(!isSubmitting ? true : false)}
                    value={startDate}
                    inputRef={fromRef}
                  />
                  <i className={classes.calendar} onClick={() => showDatePicker('from')}>
                    <CalendarIcon />
                  </i>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.calendarContainer}>
                  <FieldInputLabel>To</FieldInputLabel>
                  <DateTextField
                    size="small"
                    name="endDate"
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    disabled={isSubmitting ? true : false}
                    onClick={() => setDateRangeVisible(!isSubmitting ? true : false)}
                    value={endDate}
                    inputRef={toRef}
                  />
                  <i className={classes.calendar} onClick={() => showDatePicker('to')}>
                    <CalendarIcon />
                  </i>
                </Grid>
                <Box
                  position="relative"
                  bgcolor={theme.palette.common.white}
                  zIndex={10000}
                  boxShadow={1}
                  marginLeft="1.53rem"
                  width="41.5rem"
                  hidden={!dateRangeVisible}
                  {...{ ref: ref }}
                >
                  <Grid item xs={10} sm={5}>
                    <DateRange
                      onChange={(item: any) => setInternalDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={internalDateRange}
                      showSelectionPreview={false}
                      shouldCloseOnSelect={false}
                      months={2}
                      maxDate={new Date()}
                      direction="horizontal"
                    />
                    <Box justifyContent="flex-end" display="flex" paddingBottom={2} paddingRight={2}>
                      <Button
                        style={{
                          textTransform: 'none',
                          paddingLeft: theme.spacing(4),
                          paddingRight: theme.spacing(4),
                        }}
                        disableElevation
                        disableTouchRipple
                        color="secondary"
                        onClick={() => setDateRangeVisible(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          textTransform: 'none',
                          paddingLeft: theme.spacing(4),
                          paddingRight: theme.spacing(4),
                        }}
                        disableElevation
                        disableTouchRipple
                        color="primary"
                        onClick={() => handleDate(setFieldValue)}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Grid>
                </Box>
                <Grid item xs={12} style={{ marginTop: theme.spacing(3) }}>
                  <FieldInputLabel>Senders</FieldInputLabel>
                  <SelectAvatar
                    name="senderUserIds"
                    option={[{ label: `Select all (${totalUsers.length})`, value: 'all' }, ...userOption]}
                    selectedValue={senderUserIds}
                    type="withAvtar"
                    placeholder="Search or select users"
                    touched={touched.senderUserIds}
                    isSubmitting={isSubmitting}
                    handleSelect={handleTypeChange}
                    setFieldValue={setFieldValue}
                  />
                  {touched.senderUserIds && (
                    <FormHelperText error={Boolean(errors.senderUserIds)}>{errors.senderUserIds}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} style={{ marginTop: theme.spacing(3) }}>
                  <FieldInputLabel>Recipients</FieldInputLabel>
                  <SelectAvatar
                    name="recipientUserIds"
                    option={[{ label: `Select all (${totalUsers.length})`, value: 'all' }, ...userOption]}
                    selectedValue={recipientUserIds}
                    type="withAvtar"
                    placeholder="Search or select users"
                    touched={touched.recipientUserIds}
                    isSubmitting={isSubmitting}
                    handleSelect={handleTypeChange}
                    setFieldValue={setFieldValue}
                  />
                  {touched.recipientUserIds && (
                    <FormHelperText error={Boolean(errors.recipientUserIds)}>{errors.recipientUserIds}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={10} sm={6} style={{ marginTop: theme.spacing(5) }}>
                  <SecondaryButton
                    type="button"
                    disabled={isSubmitting ? true : false}
                    onClick={handleClickOpen}
                    style={{ marginRight: theme.spacing(1) }}
                  >
                    Quit editing
                  </SecondaryButton>
                  {!isSubmitting ? (
                    <PrimaryButton
                      type="submit"
                      disabled={hasErrors || isSubmitting}
                      onClick={handleExportButtonPressed}
                    >
                      Export reports
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton type="submit" disabled={true}>
                      <CircularProgress
                        color="inherit"
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                      />{' '}
                      &nbsp; Exporting reports...
                    </PrimaryButton>
                  )}
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
      {open && <TemplateModal openModal={open} handleCloseModal={handleClose} />}
    </>
  );
};

export default TemplateReportForm;
