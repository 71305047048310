import { useQuery } from 'react-apollo';
import { ADMIN_FETCH_SCHEDULES, AdminFetchScheduleResponse } from '../../gql/query/AdminFetchSchedule';
import { AdminFetchSchedulePayload } from '../../types';

export const SchedulingRepository = () => {
  const useFetchPaginatedUsers = ({
    departmentId,
    siteId,
    year,
    shiftStartDate,
    shiftEndDate,
    month,
  }: AdminFetchSchedulePayload) => {
    const result = useQuery<AdminFetchScheduleResponse, AdminFetchSchedulePayload>(ADMIN_FETCH_SCHEDULES, {
      variables: {
        departmentId,
        siteId,
        year,
        shiftStartDate,
        shiftEndDate,
        month,
      },
    });
    return {
      ...result,
    };
  };

  return {
    useFetchPaginatedUsers,
  };
};
