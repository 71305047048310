import React from 'react';
import moment from 'moment';
import client from 'src/clients/apolloClient';
import CreateScheduleMutation from 'src/gql/mutation/CreateScheduleMutation';
import { useDispatch } from 'react-redux';
import allActions from '../../../../redux/actions';

interface Props {
  departmentId: number;
  startDateMomentUTC: moment.Moment;
}

const useCreateSchedule = ({ departmentId, startDateMomentUTC }: Props) => {
  const [isCreationDone, setCreationDone] = React.useState(false);
  const dispatch = useDispatch();

  async function createSchedule() {
    try {
      const result = await client.mutate({
        mutation: CreateScheduleMutation,
        variables: {
          departmentId: departmentId,
          scheduleDetails: {
            name: moment(startDateMomentUTC).format('MMMM'),
            month: moment(startDateMomentUTC).local().format('MMMM').toLowerCase(),
            year: moment(startDateMomentUTC).local().year(),
          },
        },
      });

      if (result) {
        const newCreatedSchedule = result.data?.admin.locating.department.createSchedule;
        dispatch(allActions.monthlyScheduleAction.setScheduleId({ scheduleId: newCreatedSchedule.id }));
      }
      await client.reFetchObservableQueries();
      setCreationDone(true);
    } catch (e) {
      console.error(e);
    }
  }

  React.useEffect(() => {
    createSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isCreationDone };
};

export default useCreateSchedule;
