import React from 'react';
import { VirtualPagerStatusTypes, VirtualPagersMenuOptionStatus } from 'src/types/VirtualPagers';
import styled from 'styled-components';
import { ACTIVE, ALL, DISABLED, PENDING_CONFIGURATION } from 'src/constants/virtualPagerStrings';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const VirtualPagersMgtHeaderMenu = styled.div`
  margin-top: 16px;
  display: flex;
  border-radius: 5px 5px 0px 0px;
  color: ${(props) => props.theme.mainFontColor};
  font-size: 16px;
  align-items: left;
  background: ${(props) => props.theme.white};
  border-bottom: 1px solid ${(props) => props.theme.borderBottomLight};
`;

const VirtualPagersMgtHeaderMenuButton = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 12px 16px;
  align-self: center;
  user-select: none;
  display: flex;

  height: 100%;
  &:hover {
    color: ${(props) => props.theme.mainFontColor};
  }
  border-bottom: ${(props) => (props.isSelected ? `3px solid ${props.theme.mainTealColor}` : 'unset')} !important;
  span {
    font-weight: ${(props) => (props.isSelected ? 600 : 'auto')};
  }
`;

interface Props {
  currentMenuOption: VirtualPagersMenuOptionStatus;
  setMenuOptions: React.Dispatch<React.SetStateAction<VirtualPagersMenuOptionStatus>>;
  allPagerCount: number;
  activePagerCount: number;
  disabledPagerCount: number;
  pendingPagerCount: number;
}

const VirtualPagersManagmentHeader = ({
  allPagerCount,
  activePagerCount,
  disabledPagerCount,
  pendingPagerCount,
  currentMenuOption,
  setMenuOptions,
}: Props) => {
  const handleMenuOption = (tab: VirtualPagersMenuOptionStatus) => {
    setMenuOptions(tab);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaListTabChosen,
      params: {
        tab_selected: tab.toString(),
      },
    });
  };

  return (
    <VirtualPagersMgtHeaderMenu>
      <VirtualPagersMgtHeaderMenuButton
        isSelected={currentMenuOption === VirtualPagerStatusTypes.ALL}
        onClick={() => handleMenuOption(VirtualPagerStatusTypes.ALL)}
      >
        <span>
          {ALL} ({allPagerCount})
        </span>
      </VirtualPagersMgtHeaderMenuButton>

      <React.Fragment>
        <>
          <VirtualPagersMgtHeaderMenuButton
            isSelected={currentMenuOption === VirtualPagerStatusTypes.ACTIVE}
            onClick={() => handleMenuOption(VirtualPagerStatusTypes.ACTIVE)}
          >
            <span>
              {ACTIVE} ({activePagerCount})
            </span>
          </VirtualPagersMgtHeaderMenuButton>
        </>

        <VirtualPagersMgtHeaderMenuButton
          isSelected={currentMenuOption === VirtualPagerStatusTypes.PENDING}
          onClick={() => handleMenuOption(VirtualPagerStatusTypes.PENDING)}
        >
          <span>
            {PENDING_CONFIGURATION} ({pendingPagerCount})
          </span>
        </VirtualPagersMgtHeaderMenuButton>

        <>
          <VirtualPagersMgtHeaderMenuButton
            isSelected={currentMenuOption === VirtualPagerStatusTypes.DISABLED}
            onClick={() => handleMenuOption(VirtualPagerStatusTypes.DISABLED)}
          >
            <span>
              {DISABLED} ({disabledPagerCount})
            </span>
          </VirtualPagersMgtHeaderMenuButton>
        </>
      </React.Fragment>
    </VirtualPagersMgtHeaderMenu>
  );
};

export default VirtualPagersManagmentHeader;
