import { Auth0ContextInterface } from '@auth0/auth0-react';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { AuthContext } from 'src/auth';
import { HandleContinueLoginAddressParams, LoginResponse } from 'src/types';
import { HypercareLoginPasswordView } from 'src/pages/LoginPage/HypercarePassword/HypercareLoginPasswordView';
import LinearProgress from '@material-ui/core/LinearProgress';
import HypercareLoginAddressView from 'src/pages/LoginPage/HypercareLoginAddress/HypercareLoginAddressView';
import { ProgressContainer } from 'src/pages/LoginPage/components/styles';
import { LAST_ACTIVE_TIME } from 'src/constants/sessionTimeoutTypes';

const LOGIN_STEP_ONE = 1;
const LOGIN_STEP_TWO = 2;

const LoginPageContentContainer = styled.div<{ loading?: string }>`
  opacity: ${(props) => (props.loading ? '0.8' : '')};
  position: relative;
`;

type LoginCoordinatorProps = {
  auth0props: Auth0ContextInterface;
  login: (username: string, password: string) => Promise<LoginResponse> | null;
};

const LoginCoordinator = ({ login, auth0props }: LoginCoordinatorProps) => {
  const [currentStep, setCurrentStep] = useState(LOGIN_STEP_ONE);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const handleContinueLoginAddress = (values: HandleContinueLoginAddressParams) => {
    window.localStorage.removeItem(LAST_ACTIVE_TIME);
    if (values?.auth0Id) {
      setLoading(true);
      auth0props.loginWithRedirect({
        connection: values.auth0Id,
        scope: 'openid profile email',
      });
    } else {
      setAddress(values.address);
      setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  const handleGoToPrevious = () => {
    setCurrentStep((prevValue) => prevValue - 1);
  };

  return (
    <LoginPageContentContainer loading={loading ? 'true' : undefined}>
      {loading && (
        <ProgressContainer>
          <LinearProgress color="secondary" />
        </ProgressContainer>
      )}
      {currentStep === LOGIN_STEP_ONE && (
        <HypercareLoginAddressView handleNextButtonPressed={handleContinueLoginAddress} />
      )}
      {currentStep === LOGIN_STEP_TWO && (
        <HypercareLoginPasswordView login={login} address={address} handleBackButtonClick={handleGoToPrevious} />
      )}
    </LoginPageContentContainer>
  );
};

const HypercareLoginCoordinator = () => {
  return (
    <AuthContext.Consumer>
      {({ auth0props, login }) => <LoginCoordinator login={login} auth0props={auth0props} />}
    </AuthContext.Consumer>
  );
};
export default HypercareLoginCoordinator;
