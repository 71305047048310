import React, { useState } from 'react';
import { StyledModal } from 'src/components/modals/ModalStyleComponents';
import { HCHeadingThree, SecondaryButton } from 'src/components/shared/HypercareComponents';
import { Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { ADD, DISCARDED, DONE, ROLE_NOTE_MODAL_TITLE } from 'src/constants/hiddenNotes';
import theme from 'src/assets/styles/theme';
import { SCHEDULING } from 'src/constants/scheduler';
import NoteList from 'src/components/hidden-notes/NoteList';
import NoteForm from 'src/components/hidden-notes/NoteForm';
import HypercareRoleNotesViewModel from './HypercareRoleNotesViewModel';
import { typedUseSelector } from 'src/redux/store';
import { sortByUpdatedAt } from 'src/utils/userSorting/sortByUpdatedAt';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import DiscardNoteModal from 'src/components/hidden-notes/DiscardNoteModal';
import { toast } from 'react-toastify';
import { ROLE_NOTES_MODAL_HEADER_CONTENT } from 'src/constants/strings';
import { HiddenNotes } from 'src/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      border: 'none',
      marginLeft: '-24px',
      marginRight: '-24px',
    },
    span: {
      fontFamily: 'Nunito Sans !important',
      fontStyle: 'normal !important',
      fontWeight: 600,
      fontSize: '17px !important',
      lineHeight: '24px !important',
      display: 'flex !important',
      alignItems: 'center !important',
    },
  }),
);

const FlexContainer = styled.div`
  display: flex;
  margin-top: 1.5em;
  justify-content: flex-end;
  gap: 8px;
`;

const RoleNoteModalHeader = styled.div`
  word-break: break-all;
`;

const RoleNoteModalContent = styled.div`
  overflow-y: auto;
  padding: 24px 0 8px 0;
  overflow-x: hidden;
`;

const ShowRoleNoteBody = () => {
  const [editableNoteId, setEditableNoteId] = React.useState(null);
  const [isDiscard, setIsDiscard] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState<HiddenNotes | null>(null);
  const [selectedNoteId, setSelectedNoteId] = React.useState(null);
  const [isDeleteModalOpen, setDeleteModal] = React.useState(false);
  const roleNotesData = typedUseSelector((state) => state.monthlyScheduleReducer.roleNotes);
  const currentRoleSelected = typedUseSelector((state) => state.monthlyScheduleReducer.currentSelectedRole);
  const data =
    (roleNotesData?.[currentRoleSelected?.roleId] &&
      sortByUpdatedAt(roleNotesData?.[currentRoleSelected?.roleId]?.notes)) ||
    [];
  const classes = useStyles();
  const [isAddNotesActive, setIsAddNotesActive] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { handleCreateRoleNote, handleDeleteRoleNote, handleUpdateRoleNote } = HypercareRoleNotesViewModel();

  const changeRoleNoteModalVisibility = (value: boolean) => {
    dispatch(allActions.monthlyScheduleAction.setRoleNoteModalVisibility({ visibility: value }));
  };

  const handleDiscardNotes = () => {
    setIsAddNotesActive(false);
    setEditableNoteId(null);
    setSelectedNoteId(null);

    setIsDiscard(false);
    toast.success(DISCARDED, {
      className: 'toast-message toast-discard ',
      autoClose: 5000,
    });
  };

  const handleDiscardModal = () => {
    setIsDiscard(true);
  };

  return (
    <>
      <RoleNoteModalHeader>
        <HCHeadingThree id="alert-dialog-title">
          {data?.length > 0
            ? ROLE_NOTES_MODAL_HEADER_CONTENT(currentRoleSelected?.roleName)
            : ROLE_NOTE_MODAL_TITLE(currentRoleSelected?.roleName)}
        </HCHeadingThree>
      </RoleNoteModalHeader>
      <RoleNoteModalContent>
        {isAddNotesActive && !editableNoteId && (
          <NoteForm
            selectedNoteId={currentRoleSelected.roleId.toString()}
            page={SCHEDULING}
            action={ADD}
            handleCancelledNote={() => handleDiscardModal()}
            submitForm={handleCreateRoleNote}
            handleResetInputs={() => setIsAddNotesActive(false)}
          />
        )}
        {data && (
          <NoteList
            notes={data}
            isDiscard={isDiscard}
            setIsDiscard={setIsDiscard}
            page={SCHEDULING}
            isAddNotesActive={isAddNotesActive}
            setIsAddNotesActive={setIsAddNotesActive}
            submitForm={handleUpdateRoleNote}
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
            selectedNoteId={selectedNoteId}
            setSelectedNoteId={setSelectedNoteId}
            editableNoteId={editableNoteId}
            setEditableNoteId={setEditableNoteId}
            isDeleteModalOpen={isDeleteModalOpen}
            setDeleteModal={setDeleteModal}
            deleteNote={handleDeleteRoleNote}
          />
        )}
      </RoleNoteModalContent>
      {!isAddNotesActive && (
        <>
          <Divider className={classes.divider} />
          <FlexContainer style={{ marginTop: '16px' }}>
            <SecondaryButton color={theme.black} onClick={() => changeRoleNoteModalVisibility(false)}>
              <span className={classes.span}>{DONE}</span>
            </SecondaryButton>
          </FlexContainer>
        </>
      )}
      {isDiscard && (
        <DiscardNoteModal
          open={isDiscard}
          handleCloseModal={() => setIsDiscard(false)}
          handleDiscard={() => handleDiscardNotes()}
        />
      )}
    </>
  );
};

interface IHypercareRoleNotesModal {
  isOpen: boolean;
}

export const HypercareRoleNotesModal: React.FC<IHypercareRoleNotesModal> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const changeRoleNoteModalVisibility = (value: boolean) => {
    dispatch(allActions.monthlyScheduleAction.setRoleNoteModalVisibility({ visibility: value }));
  };

  return (
    <StyledModal
      width="600px"
      height="620px"
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onRequestClose={() => changeRoleNoteModalVisibility(false)}
    >
      <ShowRoleNoteBody />
    </StyledModal>
  );
};
