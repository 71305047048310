import gql from 'graphql-tag';

export const FetchEscalationLadderPreviewForDepartment = gql`
  query FetchEscalationLadderPreviewForDepartment($departmentId: Int!) {
    locating {
      department(id: $departmentId) {
        id
        escalationLadders {
          id
          name
          validFrom
        }
      }
    }
  }
`;

export default gql`
  query FetchEscalationLadderForDepartment($escalationLadderId: String!, $departmentId: Int!) {
    locating {
      department(id: $departmentId) {
        escalationLadder(escalationLadderId: $escalationLadderId) {
          id
          name
          validFrom
          validUntil
          escalationLevels {
            id
            level
            name
            escalationMembers {
              id
              user {
                id
                firstname
                lastname
                role
              }
              role {
                id
                auditId
                name
                repeatRule
                startTime
                endTime
              }
            }
            escalationPolicy {
              id
              name
              channels {
                type
                numAttempts
                timeBetweenAttempts
              }
            }
          }
        }
      }
    }
  }
`;
