import gql from 'graphql-tag';

export const publicUserFragment = `
  id
  username
  firstname
  lastname
`;

export const organizationFragment = `
  id
  name
`;

export const addressFragment = `
  address
`;

export const inviteNotesFragment = `
    inviteStatus
    note
    noteBy {
        ${publicUserFragment}
    }
    createdAt
`;

export default gql`
  fragment UserInviteFragment on UserInvite {
    id
    user {
      ${publicUserFragment}
    }
    address {
      ${addressFragment}
    }
    invitedBy {
      ${publicUserFragment}
    }
    organization {
      ${organizationFragment}
    }
    resolvedBy {
      ${publicUserFragment}
    }
    inviteStatus
    status
    createdAt
    updatedAt
  }
`;
