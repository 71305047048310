import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { GET_USERS } from 'src/gql/query/GetUsersQuery';
import { UserSelectOptionType, GetUsersResult } from 'src/types';
import UsernameInputDropdown from 'src/components/shared/UsernameInputSelect';
import Loader from 'src/components/loaders/InputContentLoader';

const UserInputDropdownContainer = ({ userId, setFieldValue, userFullNameError, isSingleUserForm, index }) => {
  return (
    <Query query={GET_USERS}>
      {({ loading, error, data }: QueryResult<GetUsersResult>) => {
        if (error || loading)
          return (
            <div style={{ marginTop: 25 }}>
              <Loader />
            </div>
          );

        const colleagues = data.admin.users;
        // const selfID = getSelfInfo().id;
        let prefillAssignee;
        const suggestions = colleagues.map((colleague) => {
          if (userId && userId === colleague.id) {
            prefillAssignee = {
              value: colleague.id,
              label: colleague.firstname + ' ' + colleague.lastname,
              username: colleague.username,
              role: colleague.role ? colleague.role : '',
            };
          }
          return {
            value: colleague.id,
            label: colleague.firstname + ' ' + colleague.lastname,
            username: colleague.username,
            role: colleague.role ? colleague.role : '',
          } as UserSelectOptionType;
        });

        return (
          <UsernameInputDropdown
            prefillAssignee={prefillAssignee ? prefillAssignee : null}
            isSingleUserForm={isSingleUserForm}
            index={index}
            setFieldValue={setFieldValue}
            userFullNameError={userFullNameError}
            suggestions={suggestions}
          />
        );
      }}
    </Query>
  );
};

export default UserInputDropdownContainer;
