import React from 'react';
import { QueryResult } from 'react-apollo';
import AllUsersSectionLayout from 'src/pages/HomePage/account-management/AllUsersSectionLayout';
import { GetAllLicensingUsersResult, User, UserMenuOptionStatus, GetPendingUsersResult } from 'src/types';

interface Props {
  usersData: GetAllLicensingUsersResult;
  menuOptions: UserMenuOptionStatus;
  pendingRecords: QueryResult<GetPendingUsersResult>;
  adminCoSignInvites: boolean;
  LicensedMembers: any;
}

const UserLayoutContainer = ({
  usersData,
  menuOptions,
  pendingRecords,
  adminCoSignInvites,
  LicensedMembers,
}: Props) => {
  const users: User[] = LicensedMembers;
  const blackListedUsers = usersData.admin.blacklistedUsers || [];
  const pendingUsers = usersData.admin.pendingUsers || [];

  return (
    <React.Fragment>
      <AllUsersSectionLayout
        licensedUsers={users}
        blackListedUsers={blackListedUsers}
        pendingRecords={pendingRecords}
        adminPendingUsers={pendingUsers}
        currentMenuOption={menuOptions}
        adminCoSignInvites={adminCoSignInvites}
      />
    </React.Fragment>
  );
};

export default UserLayoutContainer;
