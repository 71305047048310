import { UserScope, ShorthandOrganizationType } from 'src/types';
import determineScopeType from 'src/utils/determineScopeType';
import { DEPARTMENT, ORGANIZATION, SITE } from '../constants/organizationTypes';
import { DEP, ORG } from '../constants/strings';

const getAdminTypeBaseOnScopes = (scopes: UserScope[]): ShorthandOrganizationType | null => {
  if (!scopes) return null;

  for (let scope of scopes) {
    if (scope.status === 'admin') {
      const organizationType = determineScopeType(scope);

      switch (organizationType) {
        case ORGANIZATION:
          return ORG;
        case SITE:
          return SITE;
        case DEPARTMENT:
          return DEP;
      }
    }
  }
  return null;
};

export default getAdminTypeBaseOnScopes;
