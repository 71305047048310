import moment from 'moment';
import PVPAApiHelper from 'src/api/VirtualPagersAPIEndpoints';

import {
  DepartmentFragment,
  OrganizationMemberFragment,
  OrganizationRoleFragment,
  TextToSpeechResponse,
  VirtualPagers,
  audioObject,
  uploadAudioResponse,
  FetchAudioStepsByOrgIdResponse,
  AudioType,
  PVPAUploadAudioResponse,
} from 'src/types';
import { StringMappingType } from 'typescript';

interface ConfigureNumberViewModelProps {
  fetchPagerById: (pagerId: string) => Promise<VirtualPagers>;
  formatPhoneNumber: (inputNumber: string) => any;
  fetchUserbyUserID: (userIds: string[]) => Promise<OrganizationMemberFragment[]>;
  fetchRolebyRoleID: (roleIds: string[]) => Promise<OrganizationRoleFragment[]>;
  configureFriendlyName: (numberId: string, friendlyName?: string) => Promise<VirtualPagers>;
  configureChatPreferences: (numberId: string, alertPreference: string, isGroup: boolean) => Promise<VirtualPagers>;
  configureStatus: (numberId: string, status: string) => Promise<VirtualPagers>;
  configureCallback: (numberId: string, callbackId: string) => Promise<VirtualPagers>;
  configureVoicemail: (numberId: string, voicemailId: string) => Promise<VirtualPagers>;
  configureUserAssignees: (numberId: string, users: string[]) => Promise<VirtualPagers>;
  configureRoleAssignees: (numberId: string, role: string[]) => Promise<VirtualPagers>;
  generateTextToSpeech: (fileName: string, text: string) => Promise<TextToSpeechResponse>;
  fetchOrganizationRoles: () => Promise<OrganizationRoleFragment[]>;
  fetchOrgCallbacks: (organizationId: number, limit: number) => Promise<FetchAudioStepsByOrgIdResponse>;
  fetchOrgVoicemails: (organizationId: number, limit: number) => Promise<FetchAudioStepsByOrgIdResponse>;
  fetchSingleAudio: (audioStepId: string) => Promise<audioObject>;
  uploadAudioToMainAPI: (file: File) => Promise<uploadAudioResponse>;
  createAudioSteps: (organizationId: number, name: string, type: AudioType, audioUrl: string) => Promise<audioObject>;
  uploadAudioToPVPA: (url: string, fileName: string) => Promise<PVPAUploadAudioResponse>;
}

export default (): ConfigureNumberViewModelProps => {
  const fetchPagerById = async (pagerId: string) => {
    const res = await PVPAApiHelper.fetchPagerById(pagerId);
    return res.data;
  };

  const formatPhoneNumber = (inputNumber: string) => {
    const cleanedNumber = inputNumber.replace(/\D/g, ''); // Remove non-digit characters
    const countryCode = cleanedNumber.substring(0, 1);
    const areaCode = cleanedNumber.substring(1, 4);
    const firstPart = cleanedNumber.substring(4, 7);
    const secondPart = cleanedNumber.substring(7);

    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  };

  const fetchUserbyUserID = async (userIds: string[]) => {
    const fetchUserbyUserIDResult = await PVPAApiHelper.fetchUserbyUserID(userIds);

    return fetchUserbyUserIDResult.data.users;
  };

  const fetchRolebyRoleID = async (roleIds: string[]) => {
    let roleArray = [];
    for (let i = 0; i < roleIds.length; i++) {
      const fetchRolebyRoleIDResult = await PVPAApiHelper.fetchRolebyRoleID(parseInt(roleIds[i], 10));
      roleArray.push(fetchRolebyRoleIDResult);
    }

    return roleArray;
  };

  const configureFriendlyName = async (numberId: string, friendlyName: string) => {
    const configuredPager = await PVPAApiHelper.configurePager(numberId, 'friendlyName', friendlyName);

    return configuredPager.data;
  };

  const configureChatPreferences = async (numberId: string, alertPreference: string, isGroupChat: boolean) => {
    const configuredAlert = await PVPAApiHelper.configurePager(numberId, 'alertPreference', null, alertPreference);

    const configuredGroup = await PVPAApiHelper.configurePager(
      numberId,
      'isGroupChat',
      null,
      null,
      null,
      null,
      null,
      isGroupChat,
    );

    return configuredGroup.data;
  };

  const configureStatus = async (numberId: string, status: string) => {
    const configuredStatus = await PVPAApiHelper.configurePager(numberId, 'status', null, null, status);

    return configuredStatus.data;
  };

  const configureCallback = async (numberId: string, callbackId: string) => {
    const configuredCallback = await PVPAApiHelper.configurePager(
      numberId,
      'callbackAudio',
      null,
      null,
      null,
      callbackId,
    );

    return configuredCallback.data;
  };

  const configureVoicemail = async (numberId: string, voicemailId: string) => {
    const configuredStatus = await PVPAApiHelper.configurePager(
      numberId,
      'voicemailAudio',
      null,
      null,
      null,
      null,
      voicemailId,
    );

    return configuredStatus.data;
  };

  const configureUserAssignees = async (numberId: string, users: string[]) => {
    await PVPAApiHelper.configurePager(numberId, 'roleIds', null, null, null, null, null, null, null, []);
    const configuredPager = await PVPAApiHelper.configurePager(
      numberId,
      'userIds',
      null,
      null,
      null,
      null,
      null,
      null,
      users,
    );

    return configuredPager.data;
  };

  const configureRoleAssignees = async (numberId: string, roles: string[]) => {
    await PVPAApiHelper.configurePager(numberId, 'userIds', null, null, null, null, null, null, []);

    const configuredPager = await PVPAApiHelper.configurePager(
      numberId,
      'roleIds',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      roles,
    );

    return configuredPager.data;
  };

  const generateTextToSpeech = async (fileName: string, text: string) => {
    const finalString = `${fileName}.mp3`;

    const audioObject = await PVPAApiHelper.generateTextToSpeech(finalString, text);

    return audioObject;
  };

  const fetchOrganizationRoles = async () => {
    const currentDate = moment(); // This creates a moment object representing the current date and time

    const currentMonth = currentDate.format('MMMM').toLowerCase(); // This will give you the full name of the current month (e.g., "September")
    const currentYear = parseInt(currentDate.format('YYYY')); // This will give you the current year (e.g., "2023")

    const fetchedRoles = await PVPAApiHelper.fetchScheduleByMonthAndYear(currentMonth, currentYear);

    const data = fetchedRoles.data.locating.sites;

    const rolesList: OrganizationRoleFragment[] = processRoles(data);

    return rolesList;
  };

  function processRoles(data: Array<{ departments: DepartmentFragment[] }>): OrganizationRoleFragment[] {
    const result: OrganizationRoleFragment[] = [];

    for (const item of data) {
      const departments = item.departments;
      if (departments) {
        for (const department of departments) {
          const departmentName = department.name;
          const roles = department.schedule?.roles;

          if (roles) {
            for (const role of roles) {
              result.push({
                roleId: role.id,
                roleAuditId: role.auditId,
                roleName: role.name,
                department: departmentName,
              });
            }
          }
        }
      }
    }

    return result;
  }

  const fetchOrgCallbacks = async (organizationId: number, limit: number) => {
    const orgId = organizationId.toString();
    const fetchedAudios = await PVPAApiHelper.fetchOrgAudios(orgId, limit, 'callback');

    return { audioSteps: fetchedAudios.data, defaultAudio: fetchedAudios.defaultAudio };
  };

  const fetchOrgVoicemails = async (organizationId: number, limit: number) => {
    const orgId = organizationId.toString();

    const fetchedAudios = await PVPAApiHelper.fetchOrgAudios(orgId, limit, 'voicemail');

    return { audioSteps: fetchedAudios.data, defaultAudio: fetchedAudios.defaultAudio };
  };

  const fetchSingleAudio = async (audioId: string) => {
    const fetchedAudio = await PVPAApiHelper.fetchSingleAudio(audioId);

    return fetchedAudio.data;
  };

  const uploadAudioToPVPA = async (audioURL: string, fileName: string) => {
    const uploadRes = await PVPAApiHelper.uploadAudioToPVPA(audioURL, fileName);
    return uploadRes.data;
  };

  const uploadAudioToMainAPI = async (file: File) => {
    const uploadRes = await PVPAApiHelper.uploadAudioToMainAPI(file);
    return uploadRes;
  };

  const createAudioSteps = async (organizationId: number, name: string, type: AudioType, audioUrl: string) => {
    const createAudioStepsRes = await PVPAApiHelper.createAudioSteps(organizationId, name, type, audioUrl);
    return createAudioStepsRes.data;
  };

  return {
    createAudioSteps,
    fetchPagerById,
    formatPhoneNumber,
    fetchUserbyUserID,
    fetchRolebyRoleID,
    configureFriendlyName,
    configureChatPreferences,
    configureStatus,
    configureCallback,
    configureVoicemail,
    configureUserAssignees,
    configureRoleAssignees,
    generateTextToSpeech,
    fetchOrganizationRoles,
    fetchOrgCallbacks,
    fetchOrgVoicemails,
    fetchSingleAudio,
    uploadAudioToMainAPI,
    uploadAudioToPVPA,
  };
};
