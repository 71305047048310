import { OrganizationUserResultFragment } from '../fragment/OrganizationUserResultFragment';
import gql from 'graphql-tag';

export const ADMIN_UPDATE_PROFILE_NOTE = gql`
  mutation UpdateProfileNote(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $details: UpdateHiddenNoteDetails!
    $noteId: ID!
  ) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationMutation {
            __typename
            member(id: $userId) {
              note(id: $noteId) {
                __typename
                ... on AccessForbiddenError {
                  __typename
                  message
                }
                ... on NoteNotFoundError {
                  __typename
                  message
                }
                ... on NoteDeletedError {
                  __typename
                  message
                }
                ... on ProfileNoteMutation {
                  update(details: $details) {
                    ... on ProfileNote {
                      __typename
                      id
                      note
                      access
                      createdAt
                      updatedAt
                      createdBy {
                        ...OrganizationUserResultFragment
                        __typename
                      }
                      editedBy {
                        ...OrganizationUserResultFragment
                        __typename
                      }
                    }
                    ... on ExceedMaxWordCountError {
                      __typename
                      message
                      maxCount
                    }
                  }
                }
              }
            }
          }
          ... on AdminSiteMutation {
            __typename
            member(id: $userId) {
              note(id: $noteId) {
                __typename
                ... on AccessForbiddenError {
                  __typename
                  message
                }
                ... on NoteNotFoundError {
                  __typename
                  message
                }
                ... on NoteDeletedError {
                  __typename
                  message
                }
                ... on ProfileNoteMutation {
                  update(details: $details) {
                    ... on ProfileNote {
                      __typename
                      id
                      note
                      access
                      createdAt
                      updatedAt
                      createdBy {
                        ...OrganizationUserResultFragment
                        __typename
                      }
                      editedBy {
                        ...OrganizationUserResultFragment
                        __typename
                      }
                    }
                    ... on ExceedMaxWordCountError {
                      __typename
                      message
                      maxCount
                    }
                  }
                }
              }
            }
          }
          ... on AdminDepartmentMutation {
            __typename
            member(id: $userId) {
              note(id: $noteId) {
                __typename
                ... on AccessForbiddenError {
                  __typename
                  message
                }
                ... on NoteNotFoundError {
                  __typename
                  message
                }
                ... on NoteDeletedError {
                  __typename
                  message
                }
                ... on ProfileNoteMutation {
                  update(details: $details) {
                    ... on ProfileNote {
                      __typename
                      id
                      note
                      access
                      createdAt
                      updatedAt
                      createdBy {
                        ...OrganizationUserResultFragment
                        __typename
                      }
                      editedBy {
                        ...OrganizationUserResultFragment
                        __typename
                      }
                    }
                    ... on ExceedMaxWordCountError {
                      __typename
                      message
                      maxCount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${OrganizationUserResultFragment}
`;
