import gql from 'graphql-tag';

// TODO: create public userFragment and handle query cache update
export const publicUserFragment = `
  id
  username
  firstname
  lastname
  profilePic {
    url
  }
  role
  status
`;

export const removeUserFragmentString = `
  id
  username
  firstname
  lastname
  addresses {
    id
    address
    type
  }
  joinDate
  status
`;

export const userFragmentString = `
  ${publicUserFragment}
  joinDate
  licenseExpiryTime
  licenseStartTime
  scopes {
    organizationId
    siteId
    departmentId
    status
  }
`;

export default gql`
  fragment FullUserFragment on FullUser {
    ${userFragmentString}
  }
`;
