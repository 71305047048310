import gql from 'graphql-tag';
import { BasicProfileFieldFragment, ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type UpdateProfileFieldMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        profileField: {
          update: ProfileFieldFragmentType;
        };
      };
    };
  };
};

const UpdateProfileSectionFragment = gql`
  fragment UpdateProfileSectionFragment on ProfileFieldMutationResult {
    ... on Node {
      id
    }
    ... on ProfilePhoneField {
      ...BasicProfileFieldFragment
      phoneNumber
      placeholder
    }
    ... on ProfileTextField {
      ...BasicProfileFieldFragment
      text
      placeholder
    }
    ... on ProfileDropdownField {
      options
      selectedOptions
    }
    ... on ProfileNumericField {
      value
    }
    ... on ProfileLinkField {
      url
    }
    ... on ProfileEmailField {
      email
    }
    ... on ProfileDateField {
      date
    }
  }
`;
export const ADMIN_UPDATE_PROFILE_FIELD_MUTATION = gql`
  mutation UpdateProfileFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $uniqueFieldId: ID!
    $fieldData: ProfileFieldInput!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              profileField(id: $uniqueFieldId) {
                update(fieldData: $fieldData) {
                  ...UpdateProfileSectionFragment
                }
              }
            }
          }
          ... on AdminDepartmentMutation {
            member(id: $userId) {
              profileField(id: $uniqueFieldId) {
                update(fieldData: $fieldData) {
                  ...UpdateProfileSectionFragment
                }
              }
            }
          }
          ... on AdminSiteMutation {
            member(id: $userId) {
              profileField(id: $uniqueFieldId) {
                update(fieldData: $fieldData) {
                  ...UpdateProfileSectionFragment
                }
              }
            }
          }
        }
      }
    }
  }

  ${UpdateProfileSectionFragment}
  ${BasicProfileFieldFragment}
`;
