import React from 'react';
import styled from 'styled-components';

import Header from '../../../components/header';
import { GetNumbersButton } from '../../../components/buttons';
import SearchBar from './SearchBar';
import VirtualPagersManagmentHeader from './VirtualPagersManagementHeader';

import SortButtons from './PagerSortButton';

import VirtualPagerMenuItemLoader from './VirtualPagerMenuItemLoader';
import { VirtualPagerStatusTypes } from 'src/types';

const MainContainer = styled.div`
  max-width: unset !important;
  padding-left: 5%;
  padding-right: 2.5%;
`;

const ListContainer = styled.div`
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); */
`;

const NumberListLoader = () => {
  return (
    <MainContainer>
      <Header
        buttonComponent={
          <GetNumbersButton isDisabled={false} handleOnClick={() => {}} virtualPagerSectionState="Table" />
        }
        hasNumbers={true}
      />
      <SearchBar />
      <ListContainer>
        <VirtualPagersManagmentHeader
          allPagerCount={0}
          activePagerCount={0}
          pendingPagerCount={0}
          disabledPagerCount={0}
          currentMenuOption={VirtualPagerStatusTypes.ALL}
          setMenuOptions={null}
        />
        <SortButtons />
        {[...Array(4)].map((_, index) => (
          <VirtualPagerMenuItemLoader key={index} />
        ))}
      </ListContainer>
    </MainContainer>
  );
};

export default NumberListLoader;
