import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export default ({ width, height, color }: Props) => (
  <svg width={width || 22} height={height || 22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0.5C8.9233 0.5 6.89323 1.11581 5.16652 2.26957C3.4398 3.42332 2.09399 5.0632 1.29927 6.98182C0.504549 8.90045 0.296614 11.0116 0.701759 13.0484C1.1069 15.0852 2.10693 16.9562 3.57538 18.4246C5.04383 19.8931 6.91476 20.8931 8.95156 21.2982C10.9884 21.7034 13.0996 21.4955 15.0182 20.7007C16.9368 19.906 18.5767 18.5602 19.7304 16.8335C20.8842 15.1068 21.5 13.0767 21.5 11C21.5 8.21523 20.3938 5.54451 18.4246 3.57538C16.4555 1.60625 13.7848 0.5 11 0.5ZM9.5 15.5H8V6.5H9.5V15.5ZM14 15.5H12.5V6.5H14V15.5Z"
      fill={color || '#4A4A4A'}
    />
  </svg>
);
