import React from 'react';
import styled from 'styled-components';
import Info from 'src/assets/svgs/Info';
import { QueryResult } from 'react-apollo';
import { Tooltip } from '@material-ui/core';
import theme from 'src/assets/styles/theme';
import { PENDING_ADMIN, PENDING_ADMIN_APPROVAL, PENDING_ADMIN_RESPONSE } from 'src/constants/inviteUserTypes';
import { User, GetPendingUsersResult } from 'src/types';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import PendingUsersList from 'src/pages/HomePage/views/PendingUsersList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryContainer: {
      userSelect: 'none',
      display: 'flex',
      padding: '12px 12px 12px 0px',
    },
    categoryHeader: {
      width: '100%',
      minWidth: '510px',
      paddingTop: '12px',
      paddingBottom: '4px',
      display: 'flex',
      background: '#FAFAFA',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '17px',
      lineHeight: '24px',
      color: '#000000',
    },
  }),
);

const StyledTooltip = withStyles({
  tooltip: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
  },
})(Tooltip);

const StyledInfo = styled(Info)`
  height: 16px !important;
  width: 16px !important;
  margin: 4px 0px 0px 9px !important;
`;

interface Props {
  users: User[];
  status: string;
  pendingByAdmin: any;
  setPendingByAdmin: any;
  pendingByInvitee: any;
  setPendingByInvitee: any;
  pendingRecords?: QueryResult<GetPendingUsersResult>;
}

const PendingUserListContainer: React.FC<Props> = ({
  users,
  status,
  pendingByAdmin,
  setPendingByAdmin,
  pendingByInvitee,
  setPendingByInvitee,
  pendingRecords,
}) => {
  const classes = useStyles();

  return (
    <div>
      {users && users.length > 0 && (
        <>
          <div className={classes.categoryContainer}>
            <div className={classes.categoryHeader}>
              <span>{status === PENDING_ADMIN ? PENDING_ADMIN_APPROVAL : PENDING_ADMIN_RESPONSE}</span>
              <StyledTooltip
                arrow
                interactive
                placement="bottom-start"
                title={
                  status === PENDING_ADMIN
                    ? 'These users are waiting for admin approval before the invitation gets sent to the invitee.'
                    : 'These users already received admin approval to join the organization, and is waiting for the invitee to accept the invitation.'
                }
              >
                <span>
                  <StyledInfo circlefill={theme.warmGrey} strokefill={'white'} />
                </span>
              </StyledTooltip>
            </div>
          </div>
          <div className="usersList__wrapper">
            <PendingUsersList
              users={users}
              status={status}
              pendingByAdmin={pendingByAdmin}
              setPendingByAdmin={setPendingByAdmin}
              pendingByInvitee={pendingByInvitee}
              setPendingByInvitee={setPendingByInvitee}
              pendingRecords={pendingRecords}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PendingUserListContainer;
