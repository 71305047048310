import React from 'react';
import moment from 'moment';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ALREADY_EXIST, USERNAME_ALREADY_EXIST } from 'src/constants/formErrors';
import styled from 'styled-components';
import Info from 'src/assets/svgs/Info';
import theme from 'src/assets/styles/theme';
import { withStyles } from '@material-ui/core/styles';
import LicenseDatePicker from 'src/components/forms/LicenseDatePicker';
import { PrimaryButton, SecondaryButton, StyledDivider } from 'src/components/shared/HypercareComponents';
import { Grid, Typography, Tooltip, Checkbox, FormControlLabel, InputAdornment, TextField } from '@material-ui/core';

import 'src/assets/styles/FormStyles.scss';

const StyledInfo = styled(Info)`
  height: 16px !important;
  width: 16px !important;
`;

const ActionButton = styled.div`
  float: right;
  margin-top: 8px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 8px !important;
`;

const StyledTooltip = withStyles({
  tooltip: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
  },
})(Tooltip);

const FieldLabel = styled.div`
  margin-bottom: -12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #4a4a4a;
`;

const DualInput = styled.div`
  display: inherit !important;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 24px !important;
  input {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-top: 14.5px !important;
    padding-bottom: 14.5px !important;
  }
  .MuiFormHelperText-root.Mui-error {
    margin-left: 0px !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ff3e55;
  }
`;

const StyledFormContainer = styled.div`
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: calc(82vh - 162px) !important;
  padding-right: 4px !important;
`;

const IntroBanner = styled(Typography)`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #222222 !important;
`;

const ShellAccount = styled.div`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #4a4a4a !important;
`;

// TODO: update phonenumber requirement and regex to match backend
const newUserValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required!'),
  username: Yup.string()
    .required('User name is required!')
    .min(4, 'A minimum length of 4 characters')
    .max(30, 'A maximum length of 30 characters')
    .matches(/^[a-zA-Z0-9]+$/, 'Must be alphanumeric characters'),
  firstname: Yup.string()
    .min(1, 'A minimum length of 1 characters')
    .max(40, 'A maximum length of 40 characters')
    .required('First name is required!'),
  lastname: Yup.string()
    .min(1, 'A minimum length of 1 characters')
    .max(40, 'A maximum length of 40 characters')
    .required('Last name is required!'),
  role: Yup.string(),
});

interface NewUserFormValue {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  role: string;
  isShellAccount: boolean;
  licenseStartDate: moment.Moment;
  licenseEndDate: moment.Moment;
}

const NewUserForm = ({ handleModalFormSubmission, closeModal }) => (
  <Formik
    initialValues={
      {
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        role: '',
        isShellAccount: false,
        licenseStartDate: moment(new Date()),
        licenseEndDate: null,
      } as NewUserFormValue
    }
    validationSchema={newUserValidationSchema}
    onSubmit={(values, { setFieldError, setSubmitting }) => {
      handleModalFormSubmission(values).catch((e) => {
        if (e === ALREADY_EXIST) {
          setFieldError('username', USERNAME_ALREADY_EXIST);
          setSubmitting(false);
        } else {
          setSubmitting(false);
          setFieldError('general', 'Failed inviting new member, please try again');
        }
      });
    }}
    render={(props) => {
      const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } =
        props as FormikProps<any>;
      const hasErrors = Object.keys(errors).length > 0;

      return (
        <form onSubmit={handleSubmit} className="modalForms">
          <StyledFormContainer className="custom-scrollbar">
            <div className="force-overflow">
              <div className="instruction-body" style={{ marginTop: '16px', marginBottom: '1em' }}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <StyledInfo circlefill={theme.warmGrey} strokefill={'white'} />
                  </Grid>
                  <Grid item xs>
                    <IntroBanner>Create a new Hypercare account on behalf of another user.</IntroBanner>
                  </Grid>
                </Grid>
              </div>
              <FieldLabel>Username</FieldLabel>
              <StyledTextField
                fullWidth
                autoFocus
                id="username"
                name="username"
                variant="outlined"
                placeholder="janedoe123"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                value={values.username}
                margin="normal"
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.username ? errors.username : ''}
                error={touched.username && Boolean(errors.username)}
              />
              <DualInput>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FieldLabel>First Name</FieldLabel>
                    <StyledTextField
                      fullWidth
                      id="firstname"
                      name="firstname"
                      variant="outlined"
                      placeholder="Jane"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={values.firstname}
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.firstname ? errors.firstname : ''}
                      error={touched.firstname && Boolean(errors.firstname)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldLabel>Last Name</FieldLabel>
                    <StyledTextField
                      fullWidth
                      id="lastname"
                      name="lastname"
                      variant="outlined"
                      placeholder="Doe"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={values.lastname}
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.lastname ? errors.lastname : ''}
                      error={touched.lastname && Boolean(errors.lastname)}
                    />
                  </Grid>
                </Grid>
              </DualInput>
              <FieldLabel>Email</FieldLabel>
              <StyledTextField
                fullWidth
                id="email"
                name="email"
                variant="outlined"
                placeholder="janedoe@acme.com"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                type="email"
                value={values.email}
                margin="normal"
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.email ? errors.email : ''}
                error={touched.email && Boolean(errors.email)}
              />
              <FieldLabel>Phone number (Optional)</FieldLabel>
              <StyledTextField
                fullWidth
                id="phonenumber"
                name="phonenumber"
                variant="outlined"
                placeholder="519-732-1185"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                }}
                value={values.phonenumber}
                margin="normal"
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.phonenumber ? errors.phonenumber : ''}
                error={touched.phonenumber && Boolean(errors.phonenumber)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="isShellAccount"
                    name="isShellAccount"
                    checked={values.isShellAccount}
                    onChange={handleChange}
                  />
                }
                style={{
                  color: values.isShellAccount ? 'inherit' : 'grey',
                  userSelect: 'none',
                }}
                label={
                  <ShellAccount>
                    This is a&nbsp;
                    <StyledTooltip
                      arrow
                      title="Shell accounts can be created to place users on-call that doesn't have a Hypercare account"
                    >
                      <span style={{ borderBottom: '.5px dashed grey' }}>shell account</span>
                    </StyledTooltip>
                  </ShellAccount>
                }
              />
              <FieldLabel style={{ marginBottom: '4px' }}>Role (Optional)</FieldLabel>
              <StyledTextField
                fullWidth
                id="role"
                name="role"
                variant="outlined"
                placeholder="Manager"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                value={values.role}
                margin="normal"
                style={{ marginTop: 0 }}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.role ? errors.role : ''}
                error={touched.role && Boolean(errors.role)}
              />

              <LicenseDatePicker formikProps={props} isDisabled={isSubmitting} />

              {isSubmitting && <span className="modalForm__sendRequestLabel">sending request...</span>}
              {errors.general && <span className="modalForm__inputError">{errors.general}</span>}
            </div>
          </StyledFormContainer>
          <StyledDivider />
          <ActionButton>
            <StyledSecondaryButton type="submit" onClick={closeModal}>
              Cancel
            </StyledSecondaryButton>
            <PrimaryButton type="submit" disableTouchRipple color="secondary" disabled={hasErrors || isSubmitting}>
              Create a new user
            </PrimaryButton>
          </ActionButton>
        </form>
      );
    }}
  />
);

export default NewUserForm;
