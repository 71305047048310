import gql from 'graphql-tag';
import { DepartmentFragments, SiteFragments } from 'src/gql/fragment/ManagementFragments';

export const RoleFragment = gql`
  fragment RoleFragment on Role {
    id
    name
    department {
      ...DepartmentFragments
    }
    site {
      ...SiteFragments
    }
  }
  ${DepartmentFragments}
  ${SiteFragments}
`;

export default gql`
  fragment ConflictShiftFragment on Shift {
    id
    startDate
    endDate
    role {
      id
      name
      department {
        id
        name
      }
      site {
        id
        name
      }
    }
  }
`;
