import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDrag } from 'react-dnd';
import { DropSourceItem, EscalationPolicy } from 'src/types/Escalation';
import AppTheme from 'src/assets/styles/theme';

interface PolicyDropSourceHolderProps {
  isDragging: boolean;
}

const PolicyDropSourceHolder = styled.div`
  flex: 0 48%;
  min-height: 224px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: ${(props: PolicyDropSourceHolderProps) =>
    props.isDragging ? `2px solid ${AppTheme.mainTealColor}` : '1px solid grey'};
  padding: 1em;
  background-color: white;
  cursor: move;
  margin-bottom: 1em;
  div {
    &:first-child {
      line-height: 2em;
    }
    &:last-child {
      line-height: 1.5em;
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

interface Props {
  dropSourceItem: DropSourceItem;
}

const EscalationOnCallSource: React.FC<Props> = ({ dropSourceItem }) => {
  const [{ isDragging }, drag] = useDrag({
    item: dropSourceItem,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const policyData = dropSourceItem.data as EscalationPolicy;

  const { name, repeatAttempts, repeatLength } = policyData;

  return (
    <PolicyDropSourceHolder ref={drag} isDragging={isDragging}>
      <div>{name}</div>
      <div>
        Try each channel every {moment.duration(repeatLength).asMinutes()} minutes for {repeatAttempts} attempts
      </div>
    </PolicyDropSourceHolder>
  );
};

export default EscalationOnCallSource;
