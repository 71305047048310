import React from 'react';
import styled from 'styled-components';
import Info from 'src/assets/svgs/Info';
import { Tooltip } from '@material-ui/core';
import theme from 'src/assets/styles/theme';
import {
  PENDING_ADMIN,
  PENDING_ADMIN_APPROVAL,
  PENDING_ADMIN_RESPONSE,
  PresentedUserLicensingStatus,
} from 'src/constants/inviteUserTypes';
import PendingUsersListV2 from './PendingUsersListV2';
import { PENDING_ADMIN_APPROVAL_TOOLTIP_TEXT, PENDING_INVITEE_RESPONSE_TOOLTIP_TEXT } from '../../../constants/strings';
import { PaginatedInvites } from '../../../types/PaginatedTypes';
import {
  FetchPaginatedInvitesQueryResponse,
  PaginatedPendingInvites,
} from '../../../gql/v2/query/FetchPaginatedInvitesQuery';
import PendingAdminUsersListV2 from './PendingAdminUsersListV2';
import { ApolloQueryResult } from 'apollo-client';
import { OrganizationalUnitInputType } from '../../../gql/v2/types/input';
import { FetchPaginatedInviteRequestInput } from '../../../data/repository/UserRepository';

const StyledCategoryContainer = styled.div`
  user-select: none;
  display: flex;
  padding: 12px 12px 12px 0px;
`;

const StyledCategoryHeader = styled.div`
  width: 100%;
  min-width: 510px;
  padding-top: 12px;
  padding-bottom: 4px;
  display: flex;
  background: #fafafa;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
`;

const StyledTooltip = styled(Tooltip)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const StyledInfo = styled(Info)`
  height: 16px !important;
  width: 16px !important;
  margin: 4px 0px 0px 9px !important;
`;

interface Props {
  users: PaginatedInvites[];
  status: PresentedUserLicensingStatus;
  pendingByAdmin: PaginatedInvites[];
  setPendingByAdmin: React.Dispatch<React.SetStateAction<PaginatedInvites[]>>;
  pendingByInvitee: PaginatedInvites[];
  setPendingByInvitee: React.Dispatch<React.SetStateAction<PaginatedInvites[]>>;
  pendingRecords: (
    variables?: OrganizationalUnitInputType & FetchPaginatedInviteRequestInput,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponse>>;
  paginatedPendingUserInvitesQuery: FetchPaginatedInvitesQueryResponse;
  paginatedPendingAdminInvitesQuery: FetchPaginatedInvitesQueryResponse;
  fetchMorePaginatedAdminInviteQuery: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponse>>;
  fetchMorePaginatedUserInvitesQuery: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponse>>;
  searchPendingInviteData: PaginatedPendingInvites;
  setSearchPendingInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvites>>;
  searchPendingAdminApprovalInviteData: PaginatedPendingInvites;
  setSearchPendingAdminApprovalInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvites>>;
  localSearchText: string;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

const PendingUserListContainer: React.FC<Props> = ({
  users,
  status,
  pendingByAdmin,
  setPendingByAdmin,
  pendingByInvitee,
  setPendingByInvitee,
  pendingRecords,
  paginatedPendingAdminInvitesQuery,
  paginatedPendingUserInvitesQuery,
  fetchMorePaginatedUserInvitesQuery,
  fetchMorePaginatedAdminInviteQuery,
  searchPendingInviteData,
  setSearchPendingInviteData,
  searchPendingAdminApprovalInviteData,
  setSearchPendingAdminApprovalInviteData,
  localSearchText,
  isDoneRows,
  setIsDoneRows,
}) => {
  const totalAdminApprovalsInvitesResultsCount =
    localSearchText.length >= 1
      ? searchPendingAdminApprovalInviteData?.totalResultsCount
      : paginatedPendingAdminInvitesQuery.adminQuery.organizationalUnit.paginatedPendingInvites.totalResultsCount;
  const totalPendingUserInvitesResultsCount =
    localSearchText.length >= 1
      ? searchPendingInviteData?.totalResultsCount
      : paginatedPendingUserInvitesQuery.adminQuery.organizationalUnit.paginatedPendingInvites.totalResultsCount;
  return (
    <div>
      {users && users.length > 0 && (
        <>
          <StyledCategoryContainer>
            <StyledCategoryHeader>
              <span>
                {status === PENDING_ADMIN ? (
                  <>
                    {PENDING_ADMIN_APPROVAL} <span>({totalAdminApprovalsInvitesResultsCount})</span>
                  </>
                ) : (
                  <>
                    {PENDING_ADMIN_RESPONSE} <span>({totalPendingUserInvitesResultsCount})</span>
                  </>
                )}
              </span>
              <StyledTooltip
                arrow
                interactive
                placement="bottom-start"
                title={
                  status === PENDING_ADMIN ? PENDING_ADMIN_APPROVAL_TOOLTIP_TEXT : PENDING_INVITEE_RESPONSE_TOOLTIP_TEXT
                }
              >
                <span>
                  <StyledInfo circlefill={theme.warmGrey} strokefill={'white'} />
                </span>
              </StyledTooltip>
            </StyledCategoryHeader>
          </StyledCategoryContainer>
          <div className="usersList__wrapper">
            {status === PENDING_ADMIN ? (
              <PendingAdminUsersListV2
                users={users}
                status={status}
                pendingByAdmin={pendingByAdmin}
                setPendingByAdmin={setPendingByAdmin}
                pendingByInvitee={pendingByInvitee}
                setPendingByInvitee={setPendingByInvitee}
                pendingRecords={pendingRecords}
                paginatedPendingAdminInvitesQuery={paginatedPendingAdminInvitesQuery}
                paginatedPendingUserInvitesQuery={paginatedPendingUserInvitesQuery}
                fetchMorePaginatedAdminInviteQuery={fetchMorePaginatedAdminInviteQuery}
                searchPendingAdminApprovalInviteData={searchPendingAdminApprovalInviteData}
                setSearchPendingAdminApprovalInviteData={setSearchPendingAdminApprovalInviteData}
                localSearchText={localSearchText}
                isDoneRows={isDoneRows}
                setIsDoneRows={setIsDoneRows}
              />
            ) : (
              <PendingUsersListV2
                users={users}
                status={status}
                paginatedPendingUserInvitesQuery={paginatedPendingUserInvitesQuery}
                fetchMorePaginatedUserInvitesQuery={fetchMorePaginatedUserInvitesQuery}
                setSearchPendingInviteData={setSearchPendingInviteData}
                searchPendingInviteData={searchPendingInviteData}
                localSearchText={localSearchText}
                isDoneRows={isDoneRows}
                setIsDoneRows={setIsDoneRows}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PendingUserListContainer;
