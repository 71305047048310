import * as React from 'react';
import { HCLabelTwo } from 'src/components/shared/HypercareComponents';
import { StyledTextField } from '../../../../components/shared';
import styled from 'styled-components';
import {
  DISPLAY_NAME_ACCORDION_INPUT_LABEL,
  DISPLAY_NAME_ACCORDION_INPUT_PLACEHOLDER,
} from 'src/constants/virtualPagerStrings';
import theme from 'src/assets/styles/theme';

const TextFieldMetaDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type FriendlyNameAccordionDetailsProps = {
  friendlyName: string;
  setFriendlyName: React.Dispatch<React.SetStateAction<string>>;
  isPagerDisabled: boolean;
};

const FriendlyNameAccordionDetails: React.FC<FriendlyNameAccordionDetailsProps> = ({
  isPagerDisabled,
  friendlyName,
  setFriendlyName,
}) => {
  const CHARACTER_LIMIT = 75;

  return (
    <>
      <TextFieldMetaDataContainer>
        <HCLabelTwo
          afterContent=" *"
          color="#4A4A4A"
          style={{
            fontSize: '14px',
          }}
        >
          {DISPLAY_NAME_ACCORDION_INPUT_LABEL}
        </HCLabelTwo>
        <HCLabelTwo
          color={friendlyName.length >= CHARACTER_LIMIT ? theme.errorRedBorder : theme.textLightTertiary}
        >{`${friendlyName.length}/${CHARACTER_LIMIT}`}</HCLabelTwo>
      </TextFieldMetaDataContainer>
      <StyledTextField
        id="outlined-basic"
        variant="outlined"
        placeholder={DISPLAY_NAME_ACCORDION_INPUT_PLACEHOLDER}
        style={{ height: '40px', borderRadius: '2px', marginBottom: '28px' }}
        autoComplete="off"
        disabled={isPagerDisabled}
        inputProps={{
          maxLength: CHARACTER_LIMIT,
          style: {
            padding: '6px 12px 6px 12px',
            width: '100%',
            height: '40px',
            fontSize: '16px',
          },
        }}
        value={friendlyName}
        onChange={(event) => setFriendlyName(event.target.value)}
        error={friendlyName.length === CHARACTER_LIMIT}
      />
    </>
  );
};

export default FriendlyNameAccordionDetails;
