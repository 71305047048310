import { AsYouType, parseIncompletePhoneNumber } from 'libphonenumber-js';

export default (phonenumber: string) => {
  let formatedPhoneNumber;
  if (phonenumber) {
    formatedPhoneNumber = new AsYouType('CA').input(phonenumber);
    if (formatedPhoneNumber.length <= 7) {
      formatedPhoneNumber = parseIncompletePhoneNumber(phonenumber) || phonenumber;
    }
  } else {
    formatedPhoneNumber = '';
  }
  return removePlusSignWithNumber(formatedPhoneNumber);
};

// TODO: replace +1 base on phoneNumber country code
export const removePlusSignWithNumber = (str) => (!!str ? str.replace('+1', '').replace('+', '') : '');

export const sanitizePhoneNumbers = (number: string) => {
  if (number.includes('+1')) {
    return number;
  }
  return `+1${number}`;
};
