import React from 'react';

export default function PlusButton(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1242_1846)">
        <path
          d="M8.66602 7.33332V3.33332C8.66602 2.96513 8.36754 2.66666 7.99935 2.66666C7.63116 2.66666 7.33268 2.96513 7.33268 3.33332V7.33332H3.33268C2.96449 7.33332 2.66602 7.6318 2.66602 7.99999C2.66602 8.36818 2.96449 8.66666 3.33268 8.66666H7.33268V12.6667C7.33268 13.0348 7.63116 13.3333 7.99935 13.3333C8.36754 13.3333 8.66602 13.0348 8.66602 12.6667V8.66666H12.666C13.0342 8.66666 13.3327 8.36818 13.3327 7.99999C13.3327 7.6318 13.0342 7.33332 12.666 7.33332H8.66602Z"
          fill={props.color || '#00859A'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1242_1846">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
