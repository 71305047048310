import * as React from 'react';

export default (props) => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="33" cy="32" r="32" fill="#F6F6F9" />
    <g clipPath="url(#clip0_2231_13757)">
      <path
        d="M47.6248 45.0349L39.1287 36.5388C41.1702 34.0878 42.1883 30.9441 41.971 27.7617C41.7538 24.5792 40.3179 21.603 37.9622 19.4522C35.6065 17.3015 32.5123 16.1417 29.3233 16.2141C26.1343 16.2866 23.0959 17.5858 20.8404 19.8413C18.5848 22.0969 17.2856 25.1352 17.2132 28.3243C17.1407 31.5133 18.3005 34.6075 20.4513 36.9632C22.6021 39.3189 25.5782 40.7547 28.7607 40.972C31.9431 41.1892 35.0868 40.1712 37.5378 38.1296L46.0339 46.6258L47.6248 45.0349ZM19.4998 28.6258C19.4998 26.6232 20.0936 24.6657 21.2061 23.0006C22.3187 21.3356 23.9 20.0378 25.7501 19.2715C27.6002 18.5051 29.636 18.3046 31.6001 18.6953C33.5641 19.086 35.3682 20.0503 36.7842 21.4663C38.2002 22.8823 39.1646 24.6864 39.5552 26.6505C39.9459 28.6145 39.7454 30.6503 38.9791 32.5004C38.2127 34.3505 36.915 35.9318 35.2499 37.0444C33.5849 38.1569 31.6273 38.7508 29.6248 38.7508C26.9404 38.7478 24.3668 37.6801 22.4686 35.7819C20.5704 33.8838 19.5028 31.3102 19.4998 28.6258Z"
        fill="#00859A"
      />
    </g>
    <defs>
      <clipPath id="clip0_2231_13757">
        <rect width="36" height="36" fill="white" transform="translate(15 14)" />
      </clipPath>
    </defs>
  </svg>
);
