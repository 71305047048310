import gql from 'graphql-tag';
import { OrganizationUserResultFields } from './OrganizationUserResultFieldsFragment';

export const ProfileNoteFields = gql`
  fragment ProfileNoteFields on ProfileNote {
    id
    note
    createdBy {
      __typename
      ...OrganizationUserResultFields
    }
    editedBy {
      __typename
      ...OrganizationUserResultFields
    }
    access
    createdAt
    updatedAt
  }
  ${OrganizationUserResultFields}
`;
