import React from 'react';
import ReactDOM from 'react-dom';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

class InputScheduleBtnPortal extends React.PureComponent<any, any> {
  public el: Element;

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount = () => {
    const portal = document.getElementById('backTopFAB');
    if (portal) portal.appendChild(this.el);
  };

  componentWillUnmount = () => {
    const portal = document.getElementById('backTopFAB');
    if (portal) portal.removeChild(this.el);
  };

  render() {
    return ReactDOM.createPortal(
      <Tooltip title="Input Schedule" aria-label="Input Schedule">
        <Fab
          color="secondary"
          onClick={this.props.onBtnClick}
          style={{
            marginRight: '0.5em',
            fontSize: '22px',
          }}
        >
          +
        </Fab>
      </Tooltip>,
      this.el,
    );
  }
}

export default InputScheduleBtnPortal;
