import gql from 'graphql-tag';

export const ADMIN_DELETE_PROFILE_NOTE = gql`
  mutation DeleteProfileNote($organizationalUnit: OrganizationalUnitInput!, $userId: ID!, $noteId: ID!) {
    adminMutation {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationMutation {
            __typename
            member(id: $userId) {
              note(id: $noteId) {
                __typename
                ... on AccessForbiddenError {
                  __typename
                  message
                }
                ... on NoteNotFoundError {
                  __typename
                  message
                }
                ... on NoteDeletedError {
                  __typename
                  message
                }
                ... on ProfileNoteMutation {
                  deleteNote {
                    ... on ProfileNote {
                      __typename
                      id
                    }
                  }
                }
              }
            }
          }
          ... on AdminSiteMutation {
            __typename
            member(id: $userId) {
              note(id: $noteId) {
                __typename
                ... on AccessForbiddenError {
                  __typename
                  message
                }
                ... on NoteNotFoundError {
                  __typename
                  message
                }
                ... on NoteDeletedError {
                  __typename
                  message
                }
                ... on ProfileNoteMutation {
                  deleteNote {
                    ... on ProfileNote {
                      __typename
                      id
                    }
                  }
                }
              }
            }
          }
          ... on AdminDepartmentMutation {
            __typename
            member(id: $userId) {
              note(id: $noteId) {
                __typename
                ... on AccessForbiddenError {
                  __typename
                  message
                }
                ... on NoteNotFoundError {
                  __typename
                  message
                }
                ... on NoteDeletedError {
                  __typename
                  message
                }
                ... on ProfileNoteMutation {
                  deleteNote {
                    ... on ProfileNote {
                      __typename
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
