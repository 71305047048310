import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { StyledTextField } from 'src/pages/VirtualPagersPage/components/shared';
import WatchVideo from 'src/assets/svgs/WatchVideo';
import theme from 'src/assets/styles/theme';
import PauseIcon from 'src/assets/svgs/PauseIcon';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
`;

type Props = {
  value: string;
  src: string;
  disabled: boolean;
  children: React.ReactNode;
};

const AudioSelectionTextField = ({ src, value, disabled, children }: Props) => {
  const [audio] = React.useState(new Audio(src));
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    audio.src = src;
  }, [src]);

  const pauseAudio = () => {
    audio.pause();
    setIsPlaying(false);
  };

  const playAudio = () => {
    audio.play();
    setIsPlaying(true);
  };

  audio.addEventListener('ended', () => {
    setIsPlaying(false);
  });

  return (
    <StyledTextField
      select
      disabled={disabled}
      variant="outlined"
      value={value}
      style={{ minWidth: '495px', height: '40px', borderRadius: '2px', marginBottom: '16px' }}
      InputProps={{
        style: {
          marginRight: '8px',
          marginLeft: '8px',
        },
        startAdornment: (
          <InputAdornment position="start">
            <IconContainer onClick={isPlaying ? pauseAudio : playAudio}>
              {isPlaying ? (
                <PauseIcon width={20} height={20} color={theme.mainTealColor} />
              ) : (
                <WatchVideo width={20} height={20} color={theme.mainTealColor} />
              )}
            </IconContainer>
          </InputAdornment>
        ),
      }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {children}
    </StyledTextField>
  );
};

export default AudioSelectionTextField;
