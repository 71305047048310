import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';

import { StyledTextField } from '../../pages/VirtualPagersPage/components/shared';
import theme from '../../assets/styles/theme';
import styled from 'styled-components';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '../../assets/svgs/SearchIcon';

const SearchBarContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;
interface CustomSearchFieldProps {
  error?: boolean;
  searchError?: string;
  clearSearchText: () => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  localSearchText: string;
  placeHolder: string;
  inputWidth?: string;
  inputHeight?: string;
}

const CustomSearchField: React.FC<CustomSearchFieldProps> = ({
  error,
  searchError,
  clearSearchText,
  handleKeyDown,
  handleChange,
  localSearchText,
  placeHolder,
  inputWidth,
  inputHeight,
}) => {
  return (
    <SearchBarContainer>
      <StyledTextField
        error={error}
        helperText={error && searchError}
        variant="outlined"
        id="outlined-adornment-search"
        style={{ background: 'white' }}
        inputProps={{
          style: {
            padding: '0px 2px 0px 8px',
            width: inputWidth,
            height: inputHeight ? inputHeight : '36px',
            borderRadius: '2px',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon width={20} height={20} stroke={theme.mainFontColor} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={clearSearchText}
              style={{
                cursor: 'pointer',
                visibility: localSearchText === '' ? 'hidden' : 'visible',
              }}
            >
              <ClearIcon width={20} height={20} stroke={theme.mainFontColor} />
            </InputAdornment>
          ),
          onKeyDown: handleKeyDown,
        }}
        placeholder={placeHolder}
        value={localSearchText}
        onChange={handleChange}
      />
    </SearchBarContainer>
  );
};

export default CustomSearchField;
